import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Col, Container, Row} from "reactstrap";
import {useHistory} from "react-router-dom";
import {getProductDetail, getProductDetailsReset,} from "../../store/e-commerce/actions";
import ArticlesDetail from "./ArticlesDetail";
import {put} from "../../helpers/api_helper";
import {isEmpty} from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";

const ArticlePreview = (props) => {
  const {
    article,
    match: { params },
    onGetProductDetail,
    onGetProductReset,
    loading,
    error,
  } = props;

  const [product, setProduct] = useState(article);
  const [brandSettings, setBrandSettings] = useState(null)
  const history = useHistory();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    if (params && params.id) {
      onGetProductDetail(params.id);
    }
    return () => {
      onGetProductReset();
    };
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetProductReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if (!isEmpty(article)) {
      setProduct(article);
    }
  }, [article]);

  const handleBack = () => {
    history.goBack();
  };

  const handleEdit = () => {
    history.push(`/articles/${article.articleNumber}/edit`);
  };

  const togglePublish = async () => {
    let response = await put(`/api/article/publish-toggle/${article.articleNumber}`);
    onGetProductDetail(article.articleNumber);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <>
          <PageToolbarTop
            noBackBtn={true}
            backBtn={handleBack}
            title={
              article.displayName ? article.displayName : article.description
            }
            breadcrumbs={[
              { title: props.t("Products"), link: "#" },
              { title: props.t("Articles"), link: "/articles" },
            ]}

            toggle={{
              active: article.publish,
              onClick: togglePublish,
              label: article.publish ? (
                <div className="product-ribbon badge bg-soft-success bg-success">
                  {" "}
                  {props.t("Published")}{" "}
                </div>
              ) : (
                <div className="product-ribbon badge bg-soft-warning bg-warning">
                  {" "}
                  {props.t("Unpublished")}
                </div>
              ),
            }}
          />

          <Row>
            <Col lg="12">
              <ArticlesDetail product={product} showBuyOption={false} loading={loading} key={product.id} error={error} />
            </Col>
          </Row></>
        </Container>
      </div>
    </React.Fragment>
  );
};

ArticlePreview.prototype = {
  article: PropTypes.object,
  onGetProductDetail: PropTypes.func,
  loading: PropTypes.bool,
  onGetProductReset: PropTypes.func,
};

const mapStateToProps = ({ ecommerce }) => ({
  article: ecommerce.product,
  loading: ecommerce.productDetailLoading,
  error: ecommerce.productDetailError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductDetail: (id) => dispatch(getProductDetail(id)),
  onGetProductReset: () => dispatch(getProductDetailsReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ArticlePreview));
