import {call, put, takeEvery} from "redux-saga/effects";
import {GET_PROJECT_TEMPLATE, GET_PROJECT_TEMPLATE_DETAIL} from "./actionTypes";
import {getProjectTemplateDetailsError, getProjectTemplateDetailsSuccess, getProjectTemplateError, getProjectTemplateSuccess} from "./actions";
import {getProjectTemplateDetails, getProjectTemplateList} from "../../helpers/fakebackend_helper";


function* fetchProjectTemplate() {
    try {
        const response = yield call(getProjectTemplateList);
        yield put(getProjectTemplateSuccess(response));
    } catch (e) {
        yield put(getProjectTemplateError(e))
    }
}

function* fetchProjectTemplateDetails({id}){
    try{
        const response = yield call(getProjectTemplateDetails, id);
        yield put(getProjectTemplateDetailsSuccess(response))
    }catch (e){
        yield put(getProjectTemplateDetailsError(e))
    }
}

function* projectTemplateSaga() {
    yield takeEvery(GET_PROJECT_TEMPLATE, fetchProjectTemplate);
    yield takeEvery(GET_PROJECT_TEMPLATE_DETAIL, fetchProjectTemplateDetails)
}

export default projectTemplateSaga;
