import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_ALL_USERS,
    GET_USERS
} from "./actionTypes";
import {
    getAllUsersFail,
    getAllUsersSuccess, getUser, getUserFail, getUserSuccess,
} from "./actions";
import {getAllUsers, getUsersForImpersonation} from "../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../util/constants";


function* fetchUsers() {
    try {
        const response = yield call(getAllUsers)
        yield put(getAllUsersSuccess(response))
    } catch (error) {
        yield put(getAllUsersFail(API_ERR_MSG))
    }
}

function* fetchUserList(action) {
    try {
        const response = yield call(getUsersForImpersonation, action.data);
        yield put(getUserSuccess(response));
    } catch (error) {
        yield put(getUserFail(error));
    }
}

function* userSaga() {
    yield takeEvery(GET_ALL_USERS, fetchUsers)
    yield takeEvery(GET_USERS, fetchUserList);
}

export default userSaga
