import React from "react";

const TrackingNumber = (props) => {
    let {item} = props;

    const getItemDescription = (value) => {
        if (value?.startsWith("KolliNr:")) {
            let link = "KolliNr:";
            let accountNumber = value.replace(link, "").split(";");
            for (var i = 0; i < accountNumber.length; i++) {
                if (accountNumber[i]) {
                    link += "<a target='_blank' style='color:#0404bd' href='https://www.postnord.se/vara-verktyg/spara-brev-paket-och-pall?shipmentId=" + accountNumber[i].trim() + "'>" + accountNumber[i] + "</a>";
                }
            }
            return link;
        }
        return value
    }


    return (<>
        <div dangerouslySetInnerHTML={{__html:getItemDescription(item)}}></div>
    </>)
}

export default TrackingNumber;