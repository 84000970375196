import {withTranslation} from "react-i18next";
import {Badge} from "reactstrap";

const InvoiceStatus = (props) => {
    let {status} = props;

    return (<>
        {status === 'UN_SENT' && <span><Badge className="bg-soft-dark">{props.t("Unsent")}</Badge></span>}
        {status === 'PAID' && <span><Badge className="bg-soft-success">{props.t("Paid")}</Badge></span>}
        {status === 'PARTIAL' && <span><Badge className="bg-soft-warning">{props.t("Partial")}</Badge></span>}
        {status === 'SENT' && <span><Badge className="bg-soft-info">{props.t("Sent")}</Badge></span>}
        {status === 'OVERDUE' && <span><Badge className="bg-soft-danger">{props.t("Overdue")}</Badge></span>}
        {status === 'CANCELLED' && <span><Badge className="bg-soft-danger">{props.t("Cancelled")}</Badge></span>}
    </>)
}

export default withTranslation()(InvoiceStatus)
