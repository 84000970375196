import React, { useEffect, useState } from "react"
import ReactLoading from "react-loading";
import { withTranslation } from "react-i18next";
import { SPINNER_DEFAULT_COLOR } from "../../util/constants"

const Loader = props => {
    const {loading, title, defaultLoader} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [color, setColor] = useState(SPINNER_DEFAULT_COLOR)

    useEffect(() => {
      let user = JSON.parse(localStorage.getItem("authUser"));
      setBrandSettings(user?.brandConfig);
    }, [])

    useEffect(() => {
      setColor(brandSettings ? brandSettings.primaryColor : SPINNER_DEFAULT_COLOR)
    }, [brandSettings])

    return loading ? (defaultLoader ? 
      <div className="loader-wrapper">
        <ReactLoading type="bubbles" color={color} />
        {title && <h5>{props.t("Loading")} {props.t(title)}...</h5>}
      </div> : 
      <div className="loader-wrapper">
        <ReactLoading type="bubbles" color={color} height={'4%'} width={'4%'} className="loader" />
        {title && <h5 className="loader-title">{props.t("Loading")} {props.t(title)}...</h5>}
    </div>
    ) : null
}

export default withTranslation()(Loader)
