import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {del, get, post, postMultipart, put} from "../../helpers/api_helper";
import {withTranslation} from "react-i18next";
import {API_ERR_MSG} from "../../util/constants";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {AvField} from "availity-reactstrap-validation";
import Loader from "../../components/Common/Loader";
import {Avatar, IconButton} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {editProfile, getTenantUserDetails, getTenantUserDetailsReset} from "../../store/actions";
import {connect} from "react-redux";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import moment from 'moment-timezone'; //import moment-timezone
import Select from 'react-select'; //import react-select

const timezones = moment.tz.names();
const options = timezones.map((tz) => {
    return { value: tz, label: tz }
});
const TenantUserForm = (props) => {
    const {
        onEditProfile, 
        editProfileLoading,
        onGetUserDetails,
        profileError,
        success,
        user,
        onGetUserDetailsReset,
        useModal, tog_standard
    } = props;
    const { userId } = useParams();
    const [uid, setuid] = useState();
    const [tenantId, setTenantId] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const [profileImageId, setProfileImageId] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [selectedFile, setselectedFile] = useState(null)
    const ERR_MSG = "Error occured while updating profile. If issue persists, Please contact system administrator";


    const {header} = props.location.state;
    let history = useHistory();
    const [formFields, setFormFields] = useState({
        title:"",
        name: "",
        email: "",
        id: 0,
        city: "",
        phone: "",
        mobile:"",
        role: "ROLE_TENANT_USER",
        timezone: JSON.parse(localStorage.getItem("authUser"))?.timezone
    });

    const clearError = () => setError([])
    const [brandSettings, setBrandSettings] = useState(null);
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    
    useEffect(() => {
        setBrandSettings(loggedInUser.brandConfig);
        if(!useModal){
            if(userId){
                setuid(userId);
            } else {
                setuid(props.userId);
            }
        }

        if(props.tenantId){
            setTenantId(props.tenantId)
        }
        else if (localStorage.getItem("authUser")) {
            setTenantId(loggedInUser?.tenantId);
        }
        return () => {
            onGetUserDetailsReset();
        };
    }, [])

    useEffect(async () => {
        if (uid && tenantId && !useModal) {
            setLoading(true);
            let response = await get(`/api/tenant/${tenantId}/users/${uid}`);
            let data = response.data;
            setFormFields({...data, username: data.username})
            setLoading(false);
            onGetUserDetails(uid);
        }
    }, [uid, tenantId])

    useEffect(() => {
        if(user && !useModal){
            setProfileImageId(user?.profileImage?.s3Key)
        }
    }, [user])

    useEffect(()=> {
        if(document.querySelector('select[name=role]')){
            document.querySelector('select[name=role]').value = formFields.role
        }
    }, [formFields])


    const handleTimezoneChange = selectedOption => {
        setFormFields({...formFields, timezone: selectedOption.value})
    };

    // useEffect(() => {
    //     if(success && useModal){
    //         cogoToast.success(props.t("User created successfully!"), toastOptions)
    //         tog_standard(user);
    //     }
    // }, [success])

    // useEffect(() => {
    //     if(profileError && useModal){
    //         cogoToast.error(props.t("Error occured while creating user!"), toastOptions)
    //     }
    // }, [profileError])

    const createUser = (fields) => {
        post(`/api/tenant/${tenantId}/users`, fields)
            .then(data => {
                if(data?.status !== 200) {
                    setError([props.t(data.data)])
                    setLoading(false);
                } else {
                    if(formFields.profileImageId){
                        let u = data?.data;
                        onEditProfile({userId: u.id, name: u.name, email: u.email, phone: u.phone, mobile: u.mobile, title: u.title, profileImageId: formFields.profileImageId})
                    }
                    if(props.callback) {
                        props.callback();
                    }
                    setLoading(false);
                    if(useModal){
                        cogoToast.success(props.t("User created successfully!"), toastOptions)
                        tog_standard(data?.data);
                        return;
                    }
                    else if(props.avoidRedirect) {
                        history.push(`/tenants/${tenantId}/user/${data?.data?.id}`)
                    } else
                        history.push(`/settings/users/${data?.data?.id}`)
                }
            })
            .catch(err => {
                if(useModal){
                    cogoToast.error(props.t("Error occured while creating user!"), toastOptions)
                }
                setError([props.t(API_ERR_MSG)])
            })
            setLoading(false);
    }

    const updateUser = (fields) => {
        put(`/api/tenant/${tenantId}/users/${fields.id}`, fields)
            .then(data => {
                if(data?.status !== 200) {
                    setError([props.t(data.data)])
                } else {
                    if(formFields.profileImageId){
                        onEditProfile({userId: formFields.id, name: formFields.name, email: formFields.email, phone: formFields.phone, mobile: formFields.mobile, title: formFields.title, profileImageId: formFields.profileImageId})
                    } else if(user?.profileImage?.s3Key){
                        try {
                            let response = del(`/api/user/${formFields.id}/profile-picture`);
                        } catch (e) {
                            setErrorMessage(props.t(API_ERR_MSG));
                        }
                    }
                    if(props.callback) {
                        props.callback();
                    }
                    setLoading(false);
                    if(props.avoidRedirect) {
                        history.push(`/tenants/${tenantId}/user/${data?.data?.id}`)
                    } else
                        history.push(`/settings/users/${data?.data?.id}`)
                }
            })
            .catch(err => {
                setError([props.t(API_ERR_MSG)])
                setLoading(false);
            })
    }

    const submitForm = (e, fields) => {
        setLoading(true);
        (uid && !useModal) ? updateUser(fields) : createUser(fields)
    }

    const handleDeleteProfilePicture = async () => {
        setProfileImageId(null);
        setFormFields({...formFields, profileImageId: null})
    }

    const handleAcceptedFile = async (e) => {
        if(e.target.files){
            setImageUploading(true);
            let formData = new FormData();
            formData.append("files[]", e.target.files[0]);
            let response;
            try {
                response = await postMultipart("/api/media", formData);
            } catch (ex){
                setErrorMessage(props.t(ERR_MSG));
                setTimeout(() => setErrorMessage(null), 3000);
            }
            if (response && response.status === 200) {
                setselectedFile(response.data[0])
                setProfileImageId(response.data[0].s3Key);
                setFormFields({...formFields, profileImageId: response.data[0]?.id})
            } else {
                setErrorMessage(props.t(ERR_MSG));
                setTimeout(() => setErrorMessage(null), 3000);
            }

            setImageUploading(false);
        }
    }

    return (
            <Card>
                <Loader loading={loading} />
                {!loading && <AvForm
                    onValidSubmit={(e, v) => {
                        e.preventDefault();
                        submitForm(e, v)
                    }}
                >
                    <CardBody>
                        {!useModal && <>
                            <CardTitle className="h4">{uid ? "Update Tenant User" : "Create Tenant"}</CardTitle>
                            <p className="card-title-desc"></p>
                        </>}

                        {error.length > 0 ? (
                            <Alert color="danger">{error.map((msg, index) => index === 0 ? msg : (<p>{msg}</p>))}</Alert>
                        ) : null}

                        {successMessage && (
                                <Col className="col-12">
                                    <Alert color="success">{successMessage}</Alert>
                                </Col>
                        )}
                        {errorMessage && (
                                <Col className="col-12">
                                    <Alert color="danger">{errorMessage}</Alert>
                                </Col>
                        )}

                        
                        <div className="text-center">
                            <input
                                accept='image/*'
                                id='icon-button-file'
                                type='file'
                                onChange={handleAcceptedFile}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <label htmlFor='icon-button-file'>
                                <IconButton
                                    color='primary'
                                    aria-label='upload picture'
                                    component='span'
                                >
                                    <Avatar
                                        variant="rounded"
                                        src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                        style={{
                                            margin: "10px",
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "100px"
                                        }}
                                    >
                                        <PersonIcon/>
                                    </Avatar>
                                </IconButton>
                            </label>
                                    {/* <div>
                                        <img
                                            src={profileImageId ? BASE_IMAGE_URL + "/" + profileImageId : avatar}
                                            alt=""
                                            className="avatar-lg rounded-circle img-thumbnail"
                                        />
                                    </div> */}
                            {profileImageId && 
                            <h6>
                                <Button className="btn-sm btn-danger"
                                    onClick={handleDeleteProfilePicture}
                                    color="danger">
                                    {props.t(deleteBtnText)}
                                </Button>
                            </h6>}
                            <h5 className="mb-1">{formFields?.name}</h5>
                            {formFields?.username && <p className="text-muted"><a href={"mailto:"+ formFields?.username}>{formFields?.username}</a></p>}
                        </div>

                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("User Role")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    className="form-control"
                                    name="role"
                                    type="select"
                                    value={formFields.role}
                                    disabled={loading}
                                >
                                    <option value={'ROLE_TENANT_USER'}>{props.t("User")}</option>
                                    <option value={'ROLE_TENANT_ADMIN'}>{props.t("Admin")}</option>
                                </AvField>
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Title")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="title"
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter title")}
                                    value={formFields.title}
                                    disabled={loading}
                                    required
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Name")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="name" 
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter name")}
                                    value={formFields.name}
                                    disabled={loading}
                                    required
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Email")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="username" 
                                    className="form-control"
                                    type="email"
                                    placeholder={props.t("Enter email")}
                                    value={formFields.username}
                                    disabled={loading}
                                    required
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("City")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="city" 
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter city")}
                                    value={formFields.city}
                                    disabled={loading}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Timezone")}
                            </label>
                            <div className="col-md-10">
                                <Select
                                    value={{label: formFields.timezone, value: formFields.timezone}}
                                    onChange={handleTimezoneChange}
                                    options={options}
                                />
                            </div>
                            <AvField type="hidden" name="timezone" value={formFields.timezone} />
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Phone")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="phone" 
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter phone")}
                                    value={formFields.phone}
                                    disabled={loading}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Mobile")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="mobile"
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter mobile")}
                                    value={formFields.mobile}
                                    disabled={loading}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                {props.t("Fortnox Username")}
                            </label>
                            <div className="col-md-10">
                                <AvField
                                    name="fortnoxUsername"
                                    className="form-control"
                                    type="text"
                                    placeholder={props.t("Enter Fornox Username")}
                                    value={formFields.fortnoxUsername}
                                    disabled={loading}
                                />
                            </div>
                        </Row>
                        {uid && <AvField type="hidden" name="id" value={uid} />}
                        <Row className="mb-3">
                            <Col align="center">
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                    onClick={()=> {
                                        if(useModal){
                                            tog_standard();
                                        }
                                        else if(props.setEditMode){
                                            props.setEditMode(false);
                                        } else {                            
                                            // window.location.href = "/settings/users";
                                            history.goBack()
                                        }
                                    }}
                                    disabled={loading}
                                >
                                    {props.t("Cancel")}
                                </Button>
                                {" "}
                                <Button
                                    type="submit"
                                    color="success"
                                    className="btn btn-success w-lg waves-effect waves-light"
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    disabled={loading || editProfileLoading || imageUploading}
                                >
                                    {uid ? props.t("Update") : props.t("Save")}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </AvForm>}
            </Card>
    );
};

TenantUserForm.propTypes = {
};

const mapStateToProps = (state) => {
    return {
        user: state.TenantAdmin.user,
        editProfileLoading: state.Profile.editProfileLoading,
        profileError: state.Profile.error,
        success: state.Profile.success,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUserDetails: (id) => dispatch(getTenantUserDetails(id)),
    onGetUserDetailsReset: () => dispatch(getTenantUserDetailsReset()),
    onEditProfile: (data) => dispatch(editProfile(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TenantUserForm)));
