import {
    DELETE_USER,
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS,
    GET_TENANT,
    GET_TENANT_ADMIN_USERS,
    GET_TENANT_ADMIN_USERS_FAIL,
    GET_TENANT_ADMIN_USERS_RESET,
    GET_TENANT_ADMIN_USERS_SUCCESS,
    GET_TENANT_DETAILS,
    GET_TENANT_DETAILS_FAIL,
    GET_TENANT_DETAILS_RESET,
    GET_TENANT_DETAILS_SUCCESS,
    GET_TENANT_FAIL,
    GET_TENANT_RESET,
    GET_TENANT_SUCCESS,
    REGENERATE_TOKEN,
    REGENERATE_TOKEN_FAIL,
    REGENERATE_TOKEN_SUCCESS,
    GET_TENANT_CUSTOMERS,
    GET_TENANT_CUSTOMERS_FAIL,
    GET_TENANT_CUSTOMERS_RESET,
    GET_TENANT_CUSTOMERS_SUCCESS,
} from "./actionTypes";

export const getTenants = () => ({
    type: GET_TENANT
});

export const getTenantsSuccess = (payload) => ({
    type: GET_TENANT_SUCCESS,
    payload: payload.data
});

export const getTenantsFail = (error) => ({
    type: GET_TENANT_FAIL,
    payload: error
});

export const getTenantsReset = (error) => ({
    type: GET_TENANT_RESET,
    payload: error
});

export const getTenantCustomers = (tenantId, filter) => ({
    type: GET_TENANT_CUSTOMERS,
    tenantId,
    filter
});

export const getTenantCustomersSuccess = (payload) => ({
    type: GET_TENANT_CUSTOMERS_SUCCESS,
    payload: payload.data
});

export const getTenantCustomersFail = (error) => ({
    type: GET_TENANT_CUSTOMERS_FAIL,
    payload: error
});

export const getTenantCustomersReset = (error) => ({
    type: GET_TENANT_CUSTOMERS_RESET,
    payload: error
});

export const getTenantDetails = (id) => ({
    type: GET_TENANT_DETAILS,
    id
})

export const getTenantDetailsSuccess = (payload) => ({
    type: GET_TENANT_DETAILS_SUCCESS,
    payload: payload.data
})

export const getTenantDetailsFail = (error) => ({
    type: GET_TENANT_DETAILS_FAIL,
    payload: error
})

export const getTenantDetailsReset = () => ({
    type: GET_TENANT_DETAILS_RESET
})

export const getTenantAdmins = (tenantId, filter) => ({
    type: GET_TENANT_ADMIN_USERS,
    tenantId,
    filter
});

export const getTenantAdminsSuccess = (users) => {
    return {
        type: GET_TENANT_ADMIN_USERS_SUCCESS,
        payload: users.data,
    };
};

export const getTenantAdminsFail = (error) => ({
    type: GET_TENANT_ADMIN_USERS_FAIL,
    payload: error,
});

export const getTenantAdminsReset = () => ({
    type: GET_TENANT_ADMIN_USERS_RESET
});

export const getRegenerateToken = (userId) => ({
    type: REGENERATE_TOKEN,
    userId
})

export const getRegenerateTokenSuccess = (response) => {
    return {
        type: REGENERATE_TOKEN_SUCCESS,
        payload: {
            status: response.status === 200
        }
    }
}

export const getRegenerateTokenFail = (error) => ({
    type: REGENERATE_TOKEN_FAIL,
    payload: error
})

export const deleteUser = (userId) => ({
    type: DELETE_USER,
    userId
})

export const deleteUserSuccess = (response) => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: {
            status: response.status === 200
        }
    }
}

export const deleteUserFail = (error) => ({
    type: DELETE_USER_FAIL,
    payload: error
})

export const tenantsActionTypes = {
    GET_TENANT,
}
