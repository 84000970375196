import React, {useEffect, useState} from "react"
import {Col, Container, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap"
import {Link, withRouter} from "react-router-dom";
import {isEmpty, map} from "lodash";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import {Search} from "react-bootstrap-table2-toolkit";
import PropTypes from "prop-types";
import {getProducts, getSubCategoryList} from "../../store/e-commerce/actions";
import {connect} from "react-redux";
import {Tooltip} from "@material-ui/core";
import Loader from "../../components/Common/Loader";

const Home = (props) => {
    const {products,
        history,
        onGetProducts,
        metaInfo,} = props;

    const [productList, setProductList] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(5);
    const [loading, setLoading] = useState(true);
    const [selectCategory, setSelectedCategory] = useState(null);
    const [sizePerPage, setSizePerPage] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [brandSettings, setBrandSettings] = useState(null)

    const {SearchBar} = Search

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
        onGetProducts(0, sizePerPage);
    }, []);

    useEffect(() => {
        if (!isEmpty(products)) {
            setProductList(products);
        }
        setLoading(false);
    }, [products]);

    useEffect(() => {
        setTotalPage(metaInfo.totalPage)
        setTotalItems(metaInfo.totalItems);
    }, [metaInfo])


    const handlePageClick = (page) => {
        setPage(page);
        onGetProducts((page -1 ), sizePerPage);
    };

    const getTitle = (name) => {
        return name.length > 94 ? name.substr(0, 91) + "..." : name;
    }

  return (
      <React.Fragment>
          <div className="page-content">
              <Container fluid>

                  <Loader loading={loading} title="Articles" />

                  <Row>
                      <Col lg="12" xl="12">
                          <div>
                              <Row className="min-height-400 vh">
                                  {(productList && productList.length === 0) &&
                                  <h6 align="center" className="margin-top-10 font-color-grey">No articles for this category</h6>
                                  }

                                  {!isEmpty(productList) && productList.map((product, key) => (
                                      <Col xl="3" sm="6" key={"_col_" + key}>
                                          <div
                                              className="product-box"
                                              onClick={() =>
                                                  history.push(
                                                      `/customer/articles/${product.articleNumber}`
                                                  )
                                              }>
                                              <div className="product-img pt-4 px-4">
                                                  <img
                                                      src={product.articleImages.length > 0 ? BASE_IMAGE_URL + "/" + product.articleImages[0].media.s3Key : articlePlaceholderImg}
                                                      alt=""
                                                      className="img-fluid mx-auto height-100 d-block"
                                                  />
                                              </div>

                                              <div className="text-center product-content p-4">
                                                  <Tooltip title={product.displayName ? product.displayName : product.description}>
                                                      <h5 className="mb-1 article-title">
                                                          <Link
                                                              to={
                                                                  `/customer/articles/${product.articleNumber}`
                                                              }
                                                              className="text-dark"
                                                          >
                                                              {getTitle(product.displayName ? product.displayName : product.description)}
                                                          </Link>
                                                      </h5>
                                                  </Tooltip>
                                                  <p className="text-muted font-size-13">
                                                      {"Quantity In Stock: "}
                                                      {product.quantityInStock}
                                                  </p>

                                                  <h5 className="mt-3 mb-0">
                                                            <span className="text-muted me-2">
                                                              <span>${product.purchasePrice}</span>
                                                            </span>
                                                  </h5>
                                              </div>
                                          </div>
                                      </Col>
                                  ))}
                              </Row>
                              {(productList && productList.length > 0) &&
                              <Row className="mt-4">
                                  <Col sm="6">
                                      <div>
                                          <p className="mb-sm-0">Page {metaInfo.page} of {metaInfo.totalPages}</p>
                                      </div>
                                  </Col>
                                  <Col sm="6">
                                      <div className="float-sm-end">
                                          <Pagination className="pagination pagination-rounded mb-sm-0">
                                              <PaginationItem disabled={page === 1}>
                                                  <PaginationLink
                                                      previous
                                                      to="#"
                                                      onClick={() => handlePageClick(page - 1)}
                                                  />
                                              </PaginationItem>
                                              {map(Array(metaInfo.totalPages), (item, i) => (
                                                  <PaginationItem
                                                      active={i + 1 === page}
                                                      key={"_pagination_" + i}
                                                  >
                                                      <PaginationLink
                                                          onClick={() => handlePageClick(i + 1)}
                                                          to="#"
                                                      >
                                                          {i + 1}
                                                      </PaginationLink>
                                                  </PaginationItem>
                                              ))}
                                              <PaginationItem disabled={page === totalPage}>
                                                  <PaginationLink
                                                      next
                                                      to="#"
                                                      onClick={() => handlePageClick(page + 1)}
                                                  />
                                              </PaginationItem>
                                          </Pagination>
                                      </div>
                                  </Col>
                              </Row>}
                          </div>
                      </Col>
                  </Row>
              </Container>
          </div>
      </React.Fragment>
  )
}

Home.propTypes = {
    products: PropTypes.array,
    history: PropTypes.object,
    onGetProducts: PropTypes.func,
    metaInfo: PropTypes.object,
    onGetSubCategoryList: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        products: state.ecommerce.products.data,
        category: state.ecommerce.subCategoryList,
        metaInfo: {
            page: state.ecommerce.products.currentPage + 1,
            totalPages: state.ecommerce.products.totalPages,
            totalItems: state.ecommerce.products.totalItems
        },
    }
};

const mapDispatchToProps = (dispatch) => ({
    onGetProducts: (page, pageSize) => dispatch(getProducts(page, pageSize, true)),
    onGetSubCategoryList: id => dispatch(getSubCategoryList(id))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Home));
