import { uiActionTypes } from './actionTypes';

export const startAction = (name, params) => ({
    type: uiActionTypes.START_ACTION,
    payload: {
      action: {
        name,
        params
      }
    }
  });
  
  export const stopAction = name => ({
    type: uiActionTypes.STOP_ACTION,
    payload: { name }
  });