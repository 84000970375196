import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getLanguageList} from "../../store/language/actions";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {LANGUAGE_MASTER_LIST} from "../../util/constants";
import { orderBy } from "lodash";

const LanguageList = (props) => {
    const {languages, onGetLanguageList, loading, error} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [search, setSearch] = useState("");
    const [langOpt, setLangOpt] = useState("");
    const history = useHistory();
    const [modal_standard, setmodal_standard] = useState(false)
    const [languageList, setLanguageList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetLanguageList();
    }, [])

    useEffect(() => {
        setLanguageList(languages);
    }, [languages]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setLanguageList(orderBy(languageList, o => {
            if(column === 'name') return getLanguageName(o)?.toLowerCase();
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const getDatatableData = () => {
        const rows = languageList?.filter(row => row.toLowerCase().includes(search?.toLowerCase()))
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    autoWidth: false,
                    width: "70%"
                }
            ],
            rows: rows?.map((item, key) => ({
                name: getLanguageName(item),
                clickEvent: function () {
                    handleOpen(item)
                },
            }))
        }
        return data;
    }

    const getLanguageName = (code) => {
        for (let i = 0; i < LANGUAGE_MASTER_LIST.length; i++) {
            if (LANGUAGE_MASTER_LIST[i].key == code) {
                return LANGUAGE_MASTER_LIST[i].value;
            }
        }
        return code;
    }

    const handleOpen = (language) => {
        history.push(`/languages/labels/${language}`)
    }

    const handleAdd = () => {
        tog_standard();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        title={props.t("Translations")}
                        breadcrumbs={[{title: props.t("Language"), link: "#"}]}
                    />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Languages")}
                                        buttons={[{
                                            text: props.t("Add Language"),
                                            onClick: handleAdd,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Languages"/>
                                {!loading &&
                                    <CardBody>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {error}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            searching={true}
                                            // onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Language")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Language")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" onChange={(e)=> setLangOpt(e.target.value)}>
                                <option value={""}>{props.t("Choose Language")}</option>
                                {LANGUAGE_MASTER_LIST.map(function(lang){
                                   return <option value={lang.key}>{lang.value}</option>
                                })}
                            </select>
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => handleOpen(langOpt)}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Setup")}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );

}


LanguageList.propTypes = {
    languages: PropTypes.array,
    onGetLanguageList: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        languages: state.Language.languageList,
        error: state.Language.languageListError,
        loading: state.Language.languageListLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onGetLanguageList: () => dispatch(getLanguageList()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(LanguageList)));
