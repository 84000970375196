import {
    GET_ALL_USERS,
    GET_ALL_USERS_FAIL,
    GET_ALL_USERS_RESET,
    GET_ALL_USERS_SUCCESS, GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESS
} from "./actionTypes";


export const getAllUsers = (filter) => ({
    type: GET_ALL_USERS,
    filter
});

export const getAllUsersSuccess = (users) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: users.data,
    };
};

export const getAllUsersFail = (error) => ({
    type: GET_ALL_USERS_FAIL,
    payload: error,
});

export const getAllUsersReset = () => ({
    type: GET_ALL_USERS_RESET
});

export const getUser = (tenantId, customerId, roles) => ({
    type: GET_USERS,
    data: {
        roles: roles,
        tenantId : tenantId,
        customerId: customerId
    }
});

export const getUserSuccess = (users) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: users.data,
    };
};

export const getUserFail = (error) => ({
    type: GET_USERS_FAIL,
    payload: error,
});
