import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getProjectTemplate} from "../../store/project-template/actions";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {API_ERR_MSG} from "../../util/constants";
import {MDBDataTable} from "mdbreact";
import React, {useEffect, useState} from "react";
import {post} from "../../helpers/api_helper";
import { orderBy } from "lodash";


const ProjectTemplateList = (props) => {
    let {projectTemplates, onGetProjectTemplates, loading, error} = props;

    const history = useHistory();
    const [search, setSearch] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [name, setName] = useState("");
    const [editItem, setEditItem] = useState(null);
    const [projectTemplateList, setProjectTemplateList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetProjectTemplates();
    }, []);

    useEffect(() => {
        if (projectTemplates) {
            setProjectTemplateList(projectTemplates);
        }
    }, [projectTemplates]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setProjectTemplateList(orderBy(projectTemplateList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt",
                    sort: "asc",
                    autoWidth: true,
                },
            ],
            rows: projectTemplateList?.map((item, key) => ({
                name: item.name,
                createdAt: new Date(item.createdAt).toLocaleString(),
                clickEvent: function () {
                    openContent(item);
                },
            })),
        };
        return data;
    };

    const openContent = (item) => {
        history.push(`/project-template/${item.id}`);
    };

    const handleSave = async () => {
        let response = await post(
            `/api/project-template`,
            editItem ? { id: editItem.id, name: name } : { name: name }
        );
        setName("");
        setEditItem(null);
        tog_standard();
        onGetProjectTemplates();
    };


    return (<>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        title={props.t("Templates")}
                        breadcrumbs={[{ title: props.t("Products"), link: "#" }]}
                    />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Templates")}
                                        buttons={[{
                                            text: props.t("Add New Template"),
                                            onClick: tog_standard,
                                        }]}
                                    />
                                </CardHeader>

                                <Loader loading={loading} title="Tabs" />
                                {!loading &&
                                    <CardBody>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {props.t(API_ERR_MSG)}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            // onSearch={(text) => console.log(text)}
                                            searching={true}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Add Template
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter field label")}
                                value={name}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={handleSave}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
        </>
    )
}


ProjectTemplateList.prototype = {
    projectTemplates: PropTypes.array,
    onGetProjectTemplates: PropTypes.func,
}


const mapStateToProps = (state) => {
    return {
        projectTemplates: state.ProjectTemplate.projectTemplates,
        loading: state.ProjectTemplate.projectTemplatesLoading,
        error: state.ProjectTemplate.projectTemplatesError
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetProjectTemplates: () => dispatch(getProjectTemplate())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectTemplateList)));
