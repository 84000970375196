import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Form, Input, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getGlobalSearch, getGlobalSearchReset} from "../../store/e-commerce/actions";
import queryString from "query-string";
import {MDBDataTable} from "mdbreact";
import CategoryRibbon from "../../components/Common/CategoryRibbon";
import Loader from "../../components/Common/Loader";
import {debounce} from "../../util/helperFunctions";

const Search = (props) => {
  let { searchResult, globalSearchLoading, onGetSearch, onGetSearchReset } = props;
  const [searchValue, setSearchValue] = useState();
  const [brandSettings, setBrandSettings] = useState(null)
  const history = useHistory();
  let user = JSON.parse(localStorage.getItem("authUser"));
  let params = queryString.parse(props.location.search);

  useEffect(() => {
    setBrandSettings(user.brandConfig);
  }, [])

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(obj?.brandConfig);
    let params = queryString.parse(props.location.search);
    if (params.value) {
      setSearchValue(params.value);
      onGetSearch(params.value);
    }

    return () => {
      onGetSearchReset();
    }
  }, [params.value]);

  const handleSearch = () => {
    if(searchValue && searchValue.trim() !== ''){
      onGetSearch(searchValue);
    }
  };

  const getDatatableData = () => {
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "description",
          sort: "asc",
          width: 300,
        },
        {
          label: props.t("Article Number"),
          field: "articleNumber",
          sort: "asc",
          width: 100,
        },
        {
          label: props.t("Category"),
          field: "category",
          sort: "asc",
          width: 200,
        },
        {
          label: props.t("Disposable Quantity"),
          field: "disposableQuantity",
          sort: "asc",
          width: 100,
        },
        {
          label: props.t("Reserved Quantity"),
          field: "reservedQuantity",
          sort: "asc",
          width: 100,
        },
        {
          label: props.t("Published"),
          field: "status",
          width: 200,
        },
      ],
      rows: searchResult?.articleList?.map((item, key) => ({
        description: <div onClick={() => handleOpen(item)}>{item.displayName ? item.displayName : item.description} &nbsp;&nbsp; {["MANUAL", "DYNAMIC"].includes(item.articlePackageType) && <label className="badge bg-soft-info bg-info p-1">{props.t("Package")}</label>}</div>,
        articleNumber: item.articleNumber,
        category: <CategoryRibbon categoryList={item.categories} />,
        disposableQuantity: item.disposableQuantity,
        reservedQuantity: item.reservedQuantity,
        status:
          item.publish === true ? (
            <div className="product-ribbon badge bg-soft-success bg-success">
              {" "}
              {props.t("Published")}{" "}
            </div>
          ) : (
            <div className="product-ribbon badge bg-soft-warning bg-warning">
              {" "}
              {props.t("Unpublished")}
            </div>
          ),
        clickEvent: function () {
          handleOpen(item);
        },
      })),
    };
    return data;
  };

  const handleOpen = (item) => {
    if(user?.customerId){
      /*if(item.categories && item.categories.length > 0){
        history.push(`/customer/categories/${item.categories[0]?.id}/articles`);
      } else {
        history.push(`/articles/${item.id}/view`);
      }*/
      history.push(`/customer/articles/${item.articleNumber}`);
    } else {
      history.push(`/articles/${item.articleNumber}`);
    }
  };

  const handleClearSearch = (e) => {
      if (e.key === 'Backspace' && searchValue.length == 0) {
          onGetSearch(null);
      }
  }

  const processChange = debounce((arg) => {
      setSearchValue(arg?.target?.value)
      onGetSearch(arg?.target?.value);
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                <Form
                  className="app-search d-none d-lg-block"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <div className="row">
                    <div className="col-10">
                      <input
                        className="form-control form-control-lg border"
                        type="text"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder={props.t("Search") + "..."}
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        color="primary"
                        className="w-md btn-lg mt-sm-10 "
                        onClick={handleSearch}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        disabled={!searchValue || searchValue.trim() === ''}
                      >
                        {props.t("Search") + "..."}
                      </Button>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Row>
          <Loader loading={globalSearchLoading} title="Search Results" />
          <Row>
              <Col className="col-12 article-table mt-4 mb-2">
                  <div className="form-inline float-md-end searchWidth">
                      <div className="search-box ml-0">
                          <div className="position-relative ">
                              <Form
                                  className="app-search ml-0   d-lg-block "
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                  }}
                              >
                                  <Input
                                      type="text"
                                      key={1}
                                      id="article-search-input"
                                      className="form-control bg-light border-light rounded toolbar-search"
                                      placeholder={props.t("Search") + "..."}
                                      onChange={processChange}
                                      onKeyUp={(e) => handleClearSearch(e)}
                                  />
                                  <i className="mdi mdi-magnify search-icon"></i>
                              </Form>
                          </div>
                      </div>
                  </div>
              </Col>
          </Row>
          {!globalSearchLoading && searchResult && searchResult?.articleList && (
            <Row>
              <Card>
                <CardBody>
              <h4>{props.t("Articles")}</h4>
                  <MDBDataTable
                    responsive
                    barReverse={true}
                    hover
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    // searchLabel={props.t("Search") + "..."}
                    paging={false}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    sortable={true}
                    searching={false}
                    sortRows={[
                      "created",
                      "description",
                      "articleNumber",
                      "category",
                    ]}
                    // onSearch={(text) => console.log(text)}
                    noRecordsFoundLabel={props.t("No matching records found")}
                  />
                </CardBody>
              </Card>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

Search.prototype = {
  searchResult: PropTypes.object,
  globalSearchLoading: PropTypes.object,
  onGetSearch: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    searchResult: state.ecommerce.globalSearch?.data,
    globalSearchLoading: state.ecommerce.globalSearchLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetSearch: (search) => dispatch(getGlobalSearch(search)),
  onGetSearchReset: () => dispatch(getGlobalSearchReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Search)));
