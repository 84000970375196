import {
    GET_MANUFACTURER,
    GET_MANUFACTURER_DETAILS,
    GET_MANUFACTURER_DETAILS_ERROR,
    GET_MANUFACTURER_DETAILS_SUCCESS,
    GET_MANUFACTURER_FAIL,
    GET_MANUFACTURER_SUCCESS
} from "./actionTypes";


export const getManufacturer = () => ({
    type : GET_MANUFACTURER
})

export const getManufacturerSuccess = (payload) => ({
    type: GET_MANUFACTURER_SUCCESS,
    payload: payload.data
})

export const getManufacturerFail = (error) => ({
    type: GET_MANUFACTURER_FAIL,
    payload: error
})

export const getManufacturerDetails = (id) => ({
    type : GET_MANUFACTURER_DETAILS,
    id
})

export const getManufacturerDetailsSuccess = (payload) => ({
    type: GET_MANUFACTURER_DETAILS_SUCCESS,
    payload: payload.data
})

export const getManufacturerDetailsError = (error) => ({
    type: GET_MANUFACTURER_DETAILS_ERROR,
    payload: error
})
