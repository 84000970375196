import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import store from "../../store";
import {userDetailContext} from "./TenantUsers";
import {useHistory} from "react-router-dom";
import {TENANT_ACTIVATE_USER, TENANT_ADMIN_DELETE_USER, TENANT_ADMIN_REGENERATE_TOKEN} from "../../store/users/tenant/actionTypes";
import DeleteModal from "../../components/Common/DeleteModal";
import OverridePwdModal from "../../components/Common/OverridePwdModal";

const TenantUserActions = (props) => {
    const history = useHistory();
    var userId = React.useContext(userDetailContext);
    var {user} = props;
    const [uid, setuid] = useState("");

    useEffect(() => {
        setuid(userId ? userId : props.userId);
    }, [])

    const handleRegenerateClick = () =>
        store.dispatch({ type: TENANT_ADMIN_REGENERATE_TOKEN, userId: uid });

    const handleDeleteUser = async () => {
        await store.dispatch({ type: TENANT_ADMIN_DELETE_USER, userId: uid })
        tog_standard()
    }

    const [modal_standard, setmodal_standard] = useState(false);
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);

    const handleActiveUser = async () => {
        store.dispatch({type:TENANT_ACTIVATE_USER, userId: uid});
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleEdit = async () => {
        if(props.setEditMode && props.setSelectedTenantUser){
            await props.setSelectedTenantUser(uid)
            props.setEditMode(true);
            return;
        }
        history.push(`/settings/users/edit/${uid}`);
    }

    return (
        <React.Fragment>
            {user.role != "ROLE_CONTACT_USER" && <>
                <label className="link" onClick={handleRegenerateClick}>
                    {props.t("Send Activation Email")}
                </label> | {" "}
            </>}

            <label className="link" onClick={handleEdit}>
                {props.t("Edit")}
            </label>{" "}
            |{" "}

            {user.enabled == true && <label className="link" onClick={tog_standard}>
                {props.t("De-active")}
            </label>}
            {user.enabled == false && <label className="link" onClick={handleActiveUser}>
                {props.t("Active")}
            </label>}{" "}
            |{" "}
            <label className="link" onClick={togglePwdModal}>
                {props.t("Override Password")}
            </label>
            <DeleteModal
                deleteCallback={() => handleDeleteUser()}
                element={"User"}
                t={(text) => props.t(text)}
                showDeleteModal={modal_standard}
                toggleModal={() => tog_standard()}
            />
            <OverridePwdModal
                t={(text) => props.t(text)}
                showOverridePwdModal={showOverridePwdModal}
                toggleModal={() => togglePwdModal()}
                userId={uid}
            />
        </React.Fragment>
    );
};

export default withTranslation()(TenantUserActions);
