import React from "react";
import WorkLogEdit from "./WorkLogEdit";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Modal} from "reactstrap";


const TimeslipModal = (props) => {

    const {
        modal_standard,
        setmodal_standard,
        tog_standard,
        avoidRedirect,
        realodTaskWorkLog
    } = props;

    return (
        <>
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-task-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("New Timeslip")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        setmodal_standard(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <WorkLogEdit 
                    cid={null} 
                    workLog={null} 
                    showForm={modal_standard} 
                    setShowForm={setmodal_standard} 
                    taskId={null} 
                    userDropdown={null} 
                    realodTaskWorkLog={() => realodTaskWorkLog()}
                    avoidRedirect={avoidRedirect}
                />
            </div>
        </Modal>
        </>
    )
}


const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TimeslipModal)));
