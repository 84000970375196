import PropTypes from 'prop-types'
import React, {useEffect} from "react"

import {Alert, Col, Container, Row} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation"

// actions
import {getBrand, loginUser} from "../../store/actions"

// import images
import {withTranslation} from "react-i18next";
import {LOGO_URL} from "../../helpers/url_helper";

//Import config

const Login = (props) => {
    // handleValidSubmit
    let {brand, getBrand, loginUser} = props;
    const handleValidSubmit = (event, values) => {
        loginUser(values, props.history)
    }

    useEffect(() => {
        getBrand(window.location.hostname);
    }, [])



    useEffect(() => {
        document.body.className = "authentication-bg";
        if(brand?.primaryBackgroundColor){
            document.body.style = `background-color: ${brand?.primaryBackgroundColor}`;
        }
        // remove classname when component will unmount
        return function cleanup() {
            document.body.className = "";
        };
    }, [brand]);

    return (
        <React.Fragment>
            <div className="account-pages">
                <Container className="align-center">
                    <Row className="align-items-center justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <div className="p-2">
                                <Link to="/"> {/** className="mb-5 d-block auth-logo" **/}
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" width="80%" className="logo logo-dark"/>
                                    <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" width="80%" className="logo logo-light"/>
                                </Link>
                            </div>
                            <div className="p-2 mt-1">
                                <h3>{props.t("Sign In")} </h3>
                                {brand && <p>{props.t(`Welcome to ${brand?.tenantName ? brand.tenantName : 'Lightab'}`)}</p>}
                            </div>
                            <div className="p-2">
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                >
                                    {props.error && typeof props.error === "string" ? (
                                        <Alert color="danger">{props.error}</Alert>
                                    ) : null}

                                    <AvField
                                        name="domain"
                                        type="hidden"
                                        value={window.location.hostname}
                                        required
                                    />

                                    <div className="mb-3">
                                        <div className={"login-form-text-icon"}>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon mx-2" data-component-name="Icon-C"
                                                 data-name="Hero--HeroEnvelope">
                                                <path
                                                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <AvField
                                            name="email"
                                            /*label={props.t("Email")}*/
                                            className="form-control login-form-control"
                                            placeholder={props.t("Username")}
                                            type="email"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <div className={"login-form-text-icon"}>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-icon mx-2" data-component-name="Icon-C"
                                                 data-name="Hero--HeroKey">
                                                <path
                                                    d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                                                    stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <AvField
                                            name="password"
                                            className="form-control login-form-control"
                                            /*label={props.t("Password")}*/
                                            type="password"
                                            required
                                            placeholder={props.t("Password")}
                                        />
                                    </div>


                                    <div className="mt-3">
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="submit"
                                            style={{backgroundColor: brand?.primaryBtnColor, borderColor: brand?.primaryBtnColor, fontWeight:600, lineHeight:2}}
                                        >
                                            {props.t("Sign In")}
                                        </button>
                                    </div>
                                </AvForm>

                            </div>
                            <div className="mt-1 text-center">
                                <Link to="/forgot-password">{props.t("Forgot Password") + "?"}</Link>
                            </div>
                            <div className="mt-5 text-center">
                                <p>© {new Date().getFullYear()} {props.t("Flowsense")}. <a href="http://flowsense.se/" target="_blank">{props.t("Design & Develop by Flowsense")}</a>
                                </p>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {error, brand} = state.Login
    return {error, brand}
}

const mapDispatchToProps = (dispatch) => ({
    loginUser: (user, history) => dispatch(loginUser(user, history)),
    getBrand: (domain) => dispatch(getBrand(domain))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Login)))
)

Login.propTypes = {
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func
}
