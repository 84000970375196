import React from "react";
import {withTranslation} from "react-i18next";
import FortnoxSystemConfig from "./FortnoxSystemConfig";
import FortnoxTenantConfig from "./FortnoxTenantConfig";


const FortnoxConfig = (props) => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    if(user?.roles?.includes("ROLE_SUPER_ADMIN")) {
        return <FortnoxSystemConfig {...props}/>
    } else {
        return <FortnoxTenantConfig {...props}/>
    }
}

export default withTranslation()(FortnoxConfig)