import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next"
import {isEmpty} from "lodash";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getTenantAdmins, getTenantDetails, getTenantDetailsReset} from "../../store/tenant/actions"
import {RESET_DELETE_USER, RESET_REGENERATE_TOKEN, tenantsActionTypes} from "../../store/tenant/actionTypes";
import classnames from "classnames"
import TenantUserManagement from "./TenantUserManagement";
import {del, get, put} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import {checkIfLoading} from "../../store/selector";
import Loader from "../../components/Common/Loader";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import avatar from "../../assets/images/logo-placeholder.jpg";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";

export const userDetailContext = React.createContext(null)


const TenantDetails = (props) => {
    const id = useParams().tenantId;
    const {tenant, onGetTenantDetails, users, onGetUsers, tokenRegenerate, userDeleted, onResetRegenerateToken, onResetDeleteUser, tenantDetailsError, loading, onGetTenantDetailsReset } = props
    const [TenantList, setTenantList] = useState([])
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [activeTabJustify, setactiveTabJustify] = useState("1")
    const history = useHistory();
    const [showDeleteTenantsModal, setShowDeleteTenantsModal] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [modules, setModules] = useState(null);

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
        return () => {
            onGetTenantDetailsReset();
        }
    }, [])

    useEffect(() => {
        onGetTenantDetails(id);
        onGetUsers(id);
        getTenantModules();
    }, [id])

    useEffect(() => {
        if (!isEmpty(users)) setTenantList(users)
    }, [users])

    useEffect(() => {
      if(tenantDetailsError){
        setErrorMessage(tenantDetailsError)
        setTimeout(() => {onGetTenantDetailsReset()}, 5000);
        setTimeout(() => {setErrorMessage('')}, 5000);
      }
    }, [tenantDetailsError])

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
            setactiveTabJustify(tab)
        }
    }

    useEffect(() => {
        if (tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."))
            setTimeout(() => {
                setSuccessMessage("");
                onResetRegenerateToken()
            }, 3000)
        } else if (tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"))
            setTimeout(() => {
                setErrorMessage("");
                onResetRegenerateToken()
            }, 3000)
        }
    }, [tokenRegenerate])

    useEffect(() => {
        if (userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User delete successfully."))
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser()
            }, 3000)
            onGetUsers()
        } else if (userDeleted) {
            setErrorMessage(props.t("Error deleting user."))
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser()
            }, 3000)
        }
    }, [userDeleted])

    function toggleDeleteTenantModal() {
        setShowDeleteTenantsModal(!showDeleteTenantsModal);
    }

    const handleEditTenant = () => {
        history.push(`/tenants/edit/${users[0].id}`)
    }

    const handleDeleteTenant = () => {
        let response = del(`/api/tenant/${tenant?.id}`);
        window.location.href = "/tenants";
    }

    const handleBack = () => {
        history.push("/tenants")
    }

    const getTenantModules = async () => {
        let response = await get(`/api/tenant/modules/${id}`);
        if(response.status === 200){
            setModules(response.data);
        }
    }

    const toggleModule = async(module) => {
        let response = await put(`/api/tenant/feature/${id}/${module}`)
        if(response.status === 200){
            setModules(response.data);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Tenants"), link: "/tenants"}]}
                        title={"Tenant Details"}
                        canEdit={handleEditTenant}
                        canDelete={toggleDeleteTenantModal}
                    />
                     <Loader loading={loading} title="Tenant Details" />
                    {!loading && (<>

                    {successMessage && <Row>
                        <Alert color="success">
                            {successMessage}
                        </Alert>
                    </Row>}
                    <Card>
                        <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                            <TableToolbar
                                title={props.t("Tenant Details")}
                            />
                        </CardHeader>
                    </Card>
                    <Row>
                        <Col xl="4">
                            <Card className="card">
                                <CardBody>
                                    {tenantDetailsError && <Row>
                                        <Alert color="danger">
                                            {tenantDetailsError}
                                        </Alert>
                                    </Row>}
                                    <div className="text-center">
                                        <div>
                                            <img
                                                src={tenant?.brandConfig == null ? avatar : BASE_IMAGE_URL + "/" + tenant?.brandConfig?.brandLogo}
                                                className="img-fluid light-tab-logo"
                                                style={{marginLeft: 0}}
                                            />
                                        </div>
                                        <h5 className="mt-3 mb-1">{tenant?.name}</h5>
                                    </div>

                                    <hr className="my-4"/>

                                    <div className="text-muted">
                                        <div className="table-responsive mt-4">
                                            {props.tenant?.domain && <div className="mt-4">
                                                <p className="mb-1">{props.t("Domain")}</p>
                                                <h5 className="font-size-16">{tenant?.domain}</h5>
                                            </div>}

                                            {props.tenant?.key && <div className="mt-4">
                                                <p className="mb-1">{props.t("Key")}</p>
                                                <h5 className="font-size-16">{tenant?.key}</h5>
                                            </div>}

                                            {props.tenant?.fortnoxTenantId && <div className="mt-4">
                                                <p className="mb-1">{props.t("Fortnox Tenant Id")}</p>
                                                <h5 className="font-size-16">{tenant?.fortnoxTenantId}</h5>
                                            </div>}

                                            {props.tenant?.organizationNumber && <div className="mt-4">
                                                <p className="mb-1">{props.t("Organization Number")}</p>
                                                <h5 className="font-size-16">{tenant?.organizationNumber}</h5>
                                            </div>}

                                            {props.tenant?.timezone && <div className="mt-4">
                                                <p className="mb-1">{props.t("Timezone")}</p>
                                                <h5 className="font-size-16">{tenant?.timezone}</h5>
                                            </div>}

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8">
                            <Card className="mb-0">
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                profiletoggle('1');
                                            }}
                                        >
                                            <i className="uil uil-users-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Users")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => {
                                                profiletoggle('2');
                                            }}
                                        >
                                            <i className="uil uil-cog font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Modules")}</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-4">
                                    <TabPane tabId="1">
                                        <TenantUserManagement tenantId={id} />
                                    </TabPane>
                                    <TabPane tabId="2">

                                        {modules?.map(m => {
                                            return (<>
                                                <Row className="margin-top-10-px">
                                                    <div className="col-xl-2">
                                                        <label>{m?.module}</label>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-check form-switch form-switch-lg mb-3">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customSwitchsizelg"
                                                                style={m?.enabled ? {backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor} : {}}
                                                                checked={m?.enabled}
                                                                onChange={e => toggleModule(m?.module)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </>)
                                        })}
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col>
                    </Row>
                    </>)}
                </Container>
            </div>
            <DeleteModal
                deleteCallback={() => handleDeleteTenant()}
                element={"Tenant"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteTenantsModal}
                toggleModal={() => toggleDeleteTenantModal()}
            />
        </React.Fragment>
    );
};

TenantDetails.propTypes = {
    tenant: PropTypes.object,
    onGetTenantDetails: PropTypes.func,
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
    loading: PropTypes.bool,
    tenantDetailsError: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        tenant: state.tenant.tenantDetails,
        users: state.tenant.tenantAdmins,
        tokenRegenerate: state.tenant.tokenRegenerate,
        userDeleted: state.tenant.userDeleted,
        loading: checkIfLoading(state, tenantsActionTypes.GET_TENANT_DETAILS),
        tenantDetailsError: state.tenant.tenantDetailsError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetUsers: id => dispatch(getTenantAdmins(id)),
    onGetTenantDetails: id => dispatch(getTenantDetails(id)),
    onResetRegenerateToken: () => dispatch({
        type: RESET_REGENERATE_TOKEN,
    }),
    onResetDeleteUser: () => dispatch({
        type: RESET_DELETE_USER,
    }),
    onGetTenantDetailsReset: () => dispatch(getTenantDetailsReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TenantDetails)));
