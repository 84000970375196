export const GET_BRAND_SETTINGS = "GET_BRAND_SETTINGS";
export const GET_BRAND_SETTINGS_SUCCESS = "GET_BRAND_SETTINGS_SUCCESS"
export const GET_BRAND_SETTINGS_FAIL = "GET_BRAND_SETTINGS_FAIL"

export const POST_BRAND_SETTINGS = "POST_BRAND_SETTINGS";
export const POST_BRAND_SETTINGS_SUCCESS = "POST_BRAND_SETTINGS_SUCCESS";
export const POST_BRAND_SETTINGS_FAIL = "POST_BRAND_SETTINGS_FAIL";

export const GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS = "GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS";
export const GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS = "GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS"
export const GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL = "GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL"

export const POST_RESET_BRAND_COLORS = "POST_RESET_BRAND_COLORS";
export const POST_RESET_BRAND_COLORS_SUCCESS = "POST_RESET_BRAND_COLORS_SUCCESS";
export const POST_RESET_BRAND_COLORS_FAIL = "POST_RESET_BRAND_COLORS_FAIL";