import React, {useEffect, useState} from "react";
import {Button, Modal, Row, Table} from "reactstrap";
import PropTypes from "prop-types";
import {del, post} from "../../helpers/api_helper";
import {withTranslation} from "react-i18next";

const ContentItem = props => {
    const {content,onGetContentTypes, button} = props;
    const [modal_standard, setmodal_standard] = useState(false)
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [unit, setUnit] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }


    const handleSave = async () => {
        if(name.length > 0 && type.length > 0){
            let response = await post(`/api/content/${content.id}`, {name: name, contentType: type, unit: unit});
            setName("");
            setType("");
            setUnit("");
            onGetContentTypes();
        }
    }

    const handleDelete = async (id) => {
        let response = await del(`/api/content/${id}`);
        onGetContentTypes();

    }

    const getContentType = (type)=> {
        if(type === "INPUT_FIELD"){
            return props.t("Text")
        } else if (type === "FILE") {
            return props.t("File")
        } else if (type === "NUMBER_FIELD"){
            return props.t("Number")
        } else if(type === "HEADER"){
            return props.t("Header")
        } else if(type === "BOOLEAN"){
            return props.t("Boolean")
        } else if(type === "RANGE" ){
            return props.t("Range")
        }else {
            return props.t("Free Text")
        }
    }

    return (
        <React.Fragment>
            {button &&
                <Button color="primary"
                        onClick={() => {
                            tog_standard()
                        }}
                        data-toggle="modal"
                        data-target="#myModal"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="btn btn-primary btn-sm waves-effect waves-light float-right m-2">
                    {props.t("Add Field")}
                </Button>
            }
            {button == null &&
                <i onClick={() => {
                    tog_standard()
                }}
                   className="uil uil-pen text-primary font-size-18"
                   data-toggle="modal"
                   data-target="#myModal"></i>
            }


            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {content.name}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e)=> setName(e.target.value)}
                                placeholder={props.t("Enter field label")}
                                value={name}
                            />
                        </div>
                    </Row>
                    <Row>
                        <label className="col-md-3 col-form-label">
                            {props.t("Field Type")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" value={type} onChange={(e)=> setType(e.target.value)}>
                                <option value="">{props.t("Select Field Type")}</option>
                                <option value="INPUT_FIELD">{props.t("Text")}</option>
                                <option value="NUMBER_FIELD">{props.t("Number")}</option>
                                <option value="FREE_TEXT">{props.t("Free Text")}</option>
                                <option value="FILE">{props.t("File")}</option>
                                <option value="HEADER">{props.t("Header")}</option>
                                <option value="BOOLEAN">{props.t("Boolean")}</option>
                                <option value="RANGE">{props.t("Range")}</option>
                            </select>
                        </div>
                    </Row>
                    {(type === "NUMBER_FIELD" || type === "RANGE") &&
                    <Row className="mb-3 mt-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Unit")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e)=> setUnit(e.target.value)}
                                placeholder={props.t("Enter unit")}
                                value={unit}
                            />
                        </div>
                    </Row> }
                    <br/>
                    <Row>
                        <div className="col-md-2"></div>
                        <Button color="primary"
                            onClick={handleSave}
                            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                            className="col-md-2 btn btn-primary btn-sm waves-effect waves-light margin-left-10">
                            {props.t("Save")}
                        </Button>
                    </Row>
                    <br/><br/>
                    <div className="table-responsive">
                        <Table className="table mb-0">
                            <tbody>
                            {content?.contentList?.map(function(item, index){
                                return (<tr>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{getContentType(item.contentType)}</td>
                                    <td><i onClick={()=> handleDelete(item.id)} className="uil uil-trash-alt font-size-18 text-danger"></i></td>
                                </tr>)
                            })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard()
                        }}
                        className="btn btn-secondary btn-sm waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}

ContentItem.propTypes = {
    content: PropTypes.object,
}

export default (withTranslation()(ContentItem))
