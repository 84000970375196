import PropTypes from "prop-types";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {getManufacturer} from "../../store/manufacturer/actions";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {connect} from "react-redux";


const ManufacturerList = (props) => {
    const {manufacturerList, manufacturerListLoading, manufacturerListError, onGetManufacturer} = props;

    let user = JSON.parse(localStorage.getItem("authUser"));
    let history = useHistory();
    const [brandSettings, setBrandSettings] = useState(null);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetManufacturer();
    }, [])

    const handleOpen = (manufacturer) => {
        history.push(`/manufacturers/${manufacturer.id}/details`);
    }

    const getDatatableData = () => {
        /*let rows = manufacturerList?.filter((row) =>
            row.name?.toLowerCase().includes(search?.toLowerCase())
        );*/

        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    width: 150,
                }
            ],
            rows: manufacturerList?.map((item, key) => ({
                name: item.name,
                clickEvent: function () {
                    handleOpen(item);
                }
            })),
        };
        return data;
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Manufacturer")}

                                    />
                                </CardHeader>
                                <Loader loading={manufacturerListLoading} title="Manufacturer"/>
                                {!manufacturerListLoading &&
                                    <CardBody>
                                        {manufacturerListError && <Row>
                                            <Alert color="dander">{manufacturerListError}</Alert>
                                        </Row>}
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            searchLabel={props.t("Search") + "..."}
                                            displayEntries={50}
                                            noBottomColumns={true}
                                            searching={true}
                                            entries={50}
                                            entriesOptions={[20, 50, 100, 500]}
                                            data={getDatatableData()}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                        />
                                    </CardBody>
                                }
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

ManufacturerList.propTypes = {
    manufacturerList: PropTypes.array,
    manufacturerListLoading: PropTypes.bool,
    manufacturerListError: PropTypes.object,
    onGetManufacturer: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        manufacturerList: state.Manufacturer.manufacturerList,
        manufacturerListLoading: state.Manufacturer.manufacturerListLoading,
        manufacturerListError: state.Manufacturer.manufacturerListError
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetManufacturer: () => dispatch(getManufacturer())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ManufacturerList)))
