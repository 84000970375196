import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getSubCategoryList, getSubCategoryListReset,} from "../../store/e-commerce/actions";
import {del, get, post, postMultipart} from "../../helpers/api_helper";
import Dropzone from "react-dropzone";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {checkIfLoading} from "../../store/selector";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import Loader from "../../components/Common/Loader";
import {orderBy} from "lodash";

const SubCategoryList = (props) => {
  const {
    category,
    onGetSubCategoryList,
    onGetSubCategoryListReset,
    error,
    loading,
    match: { params },
  } = props;

  const [modal_standard, setmodal_standard] = useState(false);
  const [contentTypeList, setContentTypeList] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [hasSubcategory, setHasSubcategory] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectCategory, setSelectedCategory] = useState(category);
  const [editCategory, setEditCategory] = useState(null);
  const history = useHistory();
  const [removeImage, setRemoveImage] = useState(null);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [search, setSearch] = useState("");
  const [brandSettings, setBrandSettings] = useState(null);
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function toggleDeleteCategoryModal() {
    setShowDeleteCategoryModal(!showDeleteCategoryModal);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    return () => {
      onGetSubCategoryListReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetSubCategoryListReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if (params && params.id) {
      onGetSubCategoryList(params.id);
    }

    return () => {
      onGetSubCategoryListReset();
    };
  }, [params]);

  useEffect(() => {
    if (category) {
      setContentTypeList(category.subCategories);
      setSelectedCategory(category);
    }
  }, [category]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setContentTypeList(orderBy(contentTypeList, o => {
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  const handleSave = async () => {
    let mediaId =
      uploadedFiles && uploadedFiles.id
        ? uploadedFiles.id
        : editCategory && editCategory.media
          ? editCategory.media.id
          : null;
    var data = {
      id: editCategory ? editCategory.id : null,
      name: name,
      description: description,
      media: { id: mediaId },
    };
    if (!mediaId && removeImage) {
      data = { id: editCategory ? editCategory.id : null, name: name };
    }
    let response = await post(`/api/category/${params.id}`, data);
    setName("");
    setDescription("");
    setEditCategory(null);
    setHasSubcategory(false);
    setUploadedFiles(null);
    tog_standard();
    onGetSubCategoryList(params.id);
  };

  const handleDelete = async (id) => {
    let response = await del(`/api/category/${id}`);
    onGetSubCategoryList(params.id);
  };

  const handleAcceptedFiles = async (files) => {
    let formData = new FormData();
    files.map((file) => formData.append("files[]", file));
    let response = await postMultipart("/api/media", formData);
    if (response.status === 200) {
      setUploadedFiles(response.data[0]);
    }
  };

  const handleEdit = () => {
    setEditCategory(category);
    tog_standard();
    setName(category.name);
    setDescription(category.description);
  };

  const handleAdd = () => {
    setEditCategory(null);
    setName("");
    setDescription("");
    tog_standard();
  };

  const handleOpen = (item) => {
    history.push(`/categories/${item.id}/subcategory`);
  };

  const deleteImage = (id) => {
    setRemoveImage(id);
    editCategory.media = null;
  };

  const handleBack = () => {
    if (category.parents == null || category.parents.length === 0) {
      history.push("/categories");
      return;
    }
    history.push(
      `/categories/${category.parents[category.parents.length - 1].id
      }/subcategory`
    );
  };

  const handleDeleteCategory = async () => {
    let response = await del(`/api/category/${category.id}`);
    if (category.parents == null || category.parents.length === 0) {
      history.push("/categories");
      return;
    }
    history.push(
      `/categories/${category.parents[category.parents.length - 1].id
      }/subcategory`
    );
  };

  const handleOrder = async (type, item) => {
    let response = await get(
      `/api/category/${params.id}/order/${type}?id=${item.id}`
    );
    onGetSubCategoryList(params.id);
  };

  const getDatatableData = () => {
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "name",
          sort: "asc",
          autoWidth: true,
        },
        {
          label: props.t("Created Date"),
          field: "created",
          sort: "asc",
          autoWidth: true,
        },
      ],
      rows: contentTypeList?.map((item, key) => ({
        name: <>
                <i
                    className="uil uil-arrow-circle-up text-primary font-size-18"
                    onClick={() => key > 0 && handleOrder("UP", item)}
                ></i>
                <i
                    className="uil uil-arrow-circle-down px-3 text-primary font-size-18"
                    onClick={() =>
                        key < contentTypeList.length - 1 && handleOrder("DOWN", item)
                    }
                ></i>
                <span onClick={() => handleOpen(item)}>{item.name}</span>
              </>,
        created: (
          <span onClick={() => handleOpen(item)}>
            {new Date(item.createdAt).toLocaleString()}
          </span>
        ),
      })),
    };
    return data;
  };

  const getBreadcrumbs = () => {
    let crumbs = [
      { title: props.t("Products"), link: "#" },
      { title: props.t("Categories"), link: "/categories" },
    ];
    let additionalCrumbs = [];
    if (selectCategory != null && selectCategory.parents != null) {
      additionalCrumbs = [
        ...selectCategory?.parents?.map((item, index) => ({
          title: item.name,
          link: `/categories/${item.id}/subcategory`,
        })),
      ];
      crumbs = [...crumbs, ...additionalCrumbs];
    }
    return crumbs;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageToolbarTop
            noBackBtn={true}
            title={selectCategory?.name}
            breadcrumbs={getBreadcrumbs()}
            backBtn={handleBack}
          />
          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                  <TableToolbar
                    title={selectCategory?.name}
                    buttons={[{
                      text: props.t("Add Sub Category"),
                      onClick: handleAdd,
                    }]}

                    canEdit={handleEdit}
                    canDelete={toggleDeleteCategoryModal}
                  />
                </CardHeader>
                <Loader loading={loading} title={props.t("Sub Categories for ") + selectCategory?.name } />
                {!loading && 
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {error}
                    </Alert>
                  }
                  <MDBDataTable
                    responsive
                    barReverse={true}
                    hover
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    noBottomColumns={true}
                    entries={500}
                    entriesOptions={[10, 100, 500]}
                    data={getDatatableData()}
                    // onSearch={(text) => console.log(text)}
                    searching={true}
                    sortRows={[]}
                    onSort={({column, direction}) => {
                        if(sortState && sortState.column == column){
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                    }}
                  />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {editCategory && props.t("Edit Category")}
            {!editCategory && props.t("Add Category")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              {props.t("Name")}
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder={props.t("Enter Category Name")}
                value={name}
              />
            </div>
          </Row>
          {selectCategory?.parents?.length != 0 && <>
          <Row className="mb-3">
            <label className="col-md-3 col-form-label">{props.t("Description")}</label>
            <div className="col-md-9">
              <textarea className="form-control"
                        onInput={(e) => setDescription(e.target.value)}
                        defaultValue={description}
                        value={description}></textarea>
            </div>
          </Row>
          {editCategory && editCategory.media && (
            <Row className="align-items-center delete-category-row">
              <br />
              <br />
              <Col className="col-auto">
                <img
                  data-dz-thumbnail=""
                  height="80"
                  className="avatar-sm rounded bg-light"
                  alt={editCategory.media.name}
                  src={BASE_IMAGE_URL + "/" + editCategory.media.s3Key}
                />
              </Col>
              <Col>
                {editCategory.media.name}
                &nbsp;&nbsp;
              </Col>
              <Col xs={1}>
                <i
                  className="uil uil-trash-alt text-danger cursor-pointer"
                  onClick={() => deleteImage(editCategory.media.id)}
                ></i>
              </Col>
            </Row>
          )}

          <Row className="mb-3 margin-10">
            <br />
            <br />
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted uil uil-cloud-upload" />
                    </div>
                    <h4>{props.t("Drop files here or click to upload")}</h4>
                  </div>
                </div>
              )}
            </Dropzone>
          </Row>
          <label>{uploadedFiles ? uploadedFiles.name : ""}</label>
          </>}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="col-md-2 btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          <Button
            color="primary"
            onClick={handleSave}
            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
            className="col-md-2 btn btn-primary waves-effect waves-light"
          >
            {props.t("Save")}
          </Button>
        </div>
      </Modal>
      <DeleteModal
        deleteCallback={() => handleDeleteCategory()}
        element={"Category"}
        t={(text) => props.t(text)}
        showDeleteModal={showDeleteCategoryModal}
        toggleModal={() => toggleDeleteCategoryModal()}
      />
    </React.Fragment>
  );
};

SubCategoryList.propTypes = {
  category: PropTypes.object,
  onGetSubCategoryList: PropTypes.func,
  onGetSubCategoryListReset: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    category: state.ecommerce.subCategoryList,
    error: state.ecommerce.getSubCategoryListError,
    loading: checkIfLoading(state, ecommerceActionTypes.GET_SUB_CATEGORY_LIST),
    brandSettings: state.BrandSettings
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetSubCategoryList: (id) => dispatch(getSubCategoryList(id)),
  onGetSubCategoryListReset: () => dispatch(getSubCategoryListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SubCategoryList)));
