import {
    GET_TASK_LIST,
    GET_TASK_LIST_FAIL,
    GET_TASK_LIST_RESET,
    GET_TASK_LIST_SUCCESS,
    GET_TASK_COMMENT_LIST,
    GET_TASK_COMMENT_LIST_FAIL,
    GET_TASK_COMMENT_LIST_RESET,
    GET_TASK_COMMENT_LIST_SUCCESS,
    GET_TASK,
    GET_TASK_SUCCESS,
    GET_TASK_FAIL,
    GET_TASK_RESET,
    GET_TASK_WORK_LOG,
    GET_TASK_WORK_LOG_SUCCESS,
    GET_TASK_WORK_LOG_FAIL,
    GET_WORK_LOG_TYPE,
    GET_WORK_LOG_TYPE_SUCCESS,
    GET_WORK_LOG_TYPE_FAIL,
    GET_WORK_LOG_FOR_CUSTOMER,
    GET_WORK_LOG_FOR_CUSTOMER_SUCCESS,
    GET_WORK_LOG_FOR_CUSTOMER_FAIL,
    GET_TASK_BY_PROJECT,
    GET_TASK_BY_PROJECT_SUCCESS,
    GET_TASK_BY_PROJECT_ERROR,
    GET_WORK_LOG_FOR_PROJECT,
    GET_WORK_LOG_FOR_PROJECT_SUCCESS,
    GET_WORK_LOG_FOR_PROJECT_ERROR, GET_WORK_LOG, GET_WORK_LOG_SUCCESS, GET_WORK_LOG_ERROR, GET_TASK_CATEGORIES_FAIL, GET_TASK_CATEGORIES_SUCCESS, GET_TASK_CATEGORIES, GET_TASK_PRIORITES, GET_TASK_PRIORITIES_SUCCESS, GET_TASK_PRIORITIES_FAIL, GET_WORK_LOG_FOR_SUPERADMIN_ERROR, GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS, GET_WORK_LOG_FOR_SUPERADMIN,GET_WORK_LOG_FOR_USER,
    GET_WORK_LOG_FOR_USER_SUCCESS,
    GET_WORK_LOG_FOR_USER_FAIL,
    GET_WORK_LOG_FOR_USER_RESET,
    GET_WORK_LOG_RESET,
    GET_DEFAULT_TIMESLIP_TYPE,
    GET_DEFAULT_TIMESLIP_TYPE_SUCCESS,
    GET_DEFAULT_TIMESLIP_TYPE_FAIL,
    GET_DEFAULT_TIMESLIP_TYPE_RESET,
} from "./actionTypes";

export const getTaskList = (filter) => ({
    type: GET_TASK_LIST,
    filter
});

export const getTaskListSuccess = (response) => ({
    type: GET_TASK_LIST_SUCCESS,
    payload: response.data
});

export const getTaskListFail = (error) => ({
    type: GET_TASK_LIST_FAIL,
    payload: error
});

export const getTaskListReset = () => ({
    type: GET_TASK_LIST_RESET
});

export const getTask = (taskId) => ({
    type: GET_TASK,
    taskId
});

export const getTaskSuccess = (response) => ({
    type: GET_TASK_SUCCESS,
    payload: response.data
});

export const getTaskFail = (error) => ({
    type: GET_TASK_FAIL,
    payload: error
});

export const getTaskReset = () => ({
    type: GET_TASK_RESET
});

export const getTaskCommentList = (taskId) => ({
    type: GET_TASK_COMMENT_LIST,
    taskId
});

export const getTaskCommentListSuccess = (response) => ({
    type: GET_TASK_COMMENT_LIST_SUCCESS,
    payload: response.data
});

export const getTaskCommentListFail = (error) => ({
    type: GET_TASK_COMMENT_LIST_FAIL,
    payload: error
});

export const getTaskCommentListReset = () => ({
    type: GET_TASK_COMMENT_LIST_RESET
});

export const getTaskWorkLog = (taskId) => ({
    type: GET_TASK_WORK_LOG,
    taskId
})

export const getTaskWorkLogSuccess = (response) => ({
    type: GET_TASK_WORK_LOG_SUCCESS,
    payload: response.data
})

export const getTaskWorkLogFail = (error) => ({
    type: GET_TASK_WORK_LOG_FAIL,
    payload: error
})

export const getWorkLogType = () => ({
    type: GET_WORK_LOG_TYPE
})

export const getWorkLogTypeSuccess = (response) => ({
    type: GET_WORK_LOG_TYPE_SUCCESS,
    payload: response.data
})

export const getWorkLogTypeError = (error) => ({
    type: GET_WORK_LOG_TYPE_FAIL,
    payload: error
})

export const getWorkLogForCustomer = (customerId) => ({
    type: GET_WORK_LOG_FOR_CUSTOMER,
    customerId
})

export const getWorkLogForCustomerSuccess = (response) => ({
    type: GET_WORK_LOG_FOR_CUSTOMER_SUCCESS,
    payload: response.data
})

export const getWorkLogForCustomerFail = (error) => ({
    type: GET_WORK_LOG_FOR_CUSTOMER_FAIL,
    payload: error
})

export const getTaskByProject = (projectId) => ({
    type: GET_TASK_BY_PROJECT,
    projectId
})

export const getTaskByProjectSuccess = (response) => ({
    type: GET_TASK_BY_PROJECT_SUCCESS,
    payload: response.data
})

export const getTaskByProjectError = (error) => ({
    type: GET_TASK_BY_PROJECT_ERROR,
    payload: error
})

export const getWorkLogByProject = (projectId) => ({
    type: GET_WORK_LOG_FOR_PROJECT,
    projectId
})

export const getWorkLogByProjectSuccess = (response) => ({
    type:GET_WORK_LOG_FOR_PROJECT_SUCCESS,
    payload: response.data
})

export const getWorkLogByProjectError = (error) => ({
    type: GET_WORK_LOG_FOR_PROJECT_ERROR,
    payload: error
})

export const getWorkLog = (filter) => ({
    type:GET_WORK_LOG,
    filter
})

export const getWorkLogSuccess = (response) => ({
    type:GET_WORK_LOG_SUCCESS,
    payload: response.data
})

export const getWorkLogError = (error) => ({
    type: GET_WORK_LOG_ERROR,
    payload:error
})

export const getWorkLogReset = () => ({
    type:GET_WORK_LOG_RESET
})

export const getWorkLogForSuperAdmin = () => ({
    type:GET_WORK_LOG_FOR_SUPERADMIN
})

export const getWorkLogforSuperAdminSuccess = (response) => ({
    type:GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS,
    payload: response.data
})

export const getWorkLogForSuperAdminError = (error) => ({
    type: GET_WORK_LOG_FOR_SUPERADMIN_ERROR,
    payload:error
})

export const getTaskCategories = () => ({
    type: GET_TASK_CATEGORIES
})

export const getTaskCategoriesSuccess = (response) => ({
    type: GET_TASK_CATEGORIES_SUCCESS,
    payload: response.data
})

export const getTaskCategoriesError = (error) => ({
    type: GET_TASK_CATEGORIES_FAIL,
    payload: error
})

export const getTaskPriorities = () => ({
    type: GET_TASK_PRIORITES
})

export const getTaskPrioritiesSuccess = (response) => ({
    type: GET_TASK_PRIORITIES_SUCCESS,
    payload: response.data
})

export const getTaskPrioritiesError = (error) => ({
    type: GET_TASK_PRIORITIES_FAIL,
    payload: error
})

export const getWorkLogForUser = (userId) => ({
    type: GET_WORK_LOG_FOR_USER,
    userId
})

export const getWorkLogForUserSuccess = (response) => ({
    type: GET_WORK_LOG_FOR_USER_SUCCESS,
    payload: response.data
})

export const getWorkLogForUserFail = (error) => ({
    type: GET_WORK_LOG_FOR_USER_FAIL,
    payload: error
})

export const getWorkLogForUserReset = () => ({
    type: GET_WORK_LOG_FOR_USER_RESET
})

export const getDefaultTimeslipType = () => ({
    type: GET_DEFAULT_TIMESLIP_TYPE
})

export const getDefaultTimeslipTypeSuccess = (response) => ({
    type: GET_DEFAULT_TIMESLIP_TYPE_SUCCESS,
    payload: response.data
})

export const getDefaultTimeslipTypeFail = (error) => ({
    type: GET_DEFAULT_TIMESLIP_TYPE_FAIL,
    payload: error
})

export const getDefaultTimeslipTypeReset = () => ({
    type: GET_DEFAULT_TIMESLIP_TYPE_RESET
})
