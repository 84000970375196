import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import TableToolbar from "../../components/Common/TableToolbar";
import {size} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {getTaskList, getTaskListReset} from "../../store/task/actions";
import {GetCustomers} from "../../store/actions";
import {find, orderBy} from "lodash";
import {getTenantCustomers, getTenants} from "../../store/tenant/actions";
import {get} from "../../helpers/api_helper";
import TaskModal from "./TaskModal";
import {getPriorityLabelClassName} from "../../util/helperFunctions";

const TaskList = (props) => {
    const {
        tasks,
        loading,
        getTaskError,
        onGetTasks,
        onGetTasksReset,

        tenantOptions,
        customerOptions,
        tenantCustomerOptions,
        tenantCustomersLoading,
        onGetTenantOptions,
        onGetCustomerOptions,
        onGetProductOptions,
        onGetProjectOptions,
        onGetTenantUsers, 
        onGetCustomerUsers,
        onGetTenantCustomers,
    } = props;

    
    const [customerId, setCustomerId] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [taskList, setTaskList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);
    const [taskCustomerOptions, setTaskCustomerOptions] = useState([]);
    const [higherLevelAdminUser, setHigherLevelAdminUser] = useState(null);
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['id'];

    const history = useHistory();

    const user = JSON.parse(localStorage.getItem("authUser"));

    const SUPER_ADMIN_VIEW = 'SUPER_ADMIN_VIEW';
    const TENANT_VIEW = 'TENANT_VIEW';
    const CUSTOMER_VIEW = 'CUSTOMER_VIEW';
    const [view, setView] = useState(null);
    const [taskTypeFilter, setTaskTypeFilter] = useState('MY_TASKS');
    const [statusTypeFilter, setStatusTypeFilter] = useState('OPEN');
    const [customFilter, setCustomFilter] = useState({
        taskType: 'MY_TASKS',
        tenantId: null,
        customerId: null,
        status: 'OPEN',
        assignedUserId: null,
        reporterUserId: null,
    });

    const [modal_standard, setmodal_standard] = useState(false);
    
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetTasks(customFilter);
        initializeView();
        return () => {
            onGetTasksReset();
        }
    }, [])
    
    useEffect(() => {
        setTaskList(tasks);
    }, [tasks]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setTaskList(orderBy(taskList, t => {
            let o = t.taskDetails;
            o["project"] = t.project;
            if(column === 'category' || column === 'customer') return o[column]?.name?.toLowerCase();
            if(column === 'tenant') return find(tenantOptions, {id: t.tenantId})?.name?.toLowerCase();
            if(column === 'assignedToUser') return getAssigneeName(t)?.toLowerCase();
            if(column === 'reporters') return o[column].length > 0 ? o[column][0]?.reporter?.name?.toLowerCase() : '';
            if(column === 'priority') return o.priority ? o.priority.name.toLowerCase() : '';
            return numericColumns.includes(column) ? Number(o[column]) : o[column] ? o[column]?.toLowerCase() : '';
        }, [direction]))
      }
    }, [sortState])
    
    useEffect(() => {
        if (getTaskError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetTasksReset()
            }, 5000);
        }
    }, [getTaskError])

    useEffect(async () => {
        if(view === 'CUSTOMER_VIEW'){
            let tenantAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_TENANT_ADMIN');
            if (tenantAdminUser) {
                tenantAdminUser['name'] = props.t('Tenant');
                setHigherLevelAdminUser(tenantAdminUser);
            }
            setCustomerId(user?.customerId);
        } else if(view === 'TENANT_VIEW'){
            let superAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_SUPER_ADMIN');
            if (superAdminUser) {
                superAdminUser['name'] = props.t('Super Admin');
                setHigherLevelAdminUser(superAdminUser);
            }
            setTenantId(user?.tenantId);
            onGetCustomerOptions();
        } else if(view === 'SUPER_ADMIN_VIEW'){
            let superAdminUser = await getHigherLevelAdminUsersByRole(null, 'ROLE_SUPER_ADMIN');
            if(superAdminUser){
                setHigherLevelAdminUser(superAdminUser);
            }
            onGetTenantOptions();
        }
    }, [view]);

    useEffect(() => {
        if(tenantId){
            if(view === SUPER_ADMIN_VIEW){
                onGetTenantCustomers(tenantId);
                onGetProjectOptions(tenantId, null);
                onGetProductOptions(tenantId, null);
            }
        }
    }, [tenantId]);

    useEffect(() => {
        const prevCustomSearchFilter = JSON.parse(localStorage.getItem("taskCustomSearchFilter"));
        const customSearchFilter = {...customFilter, taskType:taskTypeFilter, status: statusTypeFilter};
        const filterKeys = Object.keys(customSearchFilter);
        if(!prevCustomSearchFilter || (filterKeys.filter(key => customSearchFilter[key] == prevCustomSearchFilter[key])).length != filterKeys.length){
            setCustomFilter(customSearchFilter);
            localStorage.setItem("taskCustomSearchFilter", JSON.stringify(customSearchFilter));

            onGetTasksReset();
            onGetTasks(customSearchFilter);
        }
    }, [taskTypeFilter, statusTypeFilter, customFilter]);

    useEffect(() => {
        setTaskCustomerOptions(view === SUPER_ADMIN_VIEW ? tenantCustomerOptions : customerOptions)
    }, [customerOptions, tenantCustomerOptions])


    function initializeView(){
        if(!user?.customerId && !user?.tenantId){
            setView(SUPER_ADMIN_VIEW);
        } else if(user?.customerId){
            setView(CUSTOMER_VIEW);
        } else if(user?.tenantId){
            setView(TENANT_VIEW);
        }
    }

    const getTaskStatusLabel = (status) => {
        if(status === "TODO") return props.t("TODO");
        else if(status === "IN_PROGRESS") return props.t("IN PROGRESS");
        else if(status === "DONE") return props.t("DONE");
        else if(status === "REVIEW") return props.t("REVIEW");
        else return null;
    }

    const getHigherLevelAdminUsersByRole = async (tenantId, roleName) => {
        try {
            const response = await get(`/api/user/adminUserByRole?roleName=${roleName}${tenantId ? '&tenantId=' + tenantId : ''}`);
            if(response.status === 200) {
                return response.data;
            }
        } catch (err) {
        }
        return null;
    };
    
    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    const handleAddTask = () => {
        // clearModalData();
        initializeView();
        tog_standard();
    };

    const getTaskTypeButtonGroups = () => {
        let btnGroups = [
            {name: props.t("My Tasks"), value: 'MY_TASKS'},
            {name: props.t("All"), value: null},
            {name: props.t("Internal"), value:"INTERNAL"},
            {name: props.t("Escalated"), value: "ESCALATED"}
        ]
        if(view === SUPER_ADMIN_VIEW){
            btnGroups.splice(3, 1); // remove escalated filter from super admin view
            btnGroups.push({
                isSearchableDropdown: true,
                value: find(tenantOptions, { id: customFilter.tenantId}),
                options: tenantOptions,
                placeholder: props.t("Select Tenant"),
                handleSelect: (op) => {
                    setCustomFilter({...customFilter, tenantId: op?.id});
                    setTaskTypeFilter(null);
                    },
                keyToDisplay: "name",
            })
        } else if (view === TENANT_VIEW) {
            btnGroups.push({
                isSearchableDropdown: true,
                value: find(taskCustomerOptions, { id: customFilter.customerId}),
                options: taskCustomerOptions,
                placeholder: tenantCustomersLoading ? props.t("Loading...") : props.t("Select Customer"),
                handleSelect: (op) => {
                    setCustomFilter({...customFilter, customerId: op?.id});
                    setTaskTypeFilter(null);
                },
                keyToDisplay: "name",
                favorite: "favorite"
            })
        }

        return btnGroups;
    }

    
    const getStatusButtonGroups = () => {
        let btnGroups = [
            {name: props.t("Open"), value: 'OPEN'},
            {name: props.t("Closed"), value: 'CLOSED'},
            {name: props.t("All"), value: null},
        ]
        return btnGroups;
    }

    function getAssigneeName(task){
        if(view === CUSTOMER_VIEW && task.taskDetails.assigned){
            const roles = task.taskDetails.assigned.roles;
            if(roles.length > 0 && (roles[0].name == 'ROLE_CUSTOMER_ADMIN' || roles[0].name == 'ROLE_CUSTOMER_USER' || roles[0].name == 'ROLE_CONTACT_USER')){
                return task.taskDetails.assigned?.name;
            } else {
                return props.t('Tenant');
            }
        }
        return (higherLevelAdminUser && task.taskDetails.assigned?.id == higherLevelAdminUser?.id) ? higherLevelAdminUser.name : task.taskDetails.assigned?.name
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Subject"),
                    field: "subject",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Priority"),
                    field: "priority",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Category"),
                    field: "category",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Tenant"),
                    field: "tenant",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Customer"),
                    field: "customer",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Project"),
                    field: "project",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Reporters"),
                    field: "reporters",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Assigned to User"),
                    field: "assignedToUser",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Updated Date"),
                    field: "updatedAt",
                    sort: "asc",
                    autoWidth: true,
                }
            ],
            rows: taskList?.map((item, key) => ({
                subject: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                            {item.taskDetails.subject}
                            {user?.uid == item.taskDetails.assigned?.id &&
                            <div className="product-ribbon badge bg-soft-success bg-success ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Assignee")}
                            </div>}
                            {find(item.taskDetails.reporters.map(r => r.reporter), {id: user?.uid}) &&
                            <div className="product-ribbon badge bg-soft-info bg-info ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {" "} {props.t("Reporter")}
                            </div>} 
                            {item.internal ? 
                            <div className="product-ribbon badge bg-soft-warning bg-warning ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {props.t("Internal")}
                            </div> : ""}
                            {item.overdue ? 
                            <div className="product-ribbon badge bg-soft-danger bg-danger ms-2 px-2 d-flex align-items-center justify-content-center" style={{height: "20px"}}>
                                {props.t("Overdue")}
                            </div> : ""}
                        </div>,
                status: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                            {getTaskStatusLabel(item.taskDetails.status)}
                        </div>,
                priority: <div className={`badge ${getPriorityLabelClassName(item.taskDetails.priority?.name)} p-1`} onClick={() => handleOnRowClick(item)}>
                            {props.t(item.taskDetails.priority?.name)}
                        </div>,
                category: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.taskDetails.category?.name}</div>,
                tenant: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.tenantId ? find(tenantOptions, {id: item.tenantId})?.name : ""}</div>,
                customer: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.taskDetails.customer?.name}</div>,
                project: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item?.project}</div>,
                reporters: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{item.taskDetails.reporters?.map(r => r.reporter?.name).join(", ")}</div>,
                assignedToUser: <div className="d-flex" onClick={() => handleOnRowClick(item)}>
                    {getAssigneeName(item)}
                </div>,
                createdAt: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{new Date(item.taskDetails.createdAt).toLocaleString()}</div>,
                updatedAt: <div className="d-flex" onClick={() => handleOnRowClick(item)}>{new Date(item.taskDetails.updatedAt).toLocaleString()}</div>,
            })),
        };
        if(view != SUPER_ADMIN_VIEW){
            data.columns.splice(data.columns.findIndex(col => col.field == 'tenant'), 1);
            if(view == CUSTOMER_VIEW){
                data.columns.splice(data.columns.findIndex(col => col.field == 'customer'), 1);
            }
        }
        return data;
    };

    const handleOnRowClick = (item) => history.push(`${user?.customerId ? '/customer' : ''}/tasks/${item.taskDetails.id}`)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Tasks")}
                                        buttons={[
                                                {
                                                    text: props.t("New Task"),
                                                    onClick: handleAddTask,
                                                }
                                            ]}
                                        filters={[
                                            {
                                                selected: statusTypeFilter,
                                                options : getStatusButtonGroups(),
                                                onSelect: (option) => {
                                                    setStatusTypeFilter(option);
                                                }
                                            },
                                            {
                                                selected: taskTypeFilter,
                                                options : getTaskTypeButtonGroups(),
                                                onSelect: (option) => {
                                                    setCustomFilter({...customFilter, customerId: null, tenantId: null});
                                                    setTaskTypeFilter(option);
                                                }
                                            }
                                        
                                        ]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Tasks"/>
                                {!loading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            key={size(taskList)}
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <TaskModal 
                modal_standard={modal_standard} 
                setmodal_standard={setmodal_standard} 
                tog_standard={tog_standard}
                customFilter={customFilter}
            />
        </React.Fragment>
    );
};

TaskList.propTypes = {
    tasks: PropTypes.array,
    onGetTasks: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        tasks: state.task.tasks,
        getTasksError: state.task.getTasksError,
        loading: state.task.loading,
        customerOptions: state.Customer.customers,
        tenantOptions: state.tenant.tenantList,
        tenantCustomersLoading: state.tenant.getTenantCustomersLoading,
        tenantCustomerOptions: state.tenant.customerList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTasks: (filter) => dispatch(getTaskList(filter)),
    onGetTasksReset: () => dispatch(getTaskListReset()),
    onGetTenantOptions: () => dispatch(getTenants()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetTenantCustomers: (tenantId) => dispatch(getTenantCustomers(tenantId, "active")),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskList)));
