import {
    ADD_ADDRESS,
    ADD_ADDRESS_FAIL,
    ADD_ADDRESS_SUCCESS,
    EDIT_ADDRESS,
    EDIT_ADDRESS_FAIL,
    EDIT_ADDRESS_SUCCESS,
    GET_ADDRESS,
    GET_ADDRESS_FAIL,
    GET_ADDRESS_LIST,
    GET_ADDRESS_LIST_FAIL,
    GET_ADDRESS_LIST_RESET,
    GET_ADDRESS_LIST_SUCCESS,
    GET_ADDRESS_SUCCESS,
    GET_CUSTOMER,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_FOR_AM,
    GET_CUSTOMER_FOR_AM_FAIL,
    GET_CUSTOMER_FOR_AM_RESET,
    GET_CUSTOMER_FOR_AM_SUCCESS,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_FAIL,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_REST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_USERS,
    GET_CUSTOMER_USERS_FAIL,
    GET_CUSTOMER_USERS_RESET,
    GET_CUSTOMER_USERS_SUCCESS,
    RESET_DATA,
} from "./actionTypes";


export const GetCustomers = (sync, showHidden) => ({
    type: GET_CUSTOMER_LIST,
    sync,
    showHidden
});

export const GetCustomersSuccess = (customers) => {
    return {
        type: GET_CUSTOMER_LIST_SUCCESS,
        payload: customers.data,
    };
};

export const GetCustomersFail = (error) => ({
    type: GET_CUSTOMER_LIST_FAIL,
    payload: error,
});

export const GetCustomer = (id) => ({
    type: GET_CUSTOMER,
    customerId: id
});

export const GetCustomerSuccess = (customers) => {
    return {
        type: GET_CUSTOMER_SUCCESS,
        payload: customers.data,
    };
};

export const GetCustomerFail = (error) => ({
    type: GET_CUSTOMER_FAIL,
    payload: error,
});

export const getCustomerRest = () => ({
    type: GET_CUSTOMER_REST,
    payload: null
})

export const GetCustomerUsers = (customerId, filter) => ({
    type: GET_CUSTOMER_USERS,
    customerId: customerId,
    filter
});

export const GetCustomerUsersSuccess = (customers) => {
    return {
        type: GET_CUSTOMER_USERS_SUCCESS,
        payload: customers.data,
    };
};

export const GetCustomerUsersFail = (error) => ({
    type: GET_CUSTOMER_USERS_FAIL,
    payload: error,
});

export const GetCustomerUsersReset = () => ({
    type: GET_CUSTOMER_USERS_RESET
});

export const getAddressList = (customerId, projectId) => ({
    type: GET_ADDRESS_LIST,
    customerId,
    projectId
});

export const getAddressListSuccess = (response) => ({
    type: GET_ADDRESS_LIST_SUCCESS,
    payload: response.data
});

export const getAddressListFail = (error) => ({
    type: GET_ADDRESS_LIST_FAIL,
    payload: error
});

export const getAddressListReset = () => ({
    type: GET_ADDRESS_LIST_RESET
});

export const getAddress = (addressId) => ({
    type: GET_ADDRESS,
   addressId,
});

export const getAddressSuccess = (response) => ({
    type: GET_ADDRESS_SUCCESS,
    payload: response.data
});

export const getAddressFail = (error) => ({
    type: GET_ADDRESS_FAIL,
    payload: error
});

export const addAddress = (customerId, addressData) => ({
    type: ADD_ADDRESS,
    customerId,
    addressData,
});

export const addAddressSuccess = (response) => ({
    type: ADD_ADDRESS_SUCCESS,
    payload: response.data
});

export const addAddressFail = (error) => ({
    type: ADD_ADDRESS_FAIL,
    payload: error
});

export const editAddress = (customerId, addressId, addressData) => ({
    type: EDIT_ADDRESS,
    customerId,
    addressId,
    addressData
});

export const editAddressSuccess = (response) => ({
    type: EDIT_ADDRESS_SUCCESS,
    payload: response.data
});

export const editAddressFail = (error) => ({
    type: EDIT_ADDRESS_FAIL,
    payload: error
});

export const resetData = () => ({
    type: RESET_DATA,
});

export const getCustomerForAM = (userId) => ({
    type: GET_CUSTOMER_FOR_AM,
    userId: userId
})

export const getCustomerForAMSuccess = (response) => ({
    type: GET_CUSTOMER_FOR_AM_SUCCESS,
    payload: response.data
})

export const getCustomerForAmFail = (error) => ({
    type: GET_CUSTOMER_FOR_AM_FAIL,
    payload: error
})

export const getCustomerForAMReset = () => ({
    type: GET_CUSTOMER_FOR_AM_RESET
})
