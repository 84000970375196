import {
    GET_OFFERS_RESET,
    GET_OFFERS_DETAILS,
    GET_OFFERS_DETAILS_FAIL,
    GET_OFFERS_DETAILS_RESET,
    GET_OFFERS_DETAILS_SUCCESS,
    GET_OFFERS,
    GET_OFFERS_FAIL,
    GET_OFFERS_SUCCESS,
    ADD_OFFER_SUCCESS,
    ADD_OFFER_FAIL,
    ADD_OFFER,
    ADD_OFFER_RESET,
    GET_OFFER_METRICS,
    GET_OFFER_METRICS_SUCCESS,
    GET_OFFER_METRICS_ERROR
} from "./actionTypes";


const INIT_STATE = {
    offers: [],
    error: {},
    offerDetails: {},
    offerDetailsError: null,
    offerDetailsLoading: false,
    getOffersError: null,
    addOfferError: null,
    offer: null,
    offerMetrics: {},
    offerMetricsError: null,
    offerMetricsLoading: false
}

const Offers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OFFERS:
            return {
                ...state,
                getOffersError: null,
            }
        case GET_OFFERS_SUCCESS:
            return {
                ...state,
                offers: action.payload,
                getOffersError: null,
            }

        case GET_OFFERS_FAIL:
            return {
                ...state,
                getOffersError: action.payload,
            }
        case GET_OFFERS_RESET:
            return {
                ...state,
                offers: [],
                getOffersError: null,
            }
        case GET_OFFERS_DETAILS:
            return {
                ...state,
                offerDetailsLoading: true
            }
        case GET_OFFERS_DETAILS_SUCCESS:
            return {
                ...state,
                offerDetails: action.payload,
                offerDetailsLoading: false
            }

        case GET_OFFERS_DETAILS_FAIL:
            return {
                ...state,
                offerDetailsError: action.payload,
                offerDetailsLoading: false
            }

        case GET_OFFERS_DETAILS_RESET:
            return {
                ...state,
                offerDetailsError: null,
                offerDetailsLoading: false,
                offerDetails: {}
            }
        case ADD_OFFER:
            return {
                ...state,
                offer: null,
            }
        case ADD_OFFER_SUCCESS:
            return {
                ...state,
                offer: action.payload,
                addOfferError: null,
            }

        case ADD_OFFER_FAIL:
            return {
                ...state,
                addOfferError: action.payload,
                offer: null,
            }

        case ADD_OFFER_RESET:
            return {
                ...state,
                offer: null,
            }
        case GET_OFFER_METRICS:
            return {
                ...state,
                offerMetrics: null,
                offerMetricsLoading: true,
                offerMetricsError: null
            }
        case GET_OFFER_METRICS_SUCCESS:
            return {
                ...state,
                offerMetrics: action.payload,
                offerMetricsError: false,
                offerMetricsLoading: false
            }
        case GET_OFFER_METRICS_ERROR:
            return {
                ...state,
                offerMetrics: null,
                offerMetricsError: action.payload,
                offerMetricsLoading: false
            }
        default:
            return state
    }
}

export default Offers
