import React from "react";

import Select from 'react-select'
import {withTranslation} from "react-i18next";

const DropdownSelect = (props) => {
    const {options, isMulti, values, onChange, disabled, loading, placeholder} = props;

    const filterOption = (option, inputValue) => {
        if (inputValue?.length > 0) {
            return inputValue.split(' ').every(word => option.label?.toLowerCase().includes(word.toLowerCase()));
        }
        return option?.label?.includes(inputValue);
    }

    const isClearable = () => {
        onChange(null);
    }
    return (
        <Select
            isMulti={isMulti ? true : false}
            className="basic-multi-select"
            classNamePrefix="select"
            filterOption={filterOption}
            value={values}
            isClearable={isClearable}
            defaultValue={values}
            onChange={onChange}
            options={options}
            isDisabled={disabled}
            isLoading={loading}
            placeholder={placeholder ? placeholder : props.t("Select") + "..."}
        />
    );
}

export default withTranslation()(DropdownSelect);

