import {call, put, takeEvery} from "redux-saga/effects"
import {ADD_OFFER, GET_OFFER_METRICS, GET_OFFERS, GET_OFFERS_DETAILS} from "./actionTypes";

import {addOfferFail, addOfferSuccess, getOfferMetricsError, getOfferMetricsSuccess, getOffersDetailsFail, getOffersDetailsSuccess, getOffersFail, getOffersSuccess} from "./actions"

import {addOffer, getOfferMetrics, getOffers, getOffersDetails} from "../../helpers/fakebackend_helper"
import {startAction, stopAction} from './../ui/actions';
import {API_ERR_MSG} from "../../util/constants";

function* fetchOffers({type, customerId, offerType, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference}) {
    try {
        if(!startDate){
            startDate = null;
        }
        if(!endDate){
            endDate = null;
        }
        yield put(startAction(type));
        const response = yield call(getOffers, offerType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference);
        yield put(getOffersSuccess(response))
    } catch (error) {
        yield put(getOffersFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type));
    }
}

function* fetchOffersDetails({offerId}) {
    try {
        const response = yield call(getOffersDetails, offerId);
        yield put(getOffersDetailsSuccess(response))
    } catch (error) {
        yield put(getOffersDetailsFail(API_ERR_MSG))
    }
}

function* createOffer({type}) {
    try {
        yield put(startAction(type));
        const response = yield call(addOffer);
        yield put(addOfferSuccess(response))
    } catch (error) {
        yield put(addOfferFail(error))
    } finally {
        yield put(stopAction(type));
    }
}

function* fetchOfferMetrics({startDate, endDate}){
    try{
        let response = yield call(getOfferMetrics, startDate.substring(0,10), endDate.substring(0,10));
        yield put(getOfferMetricsSuccess(response))
    }catch (error){
        yield put(getOfferMetricsError(error));
    }
}

function* offerSaga() {
    yield takeEvery(GET_OFFERS, fetchOffers)
    yield takeEvery(GET_OFFERS_DETAILS, fetchOffersDetails)
    yield takeEvery(ADD_OFFER, createOffer)
    yield takeEvery(GET_OFFER_METRICS, fetchOfferMetrics)
}

export default offerSaga
