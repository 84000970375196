import {EDIT_PROFILE, GET_USER_INFO, GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS, PROFILE_ERROR, PROFILE_RESET, PROFILE_SUCCESS, RESET_PROFILE_FLAG} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const profileReset = () => {
  return {
    type: PROFILE_RESET,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getUserInfo = userId => {
  return {
    type: GET_USER_INFO,
    userId,
  }
}

export const getUserSuccess = msg => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: msg,
  }
}

export const getUserError = error => {
  return {
    type: GET_USER_INFO_ERROR,
    payload: error,
  }
}
