import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {AvField} from "availity-reactstrap-validation";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {del, postMultipart, put} from "../../helpers/api_helper";
import {API_ERR_MSG} from "../../util/constants";
import {Avatar, IconButton} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {connect} from "react-redux";
import {editProfile} from "../../store/actions";
import moment from 'moment-timezone'; //import moment-timezone
import Select from 'react-select'; //import react-select

const timezones = moment.tz.names();
const options = timezones.map((tz) => {
    return { value: tz, label: tz }
});

const MyAccountEdit = (props) => {
    const {
        onEditProfile, 
        editProfileLoading,
        profileError,
        success,
    } = props;

    const { userId } = useParams();
    const [uid, setuid] = useState();
    const [tenantId, setTenantId] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState([]);
    
    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedFile, setselectedFile] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [profileImageId, setProfileImageId] = useState(null);
    const ErrorMessage = "Error occured while updating profile. If issue persists, Please contact system administrator";

    const {header} = props.location.state;
    let history = useHistory();
    const [formFields, setFormFields] = useState({
        name: "",
        email: "",
        title: "",
        id: 0,
        city: "",
        phone: "",
        mobile: "",
        timezone: "Europe/Stockholm"
    });

    const clearError = () => setError([])
    const [brandSettings, setBrandSettings] = useState(null);
    let user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user.brandConfig);
    }, [])

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        setuid(user?.id);
        setFormFields({
            name: user?.username,
            username: user?.email,
            title: user?.title,
            id: user?.uid,
            city: user?.city,
            phone: user?.phone,
            mobile: user?.mobile,
            profileImageId: user?.profileImageId,
            timezone: user?.timezone
        })
        setProfileImageId(user?.profileImageId);
        if(props.tenantId){
            setTenantId(props.tenantId)
        }
        else if (localStorage.getItem("authUser")) {
            setTenantId(user?.tenantId);
        }
    }, []);

   /* useEffect(async () => {
        if (uid && tenantId) {
            setLoading(true);
            let response = await get(`/api/tenant/${tenantId}/users/${uid}`);
            let data = response.data;
            setFormFields({...data, username: data.email})
            setLoading(false);
        }
    }, [uid])*/

    useEffect(()=> {
        if(document.querySelector('select[name=role]')){
            document.querySelector('select[name=role]').value = formFields.role
        }
    }, [formFields])

    const handleTimezoneChange = selectedOption => {
        setFormFields({...formFields, timezone: selectedOption.value})
    };

    const updateUser = (fields) => {
        put(`/api/user/account/${user.uid}`, fields)
            .then(data => {
                if(data?.status !== 200) {
                    setError([props.t(data.data)])
                } else {
                    if(props.callback) {
                        props.callback();
                    }
                    setLoading(false);
                    let authUser = JSON.parse(localStorage.getItem("authUser"));
                    authUser.username = fields.name;
                    authUser.title = fields.title;
                    authUser.city = fields.city;
                    authUser.phone = fields.phone;
                    authUser.mobile = fields.mobile;
                    authUser.email = fields.username;
                    authUser.timezone = fields.timezone;
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                    history.push(`/settings/user-profile`)
                }
            })
            .catch(err => {
                setError([props.t(API_ERR_MSG)])
                setLoading(false);
            })
    }

    const submitForm = (e, fields) => {
        setLoading(true);
        updateUser(fields)
    }

    const handleAcceptedFile = async (e) => {
        if(e.target.files){
            setImageUploading(true);
            let formData = new FormData();
            formData.append("files[]", e.target.files[0]);
            let response = await postMultipart("/api/media", formData);
            if (response.status === 200) {
                setselectedFile(response.data[0])
                setProfileImageId(response.data[0].s3Key);
                if (localStorage.getItem("authUser")) {
                    user.profileImageId = response.data[0].s3Key;
                    localStorage.setItem("authUser", JSON.stringify(user));
                }
                onEditProfile({userId: user?.uid, name: props.user?.name, email: props.user?.email, title:props.user?.title, phone: props.user?.phone, mobile: props.user?.mobile, profileImageId: response.data[0]?.id})
                history.push('/settings/user-profile');
            }
        }
    }

    const handleDeleteProfilePicture = async () => {
        setDeleteBtnText("Deleting");
        let response = await del(`/api/user/${user?.uid}/profile-picture`);
        user.profileImageId = null;
        localStorage.setItem("authUser", JSON.stringify(user));
        history.push('/settings/user-profile');
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <Loader loading={loading} />
                                {!loading && <AvForm
                                    onValidSubmit={(e, v) => {
                                        e.preventDefault();
                                        submitForm(e, v)
                                    }}
                                >
                                    <CardBody>
                                        <CardTitle className="h4">{"Edit Profile"}</CardTitle>
                                        <p className="card-title-desc"></p>

                                        {error.length > 0 ? (
                                            <Alert color="danger">{error.map((msg, index) => index === 0 ? msg : (<p>{msg}</p>))}</Alert>
                                        ) : null}

                                        <div className="text-center">
                                            <input
                                                accept='image/*'
                                                id='icon-button-file'
                                                type='file'
                                                onChange={handleAcceptedFile}
                                                style={{
                                                    display: 'none'
                                                }}
                                            />
                                            <label htmlFor='icon-button-file'>
                                                <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                        style={{
                                                            margin: "10px",
                                                            width: "100px",
                                                            height: "100px",
                                                            borderRadius: "100px"
                                                        }}
                                                    >
                                                        <PersonIcon/>
                                                    </Avatar>
                                                </IconButton>
                                            </label>
                                                    {/* <div>
                                                        <img
                                                            src={profileImageId ? BASE_IMAGE_URL + "/" + profileImageId : avatar}
                                                            alt=""
                                                            className="avatar-lg rounded-circle img-thumbnail"
                                                        />
                                                    </div> */}
                                            {profileImageId && 
                                            <h6>
                                                <Button className="btn-sm btn-danger"
                                                    onClick={handleDeleteProfilePicture}
                                                    color="danger">
                                                    {props.t(deleteBtnText)}
                                                </Button>
                                            </h6>}
                                            <h5 className="mb-1">{formFields?.name}</h5>
                                            {formFields?.username && <p className="text-muted"><a href={"mailto:"+ formFields?.username}>{formFields?.username}</a></p>}
                                        </div>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Title")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="title"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter title")}
                                                    value={formFields.title}
                                                    disabled={loading}
                                                    required
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Name")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="name"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter name")}
                                                    value={formFields.name}
                                                    disabled={loading}
                                                    required
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Email")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="username"
                                                    className="form-control"
                                                    type="email"
                                                    placeholder={props.t("Enter email")}
                                                    value={formFields.username}
                                                    disabled={loading}
                                                    required
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("City")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="city"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter city")}
                                                    value={formFields.city}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Timezone")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField type="hidden" name="timezone" value={formFields.timezone} />
                                                <Select
                                                    value={{label: formFields.timezone, value: formFields.timezone}}
                                                    onChange={handleTimezoneChange}
                                                    options={options}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Phone")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="phone"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter phone")}
                                                    value={formFields.phone}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Mobile")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="mobile"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter mobile")}
                                                    value={formFields.mobile}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Fortnox Username")}
                                            </label>
                                            <div className="col-md-10">
                                                <AvField
                                                    name="fortnoxUsername"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Enter Fornox Username")}
                                                    value={formFields.fortnoxUsername}
                                                    disabled={loading}
                                                />
                                            </div>
                                        </Row>
                                        {uid && <AvField type="hidden" name="id" value={uid} />}
                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                                    onClick={()=> {
                                                        if(props.setEditMode){
                                                            props.setEditMode(false);
                                                        } else {
                                                            // window.location.href = "/settings/users";
                                                            history.goBack()
                                                        }
                                                    }}
                                                    disabled={loading}
                                                >
                                                    {props.t("Cancel")}
                                                </Button>
                                                {" "}
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    disabled={loading || imageUploading}
                                                >
                                                    {uid ? props.t("Update") : props.t("Save")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </AvForm>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        editProfileLoading: state.Profile.editProfileLoading,
        profileError: state.Profile.error,
        success: state.Profile.success,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onEditProfile: (data) => dispatch(editProfile(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(MyAccountEdit)));
