export const TENANT_ADMIN_GET_USERS = "TENANT_ADMIN_GET_USERS"
export const TENANT_ADMIN_GET_USERS_SUCCESS = "TENANT_ADMIN_GET_USERS_SUCCESS"
export const TENANT_ADMIN_GET_USERS_FAIL = "TENANT_ADMIN_GET_USERS_FAIL"
export const TENANT_ADMIN_GET_USERS_RESET = "TENANT_ADMIN_GET_USERS_RESET"
export const TENANT_ADMIN_REGENERATE_TOKEN = "TENANT_ADMIN_REGENERATE_TOKEN"
export const TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS = "TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS"
export const TENANT_ADMIN_REGENERATE_TOKEN_FAIL = "TENANT_ADMIN_REGENERATE_TOKEN_FAIL"
export const TENANT_ADMIN_DELETE_USER = "TENANT_ADMIN_DELETE_USER"
export const TENANT_ADMIN_DELETE_USER_SUCCESS = "TENANT_ADMIN_DELETE_USER_SUCCESS"
export const TENANT_ADMIN_DELETE_USER_FAIL = "TENANT_ADMIN_DELETE_USER_FAIL"

export const TENANT_ADMIN_RESET_DELETE_USER = "TENANT_ADMIN_RESET_DELETE_USER"
export const TENANT_ADMIN_RESET_DELETE_USER_SUCCESS = "TENANT_ADMIN_RESET_DELETE_USER_SUCCESS"
export const TENANT_ADMIN_RESET_REGENERATE_TOKEN = "TENANT_ADMIN_RESET_REGENERATE_TOKEN"

export const TENANT_ACTIVATE_USER = "TENANT_ACTIVATE_USER"
export const TENANT_ACTIVATE_USER_SUCCESS = "TENANT_ACTIVATE_USER_SUCCESS"
export const TENANT_ACTIVATE_USER_FAIL = "TENANT_ACTIVATE_USER_FAIL"

export const GET_TENANT_CUSTOMER_USERS = "GET_TENANT_CUSTOMER_USERS"
export const GET_TENANT_CUSTOMER_USERS_SUCCESS = "GET_TENANT_CUSTOMER_USERS_SUCCESS"
export const GET_TENANT_CUSTOMER_USERS_FAIL = "GET_TENANT_CUSTOMER_USERS_FAIL"
export const GET_TENANT_CUSTOMER_USERS_RESET = "GET_TENANT_CUSTOMER_USERS_RESET"

export const GET_TENANT_USER_DETAILS = "GET_TENANT_USER_DETAILS"
export const GET_TENANT_USER_DETAILS_SUCCESS = "GET_TENANT_USER_DETAILS_SUCCESS"
export const GET_TENANT_USER_DETAILS_FAIL = "GET_TENANT_USER_DETAILS_FAIL"
export const GET_TENANT_USER_DETAILS_RESET = "GET_TENANT_USER_DETAILS_RESET"

export const tenantActionTypes = {
    GET_TENANT_CUSTOMER_USERS,
}
