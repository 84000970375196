import {
    COPY_ORDER_TO_CART,
    COPY_ORDER_TO_CART_FAIL,
    COPY_ORDER_TO_CART_RESET,
    COPY_ORDER_TO_CART_SUCCESS,
    GET_ARTICLE_GROUP,
    GET_ARTICLE_GROUP_FAIL,
    GET_ARTICLE_GROUP_LIST,
    GET_ARTICLE_GROUP_LIST_FAIL,
    GET_ARTICLE_GROUP_LIST_RESET,
    GET_ARTICLE_GROUP_LIST_SUCCESS,
    GET_ARTICLE_GROUP_RESET,
    GET_ARTICLE_GROUP_SUCCESS,
    GET_CART_DATA,
    GET_CART_DATA_FAIL,
    GET_CART_DATA_SUCCESS,
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_FAIL,
    GET_CATEGORY_LIST_RESET,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CONTENT_TYPE,
    GET_CONTENT_TYPE_FAIL,
    GET_CONTENT_TYPE_FOR_ARTICLE,
    GET_CONTENT_TYPE_FOR_ARTICLE_FAIL,
    GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS,
    GET_CONTENT_TYPE_RESET,
    GET_CONTENT_TYPE_SUCCESS,
    GET_CUSTOMER,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMERS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    GET_DASHBOARD_LINE_CHART,
    GET_DASHBOARD_LINE_CHART_FAIL,
    GET_DASHBOARD_LINE_CHART_SUCCESS,
    GET_DASHBOARD_METRICS_LIST,
    GET_DASHBOARD_METRICS_LIST_FAIL,
    GET_DASHBOARD_METRICS_LIST_SUCCESS,
    GET_DEFAULT_LAYOUT,
    GET_DEFAULT_LAYOUT_FAIL,
    GET_DEFAULT_LAYOUT_SUCCESS,
    GET_FAVORITE_PRODUCTS,
    GET_FAVORITE_PRODUCTS_FAIL,
    GET_FAVORITE_PRODUCTS_RESET,
    GET_FAVORITE_PRODUCTS_SUCCESS,
    GET_FAVOURITES,
    GET_FAVOURITES_FAIL,
    GET_FAVOURITES_RESET,
    GET_FAVOURITES_SUCCESS,
    GET_FIELDS,
    GET_FIELDS_FAIL,
    GET_FIELDS_RESET,
    GET_FIELDS_SUCCESS,
    GET_GLOBAL_SEARCH,
    GET_GLOBAL_SEARCH_FAIL,
    GET_GLOBAL_SEARCH_RESET,
    GET_GLOBAL_SEARCH_SUCCESS,
    GET_GROUP_BY_ARTICLE,
    GET_GROUP_BY_ARTICLE_FAIL,
    GET_GROUP_BY_ARTICLE_SUCCESS,
    GET_LAYOUT,
    GET_LAYOUT_FAIL,
    GET_LAYOUT_LIST,
    GET_LAYOUT_LIST_FAIL,
    GET_LAYOUT_LIST_RESET,
    GET_LAYOUT_LIST_SUCCESS,
    GET_LAYOUT_RESET,
    GET_LAYOUT_SUCCESS,
    GET_ORDER_METRICS,
    GET_ORDER_METRICS_ERROR,
    GET_ORDER_METRICS_SUCCESS,
    GET_ORDERS,
    GET_ORDERS_BY_REFERENCE,
    GET_ORDERS_BY_REFERENCE_FAIL,
    GET_ORDERS_BY_REFERENCE_RESET,
    GET_ORDERS_BY_REFERENCE_SUCCESS,
    GET_ORDERS_DETAILS,
    GET_ORDERS_DETAILS_FAIL,
    GET_ORDERS_DETAILS_RESET,
    GET_ORDERS_DETAILS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDERS_FOR_AM,
    GET_ORDERS_FOR_AM_FAIL,
    GET_ORDERS_FOR_AM_RESET,
    GET_ORDERS_FOR_AM_SUCCESS,
    GET_ORDERS_RESET,
    GET_ORDERS_SUCCESS,
    GET_PRICE_LIST,
    GET_PRICE_LIST_FAIL,
    GET_PRICE_LIST_FOR_TENANT,
    GET_PRICE_LIST_FOR_TENANT_ERROR,
    GET_PRICE_LIST_FOR_TENANT_SUCCESS,
    GET_PRICE_LIST_SUCCESS,
    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_FAIL,
    GET_PRODUCT_BY_CATEGORY_RESET,
    GET_PRODUCT_BY_CATEGORY_SUCCESS,
    GET_PRODUCT_BY_GROUP,
    GET_PRODUCT_BY_GROUP_FAIL,
    GET_PRODUCT_BY_GROUP_SUCCESS,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_FAIL,
    GET_PRODUCT_DETAIL_RESET,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_RESET,
    GET_PRODUCTS_SUCCESS,
    GET_SHOPS,
    GET_SHOPS_FAIL,
    GET_SHOPS_SUCCESS,
    GET_SUB_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST_FAIL,
    GET_SUB_CATEGORY_LIST_RESET,
    GET_SUB_CATEGORY_LIST_SUCCESS,
    GET_TEMPLATE_DETAILS,
    GET_TEMPLATE_DETAILS_FAIL,
    GET_TEMPLATE_DETAILS_SUCCESS,
    GET_TEMPLATE_LIST,
    GET_TEMPLATE_LIST_FAIL,
    GET_TEMPLATE_LIST_RESET,
    GET_TEMPLATE_LIST_SUCCESS,
    POST_ORDER,
    POST_ORDER_FAIL,
    POST_ORDER_RESET,
    POST_ORDER_SUCCESS,
} from "./actionTypes"

export const getProducts = (page, pageSize, isCustomer, search, filter, customFilter, tenantId, customerId, sortColumn, sortDirection) => ({
    type: GET_PRODUCTS,
    page,
    pageSize,
    isCustomer,
    search,
    filter: filter,
    customFilter,
    tenantId,
    customerId,
    sortColumn,
    sortDirection
})

export const getProductsSuccess = products => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: products.data,
})

export const getProductsFail = error => ({
    type: GET_PRODUCTS_FAIL,
    payload: error,
})

export const getProductsReset = () => ({
    type: GET_PRODUCTS_RESET
})

export const getFavoriteProducts = (productListId, page, pageSize, search) => ({
    type: GET_FAVORITE_PRODUCTS,
    productListId,
    page,
    pageSize,
    search
})

export const getFavoriteProductsSuccess = products => ({
    type: GET_FAVORITE_PRODUCTS_SUCCESS,
    payload: products.data.data,
})

export const getFavoriteProductsFail = error => ({
    type: GET_FAVORITE_PRODUCTS_FAIL,
    payload: error,
})

export const getFavoriteProductsReset = (clearArticles) => ({
    type: GET_FAVORITE_PRODUCTS_RESET,
    payload: clearArticles
})

export const getContentTypes = (id) => ({
    type: GET_CONTENT_TYPE,
    id
})

export const getContentTypesSuccess = contentTypes => ({
    type: GET_CONTENT_TYPE_SUCCESS,
    payload: contentTypes.data,
})

export const getContentTypesFail = error => ({
    type: GET_CONTENT_TYPE_FAIL,
    payload: error
})

export const getContentTypesReset = () => ({
    type: GET_CONTENT_TYPE_RESET
})

export const getFields = (fieldType) => ({
    type: GET_FIELDS,
    fieldType
})

export const getFieldsSuccess = fields => ({
    type: GET_FIELDS_SUCCESS,
    payload: fields.data,
})

export const getFieldsFail = error => ({
    type: GET_FIELDS_FAIL,
    payload: error
})

export const getFieldsReset = () => ({
    type: GET_FIELDS_RESET
})

export const getCateoryList = () => ({
    type: GET_CATEGORY_LIST
})

export const getCategoryListSuccess = categoryList => ({
    type: GET_CATEGORY_LIST_SUCCESS,
    payload: categoryList.data,
})

export const getCategoryListFail = error => ({
    type: GET_CATEGORY_LIST_FAIL,
    payload: error
})

export const getCateoryListReset = () => ({
    type: GET_CATEGORY_LIST_RESET
})

export const getSubCategoryList = (id) => ({
    type: GET_SUB_CATEGORY_LIST,
    id
})

export const getSubCategoryListSuccess = categoryList => ({
    type: GET_SUB_CATEGORY_LIST_SUCCESS,
    payload: categoryList.data
})

export const getSubCategoryListFail = error => ({
    type: GET_SUB_CATEGORY_LIST_FAIL,
    payload: error
})

export const getSubCategoryListReset = () => ({
    type: GET_SUB_CATEGORY_LIST_RESET,
    payload: null
})

export const getProductDetail = productId => ({
    type: GET_PRODUCT_DETAIL,
    productId,
})

export const getProductDetailSuccess = products => ({
    type: GET_PRODUCT_DETAIL_SUCCESS,
    payload: products.data,
})

export const getProductDetailFail = error => ({
    type: GET_PRODUCT_DETAIL_FAIL,
    payload: error,
})

export const getProductDetailsReset = () => ({
    type: GET_PRODUCT_DETAIL_RESET
})

export const getOrders = (orderType, customerId, articleNumber, startDate, endDate, projectNumber, articleGroupId, manufacturer,ourReference, yourReference) => ({
    type: GET_ORDERS,
    orderType,
    customerId,
    articleNumber,
    startDate,
    endDate,
    projectNumber,
    articleGroupId,
    manufacturer,
    ourReference,
    yourReference
})

export const getOrdersSuccess = orders => ({
    type: GET_ORDERS_SUCCESS,
    payload: orders,
})

export const getOrdersFail = error => ({
    type: GET_ORDERS_FAIL,
    payload: error,
})

export const getOrderReset = () => ({
    type: GET_ORDERS_RESET,
    payload: null
})

export const createOrder = (data) => ({
    type: POST_ORDER,
    data
})

export const createOrderSuccess = order => ({
    type: POST_ORDER_SUCCESS,
    payload: order.data,
})

export const createOrderFail = error => ({
    type: POST_ORDER_FAIL,
    payload: error,
})

export const createOrderReset = () => ({
    type: POST_ORDER_RESET
})

export const getCartData = () => ({
    type: GET_CART_DATA,
})

export const getCartDataSuccess = cartData => ({
    type: GET_CART_DATA_SUCCESS,
    payload: cartData,
})

export const getCartDataFail = error => ({
    type: GET_CART_DATA_FAIL,
    payload: error,
})

export const getCustomers = () => ({
    type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: customers,
})

export const getCustomersFail = error => ({
    type: GET_CUSTOMERS_FAIL,
    payload: error,
})

export const getCustomerInfo = (customerId) => ({
    type: GET_CUSTOMER,
    customerId,
})

export const getCustomerInfoSuccess = customer => ({
    type: GET_CUSTOMER_SUCCESS,
    payload: customer,
})

export const getCustomerInfoFail = error => ({
    type: GET_CUSTOMER_FAIL,
    payload: error,
})

export const getShops = () => ({
    type: GET_SHOPS,
})

export const getShopsSuccess = shops => ({
    type: GET_SHOPS_SUCCESS,
    payload: shops,
})

export const getShopsFail = error => ({
    type: GET_SHOPS_FAIL,
    payload: error,
})


export const getProductByCategory = (categoryId, page, pageSize, search) => ({
    type: GET_PRODUCT_BY_CATEGORY,
    categoryId,
    page,
    pageSize,
    search
})


export const getProductByCategoryReset = () => ({
    type: GET_PRODUCT_BY_CATEGORY_RESET
})

export const getProductByCategorySuccess = articles => ({
    type: GET_PRODUCT_BY_CATEGORY_SUCCESS,
    payload: articles.data
})

export const getProductByCategoryFail = error => ({
    type: GET_PRODUCT_BY_CATEGORY_FAIL,
    payload: error
})

export const getProductByGroup = (groupId, page, pageSize, search, isAll) => ({
    type: GET_PRODUCT_BY_GROUP,
    groupId,
    page,
    pageSize,
    search,
    isAll
})

export const getProductByGroupSuccess = articles => ({
    type: GET_PRODUCT_BY_GROUP_SUCCESS,
    payload: articles.data
})

export const getProductByGroupFail = error => ({
    type: GET_PRODUCT_BY_GROUP_FAIL,
    payload: error
})

export const getLayoutList = () => ({
    type: GET_LAYOUT_LIST
})

export const getLayoutListSuccess = layout => ({
    type: GET_LAYOUT_LIST_SUCCESS,
    payload: layout.data
})

export const getLayoutListFail = error => ({
    type: GET_LAYOUT_LIST_FAIL,
    payload: error
})

export const getLayoutListReset = () => ({
    type: GET_LAYOUT_LIST_RESET
})

export const getDefaultLayout = (articleId) => ({
    type: GET_DEFAULT_LAYOUT,
    articleId
})

export const getDefaultLayoutSuccess = layout => ({
    type: GET_DEFAULT_LAYOUT_SUCCESS,
    payload: layout.data
})

export const getDefaultLayoutFail = error => ({
    type: GET_DEFAULT_LAYOUT_FAIL,
    payload: error
})


export const getLayout = (id) => ({
    type: GET_LAYOUT,
    id
})

export const getLayoutSuccess = payload => ({
    type: GET_LAYOUT_SUCCESS,
    payload: payload.data
})

export const getLayoutFail = error => ({
    type: GET_LAYOUT_FAIL,
    payload: error
})

export const getLayoutReset = () => ({
    type: GET_LAYOUT_RESET
})

export const getPriceList = (articleId, fromQuantity) => ({
    type: GET_PRICE_LIST,
    articleId, fromQuantity
})

export const getPriceListSuccess = payload => ({
    type: GET_PRICE_LIST_SUCCESS,
    payload: payload.data
})

export const getPriceListFail = error => ({
    type: GET_PRICE_LIST_FAIL,
    payload: error
})

export const getOrderDetails = (orderId) => ({
    type: GET_ORDERS_DETAILS,
    orderId
})

export const getOrderDetailsSuccess = orders => ({
    type: GET_ORDERS_DETAILS_SUCCESS,
    payload: orders,
})

export const getOrderDetailsFail = error => ({
    type: GET_ORDERS_DETAILS_FAIL,
    payload: error,
})

export const getGlobalSearch = value => ({
    type: GET_GLOBAL_SEARCH,
    value
})

export const getGlobalSearchFail = error => ({
    type: GET_GLOBAL_SEARCH_FAIL,
    payload: error
})

export const getGlobalSearchSuccess = search => ({
    type: GET_GLOBAL_SEARCH_SUCCESS,
    payload: search
})

export const getGlobalSearchReset = () => ({
    type: GET_GLOBAL_SEARCH_RESET,
    payload: {}
})


export const getOrderDetailsReset = () => ({
    type: GET_ORDERS_DETAILS_RESET,
    payload: {}
})

export const copyOrderToCart = (orderId) => ({
    type: COPY_ORDER_TO_CART,
    orderId
})

export const copyOrderToCartSuccess = cartItems => ({
    type: COPY_ORDER_TO_CART_SUCCESS,
    payload: cartItems,
})

export const copyOrderToCartFail = error => ({
    type: COPY_ORDER_TO_CART_FAIL,
    payload: error,
})

export const copyOrderToCartReset = error => ({
    type: COPY_ORDER_TO_CART_RESET,
    payload: error,
})


export const getOrderForAM = (userId, orderType) => ({
    type: GET_ORDERS_FOR_AM,
    userId,
    orderType
})

export const getOrderForAMSuccess = (response) => ({
    type: GET_ORDERS_FOR_AM_SUCCESS,
    payload: response.data
})

export const getOrderForAMFail = (error) => ({
    type: GET_ORDERS_FOR_AM_FAIL,
    payload: error
})

export const getOrderForAMReset = () => ({
    type: GET_ORDERS_FOR_AM_RESET
})

export const getOrdersByReference = (userId, orderType) => ({
    type: GET_ORDERS_BY_REFERENCE,
    userId,
    orderType
})

export const getOrdersByReferenceSuccess = (response) => ({
    type: GET_ORDERS_BY_REFERENCE_SUCCESS,
    payload: response.data
})

export const getOrdersByReferenceFail = (error) => ({
    type: GET_ORDERS_BY_REFERENCE_FAIL,
    payload: error
})

export const getOrdersByReferenceReset = () => ({
    type: GET_ORDERS_BY_REFERENCE_RESET,
})

export const getArticleGroupList = () => ({
    type: GET_ARTICLE_GROUP_LIST
})

export const getArticleGroupListSuccess = (response) => ({
    type: GET_ARTICLE_GROUP_LIST_SUCCESS,
    payload: response.data
})

export const getArticleGroupListFail = (error) => ({
    type: GET_ARTICLE_GROUP_LIST_FAIL,
    payload: error
})

export const getArticleGroupListReset = () => ({
    type: GET_ARTICLE_GROUP_LIST_RESET
})

export const getArticleGroup = (id) => ({
    type: GET_ARTICLE_GROUP,
    id
})

export const getArticleGroupSuccess = (response) => ({
    type: GET_ARTICLE_GROUP_SUCCESS,
    payload: response.data
})

export const getArticleGroupFail = (error) => ({
    type: GET_ARTICLE_GROUP_FAIL,
    payload: error
})

export const getArticleGroupReset = () => ({
    type: GET_ARTICLE_GROUP_RESET
})

export const getGroupsByArticle = (id) => ({
    type: GET_GROUP_BY_ARTICLE,
    id
})

export const getGroupsByArticleSuccess = (response) => ({
    type: GET_GROUP_BY_ARTICLE_SUCCESS,
    payload: response.data
})

export const getGroupByArticleFail = (error) => ({
    type: GET_GROUP_BY_ARTICLE_FAIL,
    payload: error
})


export const getContentTypeForArticle = (id) => ({
    type: GET_CONTENT_TYPE_FOR_ARTICLE,
    id
})

export const getContentTypeForArticleSuccess = (response) => ({
    type: GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS,
    payload: response.data
})

export const getContentTypeForArticleFail = (error) => ({
    type: GET_CONTENT_TYPE_FOR_ARTICLE_FAIL,
    payload: error
})

export const getTemplateList = () => ({
    type: GET_TEMPLATE_LIST
})

export const getTemplateListSuccess = (response) => ({
    type: GET_TEMPLATE_LIST_SUCCESS,
    payload: response.data
})

export const getTemplateListError = (error) => ({
    type: GET_TEMPLATE_LIST_FAIL,
    payload: error
})

export const getTemplateListReset = () => ({
    type: GET_TEMPLATE_LIST_RESET
})


export const getTemplateDetails = (id) => ({
    type: GET_TEMPLATE_DETAILS,
    id
})

export const getTemplateDetailsSuccess = (response) => ({
    type: GET_TEMPLATE_DETAILS_SUCCESS,
    payload: response.data
})

export const getTemplateDetailsError = (error) => ({
    type: GET_TEMPLATE_DETAILS_FAIL,
    payload: error
})

export const getFavourites = () => ({
    type: GET_FAVOURITES
})

export const getFavouritesSuccess = favourites => ({
    type: GET_FAVOURITES_SUCCESS,
    payload: favourites.data,
})

export const getFavouritesFail = error => ({
    type: GET_FAVOURITES_FAIL,
    payload: error
})

export const getFavouritesReset = () => ({
    type: GET_FAVOURITES_RESET
})


export const getOrderMetrics = (startDate, endDate) => ({
    type: GET_ORDER_METRICS,
    startDate,
    endDate
})

export const getOrderMetricsSuccess = (response) => ({
    type:GET_ORDER_METRICS_SUCCESS,
    payload: response.data
})

export const getOrderMetricsError = (error) => ({
    type: GET_ORDER_METRICS_ERROR,
    payload: error
})


export const getDashboardLineChart = (startDate, endDate, timeWindow, filter) => ({
    type: GET_DASHBOARD_LINE_CHART,
    startDate,
    endDate,
    timeWindow,
    filter
})

export const getDashboardLineChartSuccess = (response) => ({
    type: GET_DASHBOARD_LINE_CHART_SUCCESS,
    payload: response.data
})

export const getDashboardLineChartFail = (error) => ({
    type: GET_DASHBOARD_LINE_CHART_FAIL,
    payload: error
})

export const getDashboardMetricsList = (startDate, endDate, timeWindow, filter) => ({
    type: GET_DASHBOARD_METRICS_LIST,
    startDate,
    endDate,
    timeWindow,
    filter
})

export const getDashboardMetricsListSuccess = (response) => ({
    type: GET_DASHBOARD_METRICS_LIST_SUCCESS,
    payload: response.data
})

export const getDashboardMetricsListFail = (error) => ({
    type: GET_DASHBOARD_METRICS_LIST_FAIL,
    payload: error
})


export const getPriceListForTenant = () => ({
    type: GET_PRICE_LIST_FOR_TENANT
})

export const getPriceListForTenantSuccess = (response) => ({
    type: GET_PRICE_LIST_FOR_TENANT_SUCCESS,
    payload: response.data
})

export const getPriceListForTenantError = (error) => ({
    type: GET_PRICE_LIST_FOR_TENANT_ERROR,
    payload: error
})
