import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getCustomerForAM, GetCustomers} from "../../store/customer/actions";
import {changeSidebarType, impersonateUser, toggleLeftmenu} from "../../store/actions";
import {includes, isEmpty} from "lodash-es";
import TableToolbar from "../../components/Common/TableToolbar";
import {checkIfLoading} from "../../store/selector";
import {customersActionTypes} from "../../store/customer/actionTypes";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {find, orderBy} from "lodash";

export const customerDetailContext = React.createContext(null);

const CustomerList = (props) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const {customers, onGetCustomers, error, loading, customersForAmLoading, onImpersonateUser, customersForAM, onGetCustomersForAM} = props;
    const [customerList, setCustomerList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState(null);
    const [sortState, setSortState] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const numericColumns = [];
    let user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);

        const prevFilter = localStorage.getItem("companyCustomerFilter");
        if (prevFilter) {
            setFilter(prevFilter);
        } else {
            setFilter("all");
        }
    }, [])

    useEffect(() => {
        if(filter){
            if (filter === "mycustomers") {
                onGetCustomersForAM(user?.uid)
            } else if(filter === "hiddencustomers") {
                onGetCustomers(true)
            } else {
                onGetCustomers(false)
            }
        }
    }, [filter]);

    useEffect(() => {
        if (filter === "mycustomers") {
            if (customersForAM && customersForAM.length > 0) {
                setCustomerList(customersForAM);
            } else {
                setCustomerList([]);
            }
        } else {
            if (customers && customers.length > 0) {
                setCustomerList(customers);
            } else {
                setCustomerList([]);
            }
        }
    }, [customers, customersForAM]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setCustomerList(orderBy(customerList, o => {
            if(column === 'accountManager') return o[column]?.name?.toLowerCase()
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
        if (error) {
            setErrorMessage(API_ERR_MSG);
        }
    }, [error])

    const handleOpen = (id) => {
        history.push(`/customers/${id}/profile`);
    };
    const handleSearch = () => {
        if (!isEmpty(search)) {
            const results = filter(
                customerList,
                (offer) =>
                    includes(offer.displayName?.toLowerCase(), search.toLowerCase()) ||
                    includes(offer.description?.toLowerCase(), search.toLowerCase()) ||
                    filter(offer.categories, (c) =>
                        includes(c.name?.toLowerCase(), search.toLowerCase())
                    ).length > 0
            );

            setSearchResults(results);
        } else {
            setSearchResults(customerList);
        }
        setSearchValue(search);
    };


    const handleImpersonate = (customer) => {
        onImpersonateUser({customerId: customer.id}, history, location)
    }

    const getCustomerName = (customer) => {
        if(customer.favorite){
            return (<span onClick={() => handleOpen(customer.customerNumber)}>★&nbsp;{customer?.name}</span>);
        }
        return <span onClick={() => handleOpen(customer.customerNumber)}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{customer?.name}</span>;
    }
    const getDatatableData = () => {
        let rows = customerList?.filter((row) =>
            row.name?.toLowerCase().includes(search?.toLowerCase())
        );
        if(typeFilter){
            rows = rows?.filter((row) =>
                row.costCenter === typeFilter?.name
            );
        }
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    width: 150,
                },
                {
                    label: props.t("Label"),
                    field: "costCenter",
                    width: 150,
                },
                {
                    label: props.t("City"),
                    field: "city",
                    width: 100,
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                },
                {
                    label: props.t("Account Manager"),
                    field: "accountManager",
                },
                {
                    label: props.t("Impersonate"),
                    field: "impersonate",
                }
            ],
            rows: rows?.map((customer, key) => ({
                name: getCustomerName(customer),
                costCenter: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.costCenter}</span>,
                city: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.city}</span>,
                phone: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.phone}</span>,
                accountManager: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.accountManager?.name}</span>,
                impersonate: <>
                    <button onClick={() => handleImpersonate(customer)} className="btn btn-primary btn-sm" style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}><i
                        className="fa fa-sign-in-alt"></i></button>
                </>
            })),
        };
        return data;
    };

    const handleFilter = (value) => {
        setFilter(value)
        localStorage.setItem("companyCustomerFilter", value);
        if (value === "mycustomers") {
            onGetCustomersForAM(user?.uid)
        } else if(value === "hiddencustomers") {
            onGetCustomers(true)
        } else {
            onGetCustomers(false)
        }
    }

    const getLabels = () => {
        let labels = [];
        customers?.filter(obj => obj.costCenter).map(v => {
            let exist = find(labels, {name : v.costCenter})
            if(!exist){
                labels.push(({value: v, name: v.costCenter}));
            }
        });
        return labels;
    }

    //   const HandleSideBarClose = () => {
    //     props.changeSidebarType("default", isMobile);
    //     props.toggleLeftmenu(false);
    //   };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Companies")}
                                        filters={[{
                                            selected: filter,
                                            options: [
                                                {name: props.t("All"), value: "all"},
                                                {name: props.t("My Customers"), value: "mycustomers"},
                                                {name: props.t("Hidden"), value: "hiddencustomers"}
                                            ],
                                            onSelect: handleFilter
                                        },{
                                            selected: typeFilter,
                                            options : [{
                                                isSearchableDropdown: true,
                                                value: typeFilter,
                                                options: getLabels(),
                                                placeholder: props.t("Select Label"),
                                                handleSelect: (op) => {
                                                    /*setCustomFilter({...customFilter, customerId: op?.id});
                                                    setTaskTypeFilter(null);*/
                                                    setTypeFilter(op);
                                                },
                                                keyToDisplay: "name",
                                            }]
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={loading || customersForAmLoading} title="Customers"/>
                                {!loading && !customersForAmLoading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            searchLabel={props.t("Search") + "..."}
                                            displayEntries={50}
                                            noBottomColumns={true}
                                            searching={true}
                                            entries={50} entriesOptions={[20, 50, 100, 500]}
                                            data={getDatatableData()}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

CustomerList.propTypes = {
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onImpersonateUser: PropTypes.func,
    error: PropTypes.object,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const {layoutType, showRightSidebar, leftMenu, leftSideBarType} =
        state.Layout;
    return {
        customers: state.Customer.customers,
        customersForAM: state.Customer.customersForAM,
        error: state.Customer.GetCustomersError,
        loading: checkIfLoading(state, customersActionTypes.GET_CUSTOMER_LIST),
        customersForAmLoading: state.Customer.customersForAmLoading,
        leftMenu,
        leftSideBarType,
        showRightSidebar,
        layoutType
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetCustomers: (hidden) => dispatch(GetCustomers(true, hidden)),
    onGetCustomersForAM: (id) => dispatch(getCustomerForAM(id)),
    toggleLeftmenu: (value) => dispatch(toggleLeftmenu(value)),
    changeSidebarType: (mobile, value) =>
        dispatch(changeSidebarType(mobile, value)),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerList)));
