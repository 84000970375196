import {
    GET_IMPERSONATION_PROJECT_LIST,
    GET_IMPERSONATION_PROJECT_LIST_FAIL,
    GET_IMPERSONATION_PROJECT_LIST_SUCCESS,
    GET_PROJECT_LIST,
    GET_PROJECT_LIST_BY_LEADER,
    GET_PROJECT_LIST_BY_LEADER_FAIL,
    GET_PROJECT_LIST_BY_LEADER_RESET,
    GET_PROJECT_LIST_BY_LEADER_SUCCESS,
    GET_PROJECT_LIST_BY_PARTICIPANT,
    GET_PROJECT_LIST_BY_PARTICIPANT_FAIL,
    GET_PROJECT_LIST_BY_PARTICIPANT_RESET,
    GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS,
    GET_PROJECT_LIST_FAIL,
    GET_PROJECT_LIST_RESET,
    GET_PROJECT_LIST_SUCCESS,
    GET_PROJECT_USERS,
    GET_PROJECT_USERS_FAIL,
    GET_PROJECT_USERS_SUCCESS
} from "./actionTypes";

export const getProjectList = (customerId, status, tenantId, filter, sync) => ({
    type: GET_PROJECT_LIST,
    customerId,
    status,
    tenantId,
    filter,
    sync
});

export const getProjectListSuccess = (response) => ({
    type: GET_PROJECT_LIST_SUCCESS,
    payload: response.data
});

export const getProjectListFail = (error) => ({
    type: GET_PROJECT_LIST_FAIL,
    payload: error
});

export const getProjectListReset = () => ({
    type: GET_PROJECT_LIST_RESET
});

export const getImpersonationProjectList = (customerId) => ({
    type: GET_IMPERSONATION_PROJECT_LIST,
    customerId
});

export const getImpersonationProjectListSuccess = (response) => ({
    type: GET_IMPERSONATION_PROJECT_LIST_SUCCESS,
    payload: response?.data?.Projects
});

export const getImpersonationProjectListFail = (error) => ({
    type: GET_IMPERSONATION_PROJECT_LIST_FAIL,
    payload: error
});


export const getProjectListByParticipant = (userId) => ({
    type: GET_PROJECT_LIST_BY_PARTICIPANT,
    userId
})

export const getProjectListByParticipantSuccess = (response) => ({
    type: GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS,
    payload: response.data
})

export const getProjectListByParticipantFail = (error) => ({
    type: GET_PROJECT_LIST_BY_PARTICIPANT_FAIL,
    payload: error
})

export const getProjectListByParticipantReset = () => ({
    type: GET_PROJECT_LIST_BY_PARTICIPANT_RESET
})

export const getProjectUsers = (projectId) => ({
    type: GET_PROJECT_USERS,
    projectId
})

export const getProjectUsersSuccess = (response) => ({
    type:GET_PROJECT_USERS_SUCCESS,
    payload: response.data
})

export const getProjectUsersError = (error) => ({
    type: GET_PROJECT_USERS_FAIL,
    payload: error
})

export const getProjectListByLeader = (userId) => ({
    type: GET_PROJECT_LIST_BY_LEADER,
    userId
})

export const getProjectListByLeaderSuccess = (response) => ({
    type: GET_PROJECT_LIST_BY_LEADER_SUCCESS,
    payload: response.data
})

export const getProjectListByLeaderFail = (error) => ({
    type: GET_PROJECT_LIST_BY_LEADER_FAIL,
    payload: error
})

export const getProjectListByLeaderReset = () => ({
    type: GET_PROJECT_LIST_BY_LEADER_RESET
})
