import React from "react";
import {Card, CardBody, Container, Modal} from "reactstrap";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import OfferForm from "./OfferForm";
import {useParams} from "react-router-dom";
import queryString from "query-string";

const AddUpdateOffer = (props) => {
    const params = useParams();
    let query = queryString.parse(props?.location?.search);

    const OfferPage = (props) => 
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={params.id ?
                            [{ title: props.t("Offers"), link: "/offers" }, { title: params.id, link: `/offers/${params.id}` }] :
                            [{ title: props.t("Offers"), link: "/offers" }]}
                        title={params.id ? query?.copy ? props.t("Copy Offer") : props.t("Update Offer") : props.t("New Offer")}
                    />
                    <Card>
                        <CardBody>
                            <OfferForm />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>

    const OfferModal = ({modal_standard, tog_standard, useModal}) => 
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("New Offer")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <OfferForm useModal={useModal} tog_standard={tog_standard} />
            </div>
        </Modal>

    return props.useModal ? <OfferModal {...props} /> : <OfferPage {...props} />;
};

export default withTranslation()(AddUpdateOffer);
