import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  changeLayout,
  changeLayoutWidth,
  changeTopbarTheme,
} from "../../store/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
import Rightbar from "../CommonForBoth/Rightbar";
import { withTranslation } from "react-i18next";
import ClickAwayListener from "react-click-away-listener";
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../store/actions";
class Layout extends Component {
  props = null;
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isMenuOpened: false,
    };
  }

  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    // Scrollto 0,0
    window.scrollTo(0, 0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const title = this.props.location.pathname;
    let currentpage = title.charAt(1).toUpperCase() + title.slice(2);
    if (this.props.location.state && this.props.location.state.title) {
      currentpage = this.props.t(this.props.location.state.title);
    }

    document.title = currentpage + " | " + this.props.t("Flowsense");

    this.props.changeLayout("horizontal");
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };
  handleClickAway = () => {
    if (this.props.leftMenu) this.props.toggleLeftmenu(false);
  };
  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="uil-shutter-alt spin-icon"></i>
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <header id="page-topbar">
              <Header
                theme={this.props.topbarTheme}
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu}
              ></Header>

              <Navbar menuOpen={this.state.isMenuOpened} />
            </header>
          </ClickAwayListener>
          <div className="main-content">{this.props.children}</div>
          <Footer />
        </div>

        {this.props.showRightSidebar ? <Rightbar /> : null}
      </React.Fragment>
    );
  }
}

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeTopbarTheme,
  changeLayout,
  changeLayoutWidth,
  toggleLeftmenu,
})(withRouter(withTranslation()(Layout)));
