import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

const DeleteModal = props => {
    let { element, deleteCallback, showDeleteModal, toggleModal, confirmation } = props;
    const [alertMsg, setAlertMsg] = useState("");
    const [alertTitle, setAlertTitle] = useState("")
    const [brandSettings, setBrandSettings] = useState(null);
    const [confirm, setConfirm] = useState("");
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])


    useEffect(() => {
        if(props.alertMsg){
            setAlertMsg(props.alertMsg);
        } else {
            setAlertMsg(props.t('Are you sure you want to ' +
            (element === "User" ? 'de-activate' : 'delete') + ' this ') + element + '?');
        }

        if(props.warningMsg){
            setAlertMsg(props.warningMsg + ' ' + alertMsg);
        }

        if(props.alertTitle){
            setAlertTitle(props.alertTitle);
        } else {
            if(element === "User"){
                setAlertTitle(props.t('De-activate ') + element);
            } else if(element === "Order" || element === "Offer"){
                setAlertTitle(props.t('Cancel ') + element);
            } else {
                setAlertTitle(props.t('Delete ') + element);
            }
        }
    }, [element, props.alertMsg, props.alertTitle, props.warningMsg])

    const handleDeleteElement = async () => {
        if(confirmation){
            if(props.t("confirm") !== confirm) {
                return;
            }
        }
        const deleteError = await deleteCallback();
        if(!deleteError){
            setConfirm("");
            toggleModal()
        }

    }

    const deleteEnable = () => {
        if(confirmation){
            return props.t("confirm") !== confirm;
        }
        return false;
    }

    return (
            <Modal
                isOpen={showDeleteModal}
                toggle={() => {
                    toggleModal();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t(alertTitle)}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>{props.t(alertMsg)}</p>
                    <br/>
                    {confirmation && <>
                        <label>{props.t("Type confirm to continue with delete")}</label>
                        <input type="text" className="form-control" value={confirm} onChange={(e) => setConfirm(e.target.value)} placeholder={props.t("confirm")}/>
                    </>}

                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleDeleteElement}
                        disabled={confirmation ? props.t("confirm") !== confirm : false}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                    >
                        {props.t('Yes')}
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal();
                            setConfirm("");
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal">
                        {props.t('No')}
                    </button>
                </div>
            </Modal>
            )

}

export default DeleteModal;
