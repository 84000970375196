import {call, put, takeEvery} from "redux-saga/effects"
import {CHANGE_PASSWORD} from "./actionTypes";
import {userChangePasswordError, userChangePasswordSuccess} from "./actions";
import {putChangePassword} from "../../../helpers/fakebackend_helper";

function* changeUserPassword(action) {
  try{
    yield call(putChangePassword, action.payload)
    yield put(userChangePasswordSuccess("Password changed successfully!"))
  } catch(err) {
    if(err.response !== 'undefined' && err.response !== null && Array.isArray(err.response.data)){
      yield put(userChangePasswordError(err.response.data[0]))
    } else {
      yield put(userChangePasswordError("Error occured while changing password. If issue persists, Please contact system administrator"))
    }
  }
}


function* changePasswordSaga() {
    yield takeEvery(CHANGE_PASSWORD, changeUserPassword)
  }

export default changePasswordSaga
