import React, {useEffect, useState} from "react";
import {CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getCateoryList, getCateoryListReset} from "../../store/e-commerce/actions";
import categoriesPlaceholder from "../../assets/images/categories/categories.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {Card, createMuiTheme, ThemeProvider,} from "@material-ui/core";
import useStyles from "../../util/styles";
import TableToolbar from "../../components/Common/TableToolbar";

const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      // Name of the component ⚛️ / style sheet
      elevation1: {
        // Name of the rule
        boxShadow: "none", // Some CSS
      },
    },
  },
});

const ShopCategories = (props) => {
  const classes = useStyles();
  const { categoryList, onGetCategoryList, error, onGetCategoryListReset } = props;
  const [contentTypeList, setContentTypeList] = useState([]);
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    onGetCategoryList();
    setContentTypeList(categoryList);
    return () => {
      onGetCategoryListReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetCategoryListReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    setContentTypeList(categoryList);
  }, [categoryList]);

  const handleSelect = (selected) => {
    if (selected != null) {
      if (selected.subCategories && selected.subCategories.length > 0) {
        history.push(`/customer/categories/${selected.id}/subcategory`);
      } else {
        history.push(`/customer/categories/${selected.id}/articles`);
      }
    }
  };

  const handleOpenCategory = (category) => {
    history.push(`/customer/categories/${category.id}/subcategory`)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="page-content ">
        <Container fluid>
          {/*<Breadcrumbs title="Articles" breadcrumbItem="Category" />*/}
          {/*{error &&
            <Alert color="danger" role="alert">
                {error}
            </Alert>
          }*/}
          {categoryList?.map(function (category, index) {
            return (
              <>
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardHeader onClick={() => handleOpenCategory(category)} className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                      <TableToolbar
                          title={category?.name}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row className="align-items-center">
                        {category?.subCategories.map(function (subCategory, index) {
                              return (
                                  <Col
                                      key={"subcate<br/>gory_" + index}
                                      xl={2}
                                      md={4}
                                      className="col-6 cursor-pointer"
                                      onClick={() => handleSelect(subCategory)}
                                  >
                                    <div className="img-fluid">
                                      {subCategory.media != null && (
                                          <div
                                              className="background-Image"
                                              style={{
                                                backgroundImage: `url(${
                                                    BASE_IMAGE_URL + "/" + subCategory.media.s3Key
                                                })`,
                                              }}
                                          ></div>
                                      )}
                                      {subCategory.media == null && (
                                          <img
                                              src={categoriesPlaceholder}
                                              alt=""
                                              className="img-fluid mx-auto height-100 d-block"
                                          />
                                      )}
                                    </div>
                                    <br />
                                    <h6 align="center">{subCategory.name}</h6>
                                  </Col>
                              );
                            })}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

                {index !== categoryList.length - 1 ? (
                  <div className="mb-5 mt-5" />
                ) : (
                  <div className="mb-5" />
                )}
              </>
            );
          })}
        </Container>
      </div>
    </ThemeProvider>
  );
};

ShopCategories.propTypes = {
  categoryList: PropTypes.array,
  onGetCategoryList: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.ecommerce.categoryList,
    error: state.ecommerce.getCategoryListError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetCategoryList: () => dispatch(getCateoryList()),
  onGetCategoryListReset: () => dispatch(getCateoryListReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ShopCategories)));
