import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_RESET,
  GET_ALL_USERS_SUCCESS, GET_USERS_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  usersLoading: false,
  users: [],
  userList: [],
  usersError: null,
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USERS:
      return {
        ...state,
        usersLoading: true,
      }
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersError: null,
        usersLoading: false
      }
    case GET_ALL_USERS_FAIL:
      return {
        ...state,
        usersError: action.payload,
        usersLoading: false
      }
    case GET_ALL_USERS_RESET:
      return {
        ...state,
        usersError: null,
        users: [],
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        userList: action.payload,
      }
    default:
      return state;
  }
};

export default Users
