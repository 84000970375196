import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, Col, Collapse, Container, Input, Label, Media, Row, Table} from "reactstrap"

import {AvField, AvForm} from "availity-reactstrap-validation"

import {withTranslation} from "react-i18next";

// Redux
import {connect} from "react-redux"
import {Link, useHistory, withRouter} from "react-router-dom"
import DatePicker from 'react-date-picker'

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {countries} from '../../util/dataUtil';
import {createOrder, getCustomerInfo, GetCustomerUsers, getProjectList} from '../../store/actions';
import {find, isEmpty, isNull} from 'lodash-es';
import {BASE_IMAGE_URL} from '../../helpers/url_helper';
import ProjectForm from '../Projects/ProjectForm';
import {get} from "../../helpers/api_helper";
import DeliveryAddressForm from '../Customer/DeliveryAddressForm';
import {API_ERR_MSG} from '../../util/constants';
import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import Loader from '../../components/Common/Loader';
import {parseDateToString} from '../../util/helperFunctions';
import {isStockable} from '../../util/articleUtil';
import {evaluate} from "mathjs";

const ShopCheckout = props => {
    const { customer, loading, cartItems, orderSuccess, orderError, orderPending, customerUsers, 
      onGetCustomerInfo, onCreateOrder, projects, onGetProjects, onGetCustomerUsers } = props;

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const [isOpenShippinginfo, setIsOpenShippinginfo] = useState(false);
    const toggleShippinginfo = () => setIsOpenShippinginfo(!isOpenShippinginfo);

    const [isOpenAddNewShippingInfo, setIsOpenAddNewShippingInfo] = useState(false);

    const [isOpenProjectinfo, setIsOpenProjectinfo] = useState(false);
    const toggleProjectinfo = () => setIsOpenProjectinfo(!isOpenProjectinfo);

    const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);

    let user = JSON.parse(localStorage.getItem("authUser"));
    const priceListCode = user?.config?.priceList;
    useEffect(() => {
        setBrandSettings(user.brandConfig);
    }, [])

    const toggleAddNewShippingInfo = (shippingInfoId, openCloseFormValue) => {
      setSelectedShippingInfo(shippingInfoId ? shippingInfoId : null);
      setIsOpenAddNewShippingInfo(openCloseFormValue !== undefined ? openCloseFormValue : !isOpenAddNewShippingInfo);
    };

    const editShippingInfo = (id) => {
      setEditShippingInfoId(id);
      setIsOpenAddNewShippingInfo(true);
    }

    const toggleAddNewProject = (projectNumber, openCloseFormValue) => {
      setSelectedProject(projectNumber ? projectNumber : "");
      setIsOpenAddNewProject(openCloseFormValue !== undefined ? openCloseFormValue : !isOpenAddNewProject);
    };

    const editProject = (id) => {
      setEditProjectId(id);
      setIsOpenAddNewProject(id !== null);
    }

    const [error, setError] = useState([]);

    const[customerId, setCustomerId] = useState("");

    const [CustomerName, setCustomerName] = useState("")
    const [CustomerNumber, setCustomerNumber] = useState("")
    const [Address1, setAddress1] = useState("")
    const [Address2, setAddress2] = useState("")
    const [yourReference, setYourReference] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [City, setCity] = useState("")
    const [Country, setCountry] = useState("")
    const [CountryCode, setCountryCode] = useState("")
    const [ZipCode, setZipCode] = useState("")
    const [OrderRows, setOrderRows] = useState([])

    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [shippingInfoList, setShippingInfoList] = useState([])
    const [editShippingInfoId, setEditShippingInfoId] = useState(null);
    const [selectedShippingInfo, setSelectedShippingInfo] = useState(null);
    const [deliveryReference, setDeliveryReference] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");

    const [projectList, setProjectList] = useState([])
    const [editProjectId, setEditProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState("");
    const [brandSettings, setBrandSettings] = useState(null)
    const [standardDelivery, setStandardDelivery] = useState(true);
    const history = useHistory();

    useEffect(() => {
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setBrandSettings(obj?.brandConfig);
        setCustomerId(obj?.customerId);
        onGetCustomerInfo(obj?.customerId);
        onGetProjects(obj?.customerId);
        onGetCustomerUsers(obj?.customerId);
      }
    }, [])

    useEffect(() => {
      if(customerId){
        onGetShippingInfo();
      }
    }, [customerId])

    useEffect(() => {
      if(isEmpty(orderSuccess)) return;
      history.push(`/customer/orders/${orderSuccess.orderDetails.Order.DocumentNumber}`)
    }, [orderSuccess])

    useEffect(() => {
      if(orderPending){
        setTimeout(2000);
      }
    }, [orderPending])

    useEffect(() => {
      if (customer) {
        setCustomerName(isNull(customer?.name) ? "" : customer?.name)
        setCustomerNumber(isNull(customer?.customerNumber) ? "" : customer?.customerNumber)
        setAddress1(isNull(customer?.address1) ? "" : customer?.address1);
        setAddress2(isNull(customer?.address2) ? "" : customer?.address2);
        setCity(isNull(customer?.city) ? "" : customer?.city);
        setCountry(isNull(customer?.country) ? "" : customer?.country);
        setCountryCode(isNull(customer?.countryCode) ? "" : customer?.countryCode);
        setZipCode(isNull(customer?.zipCode) ? "" : customer?.zipCode);
      }
    }, [customer])

    useEffect(() => {
      if(cartItems){

        let orders = [];
        for(let i = 0; i < cartItems.length; i++){
            let cartItem = cartItems[i];
            let articlePackage = cartItem.article.articlePackageType == "MANUAL" ? find(cartItem.article.articlePackage, {quantity: parseInt(cartItem.quantity)}) : cartItem?.article?.articlePackage[0];

            orders.push({
                ArticleNumber: cartItem?.article.articleNumber,
                OrderedQuantity: cartItem.article.articlePackageType == "MANUAL" ? 1 : cartItem?.quantity,
                DeliveredQuantity: cartItem.article.articlePackageType == "MANUAL" ? 1 : cartItem?.quantity,
                Description: cartItem.article.articlePackageType == "MANUAL" ? cartItem?.article?.description + " / " + articlePackage?.name : cartItem?.article?.description,
                Price: getArticlePrice(cartItem),
                VAT: cartItem?.article.vat
            });

            let packageItemOrders = articlePackage?.packageItems.map(pkgItem => ({
                ArticleNumber: pkgItem.articleDto?.articleNumber,
                OrderedQuantity:  cartItem.article.articlePackageType == "DYNAMIC" ? parseExpression(cartItem?.quantity, pkgItem?.operator, pkgItem?.quantity) : pkgItem?.quantity,
                DeliveredQuantity:  cartItem.article.articlePackageType == "DYNAMIC" ? parseExpression(cartItem?.quantity, pkgItem?.operator, pkgItem?.quantity) : pkgItem.quantity,
                Description: pkgItem.articleDto?.description,
                Price: 0,
                VAT: 0
            }));

            packageItemOrders?.forEach(o => orders.push(o));
        }
        setOrderRows(orders);
        computeOrderData(cartItems);
      }
    }, [cartItems])

    useEffect(() => {
        if(projects){
          setProjectList(projects)
        }
    }, [projects])

    useEffect(()=> {
      if(selectedProject == null) return;
      document.querySelector('select[name=project]').value = selectedProject;
    }, [selectedProject])

    useEffect(()=> {
      if(customerUsers && !isEmpty(customerUsers)){
        // document.querySelector('select[name=yourReference]').value = customerUsers[0];
        setYourReference(customerUsers[0].id);
      }
    }, [customerUsers])

    function onGetShippingInfo() {
      get(`/api/delivery-address?customerId=${customerId}`)
      .then(data => {
          if(data?.status !== 200) {
              setError([props.t(API_ERR_MSG)])
          } else {
            setShippingInfoList(data?.data);
          }
      })
      .catch(err => {
        setError([props.t(API_ERR_MSG)])
    })
    }

    function handleOrderSubmit() {
      const err = [];
      if(!CustomerName || isEmpty(CustomerName)){
        err.push(props.t("Delivery Name is required field"));
      }
      if(!standardDelivery && (!deliveryDate || deliveryDate === '')){
        err.push(props.t("Unable to place order. Please choose the Requested Delivery Date."));
      }
      if(!selectedShippingInfo){
        err.push(props.t("Unable to place order. Delivery address is required"));
      }
      setError(err);
      let selectedCountryCode;
      if(err.length === 0){
        if(Country && !isEmpty(Country) && Country !== ""){
          selectedCountryCode = Object.keys(countries)
          .filter(key => countries[key] === Country)
          .map(key => key)[0];
        }
        
        const orderData = {
          orderDetails: {
            Order: {
              CustomerName,
              CustomerNumber,
              Address1,
              Address2,
              Country,
              City,
              ZipCode,
              OrderRows,
              Project: selectedProject,
              DocumentNumber: "",
              YourReference: yourReference,
              YourOrderNumber: orderNumber,
              DeliveryAddress2: find(shippingInfoList, {id: parseInt(selectedShippingInfo)})?.address,
              DeliveryAddress1: deliveryReference,
              DeliveryDate: !standardDelivery && deliveryDate ? parseDateToString(deliveryDate) : "",
            } 
          },
          deliveryAddressId: selectedShippingInfo,
        }

        onCreateOrder(orderData);
      }
    }

    const getArticlePrice = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : orderitem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    return priceList?.price;
                }
            }
            return mPrice;
        }
        //return price ? price * orderitem?.quantity : 0;
        return price;
    }

    const getArticlePriceWithQuantity = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : orderitem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    return priceList?.price;
                }
            }
            return mPrice;
        }
        return price ? price * orderitem?.quantity : 0;
    }

    const getArticlePackagePrice = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : orderitem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    return priceList?.price;
                }
            }
            return mPrice;
        }
        return price ? price : 0;
    }

    function computeOrderData(orderItems) {
      let ordersTotal = 0;
      let ordersSubtotal = 0;
      let ordersTax = 0;
      let ordersDiscount = 0;

      orderItems.forEach(orderitem => {
        const orderItemPrice = getArticlePriceWithQuantity(orderitem);
        ordersSubtotal += orderItemPrice;
        ordersTax += (orderItemPrice * orderitem.article.vat) / 100;
      })
      ordersTotal = ordersSubtotal + ordersTax - ordersDiscount;

      setSubTotal(ordersSubtotal);
      setDiscount(ordersDiscount);
      setTax(ordersTax);
      setTotal(ordersTotal);
    }

    function submitAddNewShippingInfo(shippingInfoId) {
      toggleAddNewShippingInfo(shippingInfoId, true);
      setEditShippingInfoId(shippingInfoId);
      onGetShippingInfo();
      if(shippingInfoId){
        setSelectedShippingInfo(shippingInfoId);
      }
    }

    async function submitAddEditProject(projectNumber){
      toggleAddNewProject(projectNumber, true);
      const project = find(projectList, {ProjectNumber: projectNumber});
      editProject(project ? project.id : null);
      setEditProjectId(project ? project.id : null);
      await onGetProjects(customerId);
      if(projectNumber && projectNumber != null){
        setSelectedProject(projectNumber);
      }
    }

    const clearError = () => setError([])

    const futureDate = () => {
      let d = new Date()
      d.setDate(d.getDate() + 2)
      return d;
    }

    const parseExpression = (oprand1, operator, oprand2) => {
        operator = operator == "x" ? "*" : "/";
        return Math.round(evaluate(oprand1 + " " +  operator + " " + oprand2));
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid className={orderPending || loading ? "low-opacity" : null}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={props.t("Products")} breadcrumbItem={props.t("Checkout")} />

            
            {/*{orderSuccess != null && (
              <div className="mt-4 text-center">
                <Alert color="success">{orderSuccess}</Alert>
              </div>
            )}*/}

            <Loader loading={orderPending || loading} />
  
            {orderSuccess === null && (
            <div className="checkout-tabs">
              <Row>
                <Col xl="6">
                  <div className="custom-accordion">
                    <Card>
                      <Link onClick={toggle} className="text-dark" to="#">
                        <div className="p-4">
                          <Media className="d-flex align-items-center">
                            <div className="me-3">
                              <i className="uil uil-receipt text-primary h2"></i>
                            </div>
                            <div className="flex-1 overflow-hidden">
                              <h5 className="font-size-16 mb-1">{props.t("Billing Info")}</h5>
                            </div>
                            <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                          </Media>
                        </div>
                      </Link>
                      <Collapse isOpen={isOpen}>
                        <AvForm
                          name="deliveryForm" 
                          className="form-horizontal"
                          disabled={orderPending}
                        >
                        <div className="p-4 border-top">                         
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3 mb-4">
                                    <AvField
                                      name="CustomerName"
                                      label={props.t("Name")}
                                      value={CustomerName}
                                      className="form-control"
                                      placeholder={props.t("")}
                                      type="text"
                                      required
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3 mb-4">
                                    <AvField
                                        name="Address1"
                                        label={props.t("Address") + " 1"}
                                        value={Address1}
                                        className="form-control"
                                        placeholder={props.t("")}
                                        type="text"
                                        disabled
                                      />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <div className="mb-3 mb-4">
                                    <AvField
                                        name="Address2"
                                        label={props.t("Address") + " 2"}
                                        value={Address2}
                                        className="form-control"
                                        placeholder={props.t("")}
                                        type="text"
                                        disabled
                                      />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <div className="mb-4 mb-3">
                                    <AvField
                                      name="Country"
                                      label={props.t("Country")}
                                      value={Country}
                                      className="form-control"
                                      placeholder={props.t("")}
                                      type="text"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-4 mb-3">
                                    <AvField
                                      name="City"
                                      label={props.t("City")}
                                      value={City}
                                      className="form-control"
                                      placeholder={props.t("")}
                                      type="text"
                                      disabled
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-4 mb-3">
                                    <AvField
                                      name="ZipCode"
                                      label={props.t("Zip Code")}
                                      value={ZipCode}
                                      className="form-control"
                                      placeholder={props.t("")}
                                      type="text"
                                      validate={{number: true}}
                                      disabled
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                  <Col lg={6}>
                                      <div className="mb-3 mb-4">
                                          <AvField
                                              name="yourReference"
                                              label={props.t("Your Reference")}
                                              value={yourReference}
                                              className="form-control"
                                              placeholder={props.t("")}
                                              onChange={(e) => {
                                                setYourReference(e.target.value);
                                              }}
                                              type="select"
                                              disabled={loading}
                                          >
                                            {customerUsers.map((u, key) => <option value={u.id}>{u.name}</option>)}
                                          </AvField>
                                      </div>
                                  </Col>
                                  <Col lg={6}>
                                      <div className="mb-3 mb-4">
                                        <AvField
                                            className="form-control"
                                            type="text"
                                            label={props.t("Order Reference")}
                                            placeholder={props.t("Enter Order Reference")}
                                            name="orderNumber"
                                            value={orderNumber}
                                            onChange={(e) => {
                                                setOrderNumber(e.target.value);
                                            }}
                                            disabled={loading}
                                        />
                                      </div>
                                  </Col>
                              </Row>
                            </div>
                         </div>
                        </AvForm>
                      </Collapse>
                    </Card>
                    <Card>
                      <div className="p-4">
                        <Row>
                          <div className="col-md-12">
                            <Media className="d-flex align-items-center">
                              <div className="me-3">
                              <i className="uil uil-truck text-primary h2"></i>
                              </div>
                              <div className="flex-1 overflow-hidden">
                                <h5 className="font-size-16 mb-1">{props.t("Delivery Info")}</h5>
                              </div>
                            </Media>
                          </div>
                        </Row>
                      </div>
                      <div className="p-3 border-top">
                        <Card>
                          <CardHeader>
                            <div className="row">
                              <div className="col-md-6">
                                {props.t("Delivery Address")}
                              </div>
                              <div className="col-md-5">
                                <select
                                  name="shippingInfo" 
                                  className="form-control form-select select2"
                                  title={props.t("Select Shipping Address")}
                                  value={selectedShippingInfo}
                                  onChange={(e) => {
                                    setSelectedShippingInfo(e.target.value);
                                    toggleAddNewShippingInfo(e.target.value, false);
                                    editShippingInfo(e.target.value)
                                    clearError();
                                  }}
                                >
                                  <option key="default" value="" disabled selected>{props.t("Mandatory")}</option>
                                  {shippingInfoList?.map((shippingInfo, index) => (
                                  <option key={index} value={shippingInfo.id}>Name: {shippingInfo.name}, Delivery Address: {shippingInfo.address}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-1">
                                  <Button
                                      color="primary"
                                      className="btn btn-primary waves-effect waves-light text-center"
                                      style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                      onClick={() => toggleAddNewShippingInfo()}
                                  >
                                      <i className="uil uil-plus"></i>
                                  </Button>
                              </div>
                            </div>
                          </CardHeader>
                          <CardBody>
                            {/*<div className="text-center">
                              <Button
                                color="primary"
                                className="btn btn-primary waves-effect waves-light text-center"
                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                onClick={() => toggleAddNewShippingInfo()}
                              >
                                {props.t("Add New Delivery Address")}
                              </Button>
                            </div>*/}
                            <Collapse isOpen={isOpenAddNewShippingInfo || selectedShippingInfo}>
                              <div className="pt-4">
                                <DeliveryAddressForm
                                  location={props.location} 
                                  shippingInfoId={selectedShippingInfo}
                                  customerId={customerId}
                                  callback={submitAddNewShippingInfo}
                                  setEditMode={() => toggleAddNewShippingInfo(selectedShippingInfo, false)}
                                  disableFieldsByDefault={selectedShippingInfo !== null}
                                  addOrderNumberField={true}
                                  setOrderNumber={(val) => setOrderNumber(val)}
                                />
                              </div>
                            </Collapse>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            {props.t("Delivery Details")}
                          </CardHeader>
                          <CardBody>
                            <AvForm
                              name="deliveryForm" 
                              className="form-horizontal"
                              disabled={orderPending}
                              >
                                <Row>
                                  <Col lg={12}>
                                    <div className="mb-3">
                                      <AvField
                                        className="form-control"
                                        type="text"
                                        label={props.t("Delivery Reference")}
                                        placeholder={props.t("Enter Delivery Reference")}
                                        name="deliveryReferene"
                                        value={deliveryReference}
                                        onChange={(e) => {
                                            setDeliveryReference(e.target.value);
                                        }}
                                        disabled={loading}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className="row">
                                  <div>
                                    <div className="mb-1 d-flex flex-column">
                                      <Label className="d-block">Shipping Date :</Label>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          id="standardDelivery"
                                          name="standardDelivery"
                                          className="form-check-input"
                                          value={standardDelivery}
                                          checked={standardDelivery}
                                          onChange={(e) => {
                                            setError([]);
                                            setStandardDelivery(e.target.checked);
                                            setDeliveryDate('');
                                          }}
                                        />
                                        <Label
                                          htmlFor="standardDelivery"
                                        >
                                          Standard Delivery Date
                                        </Label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          id="expectedDelivery"
                                          name="expectedDelivery"
                                          className="form-check-input"
                                          value={!standardDelivery}
                                          checked={!standardDelivery}
                                          onChange={(e) => {
                                            setError([]);
                                            setStandardDelivery(!(e.target.checked));
                                          }}
                                        />
                                        <Label
                                          htmlFor="expectedDelivery"
                                        >
                                          Requested Delivery Date
                                        </Label>
                                      </div>
                                    {!standardDelivery &&
                                    <div className="row mx-2">
                                      <div className="col-lg-4 col-md-4 col-12 col-sm-6">
                                        <DatePicker 
                                          onChange={(date) => {
                                            setError([]);
                                            setDeliveryDate(date);
                                          }} 
                                          value={deliveryDate}
                                          disabled={loading}
                                          name={"deliveryDate"}
                                          minDate={futureDate()}
                                          format={"yyyy-MM-dd"}
                                          placeholder={props.t("Enter Delivery Date")}
                                          dayPlaceholder={"dd"}
                                          monthPlaceholder={"mm"}
                                          yearPlaceholder={"yyyy"} />
                                      </div>
                                    </div>}
                                    </div>
                                  </div>
                                </div>
                            </AvForm>
                          </CardBody>
                        </Card>
                      </div>
                    </Card>
                    <Card>
                      <div className="p-4">
                        <Row>
                          <div className="col-md-6">
                            <Media className="d-flex align-items-center">
                              <div className="me-3">
                                <i className="uil uil-apps text-primary h2"></i>
                              </div>
                              <div className="flex-1 overflow-hidden">
                                <h5 className="font-size-16 mb-1">{props.t("Project Info")}</h5>
                              </div>
                            </Media>
                          </div>
                          {/* <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                          >
                              {props.t("Select Project")}
                          </label> */}
                          <div className="col-md-5">
                            <select
                              name="project" 
                              className="form-control form-select select2"
                              title={props.t("Select Project")}
                              value={selectedProject !== null ? selectedProject : ""}
                              onChange={(e) => {
                                setSelectedProject(e.target.value);
                                toggleAddNewProject(e.target.value, false);
                                const project = find(projectList, {projectNumber: e.target.value});
                                editProject(project ? project.id : null);
                                clearError();
                              }}
                            >
                              <option key="default" value="" selected>{props.t("Optional")}</option>
                              {projectList?.map(project => (
                                <option key={project.projectNumber} value={project.projectNumber}>
                                  ProjectNumber: {project.projectNumber}, Description: {project.description}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-1">
                              <Button
                                  color="primary"
                                  className="btn btn-primary waves-effect waves-light text-center"
                                  style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                  onClick={() => toggleAddNewProject()}
                              >
                                  <i className={"uil uil-plus"}></i>
                              </Button>
                          </div>
                          {/*<div className="col-md-3">
                            <p style={{textAlign: 'right', color: 'grey'}} className="pt-2 font-size-11">*optional</p>
                          </div>*/}
                        </Row>
                      </div>
                      <div className="p-3 border-top">
                        {/*<div className="text-center">
                          <Button
                            color="primary"
                            className="btn btn-primary waves-effect waves-light text-center"
                            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                            onClick={() => toggleAddNewProject()}
                          >
                            {props.t("Add New Project")}
                          </Button>
                        </div>*/}
                        <Collapse isOpen={isOpenAddNewProject || !isEmpty(selectedProject)}>
                          <div className="pt-4">
                            <ProjectForm 
                              resetFormData={!isOpenAddNewProject} 
                              location={props.location} 
                              projectId={editProjectId} 
                              customerId={customerId}
                              avoidRedirect={true} 
                              callback={submitAddEditProject}
                              setEditMode={() => toggleAddNewProject(selectedProject, false)}
                              disableFieldsByDefault={!isEmpty(selectedProject)}
                            />
                          </div>
                        </Collapse>
                      </div>
                    </Card>
                  </div>
                  <Row className="mb-4">
                    <Col>
                      <Link to="/customer/categories" className="btn btn-link text-muted">
                        <i className="uil uil-arrow-left me-1"></i> {props.t("Continue Shopping")}
                      </Link>
                    </Col>
                    <Col>
                      <div className="text-sm-end mt-2 mt-sm-0">
                        <Button onClick={() => handleOrderSubmit()}
                                className="btn btn-success"
                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                disabled={orderPending}>
                          <i className="uil uil-shopping-cart-alt me-1"></i> {props.t("Confirm Order") }
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {error.length > 0 && (<Alert className="text-align-center" color="danger">{error.map((msg, index) => index === 0 ? msg : (<><br />{msg}</>))}</Alert>)}
                  {orderError != null && (
                      <Row>
                        <Col>
                          <div className="mt-4">
                            <Alert color="danger">{orderError}</Alert>
                          </div>
                        </Col>
                      </Row>
                  )}
                </Col>
                <Col xl="6">
                  <Card className="checkout-order-summary">
                    <CardBody>
                      <div className="p-3 bg-light mb-4">
                        <h5 className="font-size-16 mb-0">{props.t("Order Summary")}</h5>
                      </div>

                      <div className="table-responsive">
                        <Table className="table-centered mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th className="border-top-0" style={{ width: "110px" }} scope="col">{props.t("Product")}</th>
                              <th className="border-top-0" scope="col">{props.t("Product Desc")}</th>
                              <th className="border-top-0" scope="col">{props.t("Quantity")}</th>
                              <th className="border-top-0" scope="col">{props.t("Unit Price")}</th>
                              <th className="border-top-0" scope="col">{props.t("Price")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((orderitem, key) => (
                              <tr key={"_orderSummary_" + key}>
                                <th scope="row">
                                  <img
                                    src={orderitem.article.articleImages 
                                      && orderitem.article.articleImages.length > 0 ? 
                                      BASE_IMAGE_URL + "/" + orderitem.article.articleImages[0].media.s3Key
                                      : articlePlaceholderImg}
                                    alt="product-img"
                                    title="product-img"
                                    className="avatar-md"
                                  />
                                </th>
                                <td>
                                  <div className='d-flex'>
                                    <h5 className="font-size-14 text-wrap">
                                      <Link
                                        to="/ecommerce-product-detail"
                                        className="text-dark"
                                      >
                                        {orderitem.article.description}{" "}
                                      </Link>
                                    </h5>
                                    {cartItems[key].article.articlePackageType == "MANUAL" &&
                                    <div className="product-ribbon badge bg-soft-success bg-success mb-2 ms-2 px-2 d-flex align-items-center justify-content-center">
                                        {find(cartItems[key].article.articlePackage, {quantity: cartItems[key].quantity})?.name}
                                    </div>}
                                  </div>
                                  {/*<p className="text-muted mb-0">
                                      {orderitem?.article.articlePackageType === "MANUAL" && <>
                                          {getArticlePrice(orderitem)} kr
                                      </>}
                                      {orderitem?.article.articlePackageType !== "MANUAL" && <>
                                          {orderitem.quantity} x {getArticlePackagePrice(orderitem)} kr
                                      </>}
                                  </p>*/}
                                  {isStockable(cartItems[key].article) && cartItems[key].article.disposableQuantity <= 0 && cartItems[key].quantity > 0 &&
                                        <div className="d-flex p-1">
                                    <span className="alert-danger border border-danger font-size-11 rounded p-1">
                                        {props.t("Out of Stock")}
                                    </span>
                                        </div>}
                                    {isStockable(cartItems[key].article) && cartItems[key].article.disposableQuantity > 0 && cartItems[key].article.disposableQuantity < cartItems[key].quantity &&
                                        <div className="d-flex p-1">
                                    <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                            {props.t("Up to")} {parseInt(cartItems[key].article.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                    </span>
                                        </div>}
                                    {cartItems[key].article.articlePackageType == "MANUAL" &&
                                    <ol className='pb-0 ps-0 mb-0' style={{fontSize: "12px"}}>
                                        {find(cartItems[key].article.articlePackage, {quantity: cartItems[key].quantity})?.packageItems?.map(function (i, key) {
                                          return (
                                            <li className='d-flex justify-content-between'>
                                              <label className='mb-0'>{key}. {i?.articleDto?.description}</label>
                                              {i?.quantity ? <label className='mb-0 ms-3' style={{minWidth: '75px'}}>{props.t("Qty")} - {i?.quantity}</label> : ''}
                                            </li>)
                                        })}
                                    </ol>}
                                    {cartItems[key].article.articlePackageType == "DYNAMIC" &&
                                        <ol className='pb-0 ps-0 mb-0' style={{listStyle: "none"}}>
                                            {cartItems[key].article.articlePackage[0]?.packageItems?.map(function (i) {
                                                return (
                                                    <li className='d-flex justify-content-between'>
                                                        <label className='mb-0'>{key}. {i?.articleDto?.description}</label>
                                                        {i?.quantity ? <label className='mb-0 ms-3' style={{minWidth: '75px'}}>{props.t("Qty")} - {parseExpression(cartItems[key].quantity , i?.operator, i?.quantity)}</label> : ''}
                                                    </li>)
                                            })}
                                        </ol>}
                                </td>
                                <td className="vertical-align-top">
                                    {orderitem.quantity}
                                </td>
                                <td className="vertical-align-top">
                                    {getArticlePrice(orderitem)} kr
                                </td>
                                <td className="vertical-align-top">
                                  {getArticlePriceWithQuantity(orderitem)} kr
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="4">
                                <h6 className="font-size-14 m-0">
                                  {props.t("Sub Total")}:
                                </h6>
                              </td>
                              <td>{subTotal} kr</td>
                            </tr>
                            {discount > 0 && <tr>
                              <td colSpan="4">
                                <h5 className="font-size-14 m-0">{props.t("Discount")} :</h5>
                              </td>
                              <td>
                                - {discount} kr
                              </td>
                            </tr>}

                            <tr>
                              <td colSpan="4">
                                <h5 className="font-size-14 m-0">{props.t("VAT")} :</h5>
                              </td>
                              <td>
                                {tax} kr
                              </td>
                            </tr>

                            <tr className="bg-light">
                              <td colSpan="4">
                                <h5 className="font-size-14 m-0">{props.t("Total")}:</h5>
                              </td>
                              <td>
                                  {total} kr
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>)}
          </Container>
        </div>
      </React.Fragment>
    )

    function getProjectInfoCard(){
      const project = find(projectList, {ProjectNumber: selectedProject});
      if(project){
        return (
          <Card 
            className={"border rounded shipping-address active"}
          >
            <CardBody>
              <Link className="float-end ml-1" onClick={() => editProject(project.id)}>
                <i className="uil uil-pen font-size-16"></i>
              </Link>
              <h5 className="mb-2">Project Details</h5>
              <h6 className="mb-0">ProjectNumber: {project.ProjectNumber}, Description: {project.Description}</h6>
              <p className="mb-0">ContactPerson: {project.ContactPerson ? project.ContactPerson : ""}</p>
              <p className="mb-0">Status: {project.Status ? project.Status : ""}</p>
              <p className="mb-0">StartDate: {project.StartDate ? project.StartDate : ""}</p>
              <p className="mb-0">EndDate: {project.EndDate ? project.EndDate : ""}</p>
            </CardBody>
          </Card>
        )
      }
    }
}

ShopCheckout.propTypes = {
  onGetCustomerInfo: PropTypes.func,
  onCreateOrder: PropTypes.func,
  customer: PropTypes.object,
  orderError: PropTypes.object,
  orderSuccess: PropTypes.string,
  cartItems: PropTypes.array,
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  customerUsers: PropTypes.array,
  onGetCustomerUsers: PropTypes.func,
}

const mapStatetoProps = state => {
  const { customer, loading, orderError, orderSuccess, orderPending } = state.ecommerce
  const {cartItems} = state.Cart
  const projects = state.Project.projects
  const { customerUsers, customerUsersLoading }= state.Customer
  return { 
    customer, cartItems, projects, orderError, orderSuccess, orderPending, 
    loading: loading || customerUsersLoading, 
    customerUsers 
  };
}

const mapDispatchToProps = dispatch => ({
  onGetCustomerInfo: (id) => dispatch(getCustomerInfo(id)),
  onCreateOrder: (orderData) => dispatch(createOrder(orderData)),
  onGetProjects: (customerId) => dispatch(getProjectList(customerId)),
  onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId)),
})

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(ShopCheckout))
)
