import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";
import ReactLoading from "react-loading";

// Redux
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation";

// import images
import logo from "../../assets/images/lightab_logo.svg"
import logolight from "../../assets/images/lightab_logo.svg"
import {get, post} from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

import {withTranslation} from "react-i18next";
import { SPINNER_DEFAULT_COLOR } from "../../util/constants";
import Loader from "../../components/Common/Loader";

const CreatePassword = (props) => {

  const [brandSettings, setBrandSettings] = useState(null);
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
  }, [])

  const showError = (msg) => {
    setError(msg);
    document.querySelectorAll("input").forEach(function (element) {
      element.addEventListener("input", function () {
        setError("");
      });
    });
  };

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    let response = await post(url.POST_ACTIVATE_USER, values);
    if (response.status == 200) {
      window.location.href = "/login";
    } else {
      showError(props.t("There was an error!"));
    }
  };

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [activationToken, setActivationToken] = useState("");
  const [error, setError] = useState("");

  const updateMessageOnError = (err) => {
    if (err?.response?.status == 404) {
      setMessage(props.t("Invalid or Expired link"));
    } else {
      setMessage(props.t("There was an error. Please contact admin."));
    }
  };

  useEffect(() => {
    document.body.className = "authentication-bg";

    const params = new URLSearchParams(window.location.search);
    let token = params.get("token");
    async function validateToken() {
      try {
        let response = await get(`/api/auth/activate/${token}`);
        if (response?.isAxiosError) {
          throw { response: { status: response?.response?.status } };
        }
        setActivationToken(response.data.accessToken);
      } catch (err) {
        updateMessageOnError(err);
      }
      setLoading(false);
    }
    if (token) {
      validateToken();
    } else {
      setMessage(props.t("Missing token"));
    }
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const validateEqualPassword = (value, context) => {
    return value == context.password || props.t("Passwords don't match");
  };

  const Footer = () => (
    <div className="mt-5 text-center">
      <p>© {new Date().getFullYear()} Lightab. Crafted with <i
        className="mdi mdi-heart text-danger"></i> by Flowsense
      </p>
    </div>
  );

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container className="align-center">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={logo} alt="" height="40" className="logo logo-dark" />
                  <img src={logolight} alt="" height="40" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          {(() => {
            if (loading) {
              return (
                <Row className="align-items-center justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card>
                      <CardBody className="p-4">
                        <div className="p-2" style={{ textAlign: "center" }}>
                          <Loader loading={loading} defaultLoader={true} />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            } else if (message.length === 0) {
              return (
                <Row className="align-items-center justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card m-0>
                      <CardBody className="p-4">
                        <div className="p-2 mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v);
                            }}
                          >
                            {error && typeof error === "string" ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}

                            <div className="text-center mt-2">
                              <h5 className="text-primary">Create New Password</h5>
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="password"
                                label={props.t("Password")}
                                className="form-control"
                                type="password"
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                name="confirmPassword"
                                label={props.t("Confirm Password")}
                                type="password"
                                required
                                validate={{validateEqualPassword}}
                              />
                            </div>

                            <AvField
                              name="activationToken"
                              type="hidden"
                              value={activationToken}
                            />

                            <div className="mt-3">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                type="submit"
                              >
                                {props.t("Submit")}
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </CardBody>
                    </Card>
                    <Footer />
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row className="align-items-center justify-content-center">
                  <Col md={8} lg={6} xl={5}>
                    <Card>
                      <CardBody className="p-4">
                        <Alert color="danger mb-0">{message}</Alert>
                      </CardBody>
                    </Card>
                  </Col>
                  <Footer />
                </Row>
              );
            }
          })()}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps)(
    withTranslation()(CreatePassword)
  )
);

CreatePassword.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object
};
