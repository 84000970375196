import {call, put, takeEvery} from "redux-saga/effects"
import {getSMTPConfig, getSystemConfig, getTenantConfig, getArticleConfig, getStockPoints, getStockPointConfig, getCronLogs, getCurrencyList, getProjectConfig} from "../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../util/constants";
import {getCronLogError, getCronLogSuccess, startAction, stopAction} from "../actions";
import {
    getSMTPConfigFail,
    getSMTPConfigSuccess,
    getSystemConfigFail,
    getSystemConfigSuccess,
    getTenantConfigFail,
    getTenantConfigSuccess,
    getArticleConfigFail,
    getArticleConfigSuccess,
    getStockPointConfigSuccess,
    getStockPointConfigFail,
    getStockPointsSuccess,
    getStockPointsFail,
    getCurrencyListSuccess,
    getCurrencyListFail,
    getProjectConfigSuccess,
    getProjectConfigFail
} from "./actions";
import {GET_SMTP_CONFIG, GET_SYSTEM_CONFIG, GET_TENANT_CONFIG, GET_ARTICLE_CONFIG, GET_STOCK_POINT_CONFIG, GET_STOCK_POINTS, GET_CRON_LOG, GET_CURRENCY_LIST, GET_PROJECT_CONFIG} from "./actionTypes";


function* fetchSystemConfig({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getSystemConfig)
        yield put(getSystemConfigSuccess(response.data))
    } catch (error) {
        yield put(getSystemConfigFail(error))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchTenantConfig({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getTenantConfig)
        yield put(getTenantConfigSuccess(response.data))
    } catch (error) {
        yield put(getTenantConfigFail(error))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchSMTPConfig({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getSMTPConfig)
        yield put(getSMTPConfigSuccess(response.data))
    } catch (error) {
        yield put(getSMTPConfigFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchArticleConfig({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getArticleConfig)
        yield put(getArticleConfigSuccess(response.data))
    } catch (error) {
        yield put(getArticleConfigFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchProjectConfig() {
    try {
        const response = yield call(getProjectConfig)
        yield put(getProjectConfigSuccess(response.data))
    } catch (error) {
        yield put(getProjectConfigFail(API_ERR_MSG))
    }
}

function* fetchStockPointConfig({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getStockPointConfig)
        yield put(getStockPointConfigSuccess(response.data))
    } catch (error) {
        yield put(getStockPointConfigFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchStockPoints({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getStockPoints)
        yield put(getStockPointsSuccess(response.data))
    } catch (error) {
        yield put(getStockPointsFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}


function* fetchCronLog({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getCronLogs);
        yield put(getCronLogSuccess(response.data))
    } catch (e) {
        yield put(getCronLogError(e))
    } finally {
        yield put(startAction(type))
    }
}


function* fetchCurrencyList() {
    try {
        const response = yield call(getCurrencyList);
        yield put(getCurrencyListSuccess(response.data))
    } catch (e) {
        yield put(getCurrencyListFail(API_ERR_MSG))
    }
}


function* configSaga() {
    yield takeEvery(GET_SYSTEM_CONFIG, fetchSystemConfig)
    yield takeEvery(GET_TENANT_CONFIG, fetchTenantConfig)
    yield takeEvery(GET_SMTP_CONFIG, fetchSMTPConfig)
    yield takeEvery(GET_ARTICLE_CONFIG, fetchArticleConfig)
    yield takeEvery(GET_STOCK_POINT_CONFIG, fetchStockPointConfig)
    yield takeEvery(GET_STOCK_POINTS, fetchStockPoints)
    yield takeEvery(GET_CRON_LOG, fetchCronLog)
    yield takeEvery(GET_CURRENCY_LIST, fetchCurrencyList)
    yield takeEvery(GET_PROJECT_CONFIG, fetchProjectConfig)
}

export default configSaga
