import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap"

// availity-reactstrap-validation
// Redux
import {connect} from "react-redux"

//Import Breadcrumb
import avatar from "../../assets/images/users/user-img-placeholder-2.png"
import {BASE_IMAGE_URL} from "../../helpers/url_helper";

// actions
import {editProfile, getCustomerForAM, getCustomerForAMReset, getOrderForAM, getOrderForAMReset, getUserInfo, impersonateUser, resetProfileFlag, userChangePassword} from "../../store/actions"
import {withTranslation} from "react-i18next";
import classnames from 'classnames';
import {useHistory, useLocation, withRouter} from "react-router-dom";
import TableToolbar from "../../components/Common/TableToolbar";
import {Avatar} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import Loader from '../../components/Common/Loader';
import TaskManagement from '../Tasks/TaskManagement';
import OverridePwdModal from "../../components/Common/OverridePwdModal";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {MDBDataTable} from "mdbreact";
import {downloadPDF} from "../../util/helperFunctions";
import CustomerOrderList from "../Orders/CustomerOrderList";
import EventManagement from "../Audit/EventManagement";
import {formatCurrency} from "../../helpers/currency_helper";
import ProjectListManagement from '../Projects/ProjectListManagement';
import CustomerTimeslips from "../Customer/CustomerTimeslips";

const MyAccount = props => {
    const {onEditProfile,
        onGetUserInfo,
        onUserChangePassword,
        onResetProfileFlag,
        userInfoLoading,
        customersForAM,
        customersForAmLoading,
        onGetCustomers,
        Orders,
        orderForAMLoading,
        orderForAMError,
        onGetOrders,
        customersForAmError,
        onGetOrdersReset,
        onImpersonateUser,
        editProfileLoading
    } = props;
    const [email, setemail] = useState("")
    const [name, setname] = useState("")
    const [profileImageId, setProfileImageId] = useState(null)
    const [idx, setidx] = useState("")
    const [existingPassword] = useState("")
    const [newPassword] = useState("")
    const [confirmNewPassword] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)
    const [selectedFile, setselectedFile] = useState(null)
    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('3');
    const [imageUploading, setImageUploading] = useState(false);
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);
    const [search, setSearch] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [role, setRole] = useState("");



    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            setBrandSettings(obj?.brandConfig);
            setidx(obj.uid);
            // onGetUserInfo(obj.uid);
        }
    }, [])

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        if(["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(role)){
            onGetCustomers(obj.uid);
            onGetOrders(obj.uid);
        }
    }, [role])

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        if(!editProfileLoading){
            onGetUserInfo(obj.uid);
        }
    }, [editProfileLoading])

    useEffect(() => {
        const {name, email, profileImage} = props.user
        setname(name)
        setemail(email)
        setProfileImageId(profileImage?.s3Key)
        if(props?.user?.roles && props?.user?.roles[0]?.name){
            setRole(props?.user?.roles[0]?.name);
        }
    }, [props.user])

    useEffect(() => {
        setTimeout(() => {
           onResetProfileFlag();
        }, 3000);
    }, [onResetProfileFlag])

    function handleSubmitUpdateProfile(event, values) {
        onEditProfile({userId: idx, name: values.name, email: values.email, profileImageId: selectedFile?.id})
    }

    const handleNewPasswordChange = () => {
        setConfirmPasswordError(false);
    }

    function handleSubmitChangePassword(event, values) {
        if (values.newPassword !== values.confirmNewPassword) {
            setConfirmPasswordError(true)
        } else {
            const {idx, existingPassword, newPassword} = values
            onUserChangePassword({userId: idx, existingPassword, newPassword})
        }
    }

    const getActionButtons = () => {
        let actions = [
            {
                text: props.t("Override Password"),
                onClick: togglePwdModal,
            },
        ];
        return actions;
    };

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function handleEdit() {
        history.push(`/settings/user-profile/edit/${props?.user?.id}`);
    }

    const getDatatableData = () => {
        const rows = customersForAM?.filter((row) =>
            row.name?.toLowerCase().includes(search?.toLowerCase())
        );
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("City"),
                    field: "city",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                    sort: "asc",
                },
                {
                    label: props.t("Impersonate"),
                    field: "impersonate",
                    sort: "asc",
                }
            ],
            rows: rows?.map((customer, key) => ({
                name: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.name}</span>,
                city: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.city}</span>,
                phone:<span onClick={() => handleOpen(customer.customerNumber)}>{customer.phone}</span>,
                impersonate: <><button onClick={()=> handleImpersonate(customer)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}><i className="fa fa-sign-in-alt"></i></button></>
            })),
        };
        return data;
    };

    const getOrderDatatableData = () => {
        const rows = Orders;
        let data = {
            columns: [
                {
                    label: props.t("Order Number"),
                    field: "orderNumber",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Total"),
                    field: "amount",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("OrderDate"),
                    field: "date",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Download"),
                    field: "download",
                }
            ],
            rows: rows?.map((order, key) => ({
                orderNumber: <div onClick={() => openOrderDetails(order)}>{order.DocumentNumber}</div>,
                amount: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.Total)}</div>,
                date: <div onClick={() => openOrderDetails(order)}>{order.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            })),
        };
        return data;
    };

    const openOrderDetails = (order) => {
        const fromUrl = `?from=` + encodeURIComponent(props.location.pathname);
        history.push(`/orders/${order.DocumentNumber + fromUrl}`);
    };

    const handleOpen = (id) => {
        history.push(`/customers/${id}/profile`);
    };

    const handleImpersonate = (customer) => {
        onImpersonateUser({ customerId: customer.id }, history, location)
    }


    return (
        <React.Fragment>
            <div className="page-content">
            <Loader loading={userInfoLoading || editProfileLoading} title="Profile" />
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: "/settings/users"}]}
                        title={props?.user?.name}
                        canEdit={handleEdit}
                    />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("My Account")}
                                        buttons={getActionButtons()}
                                    />
                                </CardHeader>
                            </Card>
                        </Col>
                        <Col lg="12">
                            {props.error && props.error ? (
                                <Alert color="danger">{props.error}</Alert>
                            ) : null}
                            {props.success && props.success ? (
                                <Alert color="success" className="text-align-center">{props.success}</Alert>
                            ) : null}
                        </Col>
                        {(!userInfoLoading && !editProfileLoading) && <>
                        <Col xl="4">
                            <Card className="card">
                                <CardBody>
                                    <div className="text-center">
                                        <label htmlFor='icon-button-file'>
                                                <Avatar
                                                    variant="rounded"
                                                    src={editProfileLoading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                    style={{
                                                        margin: "10px",
                                                        width: "100px",
                                                        height: "100px",
                                                        borderRadius: "100px"
                                                    }}
                                                >
                                                    <PersonIcon/>
                                                </Avatar>
                                        </label>
                                        <h5 className="mb-1">{props.user?.name}</h5>
                                        {props.user?.email && <p className="text-muted"><a href={"mailto:"+ props.user?.email}>{props.user?.email}</a></p>}
                                    </div>

                                    <hr className="my-4"/>

                                    <div className="text-muted">
                                        <div className="table-responsive mt-4">
                                            {props.user?.title && <div className="mt-4">
                                                <p className="mb-1">{props.t("Title")} :</p>
                                                <h5 className="font-size-16">{props.user?.title}</h5>
                                            </div>}
                                            <div>
                                                <p className="mb-1">{props.t("Name")} :</p>
                                                <h5 className="font-size-16">{props.user?.name}</h5>
                                            </div>
                                            {props.user?.phone && <div className="mt-4">
                                                <p className="mb-1">{props.t("Phone")} :</p>
                                                <h5 className="font-size-16"><a href={"tel:" + props.user?.phone}>{props.user?.phone}</a></h5>
                                            </div>}
                                            {props.user?.mobile && <div className="mt-4">
                                                <p className="mb-1">{props.t("Mobile")} :</p>
                                                <h5 className="font-size-16"><a href={"tel:" + props.user?.mobile}>{props.user?.mobile}</a></h5>
                                            </div>}
                                            <div className="mt-4">
                                                <p className="mb-1">{props.t("E-mail")} :</p>
                                                <h5 className="font-size-16"><a href={"mailto:" + props.user?.email}>{props.user?.email}</a></h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">{props.t("Timezone")} :</p>
                                                <h5 className="font-size-16">{props.user?.timezone}</h5>
                                            </div>
                                            {props.user?.city && <div className="mt-4">
                                                <p className="mb-1">{props.t("City")} :</p>
                                                <h5 className="font-size-16">{props.user?.city}</h5>
                                            </div>}
                                            {props.user?.fortnoxUsername && <div className="mt-4">
                                                <p className="mb-1">{props.t("Fortnox username")} :</p>
                                                <h5 className="font-size-16">{props.user?.fortnoxUsername}</h5>
                                            </div>}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                            <Col xl="8">
                                <Card className="mb-0">
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    {["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(role) &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                profiletoggle('1');
                                            }}
                                        >
                                            <i className="uil uil-users-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Customers")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(role) &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => {
                                                profiletoggle('2');
                                            }}
                                        >
                                            <i className="uil uil-clipboard-notes font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Order")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(role) &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '4'})}
                                            onClick={() => {
                                                profiletoggle('4');
                                            }}
                                        >
                                            <i className="uil uil-apps font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Projects")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"].includes(role) &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '5'})}
                                            onClick={() => {
                                                profiletoggle('5');
                                            }}
                                        >
                                            <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                        </NavLink>
                                    </NavItem>}
                                    {/*{["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"].includes(role) &&*/}
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '6'})}
                                            onClick={() => {
                                                profiletoggle('6');
                                            }}
                                        >
                                            <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Events")}</span>
                                        </NavLink>
                                    </NavItem> {/*}*/}
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '3'})}
                                            onClick={() => {
                                                profiletoggle('3');
                                            }}
                                        >
                                            <i className="uil-channel-add font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Tasks")}</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '7'})}
                                            onClick={() => {
                                                profiletoggle('5');
                                            }}
                                        >
                                            <i className="uil uil-clock font-size-20"></i>
                                            <span className="d-none d-sm-block">{props.t("Timeslips")}</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} className="p-4">
                                    <TabPane tabId="1">
                                        {customersForAmError && (
                                            <Col className="col-12">
                                                <Alert color="danger">{customersForAmError}</Alert>
                                            </Col>
                                        )}
                                        {!customersForAmLoading &&
                                            <MDBDataTable
                                                responsive
                                                barReverse={true}
                                                hover
                                                borderless
                                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                entriesLabel={props.t("Show entries")}
                                                infoLabel={[
                                                    props.t("Showing"),
                                                    props.t("to"),
                                                    props.t("of"),
                                                    props.t("entries"),
                                                ]}
                                                noRecordsFoundLabel={props.t("No matching records found")}
                                                searchLabel={props.t("Search") + "..."}
                                                displayEntries={500}
                                                sortRows={[
                                                    "created",
                                                    "description",
                                                    "articleNumber",
                                                    "category",
                                                ]}
                                                noBottomColumns={true}
                                                searching={true}
                                                entries={500}
                                                entriesOptions={[10, 100, 500]}
                                                data={getDatatableData()}
                                                onSearch={(text) => console.log(text)}
                                            />}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {orderForAMError && (
                                            <Col className="col-12">
                                                <Alert color="danger">{orderForAMError}</Alert>
                                            </Col>
                                        )}
                                        {!orderForAMLoading &&
                                            <MDBDataTable
                                                responsive
                                                barReverse={true}
                                                hover
                                                borderless
                                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                entriesLabel={props.t("Show entries")}
                                                infoLabel={[
                                                    props.t("Showing"),
                                                    props.t("to"),
                                                    props.t("of"),
                                                    props.t("entries"),
                                                ]}
                                                searchLabel={props.t("Search") + "..."}
                                                noBottomColumns={true}
                                                data={getOrderDatatableData()}
                                                entries={50}
                                                entriesOptions={[50, 100, 500]}
                                                onSearch={(text) => console.log(text)}
                                                searching={true}
                                                noRecordsFoundLabel={props.t("No matching records found")}
                                            />}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TaskManagement profileUser={props.user} />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <ProjectListManagement uid={props?.user?.id}/>
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <CustomerOrderList cid={props?.user?.id}/>
                                    </TabPane>
                                    <TabPane tabId="6">
                                        <EventManagement userId={props?.user?.id}/>
                                    </TabPane>
                                    <TabPane tabId="7">
                                        <CustomerTimeslips filter={{userId: props?.user?.id}} showAddTask={true}/>
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col></>}
                    </Row>
                </Container>
            </div>
            <OverridePwdModal
                t={(text) => props.t(text)}
                showOverridePwdModal={showOverridePwdModal && props.user?.id}
                toggleModal={() => togglePwdModal()}
                userId={props.user?.id}
            />
        </React.Fragment>
    )
}

MyAccount.propTypes = {
    onEditProfile: PropTypes.func,
    onGetUserInfo: PropTypes.func,
    onUserChangePassword: PropTypes.func,
    onResetProfileFlag: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    user: PropTypes.object,
    changePwdError: PropTypes.any,
    changePwdSuccessMsg: PropTypes.any,
    userInfoLoading: PropTypes.any

}

const mapStatetoProps = state => {
    const {error, success, user, userInfoLoading, editProfileLoading} = state.Profile
    const {changePwdError, changePwdSuccessMsg} = state.ChangePassword
    const {customersForAmLoading, customersForAmError, customersForAM} = state.Customer
    const {Orders, orderForAMLoading,orderForAMError} = state.ecommerce.orderForAM
    return {user, error, success, userInfoLoading, changePwdError, changePwdSuccessMsg, customersForAM, customersForAmLoading, customersForAmError, Orders, orderForAMLoading,orderForAMError, editProfileLoading}

}

const mapDispatchToProps = dispatch => ({
    onEditProfile: (data) => dispatch(editProfile(data)),
    onGetUserInfo: (id) => dispatch(getUserInfo(id)),
    onUserChangePassword: (data) => dispatch(userChangePassword(data)),
    onResetProfileFlag: () => dispatch(resetProfileFlag()),
    onGetCustomers : (id) => dispatch(getCustomerForAM(id)),
    onGetOrders: (id) => dispatch(getOrderForAM(id)),
    onGetCustomersReset : () => dispatch(getCustomerForAMReset()),
    onGetOrdersReset: () => dispatch(getOrderForAMReset()),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
})

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(MyAccount))
)
