import {
    GET_PROJECT_LIST,
    GET_PROJECT_LIST_BY_PARTICIPANT,
    GET_PROJECT_LIST_BY_PARTICIPANT_FAIL,
    GET_PROJECT_LIST_BY_PARTICIPANT_RESET,
    GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS,
    GET_PROJECT_LIST_FAIL,
    GET_PROJECT_LIST_RESET,
    GET_PROJECT_LIST_SUCCESS, GET_PROJECT_USERS, GET_PROJECT_USERS_FAIL, GET_PROJECT_USERS_SUCCESS,
    GET_PROJECT_LIST_BY_LEADER,
    GET_PROJECT_LIST_BY_LEADER_FAIL,
    GET_PROJECT_LIST_BY_LEADER_RESET,
    GET_PROJECT_LIST_BY_LEADER_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    projects: [],
    loading: false,
    error: null,
    projectByParticipant: [],
    projectByParticipantLoading: false,
    users:[],
    usersLoading:false,
    projectByLeaderLoading: false,
    projectByLeader: []
};

const Project = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROJECT_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                projects: action.payload,
            }
        case GET_PROJECT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_PROJECT_LIST_RESET:
            return {
                ...state,
                projects: [],
                loading: false,
                error: null,
            }
        case GET_PROJECT_LIST_BY_PARTICIPANT:
            return {
                ...state,
                projectByParticipantLoading: true
            }
        case GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS:
            return {
                ...state,
                projectByParticipant: action.payload,
                projectByParticipantLoading: false
            }
        case GET_PROJECT_LIST_BY_PARTICIPANT_FAIL:
            return {
                ...state,
                projectByParticipantLoading: false,
                error: action.payload
            }
        case GET_PROJECT_LIST_BY_PARTICIPANT_RESET:
            return {
                ...state,
                projectByParticipantLoading: false,
                projectByParticipant: [],
                error: null,
            }
        case GET_PROJECT_USERS:
            return {
                ...state,
                users: [],
                usersLoading: true
            }
        case GET_PROJECT_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                usersLoading: false
            }
        case GET_PROJECT_USERS_FAIL:
            return {
                ...state,
                users:[],
                usersLoading: false
            }
            
        case GET_PROJECT_LIST_BY_LEADER:
            return {
                ...state,
                projectByLeaderLoading: true
            }
        case GET_PROJECT_LIST_BY_LEADER_SUCCESS:
            return {
                ...state,
                projectByLeader: action.payload,
                projectByLeaderLoading: false
            }
        case GET_PROJECT_LIST_BY_LEADER_FAIL:
            return {
                ...state,
                projectByLeaderLoading: false,
                error: action.payload
            }
        case GET_PROJECT_LIST_BY_LEADER_RESET:
            return {
                ...state,
                projectByLeaderLoading: false,
                projectByLeader: [],
                error: null,
            }
        default:
            return state;
    }
};

export default Project
