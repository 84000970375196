import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import classnames from "classnames";
import {find, isEmpty} from "lodash";
import {BASE_IMAGE_URL, DOWNLOAD_URL} from "../../helpers/url_helper";
import {getContentTypeForArticle, getFavourites, getGroupsByArticle} from "../../store/e-commerce/actions";
import {withTranslation} from "react-i18next";
import {CartService} from "../ShoppingCart/CartService";
import {getAllCartItems} from "../../store/e-commerce/cart/actions";
import {Avatar, Chip, Divider} from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "../../assets/scss/done.scss";
import "./productCarousel.css";
import useStyles from "../../util/styles";
import TableToolbar from "../../components/Common/TableToolbar";
import {get} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import {getArticleConfig} from "../../store/actions";
import EventManagement from "../Audit/EventManagement";
import CustomerOrderTable from "../Customer/CustomerOrderTable";
import DropdownSelect from "../../components/Common/DropdownSelect";
import {isStockable} from "../../util/articleUtil";
import NotFoundPage from "../../components/Error/NotFoundPage";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";
import {preventNumberInputScroll} from "../../util/helperFunctions";
import Dashboard from "../Dashboard";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";


const ArticlesDetail = (props) => {
  const classes = useStyles();
  const {
    product,
    showBuyOption,
    onGetContentTypes,
    cartItems,
    onGetArticleConfig,
    articleConfig,
    error,
    onGetFavourites,
    favourites,
    getFavouritesLoading,
    cartItemsLoading,
    groupByArticle,
    onGetGroupListByArticle,
  } = props;
  const [productTab, setProductTab] = useState({});
  const [activeDescriptionTab, setDescriptionActiveTab] = useState("");
  // const [quantity, setQuantity] = useState(0);
  const [image, setImage] = useState(null);
  const [brandSettings, setBrandSettings] = useState(null);
  const [favorite, setFavorite] = useState(false);
  const [favoriteList, setFavoriteList] = useState([]);
  const [articleProductList, setArticleProductList] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [articleFavouriteListLoading, setArticleFavouriteListLoading] = useState(false);
  const [productOptions, setProductOptions] = useState();
  let qtyClickCount = 0, qtyPrevClickCount = 0;

  useEffect(() => {
    onGetArticleConfig();
    setBrandSettings(user?.brandConfig);
    onGetFavourites()
    onGetProductOptions();
  }, []);

  useEffect(() => {

    if(!isEmpty(product)) {
      setProductTab({});
      onGetContentTypes(product.id);
      setFavorite(product?.favorite);
      if(product.tabs && product.tabs.length > 0){
        setDescriptionActiveTab(product.tabs[0].id);
      }
    }
  }, [product])

  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if(groups.length == 0 && product?.id){
      onGetGroupListByArticle(product.id);
    }
  }, [product.id])

  useEffect(() => {
    setGroups(groupByArticle);
  }, [groupByArticle])

  useEffect(() => {
    if(favourites){
      setFavoriteList(favourites);
      if(user?.customerId){
        getAndSetArticleProductList();
      }
    }
  }, [favourites])

  useEffect(() => {
    let cart = cartItems?.reduce((obj, cur) => {
      return { ...obj, [cur["article"]["id"]]: cur };
    }, {});
    if (cart && cart[product.id]) {
      // setQuantity(cart[product.id].quantity);
    } else {
      // setQuantity(0);
    }
  }, [cartItems, product]);

  const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);
  }

  const getAndSetArticleProductList = async () => {
    if(user?.customerId && product.id){
      setArticleFavouriteListLoading(true);
      try {
        const response = await get(`/api/productLists/articles/${product.id}/productListArticleInfo`);
        setFavoriteList(favourites.map(f => {
          const paf = response.data?.find(af => af.favoriteList.id === f.id);
          return {...f, label: !paf || paf?.customer ? f.name : f.name + '*', disabled: !(!paf || paf?.customer)};
        }))
        setArticleProductList(response.data === "" ? null : response.data.map(item => item.favoriteList));
      } catch(err){
        cogoToast.error(err.rsponse?.data[0], toastOptions);
      }
      setArticleFavouriteListLoading(false);
    }
  }

  const toggledescription = (tab) => {
    if (activeDescriptionTab !== tab) {
      setDescriptionActiveTab(tab);
    }
  };

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  };

  const switchImageUrl = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = BASE_IMAGE_URL + "/" + img;
  };

  const openCategoryProduct = (category) => {
    let level = localStorage.getItem("level");
    if(level == "TENANT"){
      history.push(`/articles?categoryId=${category.id}`);
    } else if (showBuyOption) {
      history.push(`/customer/categories/${category.id}/articles`);
    }
  };

  const openGroupsProduct = (group) => {
    let level = localStorage.getItem("level");
    if(level == "TENANT"){
      history.push(`/articles?groupId=${group.id}`);
    }
  };

  const handleDownload = (url) => {
    window.location.href = url;
  };

  const getQuantityRowValue = (item) => {
      if(!isStockable(item)){
          return "";
      }

      if(articleConfig && item?.disposableQuantity){
          if(articleConfig.articleQuantityConfig === "COLOR_CODING"){
              return item.disposableQuantity > 0 ?
              <div className="product-ribbon badge bg-soft-success bg-success float-end mt-3">
                  {" "}
                  {props.t("In Stock")}{" "}
              </div> :
              <div className="product-ribbon badge bg-soft-danger bg-danger float-end mt-3">
                  {" "}
                  {props.t("Out of Stock")}{" "}
              </div>
          } else {
              return (
              <label className="text-muted font-size-13 float-end mx-1">
                  <p className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}><i className="uil-shopping-cart-alt"></i> &nbsp;{item.disposableQuantity}</p>
              </label>)
          }
      } else {
          return "0";
      }
  }

  const getFieldValue = (field) => {
    var result = []
    if (field.fieldType == "ASSOCIATION_FIELD") {
      let fieldValue = {field: field, value: product[field.associationField]}
      result.push(fieldValue);
    } else {
      for (var i = 0; i < product?.fieldList?.length; i++) {
        if (product.fieldList[i].field.id === field.id) {
          result.push(product.fieldList[i]);
        }
      }
    }
    return result;
  }

  const handleSelectedProductList = async (values) => {
    setArticleFavouriteListLoading(true);
    try {
      if(values.length > articleProductList.length){
        let value = values.filter(v => !(find(articleProductList, {id: v.id})))
        await get(`/api/productLists/articles/${product.id}/add?productListId=${value[0]?.id}`);
      } else {
        let value = articleProductList.filter(v => !(find(values, {id: v.id})));
        await get(`/api/productLists/${value[0]?.id}/articles/${product.id}/remove`);
      }
      if(values.length == 0 && favoriteList.length == 0){
        await onGetFavourites();
      }
        getAndSetArticleProductList()
    } catch (err) {
      cogoToast.error(err.response?.data[0], toastOptions);
    }
    setArticleFavouriteListLoading(false);
  }

  const getRangeValue = (fieldValue) => {
    let unit = fieldValue.field?.unit ? fieldValue.field?.unit : "";
    if (fieldValue.minValue && fieldValue.maxValue) {
      return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit + " - " + Number(parseFloat(fieldValue.maxValue)).toLocaleString('sv') + " " + unit;
    } else if(fieldValue.minValue) {
      return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit;
    }
    return "";
  }

  function getArticleQuantityInCart(){
    const cartItem = find(cartItems, item => item.article.id == product.id);
    return cartItem ? cartItem.quantity : 0;
  }

  const handleQuantityChange = (product, decrementQuantity) => {
    qtyPrevClickCount = qtyClickCount;
    qtyClickCount++;
    setTimeout(() => {
      // check if input is clicked multiple times or just one time in 1sec timespan
      if((qtyClickCount != 0 && qtyPrevClickCount == qtyClickCount) || (qtyClickCount == 1 && qtyPrevClickCount == 0)){
        const articleQty = getArticleQuantityInCart();
        let inputQty = 0;
        if(decrementQuantity){
          // quantity cannot go below 0
          if(articleQty - qtyClickCount > 0){
            inputQty = articleQty - qtyClickCount;
          }
        } else {
          inputQty = articleQty + qtyClickCount;
        }
        CartService.addItemsToCart(product, inputQty);

        // reset counts
        qtyClickCount = 0;
        qtyPrevClickCount = 0;
      } else {
        // if all the clicks are done and 1 sec passed then set prev click count to current to execute the above if condition
        qtyPrevClickCount = qtyClickCount;
      }
    }, 1000)
  }

  function onGetProductOptions() {
    get(`/api/article/labelValue`)
        .then(data => {
          if (data?.status === 200) {
            setProductOptions(data?.data);
          }
        });
  }

  const handleArticleChange = (article) => {
    //history.push()
    window.location.href = `/articles/${article?.value}`
  }

  const handleEdit = () => {
    history.push(`/articles/${product?.articleNumber}/edit`);
  };
const ArticleDetailView = () => {
  const quantity = getArticleQuantityInCart();
  return (
    <>{error && (
      <Alert className="text-align-center" color="danger">
        {error}
      </Alert>
    )}
    {!isEmpty(product) && <>
    <div className={user?.customerId ? '' : "d-flex"}>

    <Row style={{'max-width': user?.customerId ? '' : '1200px', 'width': '100%'}}>
      <Col xl="6">
        {product !== null && product?.articleImages && (
          <ImageGallery
            items={
              // images
              product?.articleImages?.map((item) => {
                return {
                  original: BASE_IMAGE_URL + "/" + item.media.s3Key,
                  thumbnail: BASE_IMAGE_URL + "/" + item.media.s3Key,
                };
              })
            }
          />
        )}
      </Col>

      <Col xl="6">
        <div className="ps-xl-4 min-height-250">
          <div className="article-detail-top-row">
            <div>
              {product.categories != null &&
                product.categories?.map(function (element) {
                  return (
                    <Chip
                      avatar={
                        <Avatar className={classes.chipsAvatar}>
                          {element.name.slice(0, 1).toUpperCase()}
                        </Avatar>
                      }
                      onClick={() => openCategoryProduct(element)}
                      className={`${classes.chips} p-1 mb-1`}
                      size="small"
                      label={element.name}
                      clickable
                      color="primary"
                      variant="outlined"
                    />
                  );
                })}
              {groups?.map(function(element){
                  return (
                      <Chip
                          avatar={
                            <Avatar className={classes.chipsAvatar}>
                              {element.name.slice(0, 1).toUpperCase()}
                            </Avatar>
                          }
                          onClick={() => openGroupsProduct(element)}
                          className={`${classes.chips} p-1 mb-1`}
                          size="small"
                          label={element.name}
                          clickable
                          color="secondary"
                          variant="outlined"
                      />
                  )})
              }
            </div>
              {showBuyOption &&
                (favoriteList && favoriteList.length > 0 ?
                  <DropdownSelect
                  options={favoriteList}
                  values={favoriteList.filter(f => articleProductList?.find(af => af.id === f.id))}
                  searchBy="name"
                  valueField="id"
                  labelField="label"
                  placeholder={props.t("Add to Product List")}
                  multi={true}
                  color={brandSettings?.primaryBtnColor}
                  onChange={handleSelectedProductList}
                  clearable={false}
                  loading={articleFavouriteListLoading || getFavouritesLoading} /> :
                <Button
                  onClick={() => handleSelectedProductList([])}
                  style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}>
                  {props.t("Add to Product List")}
                </Button>)}
          </div>
          <div className="row m-3">
            <Divider />
          </div>
          <div className="row">
            <h4 className="font-size-20 fw-bold">
              {product.displayName
                ? product.displayName
                : product.description}
            </h4>
            <div className="col-lg-6 mt-4 pt-2">
              {showBuyOption == true && <>
                <h5 className=" fs-2">
                  {product.specialPrice
                      ? product.specialPrice
                      : product.salesPrice}{" "}
                  kr
                </h5>
              </>}

              {showBuyOption == false && <>
                {product?.articlePriceLists?.map(function(item){
                  if(item?.priceListCode){
                    return <h6>{item?.priceListCode + "/" + item?.priceList?.description + ": "+ item?.price + " kr"}</h6>
                  }
                })
              }
              </>}

            </div>
            <div className="col-lg-5 text-align-right mt-4 pt-2">
              {isStockable(product) &&
              <>
                <label>
                  {user?.customerId ? getQuantityRowValue(product) : <p className={product.disposableQuantity > 0 ? "text-success" : "text-danger"}>{product.disposableQuantity > 0 ? props.t("In Stock") : props.t("Out of Stock")}: {product.disposableQuantity}</p>}
                </label>
                <br />
              </>}
              <label>
                <i className="uil-notes"></i>
                {product.articleNumber}
              </label>
            </div>
          </div>
          {
            <p className="mt-4 text-muted" dangerouslySetInnerHTML={{__html: product.longDescription}}>
            </p>
          }


         {/* <ArticleGroupPreview articleId={product.id} showBuyOption={showBuyOption}/>*/}
          <Row>
            {groups?.map(function(group){
              return (<Col lg={12}>
                <p>{group.description}</p>
              </Col>)
            })}
          </Row>
          <Row>
            {groups?.map(function(group){
              return (<>
                {group.fields?.map(function(content){
                  return (
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          {content?.name}
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>{props.t("Select")}</option>
                            {group?.articleList?.map(function(article){
                              return (<>
                                {article?.fieldList?.map(function(articleContent){
                                  if(articleContent?.field?.id === content.id){
                                    return (<>
                                      <option>{articleContent.value}</option>
                                    </>);
                                  }
                                })}
                              </>);
                            })}
                          </select>
                        </div>
                      </Row>
                  )
                })}
              </>);
            })}
          </Row>


          {showBuyOption && (
            <Row className="text-center mt-2">
              {product.articlePackageType == "MANUAL" &&
              <div className="col-sm-3 mx-auto d-grid">
                <select
                  className="form-control"
                  value={quantity ? quantity : 0}
                  onChange={(e) => CartService.addItemsToCart(product, e.target.value ? e.target.value : 0)}
                >
                  <option value={0}>{props.t("Add to cart")}</option>
                  {product.articlePackage.map(pkg => <option value={pkg.quantity}>{pkg.name}</option>)}
                </select>
              </div>}
              {(quantity < 1 && product.articlePackageType != "MANUAL") && (
                <div className="col-sm-3 d-grid">
                  <Button
                    type="button"
                    color="primary"
                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                    className="btn-block waves-effect waves-light mt-2 me-1  width-130px"
                    onClick={(e) => {
                      e.preventDefault();
                      CartService.addItemToCart(product);
                    }}
                  >
                    <i className="uil uil-shopping-cart-alt me-2"></i>
                    {props.t("Add to cart")}
                  </Button>
                </div>
              )}
              {(quantity > 0 && product.articlePackageType != "MANUAL") && (
                <div className="col-sm-4 d-grid">
                  <div className="btn-block waves-effect waves-light mt-2 me-1 d-flex">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-block waves-effect waves-light"
                      style={{
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor
                      }}
                      onClick={(e) => handleQuantityChange(product, true)}
                      disabled={cartItemsLoading}
                    >
                      <i className="uil uil-minus"></i>
                    </Button>
                    <input
                      className="form-control"
                      style={{ borderRadius: "0" }}
                      type="number"
                      value={getArticleQuantityInCart()}
                      onChange={(e) => {
                        CartService.addItemsToCart(
                          product,
                          e.target.value
                        );
                      }}
                      onInput={(e) => {
                        CartService.addItemsToCart(
                          product,
                          e.target.value
                        );
                      }}
                      onWheel={preventNumberInputScroll}
                      // onKeyDown={validateNumber}
                    />
                    <Button
                      type="button"
                      color="primary"
                      className="btn-block waves-effect waves-light"
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor
                      }}
                      onClick={(e) => handleQuantityChange(product, false)}
                      disabled={cartItemsLoading}
                    >
                      <i className="uil uil-plus"></i>
                    </Button>
                  </div>
                </div>
              )}
            </Row>
          )}
        </div>
      </Col>
    </Row>
    </div>
    <div className={user?.customerId ? '' : "d-flex"}>
    <Row style={{'max-width': user?.customerId ? '' : '1200px', 'width': '100%'}}>
    <div className="mt-4" key={"content_detail_" + product.id} style={{'max-width': user?.customerId ? '' : '1200px', 'width': '100%'}}>
      <div className="product-desc">
        <Nav tabs className="nav-tabs-custom">
          {product?.tabs?.map(function (tab) {
            return (
              <NavItem className="cursor-pointer">
                <NavLink
                  className={classnames({
                    active:
                      activeDescriptionTab === tab.id,
                  })}
                  onClick={() => {
                    toggledescription(tab.id);
                  }}
                >
                  {props.t(tab.name)}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent
          activeTab={activeDescriptionTab}
          className="border border-top-0 p-4"
        >
          {product?.tabs?.map(function (tab){
            if (tab.categoryType === "FILES") {
              return (<TabPane tabId={tab.id}>
                {tab.fields?.map(function (field) {
                  return (<div className="col-lg-12 mt-2">
                    {getFieldValue(field).map(fieldValue => <button
                        className="btn btn-primary btn-sm m-2"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        onClick={() => handleDownload(DOWNLOAD_URL + "/" + fieldValue.value)}
                    >
                      {fieldValue?.downloadLabel}
                    </button>)}
                  </div>);
                })}
              </TabPane>)
            } else if (tab.categoryType === "IMAGES") {
              return (<TabPane tabId={tab.id}>
                <div className="row">
                  {tab.fields?.map(function (field) {
                      return (<>
                        {getFieldValue(field).map(fieldValue => <div className="col-lg-2">
                          <img className="certificate-thumbnail height-150" src={DOWNLOAD_URL + "/" + fieldValue.value}/>
                        </div>)}
                      </>);
                  })}
                </div>
              </TabPane>)
            } else if (tab.categoryType === "FREE_TEXT") {
              return (<TabPane tabId={tab.id}>
                <div className="row">
                  {tab.fields?.map(function (field) {
                      return (<>
                      {getFieldValue(field).map(fieldValue => fieldValue.field.fieldType === 'FREE_TEXT' &&
                          <div className="col-lg-12" dangerouslySetInnerHTML={{__html: fieldValue.value}}></div>)}
                      </>);
                  })}
                </div>
              </TabPane>)
            } else {
              return (<TabPane tabId={tab.id}>
                <table className="table table-borderless table-hover">
                  <tbody>
                  {tab.fields?.map(function (field) {
                    return (<>
                      {getFieldValue(field).filter(fieldValue => fieldValue?.value).map((fieldValue) => (

                        <>{fieldValue?.field?.fieldType === "FILE" ?
                          (<tr>
                            <td colSpan={2}>
                              <p>
                                <a
                                    className={"btn btn-primary btn-sm"}
                                    href={DOWNLOAD_URL + "/" + fieldValue.value}
                                >
                                  {fieldValue.name}
                                </a>
                              </p>
                            </td>
                          </tr>)
                          : fieldValue?.field?.fieldType === "HEADER" ? (
                              <tr>
                                <td colSpan={2}>
                                  <h4> {fieldValue.field?.name} </h4>
                                </td>
                              </tr>
                          ):fieldValue?.field?.fieldType === "BOOLEAN" ? (
                              <tr>
                                <td>{fieldValue.field?.name}</td>
                                <td>
                                  {fieldValue?.value == "true" && <i className="uil uil-check-circle font-size-18" style={{color:"green"}}></i> }
                                  {fieldValue?.value == "false" && <i className="uil uil-times-circle font-size-18" style={{color:"red"}}></i> }
                                  {fieldValue?.value == null && props.t("Not applicable")}
                                </td>
                              </tr>
                          ):fieldValue?.field?.fieldType == "DROPDOWN_MULTISELECT" ? (
                            <tr>
                              <td>{fieldValue.field?.name}</td>
                              <td>
                                {fieldValue?.value.split(",").sort((a, b) => a.localeCompare(b)).map((value) => <><span className="bg-primary badge bg-secondary multi-select-label">{value}</span></>)}
                              </td>
                            </tr>
                          ):fieldValue?.field?.fieldType == "NUMBER_FIELD" ? (
                                <tr>
                                  <td>{fieldValue.field?.name}</td>
                                  <td>
                                    <span>
                                      {getRangeValue(fieldValue)}
                                    </span>
                                  </td>
                                </tr>
                            ):(<tr>
                            <td className="min-width-200">
                              {fieldValue?.field?.name} {fieldValue?.field?.contentTypes}
                            </td>
                            <td>
                              <span
                                  dangerouslySetInnerHTML={{
                                    __html: fieldValue.value + " " + (fieldValue.field?.unit ? fieldValue.field?.unit : ""),
                                  }}
                              ></span>
                            </td>
                          </tr>)}
                        </>
                        ))}
                    </>);
                  })}
                  </tbody>
                </table>
              </TabPane>)
            }
          })}
        </TabContent>
      </div>
    </div>
    </Row>
    </div>
    </>}</>
  )
}

return (
    <React.Fragment>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                      title={product.displayName ? product.displayName : product.description ? product.description : "Article Details"}
                      titleDropdown={{
                        isSearchableDropdown: true,
                        value: {id: product?.articleNumber, label: product.displayName ? product.displayName : product.description, value: product?.id},
                        options: productOptions,
                        placeholder: props.t("Select Article"),
                        isHeader: true,
                        handleSelect: (op) => {
                          console.log("OP", op);
                          handleArticleChange(op);
                        },
                        keyToDisplay: "label",
                      }}
                      canEdit={handleEdit}
                  />
              </CardHeader>

              <Loader loading={props.loading} title="Article Details" />
              {!props.loading &&
              <CardBody>

              {!props.loading && isEmpty(product) && (
                 <NotFoundPage/>
              )}
              {!isEmpty(product) && <>
              <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                      <NavLink
                          className={classnames({active: activeTab === '1'})}
                          onClick={() => {
                              toggle('1');
                          }}
                      >
                          <i className="uil uil-store font-size-20"></i>
                          <span className="d-none d-sm-block">{props.t("Article Details")}</span>
                      </NavLink>
                  </NavItem>
                <NavItem>
                  <NavLink
                      className={classnames({active: activeTab === '4'})}
                      onClick={() => {
                        toggle('4');
                      }}
                  >
                    <i className="uil uil-bolt-alt font-size-20"></i>
                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                  </NavLink>
                </NavItem>

                  <NavItem>
                      <NavLink
                          className={classnames({active: activeTab === '2'})}
                          onClick={() => {
                              toggle('2');
                          }}
                      >
                          <i className="uil uil-shopping-cart-alt font-size-20"></i>
                          <span className="d-none d-sm-block">{props.t("Orders")}</span>
                      </NavLink>
                  </NavItem>
                <NavItem>
                  <NavLink
                      className={classnames({active: activeTab === '6'})}
                      onClick={() => {
                        toggle('6');
                      }}
                  >
                    <i className="uil uil-invoice font-size-20"></i>
                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                  </NavLink>
                </NavItem>
                  <NavItem>
                      <NavLink
                          className={classnames({active: activeTab === '3'})}
                          onClick={() => {
                              toggle('3');
                          }}
                      >
                          <i className="uil uil-clipboard-notes font-size-20"></i>
                          <span className="d-none d-sm-block">{props.t("Events")}</span>
                      </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '7'})}
                        onClick={() => {
                          toggle('7');
                        }}
                    >
                      <i className="uil uil-chart font-size-20"></i>
                      <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                    </NavLink>
                  </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="p-4">
                  <TabPane tabId="1">
                    <ArticleDetailView />
                  </TabPane>
                  <TabPane tabId="2">
                    {product?.articleNumber && activeTab == "2" && <CustomerOrderTable articleNumber={product?.articleNumber} from={encodeURIComponent(props.location.pathname)}/>}
                  </TabPane>
                  <TabPane tabId="3">
                    {activeTab == "3" && <EventManagement articleEvents={true} articleId={product.id}/>}
                  </TabPane>
                  <TabPane tabId="4">
                    {product?.articleNumber && activeTab == "4" && <CustomerOfferTable articleNumber={product?.articleNumber}/>}
                  </TabPane>
                  <TabPane tabId="6">
                    {product?.articleNumber && activeTab == "6" && <CustomerInvoiceTable articleNumber={product?.articleNumber} />}
                  </TabPane>
                  <TabPane tabId="7">
                    {product?.articleNumber && activeTab == "7" && <Dashboard articleNumber={product?.articleNumber}/>}
                  </TabPane>
              </TabContent> </>}
            </CardBody>}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ArticlesDetail.propTypes = {
  product: PropTypes.object,
  contentTypes: PropTypes.array,
  onGetCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  groupByArticle: PropTypes.array,
  onGetGroupListByArticle: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    contentTypes: state.ecommerce.contentTypesForArticle,
    cartItems: state.Cart?.cartItems,
    articleConfig: state.Config.articleConfig,
    favourites: state.ecommerce.favourites,
    getFavouritesLoading: state.ecommerce.getFavouritesLoading,
    cartItemsLoading: state.Cart?.cartItemsLoading,
    groupByArticle: state.ecommerce.groupByArticle,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetContentTypes: (id) => dispatch(getContentTypeForArticle(id)),
  onGetCartItems: () => dispatch(getAllCartItems()),
  onGetArticleConfig: () => dispatch(getArticleConfig()),
  onGetFavourites: () => dispatch(getFavourites()),
  onGetGroupListByArticle: id => dispatch(getGroupsByArticle(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ArticlesDetail)));
