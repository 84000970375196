import React from "react";
import {Col, Container, Modal, Row,} from "reactstrap";
import {withTranslation} from "react-i18next";
import DeliveryAddressForm from "./DeliveryAddressForm";

const DeliveryAddressFormContainer = (props) => {

    const DeliveryAddressPage = (props) => 
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <DeliveryAddressForm customerId={props.customerId} location={props.location} clearField={true} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    const DeliveryAddressModal = ({modal_standard, tog_standard, useModal, customerId}) => 
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-delivery-address-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Add Delivery Address")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <DeliveryAddressForm
                    location={props.location}
                    clearField={true}
                    useModal={useModal}
                    tog_standard={tog_standard}
                    customerId={customerId}
                />
            </div>
        </Modal>
        
    return props.useModal ? <DeliveryAddressModal {...props} /> : <DeliveryAddressPage {...props} />;
};

export default withTranslation()(DeliveryAddressFormContainer);
