import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {get} from "../../helpers/api_helper";

const FortnoxStatus = (props) => {
    let user = JSON.parse(localStorage.getItem("authUser"));

    const [socketStatus, setSocketStatus] = useState(null);
    const [authTokenStatus, setAuthTokenStatus] = useState(null);
    const [dismiss, setDismiss] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkRefreshTokenStatus();
        checkSocketStatus();
    }, [])
    const checkSocketStatus = () => {
        get("/api/fortnox/socket/status").then(data => {
            if (data.status === 200 && (data?.data === true || data?.data === false)) {
               setSocketStatus(data?.data);
            } else {
                setSocketStatus(false);
            }
            setLoading(false);
        }).catch(data => {
            console.log("Failed");
            setSocketStatus(false);
            setLoading(false);
        })
    }

    const checkRefreshTokenStatus = () => {
        get("/api/fortnox/status").then(data => {
            if (data.status === 200) {
                setAuthTokenStatus(true);
                localStorage.setItem("fortnox-connection-status", true);
            } else {
                setAuthTokenStatus(false);
                localStorage.setItem("fortnox-connection-status", false);
            }
            setLoading(false)
        }).catch(error => {
            setAuthTokenStatus(false);
            localStorage.setItem("fortnox-connection-status", false);
            setLoading(false);
        })
    }

    if(!user?.roles?.includes("ROLE_SUPER_ADMIN")){
        return <>
            {!dismiss && !loading && <>
                {(!socketStatus || !socketStatus) && <div className="alert alert-danger alert-dismissible fade show" style={{marginBottom: "0"}} role="alert">
                    <i className="uil uil-exclamation-triangle" style={{marginRight: "10px", fontSize: "20px"}}></i>

                    {authTokenStatus ? "" : props.t("Error with Fortnox connection")}
                    {(socketStatus === false && authTokenStatus === true) ? props.t("Error with Socket connection") : ""}
                    {user?.roles?.includes("ROLE_TENANT_ADMIN") && <>
                        &nbsp;&nbsp; <a href={"/settings/fortnox-config"} class="alert-link">Click here</a>
                    </>}
                    {!user?.roles?.includes("ROLE_TENANT_ADMIN") && <> {props.t("Please contact Administrator")}</>}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={(e)=> setDismiss(true)}></button>
                </div>}
            </>}
        </>
    }
    return <></>
}

export default withTranslation()(FortnoxStatus)
