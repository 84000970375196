import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link, useHistory, withRouter} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Modal, Row,} from "reactstrap";

import {filter, isEmpty, orderBy} from "lodash";

// RangeSlider
import "nouislider/distribute/nouislider.css";

import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {get, put} from "../../helpers/api_helper";
//Import data
//import { discountData, colorData } from "../../../common/data";
//Import actions
import {getFavoriteProducts, getFavoriteProductsReset, getProducts} from "../../store/e-commerce/actions";

import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import {ARTICLE_EXPORT_URL, BASE_IMAGE_URL} from "../../helpers/url_helper";
import {MDBDataTable} from "mdbreact";
import {withTranslation} from "react-i18next";
import {Tooltip} from "@material-ui/core";
import CategoryRibbon from "../../components/Common/CategoryRibbon";
import {findIndex} from "lodash-es";
import Loader from "../../components/Common/Loader";
import InfiniteScrollbarWithLoader from "../../components/Common/InfiniteScrollbarWithLoader";
import {getArticleConfig, GetCustomers} from "../../store/actions";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {isStockable} from "../../util/articleUtil";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";

const ProductListArticles = (props) => {
    const {products, onGetProducts, metaInfo, loading,
        onGetProductsReset, error, onGetProductOptions, onGetCustomerOptions, productOptions, onGetArticleConfig,
        articleConfig, customerOptions, match: {params}} = props;
    const [isTable, setIsTable] = useState({
        value: false,
        label: props.t("Show grid"),
    });
    const history = useHistory();
    const [productList, setProductList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(8);
    const [totalItems, setTotalItems] = useState();

    const [search, setSearch] = useState("");

    const [brandSettings, setBrandSettings] = useState(null)
    const [modal_standard, setmodal_standard] = useState(false);
    const [articleOption, setArticleOption] = useState(null)
    const [customerOption, setCustomerOption] = useState(null)
    const [favouriteList, setFavouriteList] = useState(null);
    
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['disposableQuantity', 'reservedQuantity', 'salesPrice'];
    let user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        getFavouriteList();
        onGetProductOptions();
        onGetCustomerOptions();
        onGetArticleConfig();
        return () => {
            onGetProductsReset(true);
        }
    }, [])

    useEffect(() => {
      if(error){
        setTimeout(() => {onGetProductsReset()}, 5000);
      }
    }, [error])

    useEffect(() => {
        setPage(1);
        setProductList([]);
        if (!isTable.value) {
            onGetProducts(params.id, -1, -1, null);
        } else {
            onGetProducts(params.id, page - 1, sizePerPage, null);
        }
    }, [isTable, params.id]);

    useEffect(() => {
        getFavouriteList();
    }, [params.id])

    useEffect(() => {
        if (products && Array.isArray(products)) {
            setProductList(products);
        }
    }, [products]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setProductList(orderBy(productList, p => {
            let o = p.article;
            if(column === 'description') return o.displayName ? o.displayName?.toLowerCase() : o[column]?.toLowerCase()
            if(column === 'publish') return o[column]
            if(column === 'stockable') return o[column]
            if(column === 'customer') return p[column] ? p[column]?.name?.toLowerCase() : props.t('all')
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
        setTotalPage(metaInfo.totalPages);
        setTotalItems(metaInfo.totalItems);
    }, [metaInfo]);

    useEffect(() => {
        if(customerOptions && user?.customerId){
            setCustomerOption(customerOptions.find(co => co.id === user?.customerId));
        }
    }, [customerOptions])

    useEffect(() => {
        if (
            page !== 1 &&
            productList.length < totalItems &&
            !loading &&
            page <= totalPage
        ) {
            onGetProducts(params.id, page - 1, sizePerPage, search);
        }
    }, [page]);


    useEffect(() => {
        if (search) {
            setProductList([]);
            setPage(1);
            onGetProducts(params.id, 0, sizePerPage, search)
        }
    }, [search])

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
    }

    const getFavouriteList = async () => {
        if(params.id){
            const response = await get(`/api/productLists/${params.id}`);
            if(response.status === 200) {
                setFavouriteList(response.data)
                if(response.data?.customer){
                    setCustomerOption(response.data?.customer)
                }
            }
        }
    }

    const handleOpen = (id) => {
        history.push(user?.customerId ? `/customer/articles/${id}` :`/articles/${id}`);
    };

    const togglePublish = async (item) => {
        let response = await put(`/api/article/publish-toggle/${item.articleNumber}`);
        if(response.status === 200) {
            let updatedProducts = [...productList];
            var index = findIndex(updatedProducts, {id: item.id});
            updatedProducts.splice(index, 1, response.data);
            setProductList(updatedProducts);
        }
    };

    const getTitle = (name) => {
        return name.length > 94 ? name.substr(0, 91) + "..." : name;
    };

    const toggleGridView = () => {
        setIsTable({
            value: !isTable.value,
            label: props.t("Show grid"),
        });
    };

    const handleExportToExcel = (article) => {
        if(article){
            window.location.href = ARTICLE_EXPORT_URL + article.uuid;
        }
    }

    const getQuantityRowValue = (item) => {
        if(!isStockable(item)){
            return "";
        }

        if(articleConfig && item?.disposableQuantity){
            if(articleConfig.articleQuantityConfig === "COLOR_CODING"){
                return item.disposableQuantity > 0 ?
                    <div className="product-ribbon badge bg-soft-success bg-success">
                        {" "}
                        {props.t("In Stock")}{" "}
                    </div> :
                    <div className="product-ribbon badge bg-soft-danger bg-danger">
                        {" "}
                        {props.t("Out of Stock")}{" "}
                    </div>
            } else {
                return (
                    <label className="text-muted font-size-13 mx-1">
                        <p className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}><i className="uil-shopping-cart-alt"></i> &nbsp;{item.disposableQuantity}</p>
                    </label>)
            }
        } else {
            return "0";
        }
    }

    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Name"),
                field: "description",
                width: 300,
            },
            {
                label: props.t("Article Number"),
                field: "articleNumber",
                width: 100,
            },
            {
                label: props.t("Category"),
                field: "category",
                width: 200,
            },
            {
                label: props.t("Quantity"),
                field: "disposableQuantity",
                width: 100,
            },
            {
                label: props.t("Price"),
                field: "salesPrice",
                width: 100
            },
            {
                label: props.t("Reserved Quantity"),
                field: "reservedQuantity",
                width: 100,
            },
            {
                label: props.t("Published"),
                field: "publish",
                width: 200,
            },
            {
                label: props.t("Stockable"),
                field: "stockable",
                width: 200,
            },
            {
                label: props.t("Customer"),
                field: "customer",
                width: 200,
            },
            {
                label: props.t("Export"),
                field: "export",
                width: 50,
            },
            {
                label: props.t("Delete"),
                field: "delete",
                width: 50,
            },
        ];

        if(user?.customerId){
            cols.splice(5, 5);
        }

        let data = {
            columns: cols,
            rows: productList?.map(
                (item, key) => ({
                    description: <div onClick={() => handleOpen(item.article.articleNumber)}>{item.article.displayName ? item.article.displayName : item.article.description}</div>,
                    articleNumber: <div onClick={() => handleOpen(item.article.articleNumber)}>{item.article.articleNumber}</div>,
                    category: <div onClick={() => handleOpen(item.article.articleNumber)}><CategoryRibbon categoryList={item.article.categories}/></div>,
                    disposableQuantity: <div onClick={() => handleOpen(item.article.articleNumber)}>{getQuantityRowValue(item.article)}</div>,
                    reservedQuantity: <div  onClick={() => handleOpen(item.article.articleNumber)}>{item.article.stockable === false ? "" : item?.article.reservedQuantity ? item?.article.reservedQuantity : "0"}</div>,
                    publish:
                        item.article.publish === true ? (
                            <div className="product-ribbon badge bg-soft-success bg-success" onClick={() => handleOpen(item.article.articleNumber)}>
                                {" "}
                                {props.t("Published")}{" "}
                            </div>
                        ) : (
                            <div className="product-ribbon badge bg-soft-warning bg-warning" onClick={() => handleOpen(item.article.articleNumber)}>
                                {" "}
                                {props.t("Unpublished")}
                            </div>
                        ),
                    stockable:
                        item.article.stockable === false ? (
                            <div className="product-ribbon badge bg-soft-danger bg-danger" onClick={() => handleOpen(item.article.articleNumber)}>
                                {" "}
                                {props.t("Non-stockable")}{" "}
                            </div>
                        ) : (
                            <div className="product-ribbon badge bg-soft-primary bg-primary" onClick={() => handleOpen(item.article.articleNumber)}>
                                {" "}
                                {props.t("Stockable")}
                            </div>
                        ),
                    customer: <div onClick={() => handleOpen(item.article.articleNumber)}>{item.customer ? item.customer.name : props.t("All")}</div>,
                    export: <div onClick={() => handleExportToExcel(item.article)}><i className="fa fa-download font-size-10"></i></div>,
                    delete: <div onClick={() => {
                        if(!user?.customerId || item.customer?.name){
                            handleDeleteArticleFavourite(item)
                        }
                    }}><i className={`uil uil-trash-alt px-3 ${!user?.customerId || item.customer?.name ? 'text-danger' : 'text-muted'} font-size-18`}></i></div>
                })
            ),
        };
        return data;
    };

    const handleDeleteArticleFavourite = async (item) => {
        const response = await get(`/api/productLists/${params.id}/articles/${item.article.id}/remove?articleFavouriteId=${item.id}`);
        if(response.status === 202){
            onGetProducts(params.id, -1, -1, null);
        }
    }

    const getProducts = () => {
        return productList;
        /* return filter(
           productList,
           (product) =>
             includes(product.displayName?.toLowerCase(), search.toLowerCase()) ||
             includes(product.description?.toLowerCase(), search.toLowerCase())
             // ||
             // filter(product.categories, (c) =>
             //   includes(c.name?.toLowerCase(), search.toLowerCase())
             // ).length > 0
         );*/
    }

    const handleClearSearch = (e) => {
        if (e.key === 'Backspace' && search.length == 0) {
            setProductList([]);
            setPage(1);
            onGetProducts(0, sizePerPage, null);
        }
    }

    const handleAddArticle = async (showWarningForExistingArticle) => {
        let articleExists = null;
        if(articleOption && !customerOption){
            articleExists = filter(productList, p => p.article.id === articleOption.id && p.customer != null);
            if(articleExists && articleExists.length > 0 && showWarningForExistingArticle){
                setmodal_confirm(true);
                return;
            }
        } else if(articleOption && customerOption){
            const globalArticleExists = filter(productList, p => p.article.id === articleOption.id && !(p.customer));
            if(globalArticleExists && globalArticleExists.length > 0){
                cogoToast.error(props.t("Article already exists in product list for All customer."), toastOptions);
                return;
            }
        }
        try {
            if(articleExists && articleExists.length > 0){
                await handleDeleteArticleFavourite(articleExists[0]);
                setmodal_confirm(false);
            }
            await get(`/api/productLists/articles/${articleOption.id}/add?productListId=${params.id}${customerOption ? '&customerId=' + customerOption.id : ''}`)
            setArticleOption(null);
            if(!(user?.customerId)){
                setCustomerOption(null);
            }
            onGetProducts(params.id, -1, -1, null);
            tog_standard();
        } catch(err){
            if(err.response?.status === 400) cogoToast.error(props.t(err.response?.data[0]), toastOptions);
        }
    };

    const getProductView = (favouriteProducts, key) => {
        const product = favouriteProducts.article
        return (
            <div className="col-md-4 col-sm-6 col-xl-3 mb-3" key={"_col_" + key}>
                <div className="product-box mb-3">
                    <div className="d-flex justify-content-between align-items-center m-2">  
                        <div className="d-flex">
                            {product.publish == true ? (
                                <div className="product-ribbon badge bg-soft-success bg-success d-flex align-items-center">
                                    {props.t("Published")}
                                </div>
                            ) : null}
                            {!product.publish ? (
                                <div className="product-ribbon badge bg-soft-warning bg-warning d-flex align-items-center">
                                    {props.t("New")}
                                </div>
                            ) : null}
                            <div onClick={() => handleExportToExcel(product)} className="mx-2"><i className="fa fa-download font-size-10" ></i></div>
                        </div>
                        <div className="form-check form-switch float-right">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={product.publish}
                                style={product.publish ? {
                                    backgroundColor: brandSettings?.primaryBtnColor,
                                    borderColor: brandSettings?.primaryBtnColor
                                } : {}}
                                onChange={() => togglePublish(product)}
                            />
                        </div>
                    </div>
                    <div className="product-img pt-4 px-4">
                        <img
                            src={
                                product.articleImages &&
                                product.articleImages.length > 0
                                    ? BASE_IMAGE_URL +
                                    "/" +
                                    product.articleImages[0].media.s3Key
                                    : articlePlaceholderImg
                            }
                            alt=""
                            className="img-fluid mx-auto d-block"
                            onClick={() =>
                                history.push(
                                    `/articles/${product.articleNumber}`
                                )
                            }
                        />
                    </div>
                    <div
                        className="product-content p-4"
                        onClick={() =>
                            history.push(
                                `/articles/${product.articleNumber}`
                            )
                        }
                    >
                        <Tooltip
                            title={
                                product.displayName
                                    ? product.displayName
                                    : product.description
                            }
                        >
                            <h5 className="mb-1 article-title">
                                <Link
                                    to={`/articles/${product.articleNumber}`}
                                    className="text-dark"
                                >
                                    {getTitle(
                                        product.displayName
                                            ? product.displayName
                                            : product.description
                                    )}{" "}
                                </Link>
                            </h5>
                        </Tooltip>
                        <p className="text-muted article-title font-size-13">
                            {product.shortDescription?.length > 100
                                ? product.shortDescription.substr(0, 100) +
                                ".."
                                : product.shortDescription}
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                {!isStockable(product) ?
                                <div className="badge bg-soft-danger bg-danger p-2">
                                    {props.t("Non-stockable")}
                                </div> : 
                                <div className="badge bg-soft-primary bg-primary p-2">
                                    {props.t("Stockable")}
                                </div>}
                            </div>
                            <div className="col-md-6">
                                {isStockable(product) && 
                                <p className="text-muted font-size-13">
                                    <p className={product.quantityInStock > 0 ? "text-success" : "text-danger"}>{props.t("Quantity In Stock")}{" "}
                                    {product.quantityInStock}</p>
                                </p>}
                            </div>

                        </div>

                        <h5 className="mt-3 mb-0">
                            <span className="text-muted me-2">
                                <span>${product.salesPrice}</span>
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
    )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        title={props.t("Articles")}
                        breadcrumbs={[{title: props.t("Product Lists"), link: `${user?.customerId ? '/customer' : ''}/productLists`}]}
                        // toggle={{
                        //     active: isTable?.value,
                        //     onClick: toggleGridView,
                        //     label: (
                        //         <label
                        //             className="form-check-label"
                        //             htmlFor="customSwitchsizelg"
                        //         >
                        //             {props.t("Show grid")}
                        //         </label>
                        //     ),
                        // }}
                    />

                    <Loader loading={loading && page == 1} title="Articles"/>
                    {isTable.value && (
                        <>
                            <Row>
                                <Col className="col-12 article-table">
                                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={(favouriteList ? favouriteList?.name : props.t("Product List")) + " - " + props.t("Articles")}
                                            buttons={[{
                                            text: props.t("Add Article"),
                                            onClick: tog_standard,
                                            }]}
                                        />
                                    </CardHeader>
                                </Col>
                            </Row>
                            {error &&
                                <Alert className={"mt-4"} color="danger" role="alert">
                                    {error}
                                </Alert>
                            }
                            <Row>
                                <Col className="col-12 article-table mt-4 mb-2">
                                    <div className="form-inline float-md-end searchWidth">
                                        <div className="search-box ml-0">
                                            <div className="position-relative ">
                                                <Form
                                                    className="app-search ml-0   d-lg-block "
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-control bg-light border-light rounded toolbar-search"
                                                        placeholder={props.t("Search") + "..."}
                                                        onChange={(e) => {
                                                            setSearch(e.target.value);
                                                        }}
                                                        onKeyUp={(e) => handleClearSearch(e)}
                                                    />
                                                    <i className="mdi mdi-magnify search-icon"></i>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}

                    {!isTable.value && (
                        <React.Fragment>
                            <Row>
                                <Col className="col-12 article-table">
                                    <Card>
                                        <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                            <TableToolbar
                                            title={(favouriteList ? favouriteList?.name : props.t("Product List")) + " - " + props.t("Articles")}
                                            buttons={[{
                                            text: props.t("Add Article"),
                                            onClick: tog_standard,
                                            }]}
                                            />
                                        </CardHeader>
                                        {!loading &&
                                        <CardBody>
                                            {error &&
                                                <Alert className={"mt-4"} color="danger" role="alert">
                                                    {error}
                                                </Alert>
                                            }
                                            <MDBDataTable
                                                responsive
                                                hover
                                                barReverse={true}
                                                borderless
                                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                entriesLabel={props.t("Show entries")}
                                                infoLabel={[
                                                    props.t("Showing"),
                                                    props.t("to"),
                                                    props.t("of"),
                                                    props.t("entries"),
                                                ]}
                                                searchLabel={props.t("Search") + "..."}
                                                paging={false}
                                                noBottomColumns={true}
                                                data={getDatatableData()}
                                                sortable={true}
                                                searching={true}
                                                sortRows={[
                                                    "created",
                                                    "description",
                                                    "articleNumber",
                                                    "category",
                                                ]}
                                                onSearch={(text) => console.log(text)}
                                                noRecordsFoundLabel={props.t("No matching records found")}
                                            />
                                        </CardBody>}
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    {!(loading && page == 1) && isTable.value && (
                        <Row>
                            <Col lg="12" xl="12">
                                <div>
                                    <Row className="min-vh-100">
                                        {(isEmpty(productList) ||
                                            isEmpty(getProducts())) && (
                                            <Row className="min-height-400">
                                                <h6
                                                    align="center"
                                                    className="margin-top-10 font-color-grey"
                                                >
                                                    {props.t("No articles found!")}
                                                </h6>
                                            </Row>
                                        )}
                                        {!isEmpty(productList) && 
                                        <InfiniteScrollbarWithLoader 
                                            items={productList}
                                            loading={loading}
                                            totalItems={totalItems}
                                            renderCallback={getProductView}
                                            page={page}
                                            setPage={setPage}
                                            type={props.t("Articles")} 
                                            />}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Article")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label"
                        >
                        {props.t("Article")}
                        </label>
                        <div className="col-md-9">
                        <SearchableDropdown
                            keyToDisplay={['description']}
                            selectedValue={articleOption} 
                            options={productOptions} 
                            placeholder={"Select Article"}
                            handleOnOptionClick={(ao) => setArticleOption(ao)} />
                        </div>
                    </Row>
                    {!user?.customerId && <Row className="mb-3">
                        <label
                        htmlFor="example-text-input"
                        className="col-md-3 col-form-label"
                        >
                        {props.t("Customer")}
                        </label>
                        <div className="col-md-9">
                        <SearchableDropdown 
                            keyToDisplay={["name"]}
                            selectedValue={customerOption} 
                            options={customerOptions} 
                            placeholder={"All"}
                            handleOnOptionClick={(co) => setCustomerOption(co)}
                            disabled={favouriteList?.customer || user?.customerId} />
                        </div>
                    </Row>}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setArticleOption(null);
                            if(!(user?.customerId)){
                                setCustomerOption(null);
                            }
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        onClick={() => handleAddArticle(true)}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                        disabled={!articleOption}
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <Modal
                isOpen={modal_confirm}
                toggle={() => {
                    tog_confirm();
                }}
            >
                <div className="modal-header">
                    <h4 className="modal-title">
                        {props.t("Warning")}!
                    </h4>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <p>{props.t("Product list already contains the article for a specific customer. Do you want to override the product list article to all customers?")}</p>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_confirm(false);
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("No")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => {handleAddArticle(false)}}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Yes")}
                    </Button>
                </div>
            </Modal>
        </React.Fragment>
    );
};

ProductListArticles.propTypes = {
    products: PropTypes.array,
    history: PropTypes.object,
    onGetProducts: PropTypes.func,
    metaInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        products: state.ecommerce.favoriteProducts,
        metaInfo: {
            page: state.ecommerce.favoriteProducts?.currentPage + 1,
            totalPages: state.ecommerce.favoriteProducts?.totalPages,
            totalItems: state.ecommerce.favoriteProducts?.totalItems,
        },
        error: state.ecommerce.getArticlesError,
        loading: state.ecommerce.getArticlesPending,
        productOptions: state.ecommerce.products.data,
        customerOptions: state.Customer.customers,
        articleConfig: state.Config.articleConfig,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetProducts: (productListId, page, pageSize, search) =>
        dispatch(getFavoriteProducts(productListId, page, pageSize, search)),
    onGetProductsReset: (clearArticles) => dispatch(getFavoriteProductsReset(clearArticles)),
    onGetProductOptions: () => dispatch(getProducts(-1, -1, null)),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetArticleConfig: () => dispatch(getArticleConfig()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProductListArticles)));
