import {getManufacturerDetails} from "../../store/manufacturer/actions";
import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import classnames from 'classnames';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Avatar} from "@material-ui/core";
import avatar from "../../assets/images/company-placeholder.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import PersonIcon from "@material-ui/icons/Person";
import ArticleListTable from "../Articles/ArticleListTable";
import EventManagement from "../Audit/EventManagement";
import Dashboard from "../Dashboard";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";
import {downloadPDF, getFilterClassName} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";
import CustomerOrderTable from "../Customer/CustomerOrderTable";


const ManufacturerDetails = (props) => {
    const {manufacturer, onGetManufacturerDetails, orders, onGetOrders, errorOrder, orderLoading,loading, error, match: {params}} = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const history = useHistory();
    const [orderType, setOrderType] = useState('all');
    const [sortState, setSortState] = useState(null);

    let user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
    }, []);

    useEffect(() => {
        if (params.id) {
            onGetManufacturerDetails(params.id);
        }
    }, [params.id])

    useEffect(() => {
        if(manufacturer?.name) {
            //onGetOrders(orderType, null, null, null, null, manufacturer?.name, null, null)
        }
    }, [manufacturer])

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const handleBack = () => {
        history.push("/manufacturers")
    };


    const handleEdit = () => {
        history.push(`/manufacturers/${params?.id}/edit`);
    };

    const handleFilter = (value) => {
        setOrderType(value);
    }

    useEffect(() => {
        if(orderType){
            //onGetOrders(orderType, null, null, null, null, manufacturer?.name, null, null)
        }
    }, [orderType])

    const openOrderDetails = (order) => {
        let fromUrl = "";
        if(props.from){
            fromUrl = `?from=${props.from}`;
        }
        history.push(`/orders/${order.DocumentNumber + fromUrl}`)
    }

    const getDatatableData = () => {
        let cols = [
            {
                label: props.t("Document Number"),
                field: "DocumentNumber",
                width: 150,
            },
            {
                label: props.t("Status"),
                field: "status",
                width: 150,
            },
            {
                label: props.t("Total"),
                field: "Total",
                width: 150,
            },
            {
                label: props.t("OrderDate"),
                field: "OrderDate",
                width: 150,
            },
            {
                label: props.t("Download"),
                field: "download",
            }
        ]

        let data = {
            columns: cols,
            rows: orders?.map((order, key) => ({
                status: <div onClick={() => openOrderDetails(order)} className={`badge ${getFilterClassName(order?.status)} p-1`}>{props.t(order.status)}</div>,
                DocumentNumber: <div onClick={() => openOrderDetails(order)}>{order?.DocumentNumber}</div>,
                Total: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.Total)}</div>,
                OrderDate: <div onClick={() => openOrderDetails(order)}>{order?.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order?.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            }))

        }
        return data;
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Manufacturer"), link: "/manufacturers"}]}
                        title={manufacturer?.name}
                        /*backBtn={handleBack}*/
                    />
                    <>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader className="table-header-bg"
                                                style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={manufacturer?.name}
                                            canEdit={handleEdit}
                                        />
                                    </CardHeader>
                                </Card>
                            </Col>
                            {error && <Col className="col-12">
                                <Alert color="danger">{error}</Alert>
                            </Col>}

                            <Loader loading={loading} title="Manufacturer"/>
                            {!loading && <>
                                <Col xl="4">
                                    <Card className="card h-100">
                                        <CardBody>
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        <label htmlFor='icon-button-file'>
                                                            <Avatar
                                                                variant="rounded"
                                                                src={manufacturer?.profileImage?.s3Key == null ? avatar : BASE_IMAGE_URL + "/" + manufacturer?.profileImage?.s3Key}
                                                                className="customer-logo"
                                                            >
                                                                <PersonIcon/>
                                                            </Avatar>
                                                        </label>
                                                        <h5 className="mb-1 mt-2">{manufacturer?.name}</h5>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr className="my-4"/>
                                            <div className="mb-3">
                                                <Row>
                                                    <Col lg={6} md={6} sm={6}>
                                                        <div className="d-flex">
                                                            <p className="me-2 text-muted mb-0 pb-0">{manufacturer.description}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                    <Card className="mb-0">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        profiletoggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-users-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Articles")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={() => {
                                                        profiletoggle('3');
                                                    }}
                                                >
                                                    <i className="uil uil-bolt-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={() => {
                                                        profiletoggle('4');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '5'})}
                                                    onClick={() => {
                                                        profiletoggle('5');
                                                    }}
                                                >
                                                    <i className="uil uil-invoice font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        profiletoggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-clipboard-notes font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Events")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '6'})}
                                                    onClick={() => {
                                                        profiletoggle('6');
                                                    }}
                                                >
                                                    <i className="uil uil-chart font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                {manufacturer?.name && activeTab == "1" && <ArticleListTable customFilter={{manufacturer: manufacturer?.name}} columns={[
                                                    {
                                                        label: props.t("Name"),
                                                        field: "description",
                                                        width: 300,
                                                    },
                                                    {
                                                        label: props.t("Article Number"),
                                                        field: "articleNumber",
                                                        width: 100,
                                                    },
                                                    {
                                                        label: props.t("Category"),
                                                        field: "category",
                                                        width: 200,
                                                    },
                                                    {
                                                        label: props.t("Disposable Quantity"),
                                                        field: "disposableQuantity",
                                                        width: 100,
                                                    },
                                                    {
                                                        label: props.t("Sales Price"),
                                                        field: "salesPrice",
                                                        width: 100
                                                    },
                                                    {
                                                        label: props.t("Published"),
                                                        field: "publish",
                                                        width: 200,
                                                    }
                                                ]}/>}
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {manufacturer?.name && activeTab == "3"  && <CustomerOfferTable manufacturer={manufacturer?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="4">
                                               {manufacturer?.name && activeTab == "4" && <CustomerOrderTable manufacturer={manufacturer?.name}/>}
                                                {/*{manufacturer?.name && activeTab == "4" &&
                                                <Row>
                                                    {(errorOrder) &&
                                                        <Alert className={"mt-4"} color="danger" role="alert">
                                                            {props.t(API_ERR_MSG)}
                                                        </Alert>
                                                    }
                                                    <Row className="mb-3">
                                                        <Col className="col-12">
                                                            <div className="d-flex justify-content-end">
                                                                <ButtonGroup
                                                                    filter={{
                                                                        selected: orderType,
                                                                        options : [
                                                                            {name: props.t("All"), value:"all"},
                                                                            {name: props.t("Ongoing"), value:"invoicenotcreated"},
                                                                            {name: props.t("Invoiced"), value:"invoicecreated"},
                                                                            {name: props.t("Delayed"), value: "expired"},
                                                                            {name: props.t("Cancelled"), value: "cancelled"}
                                                                        ],
                                                                        onSelect: handleFilter
                                                                    }}
                                                                    brandSettings={brandSettings}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Loader loading={orderLoading} title="Orders" />

                                                    {!(orderLoading) &&
                                                        <Row>
                                                            <Col className="col-12 article-table">
                                                                <MDBDataTable
                                                                    responsive
                                                                    hover
                                                                    barReverse={true}
                                                                    borderless
                                                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                                    entriesLabel={props.t("Show entries")}
                                                                    infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                                                    searchLabel={props.t("Search") + "..."}
                                                                    noRecordsFoundLabel={props.t("No matching records found")}
                                                                    noBottomColumns={true}
                                                                    data={getDatatableData()}
                                                                    entries={50}
                                                                    entriesOptions={[50, 100, 500]}
                                                                    onSearch={(text) => console.log(text)}
                                                                    sortRows={[]}
                                                                    onSort={({column, direction}) => {
                                                                        if(sortState && sortState.column == column){
                                                                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                                        } else {
                                                                            setSortState({column, direction});
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>}
                                                </Row>}*/}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {manufacturer?.name && activeTab == "5"&& <CustomerInvoiceTable manufacturer={manufacturer?.name} />}
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {manufacturer?.name && activeTab == "2" && <EventManagement manufacturerId={manufacturer?.id}/>}
                                            </TabPane>
                                            <TabPane tabId="6">
                                                {manufacturer?.name && activeTab == "6" && <Dashboard manufacturer={manufacturer?.name}/>}
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </Col>
                            </>}
                        </Row>
                    </>
                </Container>
            </div>
        </React.Fragment>
    )
}

ManufacturerDetails.propTypes = {
    manufacturer: PropTypes.object,
    onGetManufacturerDetails: PropTypes.func,
    products: PropTypes.array,
    onGetProducts: PropTypes.func,
    metaInfo: PropTypes.object,
    /*orders: PropTypes.array,
    onGetOrders: PropTypes.func*/
}


const mapStateToProps = (state) => {
    return {
        manufacturer: state.Manufacturer.manufacturer,
        loading: state.Manufacturer.manufacturerLoading,
        error: state.Manufacturer.manufacturerError,
        products: state.ecommerce.products.data,
        /*orders: state.ecommerce?.orders?.data?.Orders,
        orderLoading: checkIfLoading(state, ecommerceActionTypes.GET_ORDERS),
        errorOrder: state.ecommerce.getOrdersError,*/
        metaInfo: {
            page: state.ecommerce.products.currentPage + 1,
            totalPages: state.ecommerce.products.totalPages,
            totalItems: state.ecommerce.products.totalItems,
        },
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetManufacturerDetails: (id) => dispatch(getManufacturerDetails(id)),
    //onGetOrders: (orderType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => dispatch(getOrders(orderType, customerId, articleNumber, null, null, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ManufacturerDetails)));
