import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, Col, Collapse, Container, Media, Row, Table} from "reactstrap"

import {AvField, AvForm} from "availity-reactstrap-validation"

import {withTranslation} from "react-i18next";

// Redux
import {connect} from "react-redux"
import {Link, useHistory, withRouter} from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {countries} from '../../util/dataUtil';
import {createOrder, getCustomerInfo, GetCustomerUsers, getOffersDetails, getOffersDetailsReset, getProjectList} from '../../store/actions';
import {find, isEmpty, isNull} from 'lodash-es';
import {BASE_IMAGE_URL} from '../../helpers/url_helper';
import ProjectForm from '../Projects/ProjectForm';
import {get, post} from "../../helpers/api_helper";
import DeliveryAddressForm from '../Customer/DeliveryAddressForm';
import {API_ERR_MSG} from '../../util/constants';
import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import Loader from '../../components/Common/Loader';

const OfferCheckout = props => {
    const {
        customer, loading, offerDetails, orderSuccess, orderError, orderPending, customerUsers,
        onGetCustomerInfo, onCreateOrder, projects, onGetProjects, onGetCustomerUsers, onGetOfferDetails, onGetOfferDetailsReset, match: {params}
    } = props;

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const [isOpenShippinginfo, setIsOpenShippinginfo] = useState(false);
    const toggleShippinginfo = () => setIsOpenShippinginfo(!isOpenShippinginfo);

    const [isOpenAddNewShippingInfo, setIsOpenAddNewShippingInfo] = useState(false);

    const [isOpenProjectinfo, setIsOpenProjectinfo] = useState(false);
    const toggleProjectinfo = () => setIsOpenProjectinfo(!isOpenProjectinfo);

    const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])

    useEffect(() => {
        onGetOfferDetails(params.offerId);

        return () => {
            onGetOfferDetailsReset();
        }
    }, [params.offerId])

    const toggleAddNewShippingInfo = (shippingInfoId, openCloseFormValue) => {
        setSelectedShippingInfo(shippingInfoId ? shippingInfoId : null);
        setIsOpenAddNewShippingInfo(openCloseFormValue !== undefined ? openCloseFormValue : !isOpenAddNewShippingInfo);
    };

    const editShippingInfo = (id) => {
        setEditShippingInfoId(id);
        setIsOpenAddNewShippingInfo(true);
    }

    const toggleAddNewProject = (projectNumber, openCloseFormValue) => {
        setSelectedProject(projectNumber ? projectNumber : "");
        setIsOpenAddNewProject(openCloseFormValue !== undefined ? openCloseFormValue : !isOpenAddNewProject);
    };

    const editProject = (id) => {
        setEditProjectId(id);
        setIsOpenAddNewProject(id !== null);
    }

    const [error, setError] = useState([]);

    const [customerId, setCustomerId] = useState("");

    const [CustomerName, setCustomerName] = useState("")
    const [Address1, setAddress1] = useState("")
    const [Address2, setAddress2] = useState("")
    const [yourReference, setYourReference] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [City, setCity] = useState("")
    const [Country, setCountry] = useState("")
    const [CountryCode, setCountryCode] = useState("")
    const [ZipCode, setZipCode] = useState("")


    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [discount, setDiscount] = useState(0)

    const [shippingInfoList, setShippingInfoList] = useState([])
    const [editShippingInfoId, setEditShippingInfoId] = useState(null);
    const [selectedShippingInfo, setSelectedShippingInfo] = useState(null);
    const [deliveryReference, setDeliveryReference] = useState("");

    const [projectList, setProjectList] = useState([])
    const [editProjectId, setEditProjectId] = useState(null);
    const [selectedProject, setSelectedProject] = useState("");
    const [brandSettings, setBrandSettings] = useState(null)
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"))
            setBrandSettings(obj?.brandConfig);
            setCustomerId(obj?.customerId);
            onGetCustomerInfo(obj?.customerId);
            onGetProjects(obj?.customerId);
            onGetCustomerUsers(obj?.customerId);
        }
    }, [])

    useEffect(() => {
        if (customerId) {
            onGetShippingInfo();
        }
    }, [customerId])

    useEffect(() => {
        if (isEmpty(orderSuccess)) return;
        history.push(`/customer/orders/${orderSuccess.orderDetails.Order.DocumentNumber}`)
    }, [orderSuccess])

    useEffect(() => {
        if (orderPending) {
            setTimeout(2000);
        }
    }, [orderPending])

    useEffect(() => {
        if (customer) {
            setCustomerName(isNull(customer?.name) ? "" : customer?.name)
            setAddress1(isNull(customer?.address1) ? "" : customer?.address1);
            setAddress2(isNull(customer?.address2) ? "" : customer?.address2);
            setCity(isNull(customer?.city) ? "" : customer?.city);
            setCountry(isNull(customer?.country) ? "" : customer?.country);
            setCountryCode(isNull(customer?.countryCode) ? "" : customer?.countryCode);
            setZipCode(isNull(customer?.zipCode) ? "" : customer?.zipCode);
        }
    }, [customer])


    useEffect(() => {
        if (projects) {
            setProjectList(projects)
        }
    }, [projects])

    useEffect(() => {
        if (selectedProject == null) return;
        document.querySelector('select[name=project]').value = selectedProject;
    }, [selectedProject])

    useEffect(() => {
        if (customerUsers && !isEmpty(customerUsers)) {
            // document.querySelector('select[name=yourReference]').value = customerUsers[0];
            setYourReference(customerUsers[0].name);
        }
    }, [customerUsers])

    function onGetShippingInfo() {
        get(`/api/customers/${customerId}/deliveryAddress`)
            .then(data => {
                if (data?.status !== 200) {
                    setError([props.t(API_ERR_MSG)])
                } else {
                    setShippingInfoList(data?.data);
                }
            })
            .catch(err => {
                setError([props.t(API_ERR_MSG)])
            })
    }

    const handleOrderSubmit = async () => {
        const err = [];
        if (!CustomerName || isEmpty(CustomerName)) {
            err.push("Delivery Name is required field");
        }
        if (!selectedShippingInfo) {
            err.push("Unable to place order. Delivery address is required");
        }
        setError(err);
        let selectedCountryCode;
        if (err.length === 0) {
            if (Country && !isEmpty(Country) && Country !== "") {
                selectedCountryCode = Object.keys(countries)
                    .filter(key => countries[key] === Country)
                    .map(key => key)[0];
            }
            setProcessing(true);
            const orderData = {
                orderDetails: {
                    Order: {
                        CustomerName,
                        Address1,
                        Address2,
                        Country,
                        City,
                        ZipCode,
                        Project: selectedProject,
                        DocumentNumber: "",
                        YourReference: yourReference,
                        YourOrderNumber: orderNumber,
                        DeliveryAddress1: deliveryReference,
                        DeliveryAddress2: find(shippingInfoList, {id: parseInt(selectedShippingInfo)})?.address
                    }
                },
                shippingInfoId: selectedShippingInfo,
            }

            let response = await post(`/api/offer/${params.offerId}/createOrder`, orderData);
            if(response.status === 200 && response.data.ErrorInformation == null){
                history.push(`/customer/orders/${response.data.Offer.OrderReference}`)
            }
            setProcessing(false);
        }
    }

    const getArticlePrice = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        return price ? price : 0;
    }

    function computeOrderData(orderItems) {
        let ordersTotal = 0;
        let ordersSubtotal = 0;
        let ordersTax = 0;
        let ordersDiscount = 0;

        orderItems.forEach(orderitem => {
            const orderItemPrice = getArticlePrice(orderitem) * orderitem.quantity;
            ordersSubtotal += orderItemPrice;
            ordersTax += (orderItemPrice * orderitem.article.vat) / 100;
        })

        ordersTotal = ordersSubtotal + ordersTax - ordersDiscount;

        setSubTotal(ordersSubtotal);
        setDiscount(ordersDiscount);
        setTax(ordersTax);
        setTotal(ordersTotal);
    }

    function submitAddNewShippingInfo(shippingInfoId) {
        toggleAddNewShippingInfo(shippingInfoId, true);
        setEditShippingInfoId(shippingInfoId);
        onGetShippingInfo();
        if (shippingInfoId) {
            setSelectedShippingInfo(shippingInfoId);
        }
    }

    async function submitAddEditProject(projectNumber) {
        toggleAddNewProject(projectNumber, true);
        const project = find(projectList, {ProjectNumber: projectNumber});
        editProject(project ? project.id : null);
        setEditProjectId(project ? project.id : null);
        await onGetProjects(customerId);
        if (projectNumber && projectNumber != null) {
            setSelectedProject(projectNumber);
        }
    }

    const clearError = () => setError([])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className={orderPending || loading ? "low-opacity" : null}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={props.t("Offers")} breadcrumbItem={props.t("Checkout")}/>


                    <Loader loading={orderPending || loading}/>

                    {orderSuccess === null && (
                        <div className="checkout-tabs">
                            <Row>
                                <Col xl="6">
                                    <div className="custom-accordion">
                                        <Card>
                                            <Link onClick={toggle} className="text-dark" to="#">
                                                <div className="p-4">
                                                    <Media className="d-flex align-items-center">
                                                        <div className="me-3">
                                                            <i className="uil uil-receipt text-primary h2"></i>
                                                        </div>
                                                        <div className="flex-1 overflow-hidden">
                                                            <h5 className="font-size-16 mb-1">{props.t("Billing Info")}</h5>
                                                        </div>
                                                        <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                                                    </Media>
                                                </div>
                                            </Link>
                                            <Collapse isOpen={isOpen}>
                                                <AvForm
                                                    name="deliveryForm"
                                                    className="form-horizontal"
                                                    disabled={orderPending}
                                                >
                                                    <div className="p-4 border-top">
                                                        <div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3 mb-4">
                                                                        <AvField
                                                                            name="CustomerName"
                                                                            label={props.t("Name")}
                                                                            value={CustomerName}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            required
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div className="mb-3 mb-4">
                                                                        <AvField
                                                                            name="Address1"
                                                                            label={props.t("Address") + " 1"}
                                                                            value={Address1}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div className="mb-3 mb-4">
                                                                        <AvField
                                                                            name="Address2"
                                                                            label={props.t("Address") + " 2"}
                                                                            value={Address2}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <div className="mb-4 mb-3">
                                                                        <AvField
                                                                            name="Country"
                                                                            label={props.t("Country")}
                                                                            value={Country}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={4}>
                                                                    <div className="mb-4 mb-3">
                                                                        <AvField
                                                                            name="City"
                                                                            label={props.t("City")}
                                                                            value={City}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={4}>
                                                                    <div className="mb-4 mb-3">
                                                                        <AvField
                                                                            name="ZipCode"
                                                                            label={props.t("Zip Code")}
                                                                            value={ZipCode}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            type="text"
                                                                            validate={{number: true}}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="mb-3 mb-4">
                                                                        <AvField
                                                                            name="yourReference"
                                                                            label={props.t("Your Reference")}
                                                                            value={yourReference}
                                                                            className="form-control"
                                                                            placeholder={props.t("")}
                                                                            onChange={(e) => {
                                                                                setYourReference(e.target.value);
                                                                            }}
                                                                            type="select"
                                                                            disabled={loading}
                                                                        >
                                                                            {customerUsers.map((u, key) => <option value={u.name} key={key}>{u.name}</option>)}
                                                                        </AvField>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="mb-3 mb-4">
                                                                        <AvField
                                                                            className="form-control"
                                                                            type="text"
                                                                            label={props.t("Order Reference")}
                                                                            placeholder={props.t("Enter Order Reference")}
                                                                            name="orderNumber"
                                                                            value={orderNumber}
                                                                            onChange={(e) => {
                                                                                setOrderNumber(e.target.value);
                                                                            }}
                                                                            disabled={loading}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </AvForm>
                                            </Collapse>
                                        </Card>
                                        <Card>
                                            <div className="p-4">
                                                <Row>
                                                    <div className="col-md-6">
                                                        <Media className="d-flex align-items-center">
                                                            <div className="me-3">
                                                                <i className="uil uil-truck text-primary h2"></i>
                                                            </div>
                                                            <div className="flex-1 overflow-hidden">
                                                                <h5 className="font-size-16 mb-1">{props.t("Delivery Info")}</h5>
                                                            </div>
                                                        </Media>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <select
                                                            name="shippingInfo"
                                                            className="form-control form-select select2"
                                                            title={props.t("Select Shipping Address")}
                                                            value={selectedShippingInfo}
                                                            onChange={(e) => {
                                                                setSelectedShippingInfo(e.target.value);
                                                                toggleAddNewShippingInfo(e.target.value, false);
                                                                editShippingInfo(e.target.value)
                                                                clearError();
                                                            }}
                                                        >
                                                            <option key="default" value="" disabled selected>{props.t("Mandatory")}</option>
                                                            {shippingInfoList?.map((shippingInfo, index) => (
                                                                <option key={index} value={shippingInfo.id}>Name: {shippingInfo.name}, Delivery Address: {shippingInfo.address}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="p-3 border-top">
                                                <div className="text-center">
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light text-center"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        onClick={() => toggleAddNewShippingInfo()}
                                                    >
                                                        {props.t("Add New Delivery Address")}
                                                    </Button>
                                                </div>
                                                <Collapse isOpen={isOpenAddNewShippingInfo || selectedShippingInfo}>
                                                    <div className="pt-4">
                                                        <DeliveryAddressForm
                                                            location={props.location}
                                                            shippingInfoId={selectedShippingInfo}
                                                            customerId={customerId}
                                                            callback={submitAddNewShippingInfo}
                                                            setEditMode={() => toggleAddNewShippingInfo(selectedShippingInfo, false)}
                                                            disableFieldsByDefault={selectedShippingInfo !== null}
                                                            addOrderNumberField={true}
                                                            setOrderNumber={(val) => setOrderNumber(val)}
                                                        />
                                                        <Row className="mb-3">
                                                            <AvForm
                                                                name="deliveryForm"
                                                                className="form-horizontal"
                                                                disabled={orderPending}
                                                            >
                                                                <AvField
                                                                    className="form-control"
                                                                    type="text"
                                                                    label={props.t("Delivery Reference")}
                                                                    placeholder={props.t("Enter Delivery Reference")}
                                                                    name="deliveryReferene"
                                                                    value={deliveryReference}
                                                                    onChange={(e) => {
                                                                        setDeliveryReference(e.target.value);
                                                                    }}
                                                                    disabled={loading}
                                                                />
                                                            </AvForm>
                                                        </Row>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="p-4">
                                                <Row>
                                                    <div className="col-md-6">
                                                        <Media className="d-flex align-items-center">
                                                            <div className="me-3">
                                                                <i className="uil uil-apps text-primary h2"></i>
                                                            </div>
                                                            <div className="flex-1 overflow-hidden">
                                                                <h5 className="font-size-16 mb-1">{props.t("Project Info")}</h5>
                                                            </div>
                                                        </Media>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <select
                                                            name="project"
                                                            className="form-control form-select select2"
                                                            title={props.t("Select Project")}
                                                            value={selectedProject !== null ? selectedProject : ""}
                                                            onChange={(e) => {
                                                                setSelectedProject(e.target.value);
                                                                toggleAddNewProject(e.target.value, false);
                                                                const project = find(projectList, {ProjectNumber: e.target.value});
                                                                editProject(project ? project.id : null);
                                                                clearError();
                                                            }}
                                                        >
                                                            <option key="default" value="" selected>{props.t("Optional")}</option>
                                                            {projectList?.map(project => (
                                                                <option key={project.ProjectNumber} value={project.ProjectNumber}>
                                                                    ProjectNumber: {project.ProjectNumber}, Description: {project.Description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                </Row>
                                            </div>
                                            <div className="p-3 border-top">
                                                <div className="text-center">
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary waves-effect waves-light text-center"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        onClick={() => toggleAddNewProject()}
                                                    >
                                                        {props.t("Add New Project")}
                                                    </Button>
                                                </div>
                                                <Collapse isOpen={isOpenAddNewProject || !isEmpty(selectedProject)}>
                                                    <div className="pt-4">
                                                        <ProjectForm
                                                            resetFormData={!isOpenAddNewProject}
                                                            location={props.location}
                                                            projectId={editProjectId}
                                                            customerId={customerId}
                                                            avoidRedirect={true}
                                                            callback={submitAddEditProject}
                                                            setEditMode={() => toggleAddNewProject(selectedProject, false)}
                                                            disableFieldsByDefault={!isEmpty(selectedProject)}
                                                        />
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </Card>
                                    </div>
                                    <Row className="mb-4">
                                        <Col>
                                            <Link to="/customer/categories" className="btn btn-link text-muted">
                                                <i className="uil uil-arrow-left me-1"></i> {props.t("Continue Shopping")}
                                            </Link>
                                        </Col>
                                        <Col>
                                            <div className="text-sm-end mt-2 mt-sm-0">
                                                <Button onClick={() => handleOrderSubmit()}
                                                        className="btn btn-success"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        disabled={processing}>
                                                    <i className="uil uil-shopping-cart-alt me-1"></i> {processing ? props.t('Please wait') + "..." : props.t("Confirm Order")}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {error.length > 0 && (<Alert className="text-align-center" color="danger">{error.map((msg, index) => index === 0 ? msg : (<><br/>{msg}</>))}</Alert>)}
                                    {orderError != null && (
                                        <Row>
                                            <Col>
                                                <div className="mt-4">
                                                    <Alert color="danger">{orderError}</Alert>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col xl="6">
                                    <Card className="checkout-order-summary">
                                        <CardBody>
                                            <div className="p-3 bg-light mb-4">
                                                <h5 className="font-size-16 mb-0">{props.t("Order Summary")}</h5>
                                            </div>

                                            <div className="table-responsive">
                                                <Table className="table-centered mb-0 table-nowrap">
                                                    <thead className="thead-light">
                                                    <tr>
                                                        <th className="border-top-0" style={{width: "110px"}} scope="col">{props.t("Product")}</th>
                                                        <th className="border-top-0" scope="col">{props.t("Product Desc")}</th>
                                                        <th className="border-top-0" scope="col">{props.t("Price")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {offerDetails?.OfferRows.map((orderitem, key) => (
                                                        <tr key={"_orderSummary_" + key}>
                                                            <th scope="row">
                                                                {<img
                                                                    src={orderitem.Article.articleImages
                                                                    && orderitem.Article.articleImages.length > 0 ?
                                                                        BASE_IMAGE_URL + "/" + orderitem.Article.articleImages[0].media.s3Key
                                                                        : articlePlaceholderImg}
                                                                    alt="product-img"
                                                                    title="product-img"
                                                                    className="avatar-md"
                                                                />}
                                                            </th>
                                                            <td>
                                                                <h5 className="font-size-14 text-wrap">
                                                                    <Link
                                                                        to="/ecommerce-product-detail"
                                                                        className="text-dark"
                                                                    >
                                                                        {orderitem.Description}{" "}
                                                                    </Link>
                                                                </h5>
                                                                <p className="text-muted mb-0">
                                                                    {orderitem?.Price} x {orderitem.Quantity} kr
                                                                </p>
                                                            </td>
                                                            <td>
                                                                {orderitem?.Price * orderitem.Quantity} kr
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td colSpan="2">
                                                            <h6 className="font-size-14 m-0">
                                                                {props.t("Sub Total")}:
                                                            </h6>
                                                        </td>
                                                        <td>{offerDetails?.Gross} kr</td>
                                                    </tr>
                                                    {discount > 0 && <tr>
                                                        <td colSpan="2">
                                                            <h5 className="font-size-14 m-0">{props.t("Discount")} :</h5>
                                                        </td>
                                                        <td>
                                                            - {discount} kr
                                                        </td>
                                                    </tr>}

                                                    <tr>
                                                        <td colSpan="2">
                                                            <h5 className="font-size-14 m-0">{props.t("VAT")} :</h5>
                                                        </td>
                                                        <td>
                                                            {offerDetails?.TotalVAT} kr
                                                        </td>
                                                    </tr>

                                                    <tr className="bg-light">
                                                        <td colSpan="2">
                                                            <h5 className="font-size-14 m-0">{props.t("Total")}:</h5>
                                                        </td>
                                                        <td>
                                                            {offerDetails?.TotalToPay} kr
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>)}
                </Container>
            </div>
        </React.Fragment>
    )

    function getProjectInfoCard() {
        const project = find(projectList, {ProjectNumber: selectedProject});
        if (project) {
            return (
                <Card
                    className={"border rounded shipping-address active"}
                >
                    <CardBody>
                        <Link className="float-end ml-1" onClick={() => editProject(project.id)}>
                            <i className="uil uil-pen font-size-16"></i>
                        </Link>
                        <h5 className="mb-2">Project Details</h5>
                        <h6 className="mb-0">ProjectNumber: {project.ProjectNumber}, Description: {project.Description}</h6>
                        <p className="mb-0">ContactPerson: {project.ContactPerson ? project.ContactPerson : ""}</p>
                        <p className="mb-0">Status: {project.Status ? project.Status : ""}</p>
                        <p className="mb-0">StartDate: {project.StartDate ? project.StartDate : ""}</p>
                        <p className="mb-0">EndDate: {project.EndDate ? project.EndDate : ""}</p>
                    </CardBody>
                </Card>
            )
        }
    }
}

OfferCheckout.propTypes = {
    onGetCustomerInfo: PropTypes.func,
    onCreateOrder: PropTypes.func,
    customer: PropTypes.object,
    orderError: PropTypes.object,
    orderSuccess: PropTypes.string,
    cartItems: PropTypes.array,
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
    customerUsers: PropTypes.array,
    onGetCustomerUsers: PropTypes.func,
    offerDetails: PropTypes.object,
    offerDetailsError: PropTypes.object,
    offerDetailsLoading: PropTypes.object,
}

const mapStatetoProps = state => {
    const {customer, loading, orderError, orderSuccess, orderPending} = state.ecommerce
    const projects = state.Project.projects?.Projects
    const {customerUsers, customerUsersLoading} = state.Customer

    return {
        customer, projects, orderError, orderSuccess, orderPending,
        loading: loading || customerUsersLoading,
        customerUsers,
        offerDetails: state.Offers.offerDetails?.Offer,
        offerDetailsError: state.Offers.offerDetailsError,
        offerDetailsLoading: state.Offers.offerDetailsLoading
    };
}

const mapDispatchToProps = dispatch => ({
    onGetCustomerInfo: (id) => dispatch(getCustomerInfo(id)),
    onCreateOrder: (orderData) => dispatch(createOrder(orderData)),
    onGetProjects: (customerId) => dispatch(getProjectList(customerId)),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId)),
    onGetOfferDetails: id => dispatch(getOffersDetails(id)),
    onGetOfferDetailsReset: () => dispatch(getOffersDetailsReset())
})

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(OfferCheckout))
)
