import {call, put, takeEvery} from "redux-saga/effects"
import {
    GET_TENANT_CUSTOMER_USERS, GET_TENANT_USER_DETAILS,
    TENANT_ACTIVATE_USER,
    TENANT_ADMIN_DELETE_USER,
    TENANT_ADMIN_GET_USERS,
    TENANT_ADMIN_REGENERATE_TOKEN,
    TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS,
    TENANT_ADMIN_RESET_DELETE_USER,
    TENANT_ADMIN_RESET_DELETE_USER_SUCCESS,
    TENANT_ADMIN_RESET_REGENERATE_TOKEN
} from "./actionTypes";
import {
    getTenantUserDetailsFail,
    getTenantUserDetailsSuccess,
    tenantActiveUser,
    tenantActiveUserFail,
    TenantAdminDeleteUserFail,
    TenantAdminDeleteUserSuccess,
    TenantAdminGetUsersFail,
    TenantAdminGetUsersSuccess,
    TenantAdminRegenerateTokenFail,
    TenantAdminRegenerateTokenSuccess,
    TenantCustomerGetUsersFail,
    TenantCustomerGetUsersSuccess,
} from "./actions";
import {activeUser, deleteUser, getRegenerateToken, getUser, tenantAdminGetUser, tenantCustomerGetUser} from "../../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../../util/constants";
import { startAction, stopAction } from "../../actions";


function* tenantAdminsFetchUsers({filter}) {
    try {
        const response = yield call(tenantAdminGetUser, filter)
        yield put(TenantAdminGetUsersSuccess(response))
    } catch (error) {
        console.log("error", error);
        yield put(TenantAdminGetUsersFail(API_ERR_MSG))
    }
}

function* tenantCustomerFetchUsers({ type, filter, page, pageSize, search, sortColumn, sortDirection }) {
    try {
        yield put(startAction(type));
        const response = yield call(tenantCustomerGetUser, filter, page, pageSize, search, sortColumn, sortDirection);
        yield put(TenantCustomerGetUsersSuccess(response))
    } catch (error) {
        yield put(TenantCustomerGetUsersFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type));
    }
}

function* fetchTenantUserDetails({id}) {
    try {
        const response = yield call(getUser, id)
        yield put(getTenantUserDetailsSuccess(response))
    } catch (error) {
        yield put(getTenantUserDetailsFail(error))
    }
}

function* tenantAdminRegenerateToken(action) {
    try {
        const response = yield call(getRegenerateToken, action.userId)
        yield put(TenantAdminRegenerateTokenSuccess(response))
    } catch (err) {
        yield put(TenantAdminRegenerateTokenFail(err))
    }
}

function* tenantAdminDeleteTenantUser(action) {
    try {
        const response = yield call(deleteUser, action.userId)
        yield put(TenantAdminDeleteUserSuccess(response))
    } catch (err) {
        yield put(TenantAdminDeleteUserFail(err))
    }
}

function* tenantAdminResetRegenerateToken() {
    yield put({
        type: TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS,
        payload: undefined,
    });
}

function* tenantAdminResetDeleteTenantUser() {
    yield put({
        type: TENANT_ADMIN_RESET_DELETE_USER_SUCCESS,
        payload: undefined
    })
}

function* tenantActiveUserById(action) {
    try {
        const response = yield call(activeUser, action.userId)
        yield put(tenantActiveUser(response))
    } catch (err) {
        yield put(tenantActiveUserFail(err))
    }
}


function* tenantAdminSaga() {
    yield takeEvery(TENANT_ADMIN_GET_USERS, tenantAdminsFetchUsers)
    yield takeEvery(TENANT_ADMIN_REGENERATE_TOKEN, tenantAdminRegenerateToken)
    yield takeEvery(TENANT_ADMIN_DELETE_USER, tenantAdminDeleteTenantUser)
    yield takeEvery(TENANT_ADMIN_RESET_REGENERATE_TOKEN, tenantAdminResetRegenerateToken)
    yield takeEvery(TENANT_ADMIN_RESET_DELETE_USER, tenantAdminResetDeleteTenantUser)
    yield takeEvery(TENANT_ACTIVATE_USER, tenantActiveUserById)
    yield takeEvery(GET_TENANT_CUSTOMER_USERS, tenantCustomerFetchUsers)
    yield takeEvery(GET_TENANT_USER_DETAILS, fetchTenantUserDetails)
}

export default tenantAdminSaga
