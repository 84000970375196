import React from "react"
import {Button, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import errorImage from "../../assets/images/500-error.png";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log("Error ", error);
        console.log("Error Info", errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo,
            hasError: true
        })
    }


    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return  <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Row className="text-center mt-auto align-content-center">
                            <Col lg={12} className="mt-lg-5">
                                <img src={errorImage} style={{width:"40%"}}></img>
                            </Col>
                            <Col lg={12} className="mt-lg-5">
                                <h1>{this.props.t("We are sorry, something went wrong!")}</h1>
                                <h2><Button
                                    color="primary"
                                    className="btn btn-primary waves-primary text-center mt-4"
                                    onClick={() => window.location.reload(false)}>
                                    {this.props.t("Click to continue")}
                                </Button>{" "}</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        }

        return this.props.children;
    }
}

export default connect()(withTranslation()(ErrorBoundary))
