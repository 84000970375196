import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Alert,
} from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAddressList, getAddressListReset } from "../../store/customer/actions";
import TableToolbar from "../../components/Common/TableToolbar";
import { filter, includes, isEmpty } from "lodash-es";
import Loader from "../../components/Common/Loader";
import { orderBy } from "lodash";
import {customerAddressManagementContext} from "./CustomerAddressManagement";
import {del} from "../../helpers/api_helper";
export const addressIdContext = React.createContext(null);

const AddressList = (props) => {
  const { addresses, onGetAddresses, loading, onGetAddressesReset, error } = props;
  const [addressList, setAddressList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null)
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setBrandSettings(obj?.brandConfig);
      onGetAddresses(obj?.customerId);
    }
    return () => {
        onGetAddressesReset();
    }
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetAddressesReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if(addresses){
      setAddressList(addresses);
    }
  }, [addresses]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setAddressList(orderBy(addressList, o => {
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        addressList,
        (address) =>
          includes(address.name?.toLowerCase(), search.toLowerCase()) ||
          includes(address.country?.toLowerCase(), search.toLowerCase()) ||
          filter(address.city, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(addressList);
    }
    setSearchValue(search);
  };

  const handleDelete = async (deliveryAddressId) => {
    const response = await del(`/api/customers/deliveryAddress/${deliveryAddressId}`);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    onGetAddresses(obj?.customerId);
  }

  const getDatatableData = () => {
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "name",
          width: 150,
        },
        {
          label: props.t("Delivery Address"),
          field: "address",
          width: 100,
        },
        {
          label: props.t("City"),
          field: "city",
        },
        {
          label: props.t("Country"),
          field: "country",
        },
        {
          label: props.t("Zip Code"),
          field: "zipCode",
        },
        {
          label: props.t("Phone"),
          field: "phone",
        },
        {
          label: props.t("Actions"),
          field: "action"
        }
      ],
      rows: addressList?.map((address, key) => ({
        name: <span onClick={() => handleOpen(address)}>{address.name}</span>,
        address: <span onClick={() => handleOpen(address)}>{address.address}</span>,
        city: <span onClick={() => handleOpen(address)}>{address.city}</span>,
        country: <span onClick={() => handleOpen(address)}>{address.country}</span>,
        zipCode: <span onClick={() => handleOpen(address)}>{address.zipCode}</span>,
        phone: <span onClick={() => handleOpen(address)}>{address.phone}</span>,
        action: <customerAddressManagementContext.Provider value={address.id}>
          <i class="uil uil-pen font-size-18" onClick={() => {
            handleOpen(address);
          }}></i> &nbsp;&nbsp;
          <i className="uil uil-trash-alt font-size-18 text-danger" onClick={() => {
            handleDelete(address.id);
          }}></i>
        </customerAddressManagementContext.Provider>
      })),
    };
    return data;
  };

  const handleOpen = (address) => {
    history.push(`/customer/deliveryAddresses/${address.id}/edit`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Row>
              <Col className="col-12 article-table">
                <Card>
                  <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                    <TableToolbar
                      title={props.t("Delivery Addresses")}
                      buttons={[{
                        text: props.t("New Delivery Address"),
                        onClick: () => {
                          history.push("/customer/deliveryAddresses/add");
                        },
                      }]}
                    />
                  </CardHeader>
                  {error &&
                      <Alert className={"mt-4"} color="danger" role="alert">
                          {error}
                      </Alert>
                  }
                  <Loader loading={loading} title="Delivery Addresses" />
                  {!loading &&
                  <CardBody> 
                    <MDBDataTable
                      responsive
                      barReverse={true}
                      hover
                      searching={true}
                      borderless
                      paginationLabel={[props.t("Previous"), props.t("Next")]}
                      entriesLabel={props.t("Show entries")}
                      infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                      ]}
                      searchLabel={props.t("Search") + "..."}
                      noRecordsFoundLabel={props.t("No matching records found")}
                      noBottomColumns={true}
                      data={getDatatableData()}
                      entries={50}
                      entriesOptions={[50, 100, 500]}
                      sortRows={[]}
                      onSort={({column, direction}) => {
                          if(sortState && sortState.column == column){
                              setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                          } else {
                              setSortState({column, direction});
                          }
                      }}
                    />
                  </CardBody>}
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddressList.propTypes = {
  addresses: PropTypes.array,
  onGetAddresses: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    addresses: state.Customer.addresses,
    loading: state.Customer.loading,
    error: state.Customer.getAddressesError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetAddresses: (customerId) => dispatch(getAddressList(customerId)),
  onGetAddressesReset: () => dispatch(getAddressListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(AddressList)));