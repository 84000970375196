import PropTypes from "prop-types";
import React, {useCallback, useEffect, useRef, useState} from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";
import UserImpersonation from "../../pages/Authentication/UserImpersonation";
import {changeSidebarType, showRightSidebarAction, toggleLeftmenu,} from "../../store/actions";
import {connect} from "react-redux";
import {equalsAny, hasAnyModuleEnabled, hasModuleEnabled} from "../../util/helperFunctions";

const SidebarContent = (props) => {
    const ref = useRef();
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [subCategoryPageClicks, setSubCategoryPageClicks] = useState(0);
    let [showDrop, setShowDrop] = useState(false);
    let [nestedDrop, setNestedDrop] = useState(false);
    const handleClickAway = () => {
        if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    };
    const activateParentDropdown = useCallback((item) => {
        item.classList.add("active");
        const parent = item.parentElement;
        const parent2El = parent.childNodes[1];
        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show");
        }

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show"); // ul tag

                const parent3 = parent2.parentElement; // li tag

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement; // ul
                    if (parent4) {
                        parent4.classList.add("mm-show"); // ul
                        const parent5 = parent4.parentElement;
                        if (parent5) {
                            parent5.classList.add("mm-show"); // li
                            parent5.childNodes[0].classList.add("mm-active"); // a tag
                        }
                    }
                }
            }
            scrollElement(item);
            return false;
        }
        scrollElement(item);
        return false;
    }, []);

    const HandleSideBarClose = () => {
        props.changeSidebarType("default", isMobile);
    };

    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {
        let pathName = props.location.pathname;

        if (pathName.endsWith("/subcategory")) {
            pathName = "/categories";
            setSubCategoryPageClicks(subCategoryPageClicks + 1)
        } else {
            setSubCategoryPageClicks(0);
        }
        if (pathName.endsWith("/layout")) {
            pathName = "/templates";
        }
        if (pathName.startsWith("/customers/") && pathName.includes("/users/")) {
            pathName = "/users";
        }
        if (pathName.startsWith("/languages/labels/")) {
            pathName = "/translations";
        }
        if (pathName.endsWith("/currencies")) {
            pathName = "/settings/currency-config";
        }

        // Temporary fix for issue #139: "Products" dropdown not clickable when subcategory page is active.
        // the issue occurs for leaf subcategory node
        // the line: new MetisMenu("#side-menu") causes the above issue.
        // for subcategory change pathName to /category so that it shows Category as active menu item in dropdown
        if (subCategoryPageClicks < 1) {
            new MetisMenu("#side-menu")
        }

        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (items[i].pathname.length > 1) {
                if (pathName.startsWith(items[i].pathname)) {
                    activateParentDropdown(items[i]);
                    break;
                }
            } else if (items[i].id === "tabs-menu" && (pathName === '/templates' || pathName === '/fields/article')) {
                activateParentDropdown(items[i]);

                const ul2 = document.getElementById("tabs-sub-menu");
                if (ul2) {
                    const items2 = ul2.getElementsByTagName("a");
                    for (let j = 0; j < items2.length; ++j) {
                        if (pathName === items2[j].pathname) {
                            activateParentDropdown(items2[j]);
                            break;
                        }
                    }
                }
                break;
            }
        }
    }, [props.location.pathname, activateParentDropdown]);

    useEffect(() => {
        ref.current.recalculate();
    });

    function scrollElement(item) {
        if (item) {
            const currentPosition = item.offsetTop;
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300;
            }
        }
    }

    return (
        <React.Fragment>
            <SimpleBar
                style={{maxHeight: "100%"}}
                ref={ref}
                className="sidebar-menu-scroll"
            >
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        {/*<li className="menu-title">{props.t("Menu")} </li>*/}
                        {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/#"
                                    className="waves-effect"
                                >
                                    <i className="uil-home-alt"></i>
                                    <span>{props.t("Dashboard")}</span>
                                </Link>
                            </li>
                        )}
                        {user?.roles?.includes("ROLE_SUPER_ADMIN") && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/tenants"
                                    className="waves-effect"
                                >
                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Tenants")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled (user?.tenantFeatures, "CUSTOMERS") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Customers")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/customers">
                                            {props.t("Companies")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/users">
                                            {props.t("Users")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}


                        {hasModuleEnabled (user?.tenantFeatures, "ARTICLES") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-store"></i>
                                    <span>{props.t("Products")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/articles">
                                            {props.t("Articles")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/groups">
                                            {props.t("Article Groups")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/categories">
                                            {props.t("Categories")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/productLists">
                                            {props.t("Product Lists")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to={"/manufacturers"}>
                                            {props.t("Manufacturers")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>


                        )}


                        

                        {hasAnyModuleEnabled (user?.tenantFeatures, ["OFFERS", "ORDERS", "INVOICES", "STATISTICS"]) && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil-shopping-cart-alt"></i>
                                    <span>{props.t("Sales")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    {hasModuleEnabled (user?.tenantFeatures, "OFFERS") && <>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/offers"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Offers")}</span>
                                        </Link>
                                    </li>
                                    </>}
                                    {hasModuleEnabled (user?.tenantFeatures, "ORDERS") && <>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/orders"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Orders")}</span>
                                        </Link>
                                    </li>
                                    </>}
                                    {hasModuleEnabled (user?.tenantFeatures, "INVOICES") && <>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/invoice"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Invoices")}</span>
                                        </Link>
                                    </li>
                                    </>}
                                    {hasModuleEnabled (user?.tenantFeatures, "STATISTICS") && <>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/statistics"
                                            className="waves-effect">
                                            <span>{props.t("Statistics")}</span>
                                        </Link>
                                    </li>
                                    </>}
                                </ul>
                            </li>
                        )}

                        {hasModuleEnabled (user?.tenantFeatures, "PROJECTS")  && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/projects"
                                    className="waves-effect"
                                >
                                    <i className="uil-apps"></i>
                                    <span>{props.t("Projects")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled (user?.tenantFeatures, "TASKS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/tasks"
                                    className="waves-effect"
                                >
                                    <i className="uil-channel-add"></i>
                                    <span>{props.t("Tasks")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled (user?.tenantFeatures, "TASKS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/timeslips"
                                    className="waves-effect"
                                >
                                    <i className="uil uil-clock font-size-20"></i>
                                    <span>{props.t("Timeslips")}</span>
                                </Link>
                            </li>
                        )}

                        {hasModuleEnabled (user?.tenantFeatures, "EVENTS") && equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/events"
                                    className="waves-effect"
                                >
                                    <i className="uil-clipboard-notes"></i>
                                    <span>{props.t("Events")}</span>
                                </Link>
                            </li>
                        )}



                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link
                                    onClick={HandleSideBarClose}
                                    to="/settings/users"
                                    className="waves-effect"
                                >
                                    <i className="uil-users-alt"></i>
                                    <span>{props.t("Users")}</span>
                                </Link>
                            </li>
                        )}


                        {/*{equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"]) && (
                            <li className="menu-title">{props.t("Settings")}</li>
                        )}



                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil uil-sliders-v-alt"></i>
                                    <span>{props.t("Site Config")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/settings/brand">
                                            {props.t("Branding")}
                                        </Link>
                                    </li>
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                            <Link onClick={HandleSideBarClose} to="/translations">
                                                {props.t("Language")}
                                            </Link>
                                        </li>
                                    )}
                                    {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN"]) && (
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <span>{props.t("Language")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link
                                                        onClick={HandleSideBarClose}
                                                        to="/translations"
                                                        className="waves-effect"
                                                    >
                                                        <span>{props.t("Translations")}</span>
                                                    </Link>
                                                </li>
                                                {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN"]) && (
                                                    <li>
                                                        <Link
                                                            onClick={HandleSideBarClose}
                                                            to="/phrases"
                                                            className="waves-effect"
                                                        >
                                                            <span>{props.t("Phrases")}</span>
                                                        </Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </li>
                                    )}
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/settings/date-config">
                                            {props.t("Date")}
                                        </Link>
                                    </li>
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                            <Link onClick={HandleSideBarClose} to="/settings/default-currency-config">
                                                {props.t("Currency")}
                                            </Link>
                                        </li>
                                    )}
                                    {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN"]) && (
                                        <li>
                                            <Link onClick={HandleSideBarClose} to="/settings/currency-config">
                                                {props.t("Currency")}
                                            </Link>
                                        </li>
                                    )}
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/settings/fortnox-config">
                                            {props.t("Fortnox Config")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/settings/smtp-config"
                                            className=" waves-effect"
                                        >
                                            <span>{props.t("Email")}</span>
                                        </Link>
                                    </li>
                                    {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                            <Link
                                                onClick={HandleSideBarClose}
                                                to="/socket-log"
                                                className="waves-effect"
                                            >
                                                <span>{props.t("Socket Log")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN"]) && (
                                        <li>
                                            <Link
                                                onClick={HandleSideBarClose}
                                                to="/deep-sync"
                                                className="waves-effect"
                                            >
                                                <span>{props.t("Sync")}</span>
                                            </Link>
                                        </li>
                                    )}
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                            <Link onClick={HandleSideBarClose} to="/deep-sync">
                                                {props.t("Sync")}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil uil-store"></i>
                                    <span>{props.t("Products")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/fields/article">
                                            {props.t("Fields")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/tabs">
                                            {props.t("Tabs")}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={HandleSideBarClose} to="/templates">
                                            {props.t("Templates")}
                                        </Link>
                                    </li>
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                            <Link onClick={HandleSideBarClose} to="/settings/stock">
                                                {props.t("Stock")}
                                            </Link>
                                        </li>
                                    )}
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                                        <li>
                                          <Link onClick={HandleSideBarClose} to="/bulk-actions">
                                            {props.t("Data import/export")}
                                          </Link>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil uil-apps"></i>
                                    <span>{props.t("Projects")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/project-templates"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Templates")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/project-fields/project"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Fields")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/settings/project-config"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Config")}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}

                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil uil-channel-add"></i>
                                    <span>{props.t("Tasks")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/settings/task-categories"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Categories")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/settings/task-priorities"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Priorities")}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}

                        {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN"]) && (
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="uil uil-clock"></i>
                                    <span>{props.t("Time")}</span>
                                </Link>
                                <ul className="sub-menu">
                                    <li>
                                        <Link
                                            onClick={HandleSideBarClose}
                                            to="/settings/timeslip-types"
                                            className="waves-effect"
                                        >
                                            <span>{props.t("Timeslip Types")}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}*/}
                        <div className="cs-hide-xl">
                            {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_ACTING_USER"]) && (
                                <UserImpersonation/>
                            )}
                        </div>
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent.propTypes = {
    location: PropTypes.object,
    changeSidebarType: PropTypes.func,
    t: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const {layoutType, showRightSidebar, leftMenu, leftSideBarType} =
        state.Layout;
    return {layoutType, showRightSidebar, leftMenu, leftSideBarType, brandSettings: state.BrandSettings};
};

export default withRouter(
    connect(mapStatetoProps, {
        showRightSidebarAction,
        toggleLeftmenu,
        changeSidebarType,
    })(withTranslation()(SidebarContent))
);
