export const GET_ALL_CART_ITEMS = "GET_ALL_CART_ITEMS"
export const GET_ALL_CART_ITEMS_SUCCESS = "GET_ALL_CART_ITEMS_SUCCESS"
export const GET_ALL_CART_ITEMS_FAIL = "GET_ALL_CART_ITEMS_FAIL"

export const POST_ADD_CART_ITEM = "POST_ADD_CART_ITEM"
export const POST_ADD_CART_ITEM_SUCCESS = "POST_ADD_CART_ITEM_SUCCESS"
export const POST_ADD_CART_ITEM_FAIL = "POST_ADD_CART_ITEM_FAIL"

export const PUT_UPDATE_CART_ITEM = "PUT_UPDATE_CART_ITEM"
export const PUT_UPDATE_CART_ITEM_SUCCESS = "PUT_UPDATE_CART_ITEM_SUCCESS"
export const PUT_UPDATE_CART_ITEM_FAIL = "PUT_UPDATE_CART_ITEM_FAIL"

export const PUT_UPDATE_CART_ITEM_FROM_INPUT = "PUT_UPDATE_CART_ITEM_FROM_INPUT"

export const RESET_CART_MESSAGE = "RESET_CART_MESSAGE"