import React from "react";
import {Button, Col, Container, Row} from "reactstrap";
import errorImage from "../../assets/images/404-error.png";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


const NotFoundPage = (props) => {
    const history = useHistory();

    const handleBack = () => {
        history.goBack()
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid={true}>
                <Row className="text-center mt-auto align-content-center">
                    <Col lg={12} className="mt-lg-5">
                        <img src={errorImage} style={{width: "40%"}}></img>
                    </Col>
                    <Col lg={12} className="mt-lg-5">
                        <h1>{props.t("Page not found")}</h1>
                        <p>
                            {props.t("The Page you are looking for doesn't exist.")}
                        </p>
                        <h2><Button
                            color="primary"
                            className="btn btn-primary waves-primary text-center mt-4"
                            onClick={handleBack}>
                            {props.t("Go back")}
                        </Button>{" "}</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>)
}

export default connect()(withTranslation()(NotFoundPage))
