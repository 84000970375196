export const GET_TENANT_ADMIN_USERS = "GET_TENANT_ADMIN_USERS"
export const GET_TENANT_ADMIN_USERS_SUCCESS = "GET_TENANT_ADMIN_USERS_SUCCESS"
export const GET_TENANT_ADMIN_USERS_FAIL = "GET_TENANT_ADMIN_USERS_FAIL"
export const GET_TENANT_ADMIN_USERS_RESET = "GET_TENANT_ADMIN_USERS_RESET"
export const REGENERATE_TOKEN = "REGENERATE_TOKEN"
export const REGENERATE_TOKEN_SUCCESS = "REGENERATE_TOKEN_SUCCESS"
export const REGENERATE_TOKEN_FAIL = "REGENERATE_TOKEN_FAIL"
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

export const RESET_DELETE_USER = "RESET_DELETE_USER"
export const RESET_REGENERATE_TOKEN = "RESET_REGENERATE_TOKEN"

export const GET_TENANT = "GET_TENANT"
export const GET_TENANT_SUCCESS = "GET_TENANT_SUCCESS"
export const GET_TENANT_FAIL = "GET_TENANT_FAIL"
export const GET_TENANT_RESET = "GET_TENANT_RESET"

export const GET_TENANT_DETAILS = "GET_TENANT_DETAILS"
export const GET_TENANT_DETAILS_SUCCESS = "GET_TENANT_DETAILS_SUCCESS"
export const GET_TENANT_DETAILS_FAIL = "GET_TENANT_DETAILS_FAIL"
export const GET_TENANT_DETAILS_RESET = "GET_TENANT_DETAILS_RESET"

export const GET_TENANT_CUSTOMERS = "GET_TENANT_CUSTOMERS"
export const GET_TENANT_CUSTOMERS_SUCCESS = "GET_TENANT_CUSTOMERS_SUCCESS"
export const GET_TENANT_CUSTOMERS_FAIL = "GET_TENANT_CUSTOMERS_FAIL"
export const GET_TENANT_CUSTOMERS_RESET = "GET_TENANT_CUSTOMERS_RESET"

export const tenantsActionTypes = {
    GET_TENANT_DETAILS,
}
