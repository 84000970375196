import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {get} from "../../helpers/api_helper";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import PropTypes from "prop-types";
import {getCronLog} from "../../store/config/actions";
import {MDBDataTable} from "mdbreact";
import {getTenants} from "../../store/tenant/actions";

const CronList = (props) => {
    const {header} = props.location.state;

    const {loading, cronLogList, onGetCronLog, tenants, onGetTenants} = props

    const [brandSettings, setBrandSettings] = useState(null);
    const [cron, setCron] = useState(null);
    const CRON_LIST = [{"key": "/api/cron/pricelist", "value": "PriceList Sync"}, {"key": "/api/cron/customers", "value": "Customer Sync"}]
    const [tenant, setTenant] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetCronLog();
        onGetTenants();
    }, [])

    const execute = async () => {
        let url = cron;
        if (tenant) {
            url += "?tenantId=" + tenant;
        }
        setCron(null);
        let response = await get(url);
        onGetCronLog();
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 300,
                },
                {
                    label: props.t("Tenant"),
                    field: "tenant",
                    width: 300
                },
                {
                    label: props.t("Start time"),
                    field: "start",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("End time"),
                    field: "end",
                    sort: "asc",
                    width: 200,
                },

            ],
            rows: cronLogList?.map(
                (item, key) => ({
                    name: item.name,
                    tenant: item?.tenant ? item?.tenant?.name : props.t("All Tenants"),
                    start: item.start,
                    end: item.end
                })
            ),
        };
        return data;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={loading} title="Cron Log"/>

                                <CardBody>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Tenant")}
                                        </label>
                                        <div className="col-md-10">
                                            <select className="form-control" onChange={(e) => setTenant(e.target.value)}>
                                                <option value={""}>{props.t("All Tenants")}</option>
                                                {tenants.map(function (tenant) {
                                                    return <option value={tenant.id}>{tenant.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Sync")}
                                        </label>
                                        <div className="col-md-10">
                                            <select className="form-control" onChange={(e) => setCron(e.target.value)}>
                                                <option value={""}>{props.t("Choose cron job")}</option>
                                                {CRON_LIST.map(function (lang) {
                                                    return <option value={lang.key}>{lang.value}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col align="center">
                                            <Button
                                                type="submit"
                                                color="success"
                                                className="btn btn-success w-lg waves-effect waves-light me-2"
                                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                onClick={execute}
                                                disabled={cron == null}
                                            >
                                                {props.t("Execute")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {!loading &&
                                            <>
                                                <MDBDataTable
                                                    responsive
                                                    barReverse={true}
                                                    hover
                                                    borderless
                                                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                    entriesLabel={props.t("Show entries")}
                                                    infoLabel={[
                                                        props.t("Showing"),
                                                        props.t("to"),
                                                        props.t("of"),
                                                        props.t("entries"),
                                                    ]}
                                                    // searchLabel={props.t('Search') + '...'}
                                                    paging={false}
                                                    noBottomColumns={true}
                                                    data={getDatatableData()}
                                                    sortable={true}
                                                    searching={false}
                                                    sortRows={[
                                                        "created",
                                                        "description",
                                                        "articleNumber",
                                                        "category",
                                                    ]}
                                                    // onSearch={(text) => console.log(text)}
                                                    noRecordsFoundLabel={props.t("No matching records found")}
                                                />
                                            </>
                                        }
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

CronList.propTypes = {
    cronLogList: PropTypes.array,
    cronListError: PropTypes.object,
    loading: PropTypes.bool,
    tenants: PropTypes.array,
    onGetTenants: PropTypes.func
}

const mapStateToProps = state => {
    return {
        cronLogList: state.Config.cronLog,
        loading: state.Config.cronLogLoading,
        cronListError: state.Config.cronLogLoading,
        tenants: state.tenant.tenantList,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetCronLog: () => dispatch(getCronLog()),
    onGetTenants: () => dispatch(getTenants()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CronList));
