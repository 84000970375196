import React, {useEffect, useState} from "react";
import {Alert, Col, Row,} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {isEmpty, isEqual} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import {impersonateUser} from "../../store/actions";
import Loader from "../../components/Common/Loader";
import {getAudit, getAuditReset} from "../../store/audit/actions";
import InfiniteScrollbarWithLoader from "../../components/Common/InfiniteScrollbarWithLoader";
import AccordionButton from "../../components/Common/AccordionButton";
import {userRoutes} from "../../routes/allRoutes";
import {getUpdatedList} from "../../util/helperFunctions";

export const customerEventManagementContext = React.createContext(null);

const EventManagement = (props) => {
  const {
    audit, metaInfo, loading, error, onGetAudit, onGetAuditReset, onImpersonateUser, customerId, articleEvents, articleId, manufacturerId, userId,
    taskEvents, taskId
  } = props;
  const [auditList, setAuditList] = useState([]);
  const [nextPageItemsLoading, setNextPageItemsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [brandSettings, setBrandSettings] = useState(null)
  const [filter, setFilter] = useState(null)
  let history = useHistory();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(16);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState();

  let user = JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    setBrandSettings(user?.brandConfig);

    const prevFilter = localStorage.getItem(articleEvents ? "articleEventsTabFilter" : "customerProfileEventsTabFilter");
    if(prevFilter){
        setFilter(prevFilter);
    } else {
      setFilter("");
    }
    return () => {
      onGetAuditReset();
    };
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetAuditReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if(!audit){
      setAuditList([]);
    }
    else if(!isEmpty(audit) && !isEqual(audit, auditList)) {
      setAuditList(getUpdatedList(auditList, audit));
    }
  }, [audit]);

  useEffect(() => {
      setAuditList([])
      setPage(1);
      localStorage.setItem(articleEvents ? "articleEventsTabFilter" : "customerProfileEventsTabFilter", filter);
      const searchFilter = getSearchFilter();
      onGetAudit({...searchFilter, page: page-1, size: sizePerPage});
  }, [filter]);

  useEffect(() => {
    if (
        page !== 1 &&
        auditList.length < totalItems &&
        !loading &&
        page <= totalPage
    ) {
      const searchFilter = getSearchFilter();
      onGetAudit({...searchFilter, page: page - 1, size: sizePerPage});
    }
  }, [page])

  useEffect(() => {
    if(error){
      setErrorMessage(props.t(API_ERR_MSG));
    }
  }, [error])

  useEffect(() => {
    if(metaInfo){
      setTotalPage(metaInfo.totalPages);
      setTotalItems(metaInfo.totalItems);
    }
  }, [metaInfo]);

  const getSearchFilter = () => {
    let entityId = null;
      let entityType = null;
      if(articleId) {
        entityId = articleId;
        entityType = "Article";
      } else if(taskId) {
        entityId = taskId;
        entityType = "Task";
      } else if(manufacturerId){
        entityId = manufacturerId;
        entityType = "Manufacturer"
      }
      const searchFilter = {
        operation: filter && filter !== "" ? filter : null, 
        customerId: customerId, 
        entityId, 
        entityType, 
        byUserId: userId ? userId : null
      };
      return searchFilter;
  }

  const handleFilter = (option) => {
    setFilter(option);
  }

  const getColorCodedOperation = (operation) => {
    let colorClsName = "bg-soft-success bg-success";
    if(operation === "DELETE"){
      colorClsName = "bg-soft-danger bg-danger";
    } else if(operation === "UPDATE"){
      colorClsName = "bg-soft-warning bg-warning";
    }
    const clsName = "product-ribbon badge " + colorClsName;
    return <>
      <div className={clsName} style={{fontSize:"14px"}}>
        {" "}
        {props.t(operation)}{" "}
      </div>
    </>
  }

  const getEventDetails = (event) => {
    if(event.operation !== 'DELETE'){
      // special handling for User entities becuase User entity can be super admin user, tenant user or customer user
      if(event.entity === 'User'){
        // if event is User with id same as logged in user then route user to user profile page.
        if(event.entityId == user?.uid){
          return history.push('/settings/user-profile');
        }

        if(event.customerId){
          if(!user?.tenantId) {
            return; // cannot open customer user details in super admin view
          } else {
            return history.push(`/customers/${event.customerId}/users/${event.entityId}`)
          }
        }
      }
      let routes = userRoutes.filter(route => route.eventMap?.entityTypes 
        && route.eventMap.entityTypes.includes(event.entity) 
        && (!route.allowedRoles 
          || (user?.customerId && (route.allowedRoles.includes('ROLE_CUSTOMER_USER') || route.allowedRoles.includes('ROLE_CUSTOMER_ADMIN')))
          || (user?.tenantId && (route.allowedRoles.includes('ROLE_TENANT_USER') || route.allowedRoles.includes('ROLE_TENANT_ADMIN')))
          || (route.allowedRoles.includes('ROLE_SUPER_ADMIN')))
        && !((!user?.tenantId && event.tenantId && route.eventMap.impersonationType === 'TENANT') 
          || (!user?.customerId && event.customerId && route.eventMap.impersonationType === 'CUSTOMER')));

        if(routes && routes.length > 0) {
          let path = routes[0].path;
          path = path.replace(':id', event?.documentNumber ? event.documentNumber : event.entityId);
          if(path.includes(':customerId') && event.customerId){
            path = path.replace(':customerId', event.customerId);
          }

          const tenantId = event.entity === 'Tenant' ? event.entityId : event.tenantId
          if(path.includes(':tenantId') && tenantId){
            path = path.replace(':tenantId', tenantId?.id);
          }
          history.push(path);
        }
    }
  }

  const getImpersonatedRoute = (event) => {
    if(event.entity === 'User'){
      // if event is User with id same as logged in user then route user to user profile page.
      if(event.entityId == user?.uid){
        return null;
      }

      if(event.customerId){
        if(user.customerId) return null;

        return {path: `/customers/:customerId/users/:id`}
      }
    }
    let routes = userRoutes.filter(route => route.eventMap?.entityTypes 
      // && event.entity != 'User' && event.entity != 'Task' // details are available in tenant view for this entities
      && route.eventMap.entityTypes.includes(event.entity)
      && ((!user?.tenantId && event.tenantId && route.eventMap.impersonationType === 'TENANT') 
        || (!user?.customerId && event.customerId && route.eventMap.impersonationType === 'CUSTOMER')));

    if(routes && routes.length > 0) {
      return routes[0];
    }

    return null;
  }

  const handleImpersonate = async (audit) => {
    let route = getImpersonatedRoute(audit);
    let path = route.path;
    path = path.replace(':id', audit.entityId);
    
    if(path.includes(':customerId') && audit.customerId){
      path = path.replace(':customerId', audit.customerId);
    }
    if(path.includes(':tenantId') && audit.tenantId){
      path = path.replace(':tenantId', audit.tenantId?.id);
    }
    let loc = {...location};
    loc['pathname'] = path;
    if(audit.customerId && (!route.allowedRoles || route.allowedRoles.includes('ROLE_CUSTOMER_USER') || route.allowedRoles.includes('ROLE_CUSTOMER_ADMIN'))){
      onImpersonateUser({ customerId: audit.customerId }, history, loc)
    } else if(audit.tenantId && (!route.allowedRoles || route.allowedRoles.includes('ROLE_TENANT_USER') || route.allowedRoles.includes('ROLE_TENANT_ADMIN'))){
      onImpersonateUser({ tenantId: audit.tenantId.id }, history, loc)
    }
  }

  const getStatusView = (audit, key) => {
    if(!audit){
      return;
    }
    return (
    <li key={key} className="event-list">
      <div 
       className="event-list-content"
      >
        <div className="event-date text-primary">{new Date(audit?.updatedAt).toLocaleString()}</div>
        <div className="d-flex">
          <h5 style={{cursor: 'pointer'}} onClick={() => {
            getEventDetails(audit);
          }}>{props.t(audit?.entity)} {audit?.name && ` - ${audit?.name}`}</h5>
        </div>
        {getColorCodedOperation(audit.operation)} <label>{" "} {audit.fortnoxChange == true ? props.t("by ERP Fortnox") : audit.byUser?.name}</label>
        {audit.operation !== 'DELETE' && getImpersonatedRoute(audit) &&  audit.fortnoxChange != true &&
        <>
          <i 
            className="fa fa-sign-in-alt" 
            style={{fontSize: "16px", marginLeft: "10px", cursor: "pointer"}} 
            onClick={()=> handleImpersonate(audit)}
          >
          </i>
        </>}
        {audit.operation === 'UPDATE' && audit?.difference && <><br/>
          <AccordionButton content={
            /*JSON.parse(audit.difference.value)?.map(function(key){
              for(var label in key){
                return  <div className="d-flex"><p className="m-0">{props.t(label)}</p><p className="text-muted m-0">{": " + key[label]}</p></div> 
              }
            })*/
            JSON.stringify(JSON.parse(audit?.difference?.value), null, 2)
          }
           activeColor={brandSettings?.primaryBtnColor}
          /></>
        }
      </div>
    </li>)
  }

  return (
        <React.Fragment>
          <Loader loading={loading && page == 1} title="Customer Events" />
          {!(loading && page == 1) &&
          <Row>
            <Col className="col-12 article-table">
              {error && (
                <Alert className="text-align-center" color="danger">
                  {error}
                </Alert>
              )}
              <div className="d-flex justify-content-end mb-3">
                <select
                  className="form-control table-filter-dropdown"
                  id="filter"
                  onChange={e => handleFilter(e.target.value)}
                  value={filter}
                  style={{ backgroundColor: brandSettings?.primaryBtnColor, color: 'white' }}
                >
                  <option value={""}>
                    {props.t("Select Operation")}
                  </option>
                  <option value={"INSERT"}>
                    {props.t("INSERT")}
                  </option>
                  <option value={"UPDATE"}>
                    {props.t("UPDATE")}
                  </option>
                  <option value={"DELETE"}>
                    {props.t("DELETE")}
                  </option>
                </select>
              </div>
              {!isEmpty(auditList) ? 
              <div className="">
                <ul className="verti-timeline list-unstyled">
                  <InfiniteScrollbarWithLoader 
                      items={auditList}
                      loading={loading}
                      totalItems={totalItems}
                      renderCallback={getStatusView}
                      page={page}
                      setPage={setPage} 
                      type={props.t("Events")}   
                      />
                </ul>
              </div> : (
              <h6
                align="center"
                className="font-color-grey"
              >
                {props.t("No matching records found!")}
              </h6>
              )}
            </Col>
          </Row>}
        </React.Fragment>
  );
};

EventManagement.propTypes = {
  audit: PropTypes.array,
  onGetAudit: PropTypes.func,
  onGetAuditReset: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    audit: state.Audit.audit?.data,
    metaInfo: {
        page: state.Audit.audit.currentPage + 1,
        totalPages: state.Audit.audit.totalPages,
        totalItems: state.Audit.audit.totalItems,
    },
    loading: state.Audit.loading,
    error: state.Audit.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetAudit: (filter) => dispatch(getAudit(filter)),
  onGetAuditReset: () => dispatch(getAuditReset()),
  onImpersonateUser: (obj, history, location) =>
      dispatch(impersonateUser(obj, history, location, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(EventManagement)));
