import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row,} from "reactstrap";

import {Search} from "react-bootstrap-table2-toolkit";

import {isEmpty, orderBy} from "lodash";
import {find, isEqual, union} from "lodash-es";


// RangeSlider
import "nouislider/distribute/nouislider.css";

import {getProductByCategory, getProductByCategoryReset, getProductByGroup, getProducts, getProductsReset, getSubCategoryList} from "../../store/e-commerce/actions";

import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {Tooltip} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {CartService} from "../ShoppingCart/CartService";
import Loader from "../../components/Common/Loader";
import {get, put} from "../../helpers/api_helper";
import {debounce, getUpdatedList, preventNumberInputScroll} from "../../util/helperFunctions";
import InfiniteScrollbarWithLoader from "../../components/Common/InfiniteScrollbarWithLoader";
import {getArticleConfig, putUpdateCartItemFromInput, resetCartMessage} from "../../store/actions";
import {isStockable} from "../../util/articleUtil";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";
import ArticleListTable from "../Articles/ArticleListTable";

const ShopArticles = (props) => {
  const {
    products,
    history,
    onGetProducts,
    metaInfo,
    articlesByCategory,
    articlesByGroup,
    category,
    onGetSubCategoryList,
    onGetProductsByCategory,
    onGetProductsByGroup,
    articlesPending,
    error,
    onGetProductsReset,
    articlesByCategoryPending,
    articlesByCategoryError,
    cartItems, 
    onGetArticleConfig, 
    articleConfig,
    onGetProductByCategoryReset,
    cartItemsLoading,
    cartItemError,
    onResetCartMessage,
    match: { params },
  } = props;

  let toggle = localStorage.getItem("article-view") == "TABLE";

  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [selectCategory, setSelectedCategory] = useState(category);
  const [sizePerPage, setSizePerPage] = useState(8);
  const [totalItems, setTotalItems] = useState(0);
  const [isGrid, setIsGrid] = useState({
    value: !toggle,
    label: props.t("Show grid"),
  });
  const [search, setSearch] = useState(null);
  const [brandSettings, setBrandSettings] = useState(null)
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];
  let qtyClickCount = 0, qtyPrevClickCount = 0;
  const [articleQuantityMap, setArticleQuantityMap] = useState([]);

  const dispatch = useDispatch();

  const { SearchBar } = Search;

  const getToggleLabel = (isTable) => {
    return props.t("Show grid");
  };

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    onGetArticleConfig();

    return () => {
        onGetProductsReset();
        onGetProductByCategoryReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetProductsReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    setPage(1);
    if (isGrid.value) {
      setProductList([]);
      loadProducts(0, sizePerPage, search === "null" ? '': search);
    }
  }, [isGrid]);

  useEffect(() => {
    setPageInfo(products);
    if (
      products &&
      !isEmpty(products.data) &&
      !isEqual(products.data, productList)
    ) {
      if(search && search.trim() != '') {
        setProductList(products.data);
      } else {
        setProductList(getUpdatedList(productList, products.data));
      }
    }
    setIsFavoriteLoading(false)
  }, [products]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setProductList(orderBy(productList, o => {
          if(column === 'description') return o.displayName ? o.displayName?.toLowerCase() : o[column]?.toLowerCase()
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  useEffect(() => {
    setProductList([]);
    setPage(1);
    if (params) {
      if (params.categoryId) {
        onGetSubCategoryList(params.categoryId, page - 1, sizePerPage);

        if (isGrid.value) {
          onGetProductsByCategory(params.categoryId, 0, sizePerPage, search === "null" ? '' : search);
        }
      } else if(params.groupId){
        if(isGrid.value){
          onGetProductsByGroup(params.groupId, 0, sizePerPage, search);
        }
      } else {
        if (isGrid.value) {
          onGetProducts(0, sizePerPage, search);
        }
      }
    }
  }, [params]);

  useEffect(() => {
    setPageInfo(articlesByCategory);
    if (
      articlesByCategory &&
      !isEmpty(articlesByCategory.data) &&
      !isEqual(articlesByCategory.data, productList)
    ) {
      if(search && search.trim() != '') {
        setProductList(articlesByCategory.data);
      } else {
        setProductList(getUpdatedList(productList, articlesByCategory.data));
      }
    }
  }, [articlesByCategory]);

  useEffect(() => {
    setPageInfo(articlesByGroup);
    if (
        articlesByGroup &&
        !isEmpty(articlesByGroup.data) &&
        !isEqual(articlesByGroup.data, productList)
    ) {
      if(search && search.trim() != '') {
        setProductList(articlesByGroup.data);
      } else {
        setProductList(union(productList, articlesByGroup.data));
      }
    }
  }, [articlesByGroup])

  useEffect(() => {
    setSelectedCategory(category);
  }, [category]);

  useEffect(() => {
    if (
      page !== 1 &&
      productList.length < totalItems &&
      !articlesPending &&
      !articlesByCategoryPending &&
      page <= totalPage
    ) {
      // onGetProductsByCategory(params.categoryId, (page -1 ), sizePerPage);
      loadProducts(page, sizePerPage, search);
    }
  }, [page]);

  useEffect(() => {
    if(cartItems) {
      setArticleQuantityMap(cartItems.map(item => ({ articleId: item.article.id, quantity: item.quantity })));
    }
  }, [cartItems])

  useEffect(() => {
    if(cartItemError){
      cogoToast.error(cartItemError, toastOptions)
      setTimeout(() => {
        onResetCartMessage();
      }, 2000)
    }
  }, [cartItemError])

  const loadProducts = (page, sizePerPage, search) => {
    // setProductList([]);
    onGetProductsReset();
    if (params && params.categoryId) {
      onGetProductsByCategory(params.categoryId, page, sizePerPage, search);
    } else if(params.groupId){
      onGetProductsByGroup(params.groupId, page, sizePerPage, search);
    } else {
      onGetProducts(page, sizePerPage, search);
    }
  }

  const setPageInfo = (metaInfo) => {
    setTotalPage(metaInfo?.totalPages);
    setTotalItems(metaInfo?.totalItems);
  };

  function getArticleQuantityInCart(product){
    const cartItem = find(cartItems, item => item.article.id == product.id);
    return cartItem ? cartItem.quantity : 0;
  }


  const getTitle = (name) => {
    return name.length > 94 ? name.substr(0, 91) + "..." : name;
  };


  const getBreadcrumbs = () => {
    let crumbs = params.categoryId ? [
      { link: "/customer/articles", title: "Articles" },
      { link: "/customer/categories", title: "Categories" },
    ] : [
      { link: "#", title: "Products" }
    ];
    let additionalCrumbs = [];
    if (params.categoryId && selectCategory != null && selectCategory.parents != null) {
      additionalCrumbs = [
        ...selectCategory?.parents?.map((item, index) => ({
          title: item.name,
          link: `/customer/categories/${item.id}/subcategory`,
        })),
      ];
      crumbs = [...crumbs, ...additionalCrumbs];
    }
    return crumbs;
  }

  const onToggleChange = () => {
    let value = !isGrid.value;
    setIsGrid({
      value: value,
      label: getToggleLabel(value),
    });
    localStorage.setItem("article-view", value ? "GRID" : "TABLE");
  };


  const handleClearSearch = (e) => {
    if (e.key === 'Backspace' && search.length == 0) {
      setProductList([]);
      setPage(1);
      loadProducts(0, sizePerPage, search)
    }
  }

  const addToFavorite = async (product) => {
    setIsFavoriteLoading(true);
    let response = await get(`/api/article/favorite/${product.id}`);
    product.favorite = !product.favorite;
    for(var i = 0; i < productList.length; i++){
      if(productList[i].id == product.id){
        productList[i].favorite = product.favorite;
        break;
      }
    }
    setIsFavoriteLoading(false);
  }

  const handleQuantityChange = (product, decrementQuantity) => {
    qtyPrevClickCount = qtyClickCount;
    qtyClickCount++;
    setTimeout(() => {
      // check if input is clicked multiple times or just one time in 1sec timespan
      if((qtyClickCount != 0 && qtyPrevClickCount == qtyClickCount) || (qtyClickCount == 1 && qtyPrevClickCount == 0)){
        const articleQty = getQuantityVaue(product);
        let inputQty = 0;
        if(decrementQuantity){
          // quantity cannot go below 0
          if(articleQty - qtyClickCount > 0){
            inputQty = articleQty - qtyClickCount; 
          }
        } else {
          inputQty = articleQty + qtyClickCount;
        }
        CartService.addItemsToCart(product, inputQty);
        setArticleQuantityMap(articleQuantityMap.map(item => ({
            ...item, 
            quantity: product.id == item.articleId ? inputQty : item.quantity
        })));

        // reset counts
        qtyClickCount = 0;
        qtyPrevClickCount = 0;
      } else {
        // if all the clicks are done and 1 sec passed then set prev click count to current to execute the above if condition
        qtyPrevClickCount = qtyClickCount;
      }
    }, 1000)
  }

  const processInputQuantityChange = debounce((arg) => {
      const {quantity, product} = arg;
      const cartItem = find(cartItems, item => item.article.id == product.id);
      if(cartItem){
        setArticleQuantityMap(articleQuantityMap.map(item => ({
            ...item, 
            quantity: product.id == item.articleId ? quantity : item.quantity
        })));
        put(`/api/cart`, {id: cartItem.id, article: {id: product.id}, quantity});
        dispatch(putUpdateCartItemFromInput({...cartItem, quantity}));
      }
  });

  const getQuantityVaue = (product) => {
      const item = find(articleQuantityMap, {articleId: product.id});
      return item && item.quantity > 0 ? item.quantity : "";
  }

  const getProductView = (product, key) => {
    if(!product){
      return "";
    }

    const articleQty = getArticleQuantityInCart(product);
    return (
    <div className="col-md-4 col-sm-6 col-xl-3 m-0 mb-3" key={"_col_" + key}>
      <div className="product-box mb-3">
          <div className="d-flex mt-2">
            {["MANUAL", "DYNAMIC"].includes(product.articlePackageType) && <div className="product-ribbon badge bg-soft-info d-flex align-items-center mx-2">{props.t("Package")}</div>}
          </div>
          <div className="product-img pt-4 px-4"
            onClick={() =>
              history.push(`/customer/articles/${product.articleNumber}`)}
          >
            <img
              src={
                product.articleImages != null &&
                product.articleImages.length > 0
                  ? BASE_IMAGE_URL +
                    "/" +
                    product.articleImages[0].media.s3Key
                  : articlePlaceholderImg
              }
              alt=""
              className="img-fluid mx-auto d-block"
            />
          </div>

          <div className="product-content p-4">
            <Tooltip
              title={
                product.displayName
                  ? product.displayName
                  : product.description
              }
            >
              <h5 className="mb-1 article-title">
                <Link
                  to={`/customer/articles/${product.articleNumber}`}
                  className="text-dark"
                >
                  {getTitle(
                    product.displayName
                      ? product.displayName
                      : product.description
                  )}{" "}
                </Link>
              </h5>
            </Tooltip>
            <p className="text-muted article-title font-size-13">
              {product.shortDescription?.length > 100
                ? product.shortDescription.substr(0, 100) +
                  ".."
                : product.shortDescription}
            </p>

            <h5 className="mt-3 mb-2">
              <span className="text-muted me-2 fs-5 fw-bold">
                <span>
                  {product.specialPrice
                    ? product.specialPrice
                    : product.salesPrice}{" "}
                  kr
                </span>
              </span>
            </h5>
            {product.articlePackageType == "MANUAL" && 
            <div className="col-sm-8 mx-auto d-grid">
              <select
                className="form-control"
                // value={articleQty ? articleQty : 0}
                onChange={(e) => CartService.addPackageToCart(product, e.target.value ? e.target.value : 0)}
              >
                <option value={0}>{props.t("Add to cart")}</option>
                {product?.articlePackage?.map(pkg => <option value={pkg.quantity}>{pkg.name}</option>)}
              </select>
            </div>}
            {(articleQty < 1 && product.articlePackageType != "MANUAL") && 
            <div className="col-sm-8 mx-auto d-grid">
              <Button
                type="button"
                color="primary"
                className="btn-block waves-effect waves-light me-1"
                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                onClick={(e) => {
                    e.preventDefault();
                    CartService.addItemToCart(product);
                }}
              >
                <i className="uil uil-shopping-cart-alt me-2"></i> {props.t("Add to cart")}
              </Button>
            </div>}
            {(articleQty > 0 && product.articlePackageType != "MANUAL") && 
            <div className="col-sm-6 mx-auto d-grid">
              <div className="btn-block waves-effect waves-light me-1 d-flex">
                <Button
                  type="button"
                  color="primary"
                  className="btn-block waves-effect waves-light"
                  style={{borderTopRightRadius: "0", borderBottomRightRadius: "0", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                  onClick={(e)=> handleQuantityChange(product, true)}
                  disabled={cartItemsLoading}
                >
                  <i className="uil uil-minus"></i>
                </Button>
                <input
                  className="form-control"
                  style={{borderRadius: "0"}}
                  type="number"
                  defaultValue={getQuantityVaue(product)}
                  onChange={(e) => processInputQuantityChange({quantity: parseInt(e.target.value), product})}
                  onWheel={preventNumberInputScroll}
                  disabled={cartItemsLoading}
                  key={cartItemsLoading}
                  // onKeyDown={validateNumber}
                />
                <Button
                  type="button"
                  color="primary"
                  className="btn-block waves-effect waves-light"
                  style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                  onClick={(e) => handleQuantityChange(product, false)}
                  disabled={cartItemsLoading}
                >
                  <i className="uil uil-plus"></i>
                </Button>
              </div>
            </div>}
            {isStockable(product) && <div className="col-12 mt-1">
                {getQuantityRowValue(product)}
            </div>}
          </div>
      </div>
    </div>
    )
  }

    const getQuantityRowValue = (item) => {
        if(item.stockable === false){
            return "";
        }

        if(articleConfig && item?.disposableQuantity){
            if(articleConfig.articleQuantityConfig === "COLOR_CODING"){
                return item.disposableQuantity > 0 ? 
                <div className="product-ribbon badge bg-soft-success bg-success float-end mt-3">
                    {" "}
                    {props.t("In Stock")}{" "}
                </div> : 
                <div className="product-ribbon badge bg-soft-danger bg-danger float-end mt-3">
                    {" "}
                    {props.t("Out of Stock")}{" "}
                </div>
            } else {
                return (
                <label className="text-muted font-size-13 float-end mx-1">
                    
                    <p className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}><i className="uil-shopping-cart-alt"></i> &nbsp;{item.disposableQuantity}</p>
                </label>)
            }
        } else {
            return "0";
        }
    }

  const processChange = debounce((arg) => {
    setSearch(arg?.target?.value);
    setProductList([]);
    loadProducts(0, isGrid.value ? sizePerPage : -1, arg?.target?.value);
  });
    
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageToolbarTop
            noBackBtn
            title={params.categoryId ? selectCategory?.name : props.t("Articles")}
            breadcrumbs={getBreadcrumbs()}
            toggle={{
              active: isGrid?.value,
              onClick: onToggleChange,
              label: (
                <label
                  className="form-check-label"
                  htmlFor="customSwitchsizelg"
                >
                  {isGrid?.label}
                </label>
              ),
            }}
          />

          <Loader loading={(articlesByCategoryPending || articlesPending) && page == 1 && isFavoriteLoading === false} title="Articles" />
          <Row>
            <Col className="col-12 article-table">
              <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                <TableToolbar
                  title={params.categoryId ? selectCategory?.name : props.t("Articles")}
                  description={params && params.categoryId ? selectCategory?.description : null}
                />
              </CardHeader>
            </Col>
          </Row>
          {error &&
              <Alert className={"mt-4"} color="danger" role="alert">
                  {error}
              </Alert>
          }


          {!isGrid.value && (
            <Row>
              <Col className="col-12 article-table">
                <Card>
                  {!articlesByCategoryPending && !articlesPending && <></>
                  }

                  <CardBody>
                    {error &&
                        <Alert className={"mt-4"} color="danger" role="alert">
                          {error}
                        </Alert>
                    }

                    <ArticleListTable key={params?.groupId + "-" + params?.categoryId} customFilter={{articleGroup : {id : params?.groupId}, category : {id: params?.categoryId}}} columns={[
                      {
                        label: props.t("Article Number"),
                        field: "articleNumber",
                        width: 100,
                      },
                      {
                        label: props.t("Name"),
                        field: "description",
                        width: 300,
                      },
                      {
                        label: props.t("Description"),
                        field: "shortDescription",
                        width: 300,
                      },
                      {
                        label: props.t("Category"),
                        field: "category",
                        width: 200,
                      },
                      {
                        label: props.t("Quantity"),
                        field: "disposableQuantity",
                        width: 100,
                      },
                      {
                        label: props.t("Price"),
                        field: "salesPrice",
                        width: 100
                      },
                    ]}/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {!((articlesByCategoryPending || articlesPending) && page == 1 && isFavoriteLoading === false) &&
            isGrid.value && (<>
                <Row>
                <Col className="col-12 article-table mt-4 mb-2 ms-1">

                  <div className="form-inline searchWidth">
                    <div className="search-box ml-0">
                      <div className="position-relative ">
                        <Form
                            className="app-search ml-0   d-lg-block "
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                        >
                          <Input
                              type="text"
                              key={1}
                              className={`form-control bg-light border-light rounded toolbar-search ${!isGrid.value ? 'ms-0' : 'ms-2'}`}
                              placeholder={props.t("Search") + "..."}
                              onChange={processChange}
                              onKeyUp={(e) => handleClearSearch(e)}
                          />
                          <i className="mdi mdi-magnify search-icon"></i>
                        </Form>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="12" xl="12">
                  {productList && productList.length === 0 && (
                    <Row className="min-height-400">
                      <h6
                        align="center"
                        className="margin-top-10 font-color-grey"
                      >
                        {props.t("No articles for this category")}
                      </h6>
                    </Row>
                  )}
                  {!isEmpty(productList) && (
                    <Row className="min-height-400">
                      {!isEmpty(productList) && 
                      <InfiniteScrollbarWithLoader 
                          items={productList}
                          loading={articlesPending}
                          totalItems={totalItems}
                          renderCallback={getProductView}
                          page={page}
                          setPage={setPage}
                          type={props.t("Articles")} 
                          />}
                    </Row>
                  )}
                </Col>
              </Row>
            </>)}
        </Container>
      </div>
    </React.Fragment>
  );
};

ShopArticles.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
  articlesByCategory: PropTypes.object,
  articlesByGroup: PropTypes.object,
  onGetProductsByCategory: PropTypes.func,
  onGetProductsByGroup: PropTypes.func,
  onGetSubCategoryList: PropTypes.func,
  articlesPending: PropTypes.bool,
  error: PropTypes.object,
  articlesByCategoryPending: PropTypes.bool,
  articlesByCategoryError: PropTypes.object,
  cartItems: PropTypes.array

};

const mapStateToProps = (state) => {
  return {
    products: state.ecommerce.products,
    articlesByCategory: state.ecommerce.articlesByCategory,
    articlesByGroup: state.ecommerce.articlesByGroup,
    category: state.ecommerce.subCategoryList,
    articlesPending: state.ecommerce.getArticlesPending,
    error: state.ecommerce.getArticlesError,
    articlesByCategoryPending: state.ecommerce.articlesByCategoryPending,
    articlesByCategoryError: state.ecommerce.articlesByCategoryError,
    cartItems: state.Cart?.cartItems,
    articleConfig: state.Config.articleConfig,
    cartItemsLoading: state.Cart?.cartItemsLoading,
    cartItemError: state.Cart?.cartItemError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetProducts: (page, pageSize, search) =>
    dispatch(getProducts(page, pageSize, true, search === "null" ? '': search)),
  onGetProductsByCategory: (id, page, pageSize, search) =>
    dispatch(getProductByCategory(id, page, pageSize, search === "null" ? '': search)),
  onGetProductsByGroup:(id, page, pageSize, search)  => dispatch(getProductByGroup(id, page, pageSize, search === "null" ? '': search)),
  onGetSubCategoryList: (id) => dispatch(getSubCategoryList(id)),
  onGetProductsReset: () => dispatch(getProductsReset()),
  onGetProductByCategoryReset: () => dispatch(getProductByCategoryReset()),
  onGetArticleConfig: () => dispatch(getArticleConfig()),
  onResetCartMessage: () => dispatch(resetCartMessage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ShopArticles)));
