export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"
export const GET_ALL_USERS_RESET = "GET_ALL_USERS_RESET"

export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"


export const userActionTypes = {
    GET_ALL_USERS,
}
