import React from "react"
import {Col, Container, Row} from "reactstrap"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={9}>{new Date().getFullYear()} © <a href="http://flowsense.se/" rel="noreferrer" target="_blank">{props.t("Flowsense")}.</a></Col>
            <Col md={3}>
              <div className="text-sm-right d-none d-sm-block">
                <a href="http://flowsense.se/" target="_blank" rel="noreferrer">{props.t("Design & Develop by Flowsense")}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

Footer.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Footer))
