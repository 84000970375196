import {call, put, takeEvery} from "redux-saga/effects"

// Crypto Redux States
import {GET_INVOICE_DETAIL, GET_INVOICE_METRICS, GET_INVOICES} from "./actionTypes"
import {getInvoiceDetailFail, getInvoiceDetailSuccess, getInvoiceMetricsFail, getInvoiceMetricsSuccess, getInvoicesFail, getInvoicesSuccess,} from "./actions"

//Include Both Helper File with needed methods
import {getInvoiceDetail, getInvoiceMetricsAPI, getInvoices} from "../../helpers/fakebackend_helper"
import {startAction, stopAction} from "../actions"
import {API_ERR_MSG} from "../../util/constants"

function* fetchInvoices({type, invoiceType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference}) {
    try {
        yield put(startAction(type))
        const response = yield call(getInvoices, invoiceType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference);
        yield put(getInvoicesSuccess(response))
    } catch (error) {
        yield put(getInvoicesFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchInvoiceDetail({invoiceId}) {
    try {
        /*yield put(getInvoiceDetailSuccess({}))
        yield put(getInvoiceDetailFail(null));*/
        const response = yield call(getInvoiceDetail, {invoiceId})
        yield put(getInvoiceDetailSuccess(response?.data))
    } catch (error) {
        yield put(getInvoiceDetailFail(API_ERR_MSG))
    }
}

function* fetchInvoiceMetrics({startDate, endDate}){
    try{
        const response = yield call(getInvoiceMetricsAPI, startDate.substring(0, 10), endDate.substring(0, 10));
        yield put(getInvoiceMetricsSuccess(response))
    }catch (error){
        yield put(getInvoiceMetricsFail(error));
    }
}

function* invoiceSaga() {
    yield takeEvery(GET_INVOICES, fetchInvoices)
    yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail)
    yield takeEvery(GET_INVOICE_METRICS, fetchInvoiceMetrics)
}

export default invoiceSaga
