export const GET_OFFERS = "GET_OFFERS"
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS"
export const GET_OFFERS_FAIL = "GET_OFFERS_FAIL"
export const GET_OFFERS_RESET = "GET_OFFERS_RESET"

export const GET_OFFERS_DETAILS = "GET_OFFERS_DETAILS"
export const GET_OFFERS_DETAILS_SUCCESS = "GET_OFFERS_SUCCESS_DETAILS"
export const GET_OFFERS_DETAILS_FAIL = "GET_OFFERS_DETAILS_FAIL"
export const GET_OFFERS_DETAILS_RESET = "GET_OFFERS_DETAILS_RESET"

export const ADD_OFFER = "ADD_OFFER"
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"
export const ADD_OFFER_FAIL = "ADD_OFFER_FAIL"
export const ADD_OFFER_RESET = "ADD_OFFER_RESET"

export const offersActionTypes = {
    GET_OFFERS,
    ADD_OFFER, ADD_OFFER_SUCCESS, ADD_OFFER_FAIL,
}

export const GET_OFFER_METRICS = "GET_OFFER_METRICS"
export const GET_OFFER_METRICS_SUCCESS = "GET_OFFER_METRICS_SUCCESS"
export const GET_OFFER_METRICS_ERROR = "GET_OFFER_METRICS_ERROR"
