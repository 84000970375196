import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {get, post} from "../../helpers/api_helper";
import PropTypes from "prop-types";
import {getTenantConfig, getTenantConfigReset} from "../../store/config/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {checkIfLoading} from "../../store/selector";
import {configActionTypes} from "../../store/config/actionTypes";
import {API_ERR_MSG} from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";

const FortnoxTenantConfig = (props) => {
    const {header} = props.location.state;

    const {onGetTenantConfig, tenantConfig, loading, onGetTenantConfigReset} = props

    const [formFields, setFormFields] = useState({
        clientId: "",
        clientSecret: "",
        accessToken: ""
    })
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const [brandSettings, setBrandSettings] = useState(null);
    const [refreshResponse, setRefreshResponse] = useState(null);
    const [socketStatus, setSocketStatus] = useState(null);
    const [refreshTokenStatus, setRefreshTokenStatus] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const clientId = user?.config?.clientId;


    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetTenantConfig();
        checkSocketStatus();
        checkRefreshTokenStatus();
        return () => {
            onGetTenantConfigReset();
        }
    }, [])

    useEffect(() => {
        setFormFields({...tenantConfig})
    }, [tenantConfig])

    useEffect(() => {
        if (props.error) {
            setError(API_ERR_MSG)
        }
    }, [props.error])

    useEffect(() => {
        if (props.error) {
            setError(props.error)
            setTimeout(() => {
                onGetTenantConfigReset()
            }, 5000);
            setTimeout(() => {
                setError(null)
            }, 5000);
        }
    }, [props.error])

    const submitForm = event => {
        let fields = Array.from(event.target.getElementsByTagName('input'))
        post('/api/settings/fortnoxConfig', fields.reduce((obj, cur) => ({...obj, [cur.id]: cur.value}), {}))
            .then(data => {
                if (data?.status !== 200) {
                    setError(API_ERR_MSG)
                } else {
                    setSuccess(props.t("Fortnox configuration updated successfully."))
                    setTimeout(clearSuccess, 5000)
                }
            })
    }

    const handleRefreshToken = async () => {
        get('/api/fortnox/refresh').then(data => {
            if (data.status === 200) {
                setRefreshResponse(data?.data)
                onGetTenantConfig();
                checkSocketStatus();
                checkRefreshTokenStatus();
            }
        }).catch(error => {
            setError(props.t("Error generating access token. Reconnect to fortnox to resolve issue."))
        });
    }

    const checkSocketStatus = () => {
        setSocketStatus('loading');
        setTimeout(() =>{
            get("/api/fortnox/socket/status").then(data => {
                if(data.status === 200 && (data?.data === true || data?.data === false)){
                    setSocketStatus(data?.data);
                } else {
                    setSocketStatus(null);
                }
            }).catch(data => {
                setSocketStatus(null)
            })
        }, 3000)
    }

    const checkRefreshTokenStatus = () => {
        get("/api/fortnox/status").then(data => {
            if(data.status === 200){
                setRefreshTokenStatus(true);
            } else {
                setRefreshTokenStatus(false);
            }
        }).catch(error => {
            setRefreshTokenStatus(false);
        })
    }

    const handleAuth20 = () => {
        let baseUrl = window.location.origin;
        let activationURL = `${baseUrl}/settings/fortnox-config/activation`;
        let state = (Math.random() + 1).toString(36).substring(2);
        localStorage.setItem("state", state);
        localStorage.setItem("redirect_uri", activationURL)
        let scope = "article%20companyinformation%20invoice%20costcenter%20currency%20customer%20settings%20supplier%20payment%20bookkeeping%20project%20price%20order%20offer"
        window.location.href = `https://apps.fortnox.se/oauth-v1/auth?client_id=${clientId}&redirect_uri=${activationURL}&scope=${scope}&state=${state}&access_type=offline&response_type=code`;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={loading} title="Fortnox Config"/>
                                {!loading &&
                                    <CardBody>
                                        {error ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}

                                        {success ? (
                                            <Alert color="success">{success}</Alert>
                                        ) : null}

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Refresh Token")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Authenticate with Auth2.0")}
                                                    defaultValue={formFields.refreshToken}
                                                    onChange={clearError}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>

                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Access Token Expire Time")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Authenticate with Auth2.0")}
                                                    defaultValue={formFields.expiresAt ? new Date(formFields.expiresAt + 'Z') : ''}
                                                    onChange={clearError}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Auth2.0 Connection")}
                                            </label>
                                            <div className="col-md-10" style={{paddingTop: "10px"}}>
                                                {refreshTokenStatus === true && <><i className="fa fa-check-circle" style={{color: "green", marginRight: "5px"}}/> {props.t("Connected")} </>}
                                                {refreshTokenStatus === false && <><i className="fa fa-times-circle" style={{color: "red", marginRight: "5px"}}/> {props.t("Error")} </>}
                                                {refreshTokenStatus === null && <><i className="fa fa-exclamation-circle" style={{color: "orange", marginRight: "5px"}}/> {props.t("Not Connected")} </>}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Socket Connection")}
                                            </label>
                                            <div className="col-md-10" style={{paddingTop: "10px"}}>
                                                {socketStatus === 'loading' && <>{props.t("Please wait, checking")}..</>}
                                                {socketStatus === true && <><i className="fa fa-check-circle" style={{color: "green", marginRight: "5px"}}/> {props.t("Connected")} </>}
                                                {socketStatus === false && <><i className="fa fa-times-circle" style={{color: "red", marginRight: "5px"}}/> {props.t("Error")} </>}
                                                {socketStatus === null && <><i className="fa fa-exclamation-circle" style={{color: "orange", marginRight: "5px"}}/> {props.t("Not Connected")} </>}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Scope")}
                                            </label>
                                            <div className="col-md-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={props.t("Scope")}
                                                    defaultValue={formFields.scope}
                                                    onChange={clearError}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col align="center">
                                                {formFields?.accessToken && <Button
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light m-lg-1"
                                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={handleRefreshToken}>
                                                    {props.t("Generate new access token")}
                                                </Button>}
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light m-lg-1"
                                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={handleAuth20}
                                                >
                                                    {!formFields?.accessToken ? props.t("Connect to Fortnox") : props.t("Refresh connection to Fortnox")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <pre style={{whiteSpace: "pre-wrap"}}>
                            {refreshResponse && JSON.stringify(refreshResponse, null, '\t')}
                            </pre>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

FortnoxTenantConfig.propTypes = {
    tenantConfig: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        tenantConfig: state.Config.tenantConfig,
        loading: checkIfLoading(state, configActionTypes.GET_TENANT_CONFIG),
        error: state.Config.tenantConfigError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetTenantConfig: () => dispatch(getTenantConfig()),
    onGetTenantConfigReset: () => dispatch(getTenantConfigReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(FortnoxTenantConfig));
