import React from "react";
import {Col, Container, Modal, Row,} from "reactstrap";
import {withTranslation} from "react-i18next";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { useParams } from "react-router-dom";
import CustomerUserForm from "./CustomerUserForm";

const CustomerUserFormContainer = (props) => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    const { userId } = useParams();

    const CustomerUserPage = (props) =>
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: loggedInUser?.customerId ? '/customer/users' : '/users'}]}
                        title={userId ? props.t("Edit User") : props.t("Create User")}
                    />
                    <Row>
                        <Col>
                            <CustomerUserForm location={props.location} customerId={props.customerId} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    const CustomerUserModal = ({modal_standard, tog_standard, useModal, customerId}) => 
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Create User")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <CustomerUserForm
                    // projectId={params.id}
                    location={props.location}
                    clearField={true}
                    useModal={useModal}
                    tog_standard={tog_standard}
                    customerId={customerId}
                />
            </div>
        </Modal>

    return props.useModal ? <CustomerUserModal {...props} /> : <CustomerUserPage {...props} />;
};

export default withTranslation()(CustomerUserFormContainer);
