import {call, put, takeEvery} from "redux-saga/effects"
import {GET_MANUFACTURER, GET_MANUFACTURER_DETAILS} from "./actionTypes";
import {
    getManufacturerDetailsError,
    getManufacturerDetailsSuccess,
    getManufacturerFail,
    getManufacturerSuccess
} from "./actions";
import {getManufacturer, getManufacturerDetails} from "../../helpers/fakebackend_helper";


function* fetchManufacturerList() {
    try {
        const response = yield call(getManufacturer);
        yield put(getManufacturerSuccess(response))
    } catch (e) {
        yield put(getManufacturerFail(e))
    }
}

function* fetchManufacturerDetails({id}) {
    try {
        const response = yield call(getManufacturerDetails, id);
        yield put(getManufacturerDetailsSuccess(response))
    } catch (e) {
        yield put(getManufacturerDetailsError(e))
    }
}

function* manufacturerSaga() {
    yield takeEvery(GET_MANUFACTURER, fetchManufacturerList)
    yield takeEvery(GET_MANUFACTURER_DETAILS, fetchManufacturerDetails)
}

export default manufacturerSaga;
