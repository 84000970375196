import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames";
import {useHistory} from "react-router-dom";

import {withTranslation} from "react-i18next";
import CustomerDetails from "./CustomerDetails";
import CustomerUserManagement from "./CustomerUserManagement";
import {GetCustomer, getCustomerRest} from "../../store/customer/actions";
import CustomerAddressManagement from "./CustomerAddressManagement";
import CustomerProjectManagement from "../Projects/CustomerProjectManagement";
import CustomerOrderTable from "./CustomerOrderTable";
import CustomerInvoiceTable from "./CustomerInvoiceTable";
import CustomerOfferTable from "./CustomerOfferTable";
import {
    Box,
    FormControl,
    MenuItem,
    Select,
    makeStyles,
} from "@material-ui/core";
import PageToolbarTop from "../../components/Common/PageToolbarTop";

import {
    getUserForImpersonation,
} from "../../store/users/impersonation/actions";
import Loader from "../../components/Common/Loader";

const useStyles = makeStyles({
    select: {
        "&:before": {
            borderColor: "black",
        },
        "&:after": {
            borderColor: "black",
        },
    },
    icon: {
        fill: "black",
    },
});
const CustomerEdit = (props) => {
    const classes = useStyles();
    const {
        customer,
        customerLoading,
        users,
        onGetCustomerDetails,
        onRestCustomer,
        onGetUserForImpersonation,
        match: {params},
    } = props;
    const [activeTabJustify, setactiveTabJustify] = useState("5");
    const history = useHistory();
    const [customerDetails, setCustomerDetails] = useState();
    const [id, setId] = useState(params.id);
    const [option, setOption] = useState(1);
    const [brandSettings, setBrandSettings] = useState(null)

    const handleChange = (event) => {
        setOption(event.target.value);
        switch (event.target.value) {
            case 1:
                toggleCustomJustified("5");
                break;
            case 2:
                toggleCustomJustified("6");
                break;
            case 3:
                toggleCustomJustified("7");
                break;
            case 4:
                toggleCustomJustified("8");
                break;
            case 5:
                toggleCustomJustified("9");
                break;
            case 6:
                toggleCustomJustified("10");
                break;
            case 7:
                toggleCustomJustified("11");
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
        return () => {
            onRestCustomer();
            setCustomerDetails({});
        };
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"))
        if (params && params.id) {
            onGetCustomerDetails(params.id);
            onGetUserForImpersonation(user.tenantId, null, [
                "ROLE_TENANT_ADMIN",
                "ROLE_TENANT_USER",
            ]);
        }
    }, [params.id]);

    useEffect(() => {
        if (customer) {
            setCustomerDetails(customer);
        }
    }, [customer]);

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
            setactiveTabJustify(tab);
        }
    }

    const handleBack = () => {
        history.push("/customers");
    };

    return (
        <React.Fragment>
            <div className="page-content" key={"customer-" + params.id}>
                <Container fluid={true}>

                    <PageToolbarTop
                        noBackBtn={true}
                        title={customerDetails?.name}
                        breadcrumbs={[
                            {title: props.t("Customers"), link: "/customers"},
                        ]}
                        backBtn={handleBack}
                    />

                    <Loader loading={customerLoading} title="Customer Details" />

                    {!customerLoading && (
                        <Row>
                            <Col lg="12 mt-3">
                                <Card>
                                    <CardBody>
                                        <div className="d-block d-md-none ">
                                            <Box sx={{minWidth: 120}}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="optionid"
                                                        id="optionidid"
                                                        value={option}
                                                        onChange={handleChange}
                                                        className={classes.select}
                                                    >
                                                        <MenuItem value={1}>
                                                            {props.t("Customer Details")}
                                                        </MenuItem>
                                                        <MenuItem value={2}>{props.t("Users")}</MenuItem>
                                                        <MenuItem value={3}>{props.t("Orders")}</MenuItem>
                                                        <MenuItem value={4}>{props.t("Invoices")}</MenuItem>
                                                        <MenuItem value={5}>{props.t("Offers")}</MenuItem>
                                                        <MenuItem value={6}>
                                                            {props.t("Delivery Addresses")}
                                                        </MenuItem>
                                                        <MenuItem value={7}>{props.t("Projects")}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <div className="d-none  d-md-block">
                                            <Nav pills className="nav-justified bg-light">
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "5",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("5");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Customer Details")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "6",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("6");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Users")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "7",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("7");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Orders")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "8",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("8");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Invoices")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "9",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("9");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Offers")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "10",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("10");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Delivery Addresses")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{cursor: "pointer"}}
                                                        className={classnames({
                                                            active: activeTabJustify === "11",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustomJustified("11");
                                                        }}
                                                    >
                            <span className="d-block d-sm-none">
                              <i className="far fa-user"></i>
                            </span>
                                                        <span className="d-none d-sm-block">
                              {props.t("Projects")}
                            </span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>

                                        <TabContent activeTab={activeTabJustify}>
                                            <TabPane tabId="5" className="p-3">
                                                <CustomerDetails customer={customerDetails} users={users}/>
                                            </TabPane>
                                            <TabPane tabId="6" className="p-3">
                                                <CustomerUserManagement
                                                    customerId={id}
                                                    isTenant={true}
                                                />
                                            </TabPane>
                                            <TabPane tabId="7" className="p-3 article-table">
                                                <CustomerOrderTable customerId={id}/>
                                            </TabPane>
                                            <TabPane tabId="8" className="p-3 article-table">
                                                <CustomerInvoiceTable customerId={id}/>
                                            </TabPane>
                                            <TabPane tabId="9" className="p-3 article-table">
                                                <CustomerOfferTable customerId={id}/>
                                            </TabPane>
                                            <TabPane tabId="10" className="p-3 article-table">
                                                <CustomerAddressManagement customerId={id}/>
                                            </TabPane>
                                            <TabPane tabId="11" className="p-3 article-table">
                                                <CustomerProjectManagement customerId={id}/>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

CustomerEdit.prototype = {
    customer: PropTypes.object,
    onGetCustomerDetails: PropTypes.func,
    onRestCustomer: PropTypes.func,
    addresses: PropTypes.array,
    onGetAddresses: PropTypes.func,
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        customer: state.Customer.customer,
        customerLoading: state.Customer.customerLoading,
        users: state.UserImpersonation.users,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetCustomerDetails: (id) => dispatch(GetCustomer(id)),
    onRestCustomer: () => dispatch(getCustomerRest()),
    onGetUserForImpersonation: (tenantId, customerId, roles) =>
        dispatch(getUserForImpersonation(tenantId, customerId, roles)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(CustomerEdit));
