import PropTypes from "prop-types";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getOffers, getOffersDetails, getOffersDetailsReset} from "../../store/offer/actions";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row, Table} from "reactstrap";
import _ from "lodash";
import queryString from "query-string";
import TableToolbar from "../../components/Common/TableToolbar";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import Loader from "../../components/Common/Loader";
import {downloadPDF} from "../../util/helperFunctions";
import {del, get} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import {API_ERR_MSG} from "../../util/constants";
import {formatCurrency} from "../../helpers/currency_helper";

const OfferDetails = (props) => {
    const {offerDetails, onGetOfferDetails, offerDetailsError, offerDetailsLoading, onGetOfferDetailsReset, onGetOffers, offers, match: {params}} = props;
    const history = useHistory();
    const [brandSettings, setBrandSettings] = useState(null);
    const [showDiscountColumn, setShowDiscountColumn] = useState(false);
    const [offerCancelled, setOfferCancelled] = useState(false);
    const [showDeleteOfferModal, setShowDeleteOfferModal] = useState(false);
    const [error, setError] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [customer, setCustomer] = useState(null);
    const [wayOfDelivery, setWayOfDelivery] = useState(null);
    const [yourReference, setYourReference] = useState(null);
    const [ourReference, setOurReference] = useState(null);
    const [termsOfDelivery, setTermsOfDelivery] = useState(null);
    const [termsOfPayment, setTermsOfPayment] = useState(null);

    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";

    useEffect(() => {
      setBrandSettings(user?.brandConfig);
      onGetOffers('all');
    }, [])

    useEffect(() => {
        onGetOfferDetails(params.id);

        return () => {
            onGetOfferDetailsReset();
        }
    }, [params.id])

    useEffect(() => {
      if(offerDetailsError){
        setError(props.t(API_ERR_MSG));
        setTimeout(() => {onGetOfferDetailsReset()}, 5000);
      }
    }, [offerDetailsError])

    useEffect(() => {
        if(offerDetails){
            if(offerDetails.WayOfDelivery){
                getWayOfDeliveryDescription(offerDetails.WayOfDelivery);
            }
            if(offerDetails.TermsOfDelivery){
                getTermsOfDeliveryDescription(offerDetails.TermsOfDelivery);
            }
            if(offerDetails.TermsOfPayment){
                getTermsOfPaymentDescription(offerDetails.TermsOfPayment);
            }
            setUserReferenceDetails(offerDetails?.YourReference, true);
            setUserReferenceDetails(offerDetails?.OurReference, false);
            setCustomerDetails(offerDetails?.CustomerNumber);
            setShowDiscountColumn(offerDetails?.OfferRows?.filter(offer => offer.Discount > 0).length > 0)
            setOfferCancelled(offerDetails?.Cancelled);
        }
    }, [offerDetails]);

    const handleBack = () => {
        history.goBack()
    }

    const handleCheckout = async () => {
        if(!user.customerId){
            history.push(`/offers/${params.id}/order/new`)
        } else {
            history.push(`/customer/offers/checkout/${params.id}`);
        }
    }

    const getLink = () => {
        let queryParams = queryString.parse(props.location.search);
        let link = "/offers";
        if(queryParams && queryParams.from){
            link = queryParams.from
        } else if(user?.customerId){
            link = '/customer/offers';
        }
        return link;
    }

    const setCustomerDetails = async (customerNumber) => {
        if(!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")){
            const response = await get(`/api/customers/byCustomerNumber/${customerNumber}`);
            if(response?.status === 200 && response.data?.id) {
                setCustomer(response.data)
            }
        }
    }

    const getWayOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/wayofdeliveries/${code}`);
        if(response?.status === 200) {
            setWayOfDelivery(response.data.WayOfDelivery.Description)
        }
    }

    const getTermsOfDeliveryDescription = async (code) => {
        const response = await get(`/api/orders/termsofdeliveries/${code}`);
        if(response?.status === 200) {
            setTermsOfDelivery(response.data.TermsOfDelivery.Description)
        }
    }

    const getTermsOfPaymentDescription = async (code) => {
        const response = await get(`/api/orders/termsofpayments/${code}`);
        if(response?.status === 200) {
            setTermsOfPayment(response.data.TermsOfPayment.Description)
        }
    }

    const setUserReferenceDetails = async (name, isCustomerUser) => {
        if(name && ((isCustomerUser && user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) || !user?.customerId)){
            const response = await get(`/api/user/byName/${name}?isCustomerUser=${isCustomerUser}`);
            if(response?.status === 200 && response.data?.id) {
                if(isCustomerUser){
                    setYourReference(response.data)
                } else {
                    setOurReference(response.data)
                }
            }
        }
    }

    const getTableToolbarButtons = () => {
        const downloadPdfButton = {
            text: props.t("Download"),
            onClick: () => downloadPDF(params.id, 'offer'),
        }
        return (!(offerCancelled || (offerDetails?.OrderReference && offerDetails?.OrderReference > "0"))
        && fortnoxConnectionStatus === true
        && ((user?.roles?.includes("ROLE_CUSTOMER_ADMIN") 
                || user?.roles?.includes("ROLE_CUSTOMER_USER") 
                || user?.roles?.includes("ROLE_TENANT_ADMIN") 
                || user?.roles?.includes("ROLE_TENANT_USER")))) ? [downloadPdfButton, {text: props.t("Create Order"), onClick: handleCheckout}] 
            : [downloadPdfButton]
    }

    function toggleDeleteOfferModal() {
      setShowDeleteOfferModal(!showDeleteOfferModal);
    }

    const handleDeleteOffer = async () => {
      let response = await del(`/api/offer/${params.id}`);
      if(response.status === 200) {
          setOfferCancelled(response.data?.Offer.Cancelled);
      }
    };

    const handleEditOffer = () => history.push(`/offers/${params.id}/edit`);

    const handleDuplicateOffer = () => history.push(`/offers/${params.id}/edit?copy=true`)
  

    const disableEditDeleteOffer = () => {
      if(fortnoxConnectionStatus !== true){
          return true;
      }
      return user?.customerId 
        || offerCancelled 
        || (offerDetails?.OrderReference && offerDetails?.OrderReference > "0") 
        || new Date(offerDetails?.ExpireDate) < new Date()
    }

    const redirectToOrder = () => {
        history.push((user?.customerId ? "/customer/orders" : "/orders") + `/${offerDetails?.OrderReference}`)
    }

    const redirectToInvoice = () => {
        if(!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")){
            history.push((user?.customerId ? "/customer/invoice" : "/invoice") + `/${offerDetails?.InvoiceReference}`)
        }
    }

    const OfferLabels = !_.isEmpty(offerDetails) &&
        <div className="d-flex justify-content-start">
            <div className="d-flex flex-column">
                <div className="d-flex">
                    {offerDetails.WarehouseReady && <div className="badge bg-success bg-light-success p-2">{props.t("Sent")}</div>}
                    {offerDetails.status === 'Ongoing' && <div className="badge bg-info bg-light-info p-2">{props.t(offerDetails.status)}</div>}
                    {offerDetails.status === 'Expired' && <div className="badge bg-warning bg-light-warning p-2">{props.t(offerDetails.status)}</div>}
                    {offerCancelled && <div className="badge bg-danger bg-light-danger p-2">{props.t("Cancelled")}</div>}
                    {offerDetails.OrderReference > 0 && 
                    <div onClick={() => redirectToOrder()}>
                        <div className={`badge bg-primary bg-light-primary ms-2 p-2 cursor-pointer`}>
                            {props.t("Order")}{" - " + offerDetails?.OrderReference}
                        </div>
                    </div>
                    }
                    {offerDetails.InvoiceReference > 0 && 
                        <div onClick={() => redirectToInvoice()}>
                            <div className={`badge bg-warning bg-light-warning ms-2 p-2 ${!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? "cursor-pointer" : ""}`}>
                                {props.t("Invoice")}{!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN") ? " - " + offerDetails?.InvoiceReference : ""}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    const handleOfferChange = (offer) => {
        window.location.href = `/offers/${offer?.DocumentNumber}`;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={props.t("Offer Details")}
                        breadcrumbs={[{ title: props.t("Offers"), link: getLink() }]}
                        backBtn={handleBack}
                    />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={props.t("Offer Details")}
                                            buttons={getTableToolbarButtons()}
                                            labels={OfferLabels}
                                            titleDropdown={{
                                                isSearchableDropdown: true,
                                                value: offerDetails,
                                                options: offers,
                                                placeholder: props.t("Select Offer"),
                                                isHeader: true,
                                                prefix: props.t("Offer") + " - ",
                                                handleSelect: (op) => {
                                                    handleOfferChange(op);
                                                },
                                                keyToDisplay: "DocumentNumber",
                                            }}
                                            canEdit={ disableEditDeleteOffer() ? null : handleEditOffer}
                                            canDelete={disableEditDeleteOffer() ? null : toggleDeleteOfferModal}
                                            canDuplicate={fortnoxConnectionStatus ? handleDuplicateOffer : null}
                                        />
                                    </CardHeader>
                                    <Loader loading={offerDetailsLoading} title="Offer Details" />
                                    {!offerDetailsLoading && 
                                    <CardBody>
                                        {error && <Alert className={"mt-4"} color="danger" role="alert">
                                            {error}
                                        </Alert>}           
                                        {!_.isEmpty(offerDetails) && <>
                                        <Row>
                                            <Col md="6">
                                            </Col>
                                            <Col md="6">
                                                <div className="d-flex justify-content-end">
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex">
                                                            <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Offer date")}</p>
                                                            <p className="p-0 m-0">{new Date(offerDetails?.OfferDate).toLocaleDateString()}</p>
                                                        </div> 
                                                        <div className="d-flex">
                                                            <p className="p-0 m-0" style={{width: "140px"}}>{props.t("Offer no")}</p>
                                                            <p className="p-0 m-0">{offerDetails?.DocumentNumber}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <p className="m-0 p-0">{props.t("Delivery Address")}</p>
                                                <p className="m-0 p-0">{offerDetails?.DeliveryName}</p>
                                                <p className="m-0 p-0">{offerDetails?.DeliveryAddress1}</p>
                                                <p className="m-0 p-0">{offerDetails?.DeliveryAddress2}</p>
                                                <p className="m-0 p-0">{offerDetails?.DeliveryZipCode + " " + offerDetails?.DeliveryCity}</p>
                                                <p className="m-0 p-0">{offerDetails?.DeliveryCountry}</p>
                                            </Col>
                                            <Col md={6}>
                                                <div 
                                                    className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                    style={{color: !user?.customerId && customer ? "#0404bd" : "black"}} 
                                                    onClick={() => {
                                                        if(customer && !user?.customerId){
                                                            history.push(`/customers/${offerDetails?.CustomerNumber}/profile`)
                                                        }
                                                    }}>
                                                        {offerDetails?.CustomerName}
                                                </div>
                                                <p className="m-0 p-0" style={{color: "black"}}>{offerDetails?.Address1}</p>
                                                <p className="m-0 p-0" style={{color: "black"}}>{offerDetails?.Address2}</p>
                                                <p className="m-0 p-0" style={{color: "black"}}>{offerDetails?.ZipCode + " " + offerDetails?.City}</p>
                                                <p className="m-0 p-0" style={{color: "black"}}>{offerDetails?.Country}</p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <div className="d-flex">
                                                    <div className="d-flex flex-column">    
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Customer number")}</p>
                                                                <div 
                                                                    className={`p-0 m-0 ${!user?.customerId && customer ? "cursor-pointer" : ""}`}
                                                                    style={{color: `${!user?.customerId && customer ? "#0404bd" : ""}`}} 
                                                                    onClick={() => {
                                                                        if(customer && !user?.customerId){
                                                                            history.push(`/customers/${offerDetails?.CustomerNumber}/profile`)
                                                                        }
                                                                    }}>
                                                                        {offerDetails?.CustomerNumber}
                                                                </div>
                                                            </div>
                                                        </div>    
                                                        {offerDetails?.YourReference && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your reference")}</p>
                                                                <div 
                                                                    className={`p-0 m-0 ${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "cursor-pointer" : ""}`}
                                                                    style={{color: `${yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN")) ? "#0404bd" : ""}`}} 
                                                                    onClick={() => {
                                                                        if(yourReference && customer && (!user?.customerId || user?.roles?.includes("ROLE_CUSTOMER_ADMIN"))){
                                                                            history.push(`/customers/${customer?.id}/users/${yourReference.id}`)
                                                                        }
                                                                    }}>
                                                                        {offerDetails?.YourReference}
                                                                </div>
                                                            </div>
                                                        </div>}  
                                                        {offerDetails.YourOrderNumber && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Your order number")}</p>
                                                                <p className="p-0 m-0">{offerDetails?.YourOrderNumber}</p>
                                                            </div>
                                                        </div>}    
                                                        {offerDetails.TermsOfDelivery && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery conditions")}</p>
                                                                <p className="p-0 m-0">{props.t(termsOfDelivery)}</p>
                                                            </div>
                                                        </div>}  
                                                        {offerDetails.WayOfDelivery && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "160px"}}>{props.t("Delivery Service")}</p>
                                                                <p className="p-0 m-0">{wayOfDelivery}</p>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="d-flex">
                                                    <div className="d-flex flex-column">    
                                                        {offerDetails?.OurReference && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Our reference")}</p>
                                                                <div 
                                                                    className={`p-0 m-0 ${user?.customerId || !ourReference ? "" : "cursor-pointer"}`}
                                                                    style={{color: `${user?.customerId || !ourReference ? "": "#0404bd"}`}} 
                                                                    onClick={() => {
                                                                        if(ourReference){
                                                                            history.push(`/settings/users/${ourReference.id}`)
                                                                        }
                                                                    }}>
                                                                        {offerDetails?.OurReference}
                                                                </div>
                                                            </div>
                                                        </div>   } 
                                                        {offerDetails?.TermsOfPayment && 
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Payment terms")}</p>
                                                                <p className="p-0 m-0">{props.t(termsOfPayment)}</p>
                                                            </div>
                                                        </div> }      
                                                        <div className="d-flex">
                                                            <div className="d-flex">
                                                                <p className="p-0 m-0" style={{width: "150px"}}>{props.t("Due date")}</p>
                                                                <p className="p-0 m-0">{new Date(offerDetails?.ExpireDate).toLocaleDateString()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="py-2 mt-4">
                                            <h5 className="font-size-15">{props.t("Offer summary")}</h5>
                                            <div className="table-responsive">
                                                <Table className="table-centered mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th style={{ width: "10%" }}>{props.t("Article Number")}</th>
                                                        <th style={{ width: "60%"}}>{props.t("Item")}</th>
                                                        <th style={{ width: "5%"}}>{props.t("Quantity")}</th>
                                                        <th style={{ width: "5%"}}>{props.t("Price")}</th>
                                                        {(showDiscountColumn || !user?.customerId) && <th style={{ width: "5%"}}>{props.t("Discount")}</th>}
                                                        <th className="text-end" style={{ width: "10%" }}>{props.t("Total")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {_.map(
                                                        offerDetails?.OfferRows,
                                                        (item, key) => (
                                                            <tr key={key}>
                                                                <td>{item.ArticleNumber}</td>
                                                                <td>
                                                                    <p className="font-size-15 mb-1">{item.Description}</p>
                                                                </td>
                                                                <td>{item.Quantity > 0 ? item.Quantity : ""}</td>
                                                                <td>{item.Price > 0 ? formatCurrency(offerDetails?.Currency, item.Price) : ""}</td>
                                                                {!user?.customerId && 
                                                                <td>
                                                                <td>{item.Discount > 0 ? item.Discount : ""}</td>
                                                                </td>}
                                                                {(showDiscountColumn && user?.customerId) && <td>{item.Discount != 0 ? item.Discount+"%" : ""}</td>}
                                                                <td className="text-end">{item.Total != 0 ? formatCurrency(offerDetails?.Currency,item.Total) : ""}</td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr>
                                                        <th colSpan="4" className="border-0 text-end p-0 pt-3">{props.t("Shipping")}</th>
                                                        <td className="border-0 text-end p-0 pt-3">{formatCurrency(offerDetails?.Currency,offerDetails?.Freight)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan="4" className="border-0 text-end p-0">{props.t("Sub Total")}</th>
                                                        <td className="border-0 text-end p-0">{formatCurrency(offerDetails?.Currency,offerDetails?.Net + offerDetails?.Freight)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th colSpan="4" className="border-0 text-end p-0">
                                                            {props.t("Tax")}</th>
                                                        <td className="border-0 text-end p-0">{formatCurrency(offerDetails?.Currency, offerDetails?.TotalVAT)}</td>
                                                    </tr>
                                                    {offerDetails?.RoundOff != 0 && 
                                                    <tr>
                                                        <th colSpan="4" className="border-0 text-end p-0">
                                                            {props.t("RoundOff")}</th>
                                                        <td className="border-0 text-end p-0">{formatCurrency(offerDetails?.Currency,offerDetails?.RoundOff)}</td>
                                                    </tr>}
                                                    <tr>
                                                        <th colSpan="4" className="border-0 text-end p-0"><h4 className="m-0 p-0">{props.t("Total")}</h4></th>
                                                        <td className="border-0 text-end p-0"><h4 className="m-0 p-0">{formatCurrency(offerDetails?.Currency,offerDetails?.TotalToPay)}</h4></td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div></>}
                                    </CardBody>}
                                </Card>
                            </Col>
                        </Row>
                </Container>
            </div>
            <DeleteModal
                deleteCallback={() => handleDeleteOffer()}
                element={"Offer"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteOfferModal}
                toggleModal={() => toggleDeleteOfferModal()}
                alertMsg={props.t("Are you sure you want to cancel this offer?")}
            />
        </React.Fragment>
    )
}


OfferDetails.prototype = {
    offerDetails: PropTypes.object,
    offerDetailsError: PropTypes.object,
    offerDetailsLoading: PropTypes.object,
    onGetOfferDetails: PropTypes.func,
    onGetOfferDetailsReset: PropTypes.func,
    offers: PropTypes.array,
    onGetOffers: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        offerDetails: state.Offers.offerDetails,
        offerDetailsError: state.Offers.offerDetailsError,
        offerDetailsLoading: state.Offers.offerDetailsLoading,
        offers: state.Offers.offers?.Offers,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetOfferDetails: id => dispatch(getOffersDetails(id)),
    onGetOffers: (offerType) => dispatch(getOffers(offerType)),
    onGetOfferDetailsReset: () => dispatch(getOffersDetailsReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(OfferDetails)));
