import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next"
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getPhrases} from "../../store/language/actions";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {LANGUAGE_MASTER_LIST} from "../../util/constants";
import {del, post} from "../../helpers/api_helper";
import { orderBy } from "lodash";

const PhraseList = (props) => {
    const {labels, onGetLabels, loading, error, match: {params}} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [search, setSearch] = useState("");
    const [langOpt, setLangOpt] = useState("");
    const history = useHistory();
    const [modal_standard, setmodal_standard] = useState(false)
    const [label, setLabel] = useState("");
    const [editId, setEditId] = useState("");
    const [labelList, setLabelList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    const [duplicatePhraseError, setDuplicatePhraseError] = useState(null);

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetLabels();
    }, [])

    useEffect(() => {
        if(labels){
            setLabelList(labels);
        }
    }, [labels]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setLabelList(orderBy(labelList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState]);

    function tog_standard() {
        setmodal_standard(!modal_standard)
        setDuplicatePhraseError(null);
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const getDatatableData = () => {
        const rows = labelList?.filter(row => row?.label.toLowerCase().includes(search?.toLowerCase()))
        let data = {
            columns: [
                {
                    label: props.t("Label"),
                    field: "label",
                    sort: "asc",
                    autoWidth: false,
                    width: "40%"
                },
                {
                    label: props.t("Action"),
                    field: "action",
                    autoWidth: false,
                    width: "20%"
                }

            ],
            rows: rows?.map((item, key) => ({
                label: item.label,
                action: <>
                    <i
                        className="uil uil-pen px-3 text-primary font-size-18"
                        onClick={() => handleEdit(item)}
                    ></i>
                    <i
                        className="uil uil-trash-alt px-3 text-danger font-size-18"
                        onClick={() => {
                            handleDeleteLabel(item);
                        }}
                    ></i>
                </>
            }))
        }
        return data;
    }

    const getLanguageName = (code) => {
        for (let i = 0; i < LANGUAGE_MASTER_LIST.length; i++) {
            if (LANGUAGE_MASTER_LIST[i].key == code) {
                return LANGUAGE_MASTER_LIST[i].value;
            }
        }
        return code;
    }

    const handleSave = async (hideModal) => {
        let response = await post("/api/translation/phrase", {id: editId, label: label});
        if(response.status == 202) {
            setEditId("");
            setLabel("");
            onGetLabels(params.locale);
            if(hideModal){
                tog_standard();
            }
        } else {
            if(typeof response?.data === 'string' && response?.data?.startsWith('Phrase already exists')) {
                setDuplicatePhraseError(response.data)
            }
        }
       
    }

    const handleEdit = (item) => {
        setEditId(item.id);
        setLabel(item.label);
        tog_standard();
    }

    const handleDelete = async () => {
        let response = await del(`/api/translation/phrase/${params.locale}`);
        history.push(`/phrases`)
    }

    const handleDeleteLabel = async (item) => {
        let response = await del(`/api/translation/phrase/${item.id}`);
        onGetLabels();
    }

    const handleBack = () => {
        history.push(`/language`)
    }

    const handleAdd = () => {
        setEditId("");
        setLabel("");
        tog_standard();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={false}
                        title={props.t("Phrase")}
                        breadcrumbs={[{title: props.t("Language"), link: "/language"}]}
                    />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={getLanguageName(params.locale)}
                                        buttons={[{
                                            text: props.t("Add Phrase"),
                                            onClick: handleAdd,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Labels"/>
                                {!loading &&
                                    <CardBody>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {error}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            searching={true}
                                            onSearch={(text) => setSearch(text)}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Label")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                    setLabel(e.target.value);
                                    setDuplicatePhraseError(null)
                                }}
                                placeholder={props.t("Enter Label")}
                                value={label}
                                defaultValue={label}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => handleSave(true)}
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => handleSave(false)}
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save & Continue")}
                    </Button>
                </div>
                {duplicatePhraseError && <Alert className="mx-3" color="danger">{props.t(duplicatePhraseError)}</Alert>}
            </Modal>
        </React.Fragment>
    );

}


PhraseList.propTypes = {
    labels: PropTypes.array,
    onGetLabels: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        labels: state.Language.phraseList,
        error: state.Language.phraseListError,
        loading: state.Language.phraseListLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onGetLabels: () => dispatch(getPhrases()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(PhraseList)));
