import {
    GET_ALL_CART_ITEMS,
    GET_ALL_CART_ITEMS_FAIL,
    GET_ALL_CART_ITEMS_SUCCESS,
    POST_ADD_CART_ITEM,
    POST_ADD_CART_ITEM_FAIL,
    POST_ADD_CART_ITEM_SUCCESS,
    PUT_UPDATE_CART_ITEM,
    PUT_UPDATE_CART_ITEM_FAIL,
    PUT_UPDATE_CART_ITEM_FROM_INPUT,
    PUT_UPDATE_CART_ITEM_SUCCESS,
    RESET_CART_MESSAGE
} from "./actionTypes";

export const getAllCartItems = () => ({
    type: GET_ALL_CART_ITEMS
})

export const getAllCartItemsSuccess = (cartItems) => ({
    type: GET_ALL_CART_ITEMS_SUCCESS,
    payload: cartItems
})

export const getAllCartItemsFail = (error) => ({
    type: GET_ALL_CART_ITEMS_FAIL,
    payload: error
})

export const resetCartMessage = () => ({
    type: RESET_CART_MESSAGE,
})

export const postAddCartItem = (articleId, quantity) => ({
    type: POST_ADD_CART_ITEM,
    action: {
        article: {
            id: articleId
        },
        quantity
    }
})

export const postAddCartItemSuccess = (cartItems) => ({
    type: POST_ADD_CART_ITEM_SUCCESS,
    payload: cartItems
})


export const postAddCartItemFail = (error) => ({
    type: POST_ADD_CART_ITEM_FAIL,
    payload: error
})

export const putUpdateCartItem = (cartItem) => ({
    type: PUT_UPDATE_CART_ITEM,
    cartItem
})

export const putUpdateCartItemFromInput = (cartItem) => ({
    type: PUT_UPDATE_CART_ITEM_FROM_INPUT,
    payload: cartItem
})

export const putUpdateCartItemSuccess = (cartItems) => ({
    type: PUT_UPDATE_CART_ITEM_SUCCESS,
    payload: cartItems
})

export const putUpdateCartItemFail = (error) => ({
    type: PUT_UPDATE_CART_ITEM_FAIL,
    payload: error
})
