import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { post } from "../../helpers/api_helper";
import { getLayoutList, getLayoutListReset } from "../../store/e-commerce/actions";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import { checkIfLoading } from "../../store/selector";
import { ecommerceActionTypes } from "../../store/e-commerce/actionTypes";
import { API_ERR_MSG } from "../../util/constants";
import Loader from "../../components/Common/Loader";
import { orderBy } from "lodash";

const LayoutList = (props) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const { layoutList, onGetLayout, loading, error, onGetLayoutListReset } = props;
  const [name, setName] = useState("");
  const [contentTypeList, setContentTypeList] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null)
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    return () => {
      onGetLayoutListReset();
    }
  }, [])

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    onGetLayout();
  }, []);

  useEffect(() => {
    setContentTypeList(layoutList);
  }, [layoutList]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setContentTypeList(orderBy(contentTypeList, o => {
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetLayoutListReset()}, 5000);
    }
  }, [error])

  const handleSave = async () => {
    let response = await post(
      `/api/template`,
      editItem ? { id: editItem.id, name: name } : { name: name }
    );
    setName("");
    setEditItem(null);
    tog_standard();
    onGetLayout();
  };

  const openContent = (item) => {
    history.push(`/tabs/${item.id}/details`);
  };

  const getDatatableData = () => {
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "name",
          sort: "asc",
          autoWidth: true,
        },
        {
          label: props.t("Created Date"),
          field: "createdAt",
          sort: "asc",
          autoWidth: true,
        },
      ],
      rows: contentTypeList?.map((item, key) => ({
        name: item.name,
        createdAt: new Date(item.createdAt).toLocaleString(),
        clickEvent: function () {
          openContent(item);
        },
      })),
    };
    return data;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageToolbarTop
            title={props.t("Tabs")}
            breadcrumbs={[{ title: props.t("Products"), link: "#" }]}
          />
          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                    title={props.t("Tabs")}
                    buttons={[{
                      text: props.t("Add New Template"),
                      onClick: tog_standard,
                    }]}
                  />
                </CardHeader>
                
                <Loader loading={loading} title="Tabs" />
                {!loading &&
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(API_ERR_MSG)}
                    </Alert>
                  }
                  <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    noBottomColumns={true}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    data={getDatatableData()}
                    // onSearch={(text) => console.log(text)}
                    searching={true}
                    sortRows={[]}
                    onSort={({column, direction}) => {
                        if(sortState && sortState.column == column){
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                    }}
                  />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Template
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              {props.t("Label")}
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                onChange={(e) => setName(e.target.value)}
                placeholder={props.t("Enter field label")}
                value={name}
              />
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="col-md-2 btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          <Button
            color="primary"
            onClick={handleSave}
            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
            className="col-md-2 btn btn-primary waves-effect waves-light"
          >
            {props.t("Save")}
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

LayoutList.propTypes = {
  layoutList: PropTypes.array,
  onGetLayout: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layoutList: state.ecommerce.layoutList,
    error: state.ecommerce.getLayoutListError,
    loading: checkIfLoading(state, ecommerceActionTypes.GET_LAYOUT_LIST)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetLayout: () => dispatch(getLayoutList()),
  onGetLayoutListReset: () => dispatch(getLayoutListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(LayoutList)));
