import {GET_LABELS, GET_LABELS_ERROR, GET_LABELS_SUCCESS, GET_LANGUAGE_LIST, GET_LANGUAGE_LIST_ERROR, GET_LANGUAGE_LIST_SUCCESS, GET_PHRASES, GET_PHRASES_ERROR, GET_PHRASES_SUCCESS} from "./actionTypes"


export const getLanguageList = () => ({
    type: GET_LANGUAGE_LIST
})

export const getLanguageListSuccess = (payload) => ({
    type: GET_LANGUAGE_LIST_SUCCESS,
    payload: payload.data
})

export const getLanguageListFail = (error) => ({
    type: GET_LANGUAGE_LIST_ERROR,
    payload: error
})

export const getLabels = (locale, tenantId, filter) => ({
    type: GET_LABELS,
    locale,
    tenantId,
    filter
})

export const getLabelsSuccess = (payload) => ({
    type: GET_LABELS_SUCCESS,
    payload: payload.data
})

export const getLabelFail = (error) => ({
    type: GET_LABELS_ERROR,
    payload: error
})


export const getPhrases = () => ({
    type: GET_PHRASES
})

export const getPhrasesSuccess = (payload) => ({
    type: GET_PHRASES_SUCCESS,
    payload: payload.data
})

export const getPhraseError = (error) => ({
    type: GET_PHRASES_ERROR,
    payload: error
})