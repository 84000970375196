import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Redirect, Route, useHistory} from "react-router-dom"
import ErrorBoundary from "../../components/Error/ErrorBoundary";

const Authmiddleware = ({
        component: Component,
        layout: Layout,
        isAuthProtected,
        allowedRoles,
        initState,
        ...rest
    }) => {
    const user = JSON.parse(localStorage.getItem("authUser"))
    const history = useHistory();

    useEffect(async () => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        const bgColor = user?.brandConfig?.primaryBackgroundColor;
        document.scrollingElement.style = `background-color: ${bgColor};`;
        var elements = document.getElementsByClassName('page-content'); // get all elements.
        for(var i = 0; i < elements. length; i++){
          elements[i].style.backgroundColor = bgColor
        }
    }, [])

    /*if(process.env.REACT_APP_GOOGLE_ANALYTICS){
        console.log("Initializing Google Analytics");
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        /!*ReactGA.set({
            userId: user.uid,
            // any data that is relevant to the user session
            // that you would like to track with google analytics
        })*!/

        history.listen(location => {
            ReactGA.set({ page: window.location.href }); // Update the user's current page
            ReactGA.pageview(window.location.href); // Record a page-view for the given page
        });
    }*/


    return <Route
        {...rest}
        render={props => {
            if (initState && Object.keys(initState).length > 0) {
                props.location.state = {...props.location.state, ...initState}
            }
            if (isAuthProtected && !(allowedRoles.length === 0 || allowedRoles.filter(element => user?.roles.includes(element)).length > 0)) {
                if (user) {
                    var landingUrl = "/customer/categories";
                    if(user.roles?.includes("ROLE_TENANT_ADMIN") || user.roles?.includes("ROLE_TENANT_USER") || user.roles?.includes("ROLE_SUPER_ADMIN")){
                        landingUrl = "/dashboard"
                    }
                    return (
                        <Redirect
                            to={{pathname: landingUrl, state: {from: props.location}}}
                        />
                    )
                }
                return (
                    <Redirect
                        to={{pathname: "/login", state: {from: props.location}}}
                    />
                )
            }
            if(isAuthProtected && !localStorage.getItem("authUser")){
                return (
                    <Redirect
                        to={{pathname: "/login", state: {from: props.location}}}
                    />
                )
            }

            return (
                <Layout>
                    <ErrorBoundary>
                        <Component {...props} />
                    </ErrorBoundary>
                </Layout>
            )
        }}
    />
}

Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
    allowedRoles: PropTypes.array
}

export default Authmiddleware
