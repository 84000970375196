import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {isEmpty, orderBy} from "lodash";
import PropTypes from "prop-types";
import {getOffers, getOffersReset} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import TableToolbar from "../../components/Common/TableToolbar";
import {filter, includes} from "lodash-es";
import {checkIfLoading} from "../../store/selector";
import {offersActionTypes} from "../../store/offer/actionTypes";
import Loader from "../../components/Common/Loader";
import {downloadPDF, getFilterClassName} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";

const Offers = (props) => {
  const { offers, onGetOffers, loading, error, onGetOffersReset } = props;
  const [offerList, setOfferList] = useState([]);
  const history = useHistory();
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [brandSettings, setBrandSettings] = useState(null);
  const [offerType, setOfferType] = useState("all")
  const [sortState, setSortState] = useState(null);
  const numericColumns = ['DocumentNumber', 'Total', 'Project'];
  let user = JSON.parse(localStorage.getItem("authUser"));
  let level = localStorage.getItem("level");
  const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";

  useEffect(() => {
    setBrandSettings(user?.brandConfig);
    const prevFilter = localStorage.getItem("salesOffersFilter");
    setOfferType("all");
    return () => {
        onGetOffersReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetOffersReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if(offers){
      setOfferList(offers);
    }
  }, [offers]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setOfferList(orderBy(offerList, o => {
          if(column === 'Sent') return o[column];
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, 
      [direction]
      ))
    }
  }, [sortState])

  useEffect(() => {
    if(offerType){
      onGetOffers(offerType);
    }
  }, [offerType]);

  const openOfferDetails = (offer) => {
    history.push(`${user.customerId ? '/customer' : ''}/offers/${offer.DocumentNumber}/types/${offerType}?from=` + encodeURIComponent(props.location.pathname));
  };
  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        offerList,
        (offer) =>
          includes(offer.displayName?.toLowerCase(), search.toLowerCase()) ||
          includes(offer.description?.toLowerCase(), search.toLowerCase()) ||
          filter(offer.categories, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(offerList);
    }
    setSearchValue(search);
  };
  const getDatatableData = () => {
    let columnsArr = [
      {
        label: props.t("Customer Name"),
        field: "CustomerName",
        width: 150,
      },
      {
        label: props.t("Document Number"),
        field: "DocumentNumber",
        width: 150,
      },
      {
        label: props.t("Our Reference"),
        field: "OurReference",
        width: 150,
      },
      {
        label: props.t("Status"),
        field: "status",
        width: 150,
      },
      {
        label: props.t("OfferDate"),
        field: "OfferDate",
        width: 150,
      },
      {
        label: props.t("Project"),
        field: "Project",
        width: 150,
      },
      {
        label: props.t("Sent"),
        field: "Sent",
        width: 150,
      },
      {
        label: props.t("Total"),
        field: "Total",
        width: 150,
      },
      {
        label: props.t("Download"),
        field: "download",
      }
    ]
    if(user?.customerId){
      columnsArr.splice(0,1);
    }
    let data = {
      columns: columnsArr,
      rows: offerList?.map((offer, key) => ({                
        status: <div onClick={() => openOfferDetails(offer)} className={`badge ${getFilterClassName(offer.status)} p-1`}>{props.t(offer.status)}</div>,
        OfferDate: <div onClick={() => openOfferDetails(offer)}>{offer.OfferDate}</div>,
        CustomerName: <div onClick={() => openOfferDetails(offer)}>{offer.CustomerName}</div>,
        DocumentNumber: <div onClick={() => openOfferDetails(offer)}>{offer.DocumentNumber}</div>,
        OurReference: <div onClick={() => openOfferDetails(offer)}>{offer.OurReference}</div>,
        Project: <div onClick={() => openOfferDetails(offer)}>{offer.Project}</div>,
        Sent: <div onClick={() => openOfferDetails(offer)}>{offer.Sent == true ? props.t("Yes") : props.t("No")}</div>,
        Total: <div onClick={() => openOfferDetails(offer)}>{formatCurrency(offer?.Currency,offer?.Total)}</div>,
        download: <div onClick={() => downloadPDF(offer.DocumentNumber, 'offer')}><i className="fa fa-download font-size-15"></i></div>
      })),
    };
    return data;
  };

  const handleFilter = (value) => {
      setOfferType(value);
  }

  const getFiltes = () => {
    let type = [{name: props.t("All"), value: "all"},
      {name: props.t("Ongoing"), value: "ordernotcreated"},
      {name: props.t("Accepted"), value: "ordercreated"},
      {name: props.t("Expired"), value: "expired"}];
    if (level == "TENANT") {
      type.push({name: props.t("Cancelled"), value: "cancelled"})
    }
    return type;
  }

  const getButtons = () => {
    let btn = [];
    if (level == "TENANT" && fortnoxConnectionStatus === true) {
      btn.push({
        text: props.t("New Offer"),
        onClick: () => history.push(`/offers/new`),
      })
    }
    return btn;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                    title={props.t("Offers")}
                    filter={{
                      selected: offerType,
                      options : getFiltes(),
                      onSelect: handleFilter
                    }}
                    buttons={getButtons()}
                  />
                </CardHeader>
                <Loader loading={loading} title="Offers" />
                {!loading &&
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(error)}
                    </Alert>
                  }
                      <MDBDataTable
                          responsive
                          hover
                          barReverse={true}
                          borderless
                          paginationLabel={[props.t("Previous"), props.t("Next")]}
                          entriesLabel={props.t("Show entries")}
                          infoLabel={[
                            props.t("Showing"),
                            props.t("to"),
                            props.t("of"),
                            props.t("entries"),
                          ]}
                          searching={true}
                          searchLabel={props.t("Search") + "..."}
                          noRecordsFoundLabel={props.t("No matching records found")}
                          noBottomColumns={true}
                          data={getDatatableData()}
                          entries={50}
                          entriesOptions={[50, 100, 500]}
                          // onSearch={(text) => console.log(text)}
                          sortRows={[]}
                          onSort={({column, direction}) => {
                            if(sortState && sortState.column == column){
                              setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                            } else {
                              setSortState({column, direction});
                            }
                          }}
                      />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Offers.propTypes = {
  offers: PropTypes.array,
  onGetOffers: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    offers: state.Offers.offers?.Offers,
    loading: checkIfLoading(state, offersActionTypes.GET_OFFERS),
    error: state.Offers.getOffersError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetOffers: (offerType) => dispatch(getOffers(offerType)),
  onGetOffersReset: () => dispatch(getOffersReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Offers)));
