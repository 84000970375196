import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import Backend from "i18next-xhr-backend"

i18n
    .use(initReactI18next)  // passes i18n down to react-i18next
    .use(Backend)
    .use(detector)
    .init({
        detection: {
            order: ['localStorage', 'querystring', 'navigator'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'I18N_LANGUAGE'
        },
        fallbackLng: "en",
        debug: false,
        backend: {
            loadPath: process.env.REACT_APP_APIBASEURL + '/locales/{{lng}}/{{ns}}' + '?domain='+ window.location.hostname,
            crossDomain: false,
            withCredentials: false,
        }
    });

export default i18n
