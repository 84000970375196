import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Button, Col, Row} from "reactstrap"
import {withRouter} from "react-router-dom"
import {withTranslation} from "react-i18next";
import {connect} from "react-redux"
import {getWorkLog, getWorkLogType} from "../../store/task/actions"
import {del, get, post} from "../../helpers/api_helper"
import {MDBDataTable} from "mdbreact";
import {size} from "lodash-es";
import WorkLogEdit from "./WorkLogEdit";
import {orderBy} from "lodash";


const WorkLog = (props) => {
    const {
        getWorklogs,
        workLog,
        onGetWorkLogTypes,
        workLogTypes,
        userDropdown,
        hideCustomer,
        hideTask,
        match: {params},
    } = props;
    const [c, setc] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [activeTabJustify, setactiveTabJustify] = useState("5");
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [description, setDescription] = useState(null);
    const [logType, setLogType] = useState(null);
    const [date, setDate] = useState(null);
    const [worklogUser, setWorklogUser] = useState(null);

    const [worklogError, setWorklogError] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [editId, setEditId] = useState(null);
    const [editWorkLog, setEditWorkLog] = useState(null);
    const [worklogList, setWorklogList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['id'];
    const level = localStorage.getItem("level");


    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        getWorklogs({taskId:params.id});
        onGetWorkLogTypes();
    }, [])

    useEffect(() => {
        if(workLog){
            setWorklogList(workLog);
        }
    }, [workLog]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setWorklogList(orderBy(worklogList, (o, key) => {
            if(column === 'id') return key + 1;
            if(column === 'timeslipType' || column === 'user') return o[column]?.name
            if(column === 'worklog') return getTimeInMinutes(o);
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    const handleAddWorkLog = async () => {
        if (!hours && !minutes) {
            setWorklogError("Please provide hours or minutes.");
            return;
        }
        let response = await post(`/api/timeslip/task/${params.id}`, {
            id: editId,
            hour: hours,
            minute: minutes,
            description: description,
            timeslipType: {id: logType},
            date: date,
            user: {id: worklogUser?.id}
        })
        setHours("");
        setMinutes("");
        setDescription("");
        getWorklogs({taskId:params.id});
        handleAddTask();
    }

    const realodTaskWorkLog = () => {
        getWorklogs({taskId: params.id});
    }

    const handleAddTask = () => {
        setEditWorkLog(null);
        setShowForm(!showForm);
    }

    const getTime = (workLog) => {
        let value = "";
        if(workLog?.hours){
            value = workLog.hours;
            value += " ";
            value += workLog.hours > 1 ? props.t("hours") : props.t("hour");
        }
        if(workLog?.minutes){
            value += " " + workLog.minutes + " "
            value += workLog.minutes > 1 ? props.t("minutes") : props.t("minute");
        }
        return value;
    }

    const getTimeInMinutes = (workLog) => {
        let value = 0;
        if(workLog?.hours){
            value = workLog.hours*60;
        }
        if(workLog?.minutes){
            value += workLog.minutes
        }
        return value;
    }

    const handleEdit = async (workLog) => {
        let response = await get(`/api/timeslip/${workLog.id}`);
        if(response.status === 200){
            setEditWorkLog(response.data);
            setShowForm(true);
        }
    }

    const handleDelete = async (workLog) => {
        let response = await del(`/api/timeslip/${workLog.id}`);
        getWorklogs({taskId:params.id});
    }

    const getDatatableData = () => {
        var columns = [
            {
                label: props.t("Sr.no"),
                field: "id",
                autoWidth: true,
            },
            {
                label: props.t("Description"),
                field: "description",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Category"),
                field: "timeslipType",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Time"),
                field: "worklog",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("User"),
                field: "user",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Date"),
                field: "date",
                sort: "asc",
                autoWidth: true,
            }
        ];
        if(level != "CUSTOMER"){
            columns.push({
                label: props.t("Action"),
                field: "action",
                autoWidth: true
            })
        }
        let data = {
            columns: columns,
            rows: worklogList?.map((item, key) => ({
                id: (key + 1),
                description: item?.description,
                timeslipType: item?.timeslipType,
                worklog:item?.time,
                user: item?.user,
                date: new Date(item?.date).toDateString().toLocaleString(),
                action: <><i className="uil uil-pen px-3 font-size-18" onClick={() => handleEdit(item)}></i>
                          <i className="uil uil-trash-alt px-3 text-danger font-size-18" onClick={() => handleDelete(item)}></i>
                </>
            })),
        };
        return data;
    };

    return (<>
            {showForm && <>
                <WorkLogEdit workLog={editWorkLog} showForm={showForm} hideCustomer={hideCustomer} hideTask={hideTask} setShowForm={setShowForm} cid={params.id} taskId={params.id}  userDropdown={userDropdown} realodTaskWorkLog={realodTaskWorkLog} />
                {/*<div className="mt-4">
                    <AvForm onValidSubmit={(e, v) => {
                        e.preventDefault();
                        handleAddWorkLog(e, v)
                    }}>
                        <Row className="mb-3">
                            <div className="col-md-12">
                                <Row>
                                    <div className="col-md-12 mb-3">
                                      <textarea
                                          className="form-control task-textarea"
                                          type="text"
                                          onChange={(e) => setDescription(e.target.value)}
                                          placeholder={props.t("Enter description of work")}
                                          value={description}
                                          rows="3"
                                      />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <select
                                            className="form-control col-md-6"
                                            value={logType}
                                            onChange={(e) => setLogType(e.target.value)}
                                        >
                                            <option value="">{props.t("Select category")}</option>
                                            {workLogTypes?.map((type) => <option value={type.id}>{type.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <SearchableDropdown
                                            keyToDisplay={"name"}
                                            options={userDropdown}
                                            selectedValue={worklogUser}
                                            placeholder={props.t("Select user")}
                                            handleOnOptionClick={(o) => setWorklogUser(o)}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <DatePicker
                                            onChange={(date) => {
                                                setDate(parseDateToString(date))
                                            }}
                                            value={date ? new Date(date) : null}
                                            name={"Date"}
                                            format={"yyyy-MM-dd"}
                                            dayPlaceholder={"dd"}
                                            monthPlaceholder={"mm"}
                                            yearPlaceholder={"yyyy"}
                                            maxDate={date ? new Date(date) : new Date()}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <AvField type="number"
                                                 name="hours"
                                                 validate={{
                                                     pattern: {value: '^[0-9]+$', errorMessage: 'Your hours must be integer numbers'},
                                                 }}
                                                 className="form-control"
                                                 value={hours}
                                                 onChange={(e) => {
                                                     setWorklogError(null);
                                                     setHours(e.target.value);
                                                 }}
                                                 defaultValue={hours}
                                                 onWheel={preventNumberInputScroll}
                                        />
                                        <span>{props.t("Hours")}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <AvField type="number"
                                                 name="minutes"
                                                 validate={{
                                                     pattern: {value: '^[0-9]+$', errorMessage: 'Your minutes must be integer numbers'},
                                                 }}
                                                 className="form-control"
                                                 value={minutes}
                                                 onChange={(e) => {
                                                     setWorklogError(null);
                                                     setMinutes(e.target.value);
                                                 }}
                                                 defaultValue={minutes}
                                                 onWheel={preventNumberInputScroll}
                                        />
                                        <span>{props.t("Minutes")}</span>
                                    </div>
                                </Row>
                                <Row className="mb-3 mt-5">
                                    <Col align="center">
                                        <Button
                                            color="secondary"
                                            style={{backgroundColor: brandSettings?.secondaryBtnColor, borderColor: brandSettings?.secondaryBtnColor}}
                                            onClick={() => setShowForm(!showForm)}
                                            className="btn btn-primary waves-effect waves-light mt-2"
                                        >
                                            {props.t("Back")}
                                        </Button>
                                        {" "}
                                        <Button
                                            color="primary"
                                            style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                            //   onClick={() => handleAddWorkLog()}
                                            className="btn btn-primary waves-effect waves-light  mt-2 ml-2"
                                            disabled={!logType}
                                            type="submit"
                                        >
                                            {props.t("Save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Row>
                        {worklogError &&
                            <Row>
                                <Col>
                                    <div>
                                        <Alert color="danger">{worklogError}</Alert>
                                    </div>
                                </Col>
                            </Row>}
                    </AvForm>
                </div>*/}
            </>}
            {!showForm && level != "CUSTOMER" && <Row className="mb-3">
                <Col className="col-12">
                    <Button
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        className="btn btn-primary waves-effect waves-light float-end"
                        onClick={handleAddTask}>
                        {props.t("Add Time")}
                    </Button>
                </Col>
            </Row>}
            {!showForm &&
                <div className="mt-4">
                    {/*{map(workLog, (log, k) => (
                        <React.Fragment key={'review_' + k}>
                            <div className={"border-top pt-3"}>
                                <p className="float-sm-end text-muted font-size-13">{log?.date ? new Date(log?.date).toLocaleString() : new Date(log.updatedAt).toLocaleString()}</p>
                                <Media className="d-flex">
                                    <div className="flex-1">
                                        <p className="font-size-15 mb-0">{log.user?.name} {(log.hour >= 1 || log.minute >= 1) && "-"} {(log.hour && log.hour >= 1) && log.hour + " " + props.t("hours")} {(log.minute && log.minute >= 1) && log.minute + " " + props.t("minutes")}</p>
                                    </div>
                                </Media>
                                <p>{log?.workLogType?.name}</p>
                                <p className="text-muted mb-4">{log.description}</p>
                            </div>
                        </React.Fragment>
                    ))}*/}

                    <MDBDataTable
                        key={size(worklogList)}
                        responsive
                        barReverse={true}
                        searching={true}
                        hover
                        borderless
                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                        entriesLabel={props.t("Show entries")}
                        infoLabel={[
                            props.t("Showing"),
                            props.t("to"),
                            props.t("of"),
                            props.t("entries"),
                        ]}
                        searchLabel={props.t("Search") + "..."}
                        noRecordsFoundLabel={props.t("No matching records found")}
                        noBottomColumns={true}
                        data={getDatatableData()}
                        entries={50}
                        entriesOptions={[50, 100, 500]}
                        onSearch={(text) => console.log(text)}
                        sortRows={[]}
                        onSort={({column, direction}) => {
                            if(sortState && sortState.column == column){
                                setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                            } else {
                                setSortState({column, direction});
                            }
                        }}
                    />
                </div>
            }
        </>
    )
}

WorkLog.propTypes = {
    workLog: PropTypes.array,
    workLogTypes: PropTypes.array
}

const mapStateToProps = (state) => {
    return {
        workLog: state.task.worklogs,
        workLogTypes: state.task.workLogType
    };
};

const mapDispatchToProps = (dispatch) => ({
    getWorklogs: (filter) => dispatch(getWorkLog(filter)),
    onGetWorkLogTypes: () => dispatch(getWorkLogType())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(WorkLog)));
