import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {isEmpty, orderBy} from "lodash";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {TenantCustomerGetUsers, TenantCustomerGetUsersReset} from "../../store/users/tenant/actions";
import {impersonateUser} from "../../store/actions";
import {tenantActionTypes, TENANT_ADMIN_RESET_DELETE_USER, TENANT_ADMIN_RESET_REGENERATE_TOKEN,} from "../../store/users/tenant/actionTypes";
import {filter, includes} from "lodash-es";
import TableToolbar from "../../components/Common/TableToolbar";
import {checkIfLoading} from "../../store/selector";
import Loader from "../../components/Common/Loader";
import {debounce} from "../../util/helperFunctions";

export const userDetailContext = React.createContext(null);

const UsersList = (props) => {
    let history = useHistory();
    const {
        users,
        totalPages,
        onGetUsers,
        tokenRegenerate,
        userDeleted,
        onResetRegenerateToken,
        onResetDeleteUser,
        userActivated,
        error,
        loading,
        onImpersonateUser,
        onGetUsersReset,
    } = props;
    const [usersList, setUsersList] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState(null)
    const [sortState, setSortState] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50)
    const numericColumns = [];
    const location = useLocation();
    const nonSortingColumns = ['impersonate', 'roles', 'username'];

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);

        const prevFilter = localStorage.getItem("companyUsersFilter");
        if(prevFilter){
            setFilter(prevFilter);
        } else {
          setFilter("active");
        }
        return () => {
            onGetUsersReset();
        }
    }, [])

    useEffect(() => {
      if(error){
        setErrorMessage(error);
        setTimeout(() => {onGetUsersReset()}, 5000);
        setTimeout(() => {setErrorMessage('')}, 5000);
      }
    }, [error])

    useEffect(() => {
        if(users){
            setUsersList(users);
        }
    }, [users]);

    useEffect(() => {
      if(sortState && usersList){
        const {column, direction} = sortState;
        if(!nonSortingColumns.includes(column)){
            if(column && direction){
                if(page == 1){
                    onGetUsers(filter, page, pageSize, search, sortState);
                } else {
                    setPage(1);
                }
            }
        }
      }
    }, [sortState])

    useEffect(() => {
        if(filter){
          localStorage.setItem("companyUsersFilter", filter);
          onGetUsers(filter, page, pageSize, search, sortState);
        }
    }, [filter]);

    useEffect(() => {
        onGetUsers(filter, page, pageSize, search, sortState);
    }, [page])

    useEffect(() => {
        onGetUsers(filter, page, pageSize, search, sortState);
    }, [pageSize])

    useEffect(() => {
        if (tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetRegenerateToken();
            }, 3000);
        } else if (tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"));
            setTimeout(() => {
                setErrorMessage("");
                onResetRegenerateToken();
            }, 3000);
        }
    }, [tokenRegenerate]);

    useEffect(() => {
        if (userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User deactivated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUsers(filter, page, pageSize, search, sortState);
        } else if (userDeleted) {
            setErrorMessage(props.t("Error deleting user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userDeleted]);

    useEffect(() => {
        if (userActivated && userActivated.status) {
            setSuccessMessage(props.t("User activated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUsers(filter, page, pageSize, search, sortState);
        } else if (userActivated) {
            setErrorMessage(props.t("Error activated user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userActivated]);

    const handleSearch = () => {
        if (!isEmpty(search)) {
            const results = filter(
                usersList,
                (user) =>
                    includes(user.name?.toLowerCase(), search.toLowerCase()) ||
                    includes(user.city?.toLowerCase(), search.toLowerCase()) ||
                    filter(user.phone, (c) =>
                        includes(c.name?.toLowerCase(), search.toLowerCase())
                    ).length > 0
            );

            setSearchResults(results);
        } else {
            setSearchResults(usersList);
        }
        setSearchValue(search);
    };

    function getRoleName(roles) {
        const role = roles[0]?.name;
        if (role === "ROLE_TENANT_ADMIN") {
            return props.t("Tenant Admin");
        } else if (role === "ROLE_TENANT_USER") {
            return props.t("Tenant User");
        } else if (role === "ROLE_CUSTOMER_ADMIN") {
            return props.t("Customer Admin");
        } else if (role === "ROLE_CUSTOMER_USER") {
            return props.t("Customer User");
        } else if (role === "ROLE_CONTACT_USER") {
            return props.t("Contact");
        } else if (role === "ROLE_SUPER_ADMIN") {
            return props.t("Super Admin");
        }
        return role;
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    width: 150,
                },
                {
                    label: props.t("Customer"),
                    field: "customer",
                    width: 150,
                },
                {
                    label: props.t("Username"),
                    field: "username",
                    width: 100,
                },
                {
                    label: props.t("City"),
                    field: "city",
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                    width: 150,
                },
                {
                    label: props.t("Role"),
                    field: "roles",
                    width: 150,
                },
                {
                    label: props.t("Status"),
                    field: "enabled",
                    width: 150,
                },
                {
                  label: props.t("Impersonate"),
                  field: "impersonate",
                }
            ],
            rows: usersList?.map((user, key) => ({
                name: <span onClick={() => handleOpen(user)}>{user.name}</span>,
                customer: <div onClick={() => handleOpen(user)}>{user?.customer?.name}</div>,
                username: <div onClick={() => handleOpen(user)}>{user.username}</div>,
                city: <div onClick={() => handleOpen(user)}>{user.city}</div>,
                phone: <div onClick={() => handleOpen(user)}>{user.phone}</div>,
                roles: <div onClick={() => handleOpen(user)}>{getRoleName(user.roles)}</div>,
                enabled: <div onClick={() => handleOpen(user)}>{user.enabled ? props.t("Active") : props.t("Disabled")}</div>,
                impersonate: user.roles?.length > 0 && user.roles[0]?.name !== "ROLE_CONTACT_USER" && <><button onClick={()=> handleImpersonate(user)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}><i className="fa fa-sign-in-alt"></i></button></>,
            })),
        };
        return data;
    };

    const handleImpersonate = (user) => {
      onImpersonateUser({ userEmail: user.email }, history, location)
    }

    const handleOpen = (u) => {
      history.push(`/customers/${u.customer.id}/users/${u.id}?from=` + encodeURIComponent(props.location.pathname));
    };

    const handleOnClick = () => {
        history.push("/users/create");
    };

    const handleFilter = (option) => {
      setFilter(option);
    }

    const getPage = (totalPage) => {
        let pages = [];
        let count = page == 1 ? page + 5 : page;
        for(let i = count - 5 > 0 ? count - 5 : 1; i < count + 5 && i <= totalPage; i++){
            pages.push(i);
        }
        return pages;
    }

    const handleClearSearch = (e) => {
        if (e.key === 'Backspace' && search.length == 0) {
            setPage(1);
        }
    }

    const processChange = debounce((arg) => {
        setSearch(arg?.target?.value)
        setPage(1);
        onGetUsers(filter, page, pageSize, arg?.target?.value);
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
              
                            <Row>
                                <Col className="col-12 article-table">
                                    <Card>
                                        <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                            <TableToolbar
                                                title={props.t("Users")}
                                                buttons={[{
                                                    text: props.t("New User"),
                                                    onClick: handleOnClick,
                                                }]}
                                                filter={{
                                                    selected: filter,
                                                    options : [
                                                        {name: props.t("All"), value:"active"},
                                                        {name: props.t("Users"), value:"users"},
                                                        {name: props.t("Contacts"), value: "contacts"},
                                                        {name: props.t("Disabled"), value:"disabled"}
                                                    ],
                                                    onSelect: handleFilter
                                                }}
                                            />
                                        </CardHeader>
                                        <Loader loading={loading} title="Users" />

                                        <CardBody>
                                            {successMessage && (
                                            <Row>
                                                <Col className="col-12">
                                                    <Alert color="success">{successMessage}</Alert>
                                                </Col>
                                            </Row>
                                            )}
                                            {errorMessage && (
                                            <Row>
                                                <Col className="col-12">
                                                    <Alert color="danger">{errorMessage}</Alert>
                                                </Col>
                                            </Row>
                                            )}

                                            <Row className="mb-3">
                                                <Col xs={6}>
                                                    <input  type="text" className="form-control"
                                                            style={{width:"200px"}}
                                                            placeholder={props.t("Search") + "..."}
                                                            onChange={processChange}
                                                            onKeyUp={(e) => handleClearSearch(e)}
                                                    />
                                                </Col>
                                                <Col xs={6} className="justify-content-end">

                                                    <div className="col-md-1 float-end justify-content-end">
                                                        <select className="form-control" value={pageSize} style={{width:"50px"}} onChange={(e) => setPageSize(e.target.value)}>
                                                            <option>20</option>
                                                            <option>50</option>
                                                            <option>100</option>
                                                            <option>500</option>
                                                        </select>
                                                    </div>
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-2 col-form-label float-end"
                                                    >
                                                        {props.t("Show Entries")}
                                                    </label>
                                                </Col>
                                            </Row>
                                        {!(loading && page == 1 && usersList.length == 0) && (<>
                                            <MDBDataTable
                                                responsive
                                                barReverse={true}
                                                hover
                                                borderless
                                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                entriesLabel={props.t("Show entries")}
                                                infoLabel={[
                                                    props.t("Showing"),
                                                    props.t("to"),
                                                    props.t("of"),
                                                    props.t("entries"),
                                                ]}
                                                searchLabel={props.t("Search") + "..."}
                                                noRecordsFoundLabel={props.t(
                                                    "No matching records found"
                                                )}
                                                noBottomColumns={true}
                                                data={getDatatableData()}
                                                entries={50}
                                                entriesOptions={[50, 100, 500]}
                                                onSearch={(text) => console.log(text)}
                                                searching={false}
                                                sortRows={[]}
                                                paging={false}
                                                onPageChange={(activePage, pagesAmount) => {
                                                    setPage(activePage);
                                                }}
                                                onSort={({column, direction}) => {
                                                if(column){
                                                    if(sortState && sortState.column == column){
                                                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                    } else {
                                                        setSortState({column, direction});
                                                    }
                                                }
                                                }}
                                            />
                                            <Row key={"page_"+ page}>
                                                <div>
                                                    <Pagination className="pagination float-sm-end">
                                                        <PaginationItem disabled={page === 1}>
                                                            <PaginationLink
                                                                previous
                                                                to="#"
                                                                onClick={() => setPage(page - 1)}
                                                            />
                                                        </PaginationItem>
                                                        {getPage(totalPages).map(function(i){
                                                            return (<PaginationItem
                                                                active={i  === page}
                                                                key={"_pagination_" + i}
                                                            >
                                                                <PaginationLink
                                                                    onClick={() => setPage(i)}
                                                                    to="#"
                                                                >
                                                                    {i}
                                                                </PaginationLink>
                                                            </PaginationItem>)
                                                        })}
                                                        <PaginationItem disabled={page === totalPages}>
                                                            <PaginationLink
                                                                next
                                                                to="#"
                                                                onClick={() => setPage(page + 1)}
                                                            />
                                                        </PaginationItem>
                                                    </Pagination>
                                                </div>
                                            </Row>
                                            </>)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

UsersList.propTypes = {
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
    onImpersonateUser: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        users: state.TenantAdmin.customerUsers,
        totalPages: state.TenantAdmin.customerUsersTotal,
        tokenRegenerate: state.TenantAdmin.tokenRegenerate,
        userDeleted: state.TenantAdmin.userDeleted,
        userActivated: state.TenantAdmin.userActivated,
        loading: checkIfLoading(state, tenantActionTypes.GET_TENANT_CUSTOMER_USERS),
        error: state.TenantAdmin.getCustomerUsersError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetUsers: (filter, page, pageSize, search, sortState) => dispatch(TenantCustomerGetUsers(
        filter, page, pageSize, search ? search : "", sortState?.column, sortState?.direction?.toUpperCase())),
    onResetRegenerateToken: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
        }),
    onResetDeleteUser: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_DELETE_USER,
        }),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
    onGetUsersReset: () => dispatch(TenantCustomerGetUsersReset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(UsersList)));
