import {CHANGE_PASSWORD, CHANGE_PASSWORD_ERROR, CHANGE_PASSWORD_SUCCESS,} from "./actionTypes"

const initialState = {
  changeSuccessMsg: null,
  changePwdError: null,
}

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      state = {
        ...state,
        changePwdSuccessMsg: null,
        changePwdError: null,
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changePwdSuccessMsg: action.payload,
        changePwdError: null,
      }
      break
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, changePwdError: action.payload, changePwdSuccessMsg: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default changePassword
