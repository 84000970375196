import React, { useEffect, useState } from "react";
import { BreadcrumbItem, Col, Container, Row, Card, CardBody, CardHeader, Alert } from "reactstrap";
import ReactLoading from "react-loading";
import { Link, useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSubCategoryList, getSubCategoryListReset } from "../../store/e-commerce/actions";
import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import { BASE_IMAGE_URL } from "../../helpers/url_helper";
import TableToolbar from "../../components/Common/TableToolbar";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { API_ERR_MSG, SPINNER_DEFAULT_COLOR } from "../../util/constants";
import { ecommerceActionTypes } from "../../store/e-commerce/actionTypes";
import { checkIfLoading } from "../../store/selector";
import categoriesPlaceholder from "../../assets/images/categories/categories.png";
import Loader from "../../components/Common/Loader";

const ShopSubCategories = (props) => {
  const {
    category,
    onGetSubCategoryList,
    onGetSubCategoryListReset,
    error,
    loading,
    match: { params },
  } = props;

  const [contentTypeList, setContentTypeList] = useState([]);
  const [selectCategory, setSelectedCategory] = useState(category);
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user?.brandConfig);
    return () => {
      onGetSubCategoryListReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetSubCategoryListReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if (params && params.id) {
      onGetSubCategoryList(params.id);
      setContentTypeList(category.subCategories);
      setSelectedCategory(category);
    }
  }, [params.id]);

  useEffect(() => {
    setContentTypeList(category.subCategories);
    setSelectedCategory(category);
  }, [category]);

  const handleSelect = (selected) => {
    if (selected != null) {
      if (selected.subCategories && selected.subCategories.length > 0) {
        history.push(`/customer/categories/${selected.id}/subcategory`);
      } else {
        history.push(`/customer/categories/${selected.id}/articles`);
      }
    }
  };

  const handleBack = () => {
    if (category.parents == null || category.parents.length === 0) {
      history.push("/customer/categories");
      return;
    }
    history.push(
      `/customer/categories/${category.parents[category.parents.length - 1].id
      }/subcategory`
    );
  };

  const getBreadcrumbs = () => {
    let crumbs = [
      { title: props.t("Articles"), link: "/customer/articles" },
      { title: props.t("Categories"), link: "/customer/categories" },
    ];
    let additionalCrumbs = [];
    if (selectCategory != null && selectCategory.parents != null) {
      additionalCrumbs = [
        ...selectCategory?.parents?.map((item, index) => ({
          title: item.name,
          link: `/customer/categories/${item.id}/subcategory`,
        })),
      ];
      crumbs = [...crumbs, ...additionalCrumbs];
    }
    return crumbs;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <PageToolbarTop
              noBackBtn={true}
              title={selectCategory?.name}
              breadcrumbs={getBreadcrumbs()}
              backBtn={handleBack}
            />
          <Row>
            <Col className="col-12">  
              <Card>
                  <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor, height:"max-content" }}>
                    <TableToolbar
                      title={selectCategory?.name}
                      description={selectCategory?.description}
                    />
                  </CardHeader>
                  <Loader loading={loading} title={props.t("Sub Categories")} />
                  {!loading && 
                  <CardBody>
                    {error &&
                      <Alert className={"mt-4"} color="danger" role="alert">
                          {error}
                      </Alert>
                    }
                    <Row className="align-items-center">
                      {contentTypeList != null &&
                        contentTypeList.map(function (subCategory, index) {
                          return (
                            <Col
                              key={"subcate<br/>gory_" + index}
                              xl={2}
                              md={4}
                              className="col-6 cursor-pointer"
                              onClick={() => handleSelect(subCategory)}
                            >
                              <div className="img-fluid">
                                {subCategory.media != null && (
                                  <div
                                    className="background-Image"
                                    style={{
                                      backgroundImage: `url(${
                                        BASE_IMAGE_URL + "/" + subCategory.media.s3Key
                                      })`,
                                    }}
                                  ></div>
                                )}
                                {subCategory.media == null && (
                                  <img
                                    src={categoriesPlaceholder}
                                    alt=""
                                    className="img-fluid mx-auto height-100 d-block"
                                  />
                                )}
                              </div>
                              <br />
                              <h6 align="center">{subCategory.name}</h6>
                            </Col>
                          );
                        })}
                    </Row>
                  </CardBody>}
                </Card>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
      </div>
    </React.Fragment>
  );
};
{
  /* <img
                                                    src={BASE_IMAGE_URL + "/"+ subCategory.media.id}
                                                    alt=""
                                                    className="img-fluid d-block"
                                                /> */
}
ShopSubCategories.propTypes = {
  category: PropTypes.object,
  onGetSubCategoryList: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    category: state.ecommerce.subCategoryList,
    error: state.ecommerce.getSubCategoryListError,
    loading: checkIfLoading(state, ecommerceActionTypes.GET_SUB_CATEGORY_LIST)
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetSubCategoryList: (id) => dispatch(getSubCategoryList(id)),
  onGetSubCategoryListReset: () => dispatch(getSubCategoryListReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ShopSubCategories)));
