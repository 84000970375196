import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {del, post, put} from "../../helpers/api_helper";
import {getLayoutList, getTemplateDetails} from "../../store/e-commerce/actions";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import {checkIfLoading} from "../../store/selector";
import {GET_TEMPLATE_DETAILS} from "../../store/e-commerce/actionTypes";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import {isArray} from "lodash";
import DeleteModal from "../../components/Common/DeleteModal";


const TemplateDetails = (props) => {
    const [modal_standard, setmodal_standard] = useState(false);
    const [template_modal_standard, settemplate_modal_standard] = useState(false);
    const {template, onGetTemplates, loading, error, match: {params},layoutList,
        onGetLayoutList} = props;
    const [name, setName] = useState("");
    const [contentTypeList, setContentTypeList] = useState([]);
    const [editItem, setEditItem] = useState(null);
    const [search, setSearch] = useState("");
    const history = useHistory();
    const [brandSettings, setBrandSettings] = useState(null)
    const [tabName, setTabName] = useState("");
    const [tabType, setTabType] = useState("");
    const [tabLayout, setTabLayout] = useState(null);
    const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
    }, [])

    function toggleDeleteTemplate() {
        setShowDeleteTemplateModal(!showDeleteTemplateModal);
        removeBodyCss();
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function tog_template_standard() {
        settemplate_modal_standard(!template_modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        if(params.id){
            onGetTemplates(params.id);
        }
        onGetLayoutList();
    }, [params.id]);

    useEffect(() => {
        setContentTypeList(template?.tabList);
        setName(template.name)
        setEditItem(template);
    }, [template]);

    const handleSave = async () => {
        let response = await post(
            `/api/tab-template`,
            editItem ? {id: editItem.id, name: name} : {name: name}
        );
        tog_template_standard();
        onGetTemplates(params.id);
    };


    const handleDelete = async () => {
        try {
            await del(`/api/tab-template/${template.id}`);
            history.push(`/templates`);
            return false;
          } catch (err){
              const errData = err.response.data;
              if(errData && isArray(errData) && errData.length > 0){
                  cogoToast.error(props.t(errData), toastOptions)
              } else {
                  cogoToast.error(props.t(API_ERR_MSG), toastOptions)
              }
              return true;
          }
    }

    const openContent = (item) => {
        //history.push(`/templates/${item.id}`);
    };

    const getLabel = (item) => {
        if(item == "DATA"){
            return props.t("Data");
        }else if(item == "FILES"){
            return props.t("Files")
        }else if(item == "IMAGES"){
            return props.t("Images");
        }else if(item == "FREE_TEXT") {
            return props.t("Free Text")
        }else {
            return "";
        }
    }

    const getDatatableData = () => {
        const rows = contentTypeList?.filter((row) =>
            row.name?.toLowerCase().includes(search?.toLowerCase())
        );
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Type"),
                    field: "type",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "created",
                    sort: "asc",
                    autoWidth: true,
                },
            ],
            rows: rows?.map((item, key) => ({
                name: <><i  className="uil uil-arrow-circle-up px-3 text-primary font-size-18"  onClick={() => handleOrder("UP", item?.id)}></i>
                    <i className="uil uil-arrow-circle-down px-3 text-primary font-size-18" onClick={() => handleOrder("DOWN", item?.id)}></i>
                    {item.name}</>,
                created: new Date(item.createdAt).toLocaleString(),
                type: getLabel(item.categoryType)
            })),
        };
        return data;
    };

    const handleChooselayout = (id) => {
        let name = "";
        for(var i = 0; i < layoutList.length; i++){
            if(layoutList[i].id == id){
                name = layoutList[i].name;
                break;
            }
        }
        setTabName(name);
    }

    const handleAddTabSave = async () => {
        let url = `/api/tab-template/${params.id}`;
        if(tabLayout != null){
            url += "?layoutId="+tabLayout;
        }
        let response = await put(url, {name: tabName, categoryType: tabType});
        onGetTemplates(params.id);
        tog_standard();
    }

    const handleOrder =  async (type, tabId) => {
        let response = await put(`/api/tab-template/order/${type}?tabTemplateId=${params.id}&&tabId=${tabId}`);
        if (response.status === 200) {
            onGetTemplates(params.id);
            setContentTypeList(response?.data?.tabList);
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={template?.name}
                        breadcrumbs={[{title: props.t("Products"), link: "#"}, {title:props.t("Templates"), link: "/templates"}]}
                    />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={template?.name}
                                        buttons={[{
                                            text: props.t("Add Tab"),
                                            onClick: tog_standard,
                                        }]}
                                        canEdit={handleSave}
                                        canDelete={() => setShowDeleteTemplateModal(true)}
                                    />
                                </CardHeader>

                                <Loader loading={loading} title="Tabs"/>
                                {!loading &&
                                    <CardBody key={date}>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {props.t(API_ERR_MSG)}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            // onSearch={(text) => console.log(text)}
                                            searching={true}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard()
                }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Tab")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">

                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Type")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" value={tabType} onChange={(e) => setTabType(e.target.value)}>
                                <option value="">{props.t("Select")}</option>
                                <option value="DATA">{props.t("Data")}</option>
                                <option value="FILES">{props.t("Files")}</option>
                                <option value="IMAGES">{props.t("Images")}</option>
                                <option value="FREE_TEXT">{props.t("Free Text")}</option>
                            </select>
                        </div>
                    </Row>
                    {tabType == "DATA" && <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Template")}
                        </label>
                        <div className="col-md-9">
                            <select className="form-control" value={tabLayout} onChange={(e) => {setTabLayout(e.target.value); handleChooselayout(e.target.value);}}>
                                <option value="">{props.t("Select")}</option>
                                {layoutList?.map(function (layout, index) {
                                    return (<option key={"cat2-" + layout.id} value={layout.id}>{layout.name}</option>)
                                })}
                            </select>
                        </div>
                    </Row> }
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label">
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setTabName(e.target.value)}
                                placeholder={props.t("Enter field label")}
                                value={tabName}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard()
                        }}
                        className="col-md-2 btn btn-secondary btn-sm waves-effect"
                        data-dismiss="modal">
                        {props.t("Close")}
                    </button>
                    <Button color="primary"
                            onClick={handleAddTabSave}
                            className="col-md-2 btn btn-primary btn-sm waves-effect waves-light m-2">
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={template_modal_standard}
                toggle={() => {
                    tog_template_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Template")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            settemplate_modal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter field label")}
                                value={name}
                            />
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_template_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        onClick={handleSave}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDelete()}
                element={"Template"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteTemplateModal}
                toggleModal={() => toggleDeleteTemplate()}
                confirmation={true}
            />

        </React.Fragment>
    );
};

TemplateDetails.propTypes = {
    template: PropTypes.array,
    onGetTemplates: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        layoutList: state.ecommerce.layoutList,
        template: state.ecommerce.templateDetails,
        error: state.ecommerce.templateDetailsError,
        loading: checkIfLoading(state, GET_TEMPLATE_DETAILS)
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTemplates: (id) => dispatch(getTemplateDetails(id)),
    onGetLayoutList: () => dispatch(getLayoutList()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TemplateDetails)));
