/* INVOICES */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"
export const GET_INVOICES_RESET = "GET_INVOICES_RESET"

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"
export const GET_INVOICE_DETAIL_RESET = "GET_INVOICE_DETAIL_RESET"

export const invoicesActionTypes = {
    GET_INVOICES,
}

export const GET_INVOICE_METRICS = "GET_INVOICE_METRICS"
export const GET_INVOICE_METRICS_SUCCESS = "GET_INVOICE_METRICS_SUCCESS"
export const GET_INVOICE_METRICS_FAIL = "GET_INVOICE_METRICS_FAIL"