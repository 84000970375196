import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

//i18n
import {withTranslation} from "react-i18next";
import SidebarContent from "./SidebarContent";

import logoSm from "../../assets/images/lightab_logo.svg";
import logoDark from "../../assets/images/lightab_logo.svg";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";

const Sidebar = (props) => {
  const user = JSON.parse(localStorage.getItem("authUser"));

  const getBrandLogo = () => {
    if(user.brandConfig && user.brandConfig.brandLogo){
      return BASE_IMAGE_URL +"/"+ user.brandConfig.brandLogo;
    }
    return logoDark;
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{position:"relative", marginTop:"10px"}}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="22" className="img-fluid" />
            </span>
            <span className="logo-lg">
              <img
                src={getBrandLogo()}
                alt=""
                height="20"
                className="img-fluid light-tab-logo-1"
              />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={getBrandLogo()} alt="" height="22" className="img-fluid" />
            </span>
            <span className="logo-lg">
              <img
                src={getBrandLogo()}
                alt=""
                height="20"
                className="img-fluid light-tab-logo"
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
