import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import {getWorkLog, getWorkLogByProject, getWorkLogForCustomer, getWorkLogForSuperAdmin, getWorkLogForUser, getWorkLogReset} from "../../store/task/actions";
import {del, get} from "../../helpers/api_helper";
import WorkLogEdit from "../Tasks/WorkLogEdit";
import {findIndex, orderBy} from "lodash";
import Loader from "../../components/Common/Loader";
import moment from "moment-timezone";


const CustomerTimeslips = (props) => {
    let {customerWorkLog, worklogLoading, workLogByProject, getWorkLogByProject,
        getCustomerWorkLog, getUserWorkLog, showAddTask, worklogs, getWorklogs, 
        workLogForSuperAdmin, workLogForUser, getWorkLogBySuperAdmin, worklogsError, onGetWorkLogReset, filter, match: {params}
    } = props;

    const [showForm, setShowForm] = useState(false);
    const [editWorkLog, setEditWorkLog] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const [level, setLevel] = useState(null);
    const [workLogList, setWorkLogList] = useState(null);
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['id'];
    const user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        console.log("Show Form", showForm);
        setShowForm(false);
        if (localStorage.getItem("authUser")) {
            setBrandSettings(user?.brandConfig);
            setLevel(localStorage.getItem("level"));
            /*if (!user?.tenantId) {
                getWorkLogBySuperAdmin();
            } else if(projectId){
                getWorkLogByProject(projectId)
            } else if(cid){
                getCustomerWorkLog(cid);
            } else if(uid){
                getUserWorkLog(uid);
            } else {
                getWorklogs();
            }*/
        }

        return () => onGetWorkLogReset();
    }, []);

    useEffect(() => {
        /*let timeslips = [];
        if (!user?.tenantId && workLogForSuperAdmin) {
            timeslips = workLogForSuperAdmin;
        } else if(projectId && workLogByProject){
            timeslips = workLogByProject;
        } else if(cid && customerWorkLog){
            timeslips = customerWorkLog;
        } else if(uid && workLogForUser){
            timeslips = workLogForUser;
        } else if(worklogs){
            timeslips = worklogs;
        }*/

        setWorkLogList(worklogs);

        /*if(params.id){
            let selectedWorkLog = worklogs.find(value => value.id == params.id);
            if(selectedWorkLog){
                handleEdit(selectedWorkLog);
            }

        }*/
    }, [workLogForSuperAdmin, workLogByProject, customerWorkLog, workLogForUser, worklogs])

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setWorkLogList(orderBy(workLogList, (o, key) => {
            if(column === 'id') return key + 1;
            if(column === 'timeslipType' || column === 'user') return o[column]?.name
            if(column === 'worklog') return getTimeInMinutes(o);
            if(column === 'subject') return o?.task?.subject;
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
        getWorklogs(filter ? filter : null);
    }, [filter])

    const realodTaskWorkLog = () => {
        /*if (projectId) {
            getWorkLogByProject(projectId)
        } else if (cid) {
            getCustomerWorkLog(cid);
        } else if (!user?.tenantId) {
            getWorkLogBySuperAdmin();
        } else {
            getWorklogs(filter);
        }*/

        getWorklogs(filter);
    }

    const getTime = (workLog) => {
        let value = "";
        if (workLog?.hours) {
            value = workLog.hours;
            value += " ";
            value += workLog.hours > 1 ? props.t("hours") : props.t("hour");
        }
        if (workLog?.minutes) {
            value += " " + workLog.minutes + " "
            value += workLog.minutes > 1 ? props.t("minutes") : props.t("minute");
        }
        return value;
    }

    const getTimeInMinutes = (workLog) => {
        let value = 0;
        if(workLog?.hours){
            value = workLog.hours*60;
        }
        if(workLog?.minutes){
            value += workLog.minutes
        }
        return value;
    }

    const handleDelete = async (workLog) => {
        let response = await del(`/api/timeslip/${workLog.id}`);
        getWorklogs(filter);
    }

    const handleEdit = async (workLog) => {
        let response = await get(`/api/timeslip/${workLog.id}`);
        if(response.status === 200){
            setEditWorkLog(response.data);
            setShowForm(true);
        }
    }

    const handleDuplicate = async (workLog) => {
        let response = await get(`/api/timeslip/${workLog.id}`);
        if(response.status === 200){
            let duplicate = response.data;
            duplicate.id = null;
            setEditWorkLog(duplicate);
            setShowForm(true);
        }
    }

    const handleAdd = () => {
        setShowForm(true);
    }

    const getDatatableData = () => {
        
        let cols = [];
        if (level == "CUSTOMER" || filter?.customerId) {
            cols = [
                {
                    label: props.t("Task Title"),
                    field: "subject",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Description"),
                    field: "description",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Category"),
                    field: "timeslipType",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Time"),
                    field: "timeslip",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("User"),
                    field: "user",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Date"),
                    field: "date",
                    sort: "asc",
                    autoWidth: true,
                }
            ]
        } else {
            cols = [
                {
                    label: props.t("Task Title"),
                    field: "subject",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Description"),
                    field: "description",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Category"),
                    field: "timeslipType",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Time"),
                    field: "timeslip",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Customer"),
                    field: "customer",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("User"),
                    field: "user",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Date"),
                    field: "date",
                    sort: "asc",
                    autoWidth: true,
                },
                {
                    label: props.t("Start Time"),
                    field: "startDate",
                    sort: "asc",
                    autoWidth: true
                },
                {
                    label: props.t("Action"),
                    field: "action",
                    sort: "asc",
                    autoWidth: true
                }
            ]
        }
        let data = {
            columns: cols,
            rows: workLogList?.map((item, key) => ({
                /*subject: item?.task?.subject,
                description: item?.description,
                timeslipType: item?.timeslipType?.name,
                timeslip: item?.time,
                customer: item?.task?.customer?.name,
                user: item?.user?.name,*/
                subject: item?.subject,
                description: item?.description,
                timeslipType: item?.timeslipType,
                timeslip: item?.time,
                customer: item?.customer,
                user: item?.user,
                date: moment(item?.date.substring(0, 19)).format("DD-MM-YYYY"),
                startDate: item?.startDate ? moment.utc(item?.startDate).tz(user?.timezone).format("HH:mm") : '',
                action:
                    <>
                        <i className="uil uil-pen font-size-18" onClick={() => handleEdit(item)}></i>
                        <i className="uil uil-copy font-size-18 ps-2" onClick={() => handleDuplicate(item)}></i>
                        <i className="uil uil-trash-alt font-size-18 text-danger ps-2" onClick={() => handleDelete(item)}></i>
                    </>
            })),
        };
        if (level == "SUPERADMIN") {
            data.columns.splice(4, 1)
        }
        if(filter?.userId){
            let userColumnIndex = findIndex(data.columns, {field: 'user'})
            if(userColumnIndex >=0){
                data.columns.splice(userColumnIndex, 1);
            }
        }
        return data;
    };

    return (
        <>
            <Row>
                <Col className="col-12 article-table">
                    {showAddTask && <>
                        <Row className="mb-3">
                            <Col className="col-12">
                                <div className="d-flex justify-content-end">
                                    <Button
                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                        className="btn btn-primary waves-effect waves-light mx-1 float-end"
                                        onClick={handleAdd}
                                    >{props.t("Add Timeslip")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>}
                    {(showForm && !worklogLoading) && <>
                        <WorkLogEdit 
                            cid={filter?.customerId}
                            hideCustomer={filter?.customerId != null}
                            workLog={editWorkLog} 
                            showForm={showForm} 
                            setShowForm={setShowForm} 
                            taskId={editWorkLog?.task?.id} 
                            userDropdown={null} 
                            realodTaskWorkLog={realodTaskWorkLog}
                            projectId={filter?.projectId}
                        />

                    </>
                    }
                    <Loader loading={worklogLoading} title="Timeslips" />
                    {(!showForm && !worklogLoading) && <>
                        <MDBDataTable
                            responsive
                            searching={true}
                            barReverse={true}
                            hover
                            borderless
                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                            entriesLabel={props.t("Show entries")}
                            infoLabel={[
                                props.t("Showing"),
                                props.t("to"),
                                props.t("of"),
                                props.t("entries"),
                            ]}
                            searchLabel={props.t("Search") + "..."}
                            noRecordsFoundLabel={props.t("No matching records found")}
                            noBottomColumns={true}
                            data={getDatatableData()}
                            entries={50}
                            entriesOptions={[50, 100, 500]}
                            onSearch={(text) => console.log(text)}
                            sortRows={[]}
                            onSort={({column, direction}) => {
                                if(sortState && sortState.column == column){
                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                } else {
                                    setSortState({column, direction});
                                }
                            }}
                        />
                    </>}
                </Col>
            </Row>
        </>
    );
}


CustomerTimeslips.propTypes = {
    worklogList: PropTypes.array,
    worklogLoading: PropTypes.bool,
    getCustomerWorkLog: PropTypes.func,
    workLogByProject: PropTypes.array,
    getWorkLogByProject: PropTypes.func,
    worklogs: PropTypes.array,
    workLogForSuperAdmin: PropTypes.array
};

const mapStateToProps = (state) => {
    return {
        customerWorkLog: state.task.customerWorkLog,
        worklogLoading: state.task.customerWorkLogLoading || state.task.workLogByProjectLoading || state.task.worklogsLoading || state.task.workLogForSuperAdminLoading || state.task.userWorkLogLoading,
        tasks: state.task.tasks,
        workLogByProject: state.task.workLogByProject,
        worklogs: state.task.worklogs,
        worklogsError: state.task.worklogsError,
        workLogForSuperAdmin: state.task.workLogForSuperAdmin,
        workLogForUser: state.task.userWorkLog,
    };
}
const mapDispatchToProps = (dispatch) => ({
    getCustomerWorkLog: (customerId) => dispatch(getWorkLogForCustomer(customerId)),
    getWorkLogByProject: (projectId) => dispatch(getWorkLogByProject(projectId)),
    getWorklogs: (filter) => dispatch(getWorkLog(filter)),
    onGetWorkLogReset: () => dispatch(getWorkLogReset()),
    getWorkLogBySuperAdmin: () => dispatch(getWorkLogForSuperAdmin()),
    getUserWorkLog: (userId) => dispatch(getWorkLogForUser(userId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerTimeslips)));
