import {call, put, takeEvery} from "redux-saga/effects"
import {deleteUser, getRegenerateToken, getTenantAdmins, getTenantCustomers, getTenantDetails, getTenantList} from "../../helpers/fakebackend_helper"
import { API_ERR_MSG } from "../../util/constants"
import { startAction, stopAction } from "../actions"
import {
    deleteUserFail,
    deleteUserSuccess,
    getRegenerateTokenFail,
    getRegenerateTokenSuccess,
    getTenantAdminsFail,
    getTenantAdminsSuccess,
    getTenantCustomersFail,
    getTenantCustomersSuccess,
    getTenantDetailsFail,
    getTenantDetailsSuccess,
    getTenantsFail,
    getTenantsSuccess
} from "./actions"
import {
    DELETE_USER,
    DELETE_USER_SUCCESS,
    GET_TENANT,
    GET_TENANT_ADMIN_USERS,
    GET_TENANT_CUSTOMERS,
    GET_TENANT_DETAILS,
    REGENERATE_TOKEN,
    REGENERATE_TOKEN_SUCCESS,
    RESET_DELETE_USER,
    RESET_REGENERATE_TOKEN
} from "./actionTypes"


function* fetchTenantAdmins({tenantId, filter}) {
    try {
        const response = yield call(getTenantAdmins, tenantId, filter)
        yield put(getTenantAdminsSuccess(response))
    } catch (error) {
        yield put(getTenantAdminsFail(API_ERR_MSG))
    }
}
function* regenerateToken(action) {
    try {
        const response = yield call(getRegenerateToken, action.userId)
        yield put(getRegenerateTokenSuccess(response))
    } catch (err) {
        yield put(getRegenerateTokenFail(err))
    }
}

function* deleteTenantUser(action) {
    try {
        const response = yield call(deleteUser, action.userId)
        yield put(deleteUserSuccess(response))
    } catch (err) {
        yield put(deleteUserFail(err))
    }
}

function* resetRegenerateToken() {
    yield put({
        type: REGENERATE_TOKEN_SUCCESS,
        payload: undefined,
    });
}

function* resetDeleteTenantUser() {
    yield put({
        type: DELETE_USER_SUCCESS,
        payload: undefined
    })
}

function* fetchTenants() {
    try {
        const response = yield call(getTenantList)
        yield put(getTenantsSuccess(response));
    } catch (error) {
        yield put(getTenantsFail(API_ERR_MSG))
    }
}

function* fetchTenantDetails({id, type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getTenantDetails, id);
        yield put(getTenantDetailsSuccess(response))
    } catch (error) {
        yield put(getTenantDetailsFail(API_ERR_MSG));
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchTenantCustomers({tenantId, filter}) {
    try {
        const response = yield call(getTenantCustomers, tenantId, filter);
        yield put(getTenantCustomersSuccess(response))
    } catch (error) {
        yield put(getTenantCustomersFail(API_ERR_MSG));
    }
}

function* tenantSaga() {
    yield takeEvery(GET_TENANT_ADMIN_USERS, fetchTenantAdmins)
    yield takeEvery(REGENERATE_TOKEN, regenerateToken)
    yield takeEvery(DELETE_USER, deleteTenantUser)
    yield takeEvery(RESET_REGENERATE_TOKEN, resetRegenerateToken);
    yield takeEvery(RESET_DELETE_USER, resetDeleteTenantUser);
    yield takeEvery(GET_TENANT, fetchTenants);
    yield takeEvery(GET_TENANT_DETAILS, fetchTenantDetails);
    yield takeEvery(GET_TENANT_CUSTOMERS, fetchTenantCustomers);
}

export default tenantSaga
