import {GET_MANUFACTURER, GET_MANUFACTURER_DETAILS, GET_MANUFACTURER_DETAILS_ERROR, GET_MANUFACTURER_DETAILS_SUCCESS, GET_MANUFACTURER_FAIL, GET_MANUFACTURER_SUCCESS} from "./actionTypes";


const INIT_STATE = {
    manufacturerList: [],
    manufacturerListError: null,
    manufacturerListLoading: false,
    manufacturer: {},
    manufacturerError: null,
    manufacturerLoading: false
}


const Manufacturer = (state = INIT_STATE, action) => {

    switch (action.type) {

        case GET_MANUFACTURER:
            return {
                ...state,
                manufacturerListLoading: true
            }
        case GET_MANUFACTURER_SUCCESS:
            return {
                ...state,
                manufacturerList: action.payload,
                manufacturerListLoading: false
            }
        case GET_MANUFACTURER_FAIL:
            return {
                ...state,
                manufacturerList: [],
                manufacturerListError: action.payload,
                manufacturerListLoading: false
            }
        case GET_MANUFACTURER_DETAILS:
            return {
                ...state,
                manufacturer: {},
                manufacturerLoading: true,
                manufacturerError: null
            }
        case GET_MANUFACTURER_DETAILS_SUCCESS:
            return {
                ...state,
                manufacturer: action.payload ? action.payload : {},
                manufacturerLoading: false,
                manufacturerError: null
            }
        case GET_MANUFACTURER_DETAILS_ERROR:
            return {
                ...state,
                manufacturer: {},
                manufacturerLoading: false,
                manufacturerError: action.payload
            }
        default:
            return state
    }
}
export default Manufacturer;
