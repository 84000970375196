import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import store from "../../store";
import {Modal} from "reactstrap";
import {TENANT_ACTIVATE_USER, TENANT_ADMIN_DELETE_USER, TENANT_ADMIN_REGENERATE_TOKEN} from "../../store/users/tenant/actionTypes";
import {customerUserManagementContext} from "./CustomerUserManagement";
import DeleteModal from "../../components/Common/DeleteModal";
import OverridePwdModal from "../../components/Common/OverridePwdModal";

const CustomerUsersActions = (props) => {
    var userId = React.useContext(customerUserManagementContext);
    const {setEditMode, setSelectedCustomerUser, user} = props;
    
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);

    const handleRegenerateClick = () =>
        store.dispatch({ type: TENANT_ADMIN_REGENERATE_TOKEN, userId });

    const handleDeleteUser = () => {
        store.dispatch({ type: TENANT_ADMIN_DELETE_USER, userId })
        tog_standard()
    }

    const handleActiveUser = async () => {
        store.dispatch({type:TENANT_ACTIVATE_USER, userId: userId});
    }

    const [modal_standard, setmodal_standard] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <React.Fragment>
            {user.role != "ROLE_CONTACT_USER" && <>
                <label className="link" onClick={handleRegenerateClick}>
                    {props.t("Send Activation Email")}
                </label>{" "}
            |{" "}</>
            }
            <label className="link" onClick={() => {
                setEditMode(true);
                setSelectedCustomerUser(userId)
            }}>
                {props.t("Edit")}
            </label>{" "}
            |{" "}
            {user.enabled == true  && <label className="link" onClick={tog_standard}>
                {props.t("De-activate")}
            </label>}
            {user.enabled == false  && <label className="link" onClick={handleActiveUser}>
                {props.t("Activate")}
            </label>}{" "}
            |{" "}
            <label className="link" onClick={togglePwdModal}>
                {props.t("Override Password")}
            </label>
            <DeleteModal
                deleteCallback={() => handleDeleteUser()}
                element={"User"}
                t={(text) => props.t(text)}
                showDeleteModal={modal_standard}
                toggleModal={() => tog_standard()}
            />
            <OverridePwdModal
                t={(text) => props.t(text)}
                showOverridePwdModal={showOverridePwdModal}
                toggleModal={() => togglePwdModal()}
                userId={userId}
            />
        </React.Fragment>
    );
};

export default withTranslation()(CustomerUsersActions);
