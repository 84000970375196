import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next"
import {Button, Card, CardBody, Col, Container, Row, Table} from "reactstrap";
import {CartService} from "./CartService";
import PropTypes from "prop-types";
import {getProjectList} from "../../store/project/actions";
import {connect, useDispatch} from "react-redux";
import {Link, useHistory, withRouter} from "react-router-dom";
import {post, put} from "../../helpers/api_helper";
import {getAllCartItems, putUpdateCartItemFromInput, resetCartMessage} from "../../store/e-commerce/cart/actions";
import {addOffer, addOfferReset, getPriceList} from "../../store/actions";
import {find, isEmpty} from "lodash-es";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import articlePlaceholderImg from "../../assets/images/product/article-placeholder.png";
import DeleteModal from "../../components/Common/DeleteModal";
import {offersActionTypes} from "../../store/offer/actionTypes";
import {checkIfLoading} from "../../store/selector";
import {isStockable} from "../../util/articleUtil";
import {evaluate} from "mathjs";
import {debounce, preventNumberInputScroll} from "../../util/helperFunctions";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";

const ShoppingCart = (props) => {
    const {projects, onGetProjects, cartItems, createOfferLoading, onCreateOffer, offer, onCreateOfferReset, 
        cartItemsLoading, cartItemError, onResetCartMessage} = props
    const [projectList, setProjectList] = useState([])

    const [stateVersion, setStateVersion] = useState(0);
    const [cartTotal, setCartTotal] = useState(0)
    const [selectedProject, setSelectedProject] = useState("0")

    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [tax, setTax] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [showRemoveArticleFromCartModal, setShowRemoveArticleFromCartModal] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const priceListCode = user?.config?.priceList;
    let qtyClickCount = 0, qtyPrevClickCount = 0;
    const [articleQuantityMap, setArticleQuantityMap] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setBrandSettings(user.brandConfig);
        return () => {
            onCreateOfferReset();
        }
    }, [])

    let history = useHistory();

    useEffect(() => {
        onGetProjects()
    }, [])

    useEffect(() => {
        setProjectList(projects)
    }, [projects])

    useEffect(() => {
        if (cartItems) {
            setCartTotal(
                Object
                    .keys(cartItems)
                    .reduce(
                        (a, b) => a + (getArticlePriceFromCart(cartItems[b]) * cartItems[b].quantity || 0), 0
                    ))
        }
        if (cartItems && !isEmpty(cartItems)) {
            computeOrderData(cartItems);
        }

        setArticleQuantityMap(cartItems.map(item => ({ articleId: item.article.id, quantity: item.quantity })));
    }, [cartItems, stateVersion])

    useEffect(() => {
        if (offer) {
            history.push(`/customer/offers/${offer.Offer.DocumentNumber}/types/all`)
        }
    }, [offer])

    useEffect(() => {
      if(cartItemError){
        cogoToast.error(cartItemError, toastOptions)
        setTimeout(() => {
          onResetCartMessage();
        }, 2000)
      }
    }, [cartItemError])

    function toggleRemoveArticleFromCartModal() {
        setShowRemoveArticleFromCartModal(!showRemoveArticleFromCartModal);
    }

    const getArticlePriceFromCart = (cartItem) => {
        const article = cartItem.article;
        let price = article.specialPrice ? article.specialPrice : article.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : cartItem?.quantity});
            price = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    price = priceList?.price;
                }
            }
        }
        return price;
    }

    const getArticlePriceFromCartForQuantity = (cartItem) => {
        const article = cartItem.article;
        let price = article.specialPrice ? article.specialPrice : article.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : cartItem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    mPrice = priceList?.price;
                }
            }
            return mPrice;
        }
        return price * cartItem.quantity;
    }

    const placeOrder = () => {
        let orderDto = {
            Project: selectedProject == "0" ? null : selectedProject,
            OrderRows: Object.keys(cartItems).map(key => ({
                'ArticleNumber': cartItems[key].article.articleNumber,
                'Description': cartItems[key].article.description,
                'OrderedQuantity': cartItems[key].quantity,
                'Price': getArticlePriceFromCart(cartItems[key]),
                'Project': selectedProject == "0" ? null : selectedProject
            }))
        }

        post('/api/orders', orderDto)
            .then(data => {
                if (data?.status !== 200) {
                    //setError([props.t("There was an error. Please contact admin.")])
                } else {
                    setCartTotal(0)
                    // setCartData({})
                    setStateVersion(0)
                    setSelectedProject("0")
                    history.push('/categories')
                }
            })
    }

    const getArticlePrice = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : orderitem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    return priceList?.price;
                }
            }
            return mPrice;
        }
        return price ? price : 0;
        //return price ? price * orderitem?.quantity : 0;
    }

    const getArticlePriceWithQuantity = (orderitem) => {
        const article = orderitem?.article;
        const price = article?.specialPrice ? article?.specialPrice : article?.salesPrice;
        if(article.articlePackageType === "MANUAL"){
            let articlePackage = find(article.articlePackage, {quantity : orderitem?.quantity});
            let mPrice = articlePackage?.price;
            if(articlePackage?.articlePackagePriceLists?.length > 0){
                let priceList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceListCode);
                if(priceList && priceList?.price){
                    return priceList?.price;
                }
            }
            return mPrice;
        }
        return price ? price * orderitem?.quantity : 0;
    }

    function computeOrderData(orderItems) {
        let ordersTotal = 0;
        let ordersSubtotal = 0;
        let ordersTax = 0;
        let ordersDiscount = 0;

        orderItems.forEach(orderitem => {
            const orderItemPrice = getArticlePriceWithQuantity(orderitem);
            ordersSubtotal += orderItemPrice;
            ordersTax += (orderItemPrice * orderitem.article.vat) / 100;
        })

        ordersTotal = ordersSubtotal + ordersTax - ordersDiscount;

        setSubTotal(ordersSubtotal);
        setDiscount(ordersDiscount);
        setTax(ordersTax);
        setTotal(ordersTotal);
    }

    const handleRemoveItemFromCart = () => {
        CartService.removeItemToCart(itemToRemove, true);
        setItemToRemove(null);
        setStateVersion(stateVersion + 1);
    }

    const parseExpression = (oprand1, operator, oprand2) => {
        operator = operator == "x" ? "*" : "/";
        return Math.round(evaluate(oprand1 + " " +  operator + " " + oprand2));
    }

    const handleQuantityChange = (key, decrementQuantity) => {
      qtyPrevClickCount = qtyClickCount;
      qtyClickCount++;
      setTimeout(() => {
        // check if input is clicked multiple times or just one time in 1sec timespan
        if((qtyClickCount != 0 && qtyPrevClickCount == qtyClickCount) || (qtyClickCount == 1 && qtyPrevClickCount == 0)){
          const articleQty = getQuantityVaue(key);
          let inputQty = 0;
          if(decrementQuantity){
            // quantity cannot go below 0
            if(articleQty - qtyClickCount > 0){
              inputQty = articleQty - qtyClickCount; 
            }
          } else {
            inputQty = articleQty + qtyClickCount;
          }
          CartService.addItemsToCart(cartItems[key].article, inputQty);
          setStateVersion(stateVersion + 1);
          setArticleQuantityMap(articleQuantityMap.map(item => ({
              ...item, 
              quantity: cartItems[key].article.id == item.articleId ? inputQty : item.quantity
          })));
  
          // reset counts
          qtyClickCount = 0;
          qtyPrevClickCount = 0;
        } else {
          // if all the clicks are done and 1 sec passed then set prev click count to current to execute the above if condition
          qtyPrevClickCount = qtyClickCount;
        }
      }, 1000)
    }

    const processChange = debounce((arg) => {
        const {quantity, product} = arg;  
        const cartItem = find(cartItems, item => item.article.id == product.id);
        if(cartItem){
            setArticleQuantityMap(articleQuantityMap.map(item => ({
                ...item, 
                quantity: product.id == item.articleId ? quantity : item.quantity
            })));
            put(`/api/cart`, {id: cartItem.id, article: {id: product.id}, quantity});
            dispatch(putUpdateCartItemFromInput({...cartItem, quantity}));
            setStateVersion(stateVersion + 1);
        }
    });

    const getQuantityVaue = (key) => {
        const item = find(articleQuantityMap, {articleId: cartItems[key].article.id});
        return item && item.quantity > 0 ? item.quantity : "";
    }

    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Link to="/customer/categories" className="btn btn-link text-muted">
                            <i className="uil uil-arrow-left me-1"></i> {props.t("Continue Shopping")}
                        </Link>
                    </Col>
                    <Col>
                        <div className="text-sm-end mt-2 mt-sm-0">
                            {user?.roles?.includes("ROLE_ACTING_USER") &&
                                <Button
                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                    color="primary"
                                    className="mx-1"
                                    onClick={() => onCreateOffer()}
                                    disabled={isEmpty(cartItems) || createOfferLoading}
                                >
                                    {createOfferLoading ? props.t("Creating Offer...") : props.t("Create Offer")}
                                </Button>}
                            <Button className="btn btn-success"
                                    onClick={() => window.location.href = '/shop/checkout'}
                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                    disabled={isEmpty(cartItems)}>
                                <i className="uil uil-shopping-cart-alt me-1"></i> {props.t("Checkout")} </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl="8">
                        {cartItems && Object.keys(cartItems).map((key, index) => (
                            <Card className="border mb-1" key={index}>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="me-3">
                                            <img
                                                src={cartItems[key].article.articleImages
                                                && cartItems[key].article.articleImages.length > 0 ?
                                                    BASE_IMAGE_URL + "/" + cartItems[key].article.articleImages[0].media.s3Key
                                                    : articlePlaceholderImg}
                                                alt="product-img"
                                                title="product-img"
                                                className="avatar-lg"/>
                                        </div>
                                        <div className="flex-1 align-self-center overflow-hidden">
                                            <div className="d-flex">
                                                <h5 className="text-truncate font-size-16 mb-2">
                                                    <Link
                                                        to={`/customer/articles/${cartItems[key].article.id}`}
                                                        className="text-dark"
                                                    >
                                                        {cartItems[key].article.displayName ? cartItems[key].article.displayName : cartItems[key].article.description}
                                                    </Link>
                                                </h5>
                                                {/*{cartItems[key].article.articlePackageType == "MANUAL" &&
                                                <div className="product-ribbon badge bg-soft-success bg-success mb-2 ms-2 px-2 d-flex align-items-center justify-content-center">
                                                    {find(cartItems[key].article.articlePackage, {quantity: cartItems[key].quantity})?.name}
                                                </div>}*/}
                                            </div>
                                            <div className="row d-flex">

                                                <div className="col-md-3">
                                                    {cartItems[key].article.articlePackageType != "MANUAL" &&
                                                        <div className="mt-2 d-flex">
                                                            <p className="text-muted mb-1">{props.t("Price")}:&ensp;</p>
                                                            <p className="font-weight-bold"><strong>{getArticlePriceFromCart(cartItems[key])}</strong></p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="d-flex justify-content-around">

                                                        {cartItems[key].article.articlePackageType == "MANUAL" ? <>
                                                        <p className="text-muted mb-1 mt-2">{props.t("Select Package")}:&ensp;</p>
                                                        <div className="d-flex">
                                                            <select
                                                                className="form-control"
                                                                value={cartItems[key].quantity ? cartItems[key].quantity : 0}
                                                                onChange={(e) => {
                                                                    CartService.addItemsToCart(cartItems[key].article, e.target.value ? e.target.value : 0);
                                                                    setStateVersion(stateVersion + 1)}}>
                                                                <option value={0}>{props.t("Add to cart")}</option>
                                                                {cartItems[key].article.articlePackage.map(pkg => <option value={pkg.quantity}>{pkg.name}</option>)}
                                                            </select>
                                                        </div></> : <>
                                                        <p className="text-muted mb-1 mt-2">{props.t("Quantity")}:&ensp;</p>
                                                        <div className="d-flex">
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn-block waves-effect waves-light btn-sm"
                                                                style={{
                                                                    borderTopRightRadius: "0",
                                                                    borderBottomRightRadius: "0",
                                                                    backgroundColor: brandSettings?.primaryBtnColor,
                                                                    borderColor: brandSettings?.primaryBtnColor
                                                                }}
                                                                onClick={(e) => handleQuantityChange(key, true)}
                                                                disabled={cartItemsLoading}
                                                            ><i className="uil uil-minus"></i></Button>
                                                            <input
                                                                className="form-control text-align-center"
                                                                style={{borderRadius: "0", width: "90px"}}
                                                                type="number"
                                                                defaultValue={getQuantityVaue(key)}
                                                                // value={getQuantityVaue(key)}
                                                                onChange={(e) => processChange({quantity: parseInt(e.target.value), product: cartItems[key].article})}
                                                                onWheel={preventNumberInputScroll}
                                                                // onKeyDown={validateNumber}
                                                                disabled={cartItemsLoading}
                                                                key={cartItemsLoading}
                                                            />
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn-block waves-effect waves-light btn-sm"
                                                                style={{
                                                                    borderTopLeftRadius: "0",
                                                                    borderBottomLeftRadius: "0",
                                                                    backgroundColor: brandSettings?.primaryBtnColor,
                                                                    borderColor: brandSettings?.primaryBtnColor
                                                                }}
                                                                onClick={e => handleQuantityChange(key, false)}
                                                                disabled={cartItemsLoading}
                                                            ><i className="uil uil-plus"></i></Button>
                                                        </div></>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mt-2 d-flex justify-content-end">
                                                        <p className="text-muted mb-1">{props.t("Total")}:&ensp;</p>
                                                        <p><strong>{getArticlePriceFromCartForQuantity(cartItems[key])}</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            <ul className="list-inline mb-0 font-size-16">
                                                <li className="list-inline-item">
                                                    <Button
                                                        type="button"
                                                        color=""
                                                        className="btn-block waves-effect waves-light btn-sm"
                                                        onClick={() => {
                                                            const item = cartItems[key];
                                                            setItemToRemove({id: item.id, article: {id: item.article.id}, quantity: item.quantity});
                                                            toggleRemoveArticleFromCartModal();
                                                        }}>
                                                        <i className="uil uil-trash-alt text-danger font-size-16 cursor-pointer"></i>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {isStockable(cartItems[key].article) && cartItems[key].article.disposableQuantity <= 0  && cartItems[key].quantity > 0 &&
                                        <div className="d-flex justify-content-center">
                                    <span className="alert-danger border border-danger font-size-11 rounded p-1">
                                        {props.t("Out of Stock")}
                                    </span>
                                        </div>}
                                    {isStockable(cartItems[key].article) && cartItems[key].article.disposableQuantity > 0 && cartItems[key].article.disposableQuantity < cartItems[key].quantity &&
                                        <div className="d-flex justify-content-center">
                                    <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                            {props.t("Up to")} {parseInt(cartItems[key].article.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                    </span>
                                        </div>}
                                    {cartItems[key].article.articlePackageType == "MANUAL" &&
                                    <ol style={{marginLeft: "40px", fontSize: "12px"}}>
                                        {find(cartItems[key].article.articlePackage, {quantity: cartItems[key].quantity})?.packageItems?.map(function (i) {
                                            return (<li><label style={{width: "500px"}}>{i?.articleDto?.description} &nbsp;&nbsp;
                                                        {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity <= 0  && i?.quantity > 0 &&
                                                            <span className="alert-danger border border-danger font-size-11 rounded p-1">{props.t("Out of Stock")}</span>}
                                                        {isStockable(i?.articleDto) && i?.articleDto?.disposableQuantity > 0 && i?.articleDto?.disposableQuantity < i?.quantity &&
                                                            <span className="alert-warning border border-warning font-size-11 rounded p-1">
                                                                {props.t("Up to")} {parseInt(i?.articleDto?.disposableQuantity)} {props.t("item(s) is available in stock for delivery")}
                                                            </span>
                                                        }</label> {i?.quantity ? <label>{props.t("Qty")} - {i?.quantity}</label> : ''}
                                                    </li>)})}
                                    </ol>}
                                    {cartItems[key].article.articlePackageType == "DYNAMIC" &&
                                        <ol style={{marginLeft: "40px"}}>
                                            {cartItems[key].article.articlePackage[0]?.packageItems?.map(function (i) {
                                                return (<li><label style={{width: "500px"}}>{i?.articleDto?.description}</label> <label>{props.t("Qty")} - {parseExpression(cartItems[key].quantity , i?.operator, i?.quantity)}</label> </li>)
                                            })}
                                        </ol>}
                                </CardBody>
                            </Card>
                        ))}
                    </Col>
                    <Col xl="4">
                        {cartItems && !isEmpty(cartItems) &&
                            <div className="mt-5 mt-lg-0">
                                <Card className="border">
                                    <div className="card-header bg-transparent border-bottom py-3 px-4">
                                        <h5 className="font-size-16 mb-0">{props.t("Order Summary")}</h5>
                                    </div>
                                    <CardBody className="p-4">
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <tbody>
                                                <tr>
                                                    <td>{props.t("Sub Total")} :</td>
                                                    <td className="text-end">{subTotal} kr</td>
                                                </tr>
                                                {discount > 0 && <tr>
                                                    <td>
                                                        {props.t("Discount")} :
                                                    </td>
                                                    <td className="text-end">
                                                        - {discount} kr
                                                    </td>
                                                </tr>}
                                                <tr>
                                                    <td>{props.t("VAT")} :</td>
                                                    <td className="text-end">{tax} kr</td>
                                                </tr>
                                                <tr>
                                                    <td>{props.t("Total")} :</td>
                                                    <td className="text-end">{total} kr</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>}
                    </Col>
                </Row>
            </Container>
        </div>
        <DeleteModal
            deleteCallback={() => handleRemoveItemFromCart()}
            element={"Article"}
            t={(text) => props.t(text)}
            showDeleteModal={showRemoveArticleFromCartModal}
            toggleModal={() => toggleRemoveArticleFromCartModal()}
            alertMsg={props.t("Are you sure you want to remove this item from cart?")}
            alertTitle={props.t("Remove Item")}
        />
    </React.Fragment>

}

ShoppingCart.propTypes = {
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
    onGetCartItems: PropTypes.func,
    onGetPriceList: PropTypes.func,
    priceList: PropTypes.array,
    cartItemError: PropTypes.object,
    onCreateOffer: PropTypes.func,
    createOfferLoading: PropTypes.bool,
    createOfferError: PropTypes.object,
}

const mapStateToProps = state => {
    return {
        projects: state.Project.projects?.Projects,
        cartItems: state.Cart?.cartItems,
        cartItemError: state.Cart?.cartItemError,
        priceList: state.ecommerce.priceList,
        createOfferLoading: checkIfLoading(state, offersActionTypes.ADD_OFFER),
        createOfferError: state.Offers.addOfferError,
        offer: state.Offers.offer,
        cartItemsLoading: state.Cart?.cartItemsLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetProjects: () => dispatch(getProjectList()),
    onGetCartItems: () => dispatch(getAllCartItems()),
    onGetPriceList: (articleId, fromQuantity) => dispatch(getPriceList(articleId, fromQuantity)),
    onCreateOffer: () => dispatch(addOffer()),
    onCreateOfferReset: () => dispatch(addOfferReset()),
    onResetCartMessage: () => dispatch(resetCartMessage()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ShoppingCart)))
