import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import {del, get, post} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import TableToolbar from "../../components/Common/TableToolbar";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {getTask, getTaskCategories, getTaskList, getTaskPriorities, getTaskReset, getTaskWorkLog} from "../../store/task/actions";
import Comments from "./Comments";
import DatePicker from "react-date-picker";
import {getDateObject, parseDateToString, preventNumberInputScroll} from "../../util/helperFunctions";
import {GetCustomers, GetCustomerUsers, GetCustomerUsersReset, getProducts, getProductsReset, getProjectList, getProjectListReset} from "../../store/actions";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {find} from "lodash";
import DropdownSelect from "../../components/Common/DropdownSelect";
import {getTenantAdmins, getTenantAdminsReset, getTenantCustomers, getTenants} from "../../store/tenant/actions";
import EventManagement from "../Audit/EventManagement";
import WorkLog from "./WorkLog";

const TaskList = (props) => {
    const {
        task,
        tasks,
        loading,
        getTaskError,
        onGetTask,
        onGetTaskReset,
        workLog,
        onGetTasks,
        tenantOptions,
        tenantCustomerOptions,
        tenantCustomersLoading,
        customerOptions,
        productOptions,
        projectOptions,
        customerUserOptions, 
        customerUsersLoading, 
        tenantUserOptions, 
        taskCategories,
        taskPriorities,
        tenantUsersLoading,
        onGetTenantOptions,
        onGetCustomerOptions,
        onGetProductOptions,
        onGetProductsReset,
        onGetProjectOptions,
        onGetProjectsReset,
        onGetTenantUsers, 
        onGetTenantUsersReset,
        onGetCustomerUsers,
        onGetCustomerUsersReset,
        onGetTenantCustomers,
        onGetTaskWorkLog,
        loadingWorkLog,
        onGetTaskCategories,
        onGetTaskPriorities,
        match: {params},
    } = props;

    const [errorMessage, setErrorMessage] = useState("");
    const [brandSettings, setBrandSettings] = useState(null);
    
    const [editTask, setEditTask] = useState(null);
    const [editDisabled, setEditDisabled] = useState(true);

    const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [priority, setPriority] = useState(null);
    const [category, setCategory] = useState('SUPPORT');
    const [status, setStatus] = useState('TODO');
    const [dueDate, setDueDate] = useState(null);
    const [estimate, setEstimage] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [reporters, setReporters] = useState([]);
    const [assigned, setAssigned] = useState(null);
    const [article, setArticle] = useState(null);
    const [project, setProject] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [assignedOptions, setAssignedOptions] = useState([]);
    const [reporterOptions, setReporterOptions] = useState([]);
    const [taskCustomerOptions, setTaskCustomerOptions] = useState([]);
    const [taskType, setTaskType] = useState(null);
    const [hiddenToCustomer, setHiddenToCustomer] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);

    const [higherLevelAdminUser, setHigherLevelAdminUser] = useState(null);
    const [superAdmin, setSuperAdmin] = useState(null);
    const [showHiddenToCustomerCheck, setShpwHiddenToCustomerCheck] = useState(false);

    const history = useHistory();

    const user = JSON.parse(localStorage.getItem("authUser"));

    const SUPER_ADMIN_VIEW = 'SUPER_ADMIN_VIEW';
    const TENANT_VIEW = 'TENANT_VIEW';
    const CUSTOMER_VIEW = 'CUSTOMER_VIEW';

    const INTERNAL = 'INTERNAL';
    const ESCALATED = 'ESCALATED';
    const CUSTOMER = 'CUSTOMER';
    const TENANT = 'TENANT';

    const [view, setView] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [showEditViewInitially, setShowEditViewInitially] = useState(false);

    let STATUS_LIST = [{value : "TODO", label : props.t("TO DO")},
        {value : "IN_PROGRESS", label: props.t("IN PROGRESS")},
        {value: "REVIEW", label: props.t("REVIEW")},
        {value: "DONE", label: props.t("DONE")}]
    
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        onGetTaskCategories();
        onGetTaskPriorities();
        onGetTask(params.id);
        initializeView();
        onGetTasks({});
        if(window.location.pathname.endsWith('/edit')){
            setShowEditViewInitially(true);
        }
        return () => {
            onGetTaskReset();
        }
    }, [])
    
    useEffect(() => {
        initializeTaskData();
    }, [task]);
    
    useEffect(() => {
        if (getTaskError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetTaskReset()
            }, 5000);
        }
    }, [getTaskError])

    useEffect(async () => {
        if(view === 'CUSTOMER_VIEW'){
            let tenantAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_TENANT_ADMIN');
            if (tenantAdminUser) {
                tenantAdminUser['name'] = props.t('Tenant');
                setHigherLevelAdminUser(tenantAdminUser);
            }
            setCustomerId(user?.customerId);
            // onGetProjectOptions(null, user?.customerId);
            // onGetProductOptions(user?.tenantId, user?.customerId);
        } else if(view === 'TENANT_VIEW'){
            let superAdminUser = await getHigherLevelAdminUsersByRole(user?.tenantId, 'ROLE_SUPER_ADMIN');
            if (superAdminUser) {
                superAdminUser['name'] = props.t('Super Admin');
                setHigherLevelAdminUser(superAdminUser);
            }
            setTenantId(user?.tenantId);
            onGetCustomerOptions();
            // onGetProjectOptions(user?.tenantId, null);
            // onGetProductOptions(user?.tenantId, null);
        } else if(view === 'SUPER_ADMIN_VIEW'){
            let superAdminUser = await getHigherLevelAdminUsersByRole(null, 'ROLE_SUPER_ADMIN');
            if(superAdminUser){
                setSuperAdmin(superAdminUser);
            }
            onGetTenantOptions();
        }
    }, [view]);

    useEffect(() => {
        if(tenantId){
            onGetTenantUsers(tenantId);
            if(view === SUPER_ADMIN_VIEW){
                onGetTenantCustomers(tenantId);
                if(!customerId){
                    onGetProjectOptions(tenantId, null);
                    onGetProductOptions(tenantId, null);
                }
            }
        }
    }, [tenantId]);

    useEffect(() => {
        if(customerId){
            onGetCustomerUsers(customerId);
            onGetProjectOptions(tenantId, customerId);
            onGetProductOptions(tenantId, customerId);
        } else if (tenantId){
            onGetProjectOptions(tenantId, null);
            onGetProductOptions(tenantId, null);
        }
    }, [customerId]);

    useEffect(() => {
        if(customerUserOptions && tenantUserOptions){
            if(view === CUSTOMER_VIEW){
                setReporterOptions(customerUserOptions);
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : [...customerUserOptions]
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === TENANT_VIEW){
                setReporterOptions([].concat.apply([], (customerId ? [...tenantUserOptions, ...customerUserOptions] : [...tenantUserOptions])));
                const aos = taskType === ESCALATED ? (higherLevelAdminUser ? [higherLevelAdminUser] : []) : (taskType === CUSTOMER ? [...tenantUserOptions, ...customerUserOptions] : [...tenantUserOptions])
                setAssignedOptions([].concat.apply([], aos));
            } else if(view === SUPER_ADMIN_VIEW){
                const superAdminInArr = superAdmin ? [superAdmin] : [];
                const ros = taskType === INTERNAL ? (superAdmin ? [superAdmin] : []) : (customerId ? [...superAdminInArr, ...tenantUserOptions, ...customerUserOptions] : [...superAdminInArr, ...tenantUserOptions])
                setReporterOptions([].concat.apply([], ros));

                const aos = taskType === INTERNAL ? (superAdmin ? [superAdmin] : []) : [...tenantUserOptions]
                setAssignedOptions([].concat.apply([], aos));
            }
        }
    }, [customerUserOptions, tenantUserOptions, taskType, superAdmin]);

    useEffect(() => {
        setTaskCustomerOptions(view === SUPER_ADMIN_VIEW ? tenantCustomerOptions : customerOptions)
    }, [customerOptions, tenantCustomerOptions])

    useEffect(() => {
        setProject(find(projectOptions, { id: task?.projectId }));
    }, [projectOptions]);

    useEffect(() => {
        setArticle(find(productOptions, { id: task?.articleId }));
    }, [productOptions]);

    useEffect(() => {
        if(!editDisabled){
            if(view === CUSTOMER_VIEW){
                const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
                setAssigned(defAssignee);
                setReporters([]);
            }
            else if(view === TENANT_VIEW){
                const defAssignee = taskType === ESCALATED && higherLevelAdminUser ? higherLevelAdminUser : null;
                setAssigned(defAssignee);
                setCustomerId(null);
                setReporters([]);
            }
            else if(view === SUPER_ADMIN_VIEW) {
                const defAssignee = taskType === INTERNAL && superAdmin ? superAdmin : null;
                const defReporters = taskType === INTERNAL && superAdmin ? [superAdmin] : [];
                setAssigned(defAssignee);
                setReporters(defReporters);
            }
        }
    }, [taskType]);

    useEffect(() => {
        if(taskCategories && task){
            const taskCategory = find(taskCategories, {id: task.taskDetails.category?.id})
            if(taskCategory){
                setCategory(taskCategory.id)
            }
        }
    }, [taskCategories, task])

    useEffect(() => {
        if(taskPriorities && task){
            const taskPriority = find(taskPriorities, {id: task.taskDetails.priority?.id})
            if(taskPriority){
                setPriority(taskPriority.id)
            }
        }
    }, [taskPriorities, task])

    useEffect(() => {
        if(taskPriorities && priority){
            const taskPriorityDetails = find(taskPriorities, {id: parseInt(priority)})
            if(taskPriorityDetails){
                if(taskPriorityDetails.dueDays){
                    let dueDateUpdate = new Date();
                    dueDateUpdate.setDate(dueDateUpdate.getDate() + taskPriorityDetails.dueDays);
                    setDueDate(dueDateUpdate);
                } else {
                    setDueDate(null);
                }
            }
        }
    }, [priority, taskPriorities]);

    useEffect(() => {
        if((taskType == CUSTOMER || (taskType == TENANT && customerId)) && 
            (assigned && 
                (assigned.customer || assigned.role == "ROLE_CUSTOMER_USER" || assigned.role == "ROLE_CUSTOMER_ADMIN") && 
                reporters.filter(r => r.customer || r.role == "ROLE_CUSTOMER_USER" || r.role == "ROLE_CUSTOMER_ADMIN").length == reporters.length)){
            setShpwHiddenToCustomerCheck(true);
        } else {
            setHiddenToCustomer(false);
            setShpwHiddenToCustomerCheck(false);
        }
    }, [taskType, customerId, assigned, reporters])

    function initializeView(){
        if(!user?.customerId && !user?.tenantId){
            setView(SUPER_ADMIN_VIEW);
        } else if(user?.customerId){
            setView(CUSTOMER_VIEW);
        } else if(user?.tenantId){
            setView(TENANT_VIEW);
        }
    }

    const initializeTaskData = () => {
        if(task){
            setEditTask(task);
            // customer view task assignee can have higher level admin user different from higherLevelAdminUser variable.
            // this is becuase we can create customer task from tenant view and set assignee as any tenant user.
            let customerEscalatedTask = false;
            if(view === CUSTOMER_VIEW && task.taskDetails.assigned){
                const roles = task.taskDetails.assigned.roles;
                customerEscalatedTask = roles.length > 0 
                    && !(roles[0].name == 'ROLE_CUSTOMER_ADMIN' || roles[0].name == 'ROLE_CUSTOMER_USER' || roles[0].name == 'ROLE_CONTACT_USER');
            }
            if(task.internal && !(view === TENANT_VIEW && task.taskDetails.customer)){
                setTaskType(INTERNAL);
            }
            else if((higherLevelAdminUser && higherLevelAdminUser?.id == task.taskDetails.assigned?.id) || customerEscalatedTask){
                setTaskType(ESCALATED);
            } 
            else {
                if(view === TENANT_VIEW && task.taskDetails.customer){
                    setTaskType(CUSTOMER);
                }
                else if(view === SUPER_ADMIN_VIEW && task.tenantId){
                    setTaskType(TENANT);
                }
            }
            setSubject(task.taskDetails.subject);
            setDescription(task.taskDetails.description);
            setPriority(task.taskDetails.priority?.id);
            setCategory(task.taskDetails.category?.id);
            setStatus(task.taskDetails.status);
            setCustomerId(task.taskDetails.customer?.id);
            setReporters(task.taskDetails.reporters.map(r => r.reporter));
            if(task.taskDetails.assigned && customerEscalatedTask){
                task.taskDetails.assigned['name'] = props.t('Tenant');
            }
            setAssigned(task.taskDetails.assigned);
            setDueDate(task.taskDetails.dueDate ? new Date(task.taskDetails.dueDate) : null);
            /*setArticle(find(productOptions, { id: task.articleId }));
            setProject(find(projectOptions, { id: task.projectId }));*/
            setTenantId(task.tenantId);   
            setHiddenToCustomer(task.taskDetails.hiddenToCustomer);
        }
    }

    const getHigherLevelAdminUsersByRole = async (tenantId, roleName) => {
        try {
            const response = await get(`/api/user/adminUserByRole?roleName=${roleName}${tenantId ? '&tenantId=' + tenantId : ''}`);
            if(response.status === 200) {
                return response.data;
            }
        } catch (err) {
        }
        return null;
    };

    function toggleDeleteTask() {
        setShowDeleteTaskModal(!showDeleteTaskModal);
    }

    const handleSaveTask = async () => {
        let response = await post(`/api/task`, {
            taskDetails: {
                id: isDuplicate ? null : editTask.taskDetails.id,
                subject,
                description,
                priority: priority ? find(taskPriorities, {id: parseInt(priority)}) : null,
                category: category ? find(taskCategories, {id: parseInt(category)}) : null,
                status: status ? status : 'TODO',
                dueDate,
                customer: customerId ? {id: customerId} : null,
                reporters: reporters.map(r => ({reporter: r})),
                assigned: assigned ? {id: assigned?.id} : null,
                estimate: estimate,
                hiddenToCustomer
            },
            articleId: article ? article?.id : null,
            projectId: project ? project?.id : null,
            tenantId: tenantId,
        });
        setIsDuplicate(false);
        if(response?.status === 200){
            clearModalData();
            onGetTask(response?.data.taskDetails?.id)
            initializeView();
        }
    };

    const handleDeleteTask = async () => {
        await del(`/api/task/${params.id}`);
        history.push(`${user?.customerId ? '/customer' : ''}/tasks`);
    };

    const clearModalData = () => {
        // setView(null);
        setEditDisabled(true);
        initializeTaskData();
    }

    const getBreadcrumbs = () => {
      return [
        { title: props.t("Tasks"), link: `${user?.customerId ? '/customer' : ''}/tasks` },
      ];
    };

    const toggle = tab => (activeTab !== tab ? setActiveTab(tab) : null)

    const getTaskTypes = () => {
        let types = [{value: INTERNAL, label: props.t(INTERNAL)}];
        if(view !== SUPER_ADMIN_VIEW){
            types.push({value: ESCALATED, label: props.t(ESCALATED)})
        }
        if(view === TENANT_VIEW){
            types.push({value: CUSTOMER, label: props.t(CUSTOMER)})
        }
        if(view === SUPER_ADMIN_VIEW){
            types.push({value: TENANT, label: props.t(TENANT)})
        }
        return types;
    }


    const handleTaskChange = (task) => {
        window.location.href = `/tasks/${task?.id}`;
    }

    const TaskDetailView = () => (
    <>
        {errorMessage && (
            <Row>
                <Alert color="danger">{errorMessage}</Alert>
            </Row>
        )}
        
        <div className="m-2">
             
            <Row>
                <Col xl="8">
                    <Row>
                        <div className="product-detail p-3 rounded">
                            <Row className="mb-3">
                                <div className="col-md-12">
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-md-3 col-form-label"
                                    >
                                        {props.t("Title")}
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) => setSubject(e.target.value)}
                                        placeholder={props.t("Enter Subject")}
                                        value={subject}
                                        disabled={editDisabled && !showEditViewInitially}
                                    />
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <div className="col-md-12">
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-md-3 col-form-label"
                                    >
                                        {props.t("Description")}
                                    </label>
                                    <textarea
                                        className="form-control task-textarea"
                                        type="text"
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder={props.t("Enter Description")}
                                        value={description}
                                        rows="7"
                                        disabled={editDisabled && !showEditViewInitially}
                                    />
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row>
                        <Comments  userDropdown={assignedOptions}/>
                    </Row>
                </Col>
                <Col xl="4">
                    <div className="border p-3 rounded">
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Type")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    defaultValue={{value : taskType, label: props.t(taskType)}}
                                    values={taskType ? {value : taskType, label: props.t(taskType)} : null}
                                    onChange={(vals) => setTaskType(vals?.value)}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Type")}
                                    options={getTaskTypes()}
                                />
                            </div>
                        </Row>
                        {(view === SUPER_ADMIN_VIEW && taskType === TENANT) && 
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Tenant")}
                            </label>
                            <div className="col-md-8">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    selectedValue={find(tenantOptions, { id: tenantId})} 
                                    options={tenantOptions} 
                                    placeholder={props.t("Select Tenant")}
                                    handleOnOptionClick={(to) => setTenantId(to?.id)}
                                    disabled={editDisabled && !showEditViewInitially}
                                />
                            </div>
                        </Row>}
                        {((view === SUPER_ADMIN_VIEW && taskType === TENANT) || (view === TENANT_VIEW && taskType === CUSTOMER)) && 
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Customer")}
                            </label>
                            <div className="col-md-8">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    favorite={["favorite"]}
                                    selectedValue={find(taskCustomerOptions, { id: customerId})} 
                                    options={taskCustomerOptions} 
                                    placeholder={tenantCustomersLoading ? props.t("Loading...") : props.t("Select Customer")}
                                    handleOnOptionClick={(co) => setCustomerId(co?.id)}
                                    disabled={editDisabled && !showEditViewInitially}
                                />
                            </div>
                        </Row>}
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Assignee")}
                            </label>
                            <div className="col-md-8">
                                <SearchableDropdown
                                    keyToDisplay={["name"]}
                                    selectedValue={(higherLevelAdminUser && higherLevelAdminUser.id === assigned?.id) ? higherLevelAdminUser : assigned} 
                                    options={assignedOptions} 
                                    placeholder={customerUsersLoading || tenantUsersLoading ? props.t("Loading" + "...") : props.t("Select Assignee")}
                                    handleOnOptionClick={(o) => setAssigned(o)}
                                    disabled={(editDisabled || customerUsersLoading || tenantUsersLoading) && !showEditViewInitially}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Reporter")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    options={(taskType == null || taskType == '' ? reporters : reporterOptions).map(r => ({...r, label: r.name, value: r.name}))}
                                    values={(taskType == null || taskType == '' ? reporters : reporterOptions.filter(o => find(reporters, {id: o.id}))).map(r => ({...r, label: r.name, value: r}))}
                                    defaultValue={(taskType == null || taskType == '' ? reporters : reporterOptions.filter(o => find(reporters, {id: o.id}))).map(r => ({...r, label: r.name, value: r}))}
                                    searchBy="name"
                                    valueField="name"
                                    labelField="name"
                                    multi={true}
                                    color={brandSettings?.primaryBtnColor}
                                    onChange={(vals) => {
                                        setReporters(vals ? [vals] : [])}
                                    }
                                    clearable={false}  
                                    disabled={editDisabled && !showEditViewInitially} 
                                    placeholder={props.t("Select Reporter")}
                                />
                            </div>
                        </Row>
                        {showHiddenToCustomerCheck && 
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("")}
                            </label>
                            <div className="col-md-8">
                                <input
                                    className="form-check-input me-2"
                                    type="checkbox"
                                    checked={hiddenToCustomer}
                                    onChange={(e) => setHiddenToCustomer(e.target.checked)}
                                />
                                <label className="mb-0">
                                    {props.t("Hidden to customer")}
                                </label>
                            </div>
                        </Row>}
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Status")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    defaultValue={find(STATUS_LIST, {value : status})}
                                    values={status ? find(STATUS_LIST, {value : status}) : null}
                                    onChange={(vals) => setStatus(vals?.value)}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Type")}
                                    options={STATUS_LIST}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Category")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    defaultValue={{value : category, label : find(taskCategories, {id : category})?.name}}
                                    values={category ? {value : category, label : find(taskCategories, {id : category})?.name} : null}
                                    onChange={(e) => {
                                        setCategory(e?.value);
                                        if(e?.value) {
                                            let hidden = find(taskCategories, { id: parseInt(e.target.value) })?.hiddenToCustomer;
                                            if(hidden != null && hidden != undefined){
                                                setHiddenToCustomer(hidden);
                                            }
                                        }
                                    }}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Category")}
                                    options={taskCategories?.filter(type => type.enabled).map(o => ({value: o.id, label: o.name}))}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Priority")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    defaultValue={{value : priority, label : find(taskPriorities, {id : priority})?.name}}
                                    values={priority ? {value : priority, label : find(taskPriorities, {id : priority})?.name} : null}
                                    onChange={(e) => {
                                        setPriority(e?.value);
                                    }}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Priority")}
                                    options={taskPriorities?.filter(type => type.enabled).map(o => ({value: o.id, label: o.name}))}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Due Date")}
                            </label>
                            <div className="col-md-8">
                                <DatePicker
                                    onChange={(date) => {
                                        setDueDate(parseDateToString(date))
                                    }} 
                                    value={dueDate ? getDateObject(dueDate) : null}
                                    disabled={(loading || editDisabled) && !showEditViewInitially}
                                    name={"Due Date"}
                                    format={"yyyy-MM-dd"}
                                    dayPlaceholder={"dd"}
                                    monthPlaceholder={"mm"}
                                    yearPlaceholder={"yyyy"}
                                    minDate={new Date()}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Estimate")}
                            </label>
                            <div className="col-md-8">
                                <input 
                                    type="number"
                                    className="form-control"
                                    placeholder={props.t("Hours")}
                                    onChange={(e) => setEstimage(e.target.value)}
                                    defaultValue={estimate}
                                    value={estimate}
                                    disabled={editDisabled && !showEditViewInitially}
                                    onWheel={preventNumberInputScroll}
                                    // onKeyDown={validateNumber}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3">
                            <label className="col-md-4 col-form-label">
                                {props.t("Project")}
                            </label>
                            <div className="col-md-8" key={project?.id}>
                                <DropdownSelect
                                    defaultValue={{value : project?.id, label : project?.description}}
                                    values={project ? {value : project?.id, label : project?.description} : null}
                                    onChange={(e) => {
                                        setProject(find(projectOptions, {id: e?.value}));
                                    }}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Project")}
                                    options={projectOptions?.map(o => ({value: o.id, label: o.description}))}
                                />
                            </div>
                        </Row>
                        <Row>
                            <label className="col-md-4 col-form-label">
                                {props.t("Article")}
                            </label>
                            <div className="col-md-8">
                                <DropdownSelect
                                    defaultValue={{value : article?.id, label : article?.articleNumber +" | "+ article?.description}}
                                    values={article ? {value : article?.id, label : article?.articleNumber +" | "+ article?.description} : null}
                                    onChange={(e) => {
                                        setArticle(find(productOptions, {id: e?.value}));
                                    }}
                                    disabled={editDisabled && !showEditViewInitially}
                                    placeholder={props.t("Select Article")}
                                    options={productOptions?.map(o => ({value: o.id, label: article?.articleNumber +" | "+ article?.description}))}
                                />
                            </div>
                        </Row>
                        <br/><br/>
                        {!editDisabled &&  <Row className="mb-3">
                            <div className="justify-content-end">
                                <Button
                                    color="primary"
                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                    onClick={() => handleSaveTask()}
                                    disabled={!subject}
                                    className="btn btn-primary waves-effect waves-light margin-left-2 float-end">
                                    {props.t("Save")}
                                </Button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowEditViewInitially(false);
                                        clearModalData()
                                    }}
                                    className="btn btn-secondary waves-effect margin-left-2 float-end">
                                    {props.t("Close")}
                                </button>

                            </div>
                        </Row>}
                    </div>

                </Col>
            </Row>
            <Row>

            </Row>
        </div>
    </>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={editTask?.taskDetails?.subject}
                        breadcrumbs={getBreadcrumbs()}
                        backBtn={() => history.goBack()}
                    />
                    <Row>
                        <Col className="col-12 task-detail-row">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={editTask ? props.t("Task") + " / " + editTask.taskDetails.subject : props.t("Task")}
                                        buttons={editDisabled && !showEditViewInitially ? [] : [{
                                            text: props.t("Close"),
                                            onClick: () => {
                                                setShowEditViewInitially(false);
                                                clearModalData()
                                            },
                                        },
                                        {
                                            text: props.t("Save"),
                                            onClick: () => {
                                                setShowEditViewInitially(false);
                                                handleSaveTask()
                                            },
                                            disabled: !subject
                                        }]}
                                        titleDropdown={{
                                            isSearchableDropdown: true,
                                            value: task?.taskDetails,
                                            options: tasks?.map(t => t.taskDetails),
                                            placeholder: props.t("Select Task"),
                                            isHeader: true,
                                            handleSelect: (op) => {
                                                handleTaskChange(op);
                                            },
                                            keyToDisplay: "subject",
                                        }}
                                        canDelete={!loading &&!loadingWorkLog && workLog.length == 0 && (view === SUPER_ADMIN_VIEW
                                            || (view === TENANT_VIEW && user?.roles.includes('ROLE_TENANT_ADMIN'))
                                            || (view === CUSTOMER_VIEW && user?.roles.includes('ROLE_CUSTOMER_ADMIN'))) ? toggleDeleteTask : null}
                                        canEdit={() => setEditDisabled(false)}
                                        canDuplicate={() => {setIsDuplicate(true); setEditDisabled(false)}}
                                        labels={editTask?.overdue && <div className="badge bg-danger bg-light-danger p-2">{props.t("Overdue")}</div>}

                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Task Details"/>
                                {!loading &&
                                    <CardBody>
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-store font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Task Details")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        toggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-clock font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Timeslips")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={() => {
                                                        toggle('3');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Events")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                {TaskDetailView()}
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <WorkLog  userDropdown={assignedOptions} hideCustomer={true} hideTask={true}/>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <EventManagement taskEvents={true} taskId={params.id}/>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DeleteModal
                deleteCallback={() => handleDeleteTask()}
                element={"Task"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteTaskModal}
                toggleModal={() => toggleDeleteTask()}
                warningMsg={null}
            />
        </React.Fragment>
    );
};

TaskList.propTypes = {
    tasks: PropTypes.array,
    onGetTasks: PropTypes.func,
    loading: PropTypes.bool,


};

const mapStateToProps = (state) => {
    return {
        task: state.task.task,
        getTaskError: state.task.getTaskError,
        loading: state.task.getTaskLoading,
        customerOptions: state.Customer.customers,
        tenantOptions: state.tenant.tenantList,
        productOptions: state.ecommerce.products.data,
        projectOptions: state.Project.projects,
        tenantUserOptions: state.tenant.tenantAdmins,
        tenantUsersLoading: state.tenant.tenantAdminUsersLoading,
        customerUserOptions: state.Customer.customerUsers,
        customerUsersLoading: state.Customer.customerUsersLoading,
        tenantCustomersLoading: state.tenant.getTenantCustomersLoading,
        tenantCustomerOptions: state.tenant.customerList,
        workLog: state.task.workLog,
        loadingWorkLog: state.task.loadingWorkLog,
        taskCategories: state.task.taskCategories,
        taskPriorities: state.task.taskPriorities,
        tasks: state.task.tasks,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTask: (taskId) => dispatch(getTask(taskId)),
    onGetTaskReset: () => dispatch(getTaskReset()),
    onGetTenantOptions: () => dispatch(getTenants()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetProductOptions: (tenantId, customerId) => dispatch(getProducts(-1, -1, customerId != null, null, null, null, tenantId)),
    onGetProductsReset: () => dispatch(getProductsReset()),
    onGetProjectOptions: (tenantId, customerId) => dispatch(getProjectList(customerId, null, tenantId)),
    onGetProjectsReset: () => dispatch(getProjectListReset()),
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId, "active")),
    onGetCustomerUsersReset: () => dispatch(GetCustomerUsersReset()),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onGetTenantUsersReset: () => dispatch(getTenantAdminsReset()),
    onGetTenantCustomers: (tenantId) => dispatch(getTenantCustomers(tenantId, "active")),
    onGetTaskWorkLog: (taskId) => dispatch(getTaskWorkLog(taskId)),
    onGetTaskCategories: () => dispatch(getTaskCategories()),
    onGetTaskPriorities: () => dispatch(getTaskPriorities()),
    onGetTasks: (filter) => dispatch(getTaskList(filter)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskList)));
