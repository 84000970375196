import { GET_TENANT_CUSTOMER_USERS_SUCCESS } from "../users/tenant/actionTypes";
import {
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS,
    GET_TENANT_ADMIN_USERS,
    GET_TENANT_ADMIN_USERS_FAIL,
    GET_TENANT_ADMIN_USERS_SUCCESS,
    GET_TENANT_DETAILS_FAIL,
    GET_TENANT_DETAILS_SUCCESS,
    GET_TENANT,
    GET_TENANT_FAIL,
    GET_TENANT_SUCCESS,
    REGENERATE_TOKEN_FAIL,
    REGENERATE_TOKEN_SUCCESS,
    GET_TENANT_RESET,
    GET_TENANT_DETAILS_RESET,
    GET_TENANT_ADMIN_USERS_RESET,
    GET_TENANT_CUSTOMERS_FAIL,
    GET_TENANT_CUSTOMERS_RESET,
    GET_TENANT_CUSTOMERS_SUCCESS,
    GET_TENANT_CUSTOMERS,
} from "./actionTypes";

const INIT_STATE = {
    tenantList: [],
    tenantAdmins: [],
    tokenRegenerate: null,
    tenantDetails:{},
    tenantAdminUsersError: null,
    tenantAdminUsersLoading: false,
    getTenantsLoading: false,
    getTenantsError: null,
    tenantDetailsError: null,
    getTenantCustomersLoading: false,
    customerList: [],
    getTenantCustomersError: null,
};

const Tenant = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TENANT:
            return {
                ...state,
                getTenantsLoading: true,
            }
        case GET_TENANT_SUCCESS:
            return {
                ...state,
                getTenantsLoading: false,
                tenantList: action.payload
            }
        case GET_TENANT_FAIL:
            return {
                ...state,
                getTenantsLoading: false,
                getTenantsError: action.payload,
            }
        case GET_TENANT_RESET:
            return {
                ...state,
                getTenantsLoading: false,
                getTenantsError: null,
                tenantList: [],
            }
        case GET_TENANT_CUSTOMERS:
            return {
                ...state,
                getTenantCustomersLoading: true,
            }
        case GET_TENANT_CUSTOMERS_SUCCESS:
            return {
                ...state,
                getTenantCustomersLoading: false,
                customerList: action.payload
            }
        case GET_TENANT_CUSTOMERS_FAIL:
            return {
                ...state,
                getTenantCustomersLoading: false,
                getTenantCustomersError: action.payload,
            }
        case GET_TENANT_CUSTOMERS_RESET:
            return {
                ...state,
                getTenantCustomersLoading: false,
                getTenantCustomersError: null,
                customerList: [],
            }
        case GET_TENANT_ADMIN_USERS:
            return {
                ...state,
                tenantAdminUsersLoading: true,
            }
        case GET_TENANT_ADMIN_USERS_SUCCESS:
            return {
                ...state,
                tenantAdminUsersLoading: false,
                tenantAdmins: action.payload,
            }
        case GET_TENANT_ADMIN_USERS_FAIL:
            return {
                ...state,
                tenantAdminUsersLoading: false,
                tenantAdminUsersError: action.payload,
            }
        case GET_TENANT_ADMIN_USERS_RESET:
            return {
                ...state,
                tenantAdminUsersLoading: false,
                tenantAdminUsersError: null,
                tenantAdmins: []
            }
        case REGENERATE_TOKEN_SUCCESS:
            return {
                ...state,
                tokenRegenerate: action.payload
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                userDeleted: action.payload
            }
        case REGENERATE_TOKEN_FAIL:
            return {
                ...state,
                tokenRegenerate: action.payload
            }
        case DELETE_USER_FAIL:
            return {
                ...state,
                userDeleted: action.payload
            }
        case GET_TENANT_DETAILS_SUCCESS:
            return {
                ...state,
                tenantDetails: action.payload,
                tenantDetailsError: null,
            }
        case GET_TENANT_DETAILS_FAIL:
            return {
                ...state,
                tenantDetailsError: action.payload
            }
        case GET_TENANT_DETAILS_RESET:
            return {
                ...state,
                tenantDetailsError: null,
                tenantDetails: {}
            }
        default:
            return state;
    }
};

export default Tenant
