import {call, put, takeEvery} from "redux-saga/effects";
import {GET_IMPERSONATION_PROJECT_LIST, GET_PROJECT_LIST, GET_PROJECT_LIST_BY_LEADER, GET_PROJECT_LIST_BY_PARTICIPANT, GET_PROJECT_USERS} from "./actionTypes";
import {
    getImpersonationProjectListFail,
    getImpersonationProjectListSuccess,
    getProjectListByLeaderFail,
    getProjectListByLeaderSuccess,
    getProjectListByParticipantFail as getProjectListByParticipantFail,
    getProjectListByParticipantSuccess as getProjectListByParticipantSuccess,
    getProjectListFail,
    getProjectListSuccess,
    getProjectUsersError,
    getProjectUsersSuccess
} from "./actions";
import {getImpersonationProjectsList, getProjectListByLeader, getProjectListByParticipant, getProjectsList, getProjectsUsers} from "../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../util/constants";

function* fetchProjectList({tenantId, customerId, status, filter, sync}) {
    try {
        const response = yield call(getProjectsList, tenantId, customerId, status, filter, sync);
        yield put(getProjectListSuccess(response));
    } catch (error) {
        yield put(getProjectListFail(API_ERR_MSG));
    }
}

function* fetchImpersonationProjectList({customerId}) {
    try {
        const response = yield call(getImpersonationProjectsList, customerId, false);
        yield put(getImpersonationProjectListSuccess(response));
    } catch (error) {
        yield put(getImpersonationProjectListFail(API_ERR_MSG));
    }
}

function* fetchProjectsByParticipant({userId}) {
    try {
        const response = yield call(getProjectListByParticipant, userId);
        yield put(getProjectListByParticipantSuccess(response));
    } catch (error) {
        yield put(getProjectListByParticipantFail(API_ERR_MSG));
    }
}

function* fetchProjectsByLeader({userId}) {
    try {
        const response = yield call(getProjectListByLeader, userId);
        yield put(getProjectListByLeaderSuccess(response));
    } catch (error) {
        yield put(getProjectListByLeaderFail(API_ERR_MSG));
    }
}

function* fetchProjectUsers({projectId}){
    try{
        const response = yield call(getProjectsUsers, projectId);
        yield put(getProjectUsersSuccess(response))
    }catch (error){
        yield put(getProjectUsersError(error));
    }
}

function* projectsSaga() {
    yield takeEvery(GET_PROJECT_LIST, fetchProjectList);
    yield takeEvery(GET_IMPERSONATION_PROJECT_LIST, fetchImpersonationProjectList);
    yield takeEvery(GET_PROJECT_LIST_BY_PARTICIPANT, fetchProjectsByParticipant);
    yield takeEvery(GET_PROJECT_LIST_BY_LEADER, fetchProjectsByLeader);
    yield takeEvery(GET_PROJECT_USERS, fetchProjectUsers);
}

export default projectsSaga;
