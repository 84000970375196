import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next"
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import TenantUserActions from "./TenantUserActions";
import {getTenantAdmins, getTenantAdminsReset} from "../../store/tenant/actions";
import TenantUserForm from "./TenantUserForm";
import {TENANT_ADMIN_RESET_DELETE_USER, TENANT_ADMIN_RESET_REGENERATE_TOKEN} from "../../store/users/tenant/actionTypes";
import Loader from "../../components/Common/Loader";
import { impersonateUser } from "../../store/actions";
import { orderBy } from "lodash";

export const userDetailContext = React.createContext(null)

const TenantUserManagement = (props) => {

    const {users, onGetUsers, tenantId, tokenRegenerate, userDeleted, onResetRegenerateToken, onResetDeleteUser, userActivated, loading, error, onImpersonateUser, onGetUsersReset } = props
    const [editMode, setEditMode] = useState(false);
    const [selectedTenantUser, setSelectedTenantUser] = useState(undefined);
    const [userList, setUserList] = useState([])
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState(null)
    const history = useHistory();
    const location = useLocation();
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    useEffect(() => {
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);

        const prevFilter = localStorage.getItem("tenantProfileUsersTabFilter");
        if(prevFilter){
            setFilter(prevFilter);
        } else {
          setFilter("active");
        }
      }
      return () => {
        onGetUsersReset();
      }
    }, []);

    useEffect(() => {
        onGetUsers(tenantId);
    }, [tenantId])

    useEffect(() => {
    }, [selectedTenantUser])

    useEffect(() => {
        setUserList(users)
    }, [users])

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setUserList(orderBy(userList, o => {
            if(column === 'customer') return o[column]?.name?.toLowerCase()
            if(column === 'role') return getRoleName(o[column])?.toLowerCase()
            if(column === 'enabled') return o[column]
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
      if(error){
        setErrorMessage(error)
        setTimeout(() => {onGetUsersReset()}, 5000);
        setTimeout(() => {setErrorMessage('')}, 5000);
      }
    }, [error])

    useEffect(() => {
        if(filter){
          localStorage.setItem("tenantProfileUsersTabFilter", filter);
          onGetUsers(tenantId, filter);
        }
    }, [filter]);

    useEffect(() => {
        if(tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."))
            setTimeout(()=>{setSuccessMessage(""); onResetRegenerateToken()}, 3000)
        } else if(tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"))
            setTimeout(()=>{setErrorMessage(""); onResetRegenerateToken()}, 3000)
        }
    }, [tokenRegenerate, onResetRegenerateToken])

    useEffect(() => {
        if(userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User disabled successfully."))
            setTimeout(()=>{setSuccessMessage(""); onResetDeleteUser()}, 3000)
            onGetUsers(tenantId)
        } else if(userDeleted) {
            setErrorMessage(props.t("Error disabling user."))
            setTimeout(()=>{setErrorMessage(""); onResetDeleteUser()}, 3000)
        }
    }, [userDeleted])

    useEffect(() => {
        if(userActivated && userActivated.status) {
            setSuccessMessage(props.t("User activated successfully."))
            setTimeout(()=>{setSuccessMessage(""); onResetDeleteUser()}, 3000)
            onGetUsers(tenantId)
        } else if(userActivated) {
            setErrorMessage(props.t("Error activated user."))
            setTimeout(()=>{setErrorMessage(""); onResetDeleteUser()}, 3000)
        }
    }, [userActivated])

    function handleAddEditUser() {
        onGetUsers(tenantId)
        setEditMode(false);
    }

    function getRoleName(role){

        if(role === "ROLE_TENANT_ADMIN"){
            return props.t("Tenant Admin")
        } else if(role === "ROLE_TENANT_USER"){
            return props.t("Tenant User")
        }
        return role;
    }
    const getUserDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Email"),
                    field: "username",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Contact Phone"),
                    field: "phone",
                    sort: "asc",
                    width: 150,
                },{
                    label: props.t("Role"),
                    field: "role",
                    width: 150
                },
                {
                    label: props.t("Status"),
                    field: "enabled",
                    width: 150
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    sort: "asc",
                    autoWidth: true
                },
                {
                  label: props.t("Impersonate"),
                  field: "impersonate",
                }
            ],
            rows: userList?.map((user, key) => ({
                name: <span onClick={() => handleOpen(user.id)}>{user.name}</span>,
                username: <span onClick={() => handleOpen(user.id)}>{user.username}</span>,
                phone: <span onClick={() => handleOpen(user.id)}>{user.phone}</span>,
                role: <span onClick={() => handleOpen(user.id)}>{getRoleName(user.role)}</span>,
                enabled: <span onClick={() => handleOpen(user.id)}>{user.enabled ? props.t("Active") : props.t("Disabled")}</span>,
                impersonate: <><button onClick={()=> handleImpersonate(user)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}><i className="fa fa-sign-in-alt"></i></button></>
            }))
        }
        return data;
    }

    const handleOpen = (id) => {
      history.push(`/tenants/${tenantId}/user/${id}?from=` + encodeURIComponent(props.location.pathname));
    };

    const handleImpersonate = (user) => {
      onImpersonateUser({ userEmail: user.username }, history, location)
    }

    const handleFilter = (option) => {
      setFilter(option);
    }

    return (
        <React.Fragment>
            {
                !editMode && <React.Fragment>
                    { successMessage && <Row>
                        <Alert color="success">
                            {successMessage}
                        </Alert>
                    </Row>}
                    { errorMessage && <Row>
                        <Alert color="danger">
                            {errorMessage}
                        </Alert>
                    </Row>}
                    <Loader loading={loading} title="Tenant User Details" />
                    {!loading && (<>
                     <div className="d-flex justify-content-end mb-3">
                        <Button 
                            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                            className="btn btn-primary waves-effect waves-light mx-1"
                            onClick={() => { 
                                setEditMode(true);
                                setSelectedTenantUser(undefined);
                            }}
                        >Add User
                        </Button>
                        <select
                            className="form-control table-filter-dropdown"
                            id="filter"
                            onChange={e => handleFilter(e.target.value)}
                            value={filter}
                            style={{ backgroundColor: brandSettings?.primaryBtnColor, color: 'white' }}
                        >
                            <option value={"active"}>
                            {props.t("All")}
                            </option>
                            <option value={"disabled"}>
                            {props.t("Disabled")}
                            </option>
                        </select>
                    </div>
                    <Row>
                        <Col className="col-12 article-table">                        
                            <MDBDataTable
                                hover
                                barReverse={true}
                                responsive
                                borderless
                                paginationLabel={[props.t("Previous"), props.t("Next")]}
                                entriesLabel={props.t("Show entries")}
                                infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                                searchLabel={props.t("Search") + "..."}
                                noBottomColumns={true}
                                entries={50}
                                entriesOptions={[50,100,500]}
                                data={getUserDatatableData()}
                                noRecordsFoundLabel={props.t("No matching records found")}
                                sortRows={[]}
                                onSort={({column, direction}) => {
                                    if(sortState && sortState.column == column){
                                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                    } else {
                                        setSortState({column, direction});
                                    }
                                }}
                            />
                        </Col>
                    </Row></>)}
                </React.Fragment>
            }
            {
                editMode && 
                <React.Fragment>
                    <Row>
                        <Col>
                            <TenantUserForm 
                                location={props.location} 
                                tenantId={tenantId} 
                                userId={selectedTenantUser} 
                                setEditMode={setEditMode} 
                                callback={handleAddEditUser} 
                                avoidRedirect={true} />
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

TenantUserManagement.propTypes = {
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        users: state.tenant.tenantAdmins,
        tokenRegenerate: state.TenantAdmin.tokenRegenerate,
        userDeleted: state.TenantAdmin.userDeleted,
        userActivated: state.TenantAdmin.userActivated,
        error: state.tenant.tenantAdminUsersError,
        loading: state.TenantAdmin.tenantAdminUsersLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onGetUsers: (tenantId, filter) => dispatch(getTenantAdmins(tenantId, filter)),
    onResetRegenerateToken: () => dispatch({
        type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
    }),
    onResetDeleteUser: () => dispatch({
        type: TENANT_ADMIN_RESET_DELETE_USER,
    }),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
    onGetUsersReset: () => dispatch(getTenantAdminsReset()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TenantUserManagement)));
