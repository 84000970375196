import React from "react"
import {withTranslation} from "react-i18next";
import Select from "react-select";
import {find} from "lodash";

/**
 * Use 'keyToDisplay' prop when 'selectedValue' props is an object and options is an array of objects and you want to use a value of 'keyToDisplay' key in object for displaying the text to user in UI
 */
const SearchableDropdown = props => {
    const {
        selectedValue,
        options,
        handleOnOptionClick,
        placeholder,
        keyToValue,
        keyToDisplay,
        isMulti,
        disabled,
        favorite
    } = props;

    const getLabelValue = (item) => {
        if (!item) {
            return null;
        }
        let label = "";
        for (let i = 0; i < keyToDisplay.length; i++) {
            label += item[keyToDisplay[i]];
            if (i < keyToDisplay.length - 1) {
                label += " | ";
            }
        }
        if(favorite && item[favorite]){
            label = '★' + " " + label;
        } else {
            label = " " + " " + label;
        }
        return {value: item[keyToValue ? keyToValue : "id"], label: label}
    }

    const onSelectValue = (value) => {
        let selectedValue = find(options, {id: value?.value})
        handleOnOptionClick(selectedValue);
    }

    const filterOption = (option, inputValue) => {
        if (inputValue.length > 0) {
            return inputValue.split(' ').every(word => option.label.toLowerCase().includes(word.toLowerCase()));
        }
        return option.label.includes(inputValue);
    }

    return (<>
            <Select
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti={isMulti ? true : false}
                placeholder={placeholder}
                isClearable={() => onSelectValue(null)}
                onChange={(vals) => {
                    onSelectValue(vals);
                }}
                defaultValue={getLabelValue(selectedValue)}
                isDisabled={disabled}
                filterOption={filterOption}
                options={options?.map(item => (getLabelValue(item)))}/>
        </>
    )
}

export default withTranslation()(SearchableDropdown)
