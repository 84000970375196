import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getProjectList, getProjectListReset} from "../../store/project/actions";
import TableToolbar from "../../components/Common/TableToolbar";
import {includes, isEmpty} from "lodash-es";
import Loader from "../../components/Common/Loader";
import {find, orderBy} from "lodash";
import {GetCustomers} from "../../store/customer/actions";

export const projectIdContext = React.createContext(null);

const ProjectList = (props) => {
    let history = useHistory();
    const {projects, onGetProjects, loading, error, onGetProjectsReset, customerOptions, onGetCustomerOptions, customerOptionsLoading} = props;
    const [projectList, setProjectList] = useState([]);
    const [activeTabJustify, setactiveTabJustify] = useState("1");
    const [customerId, setCustomerId] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState("ALL")
    const [typeFilter, setTypeFilter] = useState("ALL");
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['projectNumber'];
    const user = JSON.parse(localStorage.getItem("authUser"));
    let level = localStorage.getItem("level");
    const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";

    useEffect(() => {
        onGetCustomerOptions();
        if (localStorage.getItem("authUser")) {
            setBrandSettings(user?.brandConfig);
            setCustomerId(user?.customerId);

            const prevFilter = localStorage.getItem("projectsFilter");
            if (prevFilter) {
                setFilter(prevFilter);
            } else {
                setFilter("ALL");
            }
        }
        return () => {
            onGetProjectsReset();
        }
    }, []);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                onGetProjectsReset()
            }, 5000);
        }
    }, [error])

    useEffect(() => {
        setProjectList(projects);
    }, [projects]);

    useEffect(() => {
        if (sortState) {
            const {column, direction} = sortState;
            setProjectList(orderBy(projectList, o => {
                if (column === 'customer') return o[column]?.name?.toLowerCase()
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    useEffect(() => {
        if (loading == false && (filter || typeFilter)) {
            let f = filter;
            if(!f){
                f = "ALL"
            }
            localStorage.setItem("projectsFilter", f);
            onGetProjects(customerId, f, typeFilter);
        }
    }, [filter, typeFilter]);

    const handleOnClick = () => {
        history.push("/projects/add");
    };

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
            setactiveTabJustify(tab);
        }
    }

    const handleSearch = () => {
        if (!isEmpty(search)) {
            const results = filter(
                projectList,
                (offer) =>
                    includes(offer.displayName?.toLowerCase(), search.toLowerCase()) ||
                    includes(offer.description?.toLowerCase(), search.toLowerCase()) ||
                    filter(offer.categories, (c) =>
                        includes(c.name?.toLowerCase(), search.toLowerCase())
                    ).length > 0
            );

            setSearchResults(results);
        } else {
            setSearchResults(projectList);
        }
        setSearchValue(search);
    };

    const getDatatableData = () => {
        const rows = projectList?.filter((row) =>
            row.description?.toLowerCase().includes(search?.toLowerCase())
        );
        let data = {
            columns: [
                {
                    label: props.t("Description"),
                    field: "description",
                    width: 150,
                },
                {
                    label: props.t("Project Number"),
                    field: "projectNumber",
                    width: 150,
                },
                {
                    label: props.t("Customer"),
                    field: "customer",
                    autoWidth: true,
                },
                {
                    label: props.t("Start Date"),
                    field: "startDate",
                    width: 100,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                }
            ],
            rows: rows?.map((project, key) => ({
                description: project.description,
                projectNumber: project.projectNumber,
                customer: project.customer,
                startDate: project.startDate,
                status: props.t(project.status),
                clickEvent: function () {
                    handleOpen(project);
                }
                /*actions: (
                  <projectIdContext.Provider value={project.id}>
                    <ProjectActions projectId={project.id} />
                  </projectIdContext.Provider>
                ),*/
            })),
        };
        if (user?.customerId) {
            data.columns.splice(2, 1);
        }
        return data;
    };

    const handleOpen = (project) => {
        history.push(`/projects/${project.projectNumber}`);
    }

    const handleFilter = (option) => {
        setFilter(option);
    }


    const getFilterBtn = () => {
        var option = [
            {name: props.t("My Projects"), value: "MY_PROJECTS"},
            {name: props.t("All"), value: 'ALL'},
            {name: props.t("Internal"), value: "INTERNAL"}
        ];
        if (level === "TENANT") {
            option.push({
                isSearchableDropdown: true,
                value: find(customerOptions, {id: customerId}),
                options: customerOptions,
                placeholder: customerOptionsLoading ? props.t("Loading...") : props.t("Select Customer"),
                handleSelect: (op) => {
                    /*setCustomFilter({...customFilter, customerId: op?.id});
                    setTaskTypeFilter(null);*/
                    setCustomerId(op?.id);
                    setTypeFilter(null);
                },
                keyToDisplay: "name",
                favorite: "favorite"
            });
        }
        return option;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Projects")}
                                        buttons={ fortnoxConnectionStatus ? [{
                                            text: props.t("New Project"),
                                            onClick: handleOnClick,
                                        }] : []}
                                        filters={[
                                            {
                                                selected: filter,
                                                options: [
                                                    {name: props.t("Ongoing"), value: "ONGOING"},
                                                    {name: props.t("Completed"), value: "COMPLETED"},
                                                    {name: props.t("Not Started"), value: "NOTSTARTED"},
                                                    {name: props.t("All"), value: "ALL"}
                                                ],
                                                onSelect: handleFilter
                                            },
                                            {
                                                selected: typeFilter,
                                                options: getFilterBtn(),
                                                onSelect: (option) => {
                                                    setCustomerId(null);
                                                    setTypeFilter(option);
                                                }
                                            }
                                        ]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Projects"/>
                                {!loading &&
                                    <CardBody className={"article-table"}>
                                        {error &&
                                            <Alert className={"mt-4"} color="danger" role="alert">
                                                {error}
                                            </Alert>
                                        }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            borderless
                                            paginationLabel={[
                                                props.t("Previous"),
                                                props.t("Next"),
                                            ]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            onSearch={(text) => console.log(text)}
                                            searching={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            // sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ProjectList.propTypes = {
    projects: PropTypes.array,
    onGetProjects: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        projects: state.Project.projects,
        loading: state.Project.loading,
        error: state.Project.error,
        customerOptions: state.Customer.customers,
        customerOptionsLoading: state.Customer.customersLoading
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetProjects: (customerId, status, filter) => dispatch(getProjectList(customerId, status, null, filter)),
    onGetProjectsReset: () => dispatch(getProjectListReset()),
    onGetCustomerOptions: () => dispatch(GetCustomers()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectList)));
