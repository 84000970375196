import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row,} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getFields, getFieldsReset} from "../../store/e-commerce/actions";
import {del, get, post} from "../../helpers/api_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import TableToolbar from "../../components/Common/TableToolbar";
import {size} from "lodash-es";
import {API_ERR_MSG} from "../../util/constants";
import {isEmpty} from "underscore";
import Loader from "../../components/Common/Loader";
import {isArray, orderBy} from "lodash";
import {toastOptions} from "../../util/dataUtil";
import cogoToast from "cogo-toast";

const TYPE_MAPPING = {
    "article": "ARTICLE_FIELD_TYPE",
    "project": "PROJECT_FIELD_TYPE"
}

const ASSOCIATION_FIELD_LIST = {
    "article": [{label: "Article Number", value: "articleNumber"},
        {label: "Bulky", value: "bulky"},
        {label: "Depth", value: "depth"},
        {label: "EAN", value: "ean"},
        {label: "Height", value: "height"},
        {label: "Manufacturer", value: "manufacturer"},
        {label: "Manufacturer Article Number", value: "manufacturerArticleNumber"},
        {label: "Weight", value: "weight"},
        {label: "Width", value: "width"}],
    "project": []
}

const FieldList = (props) => {
    const {
        fields,
        onGetFields,
        loading,
        getFieldError,
        onGetFieldsReset,
        match: {params}
    } = props;

    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [association, setAssociation] = useState("");
    const [range, setRange] = useState(false);
    const [permission, setPermission] = useState("ROLE_CUSTOMER_USER")
    const [unit, setUnit] = useState("");
    const [fieldList, setFieldList] = useState([]);
    const [editname, setEditname] = useState("");
    const [editField, setEditField] = useState(null);
    const history = useHistory();
    const [searchResults, setSearchResults] = useState([]);
    const [search, setSearch] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false);
    const [deleteFieldId, setDeleteFieldId] = useState(null);
    const [articleFields, setArticleFields] = useState([]);
    const [layoutArticles, setLayoutArticles] = useState([]);
    const [brandSettings, setBrandSettings] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [dropdownValues, setDropdownValues] = useState([{id: null, name: ""}]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
        removeBodyCss();
    }

    function toggleDeleteField() {
        if (showDeleteFieldModal) {
            setDeleteFieldId(null);
        }
        setShowDeleteFieldModal(!showDeleteFieldModal);
        removeBodyCss();
    }

    function tog_edit() {
        setmodal_edit(!modal_edit)
        removeBodyCss()
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        //console.log("Init Field -", initState)
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetFields(TYPE_MAPPING[params.type]);
        return () => {
            onGetFieldsReset();
        }
    }, [])

    useEffect(() => {
        if (deleteFieldId) {
            get(`/api/content/${deleteFieldId}/articleContents`)
                .then(data => {
                    if (data?.status !== 200) {
                        setErrorMessage(props.t(API_ERR_MSG))
                    } else {
                        setArticleFields(data?.data);
                        if (isEmpty(data?.data)) {
                            toggleDeleteField();
                        }
                    }
                })
                .catch(err => {
                    setErrorMessage(props.t(API_ERR_MSG))
                })
        }
    }, [deleteFieldId])

    useEffect(() => {
        if (!isEmpty(articleFields)) {
            toggleDeleteField();
        }
    }, [articleFields])

    useEffect(() => {
        if (getFieldError) {
            setErrorMessage(props.t(API_ERR_MSG))
            setTimeout(() => {
                setErrorMessage('')
            }, 5000);
            setTimeout(() => {
                onGetFieldsReset()
            }, 5000);
        }
    }, [getFieldError])

    useEffect(() => {
        setFieldList(fields);
    }, [fields]);

    useEffect(() => {
        if (sortState) {
            const {column, direction} = sortState;
            setFieldList(orderBy(fieldList, o => {
                if (column === 'fieldType') return getField(o[column])
                return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
            }, [direction]))
        }
    }, [sortState])

    const handleSaveField = async (editPermissionForAllArticles = false) => {

        /*if (editField?.permission != permission && confirmation === false) {
            setConfirmation(true);
            tog_confirm();
            return;
        }*/
        console.log("Value ", permission);
        let response = await post(`/api/fields`, {
            id: editField ? editField.id : null,
            name: name,
            fieldType: type,
            unit: unit,
            permission: permission,
            fieldOptions: dropdownValues,
            isRange: range,
            associationType: TYPE_MAPPING[params.type],
            associationField: association
        });
        if (confirmation) {
            tog_confirm();
        }
        setConfirmation(false);
        setName("");
        setType("");
        setUnit("");
        setRange(false);
        setPermission("ROLE_CUSTOMER_USER")
        setDropdownValues([{id: null, name: ""}])
        onGetFields(TYPE_MAPPING[params.type]);
        tog_standard();


    };


    const handleDeleteField = async () => {
        try {
            await del(`/api/fields/${deleteFieldId}`);
            onGetFields(TYPE_MAPPING[params.type]);
            setDeleteFieldId(null);
            return false;
        } catch (err) {
            const errData = err.response.data;
            if (errData && isArray(errData) && errData.length > 0) {
                cogoToast.error(props.t(errData), toastOptions)
            } else {
                cogoToast.error(props.t(API_ERR_MSG), toastOptions)
            }
            return true;
        }
    };

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    autoWidth: true,
                },
                {
                    label: props.t("Unit"),
                    field: "unit",
                    autoWidth: true,
                },
                {
                    label: props.t("Type"),
                    field: "fieldType",
                    autoWidth: true,
                },
                {
                    label: props.t("Permission"),
                    field: "permission",
                    autoWidth: true,
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt",
                    autoWidth: true,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    autoWidth: true,
                },
            ],
            rows: fieldList?.map((item, key) => ({
                name: item.name,
                unit: item.unit,
                fieldType: getField(item.fieldType),
                createdAt: new Date(item.createdAt).toLocaleString(),
                permission: item.permission ? item.permission === 'ROLE_CUSTOMER_USER' ? props.t("Customer") : props.t("Tenant") : "",
                actions: (
                    <>
                        <i
                            className="uil uil-pen px-3 text-primary font-size-18"
                            onClick={() => handleEditField(item)}
                        ></i>
                        <i
                            className="uil uil-trash-alt px-3 text-danger font-size-18"
                            onClick={() => {
                                setDeleteFieldId(item.id);
                            }}
                        ></i>
                    </>
                ),
            })),
        };
        return data;
    };

    const handleEditField = (item) => {
        setEditField(item);
        setName(item.name);
        setType(item.fieldType);
        setUnit(item.unit);
        setPermission(item.permission);
        setDropdownValues(item.fieldOptions);
        setRange(item.isRange);
        setAssociation(item?.associationField);
        tog_standard();
    };

    const handleAddField = () => {
        setEditField(null);
        setName(null);
        setType(null);
        setUnit(null);
        setAssociation(null);
        setDropdownValues([{id: null, name: ""}])
        tog_standard();
    };

    const getField = (type) => {
        if (type === "INPUT_FIELD") {
            return props.t("Text");
        } else if (type === "FILE") {
            return props.t("File");
        } else if (type === "NUMBER_FIELD") {
            return props.t("Number");
        } else if (type === "HEADER") {
            return props.t("Header");
        } else if (type === "BOOLEAN") {
            return props.t("Boolean");
        } else if (type === "DROPDOWN") {
            return props.t("Dropdown")
        } else if (type === "DROPDOWN_MULTISELECT") {
            return props.t("Dropdown MultiSelect")
        } else if (type === "RANGE") {
            return props.t("Range");
        } else if (type === "FREE_TEXT") {
            return props.t("Free Text");
        } else if (type === "ASSOCIATION_FIELD"){
            return props.t("Association")
        } else {
            return props.t("");
        }
    };

    const addDropdownOption = () => {
        setDropdownValues(prevState => ([...prevState, {id: null, name: ""}]))
    }

    const removeDropdownOption = (index) => {
        var existingList = [...dropdownValues];
        existingList.splice(index, 1);
//        let existingList = dropdownValues.splice(index, 1);
        setDropdownValues(existingList);
    }

    const updateDropdownValue = (index, value) => {
        let existingList = dropdownValues;
        existingList[index].name = value;
        setDropdownValues(existingList);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg"
                                            style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={props.t("Fields")}
                                        buttons={[{
                                            text: props.t("Add Field"),
                                            onClick: handleAddField,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={loading} title="Fields"/>
                                {!loading &&
                                    <CardBody>
                                        {errorMessage && (
                                            <Row>
                                                <Alert color="danger">{errorMessage}</Alert>
                                            </Row>
                                        )}
                                        <MDBDataTable
                                            key={size(fieldList)}
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            // sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({
                                                        ...sortState,
                                                        direction: sortState.direction == 'asc' ? 'desc' : 'asc'
                                                    });
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editField ? props.t("Edit Field") : props.t("Add Field")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Label")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter fieldType label")}
                                value={name}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                        <label className="col-md-3 col-form-label">
                            {props.t("Field Type")}
                        </label>
                        <div className="col-md-9">
                            <select
                                className="form-control"
                                value={type}
                                disabled={editField}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="">{props.t("Select Field Type")}</option>
                                <option value="INPUT_FIELD">{props.t("Text")}</option>
                                <option value="NUMBER_FIELD">{props.t("Number")}</option>
                                <option value="FREE_TEXT">{props.t("Free Text")}</option>
                                <option value="FILE">{props.t("File")}</option>
                                <option value="HEADER">{props.t("Header")}</option>
                                <option value="BOOLEAN">{props.t("Boolean")}</option>
                                <option value="DROPDOWN">{props.t("Dropdown")}</option>
                                <option value="DROPDOWN_MULTISELECT">{props.t("Dropdown MultiSelect")}</option>
                                <option value="ASSOCIATION_FIELD">{props.t("Association")}</option>
                                {/*<option value="RANGE">{props.t("Range")}</option>*/}
                            </select>
                        </div>
                    </Row>
                    {type == "ASSOCIATION_FIELD" &&
                        <Row className="mb-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                                {props.t("Association")}
                            </label>
                            <div className="col-md-9">
                                <select
                                    className="form-control"
                                    value={association}
                                    onChange={(e) => setAssociation(e.target.value)}>
                                    <option value="">{props.t("Select field")}</option>
                                    {ASSOCIATION_FIELD_LIST[params.type].map(item => <option value={item.value}>{props.t(item.label)}</option>)}
                                </select>
                            </div>
                        </Row>}
                    {(type === "NUMBER_FIELD" || type === "RANGE") && <>
                        <Row className="mb-3 mt-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                                {props.t("Unit")}
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => setUnit(e.target.value)}
                                    placeholder={props.t("Enter unit")}
                                    value={unit}
                                />
                            </div>
                        </Row>
                        <Row className="mb-3 mt-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                                {props.t("Range")}
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultChecked={range}
                                    onChange={(e) => setRange(!range)}
                                />
                            </div>
                        </Row>
                    </>}
                    {(type === "DROPDOWN" || type === "DROPDOWN_MULTISELECT") && (
                        <Row className="mb-3 mt-3">
                            <label
                                htmlFor="example-text-input"
                                className="col-md-3 col-form-label"
                            >
                            </label>
                            <div className="col-md-9">
                                {dropdownValues?.map((object, index) => {
                                    return (<>
                                        <div className="col-md-12">
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={object.name}
                                                style={{display: "inline", width: "72%"}}
                                                onChange={(e) => updateDropdownValue(index, e.target.value)}
                                                placeholder={props.t("Add option")}
                                            />
                                            <button
                                                type="button"
                                                onClick={addDropdownOption}
                                                className="btn btn-primary btn-sm waves-effect m-lg-2">
                                                <i className="uil uil-plus"></i>
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => removeDropdownOption(index)}
                                                className="btn btn-danger btn-sm waves-effect m-lg-2">
                                                <i className="uil uil-trash"></i>
                                            </button>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </Row>
                    )}

                    <Row className="mb-3 mt-3">
                        <label className="col-md-3 col-form-label">
                            {props.t("Permission")}
                        </label>
                        <div className="col-md-9">
                            <select
                                className="form-control"
                                value={permission}
                                onChange={(e) => setPermission(e.target.value)}
                            >
                                <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                                <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                            </select>
                        </div>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            tog_standard();
                        }}
                        className="col-md-2 btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("Close")}
                    </button>
                    <Button
                        color="primary"
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor
                        }}
                        onClick={() => handleSaveField(false)}
                        disabled={type == null}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>

            <Modal
                isOpen={modal_confirm}
                toggle={() => {
                    tog_confirm();
                }}
            >
                <div className="modal-header">
                    <h4 className="modal-title">
                        {props.t("Warning")}!
                    </h4>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <h4>{props.t("Do you want to change permission of content for existing articles?")}</h4>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            handleSaveField(false);
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t("No only new articles")}
                    </button>
                    <Button
                        color="primary"
                        onClick={() => {
                            handleSaveField(true)
                        }}
                        style={{
                            backgroundColor: brandSettings?.primaryBtnColor,
                            borderColor: brandSettings?.primaryBtnColor
                        }}
                        className="btn btn-primary waves-effect waves-light"
                    >
                        {props.t("Yes all articles")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteField()}
                element={"Field"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteFieldModal}
                toggleModal={() => toggleDeleteField()}
                confirmation={true}
                warningMsg={!isEmpty(articleFields) ? "The content fieldType is referenced inside Articles." : null}
            />
        </React.Fragment>
    );
};

FieldList.propTypes = {
    fields: PropTypes.array,
    onGetFields: PropTypes.func,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        fields: state.ecommerce.fields,
        getFieldsError: state.ecommerce.getFieldsError,
        loading: state.ecommerce.getFieldsLoading
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetFields: (type) => dispatch(getFields(type)),
    onGetFieldsReset: () => dispatch(getFieldsReset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(FieldList)));
