export const API_ERR_MSG = "There was an error. Please contact admin.";
export const SPINNER_DEFAULT_COLOR = "#5f6972";

export const LANGUAGE_MASTER_LIST = [
    {value: 'Abkhazian', key: 'ab'},
    {value: 'Afar', key: 'aa'},
    {value: 'Afrikaans', key: 'af'},
    {value: 'Akan', key: 'ak'},
    {value: 'Albanian', key: 'sq'},
    {value: 'Amharic', key: 'am'},
    {value: 'Arabic', key: 'ar'},
    {value: 'Aragonese', key: 'an'},
    {value: 'Argentina', key: 'es-ar'},
    {value: 'Armenian', key: 'hy'},
    {value: 'Assamese', key: 'as'},
    {value: 'Avaric', key: 'av'},
    {value: 'Avestan', key: 'ae'},
    {value: 'Aymara', key: 'ay'},
    {value: 'Azerbaijani', key: 'az'},
    {value: 'Bambara', key: 'bm'},
    {value: 'Bashkir', key: 'ba'},
    {value: 'Basque', key: 'eu'},
    {value: 'Belarusian', key: 'be'},
    {value: 'Bengali (Bangla)', key: 'bn'},
    {value: 'Bihari', key: 'bh'},
    {value: 'Bislama', key: 'bi'},
    {value: 'Bosnian', key: 'bs'},
    {value: 'Breton', key: 'br'},
    {value: 'Bulgarian', key: 'bg'},
    {value: 'Burmese', key: 'my'},
    {value: 'Catalan', key: 'ca'},
    {value: 'Chamorro', key: 'ch'},
    {value: 'Chechen', key: 'ce'},
    {value: 'Chichewa, Chewa, Nyanja', key: 'ny'},
    {value: 'Chinese', key: 'zh'},
    {value: 'Chinese (Simplified)', key: 'zh-Hans'},
    {value: 'Chinese (Traditional)', key: 'zh-Hant'},
    {value: 'Chuvash', key: 'cv'},
    {value: 'Cornish', key: 'kw'},
    {value: 'Corsican', key: 'co'},
    {value: 'Cree', key: 'cr'},
    {value: 'Croatian', key: 'hr'},
    {value: 'Czech', key: 'cs'},
    {value: 'Danish', key: 'da'},
    {value: 'Divehi, Dhivehi, Maldivian', key: 'dv'},
    {value: 'Dutch', key: 'nl'},
    {value: 'Dzongkha', key: 'dz'},
    {value: 'English', key: 'en'},
    {value: 'Esperanto', key: 'eo'},
    {value: 'Estonian', key: 'et'},
    {value: 'Ewe', key: 'ee'},
    {value: 'Faroese', key: 'fo'},
    {value: 'Fijian', key: 'fj'},
    {value: 'Finnish', key: 'fi'},
    {value: 'French', key: 'fr'},
    {value: 'Fula, Fulah, Pulaar, Pular', key: 'ff'},
    {value: 'Galician', key: 'gl'},
    {value: 'Gaelic (Scottish)', key: 'gd'},
    {value: 'Gaelic (Manx)', key: 'gv'},
    {value: 'Georgian', key: 'ka'},
    {value: 'German', key: 'de'},
    {value: 'Greek', key: 'el'},
    {value: 'Greenlandic', key: 'kl'},
    {value: 'Guarani', key: 'gn'},
    {value: 'Gujarati', key: 'gu'},
    {value: 'Haitian Creole', key: 'ht'},
    {value: 'Hausa', key: 'ha'},
    {value: 'Hebrew', key: 'he'},
    {value: 'Herero', key: 'hz'},
    {value: 'Hindi', key: 'hi'},
    {value: 'Hiri Motu', key: 'ho'},
    {value: 'Hungarian', key: 'hu'},
    {value: 'Icelandic', key: 'is'},
    {value: 'Ido', key: 'io'},
    {value: 'Igbo', key: 'ig'},
    {value: 'Indonesian', key: 'id, in'},
    {value: 'Interlingua', key: 'ia'},
    {value: 'Interlingue', key: 'ie'},
    {value: 'Inuktitut', key: 'iu'},
    {value: 'Inupiak', key: 'ik'},
    {value: 'Irish', key: 'ga'},
    {value: 'Italian', key: 'it'},
    {value: 'Japanese', key: 'ja'},
    {value: 'Javanese', key: 'jv'},
    {value: 'Kalaallisut, Greenlandic', key: 'kl'},
    {value: 'Kannada', key: 'kn'},
    {value: 'Kanuri', key: 'kr'},
    {value: 'Kashmiri', key: 'ks'},
    {value: 'Kazakh', key: 'kk'},
    {value: 'Khmer', key: 'km'},
    {value: 'Kikuyu', key: 'ki'},
    {value: 'Kinyarwanda (Rwanda)', key: 'rw'},
    {value: 'Kirundi', key: 'rn'},
    {value: 'Kyrgyz', key: 'ky'},
    {value: 'Komi', key: 'kv'},
    {value: 'Kongo', key: 'kg'},
    {value: 'Korean', key: 'ko'},
    {value: 'Kurdish', key: 'ku'},
    {value: 'Kwanyama', key: 'kj'},
    {value: 'Lao', key: 'lo'},
    {value: 'Latin', key: 'la'},
    {value: 'Latvian (Lettish)', key: 'lv'},
    {value: 'Limburgish ( Limburger)', key: 'li'},
    {value: 'Lingala', key: 'ln'},
    {value: 'Lithuanian', key: 'lt'},
    {value: 'Luga-Katanga', key: 'lu'},
    {value: 'Luganda, Ganda', key: 'lg'},
    {value: 'Luxembourgish', key: 'lb'},
    {value: 'Manx', key: 'gv'},
    {value: 'Macedonian', key: 'mk'},
    {value: 'Malagasy', key: 'mg'},
    {value: 'Malay', key: 'ms'},
    {value: 'Malayalam', key: 'ml'},
    {value: 'Maltese', key: 'mt'},
    {value: 'Maori', key: 'mi'},
    {value: 'Marathi', key: 'mr'},
    {value: 'Marshallese', key: 'mh'},
    {value: 'Moldavian', key: 'mo'},
    {value: 'Mongolian', key: 'mn'},
    {value: 'Nauru', key: 'na'},
    {value: 'Navajo', key: 'nv'},
    {value: 'Ndonga', key: 'ng'},
    {value: 'Northern Ndebele', key: 'nd'},
    {value: 'Nepali', key: 'ne'},
    {value: 'Norwegian', key: 'no'},
    {value: 'Norwegian bokmål', key: 'nb'},
    {value: 'Norwegian nynorsk', key: 'nn'},
    {value: 'Nuosu', key: 'ii'},
    {value: 'Occitan', key: 'oc'},
    {value: 'Ojibwe', key: 'oj'},
    {value: 'Old Church Slavonic, Old Bulgarian', key: 'cu'},
    {value: 'Oriya', key: 'or'},
    {value: 'Oromo (Afaan Oromo)', key: 'om'},
    {value: 'Ossetian', key: 'os'},
    {value: 'Pāli', key: 'pi'},
    {value: 'Pashto, Pushto', key: 'ps'},
    {value: 'Persian (Farsi)', key: 'fa'},
    {value: 'Polish', key: 'pl'},
    {value: 'Portuguese', key: 'pt'},
    {value: 'Punjabi (Eastern)', key: 'pa'},
    {value: 'Quechua', key: 'qu'},
    {value: 'Romansh', key: 'rm'},
    {value: 'Romanian', key: 'ro'},
    {value: 'Russian', key: 'ru'},
    {value: 'Sami', key: 'se'},
    {value: 'Samoan', key: 'sm'},
    {value: 'Sango', key: 'sg'},
    {value: 'Sanskrit', key: 'sa'},
    {value: 'Serbian', key: 'sr'},
    {value: 'Serbo-Croatian', key: 'sh'},
    {value: 'Sesotho', key: 'st'},
    {value: 'Setswana', key: 'tn'},
    {value: 'Shona', key: 'sn'},
    {value: 'Sichuan Yi', key: 'ii'},
    {value: 'Sindhi', key: 'sd'},
    {value: 'Sinhalese', key: 'si'},
    {value: 'Siswati', key: 'ss'},
    {value: 'Slovak', key: 'sk'},
    {value: 'Slovenian', key: 'sl'},
    {value: 'Somali', key: 'so'},
    {value: 'Southern Ndebele', key: 'nr'},
    {value: 'Spanish', key: 'es'},
    {value: 'Sundanese', key: 'su'},
    {value: 'Swahili (Kiswahili)', key: 'sw'},
    {value: 'Swati', key: 'ss'},
    {value: 'Swedish', key: 'sv'},
    {value: 'Tagalog', key: 'tl'},
    {value: 'Tahitian', key: 'ty'},
    {value: 'Tajik', key: 'tg'},
    {value: 'Tamil', key: 'ta'},
    {value: 'Tatar', key: 'tt'},
    {value: 'Telugu', key: 'te'},
    {value: 'Thai', key: 'th'},
    {value: 'Tibetan', key: 'bo'},
    {value: 'Tigrinya', key: 'ti'},
    {value: 'Tonga', key: 'to'},
    {value: 'Tsonga', key: 'ts'},
    {value: 'Turkish', key: 'tr'},
    {value: 'Turkmen', key: 'tk'},
    {value: 'Twi', key: 'tw'},
    {value: 'Uyghur', key: 'ug'},
    {value: 'Ukrainian', key: 'uk'},
    {value: 'Urdu', key: 'ur'},
    {value: 'Uzbek', key: 'uz'},
    {value: 'Venda', key: 've'},
    {value: 'Vietnamese', key: 'vi'},
    {value: 'Volapük', key: 'vo'},
    {value: 'Wallon', key: 'wa'},
    {value: 'Welsh', key: 'cy'},
    {value: 'Wolof', key: 'wo'},
    {value: 'Western Frisian', key: 'fy'},
    {value: 'Xhosa', key: 'xh'},
    {value: 'Yiddish', key: 'yi'},
    {value: 'Yoruba', key: 'yo'},
    {value: 'Zhuang, Chuang', key: 'za'},
    {value: 'Zulu', key: 'zu'},
];
