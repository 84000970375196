import {
  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL_RESET,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_METRICS, GET_INVOICE_METRICS_FAIL,
  GET_INVOICE_METRICS_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICES_RESET,
  GET_INVOICES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  invoices: [],
  invoiceDetail: {},
  error: null,
  invoiceDetailError: null,
  invoiceDetailLoading: false,
  invoiceMetrics: {},
  invoiceMetricsLoading: false,
  invoiceMetricsError: null
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        error: null,
      }

    case GET_INVOICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_INVOICES_RESET:
      return {
        invoices: [],
        error: null,
      }
    case GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: null,
        invoiceDetailLoading: true
      }
    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
        invoiceDetailLoading: false
      }

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        invoiceDetailError: action.payload,
        invoiceDetailLoading: false
      }
    case GET_INVOICE_DETAIL_RESET:
      return {
        ...state,
        invoiceDetail: action.payload,
        invoiceDetailError: null,
        invoiceDetailLoading: false
      }
    case GET_INVOICE_METRICS:
      return {
        ...state,
        invoiceMetrics: null,
        invoiceMetricsLoading: true,
        invoiceMetricsError: null
      }
    case GET_INVOICE_METRICS_SUCCESS:
      return {
        ...state,
        invoiceMetrics: action.payload,
        invoiceMetricsLoading: false,
      }
    case GET_INVOICE_METRICS_FAIL:
      return {
        ...state,
        invoiceMetricsLoading: false,
        invoiceMetricsError: action.payload
      }
    default:
      return state
  }
}

export default Invoices
