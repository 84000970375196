import {getCurrencyList} from "./fakebackend_helper";
import {find} from "lodash";

export function formatCurrency(code = null, value = null) {
    if (code == null || value == null) {
        return "";
    }

    let CURRENCY_LIST = JSON.parse(localStorage.getItem("currency"));
    if (CURRENCY_LIST == null) {
        let response = getCurrencyList();
        if (response.status == 200) {
            localStorage.setItem("currency", JSON.stringify(response.data));
            CURRENCY_LIST = response.data;
        }
    }
    let currency = find(CURRENCY_LIST, {"code": code});
    if (currency) {
        let symbol = currency.symbol ? currency.symbol : currency.code;
        let format = value + " " + symbol;
        if (currency.currencySymbolPosition === "PRE_FIX") {
            format = symbol + " " + value;
        }
        return format;
    }
    return value + " " + code;
}

export function convertToSEK(value, code, CURRENCY_LIST){
    let itemCurrency = find(CURRENCY_LIST, {"code": code});
    if(itemCurrency && itemCurrency?.sellRate){
        return value * itemCurrency?.sellRate;
    }
    return value;
}

export function convertCurrency(code = null, value = null){
    if (code == null || value == null) {
        return "";
    }

    let CURRENCY_LIST = JSON.parse(localStorage.getItem("currency"));
    let TENANT_CURRENCY = JSON.parse(localStorage.getItem("authUser"))?.config?.currency;

    if (CURRENCY_LIST == null) {
        let response = getCurrencyList();
        if (response.status == 200) {
            localStorage.setItem("currency", JSON.stringify(response.data));
            CURRENCY_LIST = response.data;
        }
    }

    let SEKValue = convertToSEK(value, code, CURRENCY_LIST);
    let SEK = find(CURRENCY_LIST, {"code": "SEK"});

    let convertedValue = SEKValue;
    if(TENANT_CURRENCY?.buyRate){
        if(TENANT_CURRENCY?.buyRate <= SEK?.buyRate){
            convertedValue = SEKValue * TENANT_CURRENCY.buyRate;
        } else {
            convertedValue = SEKValue / TENANT_CURRENCY.buyRate;
        }
    }
    convertedValue = convertedValue.toFixed(2);
    let symbol = TENANT_CURRENCY.symbol ? TENANT_CURRENCY.symbol : TENANT_CURRENCY.code;
    let format = convertedValue + " " + symbol;
    if (TENANT_CURRENCY.currencySymbolPosition === "PRE_FIX") {
        format = symbol + " " + convertedValue;
    }
    return format;
}