import {Col, Input, Label, Row} from "reactstrap";
import React from "react";
import Dropzone from "react-dropzone";
import {DOWNLOAD_URL} from "../../helpers/url_helper";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {del} from "../../helpers/api_helper";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import DropdownSelect from "../../components/Common/DropdownSelect";
import Select from "react-select";
import { preventNumberInputScroll, validateNumber } from "../../util/helperFunctions";


const ProjectTemplateCustomField = (props) => {
    let { field, getFieldValue, handleFieldValueChange, handleCustomFreeTextFieldChange, getEditorFieldValue} = props;

    return (<>

        {field.fieldType == "HEADER" &&
            <Row className="mb-3" key={"content-header-"+ field.id}>
                <div className="col-md-12">
                    <h5>{field.name}</h5>
                </div>
            </Row>
        }

        {field.fieldType == "INPUT_FIELD" &&
            <Row className="mb-2">
                <label htmlFor="example-text-input"
                       className="col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>
                <div>
                    <input
                        className="form-control"
                        type="text"
                        defaultValue={getFieldValue(field).value}
                        onChange={(e) => handleFieldValueChange(field, e.target.value, field.unit)}
                    />
                </div>
            </Row>
        }

        {field.fieldType == "NUMBER_FIELD" &&
            <Row className="mb-3" key={"content-" + field.id}>
                <label htmlFor="example-text-input"
                       className="col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>
                <div>
                    <input
                        className="form-control"
                        type="number"
                        defaultValue={getFieldValue(field)?.value}
                        onChange={(e) => handleFieldValueChange(field, e.target.value, field.unit)}
                        onWheel={preventNumberInputScroll}
                        // onKeyDown={validateNumber}
                    />
                </div>
                <div className="col-md-3 pt-2">
                    <span>{field.unit}</span>
                </div>
            </Row>
        }

        {field.fieldType == "BOOLEAN" &&
            <Row className="mb-3" key={"field-" + field.id}>
                <label htmlFor="example-text-input"
                       className="col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>
                <Row>
                    <Col xs={1}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={"exampleRadios"+ field.id}
                                defaultChecked={getFieldValue(field)?.value == "true"}
                                onChange={(e) => handleFieldValueChange(field, true, field.unit)}
                                id={"exampleRadios"+ field.id}/>
                            <label
                                className="form-check-label">
                                {props.t("Yes")}
                            </label>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={"exampleRadios"+ field.id}
                                defaultChecked={getFieldValue(field)?.value == "false"}
                                onChange={(e) => handleFieldValueChange(field, false, field.unit)}
                                id={"exampleRadios"+ field.id}/>
                            <label
                                className="form-check-label">
                                {props.t("No")}
                            </label>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name={"exampleRadios"+ field.id}
                                id={"exampleRadios"+ field.id}
                                defaultChecked={getFieldValue(field)?.value == null}
                                onChange={(e) => handleFieldValueChange(field, null, field.unit)}
                            />
                            <label
                                className="form-check-label">
                                {props.t("Not Applicable")}
                            </label>
                        </div>
                    </Col>
                </Row>
            </Row>
        }
        {field.fieldType == "FREE_TEXT" &&

            <Row className="mb-3">
                {field.name && <label htmlFor="example-text-input"
                                      className=" col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>}
                <div>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        defaultEditorState={getEditorFieldValue(field)}
                        onEditorStateChange={(editorState) => handleCustomFreeTextFieldChange(field, editorState)}
                    />

                </div>
            </Row>
        }

        {field.fieldType == "DROPDOWN" &&
            <Row className="mb-3" key={"content-" + field.id}>
                <label htmlFor="example-text-input"
                       className="col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>
                <div>
                    <select className="form-control"
                            defaultValue={getFieldValue(field)?.value}
                            onChange={(e) => handleFieldValueChange(field, e.target.value, field.unit)}
                    >
                        <option value={""}></option>
                        {field.fieldOptions?.map((option) => {
                            return (<option value={option.name}>{option.name}</option>)
                        })}
                    </select>
                </div>
            </Row>}
        {field.fieldType == "DROPDOWN_MULTISELECT" &&
            <Row className="mb-3" key={"content-" + field.id}>
                <label htmlFor="example-text-input"
                       className="col-form-label">
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                </label>
                <div>
                    <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={getFieldValue(field).value == null || (getFieldValue(field).value == null && getFieldValue(field).value.trim() == "") ? [] : getFieldValue(field).value.split(',').filter(v => v && v.length > 0).map(v => ({value: v, label: v}))}
                        onChange={(vals) => handleFieldValueChange(field, vals?.map(v => v.value).join(','), field.unit)}
                        options={field.fieldOptions?.map(o => ({value: o.name, label: o.name}))}
                    />
                </div>
            </Row>}
    </>)
}

export default connect()(withTranslation()(ProjectTemplateCustomField))
