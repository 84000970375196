
import { orderBy } from 'lodash';
import { findIndex } from 'lodash';
import { MDBDataTable } from 'mdbreact';
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Card, CardBody, CardHeader, Col, Container, Row, Modal, Button, } from 'reactstrap';
import DeleteModal from '../../components/Common/DeleteModal';
import Loader from '../../components/Common/Loader';
import TableToolbar from '../../components/Common/TableToolbar';
import { del, post } from '../../helpers/api_helper';
import { getTaskPriorities } from '../../store/actions';
import { parseDateToString, preventNumberInputScroll, validateNumber } from '../../util/helperFunctions';


const TaskPriority = (props) => {

    let { onGetTaskPriorities, taskPriorities, taskPrioritiesError, taskPrioritiesLoading } = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [modal_standard, setmodal_standard] = useState(false);
    const [name, setName] = useState(null);
    const [dueDays, setDueDays] = useState("");
    const [editTaskPriority, setEditTaskPriority] = useState(null);
    const [deleteTaskPriorityId, setDeleteTaskPriorityId] = useState(null);
    const [showDeleteTaskPriorityModal, setShowDeleteTaskPriorityModal] = useState(false);
    const [deleteTaskPriorityTaskReferenceCount, setDeleteTaskPriorityTaskReferenceCount] = useState(0);
    const [taskPriorityList, setTaskPriorityList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = [];
    const user = JSON.parse(localStorage.getItem("authUser"));

    useEffect(() => {
        setBrandSettings(user?.brandConfig);

        onGetTaskPriorities();
    }, [])

    useEffect(() => {
        if (deleteTaskPriorityId) {
            toggleDeleteTaskPriority();
        }
    }, [deleteTaskPriorityId])

    useEffect(() => {
        if(taskPriorities){
            setTaskPriorityList(taskPriorities);
        }
    }, [taskPriorities]);

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setTaskPriorityList(orderBy(taskPriorityList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    const handleSaveTaskPriorities = async () => {
        let response = await post(`/api/taskPriorities`, { ...editTaskPriority, name, dueDays });
        setName(null);
        setDueDays(null);
        onGetTaskPriorities()
        tog_standard();
    };

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function toggleDeleteTaskPriority() {
        if (showDeleteTaskPriorityModal) {
            setDeleteTaskPriorityId(null);
        }
        setShowDeleteTaskPriorityModal(!showDeleteTaskPriorityModal);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const getDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Due Days"),
                    field: "dueDays",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Actions"),
                    field: "actions",
                    autoWidth: true,
                },
            ],
            rows: taskPriorityList?.map((taskPriority, key) => ({
                name: taskPriority.name,
                dueDays: taskPriority.dueDays,
                actions: (
                    <div className='d-flex'>
                        <i
                            className={`uil uil-pen me-3 ${(!user.tenantId || taskPriority.tenant) ? 'text-warning' : 'text-muted'} font-size-18`}
                            title={(!user.tenantId || taskPriority.tenant) ? null : 'Task Priority is generic and cannot be edited.'}
                            onClick={() => {
                                if(!user.tenantId || taskPriority.tenant){
                                    handleEditTaskPriority(taskPriority)
                                }
                            }}
                        ></i>
                        <i
                            className={`uil uil-trash-alt px-3 ${(!user.tenantId || taskPriority.tenant) && taskPriority.taskReferenceCount == 0 ? 'text-danger' : 'text-muted'} font-size-18`}
                            title={taskPriority.taskReferenceCount > 0 ? `Task Priority cannot be deleted because it contains ${taskPriority.taskReferenceCount} task record(s).` : 
                                ((!user.tenantId || taskPriority.tenant) ? null : 'Task Priority is generic and cannot be deleted.')}
                            onClick={() => {
                                if((!user.tenantId || taskPriority.tenant) && taskPriority.taskReferenceCount == 0){
                                    setDeleteTaskPriorityId(taskPriority.id);
                                }
                            }}
                        ></i>
                        <div className="form-check form-switch ms-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                checked={taskPriority.enabled}
                                style={taskPriority.enabled ? {
                                    backgroundColor: brandSettings?.primaryBtnColor,
                                    borderColor: brandSettings?.primaryBtnColor
                                } : {}}
                                onChange={() => toggleTaskPriorityState(taskPriority)}
                            />
                            {taskPriority.enabled && <div className="badge bg-soft-success bg-success p-1 ms-2">{props.t("Enabled")}</div>}
                            {!taskPriority.enabled && <div className="badge bg-soft-warning bg-warning p-1 ms-2">{props.t("Disabled")}</div>}
                        </div>
                    </div>
                ),
            })),
        };
        return data;
    };

    const toggleTaskPriorityState = async (item) => {
        let response = item.enabled ? await post(`/api/taskPriorities/disabled/${item.id}`) 
            : await del(`/api/taskPriorities/disabled/${item.id}`);
        if(response.status == 202) {
            let updatedTypes = [...taskPriorityList];
            var index = findIndex(updatedTypes, {id: item.id});
            updatedTypes[index] = {...updatedTypes[index], enabled: !item.enabled}
            setTaskPriorityList(updatedTypes);
        }
    };

    const handleAddTaskPriority = () => {
        setEditTaskPriority(null);
        setName(null);
        setDueDays(null);
        tog_standard();
    };

    const handleEditTaskPriority = (taskPriority) => {
        setEditTaskPriority(taskPriority);
        setName(taskPriority.name);
        setDueDays(taskPriority.dueDays);
        tog_standard();
    };

    const handleDeleteTaskPriority = async () => {
        let response = await del(`/api/taskPriorities/${deleteTaskPriorityId}`);
        setDeleteTaskPriorityTaskReferenceCount(0)
        onGetTaskPriorities();
        setDeleteTaskPriorityId(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                                    <TableToolbar
                                        title={props.t("Task Priorities")}
                                        buttons={[{
                                            text: props.t("New Task Priority"),
                                            onClick: handleAddTaskPriority,
                                        }]}
                                    />
                                </CardHeader>
                                <Loader loading={taskPrioritiesLoading} title="Task Priorities" />
                                {!taskPrioritiesLoading && (
                                    <CardBody>
                                        {taskPrioritiesError && (
                                            <Row>
                                                <Col className="col-12">
                                                    <Alert color="danger">{taskPrioritiesError}</Alert>
                                                </Col>
                                            </Row>
                                        )}

                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t(
                                                "No matching records found"
                                            )}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            searching={true}
                                            key={taskPriorityList.length}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>)}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                isOpen={modal_standard}
                toggle={() => {
                    tog_standard();
                }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {editTaskPriority ? props.t("Edit Task Priority") : props.t("Add Task Priority")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setmodal_standard(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row className="mb-3">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                        >
                            {props.t("Task Priority")}
                        </label>
                        <div className="col-md-9">
                            <input
                                className="form-control"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                placeholder={props.t("Enter Task Priority")}
                                value={name}
                            />
                        </div>
                    </Row>
                    <Row className="mb-3">
                            <label className="col-md-3 col-form-label">
                                {props.t("Due Days")}
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control"
                                    type="number"
                                    onChange={(e) => setDueDays(e.target.value)}
                                    placeholder={props.t("Enter Due Days")}
                                    value={dueDays}
                                    onWheel={preventNumberInputScroll}
                                    // onKeyDown={validateNumber}
                                />
                            </div>
                        </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        onClick={() => handleSaveTaskPriorities()}
                        className="col-md-2 btn btn-primary waves-effect waves-light"
                        disabled={!name}
                    >
                        {props.t("Save")}
                    </Button>
                </div>
            </Modal>
            <DeleteModal
                deleteCallback={() => handleDeleteTaskPriority()}
                element={"Task Priority"}
                t={(text) => props.t(text)}
                showDeleteModal={showDeleteTaskPriorityModal}
                toggleModal={() => toggleDeleteTaskPriority()}
                confirmation={true}
                warningMsg={deleteTaskPriorityTaskReferenceCount > 0 ? "The task priority is referenced inside Tasks." : null}
            />

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        taskPriorities: state.task.taskPriorities,
        taskPrioritiesLoading: state.task.taskPrioritiesLoading,
        taskPrioritiesError: state.task.taskPrioritiesError,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTaskPriorities: () => dispatch(getTaskPriorities())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TaskPriority)));