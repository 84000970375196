import {
    GET_CUSTOMER_LIST_IMPERSONATION,
    GET_CUSTOMER_LIST_IMPERSONATION_FAIL,
    GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS,
    GET_IMPERSONATE_USER_EXIT,
    GET_IMPERSONATE_USER_EXIT_SUCCESS,
    GET_IMPERSONATION_USERS,
    GET_IMPERSONATION_USERS_FAIL,
    GET_IMPERSONATION_USERS_SUCCESS,
    GET_TENANT_LIST,
    GET_TENANT_LIST_FAIL,
    GET_TENANT_LIST_SUCCESS,
    POST_IMPERSONATE_USER,
    POST_IMPERSONATE_USER_SUCCESS
} from "./actionTypes";

export const getUserForImpersonation = (tenantId, customerId, roles) => ({
  type: GET_IMPERSONATION_USERS,
  data: {
    roles: roles,
    tenantId : tenantId,
    customerId: customerId
  }
});

export const getUserForImpersonationSuccess = (users) => {
  return {
    type: GET_IMPERSONATION_USERS_SUCCESS,
    payload: users.data,
  };
};

export const getUserForImpersonationFail = (error) => ({
  type: GET_IMPERSONATION_USERS_FAIL,
  payload: error,
});

export const impersonateUser = (obj, history, location, isEventImpersonation) => {
  return {
    type: POST_IMPERSONATE_USER,
    payload: { obj, history, location, isEventImpersonation},
  }
}

export const impersonateUserSuccess = (obj) => {
  return {
    type: POST_IMPERSONATE_USER_SUCCESS,
    payload: obj,
  }
}

export const exitUserImpersonation = (history) => {
  return{
    type: GET_IMPERSONATE_USER_EXIT,
    payload: { history }
  }
}

export const exitUserImpersonationSuccess = (user) => {
  return{
    type: GET_IMPERSONATE_USER_EXIT_SUCCESS,
    payload: { user }
  }
}


export const getTenantList = () => ({
  type: GET_TENANT_LIST
})

export const getTenantListSuccess = (response) => ({
  type: GET_TENANT_LIST_SUCCESS,
  payload: response.data
})

export const getTenantListFail = (error) => ({
  type: GET_TENANT_LIST_FAIL,
  payload: error
})

export const getCustomerListImpersonation = () => ({
  type: GET_CUSTOMER_LIST_IMPERSONATION
})

export const getCustomerListImpersonationSuccess = (response) => ({
  type: GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS,
  payload: response.data
})

export const getCustomerListImpersonationFail = (error) => ({
  type: GET_CUSTOMER_LIST_IMPERSONATION_FAIL,
  payload: error
})
