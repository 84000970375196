import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {find, orderBy} from "lodash";
import PropTypes from "prop-types";
import {getOrderReset, getOrders} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import TableToolbar from "../../components/Common/TableToolbar";
import {API_ERR_MSG} from "../../util/constants";
import {checkIfLoading} from "../../store/selector";
import {ecommerceActionTypes} from "../../store/e-commerce/actionTypes";
import Loader from "../../components/Common/Loader";
import {downloadPDF, getFilterClassName} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";


const Orders = (props) => {
  const { 
    orders, 
    onGetOrders, 
    loading, 
    error,
    onGetOrderReset
  } = props;
  const [orderList, setOrderList] = useState([]);
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null)
  const [orderType, setOrderType] = useState('all');
  const [sortState, setSortState] = useState(null);
  const numericColumns = ['DocumentNumber', 'Total'];
  let user = JSON.parse(localStorage.getItem("authUser"));
  let level = localStorage.getItem("level");
  const fortnoxConnectionStatus = localStorage.getItem("fortnox-connection-status") === "true";
  // const [searchResults, setSearchResults] = useState([]);
  // const [search, setSearch] = useState("");
  // const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setBrandSettings(user?.brandConfig);
    setOrderType("all");
    return () => {
        onGetOrderReset();
    }
  }, [])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetOrderReset()}, 5000);
    }
  }, [error])


  useEffect(() => {
    if(orderType){
      onGetOrders(orderType)
    }
  }, [orderType])

  useEffect(() => {
    if(orders){
      setOrderList(orders);
    }
  }, [orders]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setOrderList(orderBy(orderList, o => numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase(), [direction]))
    }
  }, [sortState])

  // const handleSearch = () => {
  //   if (!isEmpty(search)) {
  //     const results = filter(
  //       orderList,
  //       (order) =>
  //         includes(order.displayName?.toLowerCase(), search.toLowerCase()) ||
  //         includes(order.description?.toLowerCase(), search.toLowerCase()) ||
  //         filter(order.categories, (c) =>
  //           includes(c.name?.toLowerCase(), search.toLowerCase())
  //         ).length > 0
  //     );

  //     setSearchResults(results);
  //   } else {
  //     setSearchResults(orderList);
  //   }
  //   setSearchValue(search);
  // };

  const getDatatableData = () => {
    let cols = [
      {
        label: props.t("Customer"),
        field: "CustomerName",
        width: 150,
      },
      {
        label: props.t("Order Number"),
        field: "DocumentNumber",
        width: 150,
      },
      {
        label: props.t("Our Reference"),
        field: "OurReference",
        width: 150,
      },
      {
        label: props.t("Status"),
        field: "status",
        width: 150,
      },
      {
        label: props.t("Total"),
        field: "Total",
        width: 150,
      },
      {
        label: props.t("OrderDate"),
        field: "OrderDate",
        width: 150,
      },
      {
        label: props.t("Download"),
        field: "download",
      }
    ];

    if(user?.customerId){
      cols.splice(0, 1)
    }

    let data = {
      columns: cols,
      rows: orderList.map(order => ({
        CustomerName: <div onClick={() => openOrderDetails(order)}>{order.CustomerName}</div>,
        DocumentNumber: <div onClick={() => openOrderDetails(order)}>{order.DocumentNumber}</div>,
        OurReference: <div onClick={() => openOrderDetails(order)}>{order.OurReference}</div>,
        status: <><div onClick={() => openOrderDetails(order)} className={`badge ${getFilterClassName(order.status)} p-1`}>{props.t(order.status)}</div>{(user?.tenantKey == 'LIGHTAB' && level == "TENANT" && order?.StockPointCode == 'LAGER' && hasApproved(order)) && <div  className={`badge bg-soft-warning bg-warning p-1 margin-left-1`}>{props.t("Awaiting approval")}</div>}</>,
        Total: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.TotalWithoutTax)}</div>,
        OrderDate: <div onClick={() => openOrderDetails(order)}>{order.OrderDate}</div>,
        download: <div onClick={() => downloadPDF(order.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
      })),
    };
    return data;
  };

  const hasApproved = (orderDetails) => {
    return (orderDetails?.Labels?.length == 0 || find(orderDetails?.Labels, {Id:4}) == null) && orderDetails?.WarehouseReady !== true;
  }

  const openOrderDetails = (order) => {
    const fromUrl = "?from=" + encodeURIComponent(props.location.pathname);
    history.push(`${user?.customerId ? '/customer' : ''}/orders/${order.DocumentNumber + fromUrl}`);
  };

  const handleFilter = (value) => {
    setOrderType(value);
  }

  const getFilter = () => {
    let types = [{name: props.t("All"), value:"all"},
      {name: props.t("Ongoing"), value:"invoicenotcreated"},
      {name: props.t("Invoiced"), value:"invoicecreated"},
      {name: props.t("Delayed"), value: "expired"}];

    if(level == "TENANT"){
      types.push({name: props.t("Cancelled"), value: "cancelled"})
    }
    return types;
  }

  const getButtons = () => {
    let btn = [];
    if(level == "TENANT" && fortnoxConnectionStatus === true){
      btn.push({
        text: props.t("New Order"),
        onClick: () => history.push(`/order/new`),
      });
    }
    return btn;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        
        <Container fluid>

          <Row>
            <Col className="col-12 article-table"> 
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                    title={props.t("Orders")}
                    filter={{
                      selected: orderType,
                      options : getFilter(),
                      onSelect: handleFilter
                    }}
                    buttons={getButtons()}
                  />
                </CardHeader>
                <Loader loading={loading} title="Orders" />
                {!loading && 
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(API_ERR_MSG)}
                    </Alert>
                  }
                  <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    onSearch={(text) => console.log(text)}
                    searching={true}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    sortRows={[]}
                    onSort={({column, direction}) => {
                      if(sortState && sortState.column == column){
                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                      } else {
                        setSortState({column, direction});
                      }
                    }}
                  />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Orders.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    orders: state.ecommerce.orders?.data?.Orders,
    loading: checkIfLoading(state, ecommerceActionTypes.GET_ORDERS),
    error: state.ecommerce.getOrdersError
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetOrders: (type) => dispatch(getOrders(type)),
  onGetOrderReset: () => dispatch(getOrderReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Orders)));
