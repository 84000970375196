import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader, Col, Container, Row,} from "reactstrap";
import {useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {getBrandSettings, getBrandSettingsIncludingSaSettings, postResetBrandColors} from "../../store/actions";
import {connect} from "react-redux";
import TableToolbar from "../../components/Common/TableToolbar";
import {SketchPicker} from "react-color";
import ColorLensIcon from "@material-ui/icons/ColorLens";
import CloseIcon from "@material-ui/icons/Close";
import {Avatar, IconButton} from '@material-ui/core'
import PersonIcon from "@material-ui/icons/Person";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {postBrandSettings} from "../../helpers/fakebackend_helper";
import {del} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import cogoToast from "cogo-toast";
import { toastOptions } from "../../util/dataUtil";

const BrandSettings = (props) => {
    const {onUpdateBrandSettings, onGetBrandSettings, onResetBrandColors, onGetBrandSettingsIncludingSaSettings} = props;

    const regexPattern = /^#[0-9A-F]{6}$/i;
    const [primaryColor, setPrimaryColor] = useState();
    const [primaryBackgroundColor, setPrimaryBackgroundColor] = useState(null);
    const [secondaryColor, setSecondaryColor] = useState(null);
    const [secondaryBackgroundColor, setSecondaryBackgroundColor] = useState(null);
    const [primaryBtnColor, setPrimaryBtnColor] = useState(null);

    const [primaryOpen, setPrimaryOpen] = useState(false);
    const [primaryBackgroundOpen, setPrimaryBackgroundOpen] = useState(false);
    const [secondaryOpen, setSecondaryOpen] = useState(false);
    const [secondaryBackgroundOpen, setSecondaryBackgroundOpen] = useState(false);
    const [primaryBtnColorOpen, setPrimaryBtnColorOpen] = useState(false);

    const [edit, setEdit] = useState(false);
    const [image, setImage] = useState([]);
    const [logo, setLogo] = useState();
    const [brandSettings, setBrandSettings] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [deleteImageText, setDeleteImageText] = useState("Delete Image");
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("authUser")));

    useEffect(() => {
        if(user?.tenantId){
            onGetBrandSettingsIncludingSaSettings();
        } else {
            onGetBrandSettings();
        }
    }, [])

    useEffect(() => {
        setPrimaryColor(props.brandSettings.primaryColor);
        setPrimaryBackgroundColor(props.brandSettings.primaryBackgroundColor);
        setSecondaryColor(props.brandSettings.secondaryColor);
        setSecondaryBackgroundColor(props.brandSettings.secondaryBackgroundColor);
        setPrimaryBtnColor(props.brandSettings.primaryBtnColor);
        if(props.brandSettings.brandLogo){
            setImage([BASE_IMAGE_URL + "/"+ props.brandSettings.brandLogo]);
        }
        setBrandSettings(props.brandSettings);
        setUser(JSON.parse(localStorage.getItem("authUser")));
    }, [props.brandSettings]);

    useEffect(() => {
        if(resetting){
            handleReset();
        }
    }, [resetting])

    const [isError, setError] = useState({
        primary: false,
        primaryBackground: false,
        secondary: false,
        secondaryBackground: false,
        primaryBtn: false
    });

    const handlePrimaryColorChange = (color) => {
        setPrimaryColor(color.hex);
    };
    const handlePrimaryBackgroundColorChange = (color) => {
        setPrimaryBackgroundColor(color.hex);
    };

    const handleSecondaryColorChange = (color) => {
        setSecondaryColor(color.hex);
    };
    const handleSecondaryBackgroundColorChange = (color) => {
        setSecondaryBackgroundColor(color.hex);
    };

    const handlePrimaryBtnColorChange = (color) => {
        setPrimaryBtnColor(color.hex);
    }
    const HandleOpenClose = (con) => {
        switch (con) {
            case "P":
                setPrimaryOpen(!primaryOpen);
                setPrimaryBackgroundOpen(false);
                setSecondaryOpen(false);
                setSecondaryBackgroundOpen(false);
                setPrimaryBtnColorOpen(false);
                break;
            case "PBG":
                setPrimaryBackgroundOpen(!primaryBackgroundOpen);
                setPrimaryOpen(false);
                setSecondaryOpen(false);
                setSecondaryBackgroundOpen(false);
                setPrimaryBtnColorOpen(false);
                break;
            case "S":
                setSecondaryOpen(!secondaryOpen);
                setSecondaryBackgroundOpen(false);
                setPrimaryOpen(false);
                setPrimaryBackgroundOpen(false);
                setPrimaryBtnColorOpen(false);
                break;
            case "SBG":
                setSecondaryBackgroundOpen(!secondaryBackgroundOpen);
                setPrimaryOpen(false);
                setPrimaryBackgroundOpen(false);
                setSecondaryOpen(false);
                setPrimaryBtnColorOpen(false);
                break;
            case "PB":
                setPrimaryBtnColorOpen(!primaryBtnColorOpen);
                setSecondaryOpen(false);
                setSecondaryBackgroundOpen(false);
                setPrimaryOpen(false);
                setPrimaryBackgroundOpen(false);
            default:
                break;
        }
    };

    const handlePrimaryColor = (e) => {
        setError({...isError, primary: false});
        setPrimaryColor(e.target.value);
    };
    
    const handlePrimaryBackgroundColor = (e) => {
        setError({...isError, primaryBackground: false});
        setPrimaryBackgroundColor(e.target.value);
    };

    const handleSecondaryColor = (e) => {
        setError({...isError, secondary: false});
        setSecondaryColor(e.target.value);
    };

    const handleSecondaryBackgroundColor = (e) => {
        setError({...isError, secondaryBackground: false});
        setSecondaryBackgroundColor(e.target.value);
    };

    const handlePrimaryBtnColor = (e) => {
        setError({...isError, primaryBtn: false});
        setPrimaryBtnColor(e.target.value);
    };

    const handleImage = (e) => {
        if (e.target.files) {
            // console.log(e.target.files.match());
            const filesArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file),
            );
            setImage(filesArray);
            setLogo(e.target.files[0]);
            Array.from(e.target.files).map(
                (file) => URL.revokeObjectURL(file), // avoid memory leak
            );
        }
    };

    const handleReset = async () => {
        await onResetBrandColors();
        setBrandSettings(user?.brandConfig);
        cogoToast.success(props.t("Brand settings updated"), toastOptions);
        setResetting(false);
    }

    const isColorAvailable = (color) => {
        return color && color !== '' && color !== 'undefined' && color !== 'null';
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploading(true);
        let validColor = true;
        if ((isColorAvailable(primaryColor) && !regexPattern.test(primaryColor)) 
          && (isColorAvailable(primaryBackgroundColor) && !regexPattern.test(primaryBackgroundColor)) 
          && (isColorAvailable(secondaryColor) && !regexPattern.test(secondaryColor)) 
          && (isColorAvailable(secondaryBackgroundColor) && !regexPattern.test(secondaryBackgroundColor) )
          && (isColorAvailable(primaryBtnColor) && !regexPattern.test(primaryBtnColor))) {
            cogoToast.error(props.t("Color formats are incorrect"), toastOptions);
            setError({primary: true, primaryBackground: true, secondary: true, secondaryBackground: true, primaryBtn: true});
            validColor = false;
        } else if (isColorAvailable(primaryColor) && !regexPattern.test(primaryColor)) {
            cogoToast.error(props.t("Primary color format is incorrect"), toastOptions);
            setError({...isError, primary: true});
            validColor = false;
        } else if (isColorAvailable(primaryBackgroundColor) && !regexPattern.test(primaryBackgroundColor)) {
            cogoToast.error(props.t("Primary background color format is incorrect"), toastOptions);
            setError({...isError, primaryBackground: true});
            validColor = false;
        } else if (isColorAvailable(secondaryColor) && !regexPattern.test(secondaryColor)) {
            cogoToast.error(props.t("Secondary color format is incorrect"), toastOptions);
            setError({...isError, secondary: true});
            validColor = false;
        } else if (isColorAvailable(secondaryBackgroundColor) && !regexPattern.test(secondaryBackgroundColor)) {
            cogoToast.error(props.t("Secondary ackground color format is incorrect"), toastOptions);
            setError({...isError, secondaryBackground: true});
            validColor = false;
        } else if (isColorAvailable(primaryBtnColor) && !regexPattern.test(primaryBtnColor)) {
            cogoToast.error(props.t("Primary button color format is incorrect"), toastOptions);
            setError({...isError, primaryBtn: true});
            validColor = false;
        }
        if (validColor) {
            setUploading(true)
            setError({primary: false, primaryBackground: false, secondary: false, secondaryBackground: false, primaryBtn: false});
    
            let formData = new FormData();
            if(logo){
                formData.append("file", logo);
            }
            if(isColorAvailable(primaryColor)){
                formData.append("primaryColor", primaryColor);
            }
            if(isColorAvailable(primaryBackgroundColor)){
                formData.append("primaryBackgroundColor", primaryBackgroundColor);
            }
            if(isColorAvailable(secondaryColor)){
                formData.append("secondaryColor", secondaryColor);
            }
            if(isColorAvailable(secondaryBackgroundColor)){
                formData.append("secondaryBackgroundColor", secondaryBackgroundColor);
            }
            if(isColorAvailable(primaryBtnColor)){
                formData.append("primaryBtnColor", primaryBtnColor);
            }
            let response = await postBrandSettings(formData);
            let updateUser = JSON.parse(localStorage.getItem("authUser"));
            updateUser.brandConfig = response.data;
            localStorage.setItem("authUser", JSON.stringify(updateUser));
            setUser(updateUser);
            setBrandSettings(user?.brandConfig);
            cogoToast.success(props.t("Brand settings updated"), toastOptions);
            setTimeout(() => window.location.reload(), 2000);
        }
        setUploading(false);
    };

    const handleDeleteImage = async () => {
        setDeleteImageText("Deleting...");
        let response = await del("/api/brand/remove-image");
        let updatedUser = JSON.parse(localStorage.getItem("authUser"));
        updatedUser.brandConfig = response.data;
        localStorage.setItem("authUser", JSON.stringify(updatedUser));
        setBrandSettings(user?.brandConfig);
        window.location.reload();
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Loader loading={props.brandSettings.loading}  title="Brand Settings" />
                    <Row>
                        <Col className="col-12 article-table">
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: user?.brandConfig?.primaryColor ? user?.brandConfig?.primaryColor : user?.brandConfig?.superAdminPrimaryColor}}
                                >
                                    <TableToolbar title={props.t("Brand Settings")}/>
                                </CardHeader>
                                {!props.brandSettings.loading && 
                                <CardBody>
                                    <>
                                        <div className="row mb-4" style={{margin: "auto"}}>
                                            <input
                                                accept='image/*'
                                                id='icon-button-file'
                                                type='file'
                                                onChange={handleImage}
                                                style={{
                                                    display: 'none'
                                                }}
                                            />
                                            <label htmlFor='icon-button-file'>
                                                <IconButton
                                                    color='primary'
                                                    aria-label='upload picture'
                                                    component='span'
                                                >
                                                    <Avatar
                                                        variant="rounded"
                                                        src={image[0]}
                                                        style={{
                                                            margin: "10px",
                                                            width: "150px",
                                                            height: "100%",
                                                            "object-fit": "contain",
                                                        }}
                                                    >
                                                        <PersonIcon/>
                                                    </Avatar>
                                                </IconButton>
                                            </label>
                                            {image.length > 0 && <div className="col-xs-2" style={{marginLeft:"25px"}}>
                                                <Button disabled={deleteImageText != 'Delete Image'} className="ml-4 bg-danger cancel-btn btn-sm" onClick={handleDeleteImage}>{props.t(deleteImageText)}</Button>
                                            </div>}
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <label for="PrimaryColorInput">{props.t("Primary Color")}</label>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="ml-4 colorButton"
                                                        style={{
                                                            backgroundColor: primaryColor ? primaryColor : props.brandSettings?.superAdminPrimaryColor,
                                                            width: '2.2vw',
                                                            height: '2vw',
                                                            borderRadius: '50%',
                                                            margin: '0.4vw 0.8vw 0.4vw 0.4vw'
                                                        }}></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="PrimaryColorInput"
                                                        placeholder={
                                                            props.brandSettings?.superAdminPrimaryColor
                                                        }
                                                        maxlength="7"
                                                        value={primaryColor}
                                                        onChange={handlePrimaryColor}
                                                        style={{
                                                            borderColor: isError.primary ? "red" : "",
                                                        }}
                                                    />
                                                    <button
                                                        className="ml-4 colorButton"
                                                        onClick={() => {
                                                            HandleOpenClose("P");
                                                        }}
                                                        style={{
                                                            borderColor: `${primaryOpen ? "red" : ""}`,
                                                        }}
                                                    >
                                                        {primaryOpen ? (
                                                            <CloseIcon
                                                                style={{
                                                                    color: `${primaryOpen ? "red" : "white"}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ColorLensIcon/>
                                                        )}
                                                    </button>
                                                </div>
                                                {primaryOpen && (
                                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                                        <SketchPicker
                                                            color={primaryColor ? primaryColor : props.brandSettings?.superAdminPrimaryColor ? props.brandSettings?.superAdminPrimaryColor : '#FFFFFF'}
                                                            onChange={handlePrimaryColorChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-3">
                                                <label for="PrimaryBackgroundColorInput">{props.t("Primary Background Color")}</label>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="ml-4 colorButton"
                                                        style={{
                                                            backgroundColor: primaryBackgroundColor ? primaryBackgroundColor : props.brandSettings?.superAdminPrimaryBackgroundColor,
                                                            width: '2.2vw',
                                                            height: '2vw',
                                                            borderRadius: '50%',
                                                            margin: '0.4vw 0.8vw 0.4vw 0.4vw'
                                                        }}></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="PrimaryBackgroundColorInput"
                                                        placeholder={
                                                            props.brandSettings?.superAdminPrimaryBackgroundColor
                                                        }
                                                        maxlength="7"
                                                        value={primaryBackgroundColor}
                                                        onChange={handlePrimaryBackgroundColor}
                                                        style={{
                                                            borderColor: isError.primaryBackground ? "red" : "",
                                                        }}
                                                    />
                                                    <button
                                                        className="ml-4 colorButton"
                                                        onClick={() => {
                                                            HandleOpenClose("PBG");
                                                        }}
                                                        style={{
                                                            borderColor: `${primaryBackgroundOpen ? "red" : ""}`,
                                                        }}
                                                    >
                                                        {primaryBackgroundOpen ? (
                                                            <CloseIcon
                                                                style={{
                                                                    color: `${primaryBackgroundOpen ? "red" : "white"}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ColorLensIcon/>
                                                        )}
                                                    </button>
                                                </div>
                                                {primaryBackgroundOpen && (
                                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                                        <SketchPicker
                                                            color={primaryBackgroundColor ? primaryBackgroundColor : props.brandSettings?.superAdminPrimaryBackgroundColor ? props.brandSettings?.superAdminPrimaryBackgroundColor : '#FFFFFF'}
                                                            onChange={handlePrimaryBackgroundColorChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-3">
                                                <label for="SecondryColorInput">{props.t("Secondary Color")}</label>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="ml-4 colorButton"
                                                        style={{
                                                            backgroundColor: secondaryColor ? secondaryColor : props.brandSettings?.superAdminSecondaryColor,
                                                            width: '2.2vw',
                                                            height: '2vw',
                                                            borderRadius: '50%',
                                                            margin: '0.4vw 0.8vw 0.4vw 0.4vw'
                                                        }}></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="SecondryColorInput"
                                                        placeholder={
                                                            props.brandSettings?.superAdminSecondaryColor
                                                        }
                                                        value={secondaryColor}
                                                        maxlength="7"
                                                        onChange={handleSecondaryColor}
                                                        style={{
                                                            borderColor: isError.secondary ? "red" : "",
                                                        }}
                                                    />
                                                    <button
                                                        className="ml-4 colorButton"
                                                        onClick={() => {
                                                            HandleOpenClose("S");
                                                        }}
                                                        style={{
                                                            borderColor: `${secondaryOpen ? "red" : ""}`,
                                                        }}
                                                    >
                                                        {secondaryOpen ? (
                                                            <CloseIcon
                                                                style={{
                                                                    color: `${secondaryOpen ? "red" : "white"}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ColorLensIcon/>
                                                        )}
                                                    </button>
                                                </div>
                                                {secondaryOpen && (
                                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                                        <SketchPicker
                                                            color={secondaryColor ? secondaryColor : props.brandSettings?.superAdminSecondaryColor ? props.brandSettings?.superAdminSecondaryColor : '#FFFFFF'}
                                                            onChange={handleSecondaryColorChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-3">
                                                <label for="SecondryBackgroundColorInput">{props.t("Secondary Background Color")}</label>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="ml-4 colorButton"
                                                        style={{
                                                            backgroundColor: secondaryBackgroundColor ? secondaryBackgroundColor : props.brandSettings?.superAdminSecondaryBackgroundColor,
                                                            width: '2.2vw',
                                                            height: '2vw',
                                                            borderRadius: '50%',
                                                            margin: '0.4vw 0.8vw 0.4vw 0.4vw'
                                                        }}></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="SecondryBackgroundColorInput"
                                                        placeholder={
                                                            props.brandSettings?.superAdminSecondaryBackgroundColor
                                                        }
                                                        value={secondaryBackgroundColor}
                                                        maxlength="7"
                                                        onChange={handleSecondaryBackgroundColor}
                                                        style={{
                                                            borderColor: isError.secondaryBackground ? "red" : "",
                                                        }}
                                                    />
                                                    <button
                                                        className="ml-4 colorButton"
                                                        onClick={() => {
                                                            HandleOpenClose("SBG");
                                                        }}
                                                        style={{
                                                            borderColor: `${secondaryBackgroundOpen ? "red" : ""}`,
                                                        }}
                                                    >
                                                        {secondaryBackgroundOpen ? (
                                                            <CloseIcon
                                                                style={{
                                                                    color: `${secondaryBackgroundOpen ? "red" : "white"}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ColorLensIcon/>
                                                        )}
                                                    </button>
                                                </div>
                                                {secondaryBackgroundOpen && (
                                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                                        <SketchPicker
                                                            color={secondaryBackgroundColor ? secondaryBackgroundColor : props.brandSettings?.superAdminSecondaryBackgroundColor ? props.brandSettings?.superAdminSecondaryBackgroundColor : '#FFFFFF'}
                                                            onChange={handleSecondaryBackgroundColorChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-3">
                                                <label>{props.t("Button Color")}</label>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div
                                                        className="ml-4 colorButton"
                                                        style={{
                                                            backgroundColor: primaryBtnColor ? primaryBtnColor : props.brandSettings?.superAdminPrimaryBtnColor,
                                                            width: '2.2vw',
                                                            height: '2vw',
                                                            borderRadius: '50%',
                                                            margin: '0.4vw 0.8vw 0.4vw 0.4vw'
                                                        }}></div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="SecondryColorInput"
                                                        placeholder={
                                                            props.brandSettings?.superAdminPrimaryBtnColor
                                                        }
                                                        value={primaryBtnColor}
                                                        maxLength="7"
                                                        onChange={handlePrimaryBtnColor}
                                                        style={{
                                                            borderColor: isError.primaryBtn ? "red" : "",
                                                        }}
                                                    />
                                                    <button
                                                        className="ml-4 colorButton"
                                                        onClick={() => {
                                                            HandleOpenClose("PB");
                                                        }}
                                                        style={{
                                                            borderColor: `${primaryBtnColorOpen ? "red" : ""}`,
                                                        }}
                                                    >
                                                        {primaryBtnColorOpen ? (
                                                            <CloseIcon
                                                                style={{
                                                                    color: `${primaryBtnColorOpen ? "red" : "white"}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ColorLensIcon/>
                                                        )}
                                                    </button>
                                                </div>
                                                {primaryBtnColorOpen && (
                                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                                        <SketchPicker
                                                            color={primaryBtnColor ? primaryBtnColor : props.brandSettings?.superAdminPrimaryBtnColor ? props.brandSettings?.superAdminPrimaryBtnColor : '#FFFFFF'}
                                                            onChange={handlePrimaryBtnColorChange}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <Button disabled={uploading || resetting} className="mt-4" onClick={handleSubmit} style={{
                                                backgroundColor: brandSettings?.primaryBtnColor
                                            }}>
                                                {uploading ? props.t("Updating...") : props.t("Update")}
                                            </Button>
                                            <Button
                                                className="mt-4 bg-danger cancel-btn"
                                                onClick={() => {
                                                    setEdit(false);
                                                    setPrimaryColor(props?.brandSettings.primaryColor)
                                                    setPrimaryBackgroundColor(props?.brandSettings.primaryBackgroundColor)
                                                    setSecondaryColor(props?.brandSettings.secondaryColor)
                                                    setSecondaryBackgroundColor(props?.brandSettings.secondaryBackgroundColor)
                                                    setPrimaryBtnColor(props?.brandSettings.primaryBtnColor)
                                                }}
                                            >
                                                {props.t("Cancel")}
                                            </Button>
                                            <Button
                                                className="mt-4 bg-warning cancel-btn float-right"
                                                onClick={() => {
                                                    setResetting(true);
                                                }}
                                                disabled={props.brandSettings.loading || resetting || uploading}
                                            >
                                                {resetting ? props.t("Resetting...") : props.t("Reset to Default")}
                                            </Button>
                                            <hr/>
                                        </div>
                                    </>
                                </CardBody>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

BrandSettings.propTypes = {
    brandSettings: PropTypes.object,
    onGetBrandSettings: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        brandSettings: {...state?.BrandSettings},
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetBrandSettings: () => dispatch(getBrandSettings()),
    onGetBrandSettingsIncludingSaSettings: () => dispatch(getBrandSettingsIncludingSaSettings()),
    onResetBrandColors: () => dispatch(postResetBrandColors())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(BrandSettings)));
