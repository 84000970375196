import {
    GET_ALL_CART_ITEMS_SUCCESS, 
    POST_ADD_CART_ITEM_FAIL, 
    POST_ADD_CART_ITEM_SUCCESS,
    PUT_UPDATE_CART_ITEM,
    PUT_UPDATE_CART_ITEM_FAIL,
    PUT_UPDATE_CART_ITEM_FROM_INPUT,
    PUT_UPDATE_CART_ITEM_SUCCESS,
    RESET_CART_MESSAGE,
} from "./actionTypes";

import {
    COPY_ORDER_TO_CART,
    COPY_ORDER_TO_CART_SUCCESS,
    COPY_ORDER_TO_CART_FAIL,
    COPY_ORDER_TO_CART_RESET
} from "./../actionTypes";

const INIT_STATE = {
    cartItems: [],
    cartItemError: null,
    cartItemsLoading: false,
    copyToCartLoading: false,
    copyToCartError: null,
}

const Cart = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_CART_ITEMS_SUCCESS:
        case POST_ADD_CART_ITEM_SUCCESS:
            return {
                ...state,
                cartItems: action.payload,
                cartItemError: null,
            }
        case POST_ADD_CART_ITEM_FAIL:
            return {
                ...state,
                cartItemError: action.payload
            }
        case RESET_CART_MESSAGE:
            return {
                ...state,
                cartItemError: null
            }
        case PUT_UPDATE_CART_ITEM_FROM_INPUT:
            const cartItem = action.payload;
            return {
                ...state,
                cartItems: state.cartItems.map(i => ({...i, quantity: i.id == cartItem.id ? cartItem.quantity : i.quantity})),
            }
        case PUT_UPDATE_CART_ITEM:
            return {
                ...state,
                cartItemError:null,
                cartItemsLoading: true
            }
        case PUT_UPDATE_CART_ITEM_SUCCESS:
            return {
                ...state,
                cartItemError: null,
                cartItems: action.payload,
                cartItemsLoading: false
            }
        case PUT_UPDATE_CART_ITEM_FAIL:
            return {
                ...state,
                cartItemError: action.payload,
                cartItemsLoading: false
            }
        case COPY_ORDER_TO_CART:
            return {
                ...state,
                copyToCartLoading: true
            }
        case COPY_ORDER_TO_CART_SUCCESS:
            return {
                ...state,
                cartItems: action.payload,
                copyToCartLoading: false
            }
        case COPY_ORDER_TO_CART_FAIL:
            return {
                ...state,
                copyToCartError: action.payload,
                copyToCartLoading: false
            }

        case COPY_ORDER_TO_CART_RESET:
            return {
                ...state,
                copyToCartLoading: false,
                copyToCartError: null
            }
        default:
            return {
                ...state
            }
    }
}

export default Cart;