import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getSystemConfig, getSystemConfigReset} from "../../store/config/actions";
import {connect} from "react-redux";
import {post} from "../../helpers/api_helper";
import { checkIfLoading } from "../../store/selector";
import { configActionTypes } from "../../store/config/actionTypes";
import { API_ERR_MSG } from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";

const FortnoxSystemConfig = (props) => {
    const {header} = props.location.state;

    const {onGetSystemConfig, systemConfig, loading, onGetSystemConfigReset } = props

    const [baseUrl, setBaseUrl] = useState("")
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const [brandSettings, setBrandSettings] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    useEffect(()=>{
        setBrandSettings(user?.brandConfig);
        onGetSystemConfig();
        return () => {
            onGetSystemConfigReset();
        }
    },[])

    useEffect(()=> {
        setBrandSettings(user?.brandConfig);
        setBaseUrl(systemConfig.filter(obj => obj.configKey === 'baseUrl')[0]?.configValue || "")
    }, [systemConfig])

    useEffect(()=> {
        if(props.error){
            setError(props.error)
            setTimeout(() => {onGetSystemConfigReset()}, 5000);
            setTimeout(() => {setError(null)}, 5000);
        }
    }, [props.error])

    const submitForm = event=> {
        let fields = Array.from(event.target.getElementsByTagName('input'))
        post('/api/settings/systemConfig', fields.reduce((obj, cur) => ({...obj, [cur.id]: cur.value}), {}))
            .then(data => {
                if(data?.status !== 200) {
                    setError(API_ERR_MSG)
                } else {
                    setSuccess(props.t("System configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                }
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={loading} title="Fortnox Config" />
                                {!loading && 
                                <form onSubmit={(event) => {
                                    event.preventDefault()
                                    submitForm(event)
                                }}>
                                    
                                <CardBody>
                                    {error ? (
                                        <Alert color="danger">{error}</Alert>
                                    ) : null}

                                    {success ? (
                                        <Alert color="success">{success}</Alert>
                                    ) : null}

                                    <Row className="mb-3">
                                        <label
                                            htmlFor="example-text-input"
                                            className="col-md-2 col-form-label"
                                        >
                                            {props.t("Fortnox base url")}
                                        </label>
                                        <div className="col-md-10">
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder={props.t("Enter Fortnox base url")}
                                                id="baseUrl"
                                                defaultValue={baseUrl}
                                                onChange={clearError}
                                                disabled={loading}
                                            />
                                        </div>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col align="center">
                                            <Button
                                                type="submit"
                                                color="success"
                                                className="btn btn-success w-lg waves-effect waves-light"
                                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                disabled={loading}
                                            >
                                                {props.t("Save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                                </form>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

FortnoxSystemConfig.propTypes = {
    systemConfig: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        systemConfig: state.Config.systemConfig,
        loading: checkIfLoading(state, configActionTypes.GET_SYSTEM_CONFIG),
        error: state.Config.systemConfigError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetSystemConfig: () => dispatch(getSystemConfig()),
    onGetSystemConfigReset: () => dispatch(getSystemConfigReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(FortnoxSystemConfig));
