import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import ReactLoading from "react-loading";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  TENANT_ADMIN_RESET_DELETE_USER,
  TENANT_ADMIN_RESET_REGENERATE_TOKEN,
} from "../../store/users/tenant/actionTypes";
import { GetCustomerUsers, GetCustomerUsersReset } from "../../store/customer/actions";
import { get, post, put } from "../../helpers/api_helper";
import CustomerUsersActions from "./CustomerUsersActions";
import TableToolbar from "../../components/Common/TableToolbar";
import { filter, includes, isEqual, isEmpty } from "lodash-es";
import { API_ERR_MSG, SPINNER_DEFAULT_COLOR } from "../../util/constants";
import Loader from "../../components/Common/Loader";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { impersonateUser } from "../../store/actions";
import ButtonGroup from "../../components/Common/ButtonGroup";
import { orderBy } from "lodash";

export const customerUserManagementContext = React.createContext(null);

const CustomerUserManagement = (props) => {
  const {
    customerUsers,
    onGetCustomerUsers,
    tokenRegenerate,
    userDeleted,
    onResetRegenerateToken,
    onResetDeleteUser,
    isTenant,
    userActivated,
    loading,
    error,
    onImpersonateUser,
    onGetCustomerUsersReset,
    match: { params },
  } = props;
  const [customerUsersList, setCustomerUsersList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [selectedCustomerUser, setSelectedCustomerUser] = useState(undefined);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [brandSettings, setBrandSettings] = useState(null)
  const [filter, setFilter] = useState(null)
  const [sortState, setSortState] = useState(null);
  const numericColumns = [];
  let history = useHistory();
  const location = useLocation();

  const initFormFields = {
    name: "",
    email: "",
    id: 0,
    city: "",
    phone: "",
    role: "ROLE_CONTACT_USER",
  };

  const [formFields, setFormFields] = useState(initFormFields);

  const clearError = () => setErrorMessage("");

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(obj?.brandConfig);

    const prevFilter = localStorage.getItem("customerProfileUsersTabFilter");
    if(prevFilter){
        setFilter(prevFilter);
    } else {
      setFilter("active");
    }
    return () => {
      onGetCustomerUsersReset();
    };
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetCustomerUsersReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    setCustomerUsersList(customerUsers);
  }, [customerUsers]);

  useEffect(() => {
    if(sortState){
      const {column, direction} = sortState;
      setCustomerUsersList(orderBy(customerUsersList, o => {
          if(column === 'impersonate') return "";
          if(column === 'role') return getRoleName(o[column])?.toLowerCase()
          if(column === 'enabled') return o[column]
          return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
      }, [direction]))
    }
  }, [sortState])

  useEffect(() => {
    if(filter){
      localStorage.setItem("customerProfileUsersTabFilter", filter);
      onGetCustomerUsers(props.customerId, filter);
    }
  }, [filter]);

  useEffect(() => {
    if (tokenRegenerate && tokenRegenerate.status) {
      setSuccessMessage(props.t("Activation link sent to user."));
      setTimeout(() => {
        setSuccessMessage("");
        onResetRegenerateToken();
      }, 3000);
    } else if (tokenRegenerate) {
      setErrorMessage(props.t("Error sending activation link"));
      setTimeout(() => {
        setErrorMessage("");
        onResetRegenerateToken();
      }, 3000);
    }
  }, [tokenRegenerate]);

  useEffect(() => {
    if (userDeleted && userDeleted.status) {
      setSuccessMessage(props.t("User deactivated successfully."));
      setTimeout(() => {
        setSuccessMessage("");
        onResetDeleteUser();
      }, 3000);
      onGetCustomerUsers(props.customerId);
    } else if (userDeleted) {
      setErrorMessage(props.t("Error deleting user."));
      setTimeout(() => {
        setErrorMessage("");
        onResetDeleteUser();
      }, 3000);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (userActivated && userActivated.status) {
      setSuccessMessage(props.t("User activated successfully."));
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      onGetCustomerUsers(props.customerId);
    } else if (userActivated) {
      setErrorMessage(props.t("Error activated user."));
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  }, [userActivated]);

  useEffect(async () => {
    if (selectedCustomerUser) {
      let response = await get(
        `/api/customers/${props.customerId}/users/${selectedCustomerUser}`
      );
      let data = response.data;
      setFormFields({ ...data, username: data.username });
    }
  }, [selectedCustomerUser]);

  useEffect(() => {
    if (editMode) {
      document.querySelector("select[id=role]").value = formFields.role;
    }
  }, [formFields]);

  useEffect(() => {
    if(error){
      setErrorMessage(API_ERR_MSG);
    }
  }, [error])

  const createUser = (fields) => {
    let postData = fields.reduce(
      (obj, cur) => ({ ...obj, [cur.id]: cur.value }),
      {}
    );

    post(`/api/customers/${props.customerId}/users`, postData)
      .then((data) => {
        if (data?.status !== 200) {
          setErrorMessage(props.t(data?.data));
        } else {
          postData = [...customerUsersList, data.data];
          setCustomerUsersList(postData);
          setEditMode(false);
          history.push(`/customers/${props.customerId}/users/${data?.data?.id}`)
        }
      })
      .catch((err) => {
        setErrorMessage(props.t("There was an error. Please contact admin."));
      });
  };

  const updateUser = (fields) => {
    let formData = fields.reduce(
      (obj, cur) => ({ ...obj, [cur.id]: cur.value }),
      {}
    );
    put(`/api/customers/${props.customerId}/users/${formData.id}`, formData)
      .then((data) => {
        if (data?.status !== 200) {
          setErrorMessage(props.t(data?.data));
        } else {
          let data = customerUsersList.filter(
            (customer) => customer.id != formData.id
          );
          data = [...data, formData];
          setCustomerUsersList(data);
          setEditMode(false);
        }
      })
      .catch((err) => {
        setErrorMessage(props.t("There was an error. Please contact admin."));
      });
  };

  const validations = {
    name: ["required"],
    email: [
      "required",
      "regex::^[a-zA-Z0-9_.]+@[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9][.][a-zA-Z]{2,}$",
    ],
    city: ["required"],
    phone: ["required"],
  };

  const submitForm = (event) => {
    const err = [];
    let fields = Array.from(event.target.getElementsByTagName("input"));
    fields = [
      ...fields,
      ...Array.from(event.target.getElementsByTagName("select")),
    ];
    fields.forEach((field) => {
      validations[field.id]?.forEach((type) => {
        let validationType = type.split("::")[0];
        switch (validationType) {
          case "required":
            !(field.value && field.value.length > 0) &&
              err.push(`${field.id} is required field`);
            break;
          case "regex":
            if (
              field.value?.length > 0 &&
              !new RegExp(type.split("::")[1]).test(field.value)
            )
              err.push(`Please enter valid input in field ${field.id}`);
            break;
        }
      });
    });
    setErrorMessage(`${err.join("\r\n")}`);
    if (err.length === 0) {
      selectedCustomerUser ? updateUser(fields) : createUser(fields);
    }
  };

  function getRoleName(role) {
    if (role == "ROLE_TENANT_ADMIN") {
      return props.t("Tenant Admin");
    } else if (role == "ROLE_TENANT_USER") {
      return props.t("Tenant User");
    } else if (role == "ROLE_CUSTOMER_ADMIN") {
      return props.t("Customer Admin");
    } else if (role == "ROLE_CUSTOMER_USER") {
      return props.t("Customer User");
    } else if (role == "ROLE_CONTACT_USER") {
      return props.t("Contact");
    } else if (role == "ROLE_SUPER_ADMIN") {
      return props.t("Super Admin");
    }
    return role;
  }
  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        customerUsersList,
        (customer) =>
          includes(customer.name?.toLowerCase(), search.toLowerCase()) ||
          includes(customer.username?.toLowerCase(), search.toLowerCase()) ||
          filter(customer.city, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(customerUsersList);
    }
    setSearchValue(search);
  };

  function getImpersonateButton(user) {
    const { role } = user;
    return <>
    { (role && role != "ROLE_CONTACT_USER") && 
    <button onClick={()=> handleImpersonate(user)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}>
      <i className="fa fa-sign-in-alt"></i>
    </button>}</>
  }
  const getDatatableData = () => {
    let data = {
      columns: [
        {
          label: props.t("Name"),
          field: "name",
          width: 150,
        },
        {
          label: props.t("Username"),
          field: "username",
          width: 100,
        },
        {
          label: props.t("City"),
          field: "city",
        },
        {
          label: props.t("Phone"),
          field: "phone",
          width: 150,
        },
        {
          label: props.t("Role"),
          field: "role",
          width: 150,
        },
        {
          label: props.t("Status"),
          field: "enabled",
          width: 150,
        },
        {
          label: props.t("Actions"),
          field: "actions",
          autoWidth: true,
        },
        {
          label: props.t("Impersonate"),
          field: "impersonate",
        }
      ],
      rows: customerUsersList?.map((user, key) => ({
        name: <span onClick={() => handleOpen(user.id)}>{user.name}</span>,
        username: <span onClick={() => handleOpen(user.id)}>{user.username}</span>,
        city: <span onClick={() => handleOpen(user.id)}>{user.city}</span>,
        phone: <span onClick={() => handleOpen(user.id)}>{user.phone}</span>,
        role: <span onClick={() => handleOpen(user.id)}>{getRoleName(user.role)}</span>,
        enabled: <span onClick={() => handleOpen(user.id)}>{user.enabled ? props.t("Active") : props.t("Disabled")}</span>,
        impersonate: getImpersonateButton(user)
      })),
    };
    return data;
  };

  const handleFilter = (option) => {
    setFilter(option);
  }

  const handleImpersonate = (user) => {
    onImpersonateUser({ userEmail: user.username }, history, location)
  }

  const handleOpen = (id) => {
    history.push(`/customers/${props.customerId}/users/${id}?from=` + encodeURIComponent(props.location.pathname));
  };

  const handleAddUser = () => {
      history.push(`/customers/${props.customerId}/users/create`);
  };

  return (
    <React.Fragment>
      {!editMode && (
        <React.Fragment>
         
          <Row>
            <Col className="col-12 article-table">
              {isTenant && (<>
                

                <Loader loading={loading} title="Customer Users" />
                {error && (
                  <Alert className="text-align-center" color="danger">
                    {error}
                  </Alert>
                )}
              
                <div className="d-flex justify-content-end mb-3">
                  <ButtonGroup 
                    filter={{
                        selected: filter,
                        options : [
                            {name: props.t("All"), value:"active"},
                            {name: props.t("Users"), value:"users"},
                            {name: props.t("Contacts"), value: "contacts"},
                            {name: props.t("Disabled"), value: "disabled"},
                        ],
                        onSelect: (option) => {
                          handleFilter(option);
                        }
                    }}
                    brandSettings={brandSettings}     
                  />
                  <Button 
                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                    className="btn btn-primary waves-effect waves-light mx-1"
                    onClick={handleAddUser}
                  >{props.t("Add User")}
                  </Button>
                </div>
                {!loading && 
                <MDBDataTable
                  responsive
                  hover
                  barReverse={true}
                  borderless
                  paginationLabel={[props.t("Previous"), props.t("Next")]}
                  entriesLabel={props.t("Show entries")}
                  infoLabel={[
                    props.t("Showing"),
                    props.t("to"),
                    props.t("of"),
                    props.t("entries"),
                  ]}
                  searchLabel={props.t("Search") + "..."}
                  noBottomColumns={true}
                  data={getDatatableData()}
                  entries={50}
                  entriesOptions={[50, 100, 500]}
                  onSearch={(text) => console.log(text)}
                  noRecordsFoundLabel={props.t("No matching records found")}
                  sortRows={[]}
                  onSort={({column, direction}) => {
                    if(sortState && sortState.column == column){
                        setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                    } else {
                        setSortState({column, direction});
                    }
                  }}
                />}
                </>
              )}

              {!isTenant && (
                <Card>
                  <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                    <TableToolbar
                      title={props.t("Users")}
                      buttons={[{
                        text: props.t("Add User"),
                        onClick: () => history.push('/users/create'),
                      }]}
                      filter={{
                        selected: filter,
                        options : [
                            {name: props.t("All"), value:"active"},
                            {name: props.t("Users"), value:"users"},
                            {name: props.t("Contacts"), value: "contacts"},
                            {name: props.t("Disabled"), value:"disabled"}
                        ],
                        onSelect: handleFilter
                      }}
                    />
                  </CardHeader>

                  <Loader loading={loading} title="Customer Users" />

                  {!loading &&
                  <CardBody>
                      {successMessage && (
                        <Row>
                          <Alert color="success">{successMessage}</Alert>
                        </Row>
                      )}
                      {error && (
                        <Alert className="text-align-center" color="danger">
                          {error}
                        </Alert>
                      )}
                      <MDBDataTable
                        responsive
                        hover
                        barReverse={true}
                        searching={true}
                        borderless
                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                        entriesLabel={props.t("Show entries")}
                        infoLabel={[
                          props.t("Showing"),
                          props.t("to"),
                          props.t("of"),
                          props.t("entries"),
                        ]}
                        searchLabel={props.t("Search") + "..."}
                        noRecordsFoundLabel={props.t("No matching records found")}
                        noBottomColumns={true}
                        data={getDatatableData()}
                        entries={50}
                        entriesOptions={[50, 100, 500]}
                        onSearch={(text) => console.log(text)}
                      />
                  </CardBody>}
                </Card>
              )}
            </Col>
          </Row>
        </React.Fragment>
      )}
      {editMode && (
        <React.Fragment>
          <PageToolbarTop
              noBackBtn={true}
              backBtn={() => setEditMode(false)}
              breadcrumbs={[{title: props.t("Users"), link: "/customer/users"}]}
              title={props.t("Create User")}
          />
          {successMessage && (
            <Row>
              <Alert color="success">{successMessage}</Alert>
            </Row>
          )}
          {errorMessage && (
            <Row>
              <Alert color="danger">
                <pre>{errorMessage}</pre>
              </Alert>
            </Row>
          )}

            <Card>
              <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                <TableToolbar
                    title={"Create User"}
                />
              </CardHeader>
              <CardBody>
                <Col>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      submitForm(event);
                    }}
                  >
                    <p className="card-title-desc"></p>

                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("User Role")}
                      </label>
                      <div className="col-md-10">
                        <select
                          className="form-control"
                          id="role"
                          onChange={() => {}}
                          defaultValue={formFields.role}
                        >
                          <option value={"ROLE_CONTACT_USER"}>
                            {props.t("Contact")}
                          </option>
                          <option value={"ROLE_CUSTOMER_USER"}>
                            {props.t("User")}
                          </option>
                          <option value={"ROLE_CUSTOMER_ADMIN"}>
                            {props.t("Admin")}
                          </option>
                        </select>
                      </div>
                    </Row>

                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Name")}
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter name")}
                          id="name"
                          defaultValue={formFields.name}
                          onChange={clearError}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Username/Email")}
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="email"
                          placeholder={props.t("Enter email")}
                          id="username"
                          defaultValue={formFields.username}
                          onChange={clearError}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("City")}
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter city")}
                          id="city"
                          defaultValue={formFields.city}
                          onChange={clearError}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {props.t("Phone")}
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={props.t("Enter phone")}
                          id="phone"
                          defaultValue={formFields.phone}
                          onChange={clearError}
                        />
                      </div>
                    </Row>
                    {selectedCustomerUser && (
                      <input
                        type="hidden"
                        id="id"
                        defaultValue={formFields.id}
                      />
                    )}
                    <Row className="mb-3">
                      <Col align="center">
                        <Button
                          type="button"
                          color="secondary"
                          className="btn btn-secondary w-lg waves-effect waves-light"
                          onClick={() => setEditMode(false)}
                        >
                          {props.t("Cancel")}
                        </Button>{" "}
                        <Button
                          type="submit"
                          color="success"
                          className="btn btn-success w-lg waves-effect waves-light"
                          style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        >
                          {props.t("Save")}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </CardBody>
            </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CustomerUserManagement.propTypes = {
  customerUsers: PropTypes.array,
  onGetCustomerUsers: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    customerUsers: state.Customer.customerUsers,
    loading: state.Customer.customerUsersLoading,
    error: state.Customer.customerUsersError,
    tokenRegenerate: state.TenantAdmin.tokenRegenerate,
    userDeleted: state.TenantAdmin.userDeleted,
    userActivated: state.TenantAdmin.userActivated,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetCustomerUsers: (customerId, filter) => dispatch(GetCustomerUsers(customerId, filter)),
  onGetCustomerUsersReset: () => dispatch(GetCustomerUsersReset()),
  onResetRegenerateToken: () =>
    dispatch({
      type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
    }),
  onResetDeleteUser: () =>
    dispatch({
      type: TENANT_ADMIN_RESET_DELETE_USER,
    }),
  onImpersonateUser: (obj, history, location) =>
      dispatch(impersonateUser(obj, history, location)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CustomerUserManagement)));