import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getArticleConfig, getArticleConfigReset, getStockPointConfig, getStockPointConfigReset, getStockPoints} from "../../store/config/actions";
import {connect} from "react-redux";
import {post} from "../../helpers/api_helper";
import {configActionTypes} from "../../store/config/actionTypes";
import {checkIfLoading} from "../../store/selector";
import {API_ERR_MSG} from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";

const StockPointConfig = (props) => {
    const {header} = props.location.state;

    const {
        onGetStockPointConfig, stockPointConfig, configLoading, stockPointsLoading, onGetStockPointConfigReset, getStockPointConfigError, onGetStockPoints, stockPoints,
        onGetArticleConfig, articleConfig, loadingAC, onGetArticleConfigReset, getArticleConfigError
    } = props

    const [defaultStockPointId, setDefaultStockPointId] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [errorAC, setErrorAC] = useState(null);
    const [successAC, setSuccessAC] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);

    const [articleQuantityConfig, setArticleQuantityConfig] = useState({});

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    const clearErrorAC = () => setErrorAC(null)
    const clearSuccessAC = () => setSuccessAC(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetStockPoints();
        onGetStockPointConfig();
        return () => {
            onGetStockPointConfigReset();
        }
    }, [])

    useEffect(() => {
        if (stockPointConfig) {
            setDefaultStockPointId(stockPointConfig?.defaultStockPointId)
        }
    }, [stockPointConfig])

    useEffect(() => {
        if (getStockPointConfigError) {
            setError(getStockPointConfigError);
            setTimeout(() => {
                onGetStockPointConfigReset()
            }, 5000);
            setTimeout(() => {
                setError(null)
            }, 5000);
        }
    }, [getStockPointConfigError])

    const submitForm = (event) => {
        post('/api/settings/stockPointConfig', {defaultStockPointId: defaultStockPointId != "" ? defaultStockPointId : null})
            .then(data => {
                if (data?.status !== 202) {
                    setError(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                } else {
                    setSuccess(props.t("Default stock point configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                }
            }).catch(error => {
            setError("Fail to set default stock point config.");
            setTimeout(clearError, 5000)
        });
    }

    const handleDefaultStockPointConfigChange = (option) => {
        setDefaultStockPointId(option);
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetArticleConfig();
        document.cookie.split(";").forEach(cookie => {
            console.log(cookie);
        })
        return () => {
            onGetArticleConfigReset();
        }
    }, [])

    useEffect(() => {
        if (articleConfig) {
            setArticleQuantityConfig(articleConfig?.articleQuantityConfig)
        }
    }, [articleConfig])

    useEffect(() => {
        if (getArticleConfigError) {
            setErrorAC(getArticleConfigError);
            setTimeout(() => {
                onGetArticleConfigReset()
            }, 5000);
            setTimeout(() => {
                setErrorAC(null)
            }, 5000);
        }
    }, [getArticleConfigError])

    const submitFormAC = (event) => {
        post('/api/settings/articleConfig', {articleQuantityConfig})
            .then(data => {
                if (data?.status !== 202) {
                    setErrorAC(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                } else {
                    setSuccessAC(props.t("Article configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                }
            }).catch(error => {
            setErrorAC("Fail to set article config.");
            setTimeout(clearError, 5000)
        });
    }

    const handleResetAC = async () => {
        post('/api/settings/articleConfig', {articleQuantityConfig: 'NUMBER'})
            .then(data => {
                if (data?.status !== 202) {
                    setErrorAC(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                } else {
                    setSuccessAC(props.t("Article configuration reset successfully"))
                    setTimeout(clearSuccess, 5000)
                    onGetArticleConfig();
                }
            }).catch(error => {
            setErrorAC("Fail to reset article config.");
            setTimeout(clearError, 5000)
        });
    }

    const handleArticleQuantityConfigChange = (option) => {
        setArticleQuantityConfig(option);
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={configLoading || stockPointsLoading} title="Default Stock Point Config"/>
                                {!(configLoading || stockPointsLoading) && <>

                                    <CardBody>
                                        <form onSubmit={(event) => {
                                            event.preventDefault()
                                            submitForm(event)
                                        }}>
                                            {error ? (
                                                <Alert color="danger">{error}</Alert>
                                            ) : null}

                                            {success ? (
                                                <Alert color="success">{success}</Alert>
                                            ) : null}

                                            <input
                                                className="form-control"
                                                type="hidden"
                                                defaultValue={stockPointConfig?.id}
                                                id="id"
                                                disabled={configLoading || stockPointsLoading}
                                            />
                                            <Row className="mb-5 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Default Order Stock Point")}
                                                </label>
                                                <div className="col-md-3 col-sm-9">
                                                    <select
                                                        className="form-control"
                                                        id="defaultStockPointId"
                                                        onChange={e => handleDefaultStockPointConfigChange(e.target.value)}
                                                        value={defaultStockPointId}
                                                    >
                                                        <option value="">{props.t("Select Stock Point")}</option>
                                                        {stockPoints.map((stockPoint) => (<option value={stockPoint.id}>{stockPoint.name}</option>))}
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row className="mb-2 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                </label>
                                                <div align="left" className="col-md-3 col-sm-9">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn btn-success w-lg waves-effect waves-light me-2"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        disabled={configLoading || stockPointsLoading}
                                                    >
                                                        {props.t("Save")}
                                                    </Button>
                                                </div>
                                            </Row>
                                        </form>
                                    </CardBody>


                                    <CardBody>
                                        <form onSubmit={(event) => {
                                            event.preventDefault()
                                            submitFormAC(event)
                                        }}>
                                            {errorAC ? (
                                                <Alert color="danger">{errorAC}</Alert>
                                            ) : null}

                                            {successAC ? (
                                                <Alert color="success">{successAC}</Alert>
                                            ) : null}

                                            <input
                                                className="form-control"
                                                type="hidden"
                                                defaultValue={articleConfig.id}
                                                id="id"
                                                disabled={loadingAC}
                                            />
                                            <Row className="mb-5 article-config-quantity-setting">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                    {props.t("Quantity Setting")}
                                                </label>
                                                <div className="col-md-3 col-sm-10">
                                                    <select
                                                        className="form-control"
                                                        id="articleQuantityConfig"
                                                        onChange={e => handleArticleQuantityConfigChange(e.target.value)}
                                                        value={articleQuantityConfig}
                                                    >
                                                        <option value={"NUMBER"}>
                                                            {props.t("Number")}
                                                        </option>
                                                        <option value={"COLOR_CODING"}>
                                                            {props.t("Color Coding")}
                                                        </option>
                                                    </select>
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-2 col-form-label article-config-quantity-setting-label"
                                                >
                                                </label>
                                                <div align="left" className="col-md-3 col-sm-9">
                                                    <Button
                                                        type="submit"
                                                        color="success"
                                                        className="btn btn-success w-lg waves-effect waves-light me-2"
                                                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                        disabled={loadingAC}
                                                    >
                                                        {props.t("Save")}
                                                    </Button>
                                                    {/*<Button
                                                        type="button"
                                                        color="danger"
                                                        onClick={handleResetAC}
                                                        className="btn btn-danger w-lg waves-effect waves-light me-2"
                                                        disabled={loadingAC}
                                                    >
                                                        {props.t("Reset")}
                                                    </Button>*/}
                                                </div>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

StockPointConfig.propTypes = {
    stockPointConfig: PropTypes.array,
    getStockPointConfigError: PropTypes.object,
    loading: PropTypes.bool,
    articleConfig: PropTypes.array,
    getArticleConfigError: PropTypes.object,
    loadingAC: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        stockPoints: state.Config.stockPoints,
        stockPointConfig: state.Config.stockPointConfig,
        configLoading: checkIfLoading(state, configActionTypes.GET_STOCK_POINT_CONFIG),
        stockPointsLoading: checkIfLoading(state, configActionTypes.GET_STOCK_POINTS),
        getStockPointConfigError: state.Config.stockPointConfigError,
        articleConfig: state.Config.articleConfig,
        loadingAC: checkIfLoading(state, configActionTypes.GET_ARTICLE_CONFIG),
        getArticleConfigError: state.Config.articleConfigError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetStockPoints: () => dispatch(getStockPoints()),
    onGetStockPointConfig: () => dispatch(getStockPointConfig()),
    onGetStockPointConfigReset: () => dispatch(getStockPointConfigReset()),
    onGetArticleConfig: () => dispatch(getArticleConfig()),
    onGetArticleConfigReset: () => dispatch(getArticleConfigReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(StockPointConfig));
