import {
    GET_AUDIT, GET_AUDIT_SUCCESS, GET_AUDIT_FAIL, GET_AUDIT_RESET, GET_FILTERED_AUDIT, GET_FILTERED_AUDIT_SUCCESS, GET_FILTERED_AUDIT_FAIL, GET_FILTERED_AUDIT_RESET
} from "./actionTypes";

const INIT_STATE = {
    audit: [],
    filteredAudit: [],
    error: false,
    loading: false
};

const Audit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AUDIT:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_AUDIT_SUCCESS:
            return {
                ...state,
                audit: action.payload,
                loading: false,
                error: false
            }
        case GET_AUDIT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                audit: [],
            }
        case GET_AUDIT_RESET:
            return {
                ...state,
                error: null,
                loading: false,
                audit: [],
            }
        case GET_FILTERED_AUDIT:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_FILTERED_AUDIT_SUCCESS:
            return {
                ...state,
                filteredAudit: action.payload,
                loading: false,
                error: false
            }
        case GET_FILTERED_AUDIT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                filteredAudit: [],
            }
        case GET_FILTERED_AUDIT_RESET:
            return {
                ...state,
                error: null,
                loading: false,
                filteredAudit: [],
            }
        default:
            return state;
    }
};

export default Audit
