import {EDIT_PROFILE, GET_USER_INFO, GET_USER_INFO_ERROR, GET_USER_INFO_SUCCESS, PROFILE_ERROR, PROFILE_RESET, PROFILE_SUCCESS, RESET_PROFILE_FLAG} from "./actionTypes"

const initialState = {
  user: {
    id: "",
    name: "",
    email: "",
    profileImage: { id: null},
  },
  error: "",
  success: "",
  userInfoLoading: false,
  editProfileLoading: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, editProfileLoading: true }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, editProfileLoading: false }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, editProfileLoading: false }
      break
    case PROFILE_RESET:
      state = { ...state, error: null, editProfileLoading: false, success: null }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    case GET_USER_INFO:
      state = { ...state, userInfoLoading: true }
      break
    case GET_USER_INFO_SUCCESS:
      state = { ...state, user: action.payload, userInfoLoading: false }
      break
    case GET_USER_INFO_ERROR:
      state = { ...state, error: action.payload, userInfoLoading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
