import {call, put, takeEvery} from "redux-saga/effects"

// Login Redux States
import {BRAND_CONFIG, LOGIN_USER, LOGOUT_USER} from "./actionTypes"
import {apiError, brandFail, brandSuccess, loginSuccess} from "./actions"

import {postJwtLogin, getBranding, getCurrencyList} from "../../../helpers/fakebackend_helper"
import {CartService} from "../../../pages/ShoppingCart/CartService";
import { changeLayout } from "../../actions";


function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postJwtLogin, {
            email: user.email,
            password: user.password,
            domain: user.domain
        });
        //console.log("Data", response.data);
        if (response.isAxiosError) throw response;
        localStorage.setItem("authUser", JSON.stringify(response.data));

        const currencyResponse = yield call (getCurrencyList);
        if(currencyResponse.status == 200){
            localStorage.setItem("currency", JSON.stringify(currencyResponse.data))
        }

        yield put(loginSuccess(response.data));
        if (response.data.roles.includes("ROLE_CUSTOMER_ADMIN") || response.data.roles.includes("ROLE_CUSTOMER_USER")) {
            localStorage.setItem("level", "CUSTOMER")
            yield put(changeLayout("horizontal"));
            history.push("/customer/categories");
        } else {
            if (response.data.roles.includes("ROLE_TENANT_ADMIN") || response.data.roles.includes("ROLE_TENANT_USER")) {
                localStorage.setItem("level", "TENANT")
            } else {
                localStorage.setItem("level", "SUPERADMIN")
            }
            yield put(changeLayout("vertical"));
            history.push("/dashboard");
        }
    } catch (error) {
        console.error(error);
        yield put(apiError("Error! Please confirm email and password."));
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser");
        CartService.clearCart();
        //history.push("/login");
        window.location.href = "/login"
    } catch (error) {
        yield put(apiError(error));
    }
}

function* fetchBrand({domain}) {
    try {
        let response = yield call(getBranding, domain);
        yield put(brandSuccess(response))
    } catch (error) {
        yield put(brandFail(error))
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeEvery(LOGOUT_USER, logoutUser)
    yield takeEvery(BRAND_CONFIG, fetchBrand)
}

export default authSaga
