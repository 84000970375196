import {
    GET_PROJECT_TEMPLATE,
    GET_PROJECT_TEMPLATE_DETAIL,
    GET_PROJECT_TEMPLATE_DETAIL_ERROR,
    GET_PROJECT_TEMPLATE_DETAIL_SUCCESS,
    GET_PROJECT_TEMPLATE_FAIL,
    GET_PROJECT_TEMPLATE_SUCCESS
} from "./actionTypes";


const INIT_STATE = {
    projectTemplates: [],
    projectTemplatesError: null,
    projectTemplatesLoading: false,
    projectTemplateDetails: {},
    projectTemplateDetailsError: null,
    projectTemplateDetailsLoading: false
}


const ProjectTemplate = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROJECT_TEMPLATE:
            return {
                ...state,
                projectTemplatesLoading: true
            }
        case GET_PROJECT_TEMPLATE_SUCCESS:
            return {
                ...state,
                projectTemplates: action.payload,
                projectTemplatesLoading: false
            }
        case GET_PROJECT_TEMPLATE_FAIL:
            return {
                ...state,
                projectTemplates: null,
                projectTemplatesLoading: false,
                projectTemplatesError: action.payload
            }
        case GET_PROJECT_TEMPLATE_DETAIL:
            return {
                ...state,
                projectTemplateDetails: null,
                projectTemplateDetailsLoading: true
            }
        case GET_PROJECT_TEMPLATE_DETAIL_SUCCESS:
            return {
                ...state,
                projectTemplateDetails: action.payload,
                projectTemplateDetailsLoading: false,
                projectTemplateDetailsError: null
            }
        case GET_PROJECT_TEMPLATE_DETAIL_ERROR:
            return {
                ...state,
                projectTemplateDetailsLoading: false,
                projectTemplateDetailsError: action.payload,
                projectTemplateDetails: null
            }
        default:
            return state;
    }
}

export default ProjectTemplate;
