import Dropzone from "react-dropzone";
import {DOWNLOAD_URL} from "../../helpers/url_helper";
import {Button, Input, Label, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {del, postMultipart} from "../../helpers/api_helper";
import {useHistory} from "react-router-dom";


const ArticleDownloadTab = (props) => {

    let {article, type, getFileContent, handleAcceptedFilesDownloadCustomContent, handleDeleteContent} = props;
    const [fileUpload, setFileUpload] = useState([]);
    const [content, setContent] = useState(null);

    const [brandSettings, setBrandSettings] = useState(null);
    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user.brandConfig);
    }, [])

    useEffect(() => {
        if(type && type.fields && type.fields.length > 0){
            setContent(type.fields[0]);
        }
    }, [type])

    const isTabField = (tab, fieldValue) => {
        for(var i = 0; i < tab.fields.length; i++){
            if(tab.fields[i].id == fieldValue.field.id){
                return true;
            }
        }
    }

    useEffect(function(){
        if(fileUpload.length == 0){
            let files = [];

            article?.fieldList?.map(function(fieldValue, index){
                if(isTabField(type, fieldValue)){
                    var obj = {contentObject: fieldValue, content: fieldValue.id, file: {name : fieldValue.downloadLabel, id: fieldValue.value}};
                    files.push(obj);
                }
            })
            setFileUpload(files)
        }

    }, [article])

    const handleAcceptedFilesCustomContent = async (type, content, files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            handleAcceptedFilesDownloadCustomContent(type, content, response.data[0])
            var obj = {content: content?.id, file: response?.data[0]};
            setFileUpload(prevState => ([...fileUpload, obj]));
        }
    }

    const handleDelete = async (file) => {
        let response = await del(`/api/media/${file.file.id}`);
        let index = -1;
        for(var i = 0; i < fileUpload.length; i++){
            if(fileUpload[i].file?.id === file.file?.id){
                index = i;
            }
        }

        var array = [...fileUpload];
        if (index !== -1) {
            array.splice(index, 1);
            setFileUpload(array)
        }
        handleDeleteContent(file.contentObject, file.file?.id, file?.content)
    }

    const updateName = (file, name) => {
        for(var i = 0; i < fileUpload.length; i++){
            if(fileUpload[i].file?.id === file.file?.id){
                fileUpload[i].file.name = name;
            }
        }
        setFileUpload(fileUpload);
    }

    const saveName = async (file, name) => {
        /*let response = await put(`/api/media/${file.file.id}?name=${name}`);*/
        handleAcceptedFilesDownloadCustomContent(type, content, {id: file.file?.id, name: name})
    }

    return (<React.Fragment>
        <Row className="mb-3 margin-top-10px">
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {content?.name}
            </label>
            <div className="col-md-10">
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFilesCustomContent(type, content, acceptedFiles)
                    }}>

                    {({getRootProps, getInputProps}) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                </div>
                                <h4>{props.t("Drop files here or click to upload")}</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <br/>
                {/*<span>{getFileContent(content)}</span>*/}
                <div key={"file-download" + Object.keys(fileUpload).length}>
                    {fileUpload.map(function (file, index) {
                        return (<div className="col-mg-12 mt-4">
                            <div className="col-mg-12">
                                <Label>
                                    {props.t("Download Name")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => updateName(file, e.target.value)}
                                    defaultValue={file.file?.name}
                                />
                            </div>
                            <br/>
                            <Button
                                color="primary"
                                className="btn btn-primary btn-sm btn-block waves-effect waves-light me-2"
                                style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                onClick={() => saveName(file, file.file?.name)}
                            >
                                {props.t("Save")}
                            </Button>
                            <a
                                href={DOWNLOAD_URL + "/" + file.file?.id}
                                className="btn btn-secondary btn-sm btn-block waves-effect waves-light me-2">
                                {props.t("Download")}
                            </a>
                            <Button
                                color="danger"
                                onClick={() => handleDelete(file)}
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light me-2">
                                {props.t("Delete")}
                            </Button>
                        </div>)
                    })}
                </div>

            </div>
        </Row>
    </React.Fragment>)
}


export default connect()(withTranslation()(ArticleDownloadTab));
