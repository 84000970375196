import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {useHistory} from "react-router-dom"
import {Button, Card, CardBody, CardHeader, Col, Container, Input, Row} from "reactstrap"


//Import Breadcrumb
// import charts
import {getManufacturerDetails} from "../../store/actions"
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import {withTranslation} from "react-i18next";
import TableToolbar from "../../components/Common/TableToolbar";
import {API_ERR_MSG} from "../../util/constants"
import Loader from "../../components/Common/Loader"
import {del, post, postMultipart, put} from "../../helpers/api_helper";
import loadingIcon from "../../assets/images/loading.gif";
import PersonIcon from "@material-ui/icons/Person";
import avatar from "../../assets/images/company-placeholder.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import {Avatar, IconButton} from "@material-ui/core";
import TextArea from "antd/lib/input/TextArea";

const ManufacturerDetailsEdit = props => {
    const {
        manufacturer,
        onGetManufacturerDetails,
        loading,
        match: {params},
    } = props

    const [manufacturerDetails, setManufacturerDetails] = useState();
    const history = useHistory();
    const [brandSettings, setBrandSettings] = useState(null)
    const [accountManager, setAccountManager] = useState("");
    const [error, setError] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);
    const [profileImageId, setProfileImageId] = useState(null);
    const [description, setDescription] = useState(null);
    const [deleteBtnText, setDeleteBtnText] = useState("Delete Profile Picture");
    const ErrorMessage = "Error occurred while updating profile. If issue persists, Please contact system administrator";

    useEffect(() => {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"))
        if (params && params.id) {
            onGetManufacturerDetails(params.id);
        }
    }, [params.id]);

    useEffect(() => {
        if (manufacturer) {
            setManufacturerDetails(manufacturer);
            setProfileImageId(manufacturer?.profileImage?.s3Key)
            setDescription(manufacturer?.description);
        }
    }, [manufacturer]);

    const handleBack = () => {
        history.goBack();
    };

    const handleAcceptedFile = async (e) => {
        if (e.target.files) {
            setImageUploading(true);
            let formData = new FormData();
            formData.append("files[]", e.target.files[0]);
            let response;
            try {
                response = await postMultipart("/api/media", formData);

            } catch (ex) {
                setError(props.t(ErrorMessage));
                setTimeout(() => setError(null), 3000);
            }
            if (response && response.status === 200) {
                setProfileImageId(response.data[0].s3Key);
                try {
                    const editResponse = await put(`/api/manufacturer/${manufacturer?.id}/editProfile/${response.data[0]?.id}`);
                    setTimeout(() => {
                        history.push(`/manufacturer/${manufacturer.id}/details`)
                    }, 3000);
                } catch (ex) {
                    setError(props.t(ErrorMessage));
                    setTimeout(() => setError(null), 3000);
                }
            } else {
                setError(props.t(ErrorMessage));
                setTimeout(() => setError(null), 3000);
            }

            setImageUploading(false);
        }
    }

    const handleDeleteProfilePicture = async () => {
        try {
            setDeleteBtnText("Deleting");
            let response = await del(`/api/manufacturer/${manufacturer?.id}/profile-picture`);
            setProfileImageId(null);
            setDeleteBtnText("Delete Profile Picture");
            history.push(`/manufacturer/${manufacturer.id}/details`)
        } catch (err) {
            setError(props.t(API_ERR_MSG));
            setTimeout(() => setError(null), 3000);
        }
    }

    const updateManufacturerDetails = async() => {
        let response = await post(`/api/manufacturer`, {id : manufacturer.id, description : description});
        history.push(`/manufacturers/${manufacturer?.id}/details`)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        title={manufacturer?.name}
                        breadcrumbs={[
                            {title: props.t("Customers"), link: "/customers"},
                        ]}
                        backBtn={handleBack}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                    <TableToolbar
                                        title={manufacturer?.name ? manufacturer.name : props.t("Manufacturer Details")}
                                    />
                                </CardHeader>
                            </Card>
                        </Col>
                        <Loader loading={loading} title="Customer Profile"/>
                        {!loading && <>
                            <Col xl="12">
                                <Card className="card h-100">
                                    <CardBody>
                                        <Row>
                                            <div className="text-center">
                                                <input
                                                    accept='image/*'
                                                    id='icon-button-file'
                                                    type='file'
                                                    onChange={handleAcceptedFile}
                                                    style={{
                                                        display: 'none'
                                                    }}
                                                />
                                                <label htmlFor='icon-button-file' className="mb-0">
                                                    <IconButton
                                                        color='primary'
                                                        aria-label='upload picture'
                                                        component='span'
                                                        className="mb-0 pb-0"
                                                    >
                                                        <Avatar
                                                            variant="rounded"
                                                            src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                            className="customer-logo"
                                                        >
                                                            <PersonIcon/>
                                                        </Avatar>
                                                    </IconButton>
                                                </label>
                                                <div className="d-flex justify-content-center align-items-center mb-2">
                                                    <i className="uil uil-info-circle me-1 text-muted font-size-13"></i>
                                                    <p className="text-muted font-size-12 m-0">{props.t("Recommended image dimensions are 400 x 100 pixels")}</p>
                                                </div>
                                                {profileImageId && <h6>
                                                    <Button className="btn-sm btn-danger mb-3"
                                                            onClick={handleDeleteProfilePicture}
                                                            color="danger">
                                                        {props.t(deleteBtnText)}
                                                    </Button>
                                                </h6>}
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Name")}
                                            </label>
                                            <div className="col-md-10">
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={manufacturer?.name}
                                                    disabled={true}
                                                />
                                            </div>
                                        </Row>


                                        <Row className="mb-3">
                                            <label
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Description")}
                                            </label>
                                            <div className="col-md-10">
                                                <TextArea
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={manufacturer?.description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                />
                                            </div>
                                        </Row>


                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="button"
                                                    color="secondary"
                                                    className="btn btn-secondary w-lg waves-effect waves-light"
                                                    onClick={() => {
                                                        if (props.setEditMode) {
                                                            props.setEditMode(false);
                                                        } else {
                                                            history.goBack()
                                                        }
                                                    }}
                                                >
                                                    {props.t("Cancel")}
                                                </Button>
                                                {" "}
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light"
                                                    style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                                    onClick={updateManufacturerDetails}
                                                    disabled={imageUploading}
                                                >
                                                    {props.t("Update")}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                        </>}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ManufacturerDetailsEdit.propTypes = {
    manufacturer: PropTypes.object,
    onGetManufacturerDetails: PropTypes.func,
}

const mapStateToProps = (state) => ({
    manufacturer: state.Manufacturer.manufacturer,
    loading: state.Manufacturer.manufacturerLoading,
    error: state.Manufacturer.manufacturerError
})

const mapDispatchToProps = dispatch => ({
    onGetManufacturerDetails: (id) => dispatch(getManufacturerDetails(id)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ManufacturerDetailsEdit));
