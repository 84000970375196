import {call, put, takeEvery} from "redux-saga/effects"

// Ecommerce Redux States
import {
    COPY_ORDER_TO_CART,
    GET_ARTICLE_GROUP,
    GET_ARTICLE_GROUP_LIST,
    GET_CART_DATA,
    GET_CATEGORY_LIST,
    GET_CONTENT_TYPE,
    GET_CONTENT_TYPE_FOR_ARTICLE,
    GET_CUSTOMER,
    GET_CUSTOMERS,
    GET_DASHBOARD_LINE_CHART,
    GET_DASHBOARD_METRICS_LIST,
    GET_DEFAULT_LAYOUT,
    GET_FAVORITE_PRODUCTS,
    GET_FAVOURITES,
    GET_FIELDS,
    GET_GLOBAL_SEARCH,
    GET_GROUP_BY_ARTICLE,
    GET_LAYOUT,
    GET_LAYOUT_LIST,
    GET_ORDER_METRICS,
    GET_ORDERS,
    GET_ORDERS_BY_REFERENCE,
    GET_ORDERS_DETAILS,
    GET_ORDERS_FOR_AM,
    GET_PRICE_LIST,
    GET_PRICE_LIST_FOR_TENANT,
    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_GROUP,
    GET_PRODUCT_DETAIL,
    GET_PRODUCTS,
    GET_SHOPS,
    GET_SUB_CATEGORY_LIST,
    GET_TEMPLATE_DETAILS,
    GET_TEMPLATE_LIST,
    POST_ORDER
} from "./actionTypes"
import {
    copyOrderToCartFail,
    copyOrderToCartSuccess,
    createOrderFail,
    createOrderSuccess,
    getArticleGroupFail,
    getArticleGroupListFail,
    getArticleGroupListSuccess,
    getArticleGroupSuccess,
    getCartDataFail,
    getCartDataSuccess,
    getCategoryListFail,
    getCategoryListSuccess,
    getContentTypeForArticleFail,
    getContentTypeForArticleSuccess,
    getContentTypesFail,
    getContentTypesSuccess,
    getCustomerInfoFail,
    getCustomerInfoSuccess,
    getCustomersFail,
    getCustomersSuccess,
    getDashboardLineChartFail,
    getDashboardLineChartSuccess,
    getDashboardMetricsListFail,
    getDashboardMetricsListSuccess,
    getDefaultLayoutFail,
    getDefaultLayoutSuccess,
    getFavoriteProductsFail,
    getFavoriteProductsSuccess,
    getFavouritesFail,
    getFavouritesSuccess,
    getFieldsFail,
    getFieldsSuccess,
    getGlobalSearchFail,
    getGlobalSearchSuccess,
    getGroupByArticleFail,
    getGroupsByArticleSuccess,
    getLayoutFail,
    getLayoutListFail,
    getLayoutListSuccess,
    getLayoutSuccess,
    getOrderDetailsFail,
    getOrderDetailsSuccess,
    getOrderForAMFail,
    getOrderForAMSuccess,
    getOrderMetricsError,
    getOrderMetricsSuccess,
    getOrdersByReferenceFail,
    getOrdersByReferenceSuccess,
    getOrdersFail,
    getOrdersSuccess,
    getPriceListFail,
    getPriceListForTenantError,
    getPriceListForTenantSuccess,
    getPriceListSuccess,
    getProductByCategorySuccess,
    getProductByGroupFail,
    getProductByGroupSuccess,
    getProductDetailFail,
    getProductDetailSuccess,
    getProductsFail,
    getProductsSuccess,
    getShopsFail,
    getShopsSuccess,
    getSubCategoryListFail,
    getSubCategoryListSuccess,
    getTemplateDetailsError,
    getTemplateDetailsSuccess,
    getTemplateListError,
    getTemplateListSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
    copyOrderToCart,
    getArticleGroupById,
    getArticleGroupList,
    getCartData,
    getCategoryList,
    getContentTypes,
    getContentTypesByLayout,
    getContentTypesForArticles,
    getCustomer,
    getCustomers,
    getDashboardLineChartMetrics,
    getDashboardMetricsListAPI,
    getDefaultLayout,
    getFavoriteProducts,
    getFavourites,
    getFields,
    getGlobalSearch,
    getGroupsByArticle,
    getLayout,
    getLayoutById,
    getOrderDetails,
    getOrderForAM,
    getOrderMetrics,
    getOrders,
    getOrdersByReference,
    getPriceList,
    getPriceListForTenant,
    getProductByCategory,
    getProductByGroup,
    getProductDetail,
    getProducts,
    getShops,
    getSubCategory,
    getTemplateDetails,
    getTemplateList,
    postOrder
} from "../../helpers/fakebackend_helper"
import {API_ERR_MSG} from "../../util/constants";
import {startAction, stopAction} from "../actions";

function* fetchProducts({page, pageSize, search, isCustomer, filter, customFilter, tenantId, customerId, sortColumn, sortDirection}) {
    try {
        if (page === undefined) {
            page = -1;
        }
        if (pageSize === undefined) {
            pageSize = -1;
        }
        if (isCustomer === undefined) {
            isCustomer = false;
        }
        if (search === undefined) {
            search = null;
        }
        if (filter === undefined) {
            filter = null;
        }
        if (customFilter === undefined) {
            customFilter = null;
        }
        if (customerId === undefined) {
            customerId = null;
        }
        if (sortColumn === undefined) {
            sortColumn = null;
        }
        if (sortDirection === undefined) {
            sortDirection = null;
        }
        const response = yield call(getProducts, page, pageSize, search, filter, customFilter, tenantId, customerId, sortColumn, sortDirection)
        if(response){
            response.data["search"] = search;
        }
        yield put(getProductsSuccess(response))
    } catch (error) {
        yield put(getProductsFail(API_ERR_MSG))
    }
}

function* fetchFavoriteProducts({productListId, page, pageSize, search}) {
    try {
        if (page === undefined) {
            page = -1;
        }
        if (pageSize === undefined) {
            pageSize = -1;
        }
        if (search === undefined) {
            search = null;
        }
        const response = yield call(getFavoriteProducts, productListId, page, pageSize, search)
        yield put(getFavoriteProductsSuccess(response))
    } catch (error) {
        yield put(getFavoriteProductsFail(API_ERR_MSG))
    }
}

function* fetchContentTypes({id}) {
    try {
        yield put(getContentTypesSuccess({data: []}));
        if (id === undefined) {
            const response = yield call(getContentTypes)
            yield put(getContentTypesSuccess(response))
        } else {
            const response = yield call(getContentTypesByLayout, id)
            yield put(getContentTypesSuccess(response))
        }
    } catch (error) {
        yield put(getContentTypesFail(API_ERR_MSG))
    }
}

function* fetchFields({fieldType}) {
    try {
        const response = yield call(getFields, fieldType)
        yield put(getFieldsSuccess(response))
    } catch (error) {
        yield put(getFieldsFail(API_ERR_MSG))
    }
}

function* fetchContentTypesForArticle({id}) {
    try {
        const response = yield call(getContentTypesForArticles, id)
        yield put(getContentTypeForArticleSuccess(response))
    } catch (error) {
        yield put(getContentTypeForArticleFail(API_ERR_MSG))
    }
}

function* fetchProductDetail({productId}) {
    try {
        const response = yield call(getProductDetail, productId)
        yield put(getProductDetailSuccess(response))
    } catch (error) {
        yield put(getProductDetailFail(API_ERR_MSG))
    }
}

function* fetchOrders({type, orderType, customerId, articleNumber, startDate, endDate, projectNumber, articleGroupId, manufacturer, ourReference, yourReference}) {
    try {
        yield put(startAction(type))
        const response = yield call(getOrders, orderType, customerId, articleNumber, startDate, endDate, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
        yield put(getOrdersSuccess(response))
    } catch (error) {
        yield put(getOrdersFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* placeOrder({data}) {
    try {
        const response = yield call(postOrder, data)
        yield put(createOrderSuccess(response))
    } catch (error) {
        yield put(createOrderFail("Error occurred while placing order!"))
    }
}

function* fetchCartData() {
    try {
        const response = yield call(getCartData)
        yield put(getCartDataSuccess(response))
    } catch (error) {
        yield put(getCartDataFail(error))
    }
}

function* fetchCustomers() {
    try {
        const response = yield call(getCustomers)
        yield put(getCustomersSuccess(response))
    } catch (error) {
        yield put(getCustomersFail(error))
    }
}

function* fetchCustomer({customerId}) {
    try {
        const response = yield call(getCustomer, customerId)
        yield put(getCustomerInfoSuccess(response.data))
    } catch (error) {
        yield put(getCustomerInfoFail(error))
    }
}

function* fetchShops() {
    try {
        const response = yield call(getShops)
        yield put(getShopsSuccess(response))
    } catch (error) {
        yield put(getShopsFail(error))
    }
}


function* fetchCategoryList({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getCategoryList)
        yield put(getCategoryListSuccess(response))
    } catch (error) {
        yield put(getCategoryListFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }

}

function* fetchSubCategoryList({id, type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getSubCategory, id)
        yield put(getSubCategoryListSuccess(response))
    } catch (error) {
        yield put(getSubCategoryListFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchArticlesByCategory({categoryId, page, pageSize, search}) {
    try {
        if (!page) {
            page = 0
        }
        if (!pageSize) {
            pageSize = 10
        }
        if (!search) {
            search = null
        }
        const response = yield call(getProductByCategory, categoryId, page, pageSize, search)
        yield put(getProductByCategorySuccess(response))
    } catch (error) {
        yield put(getProductDetailFail(error))
    }
}

function* fetchArticlesByGroup({groupId, page, pageSize, search, isAll}){
    try {
        if (!page) {
            page = 0
        }
        if (!pageSize) {
            pageSize = 10
        }
        if (!search) {
            search = null
        }
        const response = yield call(getProductByGroup, groupId, page, pageSize, search, isAll)
        yield put(getProductByGroupSuccess(response))
    } catch (error) {
        yield put(getProductByGroupFail(error))
    }
}

function* fetchLayout({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getLayout)
        yield put(getLayoutListSuccess(response))
    } catch (error) {
        yield put(getLayoutListFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchDefaultLayout({articleId}) {
    try {
        const response = yield call(getDefaultLayout, articleId)
        yield put(getDefaultLayoutSuccess(response))
    } catch (error) {
        yield put(getDefaultLayoutFail(error))
    }
}

function* fetchLayoutById({id, type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getLayoutById, id)
        yield put(getLayoutSuccess(response))
    } catch (error) {
        yield put(getLayoutFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchPriceList({articleId, fromQuantity}) {
    try {
        const response = yield call(getPriceList, {articleId, fromQuantity})
        yield put(getPriceListSuccess(response))
    } catch (error) {
        yield put(getPriceListFail(error))
    }
}

function* fetchOrderDetails({orderId}) {
    try {
        //yield put(getOrderDetailsFail(null));
        const response = yield call(getOrderDetails, orderId);
        yield put(getOrderDetailsSuccess(response))
    } catch (error) {
        yield put(getOrderDetailsFail(API_ERR_MSG))
    }
}

function* fetchGlobalSearch({value}) {
    try {
        const response = yield call(getGlobalSearch, value);
        yield put(getGlobalSearchSuccess(response));
    } catch (error) {
        yield put(getGlobalSearchFail(error));
    }
}

function* postCopyOrderToCart({orderId}) {
    try {
        const response = yield call(copyOrderToCart, orderId);
        yield put(copyOrderToCartSuccess(response.data));
    } catch (error) {
        yield put(copyOrderToCartFail(error));
    }
}

function* loadOrderForAM({userId, orderType}) {
    try {
        const response = yield call(getOrderForAM, userId, orderType);
        yield put(getOrderForAMSuccess(response));
    } catch (error) {
        yield put(getOrderForAMFail(API_ERR_MSG));
    }
}

function* fetchOrdersByReference({userId, orderType}) {
    try {
        const response = yield call(getOrdersByReference, userId, orderType);
        yield put(getOrdersByReferenceSuccess(response));
    } catch (error) {
        yield put(getOrdersByReferenceFail(API_ERR_MSG));
    }
}

function* fetchArticleGroups({type}) {
    try {
        yield put(startAction(type))
        const response = yield call(getArticleGroupList);
        yield put(getArticleGroupListSuccess(response));
    } catch (error) {
        yield put(getArticleGroupListFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchArticleGroupDetails({id, type}){
    try{
        yield put(startAction(type))
        const response = yield call(getArticleGroupById, id)
        yield put(getArticleGroupSuccess(response))
    } catch(error){
        yield put(getArticleGroupFail(API_ERR_MSG))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchGroupForArticle({id}){
    try{
        const response = yield call(getGroupsByArticle, id);
        yield put(getGroupsByArticleSuccess(response))
    }catch (error){
        yield put(getGroupByArticleFail(error));
    }
}

function* fetchTemplateList(){
    try{
        const response = yield call(getTemplateList);
        yield put(getTemplateListSuccess(response));
    }catch (error){
        yield put(getTemplateListError(error));
    }
}

function* fetchTemplateDetails({id}){
    try{
        const response = yield call(getTemplateDetails, id);
        yield put(getTemplateDetailsSuccess(response))
    }catch (error){
        yield put(getTemplateDetailsError(error))
    }
}

function* fetchFavourites() {
    try {
        const response = yield call(getFavourites)
        yield put(getFavouritesSuccess(response))
    } catch (error) {
        yield put(getFavouritesFail(API_ERR_MSG))
    }
}

function* fetchOrderMetrics({startDate, endDate}) {
    try {
        const response = yield call(getOrderMetrics, startDate.substring(0,10), endDate.substring(0,10));
        yield put(getOrderMetricsSuccess(response))
    } catch (error) {
        yield put(getOrderMetricsError(error))
    }
}

function* fetchDashboardLineChart({startDate, endDate, timeWindow, filter}){
    try{
        const response = yield call(getDashboardLineChartMetrics, startDate, endDate, timeWindow, filter);
        yield put(getDashboardLineChartSuccess(response))
    } catch (error){
        yield put(getDashboardLineChartFail(error))
    }
}

function* fetchDashboardMetricsList({startDate, endDate, timeWindow, filter}){
    try{
        const response = yield call(getDashboardMetricsListAPI, startDate, endDate, timeWindow, filter);
        yield put(getDashboardMetricsListSuccess(response))
    } catch (error){
        yield put(getDashboardMetricsListFail(error))
    }
}


function* fetchPriceListForTenant() {
    try {
        const response = yield call(getPriceListForTenant);
        yield put(getPriceListForTenantSuccess(response));
    } catch (error) {
        yield put(getPriceListForTenantError(error))
    }
}

function* ecommerceSaga() {
    yield takeEvery(GET_PRODUCTS, fetchProducts)
    yield takeEvery(GET_FAVORITE_PRODUCTS, fetchFavoriteProducts)
    yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
    yield takeEvery(GET_ORDERS, fetchOrders)
    yield takeEvery(POST_ORDER, placeOrder)
    yield takeEvery(GET_CART_DATA, fetchCartData)
    yield takeEvery(GET_CUSTOMERS, fetchCustomers)
    yield takeEvery(GET_CUSTOMER, fetchCustomer)
    yield takeEvery(GET_SHOPS, fetchShops)
    yield takeEvery(GET_CONTENT_TYPE, fetchContentTypes)
    yield takeEvery(GET_FIELDS, fetchFields)
    yield takeEvery(GET_CATEGORY_LIST, fetchCategoryList)
    yield takeEvery(GET_SUB_CATEGORY_LIST, fetchSubCategoryList)
    yield takeEvery(GET_PRODUCT_BY_CATEGORY, fetchArticlesByCategory)
    yield takeEvery(GET_PRODUCT_BY_GROUP, fetchArticlesByGroup)
    yield takeEvery(GET_LAYOUT_LIST, fetchLayout)
    yield takeEvery(GET_DEFAULT_LAYOUT, fetchDefaultLayout)
    yield takeEvery(GET_LAYOUT, fetchLayoutById)
    yield takeEvery(GET_PRICE_LIST, fetchPriceList)
    yield takeEvery(GET_ORDERS_DETAILS, fetchOrderDetails)
    yield takeEvery(GET_GLOBAL_SEARCH, fetchGlobalSearch)
    yield takeEvery(COPY_ORDER_TO_CART, postCopyOrderToCart)
    yield takeEvery(GET_ORDERS_FOR_AM, loadOrderForAM)
    yield takeEvery(GET_ORDERS_BY_REFERENCE, fetchOrdersByReference)
    yield takeEvery(GET_ARTICLE_GROUP_LIST, fetchArticleGroups)
    yield takeEvery(GET_ARTICLE_GROUP, fetchArticleGroupDetails)
    yield takeEvery(GET_GROUP_BY_ARTICLE, fetchGroupForArticle);
    yield takeEvery(GET_CONTENT_TYPE_FOR_ARTICLE, fetchContentTypesForArticle)
    yield takeEvery(GET_TEMPLATE_LIST, fetchTemplateList)
    yield takeEvery(GET_TEMPLATE_DETAILS, fetchTemplateDetails);
    yield takeEvery(GET_FAVOURITES, fetchFavourites);
    yield takeEvery(GET_ORDER_METRICS, fetchOrderMetrics);
    yield takeEvery(GET_DASHBOARD_LINE_CHART, fetchDashboardLineChart)
    yield takeEvery(GET_DASHBOARD_METRICS_LIST, fetchDashboardMetricsList)
    yield takeEvery(GET_PRICE_LIST_FOR_TENANT, fetchPriceListForTenant)
}


export default ecommerceSaga
