import {GET_FORTNOX_LABELS, GET_FORTNOX_LABELS_ERROR, GET_FORTNOX_LABELS_SUCCESS} from "./actionTypes";


export const getLabels = () => ({
    type: GET_FORTNOX_LABELS
})

export const getLabelsSuccess = (response) => ({
    type: GET_FORTNOX_LABELS_SUCCESS,
    payload: response.data
})

export const getLabelsError = (error) => ({
    type: GET_FORTNOX_LABELS_ERROR,
    payload: error
})
