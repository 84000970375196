import React, {useEffect, useState} from "react";
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, Row} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {isEmpty, isEqual} from "lodash";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import TableToolbar from "../../components/Common/TableToolbar";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {getAudit, getAuditReset} from "../../store/audit/actions";
import InfiniteScrollbarWithLoader from "../../components/Common/InfiniteScrollbarWithLoader";
import {getUpdatedList} from "../../util/helperFunctions";
import AccordionButton from "../../components/Common/AccordionButton";
import {impersonateUser} from "../../store/actions";
import {userRoutes} from "../../routes/allRoutes";
import {getWithRequestParams} from "../../helpers/api_helper";
import Select from "react-select";

const Audit = (props) => {
  const { 
    audit, 
    metaInfo,
    loading, 
    error, 
    onGetAudit,
    onGetAuditReset,
    onImpersonateUser 
  } = props;
  const [auditList, setAuditList] = useState([]);
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null)
  const [entityType, setEntityType] = useState(null)
  const [entityName, setEntityName] = useState(null)
  const [operation, setOperation] = useState(null)
  const [modal_standard, setmodal_standard] = useState(false);
  const [entityTypes, setEntityTypes] = useState([]);
  const [operationList, setOperationList] = useState([]);
  const operations = ["INSERT", "DELETE", "UPDATE"];

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(16);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState();


  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("authUser"));
  
  useEffect(() => {
    setBrandSettings(user?.brandConfig);
    const prevSearchFilter = JSON.parse(localStorage.getItem("eventsSearchFilter"));
    if(prevSearchFilter){
      setEntityType(prevSearchFilter.entityType)
      setEntityName(prevSearchFilter.entityName)
      setOperation(prevSearchFilter.operation)
      onGetAudit({
        page: page - 1,
        size: sizePerPage,
        entityType: prevSearchFilter.entityType,
        operation: prevSearchFilter.operation,
        entityName: prevSearchFilter.entityName,
        customerId: user?.customerId
      });
    } else {
      onGetAudit({ 
        page: page - 1, 
        size: sizePerPage,
        customerId: user?.customerId
      });
    }
    getAllEntityTypes();
    return () => {
      onGetAuditReset();
    }
  }, [])

  useEffect(() => {
    console.log("Type", entityType);
  }, [entityType])

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetAuditReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if (
        page !== 1 &&
        auditList.length < totalItems &&
        !loading &&
        page <= totalPage
    ) {
      onGetAudit({
        page: page - 1, 
        size: sizePerPage, 
        entityType,
        entityName,
        operation,
        customerId: user?.customerId
      });
    }
  }, [page])

  useEffect(() => {
    if(metaInfo){
      setTotalPage(metaInfo.totalPages);
      setTotalItems(metaInfo.totalItems);
    }
  }, [metaInfo]);

  useEffect(() => {
    if(audit?.length > 0) {
      setOperationList(operations);
    } else {
      setOperationList([]);
    }

    if(!audit){
      setAuditList([]);
    }
    else if (!isEmpty(audit) && !isEqual(audit, auditList)) {
      setAuditList(getUpdatedList(auditList, audit));
    }
  }, [audit]);

  useEffect(() => {
    // when modal opens, populate fields with persisted filter state
    if(modal_standard){
      const prevSearchFilter = JSON.parse(localStorage.getItem("eventsSearchFilter"));
      setEntityType(prevSearchFilter?.entityType)
      setEntityName(prevSearchFilter?.entityName)
      setOperation(prevSearchFilter?.operation)
    }
  }, [modal_standard]);

  const getAllEntityTypes = async () => {
    const response = await getWithRequestParams('/api/events/entityTypes');
    if(response.status === 200) {
      setEntityTypes(response.data);
    }
  }

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const getColorCodedOperation = (operation) => {
    let colorClsName = "bg-soft-success bg-success";
    if(operation === "DELETE"){
      colorClsName = "bg-soft-danger bg-danger";
    } else if(operation === "UPDATE"){
      colorClsName = "bg-soft-warning bg-warning";
    } else if(operation === "SYNC_ERROR"){
      colorClsName = "bg-soft-danger bg-danger";
    }
    const clsName = "product-ribbon badge " + colorClsName;
    return <>
      <div className={clsName} style={{fontSize:"14px"}}>
        {" "}
        {props.t(operation)}{" "}
      </div>
    </>
  }

  const getEventDetails = (event) => {
    if(event.operation !== 'DELETE'){
      // special handling for User entities becuase User entity can be super admin user, tenant user or customer user
      if(event.entity === 'User'){
        // if event is User with id same as logged in user then route user to user profile page.
        if(event.entityId == user?.uid){
          return history.push('/settings/user-profile');
        }

        if(event.customerId){
          if(!user?.tenantId) {
            return; // cannot open customer user details in super admin view
          } else {
            return history.push(`/customers/${event.customerId}/users/${event.entityId}`)
          }
        }
      }
      let routes = userRoutes.filter(route => route.eventMap?.entityTypes 
        && route.eventMap.entityTypes.includes(event.entity) 
        && (!route.allowedRoles 
          || (user?.customerId && (route.allowedRoles.includes('ROLE_CUSTOMER_USER') || route.allowedRoles.includes('ROLE_CUSTOMER_ADMIN')))
          || (user?.tenantId && (route.allowedRoles.includes('ROLE_TENANT_USER') || route.allowedRoles.includes('ROLE_TENANT_ADMIN')))
          || (!user?.tenantId && !user?.customerId && route.allowedRoles.includes('ROLE_SUPER_ADMIN')))
        && !((!user?.tenantId && event.tenantId && route.eventMap.impersonationType === 'TENANT') 
          || (!user?.customerId && event.customerId && route.eventMap.impersonationType === 'CUSTOMER')));

        if(routes && routes.length > 0) {
          let path = routes[0].path;
          path = path.replace(':id', event?.documentNumber ? event.documentNumber : event.entityId);
          if(path.includes(':customerId') && event.customerId){
            path = path.replace(':customerId', event.customerId);
          }

          const tenantId = event.entity === 'Tenant' ? event.entityId : event.tenantId?.id
          if(path.includes(':tenantId') && tenantId){
            path = path.replace(':tenantId', tenantId);
          }
          history.push(path);
        }
    }
  }

  const getImpersonatedRoute = (event) => {
    if(event.entity === 'User'){
      // if event is User with id same as logged in user then route user to user profile page.
      if(event.entityId == user?.uid){
        return null;
      }

      if(event.customerId){
        if(user.customerId) return null;

        return {path: `/customers/:customerId/users/:id`}
      }
    }
    let routes = userRoutes.filter(route => route.eventMap?.entityTypes
      && route.eventMap.entityTypes.includes(event.entity)
      && ((!user?.tenantId && event.tenantId && route.eventMap.impersonationType === 'TENANT') 
        || (!user?.customerId && event.customerId && route.eventMap.impersonationType === 'CUSTOMER')));

    if(routes && routes.length > 0) {
      return routes[0];
    }

    return null;
  }

  const handleImpersonate = async (audit) => {
    let route = getImpersonatedRoute(audit);
    let path = route.path;
    path = path.replace(':id', audit.entityId);
    
    if(path.includes(':customerId') && audit.customerId){
      path = path.replace(':customerId', audit.customerId);
    }
    if(path.includes(':tenantId') && audit.tenantId){
      path = path.replace(':tenantId', audit.tenantId?.id);
    }
    let loc = {...location};
    loc['pathname'] = path;
    if(audit.customerId && (!route.allowedRoles || route.allowedRoles.includes('ROLE_CUSTOMER_USER') || route.allowedRoles.includes('ROLE_CUSTOMER_ADMIN'))){
      onImpersonateUser({ customerId: audit.customerId }, history, loc)
    } else if(audit.tenantId && (!route.allowedRoles || route.allowedRoles.includes('ROLE_TENANT_USER') || route.allowedRoles.includes('ROLE_TENANT_ADMIN'))){
      onImpersonateUser({ tenantId: audit.tenantId.id }, history, loc)
    }
  }

  const getStatusView = (audit, key) => {
    return (
    <li key={key} className="event-list">
      <div 
       className="event-list-content"
      >
        <div className="event-date text-primary">{new Date(audit.updatedAt).toLocaleString()}</div>
        <div className="d-flex">
          <h5 style={{cursor: 'pointer'}} onClick={() => {
            getEventDetails(audit);
          }}>{props.t(audit.entity)} {audit.name && ` - ${audit.name}`}</h5>
        </div>
        {getColorCodedOperation(audit.operation)} <label>{" "} {audit.fortnoxChange == true ? props.t("by ERP Fortnox") : audit.byUser?.name}</label>
        {audit.operation !== 'DELETE' && getImpersonatedRoute(audit) &&  audit.fortnoxChange != true &&
        <>
          <i 
            className="fa fa-sign-in-alt" 
            style={{fontSize: "16px", marginLeft: "10px", cursor: "pointer"}} 
            onClick={()=> handleImpersonate(audit)}
          >
          </i>
        </>}
        {console.log(audit?.difference?.value)}
        {(audit.operation === 'UPDATE' && audit?.difference) && <><br/>
          <AccordionButton content={
            /*Object.keys(JSON.parse(audit?.difference?.value))?.map(function(key){
              for(var label in key){
                return  <div className="d-flex"><p className="m-0">{props.t(label)}</p><p className="text-muted m-0">{": " + key[label]}</p></div> 
              }
            })*/
              JSON.stringify(JSON.parse(audit?.difference?.value), null, 2)

          }
           activeColor={brandSettings?.primaryBtnColor}
          /></>
        }
      </div>
    </li>)
  }

  const handleAddFilter = async () => {
    setAuditList([])
    const searchFilter = {entityType, entityName, operation};
    localStorage.setItem("eventsSearchFilter", JSON.stringify(searchFilter));
    setPage(1);
    onGetAudit({
      page: page - 1,
      size: sizePerPage,
      entityType, 
      entityName, 
      operation,
      customerId: user?.customerId
    });
    tog_standard();
  };

  const handleClearFilter = async (toggleModal) => {
    setAuditList([]);
    setEntityName(null);
    setEntityType(null);
    setOperation(null);
    setPage(1);
    localStorage.setItem("eventsSearchFilter", null);
    onGetAudit({
      page: 0, 
      size: sizePerPage,
      entityType: null, 
      entityName: null, 
      operation: null,
      customerId: user?.customerId
    });
    if(toggleModal){
      tog_standard();
    }
  };

  const getLabelValue = (item) => {
    if(!item){
      return null;
    }
    return {value: item, label: item};
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                    title={props.t("Events")}
                    buttons={JSON.parse(localStorage.getItem("eventsSearchFilter")) ? [{
                      text: props.t("Add Filter"),
                      onClick: tog_standard,
                    }, {
                      text: props.t("Clear Filter"),
                      onClick: handleClearFilter,
                    }] : [{
                      text: props.t("Add Filter"),
                      onClick: tog_standard,
                    }]}
                  />
                </CardHeader>
                <Loader loading={loading && page == 1} title="Events" />
                {!(loading && page == 1) &&
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(API_ERR_MSG)}
                    </Alert>
                  }
                  {!isEmpty(auditList) ? 
                  <div className="">
                    <ul className="verti-timeline list-unstyled">
                      <InfiniteScrollbarWithLoader 
                          items={auditList}
                          loading={loading}
                          totalItems={totalItems}
                          renderCallback={getStatusView}
                          page={page}
                          setPage={setPage} 
                          type={props.t("Events")} 
                          />
                    </ul>
                  </div> : 
                  (
                  <h6
                    align="center"
                    className="font-color-grey"
                  >
                    {props.t("No matching records found!")}
                  </h6>
                  )}
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Add Filter")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label={props.t("Close")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              {props.t("Entity Type")}
            </label>
            <div className="col-md-9">
              <Select
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={"Select Entity Type"}
                  onChange={(vals) => {
                    setEntityType(vals?.value);
                  }}
                  defaultValue={getLabelValue(entityType)}
                  options={entityTypes?.map(item => (getLabelValue(item)))}/>
            </div>
          </Row>
          {/* <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              {props.t("Entity Name")}
            </label>
            <div className="col-md-9">
              <input
                className="form-control"
                type="text"
                onChange={(e) => setEntityName(e.target.value)}
                placeholder={props.t("Enter entity name")}
                value={entityName}
              />
            </div>
          </Row> */}
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-3 col-form-label"
            >
              {props.t("Operation")}
            </label>
            <div className="col-md-9">
              <select
                className="form-control"
                value={operation}
                onChange={(e) => setOperation(e.target.value)}
              >
                <option value="">{props.t("Select Operation")}</option>
                {operationList.map(op => <option value={op}>{props.t(op)}</option>)}
              </select>
            </div>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="col-md-2 btn btn-secondary"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          <Button
            color="warning"
            onClick={() => handleClearFilter(true)}
            className="col-md-2 btn btn-warning waves-effect waves-light"
            disabled={!entityType && !operation && !entityName}
          >
            {props.t("Clear")}
          </Button>
          <Button
            color="primary"
            onClick={handleAddFilter}
            style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
            className="col-md-2 btn btn-primary waves-effect waves-light"
            disabled={!entityType && !operation && !entityName}
          >
            {props.t("Apply")}
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

Audit.propTypes = {
  audit: PropTypes.array,
  onGetAudit: PropTypes.func,
  onGetAuditReset: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    audit: state.Audit.audit?.data,
    metaInfo: {
        page: state.Audit.audit.currentPage + 1,
        totalPages: state.Audit.audit.totalPages,
        totalItems: state.Audit.audit.totalItems,
    },
    loading: state.Audit.loading,
    error: state.Audit.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetAudit: (filter) => dispatch(getAudit(filter)),
  onGetAuditReset: () => dispatch(getAuditReset()),
  onImpersonateUser: (obj, history, location) =>
      dispatch(impersonateUser(obj, history, location, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Audit)));
