import { GET_IMPERSONATION_PROJECT_LIST, GET_IMPERSONATION_PROJECT_LIST_FAIL, GET_IMPERSONATION_PROJECT_LIST_SUCCESS } from "../../project/actionTypes";
import {GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS, GET_IMPERSONATE_USER_EXIT_SUCCESS, GET_IMPERSONATION_USERS, GET_IMPERSONATION_USERS_FAIL, GET_IMPERSONATION_USERS_SUCCESS, GET_TENANT_LIST_SUCCESS, POST_IMPERSONATE_USER_SUCCESS} from "./actionTypes";

const INIT_STATE = {
  users: [],
  tenants: [],
  customers: [],
  projects: [],
  impersonateSuccess: {},
  impersonateExit: {},
  getImpersonationUsersLoading: false
};

const UserImpersonation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IMPERSONATION_USERS:
      return {
        ...state,
        getImpersonationUsersLoading: true,
      }
    case GET_IMPERSONATION_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        getImpersonationUsersLoading: false,
      }
    case GET_IMPERSONATION_USERS_FAIL:
      return {
        ...state,
        getImpersonationUsersLoading: false,
      }
    case GET_TENANT_LIST_SUCCESS:
      return {
        ...state,
        tenants: action.payload,
      }
    case GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS:
      return {
        ...state,
        customers: action.payload
      }
      case GET_IMPERSONATION_PROJECT_LIST_SUCCESS:
          return {
              ...state,
              projects: action.payload,
          }
    case POST_IMPERSONATE_USER_SUCCESS:
      return {
        ...state,
        impersonateSuccess: action.payload
      }
    case GET_IMPERSONATE_USER_EXIT_SUCCESS:
      return {
        ...state,
        impersonateExit: action.payload
      }
    default:
      return state;
  }
};

export default UserImpersonation
