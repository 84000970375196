import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  categoriesCard: {
    backgroundColor: "#f5f6f8",
    Height: "300px",
    padding: "0.5rem calc(100vw/1000px)/2",
  },
  cardContent: {
    textAlign: "center",
  },
  catName: {
    // textAlign: "center",
    // margin: "20px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "32px",
  },
  Link: {
    padding: "13px 50px",
    fontSize: "1rem",
    letterSpacing: "1px",
    transition: "0.25s all",
    border: "2px solid black",
    fontWeight: 200,
    "&:hover": {
      fontWeight: 800,
      transition: "0.25s all",
    },
  },
  img: {
    height: "140px",
    width: "75%",
    margin: "auto",
  },
  productImage: {
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    border: "none !important",
  },
  marquee: {
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  chips: {
    color: "#5f6972",
    width: "auto",
    margin: " 0px 4px",
  },
  chipsAvatar: {
    backgroundColor: "rgba(95, 105, 114, 0.25) !important",
    color: "#5f6972",
    width: "auto",
  },
  breadcrumbTitle: {
    fontWeight: "bold",
    color: "#5f6972",
    cursor: "default",
  },
  breadcrumbActions: {
    "& svg": {
      cursor: "pointer",
      marginRight: "2px",
      height: "25px",

      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },
}));
export default useStyles;
