import React, {useState} from "react"
import {withTranslation} from "react-i18next";
import {Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input} from "reactstrap";

/**
 * Use 'keyToDisplay' prop when 'selectedValue' props is an object and options is an array of objects and you want to use a value of 'keyToDisplay' key in object for displaying the text to user in UI
 */
const MenuSearchableDropdown = props => {
    const {selectedValue, options, isHeader, removeDummyOption, handleOnButtonClick, handleOnOptionClick, placeholder, keyToDisplay, favorite, removeHorizontalDropdownStretch, brandColor, enableDefaultButtonClick, disabled, prefix} = props;
    const [toggle, setToggle] = useState(false);
    const [search, setSearch] = useState("");

    const getButtonStyle = () => {
      if(disabled){
        return { backgroundColor: '#f5f6f8', color: '#495057', border: '1px solid #ced4da', borderRadius: '0.25rem' }
      }
      if(brandColor){
        return brandColor
      }
      return { backgroundColor: '#FFF', color: '#495057', border: '1px solid #a1b1be', borderRadius: '0.25rem' };
    }

    return (
      <div className={!removeHorizontalDropdownStretch && "btn-group searchable-btm-group"}>
        <ButtonDropdown
          isOpen={toggle}
          toggle={() => {
            setToggle(!toggle);
            if (toggle === false) {
              setSearch("")
            }
          }}
          className="sm-btn searchable-button-dropdown mt-0"
          disabled={disabled}
          // style={{maxHeight: '35px'}}
        >
            {(enableDefaultButtonClick || selectedValue != null) && (<Button
              id="caret"
              className="searchable-button"
              style={getButtonStyle()}
              onClick={(e) => {if(handleOnButtonClick){handleOnButtonClick()} setToggle(!toggle);}}
            >
                {!selectedValue ? <span>{props.t(placeholder)} &nbsp;&nbsp;</span> : <> {isHeader ? <h4 className={"m-0 text-white me-4"}>{prefix ? prefix : ''} {(keyToDisplay ? selectedValue[keyToDisplay]: selectedValue)} </h4> :  (keyToDisplay ? selectedValue[keyToDisplay]: selectedValue)}</>}
            </Button>)}
          <div className={selectedValue ? "searchable-button-toggle-wrapper" : "searchable-button-toggle-wrapper-grow"}>
            <DropdownToggle
              caret
              className="dropdown-toggle-split searchable-button-toggle"
              style={getButtonStyle()}
              id="page-header-user-dropdown"
            >
              {selectedValue == null && !enableDefaultButtonClick && (
                <span>{props.t(placeholder)} &nbsp;&nbsp;</span>
              )}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
          </div>
          <DropdownMenu className="dropdown-menu scrollable-list" style={{maxHeight: "500px", overflow: "scroll"}}>
            <DropdownItem header style={{ position: "sticky", top: 0, backgroundColor: "white" }}>
              <Form
                className="ml-0 d-lg-block"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Input
                  type="text"
                  className="bg-light border-light rounded toolbar-search"
                  placeholder={props.t("Search") + "..."}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  autoFocus
                />
              </Form>
            </DropdownItem>
            {!removeDummyOption && 
              <DropdownItem
                  key={-1}
                  onClick={() => {
                    handleOnOptionClick(null);
                  }}
                  style={{whiteSpace: 'initial'}}
                >
                  {placeholder}
                </DropdownItem>
            }
            {options != null &&
              options?.filter(option => option && (keyToDisplay ? option[keyToDisplay] : option)?.toLowerCase().includes(search?.toLowerCase()))?.map((element, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => {
                    handleOnOptionClick(element);
                  }}
                  style={{whiteSpace: 'initial'}}
                >
                    {favorite && element[favorite] ? <i className="fa fa-star font-size-12" style={{marginLeft: "-16px"}}></i>: ""} {keyToDisplay ? element[keyToDisplay] : element}{" "}
                </DropdownItem>
              ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    )
}

export default withTranslation()(MenuSearchableDropdown)
