import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header";


const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_APIBASEURL,
})

//axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.interceptors.request.headers = authHeader();

let refreshTokenRequestPending = false;

axiosApi.interceptors.request.use(async (config) => {
  try {
    if(!(window.location.pathname === '/login' || config.url === '/api/auth/refreshtoken' || refreshTokenRequestPending)){
      const authUserObj = localStorage.getItem("authUser")
      if(!authUserObj || authUserObj === 'undefined'){
        window.location.href = "/login";
        return
      }
      let user = JSON.parse(localStorage.getItem("authUser"));
      const decodedJwt = parseJwt(user.accessToken);
      const jwtExpiryDate = new Date(decodedJwt.exp * 1000);
      let triggerDate = new Date(decodedJwt.exp * 1000);
      triggerDate.setMinutes(triggerDate.getMinutes() - 45);
      if (!decodedJwt || (decodedJwt.exp * 1000 < Date.now())) {
          window.location.href = "/login";
      } else if(triggerDate < Date.now()) {
          refreshTokenRequestPending = true;
          const response = await post(`/api/auth/refreshtoken`, {refreshToken: user.accessToken})
          if(refreshTokenRequestPending) refreshTokenRequestPending = false;
          localStorage.setItem("authUser", JSON.stringify(response.data));

      }
    }
    return config;
  } catch (_error) {
    if(refreshTokenRequestPending) refreshTokenRequestPending = false;
    return Promise.reject(_error);
  }
}, function (error) {
  return Promise.reject(error);
});

/*axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)*/

axiosApi.interceptors.response.use((res) => {
    return Promise.resolve(res);
}, (error) => {
    if (error!= null && error.message == "Request failed with status code 401") {
        window.location.href = "/login";
    }
    return Promise.reject(error);
})

const parseJwt = (token) => {
    if(token){
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
    }
    return null;
  };

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config, headers: authHeader() }).then(response => response)
}

export async function getWithRequestParams(url, params) {
  return await axiosApi.get(url, { params: params, headers: authHeader() }).then(response => response)
}

export async function post(url, data, config = {}) {
  return axiosApi
      .post(url, { ...data }, { ...config, headers: authHeader() })
      .then(response => {
        return response;
      })
}

export async function postMultipart(url, data) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return axiosApi({method: "post",
        url: url,
        data: data,
        headers: {"Content-Type": "multipart/form-data", "Authorization": `Bearer ${obj.accessToken}`}
    }).then(response => {
        return response;
    });
}

export async function put(url, data, config = {}) {
  return axiosApi
      .put(url, { ...data }, { ...config, headers: authHeader() })
      .then(response => response)
}

export async function del(url, config = {}) {
  return await axiosApi
      .delete(url, { ...config, headers: authHeader() })
      .then(response => response)
}

