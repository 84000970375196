import React from "react";

import CustomerUserManagement from "./CustomerUserManagement";
import {Container} from "reactstrap";

const CustomerUsers = props => {
    let user = JSON.parse(localStorage.getItem("authUser"))
    return <React.Fragment>
        <div className="page-content">
            <Container fluid>
                <CustomerUserManagement customerId={user.customerId}/>
                {/*<Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>*/}
            </Container>
        </div>
    </React.Fragment>
}

export default CustomerUsers;
