import {call, put, takeEvery} from "redux-saga/effects"
import {getAuditSuccess, getAuditFail, getFilteredAuditSuccess, getFilteredAuditFail} from "./actions";
import {getAudit} from "../../helpers/fakebackend_helper";
import {GET_AUDIT, GET_FILTERED_AUDIT} from "./actionTypes";
import { API_ERR_MSG } from "../../util/constants";


function* fetchAudit({filter}) {
    try {
        const response = yield call(getAudit, filter);
        yield put(getAuditSuccess(response))
    } catch (error) {
        yield put(getAuditFail(API_ERR_MSG));
    }
}

function* fetchFilteredAudit({search}) {
    try {
        const response = yield call(getAudit, search);
        yield put(getFilteredAuditSuccess(response))
    } catch (error) {
        yield put(getFilteredAuditFail(API_ERR_MSG));
    }
}

function* auditSaga() {
    yield takeEvery(GET_AUDIT, fetchAudit);
    yield takeEvery(GET_FILTERED_AUDIT, fetchFilteredAudit);
}

export default auditSaga
