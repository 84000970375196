import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import {connect} from "react-redux";

import {Link, useHistory} from "react-router-dom";
import {equalsAny} from "../../util/helperFunctions";

// Redux Store
import {getAllCartItems, postAddCartItem, putUpdateCartItem, showRightSidebarAction, toggleLeftmenu,} from "../../store/actions";
// reactstrap
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoDark from "../../assets/images/lightab_logo.svg";

// import images
//i18n
import {withTranslation} from "react-i18next";
import UserImpersonation from "../../pages/Authentication/UserImpersonation";
import {CartService} from "../../pages/ShoppingCart/CartService";
import store from "../../store";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import { Button } from "reactstrap";
import TaskModal from "../../pages/Tasks/TaskModal";
import { find } from "lodash";

const Header = (props) => {
  const [isSearch, setSearch] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const { cartItems, getAllCartItems } = props;

  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  let user = JSON.parse(localStorage.getItem("authUser"));
  const history = useHistory();

  useEffect(() => {
    getAllCartItems();
  }, []);

  useEffect(() => {
    if (cartItems) {
      setCartCount(cartItems.length);
    }
  }, [cartItems]);

  CartService.addItemToCart = (product, callback) => {
    let cart = cartItems?.reduce((obj, cur) => {
      return { ...obj, [cur["article"]["id"]]: cur };
    }, {});
    if (!cart.hasOwnProperty(product.id)) {
      product.quantity = 1;
      cart[product.id] = product;
      store.dispatch(postAddCartItem(product.id, product.quantity)
      );
    } else {
      cart[product.id].quantity = Number(cart[product.id].quantity) + 1;
      
      const cartItem = find(cartItems, item => item.article.id == product.id);
      if(cartItem){
        store.dispatch(
          putUpdateCartItem({...cartItem, quantity: Number(cart[product.id].quantity)})
        );
      }
    }

    setCartCount(cart.length);
    callback && callback(cart[product.id].quantity);
  };

  CartService.addPackageToCart = (product, quantity) => {
    store.dispatch(postAddCartItem(product.id, Number(quantity))
    );
  };

  CartService.addItemsToCart = (product, count, callback) => {
    console.log(count);
    let cart = cartItems?.reduce((obj, cur) => {
      return { ...obj, [cur["article"]["id"]]: cur };
    }, {});
    if (!cart.hasOwnProperty(product.id)) {
      cart[product.id] = product;
    }

    const cartItem = find(cartItems, item => item.article.id == product.id);
    if(cartItem){
      cart[product.id].quantity = Number(count);
      setCartCount(cart.length);
      store.dispatch(putUpdateCartItem({id: cartItem.id, article: {id: product.id}, quantity: count}));
      callback && callback(cart[product.id].quantity);
    }
  };

  CartService.removeItemToCart = (cartItem, isDelete) => {
    let cart = cartItems?.reduce((obj, cur) => {
      return { ...obj, [cur["article"]["id"]]: cur };
    }, {});

    if (isDelete) {
      store.dispatch(putUpdateCartItem({...cartItem, quantity: 0}));
    }
    setCartCount(cart.length);
  };

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const handleGlobalSearch = () => {
    history.push("/search?value=" + searchValue);
  };

  const getBrandLogo = () => {
    if(user.brandConfig && user.brandConfig.brandLogo){
      return BASE_IMAGE_URL +"/"+ user.brandConfig.brandLogo;
    }
    return logoDark;
  }
    
  function toggleAddTaskModal() {
    setShowAddTaskModal(!showAddTaskModal)
  }


  return (
    <React.Fragment>
      <div className="navbar-header">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light h-22"
            data-toggle="collapse"
            onClick={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            data-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars" />
          </button>
          <div className="navbar-brand-box p-0">
            <Link to="/customer/categories" className="logo logo-dark">
              <span className="logo-sm">
                <img src={getBrandLogo()} alt="" height="28" />
              </span>
              <span className="logo-lg">
                <img src={getBrandLogo()} alt="" height="40" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={getBrandLogo()} alt="" height="28" />
              </span>
              <span className="logo-lg">
                <img src={getBrandLogo()} alt="" height="40" />
              </span>
            </Link>
          </div>
          {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_ACTING_USER"]) && (
            <div
              className="ms-5 mt-2 cs-hide-sm"
              style={{ display: "flex-bck" }}
            >
              <UserImpersonation />
            </div>
          )}
        </div>

        <div className="d-flex mt-2">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              onClick={() => setSearch(!isSearch)}
            >
              <i className="uil-search"></i>
            </button>
            <div
              className={
                isSearch
                  ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                  : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
              }
              aria-labelledby="page-header-search-dropdown"
            >
              <form
                className="p-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleGlobalSearch();
                }}
              >
                <div className="form-group m-0">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={props.t("Search") + "..."}
                      onChange={(e) => setSearchValue(e.target.value)}
                      aria-label="Recipient's username"
                    />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <Button
            active
            color="primary"
            onClick={() => {
              setShowAddTaskModal(true);
            }}
            className="btn btn-primary waves-effect waves-light mx-2 add-task-btn my-3"
            style={{ backgroundColor: user.brandConfig?.primaryBtnColor, borderColor: user.brandConfig?.primaryBtnColor}}
          >
            {props.t("New Task")}
          </Button>

          <LanguageDropdown />

          <div className="dropdown ms-1" style={{display: 'flex', alignItems: 'center'}}>
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              onClick={() => {
                toggleFullscreen();
              }}
              data-toggle="fullscreen"
            >
              <i className="uil-minus-path"></i>
            </button>
          </div>

          {/*<NotificationDropdown/>*/}

          <div className="dropdown ms-1" style={{display: 'flex', alignItems: 'center'}}>
            <Link to="/shop/cart">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect"
              >
                <i className="uil-shopping-cart"></i>
                {cartCount > 0 && (
                  <span className="badge bg-danger rounded-pill">
                    {cartCount}
                  </span>
                )}
              </button>
            </Link>
          </div>

          <ProfileMenu />

          {/*<div className="dropdown d-inline-block">
                        <button
                            onClick={() => {
                                props.showRightSidebarAction(!props.showRightSidebar)
                            }}
                            type="button"
                            className="btn header-item noti-icon right-bar-toggle waves-effect"
                        >
                            <i className="uil-cog"></i>
                        </button>
                    </div>*/}
        </div>
      </div>
      <TaskModal 
          modal_standard={showAddTaskModal} 
          setmodal_standard={setShowAddTaskModal} 
          tog_standard={toggleAddTaskModal}
          avoidRedirect={true}
      />
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  cartItems: PropTypes.array,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    cartItems: state.Cart?.cartItems,
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  getAllCartItems,
})(withTranslation()(Header));
