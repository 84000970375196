import {call, put, takeEvery} from "redux-saga/effects"
import {ADD_ADDRESS, EDIT_ADDRESS, GET_ADDRESS, GET_ADDRESS_LIST, GET_CUSTOMER, GET_CUSTOMER_FOR_AM, GET_CUSTOMER_LIST, GET_CUSTOMER_USERS} from "./actionTypes";
import {
    addAddressFail,
    addAddressSuccess,
    editAddressFail,
    editAddressSuccess,
    getAddressFail,
    getAddressListFail,
    getAddressListSuccess,
    getAddressSuccess,
    GetCustomerFail,
    getCustomerForAmFail,
    getCustomerForAMSuccess,
    GetCustomersFail,
    GetCustomersSuccess,
    GetCustomerSuccess,
    GetCustomerUsersFail,
    GetCustomerUsersSuccess,
} from "./actions";
import {getAddress, getAddressList, getCustomer, getCustomerList, getCustomersForAm, getCustomerUsers, postAddress, putAddress} from "../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../util/constants";
import {startAction, stopAction} from "../actions";


function* fetchCustomers({type, sync, showHidden}) {
    try {
        /*const customers = yield select(selector.customers)
        if(customers && customers.length > 0) {
            yield put(GetCustomersSuccess({data: customers}))
        } else {*/
        yield put(startAction(type))
        const response = yield call(getCustomerList, sync, showHidden)
        yield put(GetCustomersSuccess(response))
        /*}*/
    } catch (error) {
        yield put(GetCustomersFail(error))
    } finally {
        yield put(stopAction(type))
    }
}

function* fetchCustomer({customerId}) {
    try {
        /*const customers = yield select(selector.customers)
        const customer = customers.find(customer=>customer.id === customerId)
        if(customer) {
            yield put(GetCustomerSuccess({data: customer}))
        } else {*/
        const response = yield call(getCustomer, customerId)
        yield put(GetCustomerSuccess(response))
        /*}*/
    } catch (error) {
        yield put(GetCustomerFail(error))
    }
}

function* fetchCustomerUsers({customerId, filter}) {
    try {
        const response = yield call(getCustomerUsers, customerId, filter)
        yield put(GetCustomerUsersSuccess(response))
    } catch (error) {
        yield put(GetCustomerUsersFail(API_ERR_MSG))
    }
}

function* fetchAddressList({customerId, projectId}) {
    try {
        const response = yield call(getAddressList, customerId, projectId);
        yield put(getAddressListSuccess(response));
    } catch (error) {
        yield put(getAddressListFail(API_ERR_MSG));
    }
}

function* fetchAddress({addressId}) {
    try {
        const response = yield call(getAddress, addressId);
        yield put(getAddressSuccess(response));
    } catch (error) {
        yield put(getAddressFail(API_ERR_MSG));
    }
}

function* addAddress({customerId, addressData}) {
    try {
        const response = yield call(postAddress, {customerId, addressData});
        yield put(addAddressSuccess(response));
    } catch (error) {
        yield put(addAddressFail(API_ERR_MSG));
    }
}

function* updateAddress({customerId, addressId, addressData}) {
    try {
        const response = yield call(putAddress, {customerId, addressId, addressData});
        yield put(editAddressSuccess(response));
    } catch (error) {
        yield put(editAddressFail(API_ERR_MSG));
    }
}


function* customersForAm({userId}) {
    try {
        const response = yield call(getCustomersForAm, userId)
        yield put(getCustomerForAMSuccess(response))
    } catch (error) {
        yield put(getCustomerForAmFail(API_ERR_MSG))
    }
}


function* customerSaga() {
    yield takeEvery(GET_CUSTOMER_LIST, fetchCustomers)
    yield takeEvery(GET_CUSTOMER, fetchCustomer)
    yield takeEvery(GET_CUSTOMER_USERS, fetchCustomerUsers)
    yield takeEvery(GET_ADDRESS_LIST, fetchAddressList)
    yield takeEvery(GET_ADDRESS, fetchAddress)
    yield takeEvery(ADD_ADDRESS, addAddress)
    yield takeEvery(EDIT_ADDRESS, updateAddress)
    yield takeEvery(GET_CUSTOMER_FOR_AM, customersForAm)
}

export default customerSaga
