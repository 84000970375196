import {
    ADD_OFFER,
    ADD_OFFER_FAIL,
    ADD_OFFER_RESET,
    ADD_OFFER_SUCCESS,
    GET_OFFER_METRICS,
    GET_OFFER_METRICS_ERROR,
    GET_OFFER_METRICS_SUCCESS,
    GET_OFFERS,
    GET_OFFERS_DETAILS,
    GET_OFFERS_DETAILS_FAIL,
    GET_OFFERS_DETAILS_RESET,
    GET_OFFERS_DETAILS_SUCCESS,
    GET_OFFERS_FAIL,
    GET_OFFERS_RESET,
    GET_OFFERS_SUCCESS
} from "./actionTypes";

export const getOffers = (offerType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => ({
    type: GET_OFFERS,
    offerType,
    customerId,
    startDate,
    endDate,
    articleNumber,
    projectNumber,
    articleGroupId,
    manufacturer,
    ourReference,
    yourReference
})

export const getOffersSuccess = offers => ({
    type: GET_OFFERS_SUCCESS,
    payload: offers.data
})

export const getOffersFail = error => ({
    type: GET_OFFERS_FAIL,
    payload: error
})

export const getOffersReset = () => ({
    type: GET_OFFERS_RESET,
    payload: null
})


export const getOffersDetails = offerId => ({
    type: GET_OFFERS_DETAILS,
    offerId
})

export const getOffersDetailsSuccess = offers => ({
    type: GET_OFFERS_DETAILS_SUCCESS,
    payload: offers.data
})

export const getOffersDetailsFail = error => ({
    type: GET_OFFERS_DETAILS_FAIL,
    payload: error
})


export const getOffersDetailsReset = () => ({
    type: GET_OFFERS_DETAILS_RESET,
    payload: null
})

export const addOffer = () => ({
    type: ADD_OFFER,
})

export const addOfferSuccess = offers => ({
    type: ADD_OFFER_SUCCESS,
    payload: offers.data
})

export const addOfferReset = () => ({
    type: ADD_OFFER_RESET
})

export const addOfferFail = error => ({
    type: ADD_OFFER_FAIL,
    payload: error
})

export const getOfferMetrics = (startDate, endDate) => ({
    type: GET_OFFER_METRICS,
    startDate,
    endDate
})

export const getOfferMetricsSuccess = (response) => ({
    type: GET_OFFER_METRICS_SUCCESS,
    payload: response.data
})

export const getOfferMetricsError = (error) => ({
    type: GET_OFFER_METRICS_ERROR,
    payload: error
})
