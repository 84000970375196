import { uiActionTypes } from './actionTypes';

const initialState = {
  loader: {
    actions: []
  }
};

const ui = (state = initialState, { type, payload }) => {
  const { loader } = state;
  const { actions } = loader;
  switch (type) {
    case uiActionTypes.START_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: [...actions, payload.action]
        }
      };
    case uiActionTypes.STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter(action => action.name !== payload.name)
        }
      };
    default:
      return state;
  }
};

export default ui;