import {GET_FORTNOX_LABELS} from "./actionTypes";
import {call, put, takeEvery} from "redux-saga/effects"
import {getLabelsError, getLabelsSuccess} from "./actions";
import {getLabels} from "../../helpers/fakebackend_helper";

function* fetchLabels() {
    try {
        const response = yield call(getLabels);
        yield put(getLabelsSuccess(response))
    } catch (error) {
        yield put(getLabelsError(error))
    }
}


function* labelSaga() {
    yield takeEvery(GET_FORTNOX_LABELS, fetchLabels)
}

export default labelSaga
