import React, { useEffect } from "react"
import PropTypes from "prop-types"
import {Alert, Button, Col, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"
import { Link, useParams, withRouter } from "react-router-dom"
import { map } from "lodash"
import { useState } from "react"
import {withTranslation} from "react-i18next";
import { connect } from "react-redux"
import {getTaskCommentList, getTaskCommentListReset, getTaskWorkLog, getWorkLogType} from "../../store/task/actions"
import Loader from "../../components/Common/Loader"
import {post, put} from "../../helpers/api_helper"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {parseDateToString} from "../../util/helperFunctions";
import DatePicker from "react-date-picker";
import SearchableDropdown from "../../components/Common/SearchableDropdown";

const Comments = (props) => {
    const {
        comments,
        loading,
        getCommentsError,
        onGetComments,
        onGetCommentsReset,
        match: {params},
    } = props;
    const [c, setc] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const user = JSON.parse(localStorage.getItem("authUser"));
    const [activeTabJustify, setactiveTabJustify] = useState("5");
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [description, setDescription] = useState(null);
    const [logType, setLogType] = useState(null);
    const [date, setDate] = useState(null);
    const [worklogUser, setWorklogUser] = useState(null);

    const [worklogError, setWorklogError] = useState(null);


  useEffect(() => {
      setBrandSettings(user?.brandConfig);
      onGetComments(params.id);
      return () => {
        onGetCommentsReset();
      }
  }, [])

  const handleAddComment = async () => {
    let response = await put(`/api/task/${params.id}/comment`, {
        description: c,
        task: {id: params.id},
        fromUser: {id: user?.uid},
    });
    setc("");
    onGetComments(params.id)
  }

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
            setactiveTabJustify(tab)
        }
    }


    return (<>
      <Nav tabs className="nav-tabs-custom">
          <NavItem>
              <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                      active: activeTabJustify === "5",
                  })}
                  onClick={() => {
                      toggleCustomJustified("5")
                  }}
              >
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                  <span className="d-none d-sm-block">{props.t("Comments")}</span>
              </NavLink>
          </NavItem>
      </Nav>
      <TabContent activeTab={activeTabJustify}>
          <TabPane tabId="5" className="p-3">
              <div className="mt-4">
                  <Loader loading={loading} title="Task Comments"/>
                  <Row className="mb-3">
                      <div className="col-md-12 mb-3">
                          <textarea
                              className="form-control task-textarea"
                              type="text"
                              onChange={(e) => setc(e.target.value)}
                              placeholder={props.t("Enter Comment")}
                              value={c}
                              rows="3"
                          />
                          <Button
                              color="primary"
                              style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                              onClick={() => handleAddComment()}
                              disabled={!c}
                              className="col-md-2 btn btn-primary waves-effect waves-light float-sm-end mt-2"
                          >
                              {props.t("Add")}
                          </Button>
                      </div>
                  </Row>
                  {map(comments, (comment, k) => (
                      <React.Fragment key={'review_' + k}>
                          <div className={"border-top pt-3"}>
                              <p className="float-sm-end text-muted font-size-13">{new Date(comment.updatedAt).toLocaleString()}</p>
                              <Media className="d-flex">
                                  <div className="flex-1">
                                      <h5 className="font-size-15 mb-0">{comment.fromUser?.name}</h5>
                                  </div>
                              </Media>
                              <p className="text-muted mb-4">{comment.description}</p>
                          </div>
                      </React.Fragment>
                  ))}
              </div>
          </TabPane>
      </TabContent>
  </>
  )
}

Comments.propTypes = {
  comments: PropTypes.array,
  workLog: PropTypes.array,
  workLogTypes: PropTypes.array
}

const mapStateToProps = (state) => {
  return {
    comments: state.task.comments,
    getCommentsError: state.task.getCommentsError,
    loading: state.task.getCommentsLoading,
    workLog: state.task.workLog,
    workLogTypes: state.task.workLogType
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetComments: (taskId) => dispatch(getTaskCommentList(taskId)),
  onGetCommentsReset: () => dispatch(getTaskCommentListReset()),
  onGetTaskWorkLog: (taskId) => dispatch(getTaskWorkLog(taskId)),
  onGetWorkLogTypes: () => dispatch(getWorkLogType())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Comments)));
