import {
  GET_TENANT_CUSTOMER_USERS_FAIL,
  GET_TENANT_CUSTOMER_USERS_RESET,
  GET_TENANT_CUSTOMER_USERS_SUCCESS, GET_TENANT_USER_DETAILS, GET_TENANT_USER_DETAILS_FAIL, GET_TENANT_USER_DETAILS_RESET, GET_TENANT_USER_DETAILS_SUCCESS,
  TENANT_ACTIVATE_USER_FAIL,
  TENANT_ACTIVATE_USER_SUCCESS,
  TENANT_ADMIN_DELETE_USER_FAIL,
  TENANT_ADMIN_DELETE_USER_SUCCESS,
  TENANT_ADMIN_GET_USERS,
  TENANT_ADMIN_GET_USERS_FAIL,
  TENANT_ADMIN_GET_USERS_RESET,
  TENANT_ADMIN_GET_USERS_SUCCESS,
  TENANT_ADMIN_REGENERATE_TOKEN_FAIL,
  TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS,
  TENANT_ADMIN_RESET_DELETE_USER_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  user: null,
  userLoading: false,
  users: [],
  customerUsers: [],
  customerUsersTotal: null,
  tokenRegenerate: null,
  adminUsersLoading: false,
  adminUsersError: null,
  getCustomerUsersError: null,
};

const TenantAdmin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TENANT_ADMIN_GET_USERS:
      return {
        ...state,
        adminUsersLoading: true,
      }
    case TENANT_ADMIN_GET_USERS_SUCCESS:
      return {
        ...state,
        adminUsersLoading: false,
        users: action.payload,
      }
    case TENANT_ADMIN_GET_USERS_FAIL:
      return {
        ...state,
        adminUsersLoading: false,
        adminUsersError: action.payload,
      }
    case TENANT_ADMIN_GET_USERS_RESET:
      return {
        ...state,
        adminUsersLoading: false,
        adminUsersError: null,
        users: [],
      }
    case TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS:
        return {
          ...state,
          tokenRegenerate: action.payload
        }
    case TENANT_ADMIN_DELETE_USER_SUCCESS:
      return {
        ...state,
        userDeleted: action.payload,
      }
    case TENANT_ADMIN_RESET_DELETE_USER_SUCCESS:
      return {
        ...state,
        userDeleted: action.payload,
        userActivated: action.payload,
      }
    case TENANT_ADMIN_REGENERATE_TOKEN_FAIL:
      return {
        ...state,
        tokenRegenerate: action.payload
      }
    case TENANT_ADMIN_DELETE_USER_FAIL:
      return {
        ...state,
        userDeleted: action.payload
      }
    case TENANT_ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        userActivated: action.payload
      }
    case TENANT_ACTIVATE_USER_FAIL:
      return {
        ...state,
        userActivated: action.payload
      }
    case GET_TENANT_CUSTOMER_USERS_SUCCESS:
      return {
        ...state,
        customerUsers: action.payload.data,
        customerUsersTotal: action.payload.totalPages,
        getCustomerUsersError: null,
      }
    case GET_TENANT_CUSTOMER_USERS_FAIL:
      return {
        ...state,
        getCustomerUsersError: action.payload,
      }
    case GET_TENANT_CUSTOMER_USERS_RESET:
      return {
        ...state,
        getCustomerUsersError: null,
        customerUsers: [],
      }
    case GET_TENANT_USER_DETAILS:
      return {
        ...state,
        userLoading: true,
      }
    case GET_TENANT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        userLoading: false
      }
    case GET_TENANT_USER_DETAILS_FAIL:
      return {
        ...state,
        user: null,
        userLoading: false
      }
    case GET_TENANT_USER_DETAILS_RESET:
      return {
        ...state,
        user: null,
        userLoading: false
      }
    default:
      return state;
  }
};

export default TenantAdmin
