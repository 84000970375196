import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"

//i18n
import {withTranslation} from "react-i18next"
// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// users
import user4 from "../../../assets/images/users/user-img-placeholder-2.png"
import {BASE_IMAGE_URL} from "../../../helpers/url_helper";
import {exitUserImpersonation} from "../../../store/users/impersonation/actions";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [name, setname] = useState("Admin")
  const [profileImageId, setProfileImageId] = useState(null)

  let user = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setname(obj.username)
      setProfileImageId(obj.profileImageId)
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        style={{display: 'flex', alignItems: 'center'}}
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={profileImageId == null ? user4 : BASE_IMAGE_URL + "/" + profileImageId}
            alt="Header Avatar"
          />
          {/*<i className="uil uil-user-circle font-size-20"></i>*/}
          <span className="d-none d-xl-inline-block ms-2 fw-medium font-size-15">{name}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/settings/user-profile">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("My Account")}
          </DropdownItem>
          <div className="dropdown-divider" />
          { user?.roles.includes("ROLE_ACTING_USER") &&
          <DropdownItem onClick={()=>{
            props.exitUserImpersonation(props.history)
          }}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Exit Impersonation")}</span>
          </DropdownItem> }
          <Link to="/logout" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success, user } = state.Profile
  return { error, success, user }
}

export default withRouter(
  connect(mapStatetoProps, {exitUserImpersonation})(withTranslation()(ProfileMenu))
)
