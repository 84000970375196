import {Button, Col, Row} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
//import Moment from 'react-moment';
import moment from 'moment';
import {getArticleGroupList} from "../../store/e-commerce/actions";
import {get} from "../../helpers/api_helper";
import SearchableDropdown from "../Common/SearchableDropdown";
import PropTypes from "prop-types";
import {checkIfLoading} from "../../store/selector";
import {GET_ARTICLE_GROUP_LIST} from "../../store/e-commerce/actionTypes";
import {customersActionTypes} from "../../store/customer/actionTypes";
import {GetCustomers} from "../../store/customer/actions";
import {getTenantAdmins} from "../../store/tenant/actions";
import {getProjectList} from "../../store/project/actions";
import {getManufacturer} from "../../store/manufacturer/actions";


const MONTH = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const getCurrentWeek = () => {
    let today = new Date();
    const startOfTheWeek = JSON.parse(localStorage.getItem("authUser"))?.config?.startOfTheWeek;
    if ("SUN" === startOfTheWeek) {
        return {
            startDate: new Date(today.setDate(today.getDate() - today.getDay())),
            endDate: new Date(today.setDate(today.getDate() - today.getDay() + 6)),
            type: 'Week'
        }
    } else {
        let startDate = moment(today).startOf('isoWeek');
        var date = {
            startDate: startDate.toDate(),
            endDate: new Date(startDate.toDate().setDate(startDate.toDate().getDate() - startDate.toDate().getDay() + 7)),
            type: 'Week'
        }
        return date;
    }

}

const getCurrentMonth = () => {
    const date = new Date();
    return {
        startDate: new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        type: 'Month'
    }

}

const getCurrentYear = () => {
    return {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(new Date().getFullYear(), 11, 31),
        type: 'Year'
    }
}

const getCurrentQuarter = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3))
    const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
    return {
        startDate: startFullQuarter,
        endDate: endFullQuarter,
        type: 'Quarter'
    }
}

const ReportFilter = (props) => {
    let {onChange, disabled, groups, onGetGroupList, groupLoading, customers,
        onGetCustomers, customerLoading, tenantUsers, onGetTenantUsers, tenantUserOptionsLoading,
        projects, projectsLoading, onGetProjectOptions,
        manufacturerList, manufacturerListLoading, onGetManufacturer, showFilter} = props;

    const user = JSON.parse(localStorage.getItem("authUser"));

    const [brandSettings, setBrandSettings] = useState(user.brandConfig);
    const [type, setType] = useState("Month");
    const [typeLabel, setTypeLabel] = useState("Month");
    const [date, setDate] = useState(getCurrentMonth());
    const [products, setProducts] = useState(null);
    const [getArticlesPending, setGetArticlesPending] = useState(false);
    const [selectProduct, setSelectProduct] = useState(null);
    const [selectGroup, setSelectGroup] = useState(null);
    const [selectCustomer, setSelectCustomer] = useState(null);
    const [ourReference, setOurReference] = useState(null);
    const [selectProject, setSelectProject] = useState(null);
    const [selectManufacturer, setSelectManufacturer] = useState(null);

    useEffect(() => {
        onGetProducts();
        onGetGroupList();
        onGetCustomers();
        onGetTenantUsers(user?.tenantId);
        onGetProjectOptions(user?.tenantId);
        onGetManufacturer();
    }, [])

    useEffect(() => {
        if (type == "Week") {
            let currentWeek = getCurrentWeek();
            setDate(currentWeek);
        }
        if (type == "Month") {
            let currentMonth = getCurrentMonth();
            setDate(currentMonth);
        }
        if (type == "Quarter") {
            let currentQuarter = getCurrentQuarter();
            setDate(currentQuarter);
        }
        if (type == "Year") {
            let currentYear = getCurrentYear();
            setDate(currentYear)
        }

    }, [type])

    useEffect(() => {
        var filter = {
            articleNumber: selectProduct?.value,
            customerNumber: selectCustomer?.customerNumber,
            articleGroupId: selectGroup?.id,
            manufacturer: selectManufacturer?.name,
            projectNumber: selectProject?.projectNumber,
            ourReference: ourReference?.fortnoxUsername ? ourReference?.fortnoxUsername : ourReference?.name,
            yourReference: null
        }

        onChange(date, filter)
        if (type === "Week") {
            const startOfTheWeek = JSON.parse(localStorage.getItem("authUser"))?.config?.startOfTheWeek;
            let weekNumber = moment(date?.endDate).format('W');
            if (startOfTheWeek === "SUN") {
                weekNumber = moment(date?.endDate).startOf('isoWeek').format('W');
            }
            setTypeLabel(props.t("Week") + " " + weekNumber)
        }
        if (type === "Month") {
            setTypeLabel(props.t(MONTH[date?.startDate.getMonth()]) + " " + date?.startDate.getFullYear())
        }
        if (type === "Quarter") {
            setTypeLabel(props.t(MONTH[date?.startDate.getMonth()]) + " - " + props.t(MONTH[date?.endDate.getMonth()]) + ", " + date?.endDate.getFullYear())
        }
        if (type === "Year") {
            setTypeLabel(date?.startDate.getFullYear())
        }
    }, [date, selectCustomer, selectProduct, selectGroup, ourReference, selectProject, selectManufacturer])

    const handleTypeChange = (t) => {
        setType(t);
    }

    const onGetProducts = () => {
        setGetArticlesPending(true);
        get(`/api/article/labelValue`)
            .then(data => {
                setGetArticlesPending(false);
                if (data?.status == 200) {
                    setProducts(data?.data);
                }
            }).catch(err => {
            setGetArticlesPending(false);
        })
    }

    const substract = () => {
        if (type == 'Week') {
            let startDate = date.startDate;
            let endDate = date.endDate;

            startDate.setDate(startDate.getDate() - 7);
            endDate.setDate(endDate.getDate() - 7)
            setDate({
                startDate: startDate,
                endDate: endDate,
                type: type
            })
        }
        if (type == "Month") {
            let startDate = date.startDate;
            let endDate = date.endDate;

            setDate({
                startDate: moment(startDate).subtract(1, "months").set("date", 1).toDate(),
                endDate: moment(startDate).subtract(1, "months").endOf("months").toDate(),
                type: type
            })
        }
        if (type == "Quarter") {
            const today = date.startDate;
            const quarter = Math.floor((today.getMonth() / 3)) - 1

            const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
            setDate({
                startDate: startFullQuarter,
                endDate: endFullQuarter,
                type: type
            })

        }
        if (type == "Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() - 1, 0, 1),
                endDate: new Date(endDate.getFullYear() - 1, 11, 31),
                type: type
            })
        }

    }

    const addition = () => {

        if (type == 'Week') {
            let startDate = date.startDate;
            let endDate = date.endDate;

            startDate.setDate(startDate.getDate() + 7);
            endDate.setDate(endDate.getDate() + 7)
            setDate({
                startDate: startDate,
                endDate: endDate,
                type: type
            })
        }
        if (type == "Month") {
            let startDate = date.startDate;
            let endDate = date.endDate;

            let lastMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);

            setDate({
                startDate: moment(startDate).add(1, "months").set('date', 1).toDate(),
                endDate: moment(startDate).add(1, "months").endOf("months").toDate(),
                type: type
            })
        }
        if (type == "Quarter") {
            const today = date.startDate;
            const quarter = Math.floor((today.getMonth() / 3)) + 1
            const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
            setDate({
                startDate: startFullQuarter,
                endDate: endFullQuarter,
                type: type
            })
        }
        if (type == "Year") {
            let startDate = date.startDate;
            let endDate = date.endDate;
            setDate({
                startDate: new Date(startDate.getFullYear() + 1, 0, 1),
                endDate: new Date(endDate.getFullYear() + 1, 11, 31),
                type: type
            })
        }


    }
    return (
        <>
            <Row>
                <Col xs={4}>
                    <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                    >
                        <Button color="primary" disabled={disabled} onClick={substract} style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}> <i
                            class="uil uil-arrow-left"></i> </Button>
                        <Button color="light" outline={true} style={{borderColor: brandSettings?.primaryBtnColor}}>{typeLabel}</Button>
                        <Button color="primary" disabled={moment(date?.endDate).isSameOrAfter(new Date(), 'day') || disabled} onClick={addition}
                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}> <i class="uil uil-arrow-right"></i></Button>
                    </div>
                </Col>
                <Col xs={6}>
                </Col>
                <Col xs={2}>
                    <div
                        className="btn-group float-end"
                        role="group"
                        aria-label="Basic example"
                    >
                        <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Week' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                onClick={() => handleTypeChange("Week")}>{props.t("Week")}</Button>
                        <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Month' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                onClick={() => handleTypeChange("Month")}>{props.t("Month")}</Button>
                        <Button color="primary" disabled={disabled}
                                style={{backgroundColor: type == 'Quarter' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                onClick={() => handleTypeChange("Quarter")}>{props.t("Quarter")}</Button>
                        <Button color="primary" disabled={disabled} style={{backgroundColor: type == 'Year' ? brandSettings?.secondaryBtnColor : brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                onClick={() => handleTypeChange("Year")}>{props.t("Year")}</Button>
                    </div>
                </Col>
            </Row>
            {showFilter && <Row className={"mt-3"}>
                <Col xs={2}>
                    <SearchableDropdown
                        selectedValue={ourReference}
                        options={tenantUsers}
                        placeholder={tenantUserOptionsLoading ? props.t("Loading users..") : props.t("Select Our Reference")}
                        handleOnOptionClick={(et) => setOurReference(et)}
                        disabled={tenantUserOptionsLoading}
                        keyToDisplay={["name"]}
                    />
                </Col>
                <Col xs={2}>
                    <SearchableDropdown
                        selectedValue={selectProduct}
                        options={customers}
                        placeholder={customerLoading ? props.t("Loading customers..") : props.t("Select customer")}
                        handleOnOptionClick={(et) => setSelectCustomer(et)}
                        disabled={customerLoading}
                        keyToDisplay={["name"]}
                        favorite={["favorite"]}
                    />
                </Col>
                <Col xs={2}>
                    <SearchableDropdown
                        selectedValue={selectProduct}
                        options={products}
                        placeholder={getArticlesPending ? props.t("Loading articles..") : props.t("Select article")}
                        handleOnOptionClick={(et) => {console.log(et); setSelectProduct(et); handleTypeChange(type)}}
                        disabled={getArticlesPending}
                        keyToDisplay={["label"]}
                    />
                </Col>
                <Col xs={2} className={"padding-right-20"}>
                    <SearchableDropdown
                        selectedValue={selectGroup}
                        options={groups}
                        placeholder={groupLoading ? props.t("Loading article groups") : props.t("Select article group")}
                        handleOnOptionClick={(et) => setSelectGroup(et)}
                        disabled={groupLoading}
                        keyToDisplay={["name"]}
                    />
                </Col>
                <Col xs={2} className={"padding-right-20"}>
                    <SearchableDropdown
                        selectedValue={selectProject}
                        options={projects}
                        placeholder={projectsLoading ? props.t("Loading projects") : props.t("Select project")}
                        handleOnOptionClick={(et) => setSelectProject(et)}
                        disabled={projectsLoading}
                        keyToDisplay={["description"]}
                    />
                </Col>
                <Col xs={2} className={"padding-right-20"}>
                    <SearchableDropdown
                        selectedValue={selectManufacturer}
                        options={manufacturerList}
                        placeholder={manufacturerListLoading ? props.t("Loading manufacturer") : props.t("Select manufacturer")}
                        handleOnOptionClick={(et) => setSelectManufacturer(et)}
                        disabled={manufacturerListLoading}
                        keyToDisplay={["name"]}
                    />
                </Col>
            </Row>}
        </>
    )
}

ReportFilter.propTypes = {
    groups: PropTypes.array,
    customers: PropTypes.array,
    onGetCustomers: PropTypes.func,
    onGetGroupList: PropTypes.func,

}

const mapStateToProps = (state) => {
    return {
        customers: state.Customer.customers,
        customerLoading: checkIfLoading(state, customersActionTypes.GET_CUSTOMER_LIST),
        groups: state.ecommerce.groupList,
        error: state.ecommerce.groupListError,
        groupLoading: checkIfLoading(state, GET_ARTICLE_GROUP_LIST),
        tenantUsers: state.tenant.tenantAdmins,
        tenantUserOptionsLoading: state.tenant.tenantAdminUsersLoading,
        projects: state.Project.projects,
        projectsLoading: state.Project.loading,
        manufacturerList: state.Manufacturer.manufacturerList,
        manufacturerListLoading: state.Manufacturer.manufacturerListLoading,
    }
}



const mapDispatchToProps = (dispatch) => ({
    onGetGroupList: () => dispatch(getArticleGroupList()),
    onGetCustomers: () => dispatch(GetCustomers(false)),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
    onGetProjectOptions: (tenantId) => dispatch(getProjectList(null, null, tenantId, null,false)),
    onGetManufacturer: () => dispatch(getManufacturer())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ReportFilter)))



