import {GET_FORTNOX_LABELS, GET_FORTNOX_LABELS_ERROR, GET_FORTNOX_LABELS_SUCCESS} from "./actionTypes";

const INIT_STATE = {
    labels: [],
    labelsLoading: false,
    labelsError: null
}


const Labels = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FORTNOX_LABELS:
            return {
                ...state,
                labels: [],
                labelsLoading: true,
                labelsError: null
            }
        case GET_FORTNOX_LABELS_SUCCESS:
            return {
                ...state,
                labels: action.payload,
                labelsLoading: false
            }
        case GET_FORTNOX_LABELS_ERROR:
            return {
                ...state,
                labels: [],
                labelsLoading: false,
                labelsError: action.payload
            }
        default:
            return state;
    }
}

export default Labels;
