export const BASE_IMAGE_URL = process.env.REACT_APP_IMAGEURL
export const DOWNLOAD_URL = process.env.REACT_APP_APIBASEURL + "/api/media/download"
export const ARTICLE_EXPORT_URL = process.env.REACT_APP_APIBASEURL + "/api/media/article/export/"
export const TRANSLATION_EXPORT_URL = process.env.REACT_APP_APIBASEURL + "/api/media/translation/label/export/"
export const LOGO_URL = process.env.REACT_APP_APIBASEURL + "/api/media/logo"

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const SOCIAL_LOGIN = "/social-login"
export const POST_JWT_LOGIN = "/api/auth/signin"
export const POST_ACTIVATE_USER = "/api/auth/activate"
export const GET_LOGIN_BRANDING = "/api/auth/brand"

export const GET_CURRENCY = "/api/currency"

//User
export const GET_TENANT_ADMINS = "/api/user/tenantAdmin"
export const REGENERATE_TOKEN = "/api/user/regeneratetoken/"
export const ACTIVATE_USER = "/api/user/activate"
export const GET_USER = "/api/user/"
export const DELETE_USER = "/api/user/"
export const GET_USER_IMPERSONATION = "/api/user/impersonate/userByRole"
export const POST_IMPERSONATE_USER = "/api/user/impersonate"
export const GET_IMPERSONATE_USER_EXIT = "/api/user/impersonate/exit"
export const CHANGE_PASSWORD = (uid) => `/api/user/changepassword/${uid}`
export const PUT_USER_PROFILE = (uid) => `/api/user/${uid}/editProfile`
export const POST_FORGET_PASSWORD = "/api/user/forgetPassword"
export const TENANT_ADMIN_GET_USERS = "/api/tenant/users"
export const TENANT_CUSTOMER_GET_USERS = "/api/user/userByRole"
export const GET_TENANT_LIST = "/api/tenant"
export const GET_TENANT_DETAILS = "/api/tenant"
export const GET_CUSTOMER_LIST = "/api/customers"
export const GET_CUSTOMER_USERS = "/api/customers/"
export const GET_ALL_USERS = "/api/user"

//Audit
export const GET_AUDIT = "/api/events"

export const GET_LABELS = "/api/label"

//Config
export const GET_SYSTEM_CONFIG = "/api/settings/systemConfig"
export const GET_TENANT_CONFIG = "/api/settings/fortnoxConfig"
export const GET_BRAND_SETTINGS = "/api/brand"
export const POST_RESET_BRAND_COLORS = "/api/brand/resetColors"
export const GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS = "/api/brand/includeSuperAdminConfig"
export const GET_SMTP_CONFIG = "/api/settings/smtp"
export const GET_ARTICLE_CONFIG = "/api/settings/articleConfig"
export const GET_PROJECT_CONFIG = "/api/settings/projectConfig"
export const GET_STOCK_POINT_CONFIG = "/api/settings/stockPointConfig"
export const GET_STOCK_POINTS = "/api/settings/stockPoints"
export const GET_CRON_LIST = "/api/cron/log"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/api/article"
export const GET_PRODUCTS_DETAIL = "/api/article"
export const GET_PRODUCTS_BY_CATEGORY = "/api/article"
export const GET_FAVORITE_PRODUCTS = "/api/productLists"
export const GET_PRODUCTS_BY_GROUP = "/api/article/by-group"
export const GET_ALL_PRODUCTS_BY_GROUP = "/api/article"

export const GET_PRICE_LIST = (articleId, fromQuantity) => `/api/article/${articleId}` + fromQuantity ? `?fromQuantity=${fromQuantity}` : ""

//LAYOUT
export const GET_LAYOUT = "/api/template"

export const GET_DEFAULT_LAYOUT = "/api/tab/article"

//Content
export const GET_CONTENT_TYPES = "/api/content"

//Content
export const GET_FIELDS = "/api/fields"

export const GET_CONTENT_TYPES_FOR_ARTICLE = "/api/content/articles"

//PROJECTS
export const GET_PROJECT_LIST = `/api/projects`;

export const GET_PROJECT_LIST_BY_PARTICIPANT = (userId) => `/api/projects/by-participant/${userId}`;

export const GET_PROJECT_LIST_BY_LEADER = (userId) => `/api/projects/by-leader/${userId}`;

export const GET_PROJECT_USERS = "/api/projects/users"

export const GET_PROJECT_TEMPLATE = "/api/project-template"

//Category
export const GET_CATEGORY_LIST = "/api/category"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/api/orders"
export const GET_ORDERS_FOR_AM = "/api/orders/account-manager"
export const COPY_ORDER_TO_CART = (orderId) => `/api/orders/${orderId}/copyToCart`;
export const GET_ORDERS_BY_REFERENCE = (userId) => `/api/orders/${userId}/reference`;
export const GET_SEARCH = "/api/search"
export const GET_ARTICLE_GROUP_LIST = "/api/article/group";
export const GET_GROUP_BY_ARTICLE = "/api/article/group/by-article"
//CART DATA
export const GET_CART_DATA = "/cart"

export const DASHBOARD_LINE_CHART = "/api/report"

export const DASHBOARD_REPORT_METRICS_LIST = "/api/report/list"

export const PRICE_LIST_FOR_TENANT = "/api/price-list"

//CUSTOMERS
export const GET_CUSTOMERS = "/api/customers"

export const GET_CUSTOMERS_FOR_AM = "/api/customers/account-manager"

// customer addresses
export const GET_ADDRESS_LIST = (customerId, projectId) => `/api/delivery-address?customerId=${customerId ? customerId : ''}&projectId=${projectId ? projectId : ''}`;
export const GET_ADDRESS = (deliveryAddressId) => `/api/delivery-address/${deliveryAddressId}`;
export const POST_ADDRESS = (customerId) => customerId ? `/api/delivery-address?customerId=${customerId}` : '/api/delivery-address' ;
export const PUT_ADDRESS = (customerId, deliveryAddressId) => `/api/delivery-address/${deliveryAddressId}${customerId ? '?customerId='+customerId : ''}`;

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/api/invoices"
export const GET_CUSTOMER_INVOICES = (invoiceType, customerId, startDate, endDate) => startDate && endDate ? `/api/customers/${customerId}/invoices?type=${invoiceType}&startDate=${startDate}&endDate=${endDate}` : `/api/customers/${customerId}/invoices?type=${invoiceType}`
export const GET_CUSTOMER_INVOICE_DETAIL = (invoiceId) => `/api/invoices/${invoiceId}/details`
export const GET_INVOICE_METRICS = (startDate, endDate) => `/api/invoices/metrics?startDate=${startDate}&endDate=${endDate}`

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

//cart
export const GET_CART_ITEMS = "/api/cart"


//Offers
export const GET_OFFERS = "/api/offer"


export const GET_TEMPLATE_LIST = "/api/tab-template"

export const GET_FAVOURITES = "/api/productLists"

// Tasks
export const GET_TASKS = "/api/task"
export const GET_TASK = (taskId) => `/api/task/${taskId}`
export const GET_TASK_COMMENTS = (taskId) => `/api/task/${taskId}/comment`

export const GET_TASK_WORKLOG = (taskId) => `/api/timeslip/task/${taskId}`

export const GET_WORKLOG_TYPE = `/api/timeslipTypes`;

export const GET_TASK_CATEGORIES = `/api/taskCategories`;

export const GET_WORK_LOG_FOR_CUSTOMER = `/api/timeslip/customer`

export const GET_TASKS_BY_PROJECT = `api/task/project`

export const GET_WORKLOG_BY_PROJECT = `api/timeslip/project`

export const GET_WORKLOGS = `api/timeslip`

export const GET_WORKLOG_FOR_SUPERADMIN = `api/timeslip/superadmin`

export const GET_LANGUAGE_LIST = "/api/translation/language"
export const GET_LABLES_LIST = (locale) => `/api/translation/label/${locale}`

export const GET_PHRASE_LIST = "/api/translation/phrase"

export const GET_TASK_PRIORITES = `/api/taskPriorities`

export const GET_WORK_LOG_FOR_USER = `/api/timeslip`;

export const GET_DEFAULT_TIMESLIP_TYPE = `/api/timeslipTypes/default`;

export const GET_MANUFACTURER = "/api/manufacturer"
