import PropTypes from "prop-types";
import {getProducts} from "../../store/e-commerce/actions";
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Alert, Col, Pagination, PaginationItem, PaginationLink, Row} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import React, {useEffect, useState} from "react";
import CategoryRibbon from "../../components/Common/CategoryRibbon";
import {ARTICLE_EXPORT_URL} from "../../helpers/url_helper";
import {debounce} from "../../util/helperFunctions";
import {del} from "../../helpers/api_helper";

const nonSortingColumns = ['category', 'articleGroup', 'stockable', 'export'];


const ArticleListTable = (props) => {

    const {key, products, onGetProducts, columns, loading, error, metaInfo, customFilter, filter} = props;

    const history = useHistory();

    const [sortState, setSortState] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [productList, setProductList] = useState([]);


    const getPage = (totalPage) => {
        let pages = [];
        let count = page == 1 ? page + 5 : page;
        for (let i = count - 5 > 0 ? count - 5 : 1; i < count + 5 && i <= totalPage; i++) {
            pages.push(i);
        }
        return pages;
    }

    useEffect(() => {
        setTotalPage(metaInfo.totalPages);
        setTotalItems(metaInfo.totalItems);
    }, [metaInfo]);

    useEffect(() => {
        loadArticles();
    }, [page, key]);

    useEffect(() => {
        setProductList(products);
    }, [products])

    const loadArticles = () => {
        if (page !== 1 && productList?.length < totalItems && !loading && page <= totalPage) {
            onGetProducts(page - 1, sizePerPage, search, filter, customFilter, sortState);
        } else if (page == 1) {
            onGetProducts(0, sizePerPage, search, filter, customFilter, sortState);
        }
    }

    useEffect(() => {
        try {
            if (sortState && productList) {
                const {column, direction} = sortState;
                if (!nonSortingColumns.includes(column)) {
                    if (page == 1 && column && direction) {
                        onGetProducts(0, sizePerPage, search, filter, customFilter, sortState);
                    } else {
                        setPage(1);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [sortState])

    const getDatatableData = () => {
        let data = {
            columns: columns ? columns : [
                {
                    label: props.t("Name"),
                    field: "description",
                    width: 300,
                },
                {
                    label: props.t("Article Number"),
                    field: "articleNumber",
                    width: 100,
                },
                {
                    label: props.t("Category"),
                    field: "category",
                    width: 200,
                },
                {
                    label: props.t("Article Group"),
                    field: "articleGroup",
                    width: 200,
                },
                {
                    label: props.t("Disposable Quantity"),
                    field: "disposableQuantity",
                    width: 100,
                },
                {
                    label: props.t("Reserved Quantity"),
                    field: "reservedQuantity",
                    width: 100,
                },
                {
                    label: props.t("Sales Price"),
                    field: "salesPrice",
                    width: 100
                },
                {
                    label: props.t("Published"),
                    field: "publish",
                    width: 200,
                },
                {
                    label: props.t("Stockable"),
                    field: "stockable",
                    width: 200,
                },
                {
                    label: props.t("Export"),
                    field: "export",
                    width: 50,
                },
            ],
            rows: productList?.map(
                (item, key) => ({
                    description: <div
                        onClick={() => handleOpen(item)}>{item.displayName ? item.displayName : item.description} &nbsp;&nbsp; {["MANUAL", "DYNAMIC"].includes(item.articlePackageType) &&
                        <label className="badge bg-soft-info bg-info p-1">{props.t("Package")}</label>}</div>,
                    articleNumber: <div onClick={() => handleOpen(item)}>{item.articleNumber}</div>,
                    category: <div onClick={() => handleOpen(item)}><CategoryRibbon categoryList={item.categories}/></div>,
                    articleGroup: <div onClick={() => handleOpen(item)}>{item.articleGroup?.name}</div>,
                    disposableQuantity: <div className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}
                                             onClick={() => handleOpen(item)}>{item.stockable === false ? "" : item.disposableQuantity ? item?.disposableQuantity : "0"}</div>,
                    reservedQuantity: <div onClick={() => handleOpen(item)}>{item.stockable === false ? "" : item?.reservedQuantity ? item?.reservedQuantity : "0"}</div>,
                    salesPrice: <div onClick={() => handleOpen(item)}>{item.salesPrice}</div>,
                    publish:
                        item.publish === true ? (
                            <div className="product-ribbon badge bg-soft-success bg-success" onClick={() => handleOpen(item.id)}>
                                {" "}
                                {props.t("Published")}{" "}
                            </div>
                        ) : (
                            <div className="product-ribbon badge bg-soft-warning bg-warning" onClick={() => handleOpen(item.id)}>
                                {" "}
                                {props.t("Unpublished")}
                            </div>
                        ),
                    stockable:
                        item.stockable === false ? (
                            <div className="product-ribbon badge bg-soft-danger bg-danger" onClick={() => handleOpen(item.id)}>
                                {" "}
                                {props.t("Non-stockable")}{" "}
                            </div>
                        ) : (
                            <div className="product-ribbon badge bg-soft-primary bg-primary" onClick={() => handleOpen(item.id)}>
                                {" "}
                                {props.t("Stockable")}
                            </div>
                        ),
                    export: <div onClick={() => handleExportToExcel(item)}><i className="fa fa-download font-size-10"></i></div>,
                    groupAction: <><i className="uil uil-trash-alt text-danger font-size-18 cursor-pointer" onClick={() => removeArticleFromGroup(item)}></i></>,
                })
            ),
        };
        return data;
    };

    const handleOpen = (item) => {
        //history.push(`/articles/${id}/details`);
        let level = localStorage.getItem("level");
        if(level == "TENANT"){
            history.push(`/articles/${item?.articleNumber}`);
            return;
        }
        history.push(`/customer/articles/${item?.articleNumber}`);
    };

    const handleExportToExcel = (article) => {
        if (article) {
            window.location.href = ARTICLE_EXPORT_URL + article.uuid;
        }
    }

    const handleSizePerPage = (size) => {
        setSizePerPage(size)
        if (size) {
            onGetProducts(0, size, search, filter, customFilter);
        }
    }

    const processChange = debounce((arg) => {
        setSearch(arg?.target?.value)
        setPage(1);
        onGetProducts(0, sizePerPage, arg?.target?.value, filter, customFilter)
        /*if(totalItems > sizePerPage) {
            setPage(1);
            onGetProducts(0, sizePerPage, arg?.target?.value, filter, customFilter)
        } else {
            let value = arg?.target?.value;
            let filterItems = products;
            if(value){
                filterItems = products?.filter(p => {
                    let name = p.displayName ? p.displayName : p.description;
                    return name.toLowerCase().includes(value.toLowerCase());
                });
            }
            setProductList(filterItems);
        }*/
    }, 1000);

    const handleClearSearch = (e) => {
        if (e.key === 'Backspace' && search.length == 0) {
            setPage(1);
            onGetProducts(0, sizePerPage, null, filter, customFilter);
            /*if(totalItems > sizePerPage) {
                setPage(1);
                onGetProducts(0, sizePerPage, null, filter, customFilter);
            } else {
                let filterItems = products?.filter(p => {
                    let name = p.displayName ? p.displayName : p.description;
                    return name.toLowerCase().includes(search.toLowerCase());});
                setProductList(filterItems);
            }*/
        }
    }

    const removeArticleFromGroup = async (article) => {
        let response = await del(`/api/article/group/delete-article?groupId=${customFilter?.articleGroup?.id}&&articleId=${article.id}`);
        loadArticles();
    }

    return (
        <React.Fragment>
            {error &&
                <Alert className={"mt-0"} color="danger" role="alert">
                    {error}
                </Alert>
            }
            <Row className="mb-3">
                <Col xs={6}>
                    <input type="text" className="form-control" style={{width: "200px"}} disabled={loading} placeholder={props.t("Search") + "..."} onChange={processChange}
                           onKeyUp={(e) => handleClearSearch(e)}/>
                </Col>
                <Col xs={6} className="justify-content-end">

                    <div className="col-md-1 float-end justify-content-end">
                        <select className="form-control" value={sizePerPage} style={{width: "50px"}} onChange={(e) => handleSizePerPage(e.target.value)}>
                            <option>20</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                        </select>
                    </div>
                    <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label float-end"
                    >
                        {props.t("Show Entries")}
                    </label>
                </Col>
            </Row>

            {!(loading && productList?.length == 0 && page == 1) && <>
                <MDBDataTable
                    responsive
                    hover
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                    ]}
                    // searchLabel={props.t('Search') + '...'}
                    paging={false}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    sortable={true}
                    searching={false}
                    sortRows={[]}
                    onPageChange={(activePage, pagesAmount) => {
                        setPage(activePage);
                    }}
                    // onSearch={(text) => console.log(text)}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    onSort={({column, direction}) => {
                        if (sortState && sortState.column == column) {
                            setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                        } else {
                            setSortState({column, direction});
                        }
                    }}
                />
                <Row key={"page_" + page}>
                    <div>
                        <Pagination className="pagination float-sm-end">
                            <PaginationItem disabled={page === 1}>
                                <PaginationLink
                                    previous
                                    to="#"
                                    onClick={() => setPage(page - 1)}
                                />
                            </PaginationItem>
                            {getPage(metaInfo.totalPages).map(function (i) {
                                return (<PaginationItem
                                    active={i === page}
                                    key={"_pagination_" + i}
                                >
                                    <PaginationLink
                                        onClick={() => setPage(i)}
                                        to="#"
                                    >
                                        {i}
                                    </PaginationLink>
                                </PaginationItem>)
                            })}
                            <PaginationItem disabled={page === totalPage}>
                                <PaginationLink
                                    next
                                    to="#"
                                    onClick={() => setPage(page + 1)}
                                />
                            </PaginationItem>
                        </Pagination>
                    </div>
                </Row></>}

        </React.Fragment>
    )
}

ArticleListTable.propTypes = {
    products: PropTypes.array,
    onGetProducts: PropTypes.func,
    metaInfo: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        products: state.ecommerce.products.data,
        metaInfo: {
            page: state.ecommerce.products.currentPage + 1,
            totalPages: state.ecommerce.products.totalPages,
            totalItems: state.ecommerce.products.totalItems,
        },
        error: state.ecommerce.getArticlesError,
        loading: state.ecommerce.getArticlesPending,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetProducts: (page, pageSize, search, filter, customFilter, sortState) => dispatch(
        getProducts(page, pageSize, false, search, filter, customFilter, null, null, sortState?.column, sortState?.direction?.toUpperCase())),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ArticleListTable)));
