import {API_ERROR, BRAND_CONFIG, BRAND_CONFIG_FAIL, BRAND_CONFIG_SUCCESS, LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS,} from "./actionTypes"

const initialState = {
    error: "",
    loading: false,
    brand: null,
    brandLoading: false
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true,
            }
            break
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
            }
            break
        case LOGOUT_USER:
            state = {...state}
            break
        case LOGOUT_USER_SUCCESS:
            state = {...state}
            break
        case API_ERROR:
            state = {...state, error: action.payload, loading: false}
            break
        case BRAND_CONFIG:
            state = {
                ...state,
                brandLoading: true
            }
            break;
        case BRAND_CONFIG_SUCCESS:
            state = {
                ...state,
                brand: action.payload,
                brandLoading: false
            }
            break;
        case BRAND_CONFIG_FAIL:
            state = {
                ...state,
                brandLoading: false
            }
            break;
        default:
            state = {...state}
            break
    }
    return state
}

export default login
