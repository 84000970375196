import React from "react";
import {Card, Container, Modal} from "reactstrap";
import {withTranslation} from "react-i18next";
import ProjectForm from "./ProjectForm";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import { useParams } from "react-router-dom";
import {CardBody} from "mdbreact";

const ProjectFormContainer = (props) => {
    let params = useParams();

    const ProjectPage = (props) => 
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Projects"), link: "/projects"}]}
                        title={params.id ? props.t("Edit Project") : props.t("Create Project")}
                    />
                    <Card>
                        <CardBody>
                            <ProjectForm
                                projectId={params.id}
                                location={props.location}
                                clearField={true}
                                customerId={props.customerId}
                            />
                        </CardBody>
                    </Card>

                </Container>
            </div>
        </React.Fragment>

    const ProjectModal = ({modal_standard, tog_standard, useModal, customerId}) => 
        <Modal
            isOpen={modal_standard}
            backdrop="static"
            toggle={() => {
                tog_standard();
            }}
            className="add-order-modal"
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {props.t("Create Project")}
                </h5>
                <button
                    type="button"
                    onClick={() => {
                        tog_standard();
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body m-2">
                <ProjectForm
                    projectId={params.id}
                    location={props.location}
                    clearField={true}
                    useModal={useModal}
                    tog_standard={tog_standard}
                    customerId={customerId}
                />
            </div>
        </Modal>

    return props.useModal ? <ProjectModal {...props} /> : <ProjectPage {...props} />;
};

export default withTranslation()(ProjectFormContainer);
