export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST"
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS"
export const GET_CUSTOMER_LIST_FAIL = "GET_CUSTOMER_LIST_FAIL"

export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL"
export const GET_CUSTOMER_REST = "GET_CUSTOMER_REST"

export const GET_CUSTOMER_USERS = "GET_CUSTOMER_USERS"
export const GET_CUSTOMER_USERS_SUCCESS = "GET_CUSTOMER_USERS_SUCCESS"
export const GET_CUSTOMER_USERS_FAIL = "GET_CUSTOMER_USERS_FAIL"
export const GET_CUSTOMER_USERS_RESET = "GET_CUSTOMER_USERS_RESET"


// customer address action types
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST"
export const GET_ADDRESS_LIST_SUCCESS = "GET_ADDRESS_LIST_SUCCESS"
export const GET_ADDRESS_LIST_FAIL = "GET_ADDRESS_LIST_FAIL"
export const GET_ADDRESS_LIST_RESET = "GET_ADDRESS_LIST_RESET"

export const GET_ADDRESS = "GET_ADDRESS"
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS"
export const GET_ADDRESS_FAIL = "GET_ADDRESS_FAIL"

export const ADD_ADDRESS = "ADD_ADDRESS"
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS"
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL"

export const EDIT_ADDRESS = "EDIT_ADDRESS"
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS"
export const EDIT_ADDRESS_FAIL = "EDIT_ADDRESS_FAIL"


export const RESET_DATA = "RESET_DATA"

export const customersActionTypes = {
    GET_CUSTOMER_LIST,
}

export const GET_CUSTOMER_FOR_AM = "GET_CUSTOMER_FOR_AM"
export const GET_CUSTOMER_FOR_AM_SUCCESS = "GET_CUSTOMER_FOR_AM_SUCCESS"
export const GET_CUSTOMER_FOR_AM_FAIL = "GET_CUSTOMER_FOR_AM_FAIL"
export const GET_CUSTOMER_FOR_AM_RESET = "GET_CUSTOMER_FOR_AM_RESET"
