import {call, put, takeEvery} from "redux-saga/effects";
import {GET_ALL_CART_ITEMS, POST_ADD_CART_ITEM, PUT_UPDATE_CART_ITEM} from "./actionTypes";
import {getCartItems, postAddCartItem, putUpdateCartItem} from "../../../helpers/fakebackend_helper";
import {getAllCartItemsFail, getAllCartItemsSuccess, postAddCartItemFail, postAddCartItemSuccess, putUpdateCartItemFail, putUpdateCartItemSuccess} from "./actions";
import { API_ERR_MSG } from "../../../util/constants";
import { isArray } from "lodash";

function* fetchCartItems() {
    try{
        const response = yield call(getCartItems)
        yield put(getAllCartItemsSuccess(response.data))
    }catch (error){
        yield put(getAllCartItemsFail(error))
    }
}

function* postAddCartItemSaga(data) {
    try{
        const response = yield call(postAddCartItem, data.action)
        if(response.status == 200){
            yield put(postAddCartItemSuccess(response.data))
        } else {
            yield put(postAddCartItemFail(response.data))
        }
    }catch (error){
        const errData = error.response.data;
        if(errData && isArray(errData) && errData.length > 0){
            yield put(postAddCartItemFail(errData))
        } else {
            yield put(postAddCartItemFail(API_ERR_MSG))
        }
    }
}

function* putUpdateCartItemSaga({cartItem}) {
    try{
        const response = yield call(putUpdateCartItem, cartItem)
        if(response.status == 200){
            yield put(putUpdateCartItemSuccess(response.data))
        } else {
            yield put(putUpdateCartItemFail(response.data))
        }
    } catch (error) {
        const errData = error.response.data;
        if(errData && isArray(errData) && errData.length > 0){
            yield put(putUpdateCartItemFail(errData))
        } else {
            yield put(putUpdateCartItemFail(API_ERR_MSG))
        }
    }
}


function* cartSaga() {
    yield takeEvery(GET_ALL_CART_ITEMS, fetchCartItems);
    yield takeEvery(POST_ADD_CART_ITEM, postAddCartItemSaga);
    yield takeEvery(PUT_UPDATE_CART_ITEM, putUpdateCartItemSaga);
}

export default cartSaga
