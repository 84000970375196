import React, {useEffect, useState} from "react";
import {Alert, Button, CardTitle, Col, Label, Row} from "reactstrap";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {get, post, put} from "../../helpers/api_helper";
import {withTranslation} from "react-i18next";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {AvField} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {TenantAdminGetUsers} from "../../store/users/tenant/actions";
import {GetCustomers, GetCustomerUsers} from "../../store/customer/actions";
import {connect} from "react-redux";
import {API_ERR_MSG} from "../../util/constants";
import Loader from "../../components/Common/Loader";
import {checkIfLoading} from "../../store/selector";
import {customersActionTypes} from "../../store/customer/actionTypes";
import DatePicker from "react-date-picker";
import {getDateObject, parseDateToString} from "../../util/helperFunctions";
import {find} from "lodash";
import {getProjectTemplate} from "../../store/project-template/actions";
import ProjectTemplateCustomField from "../ProjectTemplate/ProjectTemplateField";
import htmlToDraft from "html-to-draftjs";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import DropdownSelect from "../../components/Common/DropdownSelect";

const ProjectForm = (props) => {
    let params = useParams();

    let cid = params.customerId;

    let {
        tenantUsers,
        onGetTenantUsers,
        customerUsers,
        onGetCustomerUsers,
        customers,
        onGetCustomers,
        customersLoading,
        onGetProjectTemplates,
        projectTemplates,
        useModal, tog_standard
    } = props;

    let projectId = useModal ? null : props.projectId;

    const projectDefaultObject = {
        Description: "",
        StartDate: "",
        Status: "ONGOING",
        ContactPerson: "",
        ProjectLeader: "",
        EndDate: "",
        ProjectNumber: "",
        Comments: "",
        projectLeaders: [],
        projectParticipants: [],
    }

    const [customerId, setCustomerId] = useState(null);
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [projectParticipants, setProjectParticipants] = useState([]);
    const [projectLeader, setProjectLeader] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [projectTemplate, setProjectTemplate] = useState(null);
    const [projectFieldValueList, setProjectFieldValueList] = useState(null);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [projectFormFields, setProjectFormFields] = useState(projectDefaultObject);
    const [disabled, setDisabled] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [suggestedProjectNumber, setSuggestedProjectNumber] = useState('');
    const PROJECT_STATUS_LIST = [{value: '', label: props.t("Select status")}, {value: 'ONGOING', label: props.t("Ongoing")}, {value: 'NOTSTARTED', label: props.t("Not started")}, {value: 'COMPLETED', label: props.t("Completed")}];
    let history = useHistory();
    let user = JSON.parse(localStorage.getItem("authUser"));
    const level = localStorage.getItem("level");
    
    
    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        
        if (!user?.customerId && !props.customerId) {
            onGetCustomers()
        }
        if(!projectId){
            getAndSetSuggestedProjectNumber();
        }
        if (cid) {
            setCustomerId(cid);
        } else if (props.customerId) {
            setCustomerId(props.customerId)
        } else if (localStorage.getItem("authUser")) {
            setCustomerId(user?.customerId)
        }
    }, [])
    
    useEffect(() => {
        if (customerId) {
            onGetCustomerUsers(customerId);
        }
    }, [customerId]);

    useEffect(() => {
        if (selectedTemplate) {
            let template = find(projectTemplates, {id: selectedTemplate?.value});
            setProjectTemplate(template);
        } else {
            setProjectTemplate(null);
        }
    }, [selectedTemplate])
    
    useEffect(async () => {
        onGetTenantUsers()
        onGetProjectTemplates();
        if (projectId) {
            setLoading(true);
            let response = await get(`/api/projects/${projectId}`);
            let data = response.data;
            if (data.customerId) {
                setCustomerId(data.customerId)
            }
            setProjectFormFields({...data})
            setSelectedTemplate({value: data?.projectTemplate?.id, label: data?.projectTemplate?.name})
            setProjectFieldValueList(data?.projectFieldValueList);
            setLoading(false);
        } else {
            setProjectFormFields(projectDefaultObject);
        }
    }, [projectId]);
    
    useEffect(() => {
        if(projectFormFields?.projectParticipants){
            let usersList = getSelectUsers(projectFormFields?.projectParticipants, allUsers);
            setProjectParticipants(usersList ? usersList : []);
        }
    }, [allUsers, projectFormFields?.projectParticipants])
    
    useEffect(() => {
        if(projectFormFields?.projectLeaders){
            let projectLeader = getSelectUsers(projectFormFields?.projectLeaders, allUsers);
            setProjectLeader(projectLeader)
        }
    }, [allUsers, projectFormFields?.projectLeaders])
    
    useEffect(() => {
        let groupedOptions = [];
        if(tenantUsers) {
            groupedOptions = [...groupedOptions, { label: props.t("Tenant - Users"), options: tenantUsers.map(o => ({value: o.id, label: o.name}))}]
        }
        if(customerUsers) {
            groupedOptions = [...groupedOptions, { label: props.t("Customer - Users"), options: customerUsers.map(o => ({value: o.id, label: o.name}))}]
        }
        setUserGroupOptions(groupedOptions);
        let all = []
        customerUsers.map(u => all.push(u))
        tenantUsers.map(u => all.push(u))
        setAllUsers([...all]);
    }, [customerUsers, tenantUsers])
    
    /*useEffect(() => {
        document.querySelector('select[name=Status]').value = projectFormFields.Status
    }, [projectFormFields])*/
    
    useEffect(() => {
        setDisabled(props.disableFieldsByDefault);
    }, [props.disableFieldsByDefault])

    useEffect(() => {
        console.log(userGroupOptions, projectLeader, projectParticipants);
    }, [projectParticipants, projectLeader])

    const toggleDisableState = () => {
        setDisabled(!disabled);
    };
    
    const createProject = (fields) => {
        if(projectParticipants.filter(o => {
            return find(projectLeader, {value: o.value})
        }).length > 0){
            setError([props.t('A user cannot be both leader and participant in the project')])
            setLoading(false);
            return;
        }
        let participants = [];
        projectParticipants?.map(project => participants.push(project.value));

        let projectLeaderList = [];
        projectLeader?.map(project => projectLeaderList.push(project.value));
        post(`/api/projects${customerId ? '?customerId=' + customerId : ''}`,
            {
                ...fields,
                StartDate: projectFormFields.StartDate,
                EndDate: projectFormFields.EndDate,
                Status: projectFormFields.Status,
                projectParticipants: participants,
                projectLeaders: projectLeaderList,
                projectTemplate: {id: selectedTemplate?.value}
            })
            .then(data => {
                if (data?.status !== 200) {
                    setError([data?.data])
                    setLoading(false);
                    if(suggestedProjectNumber && typeof data?.data === 'string' && data?.data?.startsWith('Project already exists')) {
                        setProjectFormFields({...projectFormFields, ProjectNumber: suggestedProjectNumber})
                    }
                    if(useModal){
                        cogoToast.error(props.t("Error occured while creating project!"), toastOptions)
                    }
                } else {
                    if (props.callback) {
                        props.callback(data?.data?.projectNumber);
                    }
                    setDisabled(true);
                    // setProjectFormFields(projectDefaultObject);
                    wait();
                    if (props.avoidRedirect) return;
                    if(useModal){
                        cogoToast.success(props.t("Project created successfully!"), toastOptions)
                        tog_standard(data?.data);
                    } else {
                        history.push(`/projects/${data?.data?.projectNumber}`)
                    }
                }
            })
            .catch(err => {
                wait();
                setError([props.t(API_ERR_MSG)])
            })
    }

    const updateProject = (fields) => {
        if(projectParticipants.filter(o => {
            return find(projectLeader, {value: o.value})
        }).length > 0){
            setLoading(false);
            setError([props.t('A user cannot be both leader and participant in the project')])
            return;
        }
        let participants = [];
        projectParticipants?.map(project => participants.push(project.value));

        let projectLeaderList = [];
        projectLeader?.map(project => projectLeaderList.push(project.value));
        put(`/api/projects/${projectId}${customerId ? '?customerId=' + customerId : ''}`, {
            ...fields,
            StartDate: projectFormFields.StartDate,
            EndDate: projectFormFields.EndDate,
            Status: projectFormFields.Status,
            projectParticipants: participants,
            projectLeaders: projectLeaderList,
            projectTemplate: {id: selectedTemplate?.value},
            projectFieldValueList: projectFieldValueList
        })
            .then(data => {
                if (data?.status !== 200) {
                    setError([props.t(data?.data)])
                    setLoading(false);
                } else {
                    if (props.callback) {
                        props.callback(data?.data?.projectNumber);
                    }
                    setDisabled(true);
                    // setProjectFormFields(projectDefaultObject);
                    wait();
                    if (props.avoidRedirect) return;
                    history.push(`/projects/${projectId}`)
                }
            })
            .catch(err => {
                setError([props.t(API_ERR_MSG)])
                setLoading(false);
                wait();
            })
    }

    const wait = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const submitForm = (e, fields) => {
        if (props.disableFieldsByDefault && disabled) {
            toggleDisableState();
        } else {
            setLoading(true);
            projectId ? updateProject(fields) : createProject(fields)
        }
    }

    const getButtonName = () => {
        let btnName = "Save";
        if (props.disableFieldsByDefault && disabled) {
            btnName = "Edit";
        } else if (projectId) {
            btnName = "Update";
        }

        return btnName;
    }

    const getSelectUsers = (list, userList) => {
        let sUsers = [];
        for (let i = 0; i < list?.length; i++) {
            let user = userList.find(u => u.id == list[i])
            if (user) {
                sUsers.push({'value': user.id, 'label': user.name});
            }
        }
        return sUsers;
    }

    const getFieldValue = (field) => {
        let fieldValue = find(projectFieldValueList, {field: {id: field.id}})
        if(fieldValue){
            return fieldValue
        }
        return {value : ""};
    }

    const getEditorFieldValue = (field) => {
        let value = "";
        if(field){
            let fieldValue = find(projectFieldValueList, {field: {id: field.id}})
            if(fieldValue){
                value = fieldValue?.value;
            }
        }
        const contentBlock = htmlToDraft(value);
        if (contentBlock) {
            return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlock));
        }
        return EditorState.createWithText(value);
    }

    const handleFieldValueChange = (field, value, unit) => {
        let fieldValueList = projectFieldValueList == null ? [] : projectFieldValueList;
        let fieldValue = find(fieldValueList, {field: {id: field.id}})
        if (!fieldValue) {
            fieldValue = {
                field: field,
                value: value,
                unit: unit,
            }
            fieldValueList.push(fieldValue)
        } else {
            for(let i = 0; i < fieldValueList.length; i++){
                if(fieldValueList[i].field.id == field.id){
                    fieldValueList[i].value = value;
                    fieldValueList[i].unit = unit;
                }
            }
        }
        setProjectFieldValueList([...fieldValueList])
    }

    const handleCustomFreeTextFieldChange = (field, editorState) => {
        let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        let fieldValueList = projectFieldValueList == null ? [] : projectFieldValueList;
        let fieldValue = find(fieldValueList, {field: {id: field.id}})
        if (!fieldValue) {
            fieldValue = {
                field: field,
                value: value,
                unit: null,
            }
            fieldValueList.push(fieldValue)
        } else {
            for(let i = 0; i < fieldValueList.length; i++){
                if(fieldValueList[i].field.id == field.id){
                    fieldValueList[i].value = value;
                    fieldValueList[i].unit = null;
                }
            }
        }
        setProjectFieldValueList([...fieldValueList])
    }

    const getAndSetSuggestedProjectNumber = () => {
        get('/api/projects/suggestedProjectNumber')
            .then(data => {
                if (data?.status == 200) {
                    setSuggestedProjectNumber(data?.data)
                    setProjectFormFields({...projectFormFields, ProjectNumber: data?.data})
                }
            }).catch(error => {
        });
    }



    return (
        /*<Card className={`mb-0${loading ? "low-opacity" : ''}`}>*/
            <AvForm
                onValidSubmit={(e, v) => {
                    e.preventDefault();
                    submitForm(e, v)
                }}
            >
               {/* <CardBody>*/}
                    {(!disabled && !useModal) && <>
                        <CardTitle className="h4">{props.t(projectId ? "Update Project" : "Create Project")}</CardTitle>
                        <p className="card-title-desc"></p>
                    </>}


                    {error.length > 0 ? (
                        <Alert color="danger">{error.map((msg, index) => index === 0 ? msg : (<><br/>{msg}</>))}</Alert>
                    ) : null}

                    <Loader loading={loading && customersLoading}/>

                    {!user.customerId &&
                        <Row className="mb-3">
                            <div>
                                <label>{props.t("Customer")}</label>
                                <DropdownSelect
                                    defaultValue={{value: customerId, label: find(customers, {id : parseInt(customerId)})?.name}}
                                    values={customerId? {value: customerId, label: find(customers, {id : parseInt(customerId)})?.name} : null}
                                    onChange={(vals) => setCustomerId(vals?.value)}
                                    disabled={loading || disabled || props.customerId || customers.length == 0}
                                    placeholder={props.t("Select Customer")}
                                    options={customers?.map(o => ({value: o.id, label: o.name}))}
                                />
                            </div>
                        </Row>
                    }

                    {level == "TENANT" && <>
                        <Row className="mb-3">
                            <label>{props.t("Template")}</label>
                            <DropdownSelect
                                defaultValue={selectedTemplate}
                                value={selectedTemplate}
                                onChange={(vals) => setSelectedTemplate(vals)}
                                placeholder={props.t("Select Template")}
                                options={projectTemplates?.map(o => ({value: o.id, label: o.name}))}
                            />
                        </Row>
                    </>}
                    <Row className="mb-3">
                        <div>
                            <AvField
                                name="ProjectNumber"
                                className="form-control"
                                label={props.t("Project Number")}
                                type="text"
                                placeholder={props.t("Enter Project Number")}
                                value={projectFormFields.ProjectNumber}
                                disabled={loading || disabled || projectId}
                                onChange={e => setProjectFormFields({...projectFormFields, ProjectNumber: e.target.value})}
                                required
                            />
                        </div>
                    </Row>

                    <Row className="mb-3">
                        <div>
                            <AvField
                                name="Description"
                                className="form-control"
                                label={props.t("Description")}
                                type="text"
                                placeholder={props.t("Enter description")}
                                value={projectFormFields.Description}
                                disabled={loading || disabled}
                                required
                            />
                        </div>
                    </Row>

                    <Row className="mb-3">
                        <Col lg={6}>
                            <label>{props.t("Project Leaders")}</label>
                            <DropdownSelect
                                isMulti
                                values={projectLeader}
                                onChange={(vals) => {
                                    setError([]);
                                    setProjectLeader(vals);
                                }}
                                placeholder={props.t("Select Project Leaders")}
                                options={userGroupOptions.map(ug => {
                                    ug.options = ug.options.filter(u => !find(projectParticipants, {value: u.value}))
                                    return ug;
                                })}/>
                        </Col>
                        <Col lg={6}>
                            <label>{props.t("Project Participants")}</label>
                            <DropdownSelect
                                isMulti
                                values={projectParticipants}
                                onChange={(vals) => {
                                    setError([]);
                                    setProjectParticipants(vals);
                                }}
                                placeholder={props.t("Select Project Participants")}
                                options={userGroupOptions.map(ug => {
                                    ug.options = ug.options.filter(u => !find(projectLeader, {value: u.value}))
                                    return ug;
                                })}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col lg={4}>

                            <div className="d-flex flex-column">
                                <Label>{props.t("Start Date")}</Label>
                                <DatePicker
                                    onChange={(date) => {
                                        setProjectFormFields({...projectFormFields, StartDate: parseDateToString(date)})
                                    }}
                                    value={projectFormFields.StartDate && projectFormFields.StartDate != "" ? getDateObject(projectFormFields.StartDate) : null}
                                    disabled={loading || disabled}
                                    name={"StartDate"}
                                    format={"yyyy-MM-dd"}
                                    dayPlaceholder={"dd"}
                                    monthPlaceholder={"mm"}
                                    yearPlaceholder={"yyyy"}/>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="d-flex flex-column">
                                <Label>{props.t("End Date")}</Label>
                                <DatePicker
                                    onChange={(date) => {
                                        setProjectFormFields({...projectFormFields, EndDate: parseDateToString(date)})
                                    }}
                                    value={projectFormFields.EndDate && projectFormFields.EndDate != "" ? getDateObject(projectFormFields.EndDate) : null}
                                    disabled={loading || disabled}
                                    name={"EndDate"}
                                    format={"yyyy-MM-dd"}
                                    dayPlaceholder={"dd"}
                                    monthPlaceholder={"mm"}
                                    yearPlaceholder={"yyyy"}/>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <label>{props.t("Status")}</label>
                            <DropdownSelect
                                defaultValue={find(PROJECT_STATUS_LIST, {value : projectFormFields.Status})}
                                values={find(PROJECT_STATUS_LIST, {value : projectFormFields.Status})}
                                onChange={(vals) => setProjectFormFields(prevState => ({...prevState, Status: vals?.value}))}
                                disabled={loading || disabled}
                                placeholder={props.t("Select Project status")}
                                options={PROJECT_STATUS_LIST}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <div>
                            <AvField
                                className="form-control"
                                label={props.t("Comments")}
                                placeholder={props.t("Enter comments")}
                                name="Comments"
                                value={projectFormFields.Comments}
                                disabled={loading || disabled}
                            />
                        </div>
                    </Row>
                    <div key={"key-"+ projectTemplate?.fieldList?.length + projectFieldValueList?.length}>
                        {projectTemplate?.fieldList?.map(function (field, index) {
                            return (<ProjectTemplateCustomField field={field}
                                                                getFieldValue={getFieldValue}
                                                                handleFieldValueChange={handleFieldValueChange}
                                                                handleCustomFreeTextFieldChange={handleCustomFreeTextFieldChange}
                                                                getEditorFieldValue={getEditorFieldValue}/>)
                        })}
                    </div>
                    {projectId && <AvField type="hidden" name="id" defaultValue={projectFormFields.id}/>}
                    <Row className="mb-3">
                        <Col align="center">
                            {!props.disableFieldsByDefault &&
                                <>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className="btn btn-secondary w-lg waves-effect waves-light"
                                        onClick={() => {
                                            if (props.setEditMode) {
                                                props.setEditMode(false);
                                            } else {
                                                useModal ? tog_standard() : history.push("/projects");
                                            }
                                        }}
                                    >
                                        {props.t("Cancel")}
                                    </Button>
                                    {" "}</>}
                            <Button
                                type="submit"
                                color="success"
                                className="btn btn-success w-lg waves-effect waves-light"
                                style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                disabled={loading}
                            >
                                {props.t(getButtonName())}
                            </Button>
                        </Col>
                    </Row>
                {/*</CardBody> */}
            </AvForm>

        /*</Card>*/);
};

ProjectForm.propTypes = {
    customerUsers: PropTypes.array,
    tenantUsers: PropTypes.array,
    onGetCustomerUsers: PropTypes.func,
    onGetTenantUsers: PropTypes.func,
    projectTemplates: PropTypes.array,
    onGetProjectTemplates: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        projectTemplates: state.ProjectTemplate.projectTemplates,
        customerUsers: state.Customer.customerUsers,
        tenantUsers: state.TenantAdmin.users,
        customers: state.Customer.customers,
        customersLoading: checkIfLoading(state, customersActionTypes.GET_CUSTOMER_LIST),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetCustomerUsers: (customerId) => dispatch(GetCustomerUsers(customerId, "active")),
    onGetTenantUsers: () => dispatch(TenantAdminGetUsers()),
    onGetCustomers: () => dispatch(GetCustomers()),
    onGetProjectTemplates: () => dispatch(getProjectTemplate())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ProjectForm)));

