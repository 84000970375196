import {checkIfLoading} from "../../store/selector";
import {GET_ARTICLE_GROUP_LIST} from "../../store/e-commerce/actionTypes";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {getArticleGroupList, getArticleGroupListReset} from "../../store/e-commerce/actions";
import {Alert, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import Loader from "../../components/Common/Loader";
import TableToolbar from "../../components/Common/TableToolbar";
import React, {useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { orderBy } from "lodash";

const ArticleGroupList = (props) => {
    let {groups, onGetGroupList, loading, error, onGetGroupListReset} = props;

    const [brandSettings, setBrandSettings] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['noOfArticles'];
    const history = useHistory();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetGroupList();
        return () => {
            onGetGroupListReset();
        }
    }, [])

    useEffect(() => {
        if (groups) {
            setGroupList(groups);
        }
    }, [groups])

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setGroupList(orderBy(groupList, o => {
            return numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase()
        }, [direction]))
      }
    }, [sortState])

    useEffect(() => {
      if(error){
        setTimeout(() => {onGetGroupListReset()}, 5000);
      }
    }, [error])

    const getDatatableData = () => {
        let rows = [];
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    autoWidth: false,
                    width: "70%"
                },
                {
                    label: props.t("Number of Articles"),
                    field: "noOfArticles",
                    autoWidth: false,
                    width: "20%"
                },
                {
                    label: props.t("Created Date"),
                    field: "createdAt", 
                    autoWidth: false,
                    width: "20%"
                }
            ],
            rows: groupList?.map((item, key) => ({
                name: item.name,
                createdAt: <span>{new Date(item.createdAt).toLocaleString()}</span>,
                noOfArticles: item?.noOfArticles,
                clickEvent: function (){
                    handleOpen(item);
                }
            }))
        }
        return data;
    }

    const handleAdd = () => {
        history.push("/groups/create")
    }

    const handleOpen = (item) => {
        history.push(`groups/detail/${item.id}`)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        title={props.t("Article Groups")}
                        breadcrumbs={[{title: props.t("Article Groups"), link: "#"}]}
                    />
                    <Loader loading={loading} title={"Article Groups"}/>
                        <Row>
                            <Col className="col-12 article-table">
                                <Card>
                                    <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                        <TableToolbar
                                            title={props.t("Article Groups")}
                                            buttons={[{
                                                text: props.t("Add Group"),
                                                onClick: handleAdd,
                                            }]}
                                        />
                                    </CardHeader>
                                    
                                    {!loading &&
                                    <CardBody>
                                    {error &&
                                        <Alert className={"mt-4"} color="danger" role="alert">
                                            {error}
                                        </Alert>
                                    }
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            data={getDatatableData()}
                                            searching={true}
                                            sortRows={[]}
                                            onSort={({column, direction}) => {
                                                if(sortState && sortState.column == column){
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />
                                    </CardBody>}
                                </Card>
                            </Col>
                        </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ArticleGroupList.prototype = {
    groups: PropTypes.array,
    onGetGroupList: PropTypes.func
}

const mapStateToProps = state => {
    return {
        groups: state.ecommerce.groupList,
        error: state.ecommerce.groupListError,
        loading: checkIfLoading(state, GET_ARTICLE_GROUP_LIST)
    }

}


const mapDispatchToProps = dispatch => ({
    onGetGroupList: () => dispatch(getArticleGroupList()),
    onGetGroupListReset: () => dispatch(getArticleGroupListReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ArticleGroupList)))
