import {
    GET_INVOICE_DETAIL,
    GET_INVOICE_DETAIL_FAIL,
    GET_INVOICE_DETAIL_RESET,
    GET_INVOICE_DETAIL_SUCCESS,
    GET_INVOICE_METRICS,
    GET_INVOICE_METRICS_FAIL,
    GET_INVOICE_METRICS_SUCCESS,
    GET_INVOICES,
    GET_INVOICES_FAIL,
    GET_INVOICES_RESET,
    GET_INVOICES_SUCCESS,
} from "./actionTypes"

export const getInvoices = (invoiceType, customerId, startDate, endDate, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => ({
    type: GET_INVOICES,
    invoiceType,
    customerId,
    startDate,
    endDate,
    articleNumber,
    projectNumber,
    articleGroupId,
    manufacturer,
    ourReference,
    yourReference
})

export const getInvoicesSuccess = invoices => ({
    type: GET_INVOICES_SUCCESS,
    payload: invoices.data,
})

export const getInvoicesFail = error => ({
    type: GET_INVOICES_FAIL,
    payload: error,
})

export const getInvoicesReset = () => ({
    type: GET_INVOICES_RESET,
    payload: null
})

export const getInvoiceDetail = (invoiceId) => ({
    type: GET_INVOICE_DETAIL,
    invoiceId,
})

export const getInvoiceDetailSuccess = invoices => ({
    type: GET_INVOICE_DETAIL_SUCCESS,
    payload: invoices,
})

export const getInvoiceDetailFail = error => ({
    type: GET_INVOICE_DETAIL_FAIL,
    payload: error,
})

export const getInvoiceDetailReset = () => ({
    type: GET_INVOICE_DETAIL_RESET,
    payload: null
})

export const getInvoiceMetrics = (startDate, endDate) => ({
    type: GET_INVOICE_METRICS,
    startDate,
    endDate
})

export const getInvoiceMetricsSuccess = (response) => ({
    type: GET_INVOICE_METRICS_SUCCESS,
    payload: response.data
})

export const getInvoiceMetricsFail = (error) => ({
    type: GET_INVOICE_METRICS_FAIL,
    payload: error
})
