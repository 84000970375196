/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"
export const GET_PRODUCTS_RESET = "GET_PRODUCTS_RESET"

export const GET_FAVORITE_PRODUCTS = "GET_FAVORITE_PRODUCTS"
export const GET_FAVORITE_PRODUCTS_SUCCESS = "GET_FAVORITE_PRODUCTS_SUCCESS"
export const GET_FAVORITE_PRODUCTS_FAIL = "GET_FAVORITE_PRODUCTS_FAIL"
export const GET_FAVORITE_PRODUCTS_RESET = "GET_FAVORITE_PRODUCTS_RESET"

/* CONTENT */
export const GET_CONTENT_TYPE = "GET_CONTENT_TYPE"
export const GET_CONTENT_TYPE_SUCCESS = "GET_CONTENT_TYPE_SUCCESS"
export const GET_CONTENT_TYPE_FAIL = "GET_CONTENT_TYPE_FAIL"
export const GET_CONTENT_TYPE_RESET = "GET_CONTENT_TYPE_RESET"

export const GET_CONTENT_TYPE_FOR_ARTICLE = "GET_CONTENT_TYPE_FOR_ARTICLE"
export const GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS = "GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS"
export const GET_CONTENT_TYPE_FOR_ARTICLE_FAIL = "GET_CONTENT_TYPE_FOR_ARTICLE_FAIL"
export const GET_CONTENT_TYPE_FOR_ARTICLE_RESET = "GET_CONTENT_TYPE_FOR_ARTICLE_RESET"


export const GET_PRODUCT_BY_CATEGORY = "GET_PRODUCT_BY_CATEGORY"
export const GET_PRODUCT_BY_CATEGORY_SUCCESS = "GET_PRODUCT_BY_CATEGORY_SUCCESS"
export const GET_PRODUCT_BY_CATEGORY_FAIL = "GET_PRODUCT_BY_CATEGORY_FAIL"
export const GET_PRODUCT_BY_CATEGORY_RESET = "GET_PRODUCT_BY_CATEGORY_RESET"

export const GET_PRODUCT_BY_GROUP = "GET_PRODUCT_BY_GROUP"
export const GET_PRODUCT_BY_GROUP_SUCCESS = "GET_PRODUCT_BY_GROUP_SUCCESS"
export const GET_PRODUCT_BY_GROUP_FAIL = "GET_PRODUCT_BY_GROUP_FAIL"

/* PRODUCT DETAILS */
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"
export const GET_PRODUCT_DETAIL_RESET = "GET_PRODUCT_DETAIL_RESET"

/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"
export const GET_ORDERS_RESET = "GET_ORDERS_RESET"


export const POST_ORDER = "POST_ORDER"
export const POST_ORDER_SUCCESS = "POST_ORDER_SUCCESS"
export const POST_ORDER_FAIL = "POST_ORDER_FAIL"
export const POST_ORDER_RESET = "POST_ORDER_RESET"

export const GET_ORDERS_DETAILS = "GET_ORDERS_DETAILS"
export const GET_ORDERS_DETAILS_SUCCESS = "GET_ORDERS_DETAILS_SUCCESS"
export const GET_ORDERS_DETAILS_FAIL = "GET_ORDERS_DETAILS_FAIL"
export const GET_ORDERS_DETAILS_RESET = "GET_ORDERS_DETAILS_RESET"

export const COPY_ORDER_TO_CART = "COPY_ORDER_TO_CART"
export const COPY_ORDER_TO_CART_SUCCESS = "COPY_ORDER_TO_CART_SUCCESS"
export const COPY_ORDER_TO_CART_FAIL = "COPY_ORDER_TO_CART_FAIL"
export const COPY_ORDER_TO_CART_RESET = "COPY_ORDER_TO_CART_RESET"

/* CART DATA */
export const GET_CART_DATA = "GET_CART_DATA"
export const GET_CART_DATA_SUCCESS = "GET_CART_DATA_SUCCESS"
export const GET_CART_DATA_FAIL = "GET_CART_DATA_FAIL"

/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL"

/* SHOPS */
export const GET_SHOPS = "GET_SHOPS"
export const GET_SHOPS_SUCCESS = "GET_SHOPS_SUCCESS"
export const GET_SHOPS_FAIL = "GET_SHOPS_FAIL"

/* CATEGORY */
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST"
export const GET_CATEGORY_LIST_FAIL = "GET_CATEGORY_LIST_FAIL"
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS"
export const GET_CATEGORY_LIST_RESET = "GET_CATEGORY_LIST_RESET"

export const GET_SUB_CATEGORY_LIST = "GET_SUB_CATEGORY_LIST"
export const GET_SUB_CATEGORY_LIST_FAIL = "GET_SUB_CATEGORY_LIST_FAIL"
export const GET_SUB_CATEGORY_LIST_SUCCESS = "GET_SUB_CATEGORY_LIST_SUCCESS"
export const GET_SUB_CATEGORY_LIST_RESET = "GET_SUB_CATEGORY_LIST_RESET"

export const GET_LAYOUT_LIST = "GET_LAYOUT_LIST"
export const GET_LAYOUT_LIST_FAIL = "GET_LAYOUT_LIST_FAIL"
export const GET_LAYOUT_LIST_SUCCESS = "GET_LAYOUT_LIST_SUCCESS"
export const GET_LAYOUT_LIST_RESET = "GET_LAYOUT_LIST_RESET"

export const GET_DEFAULT_LAYOUT = "GET_DEFAULT_LAYOUT"
export const GET_DEFAULT_LAYOUT_FAIL = "GET_DEFAULT_LAYOUT_FAIL"
export const GET_DEFAULT_LAYOUT_SUCCESS = "GET_DEFAULT_LAYOUT_SUCCESS"

export const GET_LAYOUT = "GET_LAYOUT"
export const GET_LAYOUT_FAIL = "GET_LAYOUT_FAIL"
export const GET_LAYOUT_SUCCESS = "GET_LAYOUT_SUCCESS"
export const GET_LAYOUT_RESET = "GET_LAYOUT_RESET"

// Price List
export const GET_PRICE_LIST = "GET_PRICE_LIST"
export const GET_PRICE_LIST_FAIL = "GET_PRICE_LIST_FAIL"
export const GET_PRICE_LIST_SUCCESS = "GET_PRICE_LIST_SUCCESS"

export const GET_PRICE_LIST_FOR_TENANT = "GET_PRICE_LIST_TENANT"
export const GET_PRICE_LIST_FOR_TENANT_SUCCESS = "GET_PRICE_LIST_TENANT_SUCCESS"
export const GET_PRICE_LIST_FOR_TENANT_ERROR = "GET_PRICE_LIST_TENANT_ERROR"

export const GET_GLOBAL_SEARCH = "GET_GLOBAL_SEARCH"
export const GET_GLOBAL_SEARCH_FAIL = "GET_GLOBAL_SEARCH_FAIL"
export const GET_GLOBAL_SEARCH_SUCCESS = "GET_GLOBAL_SEARCH_SUCCESS"
export const GET_GLOBAL_SEARCH_RESET = "GET_GLOBAL_SEARCH_RESET"

export const ecommerceActionTypes = {
    GET_ORDERS,
    GET_CATEGORY_LIST,
    GET_SUB_CATEGORY_LIST,
    GET_LAYOUT_LIST,
    GET_LAYOUT,
}

export const GET_ORDERS_FOR_AM = "GET_ORDERS_FOR_AM"
export const GET_ORDERS_FOR_AM_SUCCESS = "GET_ORDERS_FOR_AM_SUCCESS"
export const GET_ORDERS_FOR_AM_FAIL = "GET_ORDERS_FOR_AM_FAIL"
export const GET_ORDERS_FOR_AM_RESET = "GET_ORDERS_FOR_AM_RESET"

export const GET_ORDERS_BY_REFERENCE = "GET_ORDERS_BY_REFERENCE"
export const GET_ORDERS_BY_REFERENCE_SUCCESS = "GET_ORDERS_BY_REFERENCE_SUCCESS"
export const GET_ORDERS_BY_REFERENCE_FAIL = "GET_ORDERS_BY_REFERENCE_FAIL"
export const GET_ORDERS_BY_REFERENCE_RESET = "GET_ORDERS_BY_REFERENCE_RESET"


export const GET_ARTICLE_GROUP_LIST = "GET_ARTICLE_GROUP_LIST"
export const GET_ARTICLE_GROUP_LIST_SUCCESS = "GET_ARTICLE_GROUP_LIST_SUCCESS"
export const GET_ARTICLE_GROUP_LIST_FAIL = "GET_ARTICLE_GROUP_LIST_FAIL"
export const GET_ARTICLE_GROUP_LIST_RESET = "GET_ARTICLE_GROUP_LIST_RESET"

export const GET_ARTICLE_GROUP = "GET_ARTICLE_GROUP"
export const GET_ARTICLE_GROUP_SUCCESS = "GET_ARTICLE_GROUP_SUCCESS"
export const GET_ARTICLE_GROUP_FAIL = "GET_ARTICLE_GROUP_FAIL"
export const GET_ARTICLE_GROUP_RESET = "GET_ARTICLE_GROUP_RESET"

export const GET_GROUP_BY_ARTICLE = "GET_GROUP_BY_ARTICLE"
export const GET_GROUP_BY_ARTICLE_SUCCESS = "GET_GROUP_BY_ARTICLE_SUCCESS"
export const GET_GROUP_BY_ARTICLE_FAIL = "GET_GROUP_BY_ARTICLE_FAIL"

/* FIELDS */
export const GET_FIELDS = "GET_FIELDS"
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS"
export const GET_FIELDS_FAIL = "GET_FIELDS_FAIL"
export const GET_FIELDS_RESET = "GET_FIELDS_RESET"

export const GET_TEMPLATE_LIST = "GET_TEMPLATE_LIST"
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS"
export const GET_TEMPLATE_LIST_FAIL = "GET_TEMPLATE_LIST_FAIL"
export const GET_TEMPLATE_LIST_RESET = "GET_TEMPLATE_LIST_RESET"

export const GET_TEMPLATE_DETAILS = "GET_TEMPLATE_DETAILS"
export const GET_TEMPLATE_DETAILS_SUCCESS = "GET_TEMPLATE_DETAILS_SUCCESS"
export const GET_TEMPLATE_DETAILS_FAIL = "GET_TEMPLATE_DETAILS_FAIL"
export const GET_TEMPLATE_DETAILS_RESET = "GET_TEMPLATE_DETAILS_RESET"

/* Favourites */
export const GET_FAVOURITES = "GET_FAVOURITES"
export const GET_FAVOURITES_SUCCESS = "GET_FAVOURITES_SUCCESS"
export const GET_FAVOURITES_FAIL = "GET_FAVOURITES_FAIL"
export const GET_FAVOURITES_RESET = "GET_FAVOURITES_RESET"


export const GET_ORDER_METRICS = "GET_ORDER_METRICS"
export const GET_ORDER_METRICS_SUCCESS = "GET_ORDER_METRICS_SUCCESS"
export const GET_ORDER_METRICS_ERROR = "GET_ORDER_METRICS_ERROR"

export const GET_DASHBOARD_LINE_CHART = "GET_DASHBOARD_LINE_CHART"
export const GET_DASHBOARD_LINE_CHART_SUCCESS = "GET_DASHBOARD_LINE_CHART_SUCCESS"
export const GET_DASHBOARD_LINE_CHART_FAIL = "GET_DASHBOARD_LINE_CHART_FAIL"


export const GET_DASHBOARD_METRICS_LIST = "GET_DASHBOARD_METRICS_LIST"
export const GET_DASHBOARD_METRICS_LIST_SUCCESS = "GET_DASHBOARD_METRICS_LIST_SUCCESS"
export const GET_DASHBOARD_METRICS_LIST_FAIL = "GET_DASHBOARD_METRICS_LIST_FAIL"

