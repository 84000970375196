import {
    GET_TENANT_CUSTOMER_USERS, GET_TENANT_CUSTOMER_USERS_FAIL, GET_TENANT_CUSTOMER_USERS_RESET, GET_TENANT_CUSTOMER_USERS_SUCCESS, GET_TENANT_USER_DETAILS, GET_TENANT_USER_DETAILS_FAIL, GET_TENANT_USER_DETAILS_RESET, GET_TENANT_USER_DETAILS_SUCCESS,
    TENANT_ACTIVATE_USER_FAIL,
    TENANT_ACTIVATE_USER_SUCCESS,
    TENANT_ADMIN_DELETE_USER,
    TENANT_ADMIN_DELETE_USER_FAIL,
    TENANT_ADMIN_DELETE_USER_SUCCESS,
    TENANT_ADMIN_GET_USERS,
    TENANT_ADMIN_GET_USERS_FAIL,
    TENANT_ADMIN_GET_USERS_RESET,
    TENANT_ADMIN_GET_USERS_SUCCESS,
    TENANT_ADMIN_REGENERATE_TOKEN,
    TENANT_ADMIN_REGENERATE_TOKEN_FAIL,
    TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS
} from "./actionTypes";


export const TenantAdminGetUsers = (filter) => ({
    type: TENANT_ADMIN_GET_USERS,
    filter
});

export const TenantAdminGetUsersSuccess = (users) => {
    return {
        type: TENANT_ADMIN_GET_USERS_SUCCESS,
        payload: users.data,
    };
};

export const TenantAdminGetUsersFail = (error) => ({
    type: TENANT_ADMIN_GET_USERS_FAIL,
    payload: error,
});

export const TenantAdminGetUsersReset = () => ({
    type: TENANT_ADMIN_GET_USERS_RESET
});

export const TenantAdminRegenerateToken = (userId) => ({
    type: TENANT_ADMIN_REGENERATE_TOKEN,
    userId
})

export const TenantAdminRegenerateTokenSuccess = (response) => {
    return {
        type: TENANT_ADMIN_REGENERATE_TOKEN_SUCCESS,
        payload: {
            status: response.status === 200
        }
    }
}

export const TenantAdminRegenerateTokenFail = (error) => ({
    type: TENANT_ADMIN_REGENERATE_TOKEN_FAIL,
    payload: error
})

export const TenantAdminDeleteUser = (userId) => ({
    type: TENANT_ADMIN_DELETE_USER,
    userId
})

export const TenantAdminDeleteUserSuccess = (response) => {
    return {
        type: TENANT_ADMIN_DELETE_USER_SUCCESS,
        payload: {
            status: response.status === 200
        }
    }
}

export const TenantAdminDeleteUserFail = (error) => ({
    type: TENANT_ADMIN_DELETE_USER_FAIL,
    payload: error
})

export const tenantActiveUser = (response) => {
    return {
        type: TENANT_ACTIVATE_USER_SUCCESS,
        payload: {
            status: response.status === 200
        }
    }
}

export const tenantActiveUserFail = (error) => {
    return {
        type: TENANT_ACTIVATE_USER_FAIL,
        payload: error
    }
}

export const TenantCustomerGetUsers = (filter, page, pageSize, search, sortColumn, sortDirection) => ({
    type: GET_TENANT_CUSTOMER_USERS,
    filter,
    page,
    pageSize,
    search,
    sortColumn,
    sortDirection
});

export const TenantCustomerGetUsersSuccess = (users) => {
    return {
        type: GET_TENANT_CUSTOMER_USERS_SUCCESS,
        payload: users.data,
    };
};

export const TenantCustomerGetUsersFail = (error) => ({
    type: GET_TENANT_CUSTOMER_USERS_FAIL,
    payload: error,
});

export const TenantCustomerGetUsersReset = () => ({
    type: GET_TENANT_CUSTOMER_USERS_RESET,
});

export const getTenantUserDetails = (id) => ({
    type: GET_TENANT_USER_DETAILS,
    id: id
})

export const getTenantUserDetailsSuccess = (users) => {
    return {
        type: GET_TENANT_USER_DETAILS_SUCCESS,
        payload: users.data
    };
};

export const getTenantUserDetailsFail = (error) => ({
    type: GET_TENANT_USER_DETAILS_FAIL,
    payload: error
})

export const getTenantUserDetailsReset = () => ({
    type: GET_TENANT_USER_DETAILS_RESET
})
