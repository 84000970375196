import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Col, Dropdown, DropdownMenu, DropdownToggle, Form, Input, Row,} from "reactstrap";
import {connect} from "react-redux";
import MenuSearchableDropdown from "./MenuSearchableDropdown";
import {Tooltip} from "@material-ui/core";

const TableToolbar = ({ buttons, search, title, handleChange, titleDropdown, description, filter, filter2,dropdownButton, filters, customComponent, canEdit, canDuplicate, canDelete, rightCustomComponent, labels, ...props }) => {
  let [dropDown, setDropDown] = useState(false);
  const [brandSettings, setBrandSettings] = useState(null)

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(user.brandConfig);
  }, [])
  return (
    <Row className="toolbar-row">
      <Col
        md="12"
        className="d-flex flex-row justify-content-between align-items-center table-toolbar flex-wrap"
      >
        <div className=" d-flex align-items-center">
          {title &&  !titleDropdown && <h4 className="m-0 text-white me-4">{title}</h4>}
          {titleDropdown && <>
            <MenuSearchableDropdown
                selectedValue={titleDropdown?.value}
                options={titleDropdown?.options}
                isHeader={titleDropdown?.isHeader}
                placeholder={titleDropdown?.placeholder}
                handleOnOptionClick={titleDropdown?.handleSelect}
                keyToDisplay={[titleDropdown?.keyToDisplay]}
                disabled={titleDropdown?.disabled}
                prefix={titleDropdown?.prefix}
                favorite={[titleDropdown?.favorite]}
                brandColor={{filter: titleDropdown?.value ? 'brightness(100%)' : 'brightness(70%)', backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
            />
          </>}
          &nbsp;&nbsp;&nbsp;
          {labels && labels}
        </div>
        <div className="d-flex  flex-row  align-items-center ">
          {filters && 
          filters.map(f => 
          <div
              className="btn-group ms-2 flex-wrap"
              role="group"
          >
             {f?.options.map(function(item){
              if(item.isSearchableDropdown){
                const {value, options, placeholder, handleSelect, keyToDisplay, favorite, disabled} = item;
                return <MenuSearchableDropdown
                          selectedValue={value} 
                          options={options} 
                          placeholder={placeholder}
                          handleOnOptionClick={handleSelect}
                          keyToDisplay={[keyToDisplay]}
                          favorite={[favorite]}
                          disabled={disabled}
                          brandColor={{ filter: value ? 'brightness(100%)' : 'brightness(70%)', backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        />
              } else {
                return  <Button color="light"
                          className="btn btn-primary"
                          onClick={() => f.onSelect(item.value)}
                          style={f.selected === item.value ? 
                            {backgroundColor: brandSettings?.primaryBtnColor, color: "white", borderColor: brandSettings?.primaryBtnColor} : 
                            {filter: 'brightness(70%)', color: "white",  borderColor: brandSettings?.primaryBtnColor, backgroundColor: brandSettings?.primaryBtnColor}}
                          disabled={item.disabled}
                        >
                          {props.t(item.name)}
                        </Button>
              }
             })}
            </div>)
          }

          {filter && 
          <div
              className="btn-group me-2"
              role="group"
          >
             {filter?.options.map(function(item){
              if(item.isSearchableDropdown){
                const {value, options, placeholder, handleSelect, keyToDisplay, disabled} = item;
                return <MenuSearchableDropdown
                          selectedValue={value} 
                          options={options} 
                          placeholder={placeholder}
                          handleOnOptionClick={handleSelect}
                          keyToDisplay={[keyToDisplay]}
                          disabled={disabled}
                          brandColor={{ filter: value ? 'brightness(100%)' : 'brightness(70%)', backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        />
              } else {
                return  <Button color="light"
                          className="btn btn-primary"
                          onClick={() => filter.onSelect(item.value)}
                          style={filter.selected === item.value ? 
                            {backgroundColor: brandSettings?.primaryBtnColor, color: "white", borderColor: brandSettings?.primaryBtnColor} : 
                            {filter: 'brightness(70%)', color: "white",  borderColor: brandSettings?.primaryBtnColor, backgroundColor: brandSettings?.primaryBtnColor}}
                          disabled={item.disabled}
                        >
                          {props.t(item.name)}
                        </Button>
              }
             })}
            </div>
          }
          
          <div>
            {buttons &&
              buttons.map((btn) => (
                <Button
                  active
                  color = {btn.color ? btn?.color : "primary"}
                  onClick={() => {
                    btn.onClick();
                  }}
                  className="btn btn-primary waves-effect waves-light mx-1 table-toolbar-btn"
                  style={{marginLeft: "1px !important", marginRight: "1px !important", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor, filter: btn.deactive ? 'brightness(70%)' : 'brightness(100%)'}}
                  disabled={btn.disabled}>
                  {btn.text}
                </Button>
              ))}
              {customComponent && customComponent()}
          </div>

          {dropdownButton && dropdownButton()}

          {/*{filter && <div className="btn-group">
            <Dropdown
                isOpen={filterDropdown}
                toggle={() => setFilterDropdown(!filterDropdown )}
            >
              <DropdownToggle tag="button" className="btn btn-primary"
                              style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}>
                {filter?.options.map(function(item){
                    if(item.value === filter.selected){
                      return item.name;
                    }
                })} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu>
                {filter?.options.map(function(item){
                  return <DropdownItem onClick={() => filter.onSelect(item.value)}>{item.name}</DropdownItem>
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
          }*/}

          {search && (
            <Dropdown
              isOpen={dropDown}
              toggle={() => {
                setDropDown(!dropDown);
              }}
            >
              <DropdownToggle
                style={{ backgroundColor: brandSettings?.secondaryColor }}
              >
                <i className="mdi mdi-magnify search-icon"></i>
              </DropdownToggle>
              <DropdownMenu className="bg-transparent ToolBarDropDown">
                <div className="form-inline float-md-end searchWidth">
                  <div className="search-box ml-0">
                    <div className="position-relative ">
                      <Form
                        className="app-search ml-0   d-lg-block "
                        onSubmit={(e) => {
                          e.preventDefault();
                          search.handleSearch();
                        }}
                      >
                        <Input
                          type="text"
                          className="form-control bg-light border-light rounded toolbar-search"
                          placeholder={search.placeholder}
                          onChange={(e) => {
                            search.setSearch(e.target.value);
                            search.handleSearch();
                          }}
                        />
                        <i className="mdi mdi-magnify search-icon"></i>
                      </Form>
                    </div>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          )}

          {canDuplicate && (
              <Tooltip title={props.t("Duplicate")}>

                <button onClick={canDuplicate} className="btn btn-primary"
                        style={{marginLeft: "1px", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                  <i className={"fa fa-copy"}></i>
                </button>
              </Tooltip>
          )}
          {canEdit && (
              <Tooltip title={props.t("Edit")}>
                <button onClick={canEdit} className="btn btn-primary"
                        style={{marginLeft: "1px", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                  <i className={"fa fa-pen"}></i>
                </button>
              </Tooltip>
          )}
          {canDelete && (
              <Tooltip title={props.t("Delete")}>
                <button onClick={canDelete} className="btn btn-primary"
                        style={{marginLeft: "1px", backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                  <i className={"fa fa-trash"}></i>
                </button>
              </Tooltip>
          )}
          {rightCustomComponent && rightCustomComponent()}
        </div>
        {/*{(canEdit || canDuplicate || canDelete || rightCustomComponent) && <div className={"d-flex  flex-row  align-items-right"}>

        </div>}*/}
      </Col>
      {description && <Col md={12} className="d-flex flex-row text-white mt-1" style={{fontSize: "12px"}}>
      <div>{description}</div>
      </Col>}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    //brandSettings: state.BrandSettings,
  };
};

export default connect(mapStateToProps, null)(withTranslation()(TableToolbar));
