import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, CardTitle, Col, Row} from "reactstrap"
import ReactApexChart from "react-apexcharts";
import CountUp from "react-countup";
import {useHistory, withRouter} from "react-router-dom"
import PropTypes from "prop-types";
import {getDashboardLineChart, getDashboardMetricsList} from "../../store/e-commerce/actions";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import ReportFilter from "../../components/ReportFilter/ReportFilter";
import Loader from "../../components/Common/Loader";
import {formatCurrency} from "../../helpers/currency_helper";
import InvoiceStatus from "../Invoice/InvoiceStatus";
import {Line} from "react-chartjs-2";
import moment from "moment";
import {getTenantAdmins} from "../../store/tenant/actions";
import {MDBDataTable} from "mdbreact";


const series1 = [{
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}]

const options1 = {
    fill: {
        colors: ['#5b73e8']
    },
    chart: {
        width: 70,
        sparkline: {
            enabled: !0
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '50%'
        }
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
        crosshairs: {
            width: 1
        },
    },
    tooltip: {
        fixed: {
            enabled: !1
        },
        x: {
            show: !1
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: !1
        }
    }
};

const series2 = [70]

const options2 = {
    fill: {
        colors: ['#34c38f']
    },
    chart: {
        sparkline: {
            enabled: !0
        }
    },
    dataLabels: {
        enabled: !1
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '60%'
            },
            track: {
                margin: 0
            },
            dataLabels: {
                show: !1
            }
        }
    }
};

const series3 = [55]

const options3 = {
    fill: {
        colors: ['#5b73e8']
    },
    chart: {
        sparkline: {
            enabled: !0
        }
    },
    dataLabels: {
        enabled: !1
    },
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: '60%'
            },
            track: {
                margin: 0
            },
            dataLabels: {
                show: !1
            }
        }
    }
};

const series4 = [{
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
}]

const options4 = {

    fill: {
        colors: ['#f1b44c']
    },
    chart: {
        width: 70,
        sparkline: {
            enabled: !0
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '50%'
        }
    },
    xaxis: {
        crosshairs: {
            width: 1
        },
    },
    tooltip: {
        fixed: {
            enabled: !1
        },
        x: {
            show: !1
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return ''
                }
            }
        },
        marker: {
            show: !1
        }
    }
};


const Dashboard = (props) => {
    let {
        onGetLineChartMetrics,
        lineChartMetrics,
        lineChartMetricsLoading,
        lineChartMetricsList,
        lineChartMetricsListLoading,
        onGetMetricsList,
        tenantUsers,
        onGetTenantUsers,
        customerNumber,
        projectNumber,
        articleNumber,
        ourReference,
        articleGroupId,
        manufacturer,
        yourReference
    } = props

    const user = JSON.parse(localStorage.getItem("authUser"));
    const history = useHistory();
    const [dataTable, setDataTable] = useState([]);
    const [option, setOption] = useState(["order", "revenue", "offer", "invoice"]);
    const [brandSettings, setBrandSettings] = useState(null);
    const [search, setSearch] = useState("");
    const [chartKey, setChartKey] = useState();
    const [lineChartDate, setLineChartDate] = useState();
    const [orderMetrics, setOrderMetrics] = useState({});
    const [offerMetrics, setOfferMetrics] = useState({});
    const [invoiceMetrics, setInvoiceMetrics] = useState({});
    const [orders, setOrders] = useState([]);
    const [offers, setOffers] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [optionLoading, setOptionLoading] = useState(false);
    const [rFilter, setRFilter] = useState(null);
    const [sortState, setSortState] = useState(null);
    const [tableConfig, setTableConfig] = useState({
        type: "asc",
        DocumentNumber : "desc",
        CustomerName: "asc",
        date: "asc",
        Total : "asc"
    })

    let TENANT_CURRENCY = JSON.parse(localStorage.getItem("authUser"))?.config?.currency;

    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 30)

    const [date, setDate] = useState({
        startDate: startDate,
        endDate: new Date()
    })

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setBrandSettings(obj?.brandConfig);
            onGetTenantUsers(obj?.tenantId)
        }
    }, []);

    useEffect(() => {
        setChartKey(new Date());
        if(!isEmpty(lineChartMetrics)){
            setOptionLoading(false)
            setLineChartDate(lineChartMetrics)
            setOrderMetrics(lineChartMetrics?.orderMetricsDto);
            setOfferMetrics(lineChartMetrics?.offerMetricsDto);
            setInvoiceMetrics(lineChartMetrics?.invoiceMetricsDto)
        }
    }, [lineChartMetrics])


    useEffect(() => {
        setOrders(lineChartMetricsList?.orderList);
        setOffers(lineChartMetricsList?.offersList);
        setInvoices(lineChartMetricsList?.invoiceList)
    }, [lineChartMetricsList])

    const handleDateChange = (date, filter) => {
        setDate(date);
        if (articleNumber) {
            filter.articleNumber = articleNumber;
        }
        if (customerNumber) {
            filter.customerNumber = customerNumber;
        }
        if (articleGroupId) {
            filter.articleGroupId = articleGroupId;
        }
        if (manufacturer) {
            filter.manufacturer = manufacturer;
        }
        if (projectNumber) {
            filter.projectNumber = projectNumber;
        }
        if (ourReference) {
            filter.ourReference = ourReference;
        }
        if(yourReference){
            filter.yourReference = yourReference;
        }
        if (!lineChartMetricsLoading) {
            setRFilter(filter);
            loadReportData(moment(date?.startDate).format("YYYY-MM-DD"), moment(date?.endDate).format("YYYY-MM-DD"), date?.type, filter)
        }
    }

    const showFilters = () => {
        return !articleNumber && !customerNumber && !articleGroupId && !manufacturer && !projectNumber && !ourReference && !yourReference;
    }

    const loadReportData = (startDate, endDate, type, filter) =>{
        onGetLineChartMetrics(startDate, endDate, type, filter);
        onGetMetricsList(startDate, endDate, type, filter);
    }

    useEffect(() => {
        var data = [];
        if (option.includes("order") || option.includes("revenue")) {
            orders?.map(function (order) {
                data.push({
                    DocumentNumber: order?.DocumentNumber,
                    CustomerName: order?.CustomerName,
                    Currency: order?.Currency,
                    Total: order?.Total,
                    TotalWithoutTax: order?.TotalWithoutTax,
                    date: order?.OrderDate,
                    status: order?.status,
                    OurReference: order?.OurReference,
                    type: "Order"
                })
            })
        }
        if (option.includes("offer")) {
            offers?.map(function (offer) {
                data.push({
                    DocumentNumber: offer?.DocumentNumber,
                    CustomerName: offer?.CustomerName,
                    Currency: offer?.Currency,
                    Total: offer?.Total,
                    date: offer?.OfferDate,
                    status: offer?.status,
                    OurReference: offer?.OurReference,
                    type: "Offer"
                })
            })
        }
        if (option.includes("invoice")) {
            invoices?.map(function (invoice) {
                data.push({
                    DocumentNumber: invoice?.DocumentNumber,
                    CustomerName: invoice?.CustomerName,
                    Currency: invoice?.Currency,
                    Total: invoice?.Total,
                    TotalWithoutTax: invoice?.TotalWithoutTax,
                    date: invoice?.InvoiceDate,
                    status: invoice?.status,
                    OurReference: invoice?.OurReference,
                    type: "Invoice"
                })
            })
        }
        data.sort((a, b) => {
            if (a.date === b.date) return 0;
            return a.date < b.date ? 1 : -1;
        });

        setDataTable(data);
    }, [offers, invoices, orders, option]);

    const isLoading = () => {
        /*if(optionLoading){
            return false
        }*/
        return lineChartMetricsLoading;
    }

    const handleColumnSort = (column) => {
        let data = dataTable;

        let order = tableConfig[column] == "asc" ? "desc" : "asc";
        if(order == "asc"){
            data.sort((a, b) => {
                if (a[column] === b[column]) return 0;
                return a[column] > b[column] ? 1 : -1;
            });
        } else {
            data.sort((a, b) => {
                if (a[column] === b[column]) return 0;
                return a[column] < b[column] ? 1 : -1;
            });
        }
        tableConfig[column] = order;
        setTableConfig(tableConfig);
        setDataTable([...data]);
    }

    const handleOrderOpen = (item) => {
        if(item?.type == "Invoice"){
            history.push(`/invoice/${item.DocumentNumber}`)
        } else if(item?.type == "Order"){
            history.push(`/orders/${item.DocumentNumber}`)
        } else if(item?.type == "Offer"){
            history.push(`/offers/${item.DocumentNumber}`)
        }
    }

    const getXAxis = (list) => {
        if (date?.type == 'Week') {
            const startOfTheWeek = JSON.parse(localStorage.getItem("authUser"))?.config?.startOfTheWeek;
            if ("SUN" === startOfTheWeek) {
                return [props.t('Sunday'), props.t('Monday'), props.t('Tuesday'), props.t('Wednesday'), props.t('Thursday'), props.t('Friday'), props.t('Saturday')];
            }
            return [props.t('Monday'), props.t('Tuesday'), props.t('Wednesday'), props.t('Thursday'), props.t('Friday'), props.t('Saturday'), props.t('Sunday')];
        }
        if (date?.type == 'Month') {
            return list;
        }
        if (date?.type == 'Quarter') {
            let weeks = [];
            for (let i = 0; i < list?.length; i++) {
                weeks.push(props.t("Week") + " " + list[i]);
            }
            return weeks;
        }
        if (date?.type == 'Year') {
            return [props.t("Jan"), props.t("Feb"), props.t("Mar"), props.t("Apr"), props.t("May"), props.t("Jun"), props.t("Jul"), props.t("Aug"), props.t("Sep"), props.t("Oct"), props.t("Nov"), props.t("Dec")]
        }
    }

    const getColors = () => {
        let colors = [];
        if (option.includes("order")) {
            colors.push("rgba(91,115,232,0.25)")
        }
        if (option.includes("revenue")) {
            colors.push("rgba(220,22,22,0.25)")
        }
        if (option.includes("offer")) {
            colors.push("rgba(241,180,76,0.25)")
        }
        if (option.includes("invoice")) {
            colors.push("rgba(6,211,99,0.25)");
        }
        return colors;
    }

    const getYAxisChartJS = () => {
        return {
            responsive: true,
            scales: {
                yAxes: [{
                    id: 'y',
                    display: true,
                    position: 'left',
                    type: 'linear' ,
                    ticks: {
                        /*max: getLineMaxMetrics(),*/
                        min: 0,
                        stepSize: getLineTick()
                    }
                },{
                    id: 'y1',
                    display: true,
                    position: 'right',
                    type: 'linear' ,
                    ticks: {
                       /* max: getLineMaxRevenue(),*/
                        min: 0,
                        stepSize: getLineTickRevenue()
                    }
                }]
            }
        };
    }

    const getYAxix = () => {
        var yaxis = [];
        if (option.includes("order")) {
            yaxis.push({
                title: {
                    text: props.t('Orders')
                },
                range: 5
            });
        }
        if (option.includes("revenue")) {
            yaxis.push({
                opposite: true,
                title: {
                    text: props.t("Revenue")
                },
                min: lineChartDate?.min,
                max: lineChartDate?.max
            })
        }
        if (option.includes("offer")) {
            yaxis.push({
                title: {
                    text: props.t("Offers"),
                },
                range: 5
            })
        }
        if (option.includes("invoice")) {
            yaxis.push({
                title: {
                    text: props.t("Invoice")
                },
                range: 5,
                min: lineChartDate?.min,
                max: lineChartDate?.max
            })
        }
        return yaxis;
    }


    const getLineMaxMetrics = () => {
        let data = {
            Week : 100,
            Month : 1000,
            Quarter: 10000,
            Year: 100000

        }
        return data[date?.type];
    }

    const getLineTick = () => {
        let data = {
            Week : 10,
            Month : 100,
            Quarter: 1000,
            Year: 10000

        }
        return data[date?.type];
    }

    const getLineMaxRevenue = () => {
        let data = {
            Week : 100000,
            Month : 1000000,
            Quarter: 10000000,
            Year: 100000000
        }
        return data[date?.type];
    }

    const getLineTickRevenue = () => {
        let data = {
            Week : 10000,
            Month : 100000,
            Quarter: 1000000,
            Year: 10000000

        }
        return data[date?.type];
    }

    const getDateForCharJs = () => {
        let data = [];
        if(option.includes("order")){
            data.push({
                label: props.t("Orders"),
                data: lineChartDate?.order,
                borderColor: "rgba(91,115,232,0.6)",
                fill:false,
                tension: 0.1,
                yAxisID: 'y',
            })
        }
        if (option.includes("revenue")) {
            data.push({
                label: props.t("Revenue"),
                data: lineChartDate?.revenue,
                borderColor: "rgba(220,22,22,0.6)",
                fill:false,
                tension: 0.1,
                yAxisID: 'y1',
            })
        }
        if (option.includes("offer")) {
            data.push({
                label: props.t("Offers"),
                data: lineChartDate?.offers,
                borderColor: "rgba(241,180,76,0.6)",
                fill:false,
                tension: 0.1,
                yAxisID: 'y',
            })
        }
        if (option.includes("invoice")) {
            data.push({
                label: props.t("Invoice"),
                data: lineChartDate?.invoice,
                borderColor: "rgba(6,211,99,0.6)",
                fill:false,
                tension: 0.1,
                yAxisID: 'y1',
            })
        }
        return data;
    }

    const getSeries = () => {
        let data = [];
        if (option.includes("order")) {
            data.push({name: props.t("Orders"), data: lineChartDate?.order})
        }
        if (option.includes("revenue")) {
            data.push({name: props.t("Revenue"), data: lineChartDate?.revenue})
        }
        if (option.includes("offer")) {
            data.push({name: props.t("Offers"), data: lineChartDate?.offers})
        }
        if (option.includes("invoice")) {
            data.push({name: props.t("Invoice"), data: lineChartDate?.invoice})
        }
        return data;
    }

    const handleOption = (type) => {
        let optionsArray = [];
        if(option.length != 4){
            optionsArray.push(...option)
        }
        if(optionsArray.includes(type)){
            optionsArray = option.filter(value => value != type);
        } else {
            optionsArray.push(type);
        }
        if(optionsArray.length == 0){
            optionsArray.push("order", "revenue", "offer", "invoice")
        }
        setOption(optionsArray);
        if (!lineChartMetricsLoading) {
            setOptionLoading(true)
            loadReportData(moment(date?.startDate).format("YYYY-MM-DD"), moment(date?.endDate).format("YYYY-MM-DD"), date?.type, rFilter)
        }
    }

    const getDatatableData = () => {
        const rows = dataTable?.filter((row) =>
            row.CustomerName?.toLowerCase().includes(search?.toLowerCase())
        );
        rows.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);

        let tableData = rows?.map((item, key) => ({
            type: <span onClick={() => handleOrderOpen(item)}>{item?.type}</span>,
            documentNumber: <span onClick={() => handleOrderOpen(item)}>{item?.DocumentNumber}</span>,
            customerName: <span onClick={() => handleOrderOpen(item)}>{item?.CustomerName}</span>,
            date: <span onClick={() => handleOrderOpen(item)}>{item?.date}</span>,
            total: <span onClick={() => handleOrderOpen(item)}>{formatCurrency(item?.Currency, item?.TotalWithoutTax ? item?.TotalWithoutTax : item?.Total)}</span>,
            status: item?.type == "Invoice"? <InvoiceStatus status={item.status}/> : <div onClick={() => handleOrderOpen(item)} className={`badge ${getFilterClassName(item?.status)} p-1`}>{item.status}</div>,
        }));



        let data = {
            columns:  [
                {
                    label: props.t("Type"),
                    field: "type",
                    sort: "asc",
                },
                {
                    label: props.t("Document Number"),
                    field: "documentNumber",
                    sort: "asc"
                },
                {
                    label: props.t("Customer Name"),
                    field: "customerName",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: props.t("Total"),
                    field: "total",
                    sort: "asc"
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc"
                }
            ],
            rows: tableData ? tableData : [],
        };
        return data;
    };

    const getFilterClassName = (filterName) => {
        if(filterName == 'Delayed' || filterName == 'Expired'){
            return 'bg-soft-warning bg-warning';
        }
        if(filterName == 'Cancelled'){
            return 'bg-soft-danger bg-danger';
        }
        if(filterName == 'Ongoing'){
            return 'bg-soft-info bg-info';
        }
        if(filterName == 'Invoiced' || filterName == 'Accepted'){
            return 'bg-soft-success bg-success';
        }

        return null;
    }

    return (
        <React.Fragment>
            <div className={showFilters() ? "page-content": ''}>

                <div className={showFilters() ? "container-fluid": ''}>
                    <Row>
                        <ReportFilter onChange={handleDateChange} disabled={isLoading()} showFilter={showFilters()}/>
                    </Row>

                    <Loader loading={isLoading()} title="Dashboard"/>
                    {!isLoading() && <>
                        {orderMetrics?.sumOfOrders == 500 &&
                            <Row>
                                <Col>
                                    <div className="mt-4">
                                        <Alert color="warning">{props.t("Warning! metrics only for 500 records. ")}</Alert>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row className={"mt-3"}>
                            <Col md={2} xl={3}>
                                <Card style={{cursor: "pointer"}}>
                                    <CardBody onClick={() => handleOption("revenue")} style={{border: option.length != 4 && option.includes("revenue") ? "1px solid " + brandSettings?.primaryBtnColor : ""}}>
                                        <div className="float-end mt-2">
                                            <ReactApexChart
                                                options={options1}
                                                series={series1}
                                                type={'bar'}
                                                height={40}
                                                width={70}
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mb-1 mt-1" key={"orderTotal_"+ orderMetrics?.orderTotal}><span> <CountUp end={orderMetrics?.orderTotal ? orderMetrics?.orderTotal : 0} separator="," prefix={TENANT_CURRENCY?.currencySymbolPosition === "PRE_FIX" ? TENANT_CURRENCY.symbol ? TENANT_CURRENCY.symbol : TENANT_CURRENCY.code : ""} suffix={TENANT_CURRENCY?.currencySymbolPosition === "POST_FIX" ? TENANT_CURRENCY.symbol ? " " + TENANT_CURRENCY.symbol : " " + TENANT_CURRENCY.code : ""} decimals={0}/></span>
                                            </h4>
                                            <p className="text-muted mb-0">{props.t("Ongoing orders")}</p>
                                        </div>
                                        {/*<p className="text-muted mt-3 mb-0"><span className={"text-" + REPORT_TEMPLATE[0].color + " me-1"}><i
                                            className={REPORT_TEMPLATE[0].icon + " me-1"}></i>{REPORT_TEMPLATE[0].badgeValue}</span>{" "}{REPORT_TEMPLATE[0].desc}
                                        </p>*/}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={2} xl={3}>
                                <Card style={{cursor: "pointer"}}>
                                    <CardBody onClick={() => handleOption("order")} style={{border: option.length != 4 && option.includes("order") ? "1px solid " + brandSettings?.primaryBtnColor : ""}}>
                                        <div className="float-end mt-2">
                                            <ReactApexChart
                                                options={options2}
                                                series={series2}
                                                type={"radialBar"}
                                                height={45}
                                                width={45}
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mb-1 mt-1" key={"sumOfOrders"+ orderMetrics?.sumOfOrders}><span><CountUp end={orderMetrics?.sumOfOrders ? orderMetrics?.sumOfOrders : 0} separator="," prefix={""} suffix={""} decimals={0}/></span>
                                            </h4>
                                            <p className="text-muted mb-0">{props.t("Orders")}</p>
                                        </div>
                                        {/*<p className="text-muted mt-3 mb-0"><span className={"text-" + REPORT_TEMPLATE[1].color + " me-1"}><i
                                            className={REPORT_TEMPLATE[1].icon + " me-1"}></i>{REPORT_TEMPLATE[1].badgeValue}</span>{" "}{REPORT_TEMPLATE[1].desc}
                                        </p>*/}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={2} xl={3}>
                                <Card style={{cursor: "pointer"}}>
                                    <CardBody onClick={() => handleOption("offer")} style={{border: option.length != 4 && option.includes("offer") ? "1px solid " + brandSettings?.primaryBtnColor : ""}}>
                                        <div className="float-end mt-2">
                                            <ReactApexChart
                                                options={options4}
                                                series={series4}
                                                type={"bar"}
                                                height={40}
                                                width={70}
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mb-1 mt-1" key={"offerMetrics"+ offerMetrics?.noOfOffers}><span><CountUp end={offerMetrics?.noOfOffers ? offerMetrics?.noOfOffers : 0} separator="," prefix={""} suffix={""} decimals={0}/></span>
                                            </h4>
                                            <p className="text-muted mb-0">{props.t("Offers")}</p>
                                        </div>
                                        {/*<p className="text-muted mt-3 mb-0"><span className={"text-" + REPORT_TEMPLATE[2].color + " me-1"}><i
                                            className={REPORT_TEMPLATE[2].icon + " me-1"}></i>{REPORT_TEMPLATE[2].badgeValue}</span>{" "}{REPORT_TEMPLATE[2].desc}
                                        </p>*/}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={2} xl={3}>
                                <Card style={{cursor: "pointer"}}>
                                    <CardBody onClick={() => handleOption("invoice")} style={{border: option.length != 4 && option.includes("invoice") ? "1px solid " + brandSettings?.primaryBtnColor : ""}}>
                                        <div className="float-end mt-2">
                                            <ReactApexChart
                                                options={options3}
                                                series={series3}
                                                type={"radialBar"}
                                                height={45}
                                                width={45}
                                            />
                                        </div>
                                        <div>
                                            <h4 className="mb-1 mt-1" key={"invoieTotal"+ invoiceMetrics?.invoiceTotal }><span><CountUp end={invoiceMetrics?.invoiceTotal? invoiceMetrics?.invoiceTotal : 0} separator="," prefix={TENANT_CURRENCY?.currencySymbolPosition === "PRE_FIX" ? TENANT_CURRENCY.symbol ? TENANT_CURRENCY.symbol : TENANT_CURRENCY.code : ""} suffix={TENANT_CURRENCY?.currencySymbolPosition === "POST_FIX" ? TENANT_CURRENCY.symbol ? " " + TENANT_CURRENCY.symbol : " " + TENANT_CURRENCY.code : ""} decimals={0}/></span>
                                            </h4>
                                            <p className="text-muted mb-0">{props.t('Total Revenue')}</p>
                                        </div>
                                        {/*<p className="text-muted mt-3 mb-0"><span className={"text-" + REPORT_TEMPLATE[3].color + " me-1"}><i
                                            className={REPORT_TEMPLATE[3].icon + " me-1"}></i>{REPORT_TEMPLATE[3].badgeValue}</span>{" "}{REPORT_TEMPLATE[3].desc}
                                        </p>*/}
                                    </CardBody>
                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col lg={12} key={"date-"+ chartKey}>
                                <Card>
                                    <CardBody>
                                        <Line
                                            height="100"
                                            options={getYAxisChartJS()}
                                            data={{
                                                labels: getXAxis(lineChartDate?.xaxis),
                                                datasets: getDateForCharJs()
                                            }}/>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} key={"data-table-"+ chartKey}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4 mb-4">{props.t("Transactions")}</CardTitle>
                                        {dataTable?.length > 0 &&
                                        <MDBDataTable
                                            responsive
                                            hover
                                            barReverse={true}
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searching={true}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            onPageChange={(activePage, pageAmout) => { console.log("active", activePage); console.log("Page Amout", pageAmout)}}
                                            entriesOptions={[50, 100, 500]}
                                            onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}
                                        />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4 mb-4">{props.t("Transaction")}</CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table table-borderless table-hover">
                                                <thead className="datatable-head">
                                                <tr style={{"cursor": "pointer"}}>
                                                    <th onClick={() => handleColumnSort("type", "asc")}>{props.t("Type")}</th>
                                                    <th onClick={() => handleColumnSort("DocumentNumber", "asc")}>{props.t("Document Number")}</th>
                                                    <th onClick={() => handleColumnSort("CustomerName", "asc")}>{props.t("Customer Name")}</th>
                                                    <th>{props.t("Our Reference")}</th>
                                                    <th onClick={() => handleColumnSort("date", "asc")}>{props.t("Date")}</th>
                                                    <th onClick={() => handleColumnSort("Total", "asc")}>{props.t("Total")}</th>
                                                    <th>{props.t("Status")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {dataTable?.map(function (order) {
                                                    return (
                                                        <tr onClick={() => handleOrderOpen(order)} style={{"cursor": "pointer"}}>
                                                            <td>{props.t(order?.type)}</td>
                                                            <td>#{order?.DocumentNumber}</td>
                                                            <td>{order?.CustomerName}</td>
                                                            <td>{order?.OurReference}</td>
                                                            <td>
                                                                {order?.date}
                                                            </td>
                                                            <td>
                                                                {formatCurrency(order?.Currency, order?.TotalWithoutTax ? order?.TotalWithoutTax : order?.Total)}
                                                            </td>
                                                            <td>
                                                                {order?.type == "Invoice"? <InvoiceStatus status={order.status}/> : <div onClick={() => handleOrderOpen(order)} className={`badge ${getFilterClassName(order?.status)} p-1`}>{order.status}</div>}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>*/}
                    </>}
                </div>
            </div>
        </React.Fragment>
    )
}

Dashboard.propTypes = {
    lineChartMetrics: PropTypes.object,
    onGetLineChartMetrics: PropTypes.func,
    lineChartMetricsList: PropTypes.object,
    onGetMetricsList: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        lineChartMetrics: state.ecommerce.lineChartMetrics,
        lineChartMetricsLoading: state.ecommerce.lineChartMetricsLoading,
        lineChartMetricsList: state.ecommerce.lineChartMetricsList,
        lineChartMetricsListLoading: state.ecommerce.lineChartMetricsLoading,
        tenantUsers: state.tenant.tenantAdmins,
    }
}

const mapDispatchToProps = (dispatch) => ({
    onGetLineChartMetrics: (startDate, endDate, type, filter) => dispatch(getDashboardLineChart(startDate, endDate, type, filter)),
    onGetMetricsList: (startDate, endDate, type, filter) => dispatch(getDashboardMetricsList(startDate, endDate, type, filter)),
    onGetTenantUsers: (tenantId) => dispatch(getTenantAdmins(tenantId, "active")),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(Dashboard)));

