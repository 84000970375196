import {Row} from "reactstrap";
import React, {useState} from "react";
import Dropzone from "react-dropzone";
import {DOWNLOAD_URL} from "../../helpers/url_helper";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {del} from "../../helpers/api_helper";
import {Editor} from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Select from "react-select";
import {preventNumberInputScroll} from "../../util/helperFunctions";


const CustomField = (props) => {
    let {
        tab,
        field,
        handleCustomFieldChange,
        handleCustomRangeMinFieldChange,
        handleCustomRangeMaxFieldChange,
        getContentValue,
        handleAcceptedFilesCustomContent,
        getFileContent,
        fileUpload,
        reloadLayout,
        handleAccessLevel,
        handleImportantChange,
        getEditorContent,
        handleCustomFreeTextFieldChange,
        isGroup,
        toggleInheritance
    } = props;


    const [content, setContent] = useState(getContentValue(field));
    const [range, setRange] = useState(content?.minValue && content?.maxValue ? true : field?.isRange ? true : false);


    const handleDeleteCustomContent = async (content) => {
        let response = await del(`/api/content/${field.id}`);
        reloadLayout();
    }

    const isDisabled = (field) => {
        if(content?.inherited && !content?.overridden){
            return true;
        }
        return false;
    }

    const handleRangeCheck = (value) => {
        setRange(value);
        handleCustomRangeMaxFieldChange(tab, field, "", "");
    }


    const TagInheritance = (field, content) => {
        if(isGroup){
            return (
                <div className="width-80 float-start">
                    {field.name}
                </div>
            )
        }else{
            return (
                <>
                    <div className="width-80 float-start">
                        {field.name}
                    </div>
                    <div>
                        {content?.inherited && <i onClick={() => toggleInheritance(content, tab, field)} className={content.overridden ? "bx bxs-star font-size-18 float-end font-color-green pointer-cursor" : "uil uil-star font-size-18 float-end pointer-cursor"} title={content.overridden ? props.t("Field inherited from assigned article group but value overridden.") : props.t("Inherited from assigned article group.")}></i>}
                    </div>
                </>
            )
        }

    }

    return (<React.Fragment>
        
        {field.fieldType == "HEADER" &&
            <Row className="mb-3" key={"content-header-"+ field.id}>
                <div className="col-md-12">
                    <h5>{field.name}</h5>
                </div>
            </Row>
        }

        {field.fieldType == "ASSOCIATION_FIELD" && <Row>
            <Row className="mb-3">
                <label htmlFor="example-text-input"
                       className="col-md-2 col-form-label">
                    {TagInheritance(field, content)}
                </label>
                <div className={field?.fieldLevel == 'PUBLIC' ? "col-md-7": "col-md-8"}>
                    <input
                        className="form-control"
                        type="text"
                        defaultValue={content.value}
                        placeholder={`${field.name}`}
                        disabled={true}
                    />
                </div>
                <div className="col-md-2">
                    <select className="form-control"
                            defaultValue={content?.accessTo}
                            onChange={(e)=> handleAccessLevel(field, e.target.value)}
                            disabled={isDisabled(field)}>
                        <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                        <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                    </select>
                </div>
                {/*{field?.fieldLevel == 'PUBLIC' && <div className="form-check col-md-1 mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={content?.important}
                        disabled={isDisabled(field)}
                        onChange={(e) => handleImportantChange(field, e.target.checked)}
                    />
                    <label style={{fontSize:"11px"}}>
                        {props.t("Important")}
                    </label>
                </div>}*/}
            </Row>
        </Row>}

        {field.fieldType == "INPUT_FIELD" &&
        <Row className="mb-3">
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className={field?.fieldLevel == 'PUBLIC' ? "col-md-7": "col-md-8"}>
                <input
                    className="form-control"
                    type="text"
                    onChange={(e) => handleCustomFieldChange(tab, field, e.target.value)}
                    placeholder={`Enter ${field.name}`}
                    defaultValue={content.value}
                    disabled={isDisabled(field)}
                />
            </div>
            <div className="col-md-2">
                <select className="form-control"
                        defaultValue={content?.accessTo}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}
                        disabled={isDisabled(field)}>
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' && <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    disabled={isDisabled(field)}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Important")}
                </label>
            </div>}
        </Row>
        }

        {field.fieldType == "NUMBER_FIELD" &&
        <Row className="mb-3" key={"content-" + field.id}>
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className={field?.fieldLevel == 'PUBLIC' ? "row col-md-4": "row col-md-5"}>
                {/*<input
                    className="form-control"
                    type="number"
                    onChange={(e) => handleCustomFieldChange(tab, field, e.target.value)}
                    placeholder={`Enter ${field.name}`}
                    defaultValue={content.value}
                    disabled={isDisabled(field)}
                    onWheel={preventNumberInputScroll}
                />*/}
                <div className={"col-md-6"}>
                    <input
                        className="form-control"
                        type="number"
                        onChange={(e) => handleCustomRangeMinFieldChange(tab, field, e.target.value, e.target.value)}
                        placeholder={`Enter min value`}
                        defaultValue={content.minValue}
                        disabled={isDisabled(field)}
                        onWheel={preventNumberInputScroll}
                        // onKeyDown={validateNumber}
                    />
                </div>
                <div className={"col-md-6"}>
                    {range && <input
                        className="form-control"
                        type="number"
                        onChange={(e) => handleCustomRangeMaxFieldChange(tab, field, e.target.value, e.target.value)}
                        placeholder={`Enter max value`}
                        defaultValue={content.maxValue}
                        disabled={isDisabled(field)}
                        onWheel={preventNumberInputScroll}
                        // onKeyDown={validateNumber}
                    />}
                </div>
            </div>
            <div className="col-md-1 pt-2">
                <span>{field.unit}</span>
            </div>
            <div className={"col-md-2 mt-2"}>
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={range}
                    onChange={(e) => handleRangeCheck(!range)}
                />
                <label style={{fontSize:"11px"}}>
                   &nbsp; {props.t("Range")}
                </label>
            </div>
            <div className="col-md-2">
                <select className="form-control" defaultValue={content?.accessTo}
                        disabled={isDisabled(field)}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}>
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' &&  <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    disabled={isDisabled(field)}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Important")}
                </label>
            </div>}
        </Row>
        }

        {field.fieldType == "RANGE" &&
            <Row className="mb-3" key={"content-" + field.id}>
                <label htmlFor="example-text-input"
                       className="col-md-2 col-form-label">
                    {TagInheritance(field, content)}
                </label>
                <div className={field?.fieldLevel == 'PUBLIC' ? "row col-md-4": "row col-md-5"}>
                    <div className={"col-md-6"}>
                        <input
                            className="form-control"
                            type="number"
                            onChange={(e) => handleCustomRangeMinFieldChange(tab, field, e.target.value, e.target.value)}
                            placeholder={`Enter min value`}
                            defaultValue={content.minValue}
                            disabled={isDisabled(field)}
                            onWheel={preventNumberInputScroll}
                            // onKeyDown={validateNumber}
                        />
                    </div>
                    <div className={"col-md-6"}>
                        <input
                            className="form-control"
                            type="number"
                            onChange={(e) => handleCustomRangeMaxFieldChange(tab, field, e.target.value, e.target.value)}
                            placeholder={`Enter max value`}
                            defaultValue={content.maxValue}
                            disabled={isDisabled(field)}
                            onWheel={preventNumberInputScroll}
                            // onKeyDown={validateNumber}
                        />
                    </div>
                </div>
                <div className="col-md-3 pt-2">
                    <span>{field.unit}</span>
                </div>
                <div className="col-md-2">
                    <select className="form-control" defaultValue={content?.accessTo}
                            disabled={isDisabled(field)}
                            onChange={(e)=> handleAccessLevel(field, e.target.value)}>
                        <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                        <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                    </select>
                </div>
                {field?.fieldLevel == 'PUBLIC' &&  <div className="form-check col-md-1 mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={content?.important}
                        disabled={isDisabled(field)}
                        onChange={(e) => handleImportantChange(field, e.target.checked)}
                    />
                    <label style={{fontSize:"11px"}}>
                        {props.t("Important")}
                    </label>
                </div>}
            </Row>
        }

        {field.fieldType == "BOOLEAN" &&
        <Row className="mb-3" key={"field-" + field.id}>
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className={field?.fieldLevel == 'PUBLIC' ? "col-md-4": "col-md-5"}>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={"exampleRadios"+ field.id}
                        id={"exampleRadios"+ field.id}
                        onChange={(e) => handleCustomFieldChange(tab, field, true)}
                        disabled={isDisabled(field)}
                        defaultChecked={content.value == "true" ? true : false}/>
                    <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                    >
                        {props.t("Yes")}
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={"exampleRadios"+ field.id}
                        id={"exampleRadios"+ field.id}
                        onChange={(e) => handleCustomFieldChange(tab, field, false)}
                        disabled={isDisabled(field)}
                        defaultChecked={content.value == "false" ? true : false}/>
                    <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                    >
                        {props.t("No")}
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={"exampleRadios"+ field.id}
                        id={"exampleRadios"+ field.id}
                        disabled={isDisabled(field)}
                        onChange={(e) => handleCustomFieldChange(tab, field, null)}
                        defaultChecked={content.value == null ? true : false}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="exampleRadios2"
                    >
                        {props.t("Not Applicable")}
                    </label>
                </div>

            </div>
            <div className="col-md-3 pt-2">
                <span>{field.unit}</span>
            </div>
            <div className="col-md-2">
                <select className="form-control"
                        defaultValue={content?.accessTo}
                        disabled={isDisabled(field)}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}>
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' && <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    disabled={isDisabled(field)}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Important")}
                </label>
            </div>}
        </Row>
        }
        {field.fieldType == "FREE_TEXT" &&

        <Row className="mb-3">
            {field.name && <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>}
            <div className={field?.fieldLevel == 'PUBLIC' ? field.name ? "col-md-7" : "col-md-9": field.name ? "col-md-8" : "col-md-10" }>
                <Editor
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    defaultEditorState={getEditorContent(field)}
                    readOnly={isDisabled(field)}
                    onEditorStateChange={(editorState) => handleCustomFreeTextFieldChange(tab, field, editorState)}
                />

            </div>
            <div className="col-md-2">
                <select className="form-control" defaultValue={content?.accessTo}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}
                        disabled={isDisabled(field)}
                >
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' &&  <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    disabled={isDisabled(field)}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Lock")}
                </label>
            </div>}
        </Row>
        }
        {field.fieldType == "DROPDOWN" &&
            <Row className="mb-3" key={"content-" + field.id}>
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className={field?.fieldLevel == 'PUBLIC' ? "col-md-4": "col-md-5"}>
                <select className="form-control"
                        defaultValue={content.value}
                        onChange={(e) => handleCustomFieldChange(tab, field, e.target.value)}
                        disabled={isDisabled(field)}>
                    <option value={""}></option>
                    {field.fieldOptions?.map((option) => {
                        return (<option value={option.name}>{option.name}</option>)
                    })}
                </select>
            </div>
            <div className="col-md-2">
                <select className="form-control" defaultValue={content?.accessTo}
                        disabled={isDisabled(field)}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}>
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' &&  <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                    disabled={isDisabled(field)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Important")}
                </label>
            </div>}
        </Row>}
        {field.fieldType == "DROPDOWN_MULTISELECT" &&
            <Row className="mb-3" key={"content-" + field.id}>
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className={field?.fieldLevel == 'PUBLIC' ? "col-md-4": "col-md-5"}>
                <Select
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={isDisabled(field)}
                    defaultValue={content == null || content?.value == null || (content?.value == null && content?.value.trim() == "") ? [] : content.value.split(',').sort((a, b) => a.localeCompare(b)).filter(v => v && v.length > 0).map(v => ({value: v, label: v}))}
                    onChange={(vals) => handleCustomFieldChange(tab, field, vals?.map(v => v.value).join(','))}
                    options={field.fieldOptions?.map(o => ({value: o.name, label: o.name}))}
                />
            </div>
            <div className="col-md-2">
                <select className="form-control" defaultValue={content?.accessTo}
                        disabled={isDisabled(field)}
                        onChange={(e)=> handleAccessLevel(field, e.target.value)}>
                    <option value="ROLE_CUSTOMER_USER">{props.t("Customer")}</option>
                    <option value="ROLE_TENANT_USER">{props.t("Tenant")}</option>
                </select>
            </div>
            {field?.fieldLevel == 'PUBLIC' &&  <div className="form-check col-md-1 mt-2">
                <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={content?.important}
                    disabled={isDisabled(field)}
                    onChange={(e) => handleImportantChange(field, e.target.checked)}
                />
                <label style={{fontSize:"11px"}}>
                    {props.t("Important")}
                </label>
            </div>}
        </Row>}

        {field.fieldType == "FILE" &&
        <Row className="mb-3 margin-top-10px" key={"file-download-row" + field.id}>
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {TagInheritance(field, content)}
            </label>
            <div className="col-md-10">
                <Dropzone
                    onDrop={acceptedFiles => {    
                        handleAcceptedFilesCustomContent(tab, field, acceptedFiles)
                    }}>

                    {({getRootProps, getInputProps}) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                </div>
                                <h4>{props.t("Drop files here or click to upload")}</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <br/>
                <span>{getFileContent(field)}</span>
                <label key={"file-download" + Object.keys(fileUpload).length}>
                    {fileUpload.map(function (file, index) {
                        if (file.field == field.id) {
                            return (<a href={DOWNLOAD_URL + "/" + file.file.id}>{file.file.name}</a>)
                        }
                    })}
                </label>

            </div>
        </Row>
        }

    </React.Fragment>)
}

export default connect()(withTranslation()(CustomField))
