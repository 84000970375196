import React from "react"
import {Redirect} from "react-router-dom"
// Dashboard
import Dashboard from "../pages/Dashboard"
import Home from "../pages/Home"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import CreatePassword from "../pages/Authentication/CreatePassword"

// Profile
import MyAccount from "../pages/Authentication/user-profile"

//Articles
import Articles from "../pages/Articles/index"
import ArticleEdit from "../pages/Articles/ArticleEdit"
import ArticlePreview from "../pages/Articles/ArticlePreview"


//Tenants
import TenantList from "../pages/Tenant/TenantList"
import AddTenant from "../pages/Tenant/AddTenant"
import BrandSettings from '../pages/Settings/BrandSettings';
import TenantUsers from "../pages/Tenant/TenantUsers";
import TenantUserFormContainer from "../pages/Tenant/TenantUserFormContainer";
import FortnoxConfig from "../pages/Settings/FortnoxConfig";
import CustomerList from "../pages/Customer/CustomerList";
import CustomerEdit from "../pages/Customer/CustomerEdit";
import ContentList from "../pages/Content/ContentList";
import ProjectList from "../pages/Projects/ProjectList";
import ProjectFormContainer from "../pages/Projects/ProjectFormContainer";
import Orders from "../pages/Customer/Orders";
import CategoryList from "../pages/Category/CategoryList";
import SubCategoryList from "../pages/Category/SubCategoryList";
import ShopCategories from "../pages/ShopCategories/ShopCategories";
import ShopSubCategories from "../pages/ShopCategories/ShopSubCategories";
import ShopArticles from "../pages/ShopArticles/ShopArticles";
import ShopProductDetails from "../pages/ShopProductDetails/ShopProductDetails";
import CustomerUsers from "../pages/Customer/CustomerUsers";
import ShoppingCart from "../pages/ShoppingCart/ShoppingCart";
import LayoutList from "../pages/Layout/LayoutList";
import ShopCheckout from "../pages/ShopCheckout/ShopCheckout";

import SMTPConfig from "../pages/Settings/SMTPConfig";
import TenantDetails from "../pages/Tenant/TenantDetails";
import Invoices from "../pages/Invoice/Invoices";
import InvoiceDetail from "../pages/Invoice/InvoiceDetail";
import Offers from "../pages/Offer/Offers";
import OfferDetails from "../pages/Offer/OfferDetails";
import CustomerInvoices from "../pages/Invoice/CustomerInvoices";
import OrderDetails from "../pages/Customer/OrderDetails";

import DeliveryAddressFormContainer from "../pages/Customer/DeliveryAddressFormContainer";
import AddressList from "../pages/Customer/AddressList";
import Search from "../pages/Search/Search";
import TenantUserDetails from "../pages/Tenant/TenantUserDetails"
import UsersList from "../pages/Users/UsersList";
import CustomerProfile from "../pages/Customer/CustomerProfile";
import CustomerProfileEdit from "../pages/Customer/CustomerProfileEdit";
import UserProfile from "../pages/Users/UserProfile";
import SuperAdminUserDetails from "../pages/Tenant/SuperAdminUserDetails";
import OfferCheckout from "../pages/ShopCheckout/OfferCheckout";
import ArticleGroupList from "../pages/ArticleGroups/ArticleGroupList";
import CreateGroup from "../pages/ArticleGroups/CreateGroup";
import Audit from "../pages/Audit/Audit"
import FieldList from "../pages/Field/FieldList"
import TemplateList from "../pages/Templates/TemplateList";
import TemplateDetails from "../pages/Templates/TemplateDetails";
import FavouriteList from "../pages/FavouriteList/FavouriteList"
import ProductListArticles from "../pages/FavouriteList/ProductListArticles"
import TaskList from "../pages/Tasks/TaskList"
import TaskDetail from "../pages/Tasks/TaskDetail"
import StockPointConfig from "../pages/Settings/StockPointConfig"
import LanguageList from "../pages/Language/LanguageList";
import LanguageLabelsList from "../pages/Language/LanguageLabelsList";
import PhraseList from "../pages/Language/PhraseList";
import CustomerWorkLogList from "../pages/Tasks/CustomerWorkLogList";
import WorklogType from "../pages/Tasks/WorklogType"
import CronList from "../pages/Cron/CronList";
import ProjectDetails from "../pages/Projects/ProjectDetails";
import ArticleGroupDetails from "../pages/ArticleGroups/ArticleGroupDetails";
import OrderFormContainer from "../pages/Tenant/OrderFormContainer"
import OfferFormContainer from "../pages/Tenant/OfferFormContainer"
import OrderFromOfferForm from "../pages/Tenant/OrderFromOfferForm"
import MyAccountEdit from "../pages/Users/MyAccountEdit";
import DateConfig from "../pages/Settings/DateConfig";
import TaskCategory from "../pages/Tasks/TaskCategory"
import ProjectTemplateList from "../pages/ProjectTemplate/ProjectTemplateList";
import ProjectTemplateDetails from "../pages/ProjectTemplate/ProjectTemplateDetails";
import TaskPriority from "../pages/Tasks/TaskPriority"
import CurrencyList from "../pages/Settings/CurrencyList"
import CurrencyConfig from "../pages/Settings/CurrencyConfig"
import ProjectConfig from "../pages/Settings/ProjectConfig"
import CustomerUserFormContainer from "../pages/Customer/CustomerUserFormContainer"
import ArticleBulkActions from "../pages/BulkActions/ArticleBulkActions";
import ManufacturerList from "../pages/Manufacturer/ManufacturerList";
import ManufacturerDetails from "../pages/Manufacturer/ManufacturerDetails";
import ManufacturerDetailsEdit from "../pages/Manufacturer/ManufacturerDetailsEdit";
import FortnoxConfigActivation from "../pages/Settings/FortnoxConfigActivation";
import SockekAuditLog from "../pages/Socket/SockekAuditLog";
import DeepSync from "../pages/DeepSync/DeepSync";
import Statistics from "../pages/Statistics/Statistics";

const SUPER_ADMIN = ["ROLE_SUPER_ADMIN"];
const TENANT_ADMIN = ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"];
const SUPER_ADMIN_AND_TENANT_ADMIN = ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]
const CUSTOMER_ADMIN = ["ROLE_CUSTOMER_ADMIN"];

const userRoutes = [
    {path: "/dashboard", component: Dashboard, initState: {title: 'Dashboard'},
        allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]},
    {path: "/home", component: Home, initState: {title: 'Home'}},

    // //profile
    { path: "/settings/user-profile", component: MyAccount, initState: { title: 'Profile' } },
    { path: "/settings/user-profile/edit/:userId", component: MyAccountEdit, initState: {title: 'Edit Profile'}},

    { path: "/tenants", component: TenantList, allowedRoles: SUPER_ADMIN, initState: { title: 'Tenants' } },
    { path: "/tenants/:tenantId/details", component: TenantDetails, allowedRoles: SUPER_ADMIN, initState: { title: 'Tenants Details' }, eventMap: { entityTypes: ['User', 'Tenant'] , impersonationType: null }},
    { path: "/tenants/:tenantId/user/:id", component: SuperAdminUserDetails, allowedRoles: SUPER_ADMIN, initState: { title: 'User Details' }, eventMap: { entityTypes: ['User'] , impersonationType: null }},
    { path: "/tenants/edit/:userId", component: AddTenant, allowedRoles: SUPER_ADMIN, initState: { header: 'Edit Tenant', title: 'Edit Tenant' } },
    { path: "/addTenant", component: AddTenant, allowedRoles: SUPER_ADMIN, initState: { header: 'Add Tenant', title: 'Add Tenant' } },
    { path: "/settings/users", component: TenantUsers, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Add User', title: 'Users' } },
    { path: "/settings/users/add", component: TenantUserFormContainer, allowedRoles: TENANT_ADMIN, initState: { header: 'Add User', title: 'Add User' } },
    { path: "/settings/users/edit/:userId", component: TenantUserFormContainer, allowedRoles: TENANT_ADMIN, initState: { header: 'Edit User', title: 'Edit User' } },
    { path: "/settings/users/:id", component: TenantUserDetails, allowedRoles: TENANT_ADMIN, initState: { title: 'User Details' }, eventMap: { entityTypes: ['User'] , impersonationType: 'TENANT' }},

    { path: "/translations", component: LanguageList, allowedRoles: SUPER_ADMIN_AND_TENANT_ADMIN, initState: {title : 'Languages'}},
    { path: "/languages/labels/:locale", component: LanguageLabelsList, allowedRoles: SUPER_ADMIN_AND_TENANT_ADMIN, initState: {title : 'Labels'}},
    { path: "/phrases", component: PhraseList, allowedRoles: SUPER_ADMIN, initState: {title: "Languages"}},

    //Customers
    { path: "/customers", component: CustomerList, allowedRoles: TENANT_ADMIN, initState: { title: 'Customers' } },
    { path: "/customers/:id/details", component: CustomerEdit, allowedRoles: TENANT_ADMIN, initState: { title: 'Customer - Details' } },
    { path: "/customers/:id/profile", component: CustomerProfile, allowedRoles: TENANT_ADMIN, initState: { title: 'Customer - Details' }, eventMap: { entityTypes: ['Customer'] , impersonationType: 'TENANT' }},
    { path: "/customers/:id/profile/edit", component: CustomerProfileEdit, allowedRoles: TENANT_ADMIN, initState: { title: 'Customer - Details' } },
    // { path: "/customers/:id/users/:userId", component: UserProfile, allowedRoles: TENANT_ADMIN, initState: { title: 'Customer - Details' } },

    { path: "/customer/users", component: CustomerUsers, allowedRoles: CUSTOMER_ADMIN, initState: { title: 'Customer - Users' } },
    // { path: "/customer/users/:userId", component: UserProfile, allowedRoles: CUSTOMER_ADMIN, initState: { title: 'Customer - User Edit' }, eventMap: { entityTypes: ['User'] , impersonationType: 'CUSTOMER' } },
    { path: "/customers/:customerId/users/:userId/edit", component: CustomerUserFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Customer - Users' } },
    { path: "/customer/users/:userId/edit", component: CustomerUserFormContainer, allowedRoles: CUSTOMER_ADMIN, initState: { title: 'Customer - Users' } },


    { path: "/order/new", component: OrderFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Orders - New'} },
    { path: "/order/:id/edit", component: OrderFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Orders - Edit'} },
    { path: "/customer/orders", component: Orders, initState: { title: 'Orders' } },
    { path: "/customer/orders/:id", component: OrderDetails, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], initState: { title: 'Order Details' }, eventMap: { entityTypes: ['Order'] , impersonationType: null }},
    { path: "/customer/invoices", component: CustomerInvoices, allowedRoles: CUSTOMER_ADMIN, initState: { title: 'Invoices' } },
    { path: "/customer/invoice/:id", component: InvoiceDetail, initState: { title: 'Invoice - Details' } },
    { path: "/customer/offers", component: Offers, initState: { title: 'Offers' } },
    { path: "/customer/offers/checkout/:offerId", component: OfferCheckout, initState: {title: 'Offer Checkout'}},
    { path: "/customer/offers/:id/types/:offerType", component: OfferDetails, initState: { title: 'Offer Details' } },
    { path: "/customer/offers/:id", component: OfferDetails, initState: { title: 'Offer Details' }, eventMap: { entityTypes: ['Offers'] , impersonationType: null}},
    { path: "/offers/:id/types/:offerType", component: OfferDetails, initState: { title: 'Offer Details' } },
    { path: "/offers/new", component: OfferFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Offers - New'} },
    { path: "/offers/:id/edit", component: OfferFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Offers - Edit'} },
    { path: "/offers/:id/order/new", component: OrderFromOfferForm, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Order - New'} },


    { path: "/customer/deliveryAddresses", component: AddressList, allowedRoles: CUSTOMER_ADMIN, initState: { title: 'Customer - Addresses' } },
    { path: "/customer/deliveryAddresses/add", component: DeliveryAddressFormContainer, initState: { header: 'Create Delivery Address', title: 'Delivery Address - Add' } },
    { path: "/customer/deliveryAddresses/:id/edit", component: DeliveryAddressFormContainer, initState: { header: 'Update Delivery Address', title: 'Delivery Address - Edit' }, eventMap: { entityTypes: ['CustomerDeliveryAddress'] , impersonationType: 'CUSTOMER' }},

    { path: "/users", component: UsersList, allowedRoles: TENANT_ADMIN, initState: {title : 'Users'}},
    { path: "/users/create", component: CustomerUserFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Create user'}},
    { path: "/customers/:customerId/users/create", component: CustomerUserFormContainer, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Create user'}},
    { path: "/customers/:customerId/users/:id", component: UserProfile, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER", "ROLE_CUSTOMER_ADMIN"], initState: {title: 'User - Details'}, eventMap: { entityTypes: ['User'] , impersonationType: 'TENANT' }},

    //Articles
    {path: "/articles", component: Articles, allowedRoles: TENANT_ADMIN, initState: {title: 'Articles'}},
    {path: "/articles/:id", component: ArticlePreview, allowedRoles: TENANT_ADMIN, initState: {title: 'Articles - Details'}, eventMap: { entityTypes: ['Article', 'Content', 'ContentCategory', 'ArticleFieldValue', 'ArticleContent'] , impersonationType: 'TENANT' }},
    {path: "/articles/:id/edit", component: ArticleEdit, allowedRoles: TENANT_ADMIN, initState: {title: 'Articles - Edit'}},
    {path: "/customer/articles", component: ShopArticles, initState: {title: 'Articles'}},
    {path: "/customer/articles/:id", allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], component: ShopProductDetails, initState: {title: 'Articles - Details'}},


    {path: "/manufacturers", component: ManufacturerList, allowedRoles: TENANT_ADMIN, initState: {title: 'Manufacturer'}},
    {path: "/manufacturers/:id/details", component: ManufacturerDetails, allowedRoles: TENANT_ADMIN, initState: {title: 'Manufacturer Details'}},
    {path: "/manufacturers/:id/edit", component: ManufacturerDetailsEdit, allowedRoles: TENANT_ADMIN, initState: {title: 'Edit Manufacturer Details'}},

    {path: "/bulk-actions", component: ArticleBulkActions, allowedRoles: TENANT_ADMIN, initState: {title: 'Bulk Actions'}},

    {path: "/groups", component: ArticleGroupList, allowedRoles: TENANT_ADMIN, initState: {title: 'Article Groups'}},
    {path: "/groups/create", component: CreateGroup, allowedRoles: TENANT_ADMIN, initState: {title: 'Article Groups'}},
    {path: "/groups/detail/:id", component: ArticleGroupDetails, allowedRoles: TENANT_ADMIN, initState: {title: 'Article Groups'}},
    {path: "/groups/edit/:id", component: CreateGroup, allowedRoles: TENANT_ADMIN, initState: {title: 'Article Groups'}, eventMap: { entityTypes: ['ArticleGroup'] , impersonationType: 'TENANT' }},

    { path: "/orders", component: Orders, allowedRoles: TENANT_ADMIN, initState: { title: 'Orders' } },
    { path: "/orders/:id", component: OrderDetails, allowedRoles: TENANT_ADMIN, initState: { title: 'Order Details' }, eventMap: { entityTypes: ['Order'] , impersonationType: 'TENANT' }},

    { path: "/statistics", component: Statistics, allowedRoles: TENANT_ADMIN, initState: {title: 'Statistics'}},

    { path: "/invoice", component: Invoices, allowedRoles: TENANT_ADMIN, initState: { title: 'Invoices' } },
    { path: "/invoice/:id", component: InvoiceDetail, initState: { title: 'Invoice - Details' }, eventMap: { entityTypes: ['Invoice'] , impersonationType: 'TENANT' }},
    { path: "/offers", component: Offers, allowedRoles: TENANT_ADMIN, initState: { title: 'Offers' } },
    { path: "/offers/:id", component: OfferDetails, allowedRoles: TENANT_ADMIN, initState: { title: 'Offer Details' }, eventMap: { entityTypes: ['Offer'] , impersonationType: 'TENANT' }},

    { path: "/customer/categories/:categoryId/articles", allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], component: ShopArticles, initState: { title: 'Articles' } },
    { path: "/customer/group/:groupId/articles", component: ShopArticles, initState: {title: 'Articles'}},

    { path: "/shop/cart", component: ShoppingCart, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], initState: { title: 'Shopping Cart' }, eventMap: { entityTypes: ['CartItem'] , impersonationType: 'CUSTOMER' }},
    { path: "/shop/checkout", component: ShopCheckout, initState: { title: 'Checkout' } },

    //Content
    { path: "/content/:id/tab", component: ContentList, allowedRoles: TENANT_ADMIN, initState: { title: 'Layout - Content' }},
    { path: "/fields/:type", component: FieldList, allowedRoles: TENANT_ADMIN, initState: { title: 'Fields' }, eventMap: { entityTypes: ['Field', 'FieldOption'] , impersonationType: 'TENANT' }},

    { path: "/tabs", component: LayoutList, allowedRoles: TENANT_ADMIN, initState: { title: 'Tabs' } },
    { path: "/tabs/:id/details", component: ContentList, allowedRoles: TENANT_ADMIN, initState: { title: 'Tab - Content' }, eventMap: { entityTypes: ['Template'] , impersonationType: 'TENANT' } },


    {path : "/templates", component: TemplateList, allowedRoles: TENANT_ADMIN, initState: {title: 'Templates'} },
    {path : "/templates/:id", component: TemplateDetails, allowedRoles: TENANT_ADMIN, initState: {title: 'Template Details'}, eventMap: { entityTypes: ['TabTemplate'] , impersonationType: 'TENANT' }},

    //Category
    { path: "/categories", component: CategoryList, allowedRoles: TENANT_ADMIN, initState: { title: 'Categories' } },
    { path: "/categories/:id/subcategory", component: SubCategoryList, allowedRoles: TENANT_ADMIN, initState: { title: 'Category - Subcategory' }, eventMap: { entityTypes: ['Category'] , impersonationType: 'TENANT' }},


    //Shop categories
    { path: "/customer/categories", component: ShopCategories, initState: { title: 'Categories' } },
    { path: "/customer/categories/:id/subcategory", component: ShopSubCategories, initState: { title: 'Categories - Subcategory' }},

    //Settings
    { path: "/settings/fortnox-config", component: FortnoxConfig, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'Fortnox Config', title: 'Fortnox Config' }, eventMap: { entityTypes: ['FortnoxConfig'] , impersonationType: null }},
    { path: "/settings/fortnox-config/activation", component: FortnoxConfigActivation, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'Fortnox Config', title: 'Fortnox Config' }, eventMap: { entityTypes: ['FortnoxConfig'] , impersonationType: null }},

    { path: "/settings/smtp-config", component: SMTPConfig, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { header: 'SMTP Config', title: 'SMTP Config' }, eventMap: { entityTypes: ['SMTPConfig'] , impersonationType: null }},
    { path: "/settings/brand", component: BrandSettings, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN"], initState: { title: 'Brand Settings' }, eventMap: { entityTypes: ['BrandConfig'] , impersonationType: null }},
    { path: "/settings/stock", component: StockPointConfig, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Default Stock Point', title: 'Default Stock Point' }, eventMap: { entityTypes: ['StockPointConfig'] , impersonationType: 'TENANT' }},
    { path: "/settings/sync", component: CronList, allowedRoles: ["ROLE_SUPER_ADMIN"], initState: { header: 'Cron', title: 'Cron' }},
    { path: "/settings/date-config", component: DateConfig, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: { header: 'Date Config', title: 'Date'}},
    { path: "/settings/default-currency-config", component: CurrencyConfig, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Currency Config', title: 'Currency'}},
    { path: "/settings/currency-config", component: CurrencyList, allowedRoles: ["ROLE_SUPER_ADMIN"], initState: { header: 'Currency Config', title: 'Currency'}},
    { path: "/settings/project-config", component: ProjectConfig, allowedRoles: ["ROLE_TENANT_ADMIN"], initState: { header: 'Project Config', title: 'Project Config' }, eventMap: { entityTypes: ['ProjectConfig'] , impersonationType: 'TENANT' }},

    { path: "/events", component: Audit, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Events' } },

    { path: "/customer/events", component: Audit, allowedRoles: ["ROLE_CUSTOMER_ADMIN"], initState: { title: 'Events' } },

    //Projects
    { path: "/projects", component: ProjectList, initState: { title: 'Projects' } },
    { path: "/projects/add", component: ProjectFormContainer, initState: { header: 'Create Project', title: 'Projects - Add' } },
    { path: "/projects/:id", component: ProjectDetails, initState: { title: 'Projects' }, eventMap: { entityTypes: ['Project'] , impersonationType: 'CUSTOMER' }},
    { path: "/projects/:id/edit", allowedRoles: TENANT_ADMIN, component: ProjectFormContainer, initState: { header: 'Update Project', title: 'Projects - Edit' }},
    { path: "/customer/projects/edit/:id", allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], component: ProjectFormContainer, initState: { header: 'Update Project', title: 'Projects - Edit' }},
    { path: "/search", component: Search, initState: { header: 'Search', title: 'Search' } },

    { path: "/project-templates", component: ProjectTemplateList, initState: {title : 'Project Templates'}},
    { path: "/project-fields/:type", component: FieldList, allowedRoles: TENANT_ADMIN, initState: { title: 'Fields' }, eventMap: { entityTypes: ['Field', 'FieldOption'] , impersonationType: 'TENANT' }},

    { path: "/project-template/:id", component: ProjectTemplateDetails, initState: {title : 'Project Templates Details'}},

    // Favourite list
    { path: "/productLists", component: FavouriteList, allowedRoles: TENANT_ADMIN, initState: { title: 'Product Lists' } },
    { path: "/customer/productLists", component: FavouriteList, initState: { title: 'Product Lists' } },
    { path: "/customer/productLists/:id/articles", allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], component: ProductListArticles, initState: { title: 'Product List Articles' } , eventMap: { entityTypes: ['FavouriteList'] , impersonationType: 'CUSTOMER' }},
    { path: "/productLists/:id/articles", component: ProductListArticles, allowedRoles: TENANT_ADMIN, initState: { title: 'Product List Articles' }, eventMap: { entityTypes: ['FavouriteList'] , impersonationType: 'TENANT' }},

    // Tasks
    { path: "/tasks", component: TaskList, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Tasks' } },
    { path: "/tasks/:id", component: TaskDetail, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Task Details' }, eventMap: { entityTypes: ['Task'] , impersonationType: 'TENANT' }},
    { path: "/tasks/:id/edit", component: TaskDetail, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: { title: 'Task Details' }, eventMap: { entityTypes: ['Task'] , impersonationType: 'TENANT' }},
    { path: "/customer/tasks", component: TaskList, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], initState: { title: 'Tasks' } },
    { path: "/customer/tasks/:id", component: TaskDetail, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], initState: { title: 'Task Details' }, eventMap: { entityTypes: ['Task'] , impersonationType: 'CUSTOMER' }},

    // Timeslips
    { path: "/timeslips", component: CustomerWorkLogList, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Timeslips'}},
    { path: "/timeslips/:id", component: CustomerWorkLogList, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Timeslips'}, eventMap: {entityTypes: ['Timeslip'], impersonationType: 'TENANT'}},
    { path: "/customer/timeslips", component: CustomerWorkLogList, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"], initState: {title: 'Timeslips'}, eventMap: { entityTypes: ['WorkLog'], impersonationType: 'CUSTOMER'}},
    { path: "/settings/timeslip-types", component: WorklogType, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Timeslip Types'}, eventMap: { entityTypes: ['WorkLogType']}},
    { path: "/settings/task-categories", component: TaskCategory, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Task Category'}, eventMap: { entityTypes: ['TaskCategory']}},
    { path: "/settings/task-priorities", component: TaskPriority, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Task Priority'}, eventMap: { entityTypes: ['TaskPriority']}},
    { path: "/currencies", component: CurrencyList, allowedRoles: ["ROLE_TENANT_ADMIN", "ROLE_SUPER_ADMIN", "ROLE_TENANT_USER"], initState: {title: 'Currencies'}},


    {path: "/socket-log", component: SockekAuditLog, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title : 'Socket Logs'}},
    {path: "/deep-sync", component: DeepSync, allowedRoles: ["ROLE_SUPER_ADMIN", "ROLE_TENANT_ADMIN"], initState: {title: 'Deepsync Logs'}},
    // this route should be at the end of all other routes
    {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>},
    {path: "/dashboard", component: () => <Redirect to="/customer/categories"/>, allowedRoles: ["ROLE_CUSTOMER_ADMIN", "ROLE_CUSTOMER_USER"]},
]

const authRoutes = [

    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/register", component: Register },
    { path: "/activate", component: CreatePassword },


]

export { userRoutes, authRoutes }
