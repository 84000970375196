import PropTypes from 'prop-types'
import React from "react"

import {Switch, BrowserRouter as Router, useHistory} from "react-router-dom"
import {connect} from "react-redux"

// Import Routes all
import {userRoutes, authRoutes} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

import i18n from './i18n'
import {withTranslation} from 'react-i18next'

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import ReactGA from "react-ga4";
import NotFoundPage from "./components/Error/NotFoundPage";
import Route from "react-router-dom/es/Route";


const App = props => {

    const history = useHistory();

    if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        console.log("Initializing Google Analytics");
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.send("pageview");
        /*ReactGA.set({
            userId: user.uid,
            // any data that is relevant to the user session
            // that you would like to track with google analytics
        })*/

        /*history.listen(location => {
          ReactGA.set({ page: window.location.href }); // Update the user's current page
          ReactGA.pageview(window.location.href); // Record a page-view for the given page
        });*/
    }

    function getLayout() {
        let layoutCls = VerticalLayout
        let layout = props.layout.layoutType
        let user = JSON.parse(localStorage.getItem("authUser"))
        if(window.location.pathname != '/login'){
            if(user && (!user.roles || !user.accessToken)) {
                window.location.href = '/login';
                return;
            }
            if (user && (user?.roles.includes("ROLE_CUSTOMER_ADMIN") || user?.roles.includes("ROLE_CUSTOMER_USER"))) {
                layout = 'horizontal'
            }
        }

        switch (layout) {
            case "horizontal":
                layoutCls = HorizontalLayout
                break
            default:
                layoutCls = VerticalLayout
                break
        }
        return layoutCls
    }


    const Layout = getLayout()
    return (
        <React.Fragment>
            <Router>

                <Switch>
                    {authRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            allowedRoles={[]}
                            key={idx}
                            isAuthProtected={false}
                            initState={route.initState}
                        />
                    ))}

                    {userRoutes.map((route, idx) => (
                        <Authmiddleware
                            path={route.path}
                            layout={Layout}
                            component={route.component}
                            allowedRoles={route.allowedRoles || []}
                            key={idx}
                            isAuthProtected={true}
                            exact
                            initState={route.initState}
                        />
                    ))}

                    <Route path="*">
                      <NotFoundPage/>
                    </Route>
                </Switch>

            </Router>
        </React.Fragment>
    )
}

App.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}

window.changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
}

export default connect(mapStateToProps, null)(withTranslation()(App))
