import React, { useEffect, useState } from "react";
import { Alert, Modal } from "reactstrap";
import ReactLoading from "react-loading";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {put} from "../../helpers/api_helper";
import { API_ERR_MSG, SPINNER_DEFAULT_COLOR } from "../../util/constants";
import { TENANT_ACTIVATE_USER } from "../../store/users/tenant/actionTypes";
import store from "../../store";
import useStyles from "../../util/styles";
import Loader from "./Loader";

const OverridePwdModal = props => {
    let {showOverridePwdModal, toggleModal, userId } = props;
    const classes = useStyles();
  
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null)

    useEffect(() => {
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(obj?.brandConfig);
      }
    }, []);

    // handleValidSubmit
    const handleValidSubmit = async (event, values) => {
      setLoading(true);
      put(`/api/user/overridePassword/${userId}`, values)
      .then(data => {
          if(data?.status !== 200) {
              setError(props.t(API_ERR_MSG))
          }
          wait();
          store.dispatch({type:TENANT_ACTIVATE_USER, userId: userId});
          toggleModal();
      })
      .catch(err => {
          wait();
          setError(props.t(API_ERR_MSG))
      })
    };

    const wait = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const validateEqualPassword = (value, context) => {
      return value == context.password || props.t("Passwords don't match");
    };

    return (
            <Modal
                isOpen={showOverridePwdModal}
                toggle={() => {
                    toggleModal();
                }}
                className={loading ? "low-opacity" : null}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Override Password")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            toggleModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                
                <Loader loading={loading} />
                <div className="m-2">
                        <div className="p-2">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={(e, v) => {
                              handleValidSubmit(e, v);
                            }}
                          >
                            {error && typeof error === "string" ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}

                            <div className="mb-3">
                              <AvField
                                name="password"
                                label={props.t("Password")}
                                className="form-control"
                                type="password"
                                required
                              />
                            </div>

                            <div className="mb-1">
                              <AvField
                                name="confirmPassword"
                                label={props.t("Confirm Password")}
                                type="password"
                                required
                                validate={{validateEqualPassword}}
                              />
                            </div>

                            <div className="d-flex justify-content-around">
                                <div className="modal-footer">
                                    <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light"
                                    >
                                        {props.t('Confirm')}
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            toggleModal();
                                        }}
                                        className="btn btn-secondary waves-effect"
                                        data-dismiss="modal"
                                    >
                                        {props.t('Cancel')}
                                    </button>
                                </div>
                            </div>
                          </AvForm>
                        </div>
                </div>
            </Modal>
            )

}

export default OverridePwdModal;
