import {call, put, takeEvery} from "redux-saga/effects"
import {GET_LABELS, GET_LANGUAGE_LIST, GET_PHRASES} from "./actionTypes";
import {getLabelFail, getLabelsSuccess, getLanguageListFail, getLanguageListSuccess, getPhraseError, getPhrases, getPhrasesSuccess} from "./actions";
import {getLablesList, getLanguageList, getPhraseList} from "../../helpers/fakebackend_helper";


function* fetchLanuageList() {
    try {
        const response = yield call(getLanguageList);
        yield put(getLanguageListSuccess(response))
    } catch (error) {
        yield put(getLanguageListFail(error))
    }
}

function* fetchLablesList({locale, tenantId, filter}) {
    try {
        const response = yield call(getLablesList, locale, tenantId, filter);
        yield put(getLabelsSuccess(response));
    } catch (error) {
        yield put(getLabelFail(error))
    }
}

function* fetchPhaseList() {
    try {
        const response = yield call(getPhraseList);
        yield put(getPhrasesSuccess(response))
    } catch (error) {
        yield put(getPhraseError(error))
    }
}

function* languageSaga() {
    yield takeEvery(GET_LABELS, fetchLablesList)
    yield takeEvery(GET_LANGUAGE_LIST, fetchLanuageList)
    yield takeEvery(GET_PHRASES, fetchPhaseList)
}

export default languageSaga;
