import React from "react"
import PropTypes from 'prop-types'

import {connect} from "react-redux"
import {changeLayout, changeLayoutWidth, changePreloader, changeSidebarTheme, changeSidebarType, changeTopbarTheme, showRightSidebarAction,} from "../../store/actions"

//SimpleBar
import SimpleBar from "simplebar-react"

import {Link} from "react-router-dom"

import "./rightbar.scss"
import {withTranslation} from "react-i18next";
import {equalsAny, hasModuleEnabled} from "../../util/helperFunctions";
//Import images

const RightSidebar = props => {

    const user = JSON.parse(localStorage.getItem("authUser"));

    return (
        <React.Fragment>
            <div className="right-bar">
                <SimpleBar style={{height: "900px"}}>
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title d-flex align-items-center px-3 py-4">
                            <h5 className="m-0 me-2">Settings</h5>
                            <Link
                                to="#"
                                onClick={e => {
                                    e.preventDefault()
                                    props.showRightSidebarAction(false)
                                }}
                                className="right-bar-toggle ms-auto"
                            >
                                <i className="mdi mdi-close noti-icon"/>
                            </Link>
                        </div>

                        <hr className="my-0"/>

                        <div className="p-4">
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">{props.t("Sync")}</span>
                                <ul style={{listStyle:"none"}}>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/fortnox-config">{props.t("Fortnox config")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/deep-sync">{props.t("Sync jobs")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/socket-log">{props.t("Websocket log")}</Link></li>

                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) &&
                                        <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/bulk-actions">{props.t("Manual import/export")}</Link></li>
                                    }
                            </ul>
                            </div>
                            <hr className="mt-1"/>
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">{props.t("Site settings")}</span>
                                <ul style={{listStyle: "none"}}>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/brand">{props.t("Branding")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/translations">{props.t("Languages")}</Link></li>
                                    {equalsAny(user?.roles, ["ROLE_SUPER_ADMIN"]) && <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/phrases">{props.t("Phrases")}</Link></li>}
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/date-config">{props.t("Date/Time")}</Link></li>
                                    {equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) &&
                                        <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/default-currency-config">{props.t("Currencies")}</Link></li>
                                    }
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/smtp-config">{props.t("Email")}</Link></li>
                                </ul>
                            </div>
                            {hasModuleEnabled (user?.tenantFeatures, "ARTICLES") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) &&  <>
                            <hr className="mt-1"/>
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">{props.t("Articles")}</span>
                                <ul style={{listStyle: "none"}}>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/stock">{props.t("Settings")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/fields/article">{props.t("Fields")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/tabs">{props.t("Tabs")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/templates">{props.t("Templates")}</Link></li>
                                </ul>
                            </div></>}
                            {hasModuleEnabled (user?.tenantFeatures, "PROJECTS") && equalsAny(user?.roles, ["ROLE_TENANT_ADMIN", "ROLE_TENANT_USER"]) && <>
                            <hr className="mt-1"/>
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">{props.t("Projects")}</span>
                                <ul style={{listStyle: "none"}}>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/project-config">{props.t("Settings")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/project-fields/project">{props.t("Fields")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/project-templates">{props.t("Templates")}</Link></li>
                                </ul>
                            </div>
                            </>}

                            {hasModuleEnabled (user?.tenantFeatures, "TASKS") &&  <>
                            <hr className="mt-1"/>
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">Tasks</span>
                                <ul style={{listStyle: "none"}}>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/task-categories">{props.t("Categories")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/task-priorities">{props.t("Priorities")}</Link></li>
                                    <li><Link onClick={e => props.showRightSidebarAction(!props.showRightSidebar)} to="/settings/timeslip-types">{props.t("Timeslip Types")}</Link></li>
                                </ul>
                            </div>
                            </>}
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={e => props.showRightSidebarAction(false)}/>
        </React.Fragment>
    )
}

RightSidebar.propTypes = {
    changeLayout: PropTypes.func,
    changeLayoutWidth: PropTypes.func,
    changePreloader: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    isPreloader: PropTypes.any,
    layoutType: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    topbarTheme: PropTypes.any
}

const mapStateToProps = state => {
    return {...state.Layout}
}

export default connect(mapStateToProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changeTopbarTheme,
    changePreloader,
    showRightSidebarAction,
})(withTranslation()(RightSidebar))
