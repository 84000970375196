import {call, put, takeEvery} from "redux-saga/effects";
import {GET_TASK, GET_TASK_BY_PROJECT, GET_TASK_CATEGORIES, GET_TASK_COMMENT_LIST, GET_TASK_LIST, GET_TASK_WORK_LOG, GET_WORK_LOG_FOR_CUSTOMER, GET_WORK_LOG_FOR_PROJECT, GET_WORK_LOG, GET_WORK_LOG_TYPE, GET_TASK_PRIORITES, GET_WORK_LOG_FOR_SUPERADMIN, GET_WORK_LOG_FOR_USER, GET_DEFAULT_TIMESLIP_TYPE} from "./actionTypes";
import {
    getTaskByProjectError,
    getTaskByProjectSuccess,
    getTaskCategoriesError,
    getTaskCategoriesSuccess,
    getTaskCommentListFail,
    getTaskCommentListSuccess,
    getTaskFail,
    getTaskListFail,
    getTaskListSuccess,
    getTaskPrioritiesError,
    getTaskPrioritiesSuccess,
    getTaskSuccess,
    getTaskWorkLogFail,
    getTaskWorkLogSuccess,
    getWorkLogByProjectError,
    getWorkLogByProjectSuccess,
    getWorkLogForCustomerFail,
    getWorkLogForCustomerSuccess,
    getWorkLogForSuperAdminError,
    getWorkLogforSuperAdminSuccess,
    getWorkLogError,
    getWorkLogSuccess,
    getWorkLogTypeError,
    getWorkLogTypeSuccess,
    getWorkLogForUserSuccess,
    getWorkLogForUserFail,
    getDefaultTimeslipTypeSuccess,
    getDefaultTimeslipTypeFail,
} from "./actions";
import {getTaskList, getTaskCommentList, getTask, getTaskWorkLog, getWorkLogType, getWorkLogForCustomer, getTaskByProject, getWorkLogByProject, getWorkLog, getTaskCategories, getTaskPriorities, getWorkLogForSuperAdmin, getWorkLogForUser, getDefaultTimeslipType} from "../../helpers/fakebackend_helper";
import {API_ERR_MSG} from "../../util/constants";

function* fetchTaskList({filter}) {
    try {
        const response = yield call(getTaskList, filter);
        yield put(getTaskListSuccess(response));
    } catch (error) {
        yield put(getTaskListFail(API_ERR_MSG));
    }
}

function* fetchTask({taskId}) {
    try {
        const response = yield call(getTask, taskId);
        yield put(getTaskSuccess(response));
    } catch (error) {
        yield put(getTaskFail(API_ERR_MSG));
    }
}

function* fetchTaskCommentList({taskId}) {
    try {
        const response = yield call(getTaskCommentList, taskId);
        yield put(getTaskCommentListSuccess(response));
    } catch (error) {
        yield put(getTaskCommentListFail(API_ERR_MSG));
    }
}

function* fetchTaskWorkLog({taskId}) {
    try {
        const response = yield call(getTaskWorkLog, taskId);
        yield put(getTaskWorkLogSuccess(response));
    } catch (error) {
        yield put(getTaskWorkLogFail(error));
    }
}

function* fetchWorkLogType() {
    try {
        const response = yield call(getWorkLogType);
        yield put(getWorkLogTypeSuccess(response));
    } catch (error) {
        yield put(getWorkLogTypeError(API_ERR_MSG))
    }
}

function* fetchTaskCategories() {
    try {
        const response = yield call(getTaskCategories);
        yield put(getTaskCategoriesSuccess(response));
    } catch (error) {
        yield put(getTaskCategoriesError(API_ERR_MSG))
    }
}

function* fetchWorkLogForCustomer({customerId}) {
    try {
        const response = yield call(getWorkLogForCustomer, customerId)
        yield put(getWorkLogForCustomerSuccess(response))
    } catch (error) {
        yield put(getWorkLogForCustomerFail(error))
    }
}

function* fetchTaskByProject({projectId}) {
    try {
        const response = yield call(getTaskByProject, projectId);
        yield put(getTaskByProjectSuccess(response));
    } catch (error) {
        yield put(getTaskByProjectError(error))
    }
}

function* fetchWorkLogByProject({projectId}) {
    try {
        const response = yield call(getWorkLogByProject, projectId);
        yield put(getWorkLogByProjectSuccess(response));
    } catch (error) {
        yield put(getWorkLogByProjectError(error))
    }
}

function* fetchWorkLog({filter}) {
    try {
        const response = yield call(getWorkLog, filter);
        yield put(getWorkLogSuccess(response))
    } catch (error) {
        yield put(getWorkLogError(error))
    }
}

function* fetchWorkLogForSuperAdmin() {
    try {
        const response = yield call(getWorkLogForSuperAdmin);
        yield put(getWorkLogforSuperAdminSuccess(response))
    } catch (error) {
        yield put(getWorkLogForSuperAdminError(API_ERR_MSG))
    }
}

function* fetchTaskPriorities() {
    try {
        const response = yield call(getTaskPriorities);
        yield put(getTaskPrioritiesSuccess(response));
    } catch (error) {
        yield put(getTaskPrioritiesError(API_ERR_MSG))
    }
}

function* fetchWorkLogForUser({userId}) {
    try {
        const response = yield call(getWorkLogForUser, userId)
        yield put(getWorkLogForUserSuccess(response))
    } catch (error) {
        yield put(getWorkLogForUserFail(error))
    }
}

function* fetchDefaultTimeslipType() {
    try {
        const response = yield call(getDefaultTimeslipType)
        yield put(getDefaultTimeslipTypeSuccess(response))
    } catch (error) {
        yield put(getDefaultTimeslipTypeFail(error))
    }
}

function* tasksSaga() {
    yield takeEvery(GET_TASK_LIST, fetchTaskList);
    yield takeEvery(GET_TASK, fetchTask);
    yield takeEvery(GET_TASK_COMMENT_LIST, fetchTaskCommentList);
    yield takeEvery(GET_TASK_WORK_LOG, fetchTaskWorkLog);
    yield takeEvery(GET_WORK_LOG_TYPE, fetchWorkLogType);
    yield takeEvery(GET_TASK_CATEGORIES, fetchTaskCategories);
    yield takeEvery(GET_WORK_LOG_FOR_CUSTOMER, fetchWorkLogForCustomer)
    yield takeEvery(GET_TASK_BY_PROJECT, fetchTaskByProject)
    yield takeEvery(GET_WORK_LOG_FOR_PROJECT, fetchWorkLogByProject)
    yield takeEvery(GET_WORK_LOG, fetchWorkLog)
    yield takeEvery(GET_WORK_LOG_FOR_SUPERADMIN, fetchWorkLogForSuperAdmin)
    yield takeEvery(GET_TASK_PRIORITES, fetchTaskPriorities)
    yield takeEvery(GET_WORK_LOG_FOR_USER, fetchWorkLogForUser)
    yield takeEvery(GET_DEFAULT_TIMESLIP_TYPE, fetchDefaultTimeslipType)
}

export default tasksSaga;
