import PropTypes from 'prop-types'
import React, {useEffect, useState} from "react"
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap"

// Redux
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

// availity-reactstrap-validation
import {AvField, AvForm} from "availity-reactstrap-validation"

// action
import {userForgetPassword} from "../../store/actions"

// import images
import logo from "../../assets/images/lightab_logo.svg"
import logolight from "../../assets/images/lightab_logo.svg"
import {withTranslation} from "react-i18next";
import Loader from '../../components/Common/Loader';
import {LOGO_URL} from "../../helpers/url_helper";

const ForgetPasswordPage = props => {

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history)
  }

  return (
    <React.Fragment>
      <div className="account-pages">
        <Container className="align-center">
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" height="40" className="logo logo-dark" />
                  <img src={`${LOGO_URL}?domain=${window.location.hostname}`} alt="" height="40" className="logo logo-light" />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{props.t("Reset Password")}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <div className="alert alert-info text-center mb-4" role="alert">
                      {props.t("Enter your Email and instructions will be sent to you!")}
                                      </div>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                            name="domain"
                            type="hidden"
                            value={window.location.hostname}
                            required
                        />
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder={props.t("Enter email")}
                          type="email"
                          required
                        />
                      </div>
                      <Row className="row mb-3">
                        <Col className="col-12 text-end">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {props.t("Reset")}
                        </button>
                        </Col>
                      </Row>
                      <Loader loading={props.loading} />
                      {!(props.loading) && props.forgetError && props.forgetError ? (
                        <Alert color="danger" className="text-center mb-3" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {!(props.loading) && props.forgetSuccessMsg ? (
                        <Alert color="success" className="text-center mb-3" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}
                      <div className="mt-3 text-center">
                        <p className="mb-0">{props.t("Remember It ?")} <Link to="/login" className="fw-medium text-primary"> {props.t("Signin")} </Link></p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} {props.t("Flowsense")}. <a href="http://flowsense.se/" target="_blank">{props.t("Design & Develop by Flowsense")}</a>
                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg, forgetPasswordLoading } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg, loading: forgetPasswordLoading }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(withRouter(withTranslation()(ForgetPasswordPage)))
)
