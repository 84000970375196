import {
    GET_SMTP_CONFIG_FAIL,
    GET_SMTP_CONFIG_RESET,
    GET_SMTP_CONFIG_SUCCESS,
    GET_SYSTEM_CONFIG_FAIL,
    GET_SYSTEM_CONFIG_RESET,
    GET_SYSTEM_CONFIG_SUCCESS,
    GET_TENANT_CONFIG_FAIL,
    GET_TENANT_CONFIG_RESET,
    GET_TENANT_CONFIG_SUCCESS,
    GET_ARTICLE_CONFIG_FAIL,
    GET_ARTICLE_CONFIG_RESET,
    GET_ARTICLE_CONFIG_SUCCESS,
    GET_STOCK_POINT_CONFIG_SUCCESS,
    GET_STOCK_POINT_CONFIG_FAIL,
    GET_STOCK_POINT_CONFIG_RESET,
    GET_STOCK_POINTS_SUCCESS,
    GET_STOCK_POINTS_FAIL,
    GET_STOCK_POINTS_RESET,
    GET_CRON_LOG_SUCCESS, GET_CRON_LOG_FAIL, GET_CRON_LOG, GET_CURRENCY_LIST, GET_CURRENCY_LIST_SUCCESS, GET_CURRENCY_LIST_FAIL, GET_CURRENCY_LIST_RESET, GET_PROJECT_CONFIG, GET_PROJECT_CONFIG_RESET, GET_PROJECT_CONFIG_FAIL, GET_PROJECT_CONFIG_SUCCESS
} from "./actionTypes";


const INIT_STATE = {
    systemConfig: [],
    smtpConfig: [],
    articleConfig: {},
    stockPointConfig: {},
    stockPoints: [],
    systemConfigError: null,
    tenantConfigError: null,
    smtpConfigError: null,
    articleConfigError: null,
    stockPointConfigError: null,
    stockPointsError: null,
    cronLog: [],
    cronLogLoading: false,
    currencyList: [],
    currencyListLoading: false,
    currencyListError: null,
    projectConfig: null,
    projectConfigError: null,
    projectConfigLoading: false,
};

const Config = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SYSTEM_CONFIG_SUCCESS:
            return {
                ...state,
                systemConfig: action.payload,
                systemConfigError: null,
            }
        case GET_SYSTEM_CONFIG_FAIL:
            return {
                ...state,
                systemConfigError: action.payload,
            }
        case GET_SYSTEM_CONFIG_RESET:
            return {
                ...state,
                systemConfigError: null,
                systemConfig: []
            }
        case GET_TENANT_CONFIG_SUCCESS:
            return {
                ...state,
                tenantConfig: action.payload,
                tenantConfigError: null,
            }
        case GET_TENANT_CONFIG_FAIL:
            return {
                ...state,
                tenantConfigError: action.payload
            }
        case GET_TENANT_CONFIG_RESET:
            return {
                ...state,
                tenantConfigError: null,
                tenantConfig: [],
            }
        case GET_SMTP_CONFIG_SUCCESS:
            return {
                ...state,
                smtpConfig: action.payload,
                smtpConfigError: null,
            }
        case GET_SMTP_CONFIG_FAIL:
            return {
                ...state,
                smtpConfigError: action.payload
            }
        case GET_SMTP_CONFIG_RESET:
            return {
                ...state,
                smtpConfigError: null,
                smtpConfig: [],
            }
        case GET_ARTICLE_CONFIG_SUCCESS:
            return {
                ...state,
                articleConfig: action.payload,
                articleConfigError: null,
            }
        case GET_ARTICLE_CONFIG_FAIL:
            return {
                ...state,
                articleConfigError: action.payload
            }
        case GET_ARTICLE_CONFIG_RESET:
            return {
                ...state,
                articleConfigError: null,
                articleConfig: [],
            }
        case GET_STOCK_POINT_CONFIG_SUCCESS:
            return {
                ...state,
                stockPointConfig: action.payload,
                stockPointConfigError: null,
            }
        case GET_STOCK_POINT_CONFIG_FAIL:
            return {
                ...state,
                stockPointConfigError: action.payload
            }
        case GET_STOCK_POINT_CONFIG_RESET:
            return {
                ...state,
                stockPointConfigError: null,
                stockPointConfig: [],
            }
        case GET_STOCK_POINTS_SUCCESS:
            return {
                ...state,
                stockPoints: action.payload,
                stockPointsError: null,
            }
        case GET_STOCK_POINTS_FAIL:
            return {
                ...state,
                stockPointsError: action.payload
            }
        case GET_STOCK_POINTS_RESET:
            return {
                ...state,
                stockPointsError: null,
                stockPoints: [],
            }
        case GET_CRON_LOG:
            return {
                ...state,
                cronLogLoading: true,
                cronLog: []
            }
        case GET_CRON_LOG_SUCCESS:
            return {
                ...state,
                cronLogLoading: false,
                cronLog: action.payload
            }
        case GET_CRON_LOG_FAIL:
            return {
                ...state,
                cronLogLoading: false,
                cronLog: []
            }
        case GET_CURRENCY_LIST:
            return {
                ...state,
                currencyListLoading: true,
                stockPointsError: null,
            }
        case GET_CURRENCY_LIST_SUCCESS:
            return {
                ...state,
                currencyList: action.payload,
                stockPointsError: null,
                currencyListLoading: false,
            }
        case GET_CURRENCY_LIST_FAIL:
            return {
                ...state,
                stockPointsError: action.payload,
                currencyListLoading: false,
            }
        case GET_CURRENCY_LIST_RESET:
            return {
                ...state,
                stockPointsError: null,
                currencyList: [],
            }
        case GET_PROJECT_CONFIG:
            return {
                ...state,
                projectConfigLoading: true,
                projectConfigError: null,
                projectConfig: null,
            }
        case GET_PROJECT_CONFIG_SUCCESS:
            return {
                ...state,
                projectConfigLoading: false,
                projectConfig: action.payload,
                projectConfigError: null,
            }
        case GET_PROJECT_CONFIG_FAIL:
            return {
                ...state,
                projectConfigError: action.payload,
                projectConfigLoading: false,
                projectConfig: null,
            }
        case GET_PROJECT_CONFIG_RESET:
            return {
                ...state,
                projectConfigError: null,
                projectConfig: [],
                projectConfigLoading: false,
            }
        default:
            return state;
    }
};

export default Config
