import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {getInvoices, getInvoicesReset} from "../../store/actions";
import {connect} from "react-redux";
import {MDBDataTable} from "mdbreact";
import InvoiceStatus from "../Invoice/InvoiceStatus";
import {downloadPDF} from "../../util/helperFunctions";
import {checkIfLoading} from "../../store/selector";
import {invoicesActionTypes} from "../../store/invoices/actionTypes";
import {Alert, Col, Row} from "reactstrap";
import ButtonGroup from "../../components/Common/ButtonGroup";
import Loader from "../../components/Common/Loader";
import {orderBy} from "lodash";
import {formatCurrency} from "../../helpers/currency_helper";


const CustomerInvoiceTable = (props) => {
    let {
        invoices, loading, error, 
        onGetInvoices, onGetInvoicesReset, customerId, ourReference, yourReference, articleNumber, projectNumber, articleGroupId, manufacturer,
    } = props;

    const [invoiceList, setInvoiceList] = useState([])
    const [brandSettings, setBrandSettings] = useState(null)
    const [invoiceType, setInvoiceType] = useState('all')
    const [sortState, setSortState] = useState(null);
    const numericColumns = ['DocumentNumber', 'Total'];

    let user = JSON.parse(localStorage.getItem("authUser"));

    const history = useHistory()

    useEffect(() => {
        setBrandSettings(user?.brandConfig);
        if (customerId || articleNumber || projectNumber || articleGroupId || manufacturer || ourReference || yourReference) {
            //onGetInvoices(invoiceType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
        }
        return () => {
            onGetInvoicesReset();
        }
    }, [])

    useEffect(() => {
        if (invoices) {
            setInvoiceList(invoices);
        }
    }, [invoices])

    useEffect(() => {
      if(sortState){
        const {column, direction} = sortState;
        setInvoiceList(orderBy(invoiceList, o => numericColumns.includes(column) ? Number(o[column]) : o[column]?.toLowerCase(), [direction]))
      }
    }, [sortState])

    useEffect(() => {
      if(invoiceType || customerId || articleNumber || projectNumber || articleGroupId || manufacturer || ourReference || yourReference){
          onGetInvoices(invoiceType, customerId, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)
      }
    }, [invoiceType])

    const getInvoiceDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Invoice Number"),
                    field: "DocumentNumber",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Amount"),
                    field: "Total",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Invoice Date"),
                    field: "InvoiceDate",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Final Pay Date"),
                    field: "FinalPayDate",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Status"),
                    field: "status",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: props.t("Download"),
                  field: "download",
                }
            ],
            rows: invoiceList?.map((invoice, key) => ({
                DocumentNumber: <div onClick={() => openInvoiceDetails(invoice)}>{invoice.DocumentNumber}</div>,
                Total: <div onClick={() => openInvoiceDetails(invoice)}>{formatCurrency(invoice?.Currency, invoice.Total)}</div>,
                InvoiceDate: <div onClick={() => openInvoiceDetails(invoice)}>{invoice.InvoiceDate}</div>,
                FinalPayDate: <div onClick={() => openInvoiceDetails(invoice)}>{invoice.FinalPayDate}</div>,
                status: <div onClick={() => openInvoiceDetails(invoice)}><InvoiceStatus status={invoice.status}/></div>,
                download: <div onClick={() => downloadPDF(invoice.DocumentNumber, 'invoices')}><i className="fa fa-download font-size-15"></i></div>
            }))
        }
        return data;
    }

    const openInvoiceDetails = (invoice) => {
        history.push(`/invoice/${invoice.DocumentNumber}?from=` + encodeURIComponent(props.location.pathname))
    }

    const handleFilter = (value) => {
      setInvoiceType(value)
    }

    return (<React.Fragment>
        <React.Fragment>
            {error &&
            <Alert className={"mt-4"} color="danger" role="alert">
                {error}
            </Alert>
            }
            <Row className="mb-3">
                <Col className="col-12">
                    <div className="d-flex justify-content-end">
                        <ButtonGroup 
                            filter={{
                                selected: invoiceType,
                                options : [
                                    {name: props.t("All"), value: "all"},
                                    {name: props.t("Unpaid"), value: "unpaid"},
                                    {name: props.t("Overdue"), value: "unpaidoverdue"},
                                    {name: props.t("Fully Paid"), value: "fullypaid"},
                                    {name: props.t("Unbooked"), value: "unbooked"},
                                    {name: props.t("Cancelled"), value: "cancelled"}
                                ],
                                onSelect: handleFilter
                            }}
                            brandSettings={brandSettings}     
                        />
                    </div>
                </Col>
            </Row>
            
            <Loader loading={loading} title="Invoices" />
                    
            {!loading && 
            <Row>
                <Col className="col-12 article-table">
                    <MDBDataTable
                        hover
                        responsive
                        barReverse={true}
                        borderless
                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                        entriesLabel={props.t("Show entries")}
                        infoLabel={[props.t('Showing'), props.t('to'), props.t('of'), props.t('entries')]}
                        searchLabel={props.t("Search") + "..."}
                        noBottomColumns={true}
                        data={getInvoiceDatatableData()}
                        entries={50}
                        entriesOptions={[50, 100, 500]}
                        noRecordsFoundLabel={props.t("No matching records found")}
                        sortRows={[]}
                        onSort={({column, direction}) => {
                            if(sortState && sortState.column == column){
                                setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                            } else {
                                setSortState({column, direction});
                            }
                        }}
                    />
                </Col>
            </Row>}
        </React.Fragment>
    </React.Fragment>)

}


CustomerInvoiceTable.propTypes = {
    invoices: PropTypes.array,
    onGetInvoices: PropTypes.func,
    onGetInvoicesReset: PropTypes.func
}

const mapStateToProps = state => {
    return {
        invoices: state.invoices.invoices?.Invoices,
        loading: checkIfLoading(state, invoicesActionTypes.GET_INVOICES),
        error: state.invoices.error
    }
}

const mapDispatchToProps = dispatch => ({
    onGetInvoices: (invoiceType, id, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference) => dispatch(getInvoices(invoiceType, id, null, null, articleNumber, projectNumber, articleGroupId, manufacturer, ourReference, yourReference)),
    onGetInvoicesReset: () => dispatch(getInvoicesReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerInvoiceTable)));
