import {getPriceListForTenant, getProducts} from "../../store/e-commerce/actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Card, CardBody, Col, Row} from "mdbreact";
import {Alert, Button, Input, Label, Modal} from "reactstrap";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {find} from "lodash";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import {preventNumberInputScroll} from "../../util/helperFunctions";


const EditArticlePackage = (props) => {
    const {
        article,
        products,
        type,
        onGetProducts,
        handleAddEditPackage,
        handleDeleteArticlePackage,
        priceList,
        onGetPriceList
    } = props;

    const [packageName, setPackageName] = useState();
    const [packageType, setPackageType] = useState();
    const [packageQuantity, setPackageQuantity] = useState();
    const [priceListValue, setPricelistValue] = useState([]);
    const [packageItems, setPackageItems] = useState([]);
    const [packageList, setPackageList] = useState([]);
    const [editPackageId, setEditPackageId] = useState(null);
    const [error, setError] = useState(null);
    const [modal_confirm, setmodal_confirm] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);

    let user = JSON.parse(localStorage.getItem("authUser"));

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
    }

    function tog_confirm() {
        setmodal_confirm(!modal_confirm);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    useEffect(() => {
        setBrandSettings(user.brandConfig);
        onGetProducts();
        onGetPriceList();
    }, [])

    useEffect(() => {
        setPackageType(type);
    }, [type])

    useEffect(() => {
        if(article?.articlePackage){
            setPackageList(article?.articlePackage);
        }
    }, [article])

    useEffect(() => {
        setTimeout( () => {setError("")}, 3000);
    }, [error])

    const updateArticleFromPackage = (value, index) => {
        let articleList = packageItems;
        let article = products?.find(a => a.id === value?.value);
        articleList[index].articleDto = article;
        setPackageItems([...articleList]);
    }

    const addArticleToPackage = () => {
        let articleList = packageItems;
        let item = {
            quantity : "",
            articleDto: null,
            operator : "",
            displayOrder: articleList?.length + 1
        }
        articleList.push(item);
        setPackageItems([...articleList]);
    }

    const removeArticleFromPackage = (index) => {
        //let item = packageItems.find(a => a.articleDto.id === value.articleDto.id);
        let list = packageItems;
        list.splice(index, 1);
        setPackageItems([...list]);
    }

    const getArticlesDropdown = () => {
        let list = [{value: "", label: "Select article"}]
        products.map(value => list.push({value: value.id, label: value?.displayName ? value.articleNumber + "-" + value.displayName : value.articleNumber + "-" + value.description}))
        return list;
    }

    const clearPackage = () => {
        setPackageName("");
        setPackageQuantity("");
        setPackageItems([]);
        setEditPackageId(null);

    }

    const addPackage = (isClose) => {
        setError("");
        for(let i = 0; i < packageList.length; i++){
            if(editPackageId && editPackageId != packageList[i]?.id && packageList[i].type == packageType && packageList[i].name.toUpperCase()  == packageName.toUpperCase() ){
                setError(props.t("Package already exist."));
                return
            }else if(!editPackageId &&packageType != "DYNAMIC" && packageList[i].type == packageType && packageList[i].name.toUpperCase()  == packageName.toUpperCase() ){
                setError(props.t("Package already exist."));
                return;
            }
        }
        let item = {
            id: editPackageId,
            name: packageName,
            type: packageType,
            packageItems: packageItems,
            quantity: packageQuantity,
            articlePackagePriceLists: priceListValue
        }
        handleAddEditPackage(item);
        if(!editPackageId){
            let p = packageList;
            p.push(item)
            setPackageList([...p]);
        } else {
          let p = packageList;
          let selectedItem = find(p , {id : editPackageId})
          selectedItem.name = packageName;
          selectedItem.type=packageType;
          selectedItem.packageItems=packageItems;
          selectedItem.quantity=packageQuantity;
          selectedItem.articlePackagePriceLists=priceListValue
          setPackageList([...p]);
        }
        if(isClose){
            tog_confirm();
        }
        clearPackage();
    }

    const setProductQuantity = (item, value) => {
        item.quantity = value;
    }

    const setOperator = (item, value) => {
        item.operator = value;
    }

    const removePackage = (item) => {
        let list = packageList;
        let article = list.find(p => p.id == item.id);
        list.splice(list.indexOf(article), 1);
        setPackageList([...list]);
        handleDeleteArticlePackage(item);
    }

    const editPackage = (index) => {
        let item = packageList[index];
        setEditPackageId(item?.id);
        setPackageName(item?.name);
        setPackageType(item?.type);
        setPackageItems(item?.packageItems);
        setPackageQuantity(item?.quantity);
        setPricelistValue(item?.articlePackagePriceLists)
        tog_confirm()
    }

    const handleDuplicate = (index) => {
        let item = packageList[index];
        setEditPackageId(null);
        setPackageName("");
        setPackageType(item?.type);
        let packageItems = [];
        item?.packageItems.map(obj => {
            obj.id = null;
            packageItems.push(obj)
        })
        setPackageItems(packageItems);
        setPackageQuantity(null);
        tog_confirm()
    }

    const addPackageModal = () => {
        clearPackage();
        let list  = [];
        list.push({
            quantity : "",
            articleDto: null,
            operator : "",
            displayOrder: 1
        });
        setPackageItems(list)
        tog_confirm()
    }

    const getProducts = () => {
        let list = [];
        products?.map(p => {
            if(p.id != article.id && !p.articlePackageType){
                list.push(p);
            }
        })
        return list;
    }

    const handleOrder = (type, item, index) => {
        let list = packageItems;
        if(item) {
            let position = item.displayOrder;
            let siblingIndex = type === "UP" ? index - 1 : index + 1;
            if(siblingIndex < list.length && siblingIndex >= 0) {
                list[index].displayOrder = list[siblingIndex].displayOrder;
                list[siblingIndex].displayOrder = position;
            }
        }
        list.sort((a, b) => {
            if (a.displayOrder === b.displayOrder) return 0;
            return a.displayOrder > b.displayOrder ? 1 : -1;
        });
        setPackageItems([...list])
    }

    const handlePriceListValueChange = (priceList, value) => {
        for(let i = 0; i < priceListValue.length; i++){
            if(priceListValue[i].priceListCode == priceList?.code){
                priceListValue[i].price = value;
                return;
            }
        }
        let list = {priceList : {description: priceList?.description}, priceListCode : priceList?.code, price : value}
        priceListValue.push(list);
        setPricelistValue([...priceListValue]);
    }

    const getPriceListValue = (priceList) => {
        let list = priceListValue.find( p => p.priceListCode == priceList?.code)
        if(list){
            return list.price;
        }
        return ""
    }

    return (
        <>
            {(type === "MANUAL" || (type === "DYNAMIC" && packageList.length == 0)) &&
                <>
                    <button
                        type="button"
                        onClick={addPackageModal}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="btn btn-success w-lg waves-effect waves-light mb-3">
                        {props.t("Add Package")}
                    </button>
                </>
            }

            {packageList?.map(function (item, index) {
                return (<Card>
                    <CardBody>
                        <Row>
                            <Col lg={8}>
                                <h4>{item?.name}</h4>
                                <p>{props.t(item?.type)}</p>
                                {/*{item?.type == "MANUAL" && <p>{props.t("Quantity")} : {item?.quantity}</p>}*/}
                                {(item?.type == "MANUAL" && item?.articlePackagePriceLists && item?.articlePackagePriceLists.length > 0) && <>
                                    <p>{props.t("Price List")}</p>
                                    <ol>
                                    {item?.articlePackagePriceLists.map(function(item){
                                        return (<li>{item?.priceList?.description} : {item?.price}</li>)
                                    })}
                                    </ol>
                                </>}
                                {item?.type == "DYNAMIC" && <p>{props.t("Formula")} : {item?.operator} {item?.quantity}</p>}

                                <h5>{props.t("Articles")}</h5>

                                {item?.type == "MANUAL" &&
                                    <ol style={{marginLeft: "40px"}}>
                                        {item?.packageItems.map(function (i) {
                                            return (<li><label style={{width: "500px"}}>{i?.articleDto?.displayName ? i?.articleDto?.displayName : i?.articleDto?.description}</label> {i?.quantity ? <label>{props.t("Qty")} - {i?.quantity}</label> : ''}</li>)
                                        })}
                                    </ol>
                                }
                                {item?.type == "DYNAMIC" &&
                                    <ol style={{marginLeft: "40px"}}>
                                        {item?.packageItems.map(function (i) {
                                            return (<li><label style={{width: "500px"}}> {i?.articleDto?.displayName ? i?.articleDto?.displayName : i?.articleDto?.description}</label> <label>{props.t("Formula")} - {i?.operator} {i?.quantity}</label></li>)
                                        })}
                                    </ol>
                                }
                            </Col>
                            <Col lg={4}>
                                <Button
                                    type="button"
                                    onClick={() => editPackage(index)}
                                    color=""
                                    className="btn-block waves-effect waves-light btn-sm">
                                    <i className="uil uil-pen font-size-16 cursor-pointer"></i>
                                </Button>
                                {item?.type == "MANUAL" &&
                                <DuplicateIcon
                                    style={{ height: "20px" }}
                                    onClick={() => handleDuplicate(index)}
                                />}
                                <Button
                                    type="button"
                                    onClick={() => removePackage(item)}
                                    color=""
                                    className="btn-block waves-effect waves-light btn-sm">
                                    <i className="uil uil-trash-alt text-danger font-size-16 cursor-pointer"></i>
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>)
            })}

            <Modal
                isOpen={modal_confirm}
                backdrop="static"
                toggle={() => {
                    tog_confirm();
                }}
                className="add-order-modal"
            >
                <div className="modal-header">
                    <h4 className="modal-title">
                        {editPackageId && <> {props.t("Edit Package")}</>}
                        {!editPackageId && <> {props.t("Add Package")}</>}
                    </h4>
                    <button
                        type="button"
                        onClick={() => {
                            tog_confirm();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Card>
                        <CardBody>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label>
                                        {props.t("Name")}
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => setPackageName(e.target.value)}
                                        value={packageName}
                                    />
                                </div>
                            </Col>
                            {packageType == "MANUAL" && <Col lg="12">
                                {/*<div className="mb-3">
                                    <Label>
                                        {props.t("Quantity")}
                                    </Label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        value={packageQuantity} 
                                        placeholder={props.t("Enter quantity")} 
                                        onChange={(e) => setPackageQuantity(e.target.value)}
                                        onWheel={preventNumberInputScroll}
                                        // onKeyDown={validateNumber}
                                    />
                                </div>*/}
                                {priceList?.map(item => <>
                                    <div className="mb-3">
                                        <Label>
                                            {item?.description ? item?.description : item?.code}
                                        </Label>
                                        <input 
                                            type="number" 
                                            className="form-control" 
                                            defaultValue={getPriceListValue(item)} 
                                            placeholder={props.t("Enter price")} 
                                            onChange={(e) => handlePriceListValueChange(item, e.target.value)}
                                            onWheel={preventNumberInputScroll}
                                            // onKeyDown={validateNumber}
                                        />
                                    </div>
                                </>)}
                            </Col>}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Row className="mt-4">
                                <Col lg="6">
                                    <label>{props.t("Articles")}</label>
                                </Col>
                                <Col lg="3">
                                    <label>{props.t("Quantity")}</label>
                                </Col>
                                <Col lg="1">
                                    <label>{props.t("Remove")}</label>
                                </Col>
                                <Col lg="2">
                                    <label>{props.t("Move")}</label>
                                </Col>
                            </Row>
                            <Row>
                            {packageItems?.map(function (item, index) {
                                return (<Row className="mb-3">
                                            <Col lg="6">
                                                <Select
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(vals) => {
                                                        updateArticleFromPackage(vals, index);
                                                    }}
                                                    value={item?.articleDto ? {value : item?.articleDto.id, label : item?.articleDto?.displayName ? item?.articleDto?.articleNumber + " | " + item?.articleDto?.displayName : item?.articleDto?.articleNumber + " | " +  item?.articleDto?.description} : {}}
                                                    options={getProducts()?.map(product => ({value: product.id, label: product.displayName ? product?.articleNumber + " | " + product.displayName : product?.articleNumber + " | " + product.description}))}/>
                                            </Col>
                                            <Col lg="3">
                                                {packageType == "MANUAL" &&
                                                    <input 
                                                        type="number" 
                                                        className="form-control" 
                                                        defaultValue={item?.quantity} 
                                                        placeholder={props.t("Enter quantity")}
                                                        onChange={(e) => setProductQuantity(item, e.target.value)}
                                                        onWheel={preventNumberInputScroll}
                                                        // onKeyDown={validateNumber}
                                                    />
                                                }
                                                {packageType == "DYNAMIC" && <Row>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-control"
                                                            onChange={e => setOperator(item, e.target.value)}
                                                            defaultValue={item?.operator}>
                                                            <option value="">{props.t("Select operator")}</option>
                                                            <option value="x">{props.t("x")}</option>
                                                            <option value="/">{props.t("/")}</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex">
                                                            <p>
                                                                <input 
                                                                    type="number" 
                                                                    className="form-control" 
                                                                    defaultValue={item?.quantity} 
                                                                    placeholder={props.t("Enter quantity")}
                                                                    onChange={(e) => setProductQuantity(item, e.target.value)}
                                                                    onWheel={preventNumberInputScroll}
                                                                    // onKeyDown={validateNumber}
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Row>}
                                            </Col>
                                            <Col lg="1">
                                                <Button
                                                    type="button"
                                                    color=""
                                                    disabled={packageItems?.length == 1}
                                                    onClick={() => removeArticleFromPackage(index)}
                                                    className="btn-block waves-effect waves-light">
                                                    <i className="uil uil-trash-alt text-danger font-size-16 cursor-pointer"></i>
                                                </Button>
                                            </Col>
                                            <Col lg="2" className="justify-content-center">
                                                <div className="me-3" style={{paddingTop: '2.5%'}}>
                                                    <Button
                                                        type="button"
                                                        color=""
                                                        disabled={packageItems?.length == 1}
                                                        onClick={() => handleOrder("UP", item, index)}
                                                        className="btn-block waves-effect waves-light">
                                                        <i className="uil uil-arrow-circle-up text-primary font-size-18"></i>
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color=""
                                                        disabled={packageItems?.length == 1}
                                                        onClick={() => handleOrder("DOWN", item, index)}
                                                        className="btn-block waves-effect waves-light">
                                                        <i className="uil uil-arrow-circle-down mx-3 text-primary font-size-18"></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                    </Row>)
                            })}
                            </Row>
                            <Col lg="12">
                                <button
                                    type="button"
                                    onClick={addArticleToPackage}
                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                    className="btn btn-success w-lg waves-effect waves-light me-3">
                                    {props.t("Add Article")}
                                </button>
                            </Col>
                        </CardBody>
                    </Card>
                    {error && <Col lg="12" className="mt-5">
                        <Alert className={"mt-4"} color="danger" role="alert">
                            {error}
                        </Alert>
                    </Col>}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={tog_confirm}
                        className="col-md-1 btn btn-secondary waves-effect">
                        {props.t("Close")}
                    </button>
                    {type === "MANUAL" && <button
                        type="button"
                        style={{marginLeft: "5px"}}
                        onClick={() => addPackage(false)}
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        className="col-md-1 btn btn-primary waves-effect">
                        {editPackageId ? props.t("Update") : props.t("Add")}
                    </button> }
                    <button
                        type="button"
                        style={{marginLeft: "5px"}}
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                        onClick={() => {addPackage(true);}}
                        className="col-md-2 btn btn-primary waves-effect">
                        {editPackageId ? props.t("Update & Close") : props.t("Add & Close")}
                    </button>
                </div>
            </Modal>
        </>
    )
}


EditArticlePackage.prototype = {
    products: PropTypes.array,
    priceList: PropTypes.array

}

const mapStateToProps = (state) => ({
    products: state.ecommerce.products.data,
    priceList: state.ecommerce.priceListForTenant
})

const mapDispatchToProps = dispatch => ({
    onGetProducts: () => dispatch(getProducts()),
    onGetPriceList: () => dispatch(getPriceListForTenant())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(EditArticlePackage))
