import React, {useState} from "react"
import {withTranslation} from "react-i18next";
import {Collapse} from "reactstrap";
import JSONPretty from 'react-json-pretty';

/**
 * Use 'keyToDisplay' prop when 'selectedValue' props is an object and options is an array of objects and you want to use a value of 'keyToDisplay' key in object for displaying the text to user in UI
 */
const AccordionButton = props => {
  const {content, activeColor} = props;
  const [viewDetailToggle, setViewDetailToggle] = useState(false);
  return (
          <div className="d-flex">
            <i 
              className={viewDetailToggle ? "fa fa-chevron-circle-up" : "fa fa-chevron-circle-down"} 
              style={{fontSize: "16px", cursor: "pointer", color: viewDetailToggle ? activeColor : "", marginRight: "10px"}}
              onClick={() => setViewDetailToggle(!viewDetailToggle)}
            >
            </i>
            <Collapse isOpen={viewDetailToggle}>
                <JSONPretty id="json-pretty" data={content}></JSONPretty>
            </Collapse>
          </div>
  )
}

export default withTranslation()(AccordionButton)
