import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {projectIdContext} from "./ProjectList";

const ProjectActions = (props) => {
    const projectId = React.useContext(projectIdContext);

    const [modal_standard, setmodal_standard] = useState(false);
    let user = JSON.parse(localStorage.getItem("authUser"));

    const handleDeleteProject = ()=> {

    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    return (
        <React.Fragment>
            <Link
                to={{
                    pathname: `${user.customerId ? '/customer' : ''}/projects/${projectId}/edit`,
                }}
            >
                {props.t("Edit")}
            </Link>{" "}
            {/*|{" "}
            <label className="link" onClick={tog_standard}>
                {props.t("Delete")}
            </label>
            <div>
                <div>
                    <Modal
                        isOpen={modal_standard}
                        toggle={() => {
                            tog_standard();
                        }}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                {props.t('Delete Project')}
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    setmodal_standard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>{props.t('Are you sure you want to delete this Project ?')}</h5>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => {
                                    tog_standard();
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                {props.t('No')}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleDeleteProject}
                            >
                                {props.t('Yes')}
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>*/}
        </React.Fragment>
    );
};

export default withTranslation()(ProjectActions);
