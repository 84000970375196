export const GET_TASK_LIST = "GET_TASK_LIST"
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS"
export const GET_TASK_LIST_FAIL = "GET_TASK_LIST_FAIL"
export const GET_TASK_LIST_RESET = "GET_TASK_LIST_RESET"

export const GET_TASK = "GET_TASK"
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS"
export const GET_TASK_FAIL = "GET_TASK_FAIL"
export const GET_TASK_RESET = "GET_TASK_RESET"

export const GET_TASK_COMMENT_LIST = "GET_TASK_COMMENT_LIST"
export const GET_TASK_COMMENT_LIST_SUCCESS = "GET_TASK_COMMENT_LIST_SUCCESS"
export const GET_TASK_COMMENT_LIST_FAIL = "GET_TASK_COMMENT_LIST_FAIL"
export const GET_TASK_COMMENT_LIST_RESET = "GET_TASK_COMMENT_LIST_RESET"

export const GET_TASK_WORK_LOG = "GET_TASK_WORK_LOG"
export const GET_TASK_WORK_LOG_SUCCESS = "GET_TASK_WORK_LOG_SUCCESS"
export const GET_TASK_WORK_LOG_FAIL = "GET_TASK_WORK_LOG_FAIL"
export const GET_TASK_WORK_LOG_RESET = "GET_TASK_WORK_LOG_RESET"

export const GET_WORK_LOG_TYPE = "GET_WORK_LOG_TYPE"
export const GET_WORK_LOG_TYPE_SUCCESS = "GET_WORK_LOG_TYPE_SUCCESS"
export const GET_WORK_LOG_TYPE_FAIL = "GET_WORK_LOG_TYPE_FAIL"


export const GET_WORK_LOG_FOR_CUSTOMER = "GET_WORK_LOG_FOR_CUSTOMER"
export const GET_WORK_LOG_FOR_CUSTOMER_SUCCESS = "GET_WORK_LOG_FOR_CUSTOMER_SUCCESS"
export const GET_WORK_LOG_FOR_CUSTOMER_FAIL = "GET_WORK_LOG_FOR_CUSTOMER_FAIL"

export const GET_TASK_BY_PROJECT = "GET_TASK_BY_PROJECT"
export const GET_TASK_BY_PROJECT_SUCCESS = "GET_TASK_BY_PROJECT_SUCCESS"
export const GET_TASK_BY_PROJECT_ERROR = "GET_TASK_BY_PROJECT_ERROR"

export const GET_WORK_LOG_FOR_PROJECT = "GET_WORK_LOG_FOR_PROJECT"
export const GET_WORK_LOG_FOR_PROJECT_SUCCESS = "GET_WORK_LOG_FOR_PROJECT_SUCCESS"
export const GET_WORK_LOG_FOR_PROJECT_ERROR = "GET_WORK_LOG_FOR_PROJECT_ERROR"

export const GET_WORK_LOG = "GET_WORK_LOG"
export const GET_WORK_LOG_SUCCESS = "GET_WORK_LOG_SUCCESS"
export const GET_WORK_LOG_ERROR = "GET_WORK_LOG_ERROR"
export const GET_WORK_LOG_RESET = "GET_WORK_LOG_RESET"

export const GET_WORK_LOG_FOR_SUPERADMIN = "GET_WORK_LOG_FOR_SUPERADMIN"
export const GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS = "GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS"
export const GET_WORK_LOG_FOR_SUPERADMIN_ERROR = "GET_WORK_LOG_FOR_SUPERADMIN_ERROR"

export const GET_TASK_CATEGORIES = "GET_TASK_CATEGORIES"
export const GET_TASK_CATEGORIES_SUCCESS = "GET_TASK_CATEGORIES_SUCCESS"
export const GET_TASK_CATEGORIES_FAIL = "GET_TASK_CATEGORIES_FAIL"

export const GET_TASK_PRIORITES = "GET_TASK_PRIORITES"
export const GET_TASK_PRIORITIES_SUCCESS = "GET_TASK_PRIORITIES_SUCCESS"
export const GET_TASK_PRIORITIES_FAIL = "GET_TASK_PRIORITIES_FAIL"

export const GET_WORK_LOG_FOR_USER = "GET_WORK_LOG_FOR_USER"
export const GET_WORK_LOG_FOR_USER_SUCCESS = "GET_WORK_LOG_FOR_USER_SUCCESS"
export const GET_WORK_LOG_FOR_USER_FAIL = "GET_WORK_LOG_FOR_USER_FAIL"
export const GET_WORK_LOG_FOR_USER_RESET = "GET_WORK_LOG_FOR_USER_RESET"

export const GET_DEFAULT_TIMESLIP_TYPE = "GET_DEFAULT_TIMESLIP_TYPE"
export const GET_DEFAULT_TIMESLIP_TYPE_SUCCESS = "GET_DEFAULT_TIMESLIP_TYPE_SUCCESS"
export const GET_DEFAULT_TIMESLIP_TYPE_FAIL = "GET_DEFAULT_TIMESLIP_TYPE_FAIL"
export const GET_DEFAULT_TIMESLIP_TYPE_RESET = "GET_DEFAULT_TIMESLIP_TYPE_RESET"
