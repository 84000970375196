import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import TableToolbar from "../../components/Common/TableToolbar";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import CustomerTimeslips from "../Customer/CustomerTimeslips";
import {getWorkLog, getWorkLogType} from "../../store/actions";
import {find} from "lodash";
import {getTenantCustomers} from "../../store/tenant/actions";
import TimeslipModal from "./TimeslipModal";


const CustomerWorkLogList = (props) => {
    const {onGetWorkLogTypes, workLogTypes, worklogTypeLoading, onGetTenantCustomers, customersLoading, customerOptions, getWorklogs, match: {params}} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState('ALL');
    const [showTimeslipTaskModal, setShowTimeslipTaskModal] = useState(false);
    const history = useHistory();
    const [customFilter, setCustomFilter] = useState({
        timeslipTypeId: null,
        userId: null,
        customerId: null,
    });
    const user = JSON.parse(localStorage.getItem("authUser"));
    const level = localStorage.getItem("level");
    //    CustomerTimeslips
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            setBrandSettings(user?.brandConfig);
            onGetWorkLogTypes();
            onGetTenantCustomers(user?.tenantId);
        }
    }, []);

    function toggleAddTimeslipModal() {
        setShowTimeslipTaskModal(!showTimeslipTaskModal)
    }

    const getFilterButtonGroups = () => {
        let btnGroups = [
            {name: props.t("All"), value: 'ALL'},
            {name: props.t("My Timeslips"), value: 'MY_TIMESLIPS'},
            {
                isSearchableDropdown: true,
                value: find(customerOptions, { id: customFilter?.customerId}),
                options: customerOptions,
                placeholder: customersLoading ? props.t("Loading...") : props.t("Select Customer"),
                handleSelect: (op) => {
                    setCustomFilter({customerId: op?.id});
                    setFilter(null);
                },
                keyToDisplay: "name",
                favorite: "favorite"
            },
            {
                isSearchableDropdown: true,
                value: find(workLogTypes, { id: customFilter?.timeslipTypeId}),
                options: workLogTypes?.filter(w => w.enabled),
                placeholder: worklogTypeLoading ? props.t("Loading...") : props.t("Select Timeslip Type"),
                handleSelect: (op) => {
                    setCustomFilter({timeslipTypeId: op?.id});
                    setFilter(null);
                },
                keyToDisplay: "name",
            }
        ]

        return btnGroups;
    }

    const getTimeslipButton = () => {
        if(level == "TENANT"){
            return [
                {
                    text: props.t("New Timeslip"),
                    onClick: toggleAddTimeslipModal,
                }
            ]
        }
    }

    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col className="col-12 article-table">
                        <Card>
                            <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                                <TableToolbar
                                    title={props.t("Timeslips")}
                                    buttons={getTimeslipButton()}
                                    filters={!user?.customerId && user?.tenantId ? [
                                        {
                                            selected: filter,
                                            options : getFilterButtonGroups(),
                                            onSelect: (option) => {
                                                if(option == 'MY_TIMESLIPS'){
                                                    setCustomFilter({userId: user?.uid})
                                                } else if(option == 'ALL'){
                                                    setCustomFilter(null);
                                                }
                                                setFilter(option);
                                            }
                                        }
                                    
                                    ] : []}
                                />
                            </CardHeader>
                            <CardBody>
                                <CustomerTimeslips filter={customFilter} showAddTask={false}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

        <TimeslipModal
            modal_standard={showTimeslipTaskModal}
            setmodal_standard={setShowTimeslipTaskModal}
            tog_standard={toggleAddTimeslipModal}
            realodTaskWorkLog={() => getWorklogs(customFilter)}
        />
    </React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
        // customerOptions: state.Customer.customers,
        customersLoading: state.tenant.getTenantCustomersLoading,
        customerOptions: state.tenant.customerList,
        workLogTypes: state.task.workLogType,
        worklogTypeLoading: state.task.worklogTypeLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    // onGetCustomerOptions: () => dispatch(GetCustomers()),
    onGetTenantCustomers: (tenantId) => dispatch(getTenantCustomers(tenantId, "active")),
    onGetWorkLogTypes: () => dispatch(getWorkLogType()),
    getWorklogs: (filter) => dispatch(getWorkLog(filter))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerWorkLogList)));
