import {
    GET_PROJECT_TEMPLATE,
    GET_PROJECT_TEMPLATE_SUCCESS,
    GET_PROJECT_TEMPLATE_FAIL, GET_PROJECT_TEMPLATE_DETAIL, GET_PROJECT_TEMPLATE_DETAIL_SUCCESS, GET_PROJECT_TEMPLATE_DETAIL_ERROR
} from "./actionTypes";

export const getProjectTemplate = () => ({
    type: GET_PROJECT_TEMPLATE
})

export const getProjectTemplateSuccess = (response) => ({
    type: GET_PROJECT_TEMPLATE_SUCCESS,
    payload: response.data
})

export const getProjectTemplateError = (error) => ({
    type: GET_PROJECT_TEMPLATE_FAIL,
    payload: error
})


export const getProjectTemplateDetails = (id) => ({
    type: GET_PROJECT_TEMPLATE_DETAIL,
    id
})

export const getProjectTemplateDetailsSuccess = (response) => ({
    type: GET_PROJECT_TEMPLATE_DETAIL_SUCCESS,
    payload: response.data
})

export const getProjectTemplateDetailsError = (error) => ({
    type: GET_PROJECT_TEMPLATE_DETAIL_ERROR,
    payload: error
})
