import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import ChangePassword from "./auth/changepwd/reducer"

//Calendar
import calendar from "./calendar/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//chat
import chat from "./chat/reducer"

//invoices
import invoices from "./invoices/reducer"

import Labels from "./label/reducer"

//contacts
import contacts from "./contacts/reducer"

import tenant from "./tenant/reducer"

import UserImpersonation from "./users/impersonation/reducer"

import TenantAdmin from "./users/tenant/reducer";

import Config from "./config/reducer";

import Customer from "./customer/reducer";

import Project from "./project/reducer";

import task from "./task/reducer";

import Cart from "./e-commerce/cart/reducer";

import Offers from "./offer/reducer";
import BrandSettings from './brandsettings/reducer'
import ui from "./ui/reducer"
import Audit from "./audit/reducer"
import Users from "./users/reducer"
import Language from "./language/reducer"

import ProjectTemplate from "./project-template/reducer";
import Manufacturer from "./manufacturer/reducers";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ChangePassword,
  calendar,
  chat,
  ecommerce,
  invoices,
  contacts,
  tenant,
  UserImpersonation,
  TenantAdmin,
  Config,
  Customer,
  Project,
  Cart,
  Offers,
  BrandSettings,
  ui,
  Audit,
  task,
  Users,
  Language,
  Labels,
  ProjectTemplate,
  Manufacturer
})

export default rootReducer
