import {
    GET_TASK_LIST,
    GET_TASK_LIST_FAIL,
    GET_TASK_LIST_RESET,
    GET_TASK_LIST_SUCCESS,
    GET_TASK_COMMENT_LIST,
    GET_TASK_COMMENT_LIST_FAIL,
    GET_TASK_COMMENT_LIST_RESET,
    GET_TASK_COMMENT_LIST_SUCCESS,
    GET_TASK,
    GET_TASK_SUCCESS,
    GET_TASK_FAIL,
    GET_TASK_RESET,
    GET_TASK_WORK_LOG,
    GET_TASK_WORK_LOG_SUCCESS,
    GET_TASK_WORK_LOG_RESET,
    GET_WORK_LOG_TYPE,
    GET_WORK_LOG_TYPE_SUCCESS,
    GET_WORK_LOG_TYPE_FAIL,
    GET_WORK_LOG_FOR_CUSTOMER,
    GET_WORK_LOG_FOR_CUSTOMER_SUCCESS,
    GET_WORK_LOG_FOR_CUSTOMER_FAIL,
    GET_TASK_BY_PROJECT,
    GET_TASK_BY_PROJECT_SUCCESS,
    GET_TASK_BY_PROJECT_ERROR, GET_WORK_LOG_FOR_PROJECT, GET_WORK_LOG_FOR_PROJECT_SUCCESS, GET_WORK_LOG_FOR_PROJECT_ERROR, GET_WORK_LOG, GET_WORK_LOG_SUCCESS, GET_WORK_LOG_ERROR, GET_TASK_CATEGORIES, GET_TASK_CATEGORIES_SUCCESS, GET_TASK_CATEGORIES_FAIL, GET_TASK_PRIORITES, GET_TASK_PRIORITIES_SUCCESS, GET_TASK_PRIORITIES_FAIL, GET_WORK_LOG_FOR_SUPERADMIN, GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS, GET_WORK_LOG_FOR_SUPERADMIN_ERROR,GET_WORK_LOG_FOR_USER,
    GET_WORK_LOG_FOR_USER_SUCCESS,
    GET_WORK_LOG_FOR_USER_FAIL,
    GET_WORK_LOG_FOR_USER_RESET,
    GET_WORK_LOG_RESET,
    GET_DEFAULT_TIMESLIP_TYPE,
    GET_DEFAULT_TIMESLIP_TYPE_SUCCESS,
    GET_DEFAULT_TIMESLIP_TYPE_FAIL,
    GET_DEFAULT_TIMESLIP_TYPE_RESET,
} from "./actionTypes";

const INIT_STATE = {
    tasks: [],
    loading: false,
    error: null,
    task: null,
    getTaskLoading: false,
    getTaskError: null,
    comments: [],
    commentsLoading: false,
    commentsError: null,
    workLog:[],
    loadingWorkLog: false,
    customerWorkLog: [],
    customerWorkLogLoading: false,
    workLogType: [],
    worklogTypeLoading : false,
    worklogTypeError : null,
    taskCategories: [],
    taskCategoriesLoading : false,
    taskCategoriesError : null,
    tasksByProject:[],
    taskByProjectLoading: false,
    taskByProjectError: null,
    workLogByProject: [],
    workLogByProjectLoading: false,
    workLogByProjectError: null,
    worklogs: [],
    worklogsLoading: false,
    worklogsError: null,
    workLogForSuperAdmin: [],
    workLogForSuperAdminLoading: false,
    workLogForSuperAdminError: null,
    taskPriorities: [],
    taskPrioritiesLoading : false,
    taskPrioritiesError : null,
    userWorkLog: [],
    userWorkLogLoading: false,
    userWorkLogError: null,
    defaultTimeslipType: [],
    defaultTimeslipTypeLoading: false,
    defaultTimeslipTypeError: null,
};

const task = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TASK_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_TASK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.payload,
            }
        case GET_TASK_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_TASK_LIST_RESET:
            return {
                ...state,
                tasks: [],
                loading: false,
                error: null,
            }
        case GET_TASK:
            return {
                ...state,
                getTaskLoading: true,
            }
        case GET_TASK_SUCCESS:
            return {
                ...state,
                getTaskLoading: false,
                task: action.payload,
            }
        case GET_TASK_FAIL:
            return {
                ...state,
                getTaskError: action.payload,
                getTaskLoading: false,
            }
        case GET_TASK_RESET:
            return {
                ...state,
                task: null,
                getTaskLoading: false,
                getTaskError: null,
            }
        case GET_TASK_COMMENT_LIST:
            return {
                ...state,
                commentsLoading: true,
            }
        case GET_TASK_COMMENT_LIST_SUCCESS:
            return {
                ...state,
                commentsLoading: false,
                comments: action.payload,
            }
        case GET_TASK_COMMENT_LIST_FAIL:
            return {
                ...state,
                commentsError: action.payload,
                commentsLoading: false,
            }
        case GET_TASK_COMMENT_LIST_RESET:
            return {
                ...state,
                comments: [],
                commentsLoading: false,
                commentsError: null,
            }
        case GET_TASK_WORK_LOG:
            return {
                ...state,
                workLog: [],
                loadingWorkLog: true
            }
        case GET_TASK_WORK_LOG_SUCCESS:
            return {
                ...state,
                workLog: action.payload,
                loadingWorkLog: false
            }
        case GET_TASK_WORK_LOG_RESET:
            return {
                ...state,
                workLog: [],
                loadingWorkLog: false
            }
        case GET_WORK_LOG_TYPE:
            return {
                ...state,
                workLogType: [],
                worklogTypeLoading : true,
                worklogTypeError : null,
            }
        case GET_WORK_LOG_TYPE_SUCCESS:
            return {
                ...state,
                workLogType: action.payload,
                worklogTypeLoading : false,
                worklogTypeError : null,

            }
        case GET_WORK_LOG_TYPE_FAIL:
            return {
                ...state,
                workLogType: [],
                worklogTypeLoading : false,
                worklogTypeError : action.payload,
            }
        case GET_TASK_CATEGORIES:
            return {
                ...state,
                taskCategories: [],
                taskCategoriesLoading : true,
                taskCategoriesError : null,
            }
        case GET_TASK_CATEGORIES_SUCCESS:
            return {
                ...state,
                taskCategories: action.payload,
                taskCategoriesLoading : false,
                taskCategoriesError : null,

            }
        case GET_TASK_CATEGORIES_FAIL:
            return {
                ...state,
                taskCategories: [],
                taskCategoriesLoading : false,
                taskCategoriesError : action.payload,
            }
        case GET_WORK_LOG_FOR_CUSTOMER:
            return {
                ...state,
                customerWorkLog: [],
                customerWorkLogLoading: true
            }
        case GET_WORK_LOG_FOR_CUSTOMER_SUCCESS:
            return {
                ...state,
                customerWorkLog: action.payload,
                customerWorkLogLoading: false
            }
        case GET_WORK_LOG_FOR_CUSTOMER_FAIL:
            return {
                ...state,
                customerWorkLog: [],
                customerWorkLogLoading: false
            }
        case GET_TASK_BY_PROJECT:
            return {
                ...state,
                tasksByProject: [],
                taskByProjectLoading: true
            }
        case GET_TASK_BY_PROJECT_SUCCESS:
            return {
                ...state,
                tasksByProject: action.payload,
                taskByProjectLoading: false
            }
        case GET_TASK_BY_PROJECT_ERROR:
            return {
                ...state,
                tasksByProject: [],
                taskByProjectLoading: false
            }
        case GET_WORK_LOG_FOR_PROJECT:
            return {
                ...state,
                workLogByProject: [],
                workLogByProjectLoading: true
            }
        case GET_WORK_LOG_FOR_PROJECT_SUCCESS:
            return {
                ...state,
                workLogByProject: action.payload,
                workLogByProjectLoading: false
            }
        case GET_WORK_LOG_FOR_PROJECT_ERROR:
            return {
                ...state,
                workLogByProjectLoading: false,
                workLogByProject: []
            }
        case GET_WORK_LOG:
            return {
                ...state,
                worklogs: [],
                worklogsLoading: true,
                worklogsError: null,
            }
        case GET_WORK_LOG_SUCCESS:
            return {
                ...state,
                worklogs: action.payload,
                worklogsLoading: false,
                worklogsError: null,
            }
        case GET_WORK_LOG_ERROR:
            return {
                ...state,
                worklogs: [],
                worklogsLoading: false,
                worklogsError: action.payload,
            }
        case GET_WORK_LOG_RESET:
            return {
                ...state,
                worklogs: [],
                worklogsLoading: false,
                worklogsError: null,
            }
        case GET_WORK_LOG_FOR_SUPERADMIN:
            return {
                ...state,
                workLogForSuperAdmin: [],
                workLogForSuperAdminLoading: true,
                workLogForSuperAdminError: null,
            }
        case GET_WORK_LOG_FOR_SUPERADMIN_SUCCESS:
            return {
                ...state,
                workLogForSuperAdmin: action.payload,
                workLogForSuperAdminError: null,
                workLogForSuperAdminLoading: false,
            }
        case GET_WORK_LOG_FOR_SUPERADMIN_ERROR:
            return {
                ...state,
                workLogForSuperAdmin: [],
                workLogForSuperAdminLoading: false,
                workLogForSuperAdminError: action.payload
            }
        case GET_TASK_PRIORITES:
            return {
                ...state,
                taskPriorities: [],
                taskPrioritiesLoading : true,
                taskPrioritiesError : null,
            }
        case GET_TASK_PRIORITIES_SUCCESS:
            return {
                ...state,
                taskPriorities: action.payload,
                taskPrioritiesLoading : false,
                taskPrioritiesError : null,
            }
        case GET_TASK_PRIORITIES_FAIL:
            return {
                ...state,
                taskPriorities: [],
                taskPrioritiesLoading : false,
                taskPrioritiesError : action.payload,
            }
        case GET_WORK_LOG_FOR_USER:
            return {
                ...state,
                userWorkLog: [],
                userWorkLogLoading: true
            }
        case GET_WORK_LOG_FOR_USER_SUCCESS:
            return {
                ...state,
                userWorkLog: action.payload,
                userWorkLogLoading: false,
                userWorkLogError: null
            }
        case GET_WORK_LOG_FOR_USER_FAIL:
            return {
                ...state,
                userWorkLog: [],
                userWorkLogLoading: false,
                userWorkLogError: action.payload,
            }
        case GET_WORK_LOG_FOR_USER_RESET:
            return {
                ...state,
                userWorkLog: [],
                userWorkLogLoading: false,
                userWorkLogError: null

            }
        case GET_DEFAULT_TIMESLIP_TYPE:
            return {
                ...state,
                defaultTimeslipType: [],
                defaultTimeslipTypeLoading: true
            }
        case GET_DEFAULT_TIMESLIP_TYPE_SUCCESS:
            return {
                ...state,
                defaultTimeslipType: action.payload,
                defaultTimeslipTypeLoading: false,
                defaultTimeslipTypeError: null
            }
        case GET_DEFAULT_TIMESLIP_TYPE_FAIL:
            return {
                ...state,
                defaultTimeslipType: [],
                defaultTimeslipTypeLoading: false,
                defaultTimeslipTypeError: action.payload,
            }
        case GET_DEFAULT_TIMESLIP_TYPE_RESET:
            return {
                ...state,
                defaultTimeslipType: [],
                defaultTimeslipTypeLoading: false,
                defaultTimeslipTypeError: null

            }
        default:
            return state;
    }
};

export default task
