import svFlag from "../assets/images/flags/sweden.png"
import ukFlag from "../assets/images/flags/uk.png"

const languages = {
    en: {
        label: "English",
        flag: ukFlag,
    },
    sv: {
        label: "Swedish",
        flag: svFlag,
    }
}

export default languages
