import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, CardHeader, Alert } from "reactstrap";
import { useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getInvoices, getInvoicesReset } from "../../store/actions";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";
import TableToolbar from "../../components/Common/TableToolbar";
import { filter, includes, isEmpty } from "lodash-es";
import { checkIfLoading } from "../../store/selector";
import { invoicesActionTypes } from "../../store/invoices/actionTypes";
import { API_ERR_MSG } from "../../util/constants";
import Loader from "../../components/Common/Loader";
import InvoiceStatus from "./InvoiceStatus";
import { downloadPDF } from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";

const Invoices = (props) => {
  const { invoices, onGetInvoices, loading, error, onGetInvoicesReset } = props;
  const [invoiceList, setInvoiceList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const [brandSettings, setBrandSettings] = useState(null);
  const [invoiceType, setInvoiceType] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setBrandSettings(obj?.brandConfig);
      const prevFilter = localStorage.getItem("customerInvoicesFilter");
      if(prevFilter){
        setInvoiceType(prevFilter);
      } else {
        setInvoiceType("all");
      }
    }
    return () => {
      onGetInvoicesReset();
    }
  }, []);

  useEffect(() => {
    if(error){
      setTimeout(() => {onGetInvoicesReset()}, 5000);
    }
  }, [error])

  useEffect(() => {
    if(invoiceType){
      localStorage.setItem("customerInvoicesFilter", invoiceType);
      onGetInvoices(invoiceType);
    }
  }, [invoiceType])

  useEffect(() => {
     setInvoiceList(invoices);
  }, [invoices]);

  const handleOpen = (customerId, invoiceId) => {
    history.push(`/customer/invoice/${invoiceId}/?from=` + encodeURIComponent(props.location.pathname));
  };
  const handleSearch = () => {
    if (!isEmpty(search)) {
      const results = filter(
        invoiceList,
        (invoice) =>
          includes(
            invoice.DocumentNumber?.toLowerCase(),
            search.toLowerCase()
          ) ||
          includes(invoice.username?.toLowerCase(), search.toLowerCase()) ||
          filter(invoice.city, (c) =>
            includes(c.name?.toLowerCase(), search.toLowerCase())
          ).length > 0
      );

      setSearchResults(results);
    } else {
      setSearchResults(invoiceList);
    }
    setSearchValue(search);
  };
  const getDatatableData = () => {
    const rows = invoiceList?.filter((row) =>
      row.DocumentNumber?.toLowerCase().includes(search?.toLowerCase())
    );
    let data = {
      columns: [
        {
          label: props.t("Invoice Number"),
          field: "documentNumber",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Amount"),
          field: "total",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Invoice Date"),
          field: "invoiceDate",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Due Date"),
          field: "dueDate",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Status"),
          field: "booked",
          sort: "asc",
          width: 150,
        },
        {
          label: props.t("Download"),
          field: "download",
        }
      ],
      rows: rows?.map((invoice, key) => ({
        documentNumber: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.DocumentNumber}</div>,
        total: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{formatCurrency(invoice?.Currency, invoice.Total)}</div>,
        invoiceDate: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.InvoiceDate}</div>,
        dueDate: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}>{invoice.DueDate}</div>,
        booked: <div onClick={() => handleOpen(invoice.CustomerNumber, invoice.DocumentNumber)}><InvoiceStatus status={invoice.status}/></div>,
        download: <div onClick={() => downloadPDF(invoice.DocumentNumber, 'invoices')}><i className="fa fa-download font-size-15"></i></div>
      })),
    };
    return data;
  };

  const handleFilter = (value) => {
    setInvoiceType(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col className="col-12 article-table">
              <Card>
                <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor }}>
                  <TableToolbar
                    title={props.t("Invoices")}
                    filter={{
                      selected: invoiceType,
                      options : [
                        {name: props.t("All"), value: "all"},
                        {name: props.t("Unpaid"), value: "unpaid"},
                        {name: props.t("Overdue"), value: "unpaidoverdue"},
                        {name: props.t("Fully Paid"), value: "fullypaid"},
                        /*{name: props.t("Unbooked"), value: "unbooked"},
                        {name: props.t("Cancelled"), value: "cancelled"}*/
                      ],
                      onSelect: handleFilter
                    }}
                  />
                </CardHeader>
                <Loader loading={loading} title="Invoices" />
                {!loading &&
                <CardBody>
                  {error &&
                    <Alert className={"mt-4"} color="danger" role="alert">
                        {props.t(API_ERR_MSG)}
                    </Alert>
                  }
                  <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    searching={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                      props.t("Showing"),
                      props.t("to"),
                      props.t("of"),
                      props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    noBottomColumns={true}
                    data={getDatatableData()}
                    onSearch={(text) => console.log(text)}
                    sortRows={[
                      "customerNumber",
                      "documentNumber",
                      "invoiceDate",
                      "dueDate",
                      "total",
                    ]}
                  />
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    invoices: state.invoices.invoices?.Invoices,
    loading: checkIfLoading(state, invoicesActionTypes.GET_INVOICES),
    error: state.invoices.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetInvoices: (invoiceType) => dispatch(getInvoices(invoiceType)),
  onGetInvoicesReset: () => dispatch(getInvoicesReset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Invoices)));
