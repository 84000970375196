import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import {Alert, Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {getProjectConfig, getProjectConfigReset} from "../../store/config/actions";
import {connect} from "react-redux";
import {get, post} from "../../helpers/api_helper";
import { API_ERR_MSG } from "../../util/constants";
import TableToolbar from "../../components/Common/TableToolbar";
import Loader from "../../components/Common/Loader";
import { preventNumberInputScroll, validateNumber } from "../../util/helperFunctions";

const ProjectConfig = (props) => {
    const {header} = props.location.state;

    const {onGetProjectConfig, projectConfig, loading, onGetProjectConfigReset, getProjectConfigError } = props

    const [startingProjectNumber, setProjectNumber] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [brandSettings, setBrandSettings] = useState(null);
    const [suggestedProjectNumber, setSuggestedProjectNumber] = useState('');

    const clearError = () => setError(null)
    const clearSuccess = () => setSuccess(null)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);
        onGetProjectConfig();
        getAndSetSuggestedProjectNumber();
        return () => {
            onGetProjectConfigReset();
        }
    }, [])

    useEffect(() => {
        if (projectConfig) {
            setProjectNumber(projectConfig?.startingProjectNumber)
        }
    }, [projectConfig])

    useEffect(() => {
        if(getProjectConfigError){
            setError(getProjectConfigError);
            setTimeout(() => {onGetProjectConfigReset()}, 5000);
            setTimeout(() => {setError(null)}, 5000);
        }
    }, [getProjectConfigError])

    const submitForm = (event) => {
        post('/api/settings/projectConfig', {startingProjectNumber})
            .then(data => {
                if (data?.status !== 202) {
                    setError(API_ERR_MSG)
                    setTimeout(clearError, 5000)
                } else {
                    setSuccess(props.t("Project configuration updated successfully"))
                    setTimeout(clearSuccess, 5000)
                    onGetProjectConfig();
                }
            }).catch(error => {
            setError("Fail to set project config.");
            setTimeout(clearError, 5000)
        });
    }

    const handleProjectNumberChange = (option) => {
      setProjectNumber(option);
    }

    const getAndSetSuggestedProjectNumber = () => {
        get('/api/projects/suggestedProjectNumber')
            .then(data => {
                if (data?.status !== 200) {
                } else {
                    setSuggestedProjectNumber(data?.data)
                }
            }).catch(error => {
        });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader
                                    className="table-header-bg"
                                    style={{backgroundColor: brandSettings?.primaryColor}}
                                >
                                    <TableToolbar title={props.t(header)}/>
                                </CardHeader>
                                <Loader loading={loading} title="Project Config" />
                                {!loading &&
                                <form onSubmit={(event) => {
                                    event.preventDefault()
                                    submitForm(event)
                                }}>
                                    <CardBody>
                                        {error ? (
                                            <Alert color="danger">{error}</Alert>
                                        ) : null}

                                        {success ? (
                                            <Alert color="success">{success}</Alert>
                                        ) : null}

                                        <input
                                            className="form-control"
                                            type="hidden"
                                            defaultValue={projectConfig?.id}
                                            id="id"
                                            disabled={loading}
                                        />
                                        <Row className={`mb-${startingProjectNumber != suggestedProjectNumber ? '2' : '5'}`}>
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-2 col-form-label"
                                            >
                                                {props.t("Starting Project Number")}
                                            </label>
                                            <div className="col-md-3 col-sm-10">
                                                <input
                                                    className="form-control"
                                                    id="startingProjectNumber"
                                                    onChange={e => {
                                                        clearError();
                                                        handleProjectNumberChange(e.target.value);
                                                    }}
                                                    value={startingProjectNumber}
                                                    type="number"
                                                    onWheel={preventNumberInputScroll}
                                                    // onKeyDown={validateNumber}
                                                />
                                            </div>
                                        </Row>
                                        {(startingProjectNumber != suggestedProjectNumber) && 
                                        <Row className="mb-5">
                                            <div>
                                                <label className="font-size-12">{props.t('Next Available Project Number') + ': ' + suggestedProjectNumber}</label>
                                            </div>
                                        </Row>}
                                        <Row className="mb-3">
                                            <Col align="center">
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="btn btn-success w-lg waves-effect waves-light me-2"
                                                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                                                    disabled={loading || !startingProjectNumber}
                                                >
                                                    {props.t("Save")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </form>}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ProjectConfig.propTypes = {
    projectConfig: PropTypes.array,
    getProjectConfigError: PropTypes.object,
    loading: PropTypes.bool,
}

const mapStateToProps = state => {
    return {
        projectConfig: state.Config.projectConfig,
        loading: state.Config.projectConfigLoading,
        getProjectConfigError: state.Config.projectConfigError,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetProjectConfig: () => dispatch(getProjectConfig()),
    onGetProjectConfigReset: () => dispatch(getProjectConfigReset())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ProjectConfig));
