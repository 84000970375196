import React from 'react'
import { Button } from 'reactstrap';
import { withTranslation } from "react-i18next";
import SearchableDropdown from './SearchableDropdown';

const ButtonGroup = (props) => {
    const {filter, brandSettings} = props;
  return (
    <>{filter && 
        <div
            className="btn-group"
            role="group"
        >
        {filter?.options.map(function(item){
            if(item.isSearchableDropdown){
            const {value, options, placeholder, handleSelect, keyToDisplay, disabled} = item;
            return <SearchableDropdown
                        selectedValue={value} 
                        options={options} 
                        placeholder={placeholder}
                        handleOnOptionClick={handleSelect}
                        keyToDisplay={keyToDisplay}
                        disabled={disabled}
                        brandColor={{ filter: value ? 'brightness(100%)' : 'brightness(70%)', backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                    />
            } else {
            return  <Button color="light"
                        className="btn btn-primary"
                        onClick={() => filter.onSelect(item.value)}
                        style={filter.selected === item.value ? 
                        {backgroundColor: brandSettings?.primaryBtnColor, color: "white", borderColor: brandSettings?.primaryBtnColor} : 
                        {filter: 'brightness(70%)', color: "white",  borderColor: brandSettings?.primaryBtnColor, backgroundColor: brandSettings?.primaryBtnColor}}
                        disabled={item.disabled}
                    >
                        {props.t(item.name)}
                    </Button>
            }
        })}
        </div>}
    </>
  )
}

export default withTranslation()(ButtonGroup);