import React, {useEffect, useState} from "react";
import {Alert, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getTenantUserDetails, getTenantUserDetailsReset} from "../../store/users/tenant/actions";
import {
    TENANT_ACTIVATE_USER,
    TENANT_ADMIN_DELETE_USER,
    TENANT_ADMIN_REGENERATE_TOKEN,
    TENANT_ADMIN_RESET_DELETE_USER,
    TENANT_ADMIN_RESET_REGENERATE_TOKEN,
} from "../../store/users/tenant/actionTypes";
import PageToolbarTop from "../../components/Common/PageToolbarTop";
import TableToolbar from "../../components/Common/TableToolbar";
import store from "../../store";
import OverridePwdModal from "../../components/Common/OverridePwdModal";
import DeleteModal from "../../components/Common/DeleteModal";
import avatar from "../../assets/images/users/user-img-placeholder-2.png";
import {BASE_IMAGE_URL} from "../../helpers/url_helper";
import classnames from 'classnames';
import {getCustomerForAM, getCustomerForAMReset} from "../../store/customer/actions";
import {impersonateUser} from "../../store/users/impersonation/actions";
import {MDBDataTable} from "mdbreact";
import Loader from "../../components/Common/Loader";
import {Avatar} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import loadingIcon from "../../assets/images/loading.gif";
import {editProfile} from "../../store/actions";
import TaskManagement from "../Tasks/TaskManagement";
import {downloadPDF} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";
import CustomerTimeslips from "../Customer/CustomerTimeslips";
import ProjectListManagement from "../Projects/ProjectListManagement";
import EventManagement from "../Audit/EventManagement";
import CustomerOfferTable from "../Customer/CustomerOfferTable";
import CustomerInvoiceTable from "../Customer/CustomerInvoiceTable";
import CustomerOrderTable from "../Customer/CustomerOrderTable";
import Dashboard from "../Dashboard";

export const userDetailContext = React.createContext(null);

const TenantUsers = (props) => {
    const history = useHistory();
    const location = useLocation();
    const {
        user,
        onGetUserDetails,
        tokenRegenerate,
        userDeleted,
        onResetRegenerateToken,
        onResetDeleteUser,
        userActivated,
        error,
        loading,
        customers,
        customersForAmLoading,
        onGetCustomers,
        onImpersonateUser,
        orders,
        ordersLoading,
        onEditProfile,
        editProfileLoading,
        onGetCustomersReset,
        customersForAmError,
        ordersError,
        onGetUserDetailsReset,
        match: {params},
    } = props;

    const [activeUser, setActiveUser] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showOverridePwdModal, setShowOverridePwdModal] = useState(false);
    const [modal_standard, setmodal_standard] = useState(false);
    const [brandSettings, setBrandSettings] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const [search, setSearch] = useState("");
    const [profileImageId, setProfileImageId] = useState(null);
    const [imageUploading, setImageUploading] = useState(false);

    const profiletoggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem("authUser"));
        setBrandSettings(user?.brandConfig);

        const tab = JSON.parse(localStorage.getItem("tenantUserProfile"));
        if(tab){
            setActiveTab(tab.toString());
        }
        return () => {
            onGetCustomersReset();
            onGetUserDetailsReset();
        }
    }, [])

    useEffect(() => {
        if(activeTab){
            localStorage.setItem("tenantUserProfile", activeTab);
        }
    }, [activeTab])

    useEffect(() => {
        if(!editProfileLoading){
            onGetUserDetails(params.id);
            onGetCustomers(params.id);
        }
    }, [params.id, editProfileLoading]);

    useEffect(() => {
        setActiveUser(user);
        setProfileImageId(user?.profileImage?.s3Key)
    }, [user])

    useEffect(() => {
      if(customersForAmError){
        setTimeout(() => {onGetCustomersReset()}, 5000);
      }
    }, [customersForAmError])

    /*useEffect(() => {
      if(ordersError){
        setTimeout(() => {onGetOrdersReset()}, 5000);
      }
    }, [ordersError])*/

    useEffect(() => {
        if(error){
            setErrorMessage(error);
            setTimeout(() => {setErrorMessage('')}, 5000);
        }
    }, [error])

    useEffect(() => {
        if(!editProfileLoading){
            setImageUploading(false);
        }
    }, [editProfileLoading])

    useEffect(() => {
        if (tokenRegenerate && tokenRegenerate.status) {
            setSuccessMessage(props.t("Activation link sent to user."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetRegenerateToken();
            }, 3000);
        } else if (tokenRegenerate) {
            setErrorMessage(props.t("Error sending activation link"));
            setTimeout(() => {
                setErrorMessage("");
                onResetRegenerateToken();
            }, 3000);
        }
    }, [tokenRegenerate]);

    useEffect(() => {
        if (userDeleted && userDeleted.status) {
            setSuccessMessage(props.t("User deactivated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(params.id);
        } else if (userDeleted) {
            setErrorMessage(props.t("Error deleting user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userDeleted]);

    useEffect(() => {
        if (userActivated && userActivated.status) {
            setSuccessMessage(props.t("User activated successfully."));
            setTimeout(() => {
                setSuccessMessage("");
                onResetDeleteUser();
            }, 3000);
            onGetUserDetails(params.id);
        } else if (userActivated) {
            setErrorMessage(props.t("Error activated user."));
            setTimeout(() => {
                setErrorMessage("");
                onResetDeleteUser();
            }, 3000);
        }
    }, [userActivated]);

    function getRoleName(role) {
        if (role === "ROLE_TENANT_ADMIN") {
            return props.t("Tenant Admin");
        } else if (role === "ROLE_TENANT_USER") {
            return props.t("Tenant User");
        } else if (role === "ROLE_CUSTOMER_ADMIN") {
            return props.t("Customer Admin");
        } else if (role === "ROLE_CUSTOMER_USER") {
            return props.t("Customer User");
        } else if (role === "ROLE_CONTACT_USER") {
            return props.t("Contact");
        } else if (role === "ROLE_SUPER_ADMIN") {
            return props.t("Super Admin");
        }
        return role;
    }

    const handleOnClick = () => {
        history.push("/settings/users/add");
    };

    const handleRegenerateClick = () =>
        store.dispatch({
            type: TENANT_ADMIN_REGENERATE_TOKEN,
            userId: activeUser.id,
        });

    const handleActiveUser = async () => {
        store.dispatch({type: TENANT_ACTIVATE_USER, userId: activeUser.id});
    };

    function togglePwdModal() {
        setShowOverridePwdModal(!showOverridePwdModal);
    }

    function tog_standard() {
        setmodal_standard(!modal_standard);
        document.body.classList.add("no_padding");
    }

    const handleDeleteUser = async () => {
        await store.dispatch({type: TENANT_ADMIN_DELETE_USER, userId: activeUser.id});
        tog_standard();
    };

    const handleEdit = async () => {
        if (props.setEditMode && props.setSelectedTenantUser) {
            await props.setSelectedTenantUser(activeUser.id)
            props.setEditMode(true);
            return;
        }
        history.push(`/settings/users/edit/${activeUser.id}`);
    }

    const getActionButtons = () => {
        let actions = [
            {
                text: props.t("Override Password"),
                onClick: togglePwdModal,
            },
        ];

        if (activeUser) {
            if (activeUser.role != "ROLE_CONTACT_USER") {
                actions.push({
                    text: props.t("Send Activation Email"),
                    onClick: handleRegenerateClick,
                });
            }

            if (activeUser.enabled == true) {
                actions.push({
                    text: props.t("De-activate"),
                    onClick: tog_standard,
                });
            } else {
                actions.push({
                    text: props.t("Activate"),
                    onClick: handleActiveUser,
                });
            }
        }

        return actions;
    };

    const getDatatableData = () => {
        const rows = customers?.filter((row) =>
            row.name?.toLowerCase().includes(search?.toLowerCase())
        );
        let data = {
            columns: [
                {
                    label: props.t("Name"),
                    field: "name",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("City"),
                    field: "city",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: props.t("Phone"),
                    field: "phone",
                    sort: "asc",
                },
                {
                    label: props.t("Impersonate"),
                    field: "impersonate",
                    sort: "asc",
                }
            ],
            rows: rows?.map((customer, key) => ({
                name: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.name}</span>,
                city: <span onClick={() => handleOpen(customer.customerNumber)}>{customer.city}</span>,
                phone:<span onClick={() => handleOpen(customer.customerNumber)}>{customer.phone}</span>,
                impersonate: <><button onClick={()=> handleImpersonate(customer)} className="btn btn-primary btn-sm" style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}><i className="fa fa-sign-in-alt"></i></button></>
            })),
        };
        return data;
    };

    const getOrderDatatableData = () => {
        const rows = orders;
        let data = {
            columns: [
                {
                    label: props.t("Order Number"),
                    field: "orderNumber",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Total"),
                    field: "amount",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("OrderDate"),
                    field: "date",
                    sort: "asc",
                    width: 150,
                },
                {
                  label: props.t("Download"),
                  field: "download",
                }
            ],
            rows: rows?.map((order, key) => ({
                orderNumber: <div onClick={() => openOrderDetails(order)}>{order.DocumentNumber}</div>,
                amount: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.Total)}</div>,
                date: <div onClick={() => openOrderDetails(order)}>{order.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            })),
        };
        return data;
    };

    const handleOpen = (id) => {
        history.push(`/customers/${id}/profile`);
    };

    const handleImpersonate = (customer) => {
        onImpersonateUser({ customerId: customer.id }, history, location)
    }

    const openOrderDetails = (order) => {
        const fromUrl = `?from=` + encodeURIComponent(props.location.pathname);
        history.push(`/orders/${order.DocumentNumber + fromUrl}`);
    };

    const handleUserImpersonate = (user) => {
        onImpersonateUser({userEmail: user?.email}, history, location)
    }


    function getImpersonateButton() {
        const role = activeUser?.roles[0]?.name;
        return <>
            {(role && role != "ROLE_CONTACT_USER") &&
                <button onClick={() => handleUserImpersonate(activeUser)} className="btn btn-primary"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}>
                    <i className="fa fa-sign-in-alt"></i>
                </button>}</>

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <PageToolbarTop
                        noBackBtn={true}
                        breadcrumbs={[{title: props.t("Users"), link: "/settings/users"}]}
                        title={activeUser ? activeUser?.name : "User"}
                    />
                        <>
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                            <TableToolbar
                                                title={activeUser ? activeUser?.name : props.t("User Details")}
                                                buttons={getActionButtons()}
                                                canEdit={handleEdit}
                                                customComponent={getImpersonateButton}
                                            />
                                        </CardHeader>
                                    </Card>
                                </Col>

                                {successMessage && (
                                        <Col className="col-12">
                                            <Alert color="success">{successMessage}</Alert>
                                        </Col>
                                )}
                                {errorMessage && (
                                        <Col className="col-12">
                                            <Alert color="danger">{errorMessage}</Alert>
                                        </Col>
                                )}
                    
                                <Loader loading={loading || customersForAmLoading || ordersLoading} title="Tenant User Details" />
                                {!loading && !customersForAmLoading && !ordersLoading && <>
                                <Col xl="4">
                                    <Card className="card">
                                        <CardBody>
                                            <div className="text-center">
                                                <label htmlFor='icon-button-file'>
                                                        <Avatar
                                                            variant="rounded"
                                                            src={imageUploading ? loadingIcon : profileImageId == null ? avatar : BASE_IMAGE_URL + "/" + profileImageId}
                                                            style={{
                                                                margin: "10px",
                                                                width: "100px",
                                                                height: "100px",
                                                                borderRadius: "100px"
                                                            }}
                                                        >
                                                            <PersonIcon/>
                                                        </Avatar>
                                                </label>
                                                <h5 className="mb-1">{user?.name}</h5>
                                                {user?.email && <p className="text-muted"><a href={"mailto:"+ user?.email}>{user?.email}</a></p>}
                                            </div>

                                            <hr className="my-4"/>

                                            <div className="text-muted">
                                                <div className="table-responsive mt-4">
                                                    {props.user?.title && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Title")} :</p>
                                                        <h5 className="font-size-16">{user?.title}</h5>
                                                    </div>}
                                                    <div>
                                                        <p className="mb-1">{props.t("Name")} :</p>
                                                        <h5 className="font-size-16">{user?.name}</h5>
                                                    </div>
                                                    {props.user?.phone && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Phone")} :</p>
                                                        <h5 className="font-size-16"><a href={"tel:"+user?.phone}>{user?.phone}</a></h5>
                                                    </div>}
                                                    {props.user?.mobile && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Mobile")} :</p>
                                                        <h5 className="font-size-16"><a href={"tel:"+user?.mobile}>{user?.mobile}</a></h5>
                                                    </div>}
                                                    {user?.email && <div className="mt-4">
                                                        <p className="mb-1">{props.t("E-mail")} :</p>
                                                        <h5 className="font-size-16"><a href={"mailto:"+ user?.email}>{user?.email}</a></h5>
                                                    </div>}
                                                    <div className="mt-4">
                                                        <p className="mb-1">{props.t("Timezone")} :</p>
                                                        <h5 className="font-size-16">{user?.timezone}</h5>
                                                    </div>
                                                    {user?.city && <div className="mt-4">
                                                        <p className="mb-1">{props.t("City")} :</p>
                                                        <h5 className="font-size-16">{user?.city}</h5>
                                                    </div>}
                                                    {user?.fortnoxUsername && <div className="mt-4">
                                                        <p className="mb-1">{props.t("Fortnox username")} :</p>
                                                        <h5 className="font-size-16">{user?.fortnoxUsername}</h5>
                                                    </div>}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                    <Card className="mb-0">
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        profiletoggle('1');
                                                    }}
                                                >
                                                    <i className="uil uil-users-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Customers")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        profiletoggle('2');
                                                    }}
                                                >
                                                    <i className="uil uil-apps font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Projects")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '3'})}
                                                    onClick={() => {
                                                        profiletoggle('3');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Orders")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={() => {
                                                        profiletoggle('4');
                                                    }}
                                                >
                                                    <i className="uil uil-invoice font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Invoices")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '5'})}
                                                    onClick={() => {
                                                        profiletoggle('5');
                                                    }}
                                                >
                                                    <i className="uil uil-bolt-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Offers")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '6'})}
                                                    onClick={() => {
                                                        profiletoggle('6');
                                                    }}
                                                >
                                                    <i className="uil uil-shopping-cart-alt font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Events")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '7'})}
                                                    onClick={() => {
                                                        profiletoggle('7');
                                                    }}
                                                >
                                                    <i className="uil-channel-add font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Tasks")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '8'})}
                                                    onClick={() => {
                                                        profiletoggle('8');
                                                    }}
                                                >
                                                    <i className="uil uil-clock font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Timeslips")}</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: activeTab === '9'})}
                                                    onClick={() => {
                                                        profiletoggle('9');
                                                    }}
                                                >
                                                    <i className="uil uil-chart font-size-20"></i>
                                                    <span className="d-none d-sm-block">{props.t("Statistics")}</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab} className="p-4">
                                            <TabPane tabId="1">
                                                {customersForAmError && (
                                                        <Col className="col-12">
                                                            <Alert color="danger">{customersForAmError}</Alert>
                                                        </Col>
                                                )}
                                                {!customersForAmLoading &&
                                                    <MDBDataTable
                                                        responsive
                                                        hover
                                                        barReverse={true}
                                                        borderless
                                                        paginationLabel={[props.t("Previous"), props.t("Next")]}
                                                        entriesLabel={props.t("Show entries")}
                                                        infoLabel={[
                                                            props.t("Showing"),
                                                            props.t("to"),
                                                            props.t("of"),
                                                            props.t("entries"),
                                                        ]}
                                                        noRecordsFoundLabel={props.t("No matching records found")}
                                                        searchLabel={props.t("Search") + "..."}
                                                        displayEntries={500}
                                                        sortRows={[
                                                            "created",
                                                            "description",
                                                            "articleNumber",
                                                            "category",
                                                        ]}
                                                        noBottomColumns={true}
                                                        searching={true}
                                                        entries={500}
                                                        entriesOptions={[10, 100, 500]}
                                                        data={getDatatableData()}
                                                        onSearch={(text) => console.log(text)}
                                                    />}
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <ProjectListManagement uid={params.id}/>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                {user?.name && <CustomerOrderTable ourReference={user?.fortnoxUsername ? user?.fortnoxUsername : user?.name} from={encodeURIComponent(props.location.pathname)}/>}
                                            </TabPane>
                                            <TabPane tabId="4">
                                                {user?.name && <CustomerInvoiceTable ourReference={user?.fortnoxUsername ? user?.fortnoxUsername : user?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="5">
                                                {user?.name && <CustomerOfferTable ourReference={user?.fortnoxUsername ? user?.fortnoxUsername : user?.name}/>}
                                            </TabPane>
                                            <TabPane tabId="6">
                                                <EventManagement userId={params.id}/>
                                            </TabPane>
                                            <TabPane tabId="7">
                                                <TaskManagement tenantUser={user} />
                                            </TabPane>
                                            <TabPane tabId="8">
                                                <CustomerTimeslips filter={{userId: user?.id}} showAddTask={true}/>
                                            </TabPane>
                                            <TabPane tabId="9">
                                                {user?.name && <Dashboard ourReference={user?.fortnoxUsername ? user?.fortnoxUsername : user?.name}/>}
                                            </TabPane>
                                        </TabContent>
                                    </Card>
                                </Col></>}
                            </Row>
                        </>
                </Container>

                <DeleteModal
                    deleteCallback={() => handleDeleteUser()}
                    element={"User"}
                    t={(text) => props.t(text)}
                    showDeleteModal={modal_standard}
                    toggleModal={() => tog_standard()}
                />
                <OverridePwdModal
                    t={(text) => props.t(text)}
                    showOverridePwdModal={showOverridePwdModal && activeUser?.id}
                    toggleModal={() => togglePwdModal()}
                    userId={activeUser?.id}
                />
            </div>
        </React.Fragment>
    );
};

TenantUsers.propTypes = {
    users: PropTypes.array,
    onGetUserDetails: PropTypes.func,
    onEditProfile: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.TenantAdmin.user,
        tokenRegenerate: state.TenantAdmin.tokenRegenerate,
        userDeleted: state.TenantAdmin.userDeleted,
        userActivated: state.TenantAdmin.userActivated,
        loading: state.TenantAdmin.userLoading,
        error: state.TenantAdmin.adminUsersError,
        customers: state.Customer.customersForAM,
        customersForAmLoading: state.Customer.customersForAmLoading,
        customersForAmError: state.Customer.customersForAMError,
        orders: state.ecommerce.orderForAM?.Orders,
        ordersLoading: state.ecommerce.orderForAMLoading,
        ordersError: state.ecommerce.orderForAMError,
        editProfileLoading: state.Profile.editProfileLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onEditProfile: (data) => dispatch(editProfile(data)),
    onGetUserDetails: (id) => dispatch(getTenantUserDetails(id)),
    onGetCustomers : (id) => dispatch(getCustomerForAM(id)),
    onImpersonateUser: (obj, history, location) =>
        dispatch(impersonateUser(obj, history, location)),
    onResetRegenerateToken: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_REGENERATE_TOKEN,
        }),
    onResetDeleteUser: () =>
        dispatch({
            type: TENANT_ADMIN_RESET_DELETE_USER,
        }),
    onGetCustomersReset : () => dispatch(getCustomerForAMReset()),
    onGetUserDetailsReset: () => dispatch(getTenantUserDetailsReset())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(TenantUsers)));
