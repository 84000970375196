import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Alert,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";

//Import Breadcrumb
import {
  getContentTypes,
  getProductDetail,
  getProductDetailsReset,
} from "../../store/e-commerce/actions";
import ArticlesDetail from "../Articles/ArticlesDetail";
import { isEmpty } from "lodash";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../components/Common/Loader";
import CustomerArticlesDetail from "../Articles/CustomerArticlesDetail";
import NotFoundPage from "../../components/Error/NotFoundPage";

const ShopProductDetails = (props) => {
  const {
    article,
    match: { params },
    onGetProductDetail,
    loading,
    error,
    onGetProduceReset,
  } = props;

  const [product, setProduct] = useState({});
  const [selectCategory, setSelectedCategory] = useState({});
  const [brandSettings, setBrandSettings] = useState(null)

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    setBrandSettings(obj?.brandConfig);
    return () => {
      onGetProduceReset();
    };
  }, []);

  useEffect(() => {
    if (params && params.id) {
      onGetProductDetail(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (!isEmpty(article)) {
      setProduct(article);
      if (article.categories?.length > 0) {
        setSelectedCategory(article.categories[0]);
      }
    }
  }, [article]);

  return (
    <React.Fragment>
      <div className="page-content" key={"product-key-" + params.id}>
        <Container fluid={true}>
            <>
              <Col className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 width-591">
                    {/*{product != null && product.displayName
                      ? product.displayName
                      : product.description}*/}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <BreadcrumbItem>
                        <Link to="/customer/articles">{props.t("Articles")}</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link to="/customer/categories">{props.t("Categories")}</Link>
                      </BreadcrumbItem>
                      {selectCategory?.parents?.map(function (item, index) {
                        return (
                          <BreadcrumbItem active>
                            <Link to={`/customer/categories/${item.id}/subcategory`}>
                              {item.name}
                            </Link>
                          </BreadcrumbItem>
                        );
                      })}
                      <BreadcrumbItem>
                        {selectCategory != null && (
                          <Link to={`/customer/categories/${selectCategory.id}/articles`}>
                            {selectCategory.name}
                          </Link>
                        )}
                        <Link>
                          {selectCategory == null && props.t("Uncategorized")}
                        </Link>
                      </BreadcrumbItem>
                    </ol>
                  </div>
                </div>
              </Col>
              <Row>
                <Col lg="12">
                  {!loading && isEmpty(product) && <NotFoundPage/>}
                  <Loader loading={loading} title="Article Details" />
                  {!isEmpty(product) &&
                  <CustomerArticlesDetail
                    product={product}
                    showBuyOption={true}
                    key={product.id}
                    loading={loading}
                    error={error}
                  />}
                </Col>
              </Row>
            </>
        </Container>
      </div>
    </React.Fragment>
  );
};


ShopProductDetails.prototype = {
  article: PropTypes.object,
  error: PropTypes.object,
  loading: PropTypes.boolean,
  onGetProductDetail: PropTypes.func,
  onGetContentTypes: PropTypes.func,
  onGetProduceReset: PropTypes.func,
};

const mapStateToProps = ({ ecommerce }) => ({
  article: ecommerce.product,
  contentTypes: ecommerce.contentTypes,
  loading: ecommerce.productDetailLoading,
  error: ecommerce.productDetailError,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProductDetail: (id) => dispatch(getProductDetail(id)),
  onGetContentTypes: () => dispatch(getContentTypes()),
  onGetProduceReset: () => dispatch(getProductDetailsReset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShopProductDetails));
