import {
    ADD_ADDRESS,
    ADD_ADDRESS_FAIL,
    ADD_ADDRESS_SUCCESS,
    EDIT_ADDRESS,
    EDIT_ADDRESS_FAIL,
    EDIT_ADDRESS_SUCCESS,
    GET_ADDRESS,
    GET_ADDRESS_FAIL,
    GET_ADDRESS_LIST,
    GET_ADDRESS_LIST_FAIL,
    GET_ADDRESS_LIST_RESET,
    GET_ADDRESS_LIST_SUCCESS,
    GET_ADDRESS_SUCCESS,
    GET_CUSTOMER,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_FOR_AM,
    GET_CUSTOMER_FOR_AM_FAIL,
    GET_CUSTOMER_FOR_AM_RESET,
    GET_CUSTOMER_FOR_AM_SUCCESS,
    GET_CUSTOMER_LIST_FAIL,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_REST,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_USERS,
    GET_CUSTOMER_USERS_FAIL,
    GET_CUSTOMER_USERS_RESET,
    GET_CUSTOMER_USERS_SUCCESS,
    RESET_DATA,
} from "./actionTypes";

const INIT_STATE = {
    customers: [],
    customersLoading: false,
    customer: {},
    customerUsers: [],
    customerUser: {},
    addresses: [],
    address: {},
    loading: false,
    addEditSuccess: false,
    customerUsersLoading: false,
    customerUsersError: null,
    customerLoading: false,
    getCustomersError: null,
    customersForAM:[],
    customersForAmLoading: false,
    customersForAMError: null,
    getAddressesError: null,
};

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMER:
            return {
                ...state,
                customersLoading: true,
                customers: []
            }
        case GET_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                customersLoading: false,
                customers: action.payload,
                getCustomersError: null,
            }
        case GET_CUSTOMER_LIST_FAIL:
            return {
                ...state,
                customersLoading: false,
                getCustomersError: action.payload,
            }
        case GET_CUSTOMER:
            return {
                ...state,
                customerLoading: true
            }
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
                customerLoading: false
            }
        case GET_CUSTOMER_FAIL:
            return {
                ...state,
                customers: null,
                customerLoading: false
            }
        case GET_CUSTOMER_USERS:
            return {
                ...state,
                customerUsersLoading: true,
            }
        case GET_CUSTOMER_USERS_SUCCESS:
            return {
                ...state,
                customerUsers: action.payload,
                customerUsersLoading: false,
            }
        case GET_CUSTOMER_USERS_FAIL:
            return {
                ...state,
                customerUsersError: action.payload,
                customerUsersLoading: false,
            }
        case GET_CUSTOMER_USERS_RESET:
            return {
                ...state,
                customerUsersError: null,
                customerUsersLoading: false,
                customerUsers: []
            }
        case GET_ADDRESS_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_ADDRESS_LIST_SUCCESS:
            return {
                ...state,
                addresses: action.payload,
                loading: false,
            }
        case GET_ADDRESS_LIST_FAIL:
            return {
                ...state,
                getAddressesError: action.payload,
                loading: false,
            }
        case GET_ADDRESS_LIST_RESET:
            return {
                ...state,
                getAddressesError: null,
                loading: false,
                addresses: [],
            }
        case GET_ADDRESS:
            return {
                ...state,
                loading: true,
            }
        case GET_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                loading: false,
            }
        case GET_ADDRESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case ADD_ADDRESS:
            return {
                ...state,
                loading: true,
                addEditSuccess: false,
            }
        case ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                addEditSuccess: true,
                loading: false,
            }
        case ADD_ADDRESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                addEditSuccess: false,
            }
        case EDIT_ADDRESS:
            return {
                ...state,
                loading: true,
                addEditSuccess: false,
            }
        case EDIT_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload,
                addEditSuccess: true,
                loading: false,
            }
        case EDIT_ADDRESS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                addEditSuccess: false,
            }
        case GET_CUSTOMER_REST:
            return {
                ...state,
                customer: null,
                customerLoading: false
            }
        case RESET_DATA:
            return {
                ...state,
                address: {},
                errorResponse: null,
                loading: false,
                addEditSuccess: false
            }
        case GET_CUSTOMER_FOR_AM:
            return {
                ...state,
                customersForAM:[],
                customersForAmLoading: true
            }
        case GET_CUSTOMER_FOR_AM_SUCCESS:
            return {
                ...state,
                customersForAM: action.payload,
                customersForAmLoading: false
            }
        case GET_CUSTOMER_FOR_AM_FAIL:
            return {
                ...state,
                customersForAM: [],
                customersForAmLoading: false,
                customersForAMError: action.payload
            }
        case GET_CUSTOMER_FOR_AM_RESET:
            return {
                ...state,
                customersForAM: [],
                customersForAmLoading: false,
                customersForAMError: null,
            }
        default:
            return state;
    }
};

export default Customer
