export const GET_PROJECT_LIST = "GET_PROJECT_LIST"
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS"
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL"
export const GET_PROJECT_LIST_RESET = "GET_PROJECT_LIST_RESET"

export const GET_IMPERSONATION_PROJECT_LIST = "GET_IMPERSONATION_PROJECT_LIST"
export const GET_IMPERSONATION_PROJECT_LIST_SUCCESS = "GET_IMPERSONATION_PROJECT_LIST_SUCCESS"
export const GET_IMPERSONATION_PROJECT_LIST_FAIL = "GET_IMPERSONATION_PROJECT_LIST_FAIL"

export const GET_PROJECT_LIST_BY_PARTICIPANT = "GET_PROJECT_LIST_BY_PARTICIPANT"
export const GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS = "GET_PROJECT_LIST_BY_PARTICIPANT_SUCCESS"
export const GET_PROJECT_LIST_BY_PARTICIPANT_FAIL = "GET_PROJECT_LIST_BY_PARTICIPANT_FAIL"
export const GET_PROJECT_LIST_BY_PARTICIPANT_RESET = "GET_PROJECT_LIST_BY_PARTICIPANT_RESET"

export const GET_PROJECT_USERS = "GET_PROJECT_USERS"
export const GET_PROJECT_USERS_SUCCESS = "GET_PROJECT_USERS_SUCCESS"
export const GET_PROJECT_USERS_FAIL = "GET_PROJECT_USERS_FAIL"

export const GET_PROJECT_LIST_BY_LEADER = "GET_PROJECT_LIST_BY_LEADER"
export const GET_PROJECT_LIST_BY_LEADER_SUCCESS = "GET_PROJECT_LIST_BY_LEADER_SUCCESS"
export const GET_PROJECT_LIST_BY_LEADER_FAIL = "GET_PROJECT_LIST_BY_LEADER_FAIL"
export const GET_PROJECT_LIST_BY_LEADER_RESET = "GET_PROJECT_LIST_BY_LEADER_RESET"