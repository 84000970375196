export const GET_IMPERSONATION_USERS = "GET_IMPERSONATION_TENANT_ADMINS"
export const GET_IMPERSONATION_USERS_SUCCESS = "GET_IMPERSONATION_TENANT_ADMINS_SUCCESS"
export const GET_IMPERSONATION_USERS_FAIL = "GET_IMPERSONATION_TENANT_ADMINS_FAIL"
export const POST_IMPERSONATE_USER = "POST_IMPERSONATE_USER"
export const POST_IMPERSONATE_USER_SUCCESS = "POST_IMPERSONATE_USER_SUCCESS"
export const GET_IMPERSONATE_USER_EXIT = "GET_IMPERSONATE_USER_EXIT"
export const GET_IMPERSONATE_USER_EXIT_SUCCESS = "GET_IMPERSONATE_USER_EXIT_SUCCESS"

export const GET_TENANT_LIST = "GET_TENANT_LIST"
export const GET_TENANT_LIST_SUCCESS = "GET_TENANT_LIST_SUCCESS"
export const GET_TENANT_LIST_FAIL = "GET_TENANT_LIST_FAIL"

export const GET_CUSTOMER_LIST_IMPERSONATION = "GET_CUSTOMER_LIST_IMPERSONATION"
export const GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS = "GET_CUSTOMER_LIST_IMPERSONATION_SUCCESS"
export const GET_CUSTOMER_LIST_IMPERSONATION_FAIL = "GET_CUSTOMER_LIST_IMPERSONATION_FAIL"