import React from "react";

const CategoryRibbon = props => {
    let {categoryList } = props
    return (
        <>
            {categoryList?.map(function(element,index){
                return <div key={"ribbon"+index} className="product-ribbon badge bg-soft-secondary bg-secondary me-1">{element.name}</div>
            })}
        </>
    )

}

export default CategoryRibbon;
