import {
    GET_ARTICLE_GROUP_FAIL,
    GET_ARTICLE_GROUP_LIST_FAIL,
    GET_ARTICLE_GROUP_LIST_RESET,
    GET_ARTICLE_GROUP_LIST_SUCCESS,
    GET_ARTICLE_GROUP_RESET,
    GET_ARTICLE_GROUP_SUCCESS,
    GET_CART_DATA_FAIL,
    GET_CART_DATA_SUCCESS,
    GET_CATEGORY_LIST_FAIL,
    GET_CATEGORY_LIST_RESET,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CONTENT_TYPE_FAIL,
    GET_CONTENT_TYPE_FOR_ARTICLE,
    GET_CONTENT_TYPE_FOR_ARTICLE_FAIL,
    GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS,
    GET_CONTENT_TYPE_RESET,
    GET_CONTENT_TYPE_SUCCESS,
    GET_CUSTOMER,
    GET_CUSTOMER_FAIL,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    GET_DASHBOARD_LINE_CHART,
    GET_DASHBOARD_LINE_CHART_FAIL,
    GET_DASHBOARD_LINE_CHART_SUCCESS,
    GET_DASHBOARD_METRICS_LIST,
    GET_DASHBOARD_METRICS_LIST_FAIL,
    GET_DASHBOARD_METRICS_LIST_SUCCESS,
    GET_DEFAULT_LAYOUT_FAIL,
    GET_DEFAULT_LAYOUT_SUCCESS,
    GET_FAVORITE_PRODUCTS,
    GET_FAVORITE_PRODUCTS_FAIL,
    GET_FAVORITE_PRODUCTS_RESET,
    GET_FAVORITE_PRODUCTS_SUCCESS,
    GET_FAVOURITES,
    GET_FAVOURITES_FAIL,
    GET_FAVOURITES_RESET,
    GET_FAVOURITES_SUCCESS,
    GET_FIELDS,
    GET_FIELDS_FAIL,
    GET_FIELDS_RESET,
    GET_FIELDS_SUCCESS,
    GET_GLOBAL_SEARCH,
    GET_GLOBAL_SEARCH_FAIL,
    GET_GLOBAL_SEARCH_RESET,
    GET_GLOBAL_SEARCH_SUCCESS,
    GET_GROUP_BY_ARTICLE_FAIL,
    GET_GROUP_BY_ARTICLE_SUCCESS,
    GET_LAYOUT_FAIL,
    GET_LAYOUT_LIST_FAIL,
    GET_LAYOUT_LIST_RESET,
    GET_LAYOUT_LIST_SUCCESS,
    GET_LAYOUT_RESET,
    GET_LAYOUT_SUCCESS,
    GET_ORDER_METRICS,
    GET_ORDER_METRICS_ERROR,
    GET_ORDER_METRICS_SUCCESS,
    GET_ORDERS,
    GET_ORDERS_BY_REFERENCE,
    GET_ORDERS_BY_REFERENCE_FAIL,
    GET_ORDERS_BY_REFERENCE_RESET,
    GET_ORDERS_BY_REFERENCE_SUCCESS,
    GET_ORDERS_DETAILS,
    GET_ORDERS_DETAILS_FAIL,
    GET_ORDERS_DETAILS_RESET,
    GET_ORDERS_DETAILS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDERS_FOR_AM,
    GET_ORDERS_FOR_AM_FAIL,
    GET_ORDERS_FOR_AM_RESET,
    GET_ORDERS_FOR_AM_SUCCESS,
    GET_ORDERS_RESET,
    GET_ORDERS_SUCCESS,
    GET_PRICE_LIST,
    GET_PRICE_LIST_FAIL,
    GET_PRICE_LIST_FOR_TENANT,
    GET_PRICE_LIST_FOR_TENANT_ERROR,
    GET_PRICE_LIST_FOR_TENANT_SUCCESS,
    GET_PRICE_LIST_SUCCESS,
    GET_PRODUCT_BY_CATEGORY,
    GET_PRODUCT_BY_CATEGORY_FAIL,
    GET_PRODUCT_BY_CATEGORY_RESET,
    GET_PRODUCT_BY_CATEGORY_SUCCESS,
    GET_PRODUCT_BY_GROUP,
    GET_PRODUCT_BY_GROUP_FAIL,
    GET_PRODUCT_BY_GROUP_SUCCESS,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_FAIL,
    GET_PRODUCT_DETAIL_RESET,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_RESET,
    GET_PRODUCTS_SUCCESS,
    GET_SHOPS_FAIL,
    GET_SHOPS_SUCCESS,
    GET_SUB_CATEGORY_LIST_FAIL,
    GET_SUB_CATEGORY_LIST_RESET,
    GET_SUB_CATEGORY_LIST_SUCCESS,
    GET_TEMPLATE_DETAILS,
    GET_TEMPLATE_DETAILS_FAIL,
    GET_TEMPLATE_DETAILS_SUCCESS,
    GET_TEMPLATE_LIST_FAIL,
    GET_TEMPLATE_LIST_RESET,
    GET_TEMPLATE_LIST_SUCCESS,
    POST_ORDER,
    POST_ORDER_FAIL,
    POST_ORDER_RESET,
    POST_ORDER_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    products: {},
    product: {},
    orders: {},
    favoriteProducts: {},
    orderError: null,
    orderSuccess: null,
    orderPending: false,
    cartData: {},
    customers: [],
    customer: {},
    shops: [],
    error: null,
    contentTypes: [],
    contentTypesForArticle: [],
    categoryList: [],
    subCategoryList: {},
    articlesByCategory: {},
    articlesByGroup: {},
    articlesByGroupPending: false,
    articlesByGroupError:false,
    layoutList: [],
    defaultLayoutList: [],
    layout: {},
    priceList: [],
    loading: false,
    productDetailLoading: false,
    productDetailError: null,
    orderDetails: {},
    orderDetailsError: null,
    orderDetailsLoading: false,
    getArticlesPending: false,
    getArticlesError: null,
    articlesByCategoryPending: false,
    articlesByCategoryError: null,
    globalSearch: {},
    globalSearchLoading: true,
    getOrdersError: null,
    getCategoryListError: null,
    getSubCategoryListError: null,
    getLayoutListError: null,
    getLayoutError: null,
    orderForAM: [],
    orderForAMLoading: false,
    ordersByReference: [],
    ordersByReferenceLoading: false,
    groupList: [],
    articleGroup: {},
    groupByArticle: [],
    getContentTypeError: null,
    ordersByReferenceError: null,
    orderForAMError: null,
    groupListError: null,
    articleGroupError: null,
    fields: [],
    getFieldsLoading: false,
    getFieldsError: null,
    templateList:[],
    templateListError: null,
    templateDetails: {},
    templateDetailsError: null,
    favourites: [],
    getFavouritesLoading: false,
    getFavouritesError: null,
    orderMetrics: {},
    orderMetricsError: null,
    orderMetricsLoading: false,
    lineChartMetrics: {},
    lineChartMetricsLoading: false,
    lineChartMetricsError: null,
    lineChartMetricsList: {},
    lineChartMetricsListLoading: false,
    lineChartMetricsListError: null,
    priceListForTenant : [],
    priceListForTenantLoading: false,
    priceListForTenantError: null,
}

const Ecommerce = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: [],
                getArticlesPending: true,
            }
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                getArticlesPending: false,
            }

        case GET_PRODUCTS_FAIL:
            return {
                ...state,
                getArticlesError: action.payload,
                getArticlesPending: false,
            }
        case GET_PRODUCTS_RESET:
            return {
                ...state,
                getArticlesError: null,
                products: [],
            }
        case GET_FAVORITE_PRODUCTS:
            return {
                ...state,
                getArticlesPending: true,
            }
        case GET_FAVORITE_PRODUCTS_SUCCESS:
            return {
                ...state,
                favoriteProducts: action.payload,
                getArticlesPending: false,
            }

        case GET_FAVORITE_PRODUCTS_FAIL:
            return {
                ...state,
                getArticlesError: action.payload,
                getArticlesPending: false,
            }
        case GET_FAVORITE_PRODUCTS_RESET:
            return {
                ...state,
                getArticlesError: null,
                favoriteProducts: action.payload ? [] : state.favoriteProducts
            }

        case GET_PRODUCT_DETAIL:
            return {
                ...state,
                productDetailLoading: true,
            }

        case GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                productDetailLoading: false,
                product: action.payload,
            }

        case GET_PRODUCT_DETAIL_FAIL:
            return {
                ...state,
                productDetailLoading: false,
                productDetailError: action.payload,
            }

        case GET_PRODUCT_DETAIL_RESET:
            return {
                ...state,
                product: {},
                productDetailError: null,
            }
        case GET_ORDERS: {
            return {
                ...state,
                orders : {}
            }
        }
        case GET_ORDERS_SUCCESS:
            return {
                ...state,
                orders: action?.payload ? action?.payload : {},
            }

        case GET_ORDERS_FAIL:
            return {
                ...state,
                getOrdersError: action.payload,
            }
        case GET_ORDERS_RESET:
            return {
                ...state,
                orders: {},
                getOrdersError: null,
            }
        case POST_ORDER:
            return {
                ...state,
                orderPending: true,
                orderError: null,
                orderSuccess: null,
            }

        case POST_ORDER_SUCCESS:
            return {
                ...state,
                orderSuccess: action.payload,
                orderPending: false,
                orderError: null
            }

        case POST_ORDER_FAIL:
            return {
                ...state,
                orderError: action.payload,
                orderPending: false,
                orderSuccess: null,
            }

        case POST_ORDER_RESET:
            return {
                ...state,
                orderError: null,
                orderPending: false,
                orderSuccess: null,
            }

        case GET_CART_DATA_SUCCESS:
            return {
                ...state,
                cartData: action.payload,
            }

        case GET_CART_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
            }

        case GET_CUSTOMERS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_CUSTOMER:
            return {
                ...state,
                loading: true,
            }

        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
                loading: false,
            }

        case GET_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_SHOPS_SUCCESS:
            return {
                ...state,
                shops: action.payload,
            }

        case GET_SHOPS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_CONTENT_TYPE_SUCCESS:
            return {
                ...state,
                contentTypes: action.payload
            }

        case GET_CONTENT_TYPE_FAIL:
            return {
                ...state,
                getContentTypeError: action.payload
            }

        case GET_CONTENT_TYPE_RESET:
            return {
                ...state,
                getContentTypeError: null,
            }

        case GET_FIELDS:
            return {
                ...state,
                getFieldsLoading: true
            }

        case GET_FIELDS_SUCCESS:
            return {
                ...state,
                fields: action.payload,
                getFieldsLoading: false
            }

        case GET_FIELDS_FAIL:
            return {
                ...state,
                getFieldsError: action.payload,
                getFieldsLoading: false
            }

        case GET_FIELDS_RESET:
            return {
                ...state,
                getFieldsError: null,
                getFieldsLoading: false,
            }

        case GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
                getCategoryListError: null,
            }

        case GET_CATEGORY_LIST_FAIL:
            return {
                ...state,
                getCategoryListError: action.payload
            }

        case GET_CATEGORY_LIST_RESET:
            return {
                ...state,
                getCategoryListError: null,
                categoryList: [],
            }

        case GET_SUB_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                subCategoryList: action.payload,
                getSubCategoryListError: null,
            }

        case GET_SUB_CATEGORY_LIST_FAIL:
            return {
                ...state,
                getSubCategoryListError: action.payload
            }
        case GET_SUB_CATEGORY_LIST_RESET:
            return {
                ...state,
                subCategoryList: [],
                getSubCategoryListError: null,
            }
        case GET_PRODUCT_BY_CATEGORY:
            return {
                ...state,
                articlesByCategoryPending: true
            }
        case GET_PRODUCT_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                articlesByCategory: action.payload,
                articlesByCategoryPending: false,
            }

        case GET_PRODUCT_BY_CATEGORY_RESET:
            return {
                ...state,
                articlesByCategoryError: null,
                articlesByCategory: [],
            }
        case GET_PRODUCT_BY_GROUP:
            return {
                ...state,
                articlesByGroupPending: true
            }
        case GET_PRODUCT_BY_GROUP_SUCCESS:
            return {
                ...state,
                articlesByGroup: action.payload,
                articlesByGroupPending: false
            }
        case GET_PRODUCT_BY_GROUP_FAIL:
            return {
                ...state,
                articlesByGroupError: action.payload,
                articlesByGroupPending: false
            }
        case GET_PRODUCT_BY_CATEGORY_FAIL:
            return {
                ...state,
                articlesByCategoryError: action.payload,
                articlesByCategoryPending: false
            }

        case GET_LAYOUT_LIST_SUCCESS:
            return {
                ...state,
                layoutList: action.payload,
                getLayoutListError: null,
            }

        case GET_LAYOUT_LIST_FAIL:
            return {
                ...state,
                getLayoutListError: action.payload
            }

        case GET_LAYOUT_LIST_RESET:
            return {
                ...state,
                layoutList: [],
                getLayoutListError: null,
            }

        case GET_DEFAULT_LAYOUT_SUCCESS:
            return {
                ...state,
                defaultLayoutList: action.payload
            }

        case GET_DEFAULT_LAYOUT_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_LAYOUT_SUCCESS:
            return {
                ...state,
                layout: action.payload,
                getLayoutError: null,
            }
        case GET_LAYOUT_FAIL:
            return {
                ...state,
                getLayoutError: action.payload
            }
        case GET_LAYOUT_RESET:
            return {
                ...state,
                layout: {},
                getLayoutError: null,
            }

        case GET_PRICE_LIST:
            return {
                ...state,
            }
        case GET_PRICE_LIST_SUCCESS:
            return {
                ...state,
                priceList: action.payload
            }
        case GET_PRICE_LIST_FAIL:
            return {
                ...state,
                error: action.payload
            }
        case GET_ORDERS_DETAILS:
            return {
                ...state,
                orderDetailsLoading: true
            }
        case GET_ORDERS_DETAILS_SUCCESS:
            return {
                ...state,
                orderDetails: action.payload,
                orderDetailsLoading: false
            }
        case GET_ORDERS_DETAILS_FAIL:
            return {
                ...state,
                orderDetailsError: action.payload,
                orderDetailsLoading: false
            }

        case GET_ORDERS_DETAILS_RESET:
            return {
                ...state,
                orderDetails: {},
                orderDetailsLoading: false,
                orderDetailsError: null
            }
        case GET_GLOBAL_SEARCH:
            return {
                ...state,
                globalSearchLoading: true
            }
        case GET_GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                globalSearch: action.payload,
                globalSearchLoading: false
            }
        case GET_GLOBAL_SEARCH_FAIL: {
            return {
                ...state,
                error: action.error,
                globalSearchLoading: false
            }
        }
        case GET_GLOBAL_SEARCH_RESET: {
            return {
                ...state,
                globalSearch: action.payload,
                globalSearchLoading: false
            }
        }
        case GET_ORDERS_FOR_AM:
            return {
                ...state,
                orderForAM: [],
                orderForAMLoading: true
            }
        case GET_ORDERS_FOR_AM_SUCCESS:
            return {
                ...state,
                orderForAM: action.payload,
                orderForAMLoading: false
            }
        case GET_ORDERS_FOR_AM_FAIL:
            return {
                ...state,
                orderForAM: [],
                orderForAMLoading: false,
                orderForAMError: action.payload,
            }
        case GET_ORDERS_FOR_AM_RESET:
            return {
                ...state,
                orderForAM: [],
                orderForAMLoading: false,
                orderForAMError: null,
            }
        case GET_ORDERS_BY_REFERENCE:
            return {
                ...state,
                ordersByReference: [],
                ordersByReferenceLoading: true
            }
        case GET_ORDERS_BY_REFERENCE_SUCCESS:
            return {
                ...state,
                ordersByReference: action.payload,
                ordersByReferenceLoading: false
            }
        case GET_ORDERS_BY_REFERENCE_FAIL:
            return {
                ...state,
                ordersByReferenceLoading: false,
                ordersByReferenceError: action.payload,
            }
        case GET_ORDERS_BY_REFERENCE_RESET:
            return {
                ...state,
                ordersByReference: [],
                ordersByReferenceLoading: false,
                ordersByReferenceError: null,
            }
        case GET_ARTICLE_GROUP_LIST_SUCCESS:
            return {
                ...state,
                groupList: action.payload
            }
        case GET_ARTICLE_GROUP_LIST_FAIL:
            return {
                ...state,
                groupList: [],
                groupListError: action.payload,
            }
        case GET_ARTICLE_GROUP_LIST_RESET:
            return {
                ...state,
                groupList: [],
                groupListError: null,
            }
        case GET_ARTICLE_GROUP_SUCCESS:
            return {
                ...state,
                articleGroup: action.payload
            }
        case GET_ARTICLE_GROUP_FAIL:
            return {
                ...state,
                articleGroup: {},
                articleGroupError: action.payload,
            }
        case GET_ARTICLE_GROUP_RESET:
            return {
                ...state,
                articleGroup: {},
                articleGroupError: null,
            }
        case GET_GROUP_BY_ARTICLE_SUCCESS:
            return {
                ...state,
                groupByArticle: action.payload
            }
        case GET_GROUP_BY_ARTICLE_FAIL:
            return {
                ...state,
                groupByArticle: []
            }
        case GET_CONTENT_TYPE_FOR_ARTICLE:
            return {
                ...state,
                contentTypesForArticle: []
            }
        case GET_CONTENT_TYPE_FOR_ARTICLE_SUCCESS:
            return {
                ...state,
                contentTypesForArticle: action.payload
            }
        case GET_CONTENT_TYPE_FOR_ARTICLE_FAIL:
            return {
                ...state,
                contentTypesForArticle: []
            }
        case GET_TEMPLATE_LIST_RESET:
            return {
                ...state,
                templateList: []
            }
        case GET_TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                templateList: action.payload
            }
        case GET_TEMPLATE_LIST_FAIL:
            return {
                ...state,
                templateList: []
            }
        case GET_TEMPLATE_DETAILS:
            return {
                ...state,
                templateDetails: {}
            }
        case GET_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                templateDetails: action.payload
            }
        case GET_TEMPLATE_DETAILS_FAIL:
            return {
                ...state,
                templateDetailsError: action.payload
            }
        case GET_FAVOURITES:
            return {
                ...state,
                getFavouritesLoading: true
            }

        case GET_FAVOURITES_SUCCESS:
            return {
                ...state,
                favourites: action.payload,
                getFavouritesLoading: false
            }

        case GET_FAVOURITES_FAIL:
            return {
                ...state,
                getFavouritesError: action.payload,
                getFavouritesLoading: false
            }

        case GET_FAVOURITES_RESET:
            return {
                ...state,
                getFavouritesError: null,
                getFavouritesLoading: false,
            }
        case GET_ORDER_METRICS:
            return {
                ...state,
                orderMetrics: null,
                orderMetricsError: null,
                orderMetricsLoading: true
            }
        case GET_ORDER_METRICS_SUCCESS:
            return {
                ...state,
                orderMetrics: action.payload,
                orderMetricsError: null,
                orderMetricsLoading: false
            }
        case GET_ORDER_METRICS_ERROR:
            return {
                ...state,
                orderMetrics: null,
                orderMetricsError: action.payload,
                orderMetricsLoading: false
            }
        case GET_DASHBOARD_LINE_CHART:
            return {
                ...state,
                lineChartMetrics: null,
                lineChartMetricsLoading: true,
                lineChartMetricsError: null
            }
        case GET_DASHBOARD_LINE_CHART_SUCCESS:
            return {
                ...state,
                lineChartMetrics: action.payload,
                lineChartMetricsLoading:  false,
                lineChartMetricsError: null
            }
        case GET_DASHBOARD_LINE_CHART_FAIL:
            return {
                ...state,
                lineChartMetrics: null,
                lineChartMetricsLoading: false,
                lineChartMetricsError: action.payload
            }
        case GET_DASHBOARD_METRICS_LIST:
            return {
                ...state,
                lineChartMetricsList: null,
                lineChartMetricsListLoading: true,
                lineChartMetricsListError: null
            }
        case GET_DASHBOARD_METRICS_LIST_SUCCESS:
            return {
                ...state,
                lineChartMetricsList: action.payload,
                lineChartMetricsListLoading:  false,
                lineChartMetricsListError: null
            }
        case GET_DASHBOARD_METRICS_LIST_FAIL:
            return {
                ...state,
                lineChartMetricsList: null,
                lineChartMetricsListLoading: false,
                lineChartMetricsListError: action.payload
            }
        case GET_PRICE_LIST_FOR_TENANT:
            return {
                ...state,
                priceListForTenant: null,
                priceListForTenantLoading: true,
                priceListForTenantError: null

            }
        case GET_PRICE_LIST_FOR_TENANT_SUCCESS:
            return {
                ...state,
                priceListForTenant: action.payload,
                priceListForTenantLoading: false,
                priceListForTenantError: null
            }
        case GET_PRICE_LIST_FOR_TENANT_ERROR:
            return {
                ...state,
                priceListForTenant: null,
                priceListForTenantLoading: false,
                priceListForTenantError: action.payload
            }
        default:
            return state
    }
}

export default Ecommerce
