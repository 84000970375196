import {
    GET_AUDIT, GET_AUDIT_SUCCESS, GET_AUDIT_FAIL, GET_AUDIT_RESET, GET_FILTERED_AUDIT, GET_FILTERED_AUDIT_SUCCESS, GET_FILTERED_AUDIT_FAIL, GET_FILTERED_AUDIT_RESET
} from "./actionTypes";

export const getAudit = (filter) => ({
    type: GET_AUDIT,
    filter
});

export const getAuditSuccess = (response) => ({
    type: GET_AUDIT_SUCCESS,
    payload: response.data
});

export const getAuditFail = (msg) => ({
    type: GET_AUDIT_FAIL,
    payload: msg
});

export const getAuditReset = (msg) => ({
    type: GET_AUDIT_RESET,
    payload: msg
});

export const getFilteredAudit = (search) => ({
    type: GET_FILTERED_AUDIT,
    search
});

export const getFilteredAuditSuccess = (response) => ({
    type: GET_FILTERED_AUDIT_SUCCESS,
    payload: response.data
});

export const getFilteredAuditFail = (msg) => ({
    type: GET_FILTERED_AUDIT_FAIL,
    payload: msg
});

export const getFilteredAuditReset = (msg) => ({
    type: GET_FILTERED_AUDIT_RESET,
    payload: msg
});