import {
    GET_BRAND_SETTINGS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL, GET_BRAND_SETTINGS_SUCCESS,
    POST_BRAND_SETTINGS, POST_BRAND_SETTINGS_SUCCESS, POST_RESET_BRAND_COLORS, POST_RESET_BRAND_COLORS_SUCCESS, POST_RESET_BRAND_COLORS_FAIL
} from "./actionTypes";


export const getBrandSettings = () => ({
    type: GET_BRAND_SETTINGS,
});

export const postBrandSettings = brandSettings => ({
    type: POST_BRAND_SETTINGS,
    brandSettings
});

export const getBrandSettingsSuccess = (response) => ({
    type: GET_BRAND_SETTINGS_SUCCESS,
    payload: response.data
});

export const postBrandSettingsSuccess = (response) => ({
    type: POST_BRAND_SETTINGS_SUCCESS,
    payload: response.data
});

export const getBrandSettingsIncludingSaSettings = () => ({
    type: GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS,
});

export const getBrandSettingsIncludingSaSettingsSuccess = (response) => ({
    type: GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS,
    payload: response.data
});

export const getBrandSettingsIncludingSaSettingsFail = (msg) => ({
    type: GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL,
    payload: msg
});

export const postResetBrandColors = () => ({
    type: POST_RESET_BRAND_COLORS,
});

export const postResetBrandColorsSuccess = (response) => ({
    type: POST_RESET_BRAND_COLORS_SUCCESS,
    payload: response.data
});

export const postResetBrandColorsFail = (msg) => ({
    type: POST_RESET_BRAND_COLORS_FAIL,
    payload: msg
});