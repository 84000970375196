import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect, useDispatch} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import {Alert, Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import classnames from "classnames";
import {find, isEmpty} from "lodash";
import {BASE_IMAGE_URL, DOWNLOAD_URL} from "../../helpers/url_helper";
import {getContentTypeForArticle, getFavourites, getGroupsByArticle} from "../../store/e-commerce/actions";
import {withTranslation} from "react-i18next";
import {CartService} from "../ShoppingCart/CartService";
import {getAllCartItems, putUpdateCartItemFromInput, resetCartMessage} from "../../store/e-commerce/cart/actions";
import {Avatar, Chip, Divider} from "@material-ui/core";
import ImageGallery from "react-image-gallery";
import "../../assets/scss/done.scss";
import "./productCarousel.css";
import useStyles from "../../util/styles";
import TableToolbar from "../../components/Common/TableToolbar";
import {get, put} from "../../helpers/api_helper";
import Loader from "../../components/Common/Loader";
import {getArticleConfig} from "../../store/actions";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
import {isStockable} from "../../util/articleUtil";
import cogoToast from "cogo-toast";
import {toastOptions} from "../../util/dataUtil";
import {evaluate} from "mathjs";
import {debounce, preventNumberInputScroll} from "../../util/helperFunctions";


const ArticlesDetail = (props) => {
  const classes = useStyles();
  const {
    match: { params },
    product,
    showBuyOption,
    onGetContentTypes,
    cartItems,
    onGetArticleConfig, 
    articleConfig,
    error,
    onGetFavourites,
    favourites,
    getFavouritesLoading,
    groupByArticle,
    onGetGroupListByArticle,
    cartItemsLoading,
    cartItemError,
    onResetCartMessage
  } = props;
  const [activeDescriptionTab, setDescriptionActiveTab] = useState("");
  const [brandSettings, setBrandSettings] = useState(null);
  const [favoriteList, setFavoriteList] = useState([]);
  const [articleProductList, setArticleProductList] = useState([]);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [articleFavouriteListLoading, setArticleFavouriteListLoading] = useState(false);
  const [quantityInput, setQuantityInput] = useState(0);
  const [articlePrice, setArticlePrice] = useState('');
  const [manualPackageCartQuantity, setManualPackageCartQuantity] = useState(null);
  const [articlePackagePrice, setArticlePackagePrice] = useState(null);

  const dispatch = useDispatch();

  let qtyClickCount = 0, qtyPrevClickCount = 0;

  let priceList = user?.config?.priceList;

  useEffect(() => {
    onGetArticleConfig();
    setBrandSettings(user?.brandConfig);
    onGetFavourites()
  }, []);

  useEffect(() => {
    if(!isEmpty(product)) {
      onGetContentTypes(product.id);
      let price = product.specialPrice
          ? product.specialPrice
          : product.salesPrice;
      setArticlePrice(price)
      if(product?.articlePackageType == "DYNAMIC"){
        setArticlePackagePrice(price);
      }
      if(product.tabs && product.tabs.length > 0){
        setDescriptionActiveTab(product.tabs[0].id);
      }
    }
  }, [product])

  useEffect(() => {
    if(favourites){
      setFavoriteList(favourites);  
      if(user?.customerId){
        getAndSetArticleProductList();
      }
    }
  }, [favourites])

  useEffect(() => {
    if(product && cartItems){
      const cartItem = find(cartItems, item => item.article.id == product.id);
      setQuantityInput(cartItem ? cartItem.quantity : 0);
    }
  }, [cartItems, product]);

  useEffect(() => {
    if(cartItemError){
      cogoToast.error(cartItemError, toastOptions)
      setTimeout(() => {
        onResetCartMessage();
      }, 2000)
    }
  }, [cartItemError])

  const getAndSetArticleProductList = async () => {
    if(user?.customerId && product.id){
      setArticleFavouriteListLoading(true);
      try {
        const response = await get(`/api/productLists/articles/${product.id}/productListArticleInfo`);
        setFavoriteList(favourites.map(f => {
          const paf = response.data?.find(af => af.favoriteList.id === f.id);
          return {...f, label: !paf || paf?.customer ? f.name : f.name + '*', disabled: !(!paf || paf?.customer)};
        }))
        setArticleProductList(response.data === "" ? [] : response.data);
      } catch(err){
        cogoToast.error(err.rsponse?.data[0], toastOptions);
      }
      setArticleFavouriteListLoading(false);
    }
  }

  const toggledescription = (tab) => {
    if (activeDescriptionTab !== tab) {
      setDescriptionActiveTab(tab);
    }
  };

  const openCategoryProduct = (category) => {
    if (showBuyOption) {
      history.push(`/customer/categories/${category.id}/articles`);
    }
  };

  const openProductList = (pl) => {
    if (showBuyOption) {
      history.push(`${user?.customerId ? '/customer' : ''}/productLists/${pl.id}/articles`)
    }
  };

  const handleDownload = (url) => {
    window.location.href = url;
  };

  const getQuantityRowValue = (item) => {
      if(!isStockable(item)){
          return "";
      }

      if(articleConfig && item?.disposableQuantity){
          if(articleConfig.articleQuantityConfig === "COLOR_CODING"){
              return item.disposableQuantity > 0 ? 
              <div className="product-ribbon badge bg-soft-success bg-success float-end mt-3">
                  {" "}
                  {props.t("In Stock")}{" "}
              </div> : 
              <div className="product-ribbon badge bg-soft-danger bg-danger float-end mt-3">
                  {" "}
                  {props.t("Out of Stock")}{" "}
              </div>
          } else {
              return (
              <label className="text-muted font-size-13 float-end mx-1">
                  <p className={item.disposableQuantity > 0 ? "text-success" : "text-danger"}><i className="uil-shopping-cart-alt"></i> &nbsp;{item.disposableQuantity}</p>
              </label>)
          }
      } else {
          return "0";
      }
  }

  const getFieldValue = (field) => {
    var result = []
    if (field.fieldType == "ASSOCIATION_FIELD") {
      let fieldValue = {field: field, value: product[field.associationField]}
      result.push(fieldValue);
    } else {
      for (var i = 0; i < product?.fieldList?.length; i++) {
        if (product.fieldList[i].field.id === field.id) {
          result.push(product.fieldList[i]);
        }
      }
    }
    return result;
  }


  const handleAddArticleToProductList = async (productList) => {
    setArticleFavouriteListLoading(true);
    try {
      const response = await get(`/api/productLists/articles/${product.id}/add${productList ? "?productListId=" + productList.id : ""}`);
      if(!productList && favourites.length == 0){
        await onGetFavourites();
      }
        getAndSetArticleProductList()
    } catch (err) {
      cogoToast.error(err.response?.data[0], toastOptions);
    }
    setArticleFavouriteListLoading(false);
  }

  const handleProductListButtonClick = async () => {
    if(articleProductList == null || articleProductList.length == 0){
      handleAddArticleToProductList();
    }
  }

  const handleRemoveSelectedProductList = async (value) => {
    setArticleFavouriteListLoading(true);
    try {
        await get(`/api/productLists/${value?.id}/articles/${product.id}/remove`);
        getAndSetArticleProductList()
    } catch (err) {
      cogoToast.error(err.response?.data[0], toastOptions);
    }
    setArticleFavouriteListLoading(false);
  }

  const [groups, setGroups] = useState([]);
  useEffect(() => {
    if(groups.length == 0){
      onGetGroupListByArticle(product.id);
    }
  }, [product.id])

  useEffect(() => {
    setGroups(groupByArticle);
  }, [groupByArticle])

  const parseExpression = (oprand1, operator, oprand2) => {
    operator = operator == "x" ? "*" : "/";
    return Math.round(evaluate(oprand1 + " " +  operator + " " + oprand2));
  }

  const handleQuantityChange = (product, decrementQuantity) => {
    qtyPrevClickCount = qtyClickCount;
    qtyClickCount++;
    setTimeout(() => {
      // check if input is clicked multiple times or just one time in 1sec timespan
      if((qtyClickCount != 0 && qtyPrevClickCount == qtyClickCount) || (qtyClickCount == 1 && qtyPrevClickCount == 0)){
        const articleQty = quantityInput;
        let inputQty = 0;
        if(decrementQuantity){
          // quantity cannot go below 0
          if(articleQty - qtyClickCount > 0){
            inputQty = articleQty - qtyClickCount; 
          }
        } else {
          inputQty = articleQty + qtyClickCount;
        }
        CartService.addItemsToCart(product, inputQty);

        // reset counts
        qtyClickCount = 0;
        qtyPrevClickCount = 0;
      } else {
        // if all the clicks are done and 1 sec passed then set prev click count to current to execute the above if condition
        qtyPrevClickCount = qtyClickCount;
      }
    }, 1000)
  }

  const getRangeValue = (fieldValue) => {
    let unit = fieldValue.field?.unit ? fieldValue.field?.unit : "";
    if (fieldValue.minValue && fieldValue.maxValue) {
      return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit + " - " + Number(parseFloat(fieldValue.maxValue)).toLocaleString('sv') + " " + unit;
    } else if(fieldValue.minValue) {
      return Number(parseFloat(fieldValue.minValue)).toLocaleString('sv') + " " + unit;
    }
    return "";
  }

  const processChange = debounce((arg) => {
      const {quantity, product} = arg;
      const cartItem = find(cartItems, item => item.article.id == product.id);
      if(cartItem){
        setQuantityInput(quantity);
        put(`/api/cart`, {id: cartItem.id, article: {id: product.id}, quantity});
        dispatch(putUpdateCartItemFromInput({...cartItem, quantity}));
      }
  });

  const getArticlePackagePrice = (articlePackage) => {
    let mPrice = articlePackage?.price;
    if (articlePackage?.articlePackagePriceLists?.length > 0) {
      let pList = articlePackage?.articlePackagePriceLists.find(p => p.priceListCode == priceList);
      if (pList && pList?.price) {
        mPrice = pList?.price;
      }
    }
    return mPrice;
  }

  const handlePopulatePackagePrice = (quantity) => {
    let articlePackage = find(product.articlePackage, {quantity: parseInt(quantity)});
    let mPrice = getArticlePackagePrice(articlePackage);
    setArticlePackagePrice(mPrice);
  }

  const handleAddToCart = () => {
    CartService.addPackageToCart(product, manualPackageCartQuantity)
    setManualPackageCartQuantity(null);
    setArticlePackagePrice(null);
  }

  const tabHasContent = (tab) => {
    let result = false
    tab.fields?.map(function (field) {
      if (field?.permission == "ROLE_CUSTOMER_USER") {
        let valueArray = getFieldValue(field);
        if (valueArray.length > 0 && valueArray[0]?.value) {
          result = true;
        }
      }
    });
    return result;
  }

  const ArticleDetailView = () => {
    return (
    <>{error && (
      <Alert className="text-align-center" color="danger">
        {error}
      </Alert>
    )}
    {!isEmpty(product) && <>
    <div className={user?.customerId ? '' : "d-flex justify-content-center align-items-center"}>
    <Row style={{'max-width': user?.customerId ? '' : '1200px', 'width': '100%'}}>
      <Col xl="5">
        {product !== null && product?.articleImages && (
          <ImageGallery
            items={
              // images
              product?.articleImages?.map((item) => {
                return {
                  original: BASE_IMAGE_URL + "/" + item.media.s3Key,
                  thumbnail: BASE_IMAGE_URL + "/" + item.media.s3Key,
                };
              })
            }
          />
        )}
      </Col>

      <Col xl="7">
        <div className="ps-xl-4 min-height-250">
          <div className="article-detail-top-row">
            <div>
              {product.categories != null &&
                product.categories?.map(function (element) {
                  return (
                    <Chip
                      avatar={
                        <Avatar className={classes.chipsAvatar}>
                          {element.name.slice(0, 1).toUpperCase()}
                        </Avatar>
                      }
                      onClick={() => openCategoryProduct(element)}
                      className={`${classes.chips} p-1`}
                      size="small"
                      label={element.name}
                      clickable
                      color="primary"
                      variant="outlined"
                    />
                  );
                })}
            </div>
            {/* <DropdownSelect 
                  options={favoriteList}
                  values={favoriteList.filter(f => articleProductList?.find(af => af.id === f.id))}
                  searchBy="name"
                  valueField="id"
                  labelField="label"
                  placeholder={props.t("Add to Product List")}
                  multi={true}
                  color={brandSettings?.primaryBtnColor}
                  onChange={handleSelectedProductList}
                  clearable={false}
                  loading={articleFavouriteListLoading || getFavouritesLoading} /> :  */}
              {showBuyOption &&
                (favoriteList && favoriteList.length > 0 ?
                  <SearchableDropdown 
                  options={favoriteList.filter(f => !articleProductList?.find(af => af.favoriteList.id === f.id))} 
                  placeholder={props.t("Add to Product List")}
                  keyToDisplay={["name"]}
                  removeHorizontalDropdownStretch={true}
                  brandColor={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                  handleOnOptionClick={(productList) => handleAddArticleToProductList(productList)}
                  handleOnButtonClick={handleProductListButtonClick} /> : 

                <Button 
                  onClick={() => handleAddArticleToProductList(null)} 
                  style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor, maxHeight: '35px' }}>
                  {props.t("Add to Product List")}
                </Button>)}
          </div>
          {articleProductList.length > 0 &&
          <div className="row">
            <div className="d-flex flex-wrap">
                {articleProductList?.map(function (apl) {
                  const element = apl?.favoriteList;
                  return (
                    <div className="product-ribbon badge bg-soft-success bg-success m-1 px-2 d-flex align-items-center justify-content-center">
                        <div onClick={() => openProductList(element)} style={{cursor: 'pointer'}}>{element.name}</div>
                        {apl?.customer && <div onClick={() => apl?.customer ? handleRemoveSelectedProductList(element) : null}  style={{cursor: 'pointer'}}><i className={`uil uil-trash-alt p-1 text-danger font-size-18`}></i></div>}
                    </div>
                  );
                })}
            </div>
          </div>}
          <div className="row m-1 mt-2">
            <Divider />
          </div>
          <div className="row">
            <h4 className="font-size-20 fw-bold">
              {product.displayName
                ? product.displayName
                : product.description}
            </h4>
            <div className="col-lg-6 mt-4 pt-2">
              <h5 className=" fs-2">
                {(product?.articlePackageType ? (articlePackagePrice ? articlePackagePrice + " kr" : manualPackageCartQuantity ? <label style={{fontSize:"14px"}} className={"text-danger"}>{props.t("Please contact support for price")}</label> : "") : (articlePrice ? articlePrice + " kr" : <label className={"text-danger"} style={{fontSize:"14px"}} className={"text-danger"}>{props.t("Please contact support for price")}</label>))} {" "}
              </h5>
            </div>
            <div className="col-lg-5 text-align-right mt-4 pt-2">
              {isStockable(product) &&
              <>
                <label>
                  {user?.customerId ? getQuantityRowValue(product) : <p className={product.disposableQuantity > 0 ? "text-success" : "text-danger"}>{product.disposableQuantity > 0 ? props.t("In Stock") : props.t("Out of Stock")}: {product.disposableQuantity}</p>}
                </label>
                <br />
              </>}
              <label>
                <i className="uil-notes"></i>
                {product.articleNumber}
              </label>
            </div>
          </div>
          {
            <p className="mt-4 text-muted" dangerouslySetInnerHTML={{__html: product.longDescription}}>
            </p>
          }


         {/*<ArticleGroupPreview articleId={params?.id} showBuyOption={true}/>*/}
          <Row>
            {groups?.map(function(group){
              return (<Col lg={12}>
                <p>{group.description}</p>
              </Col>)
            })}
          </Row>
          <Row>
            {groups?.map(function(group){
              return (<>
                {group.fields?.map(function(content){
                  return (
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          {content?.name}
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>{props.t("Select")}</option>
                            {group?.articleList?.map(function(article){
                              return (<>
                                {article?.fieldList?.map(function(articleContent){
                                  if(articleContent?.field?.id === content.id){
                                    return (<>
                                      <option>{articleContent.value}</option>
                                    </>);
                                  }
                                })}
                              </>);
                            })}
                          </select>
                        </div>
                      </Row>
                  )
                })}
              </>);
            })}
          </Row>
          

          {showBuyOption && (<>
            <Row className="text-center mt-2">
              {product.articlePackageType == "MANUAL" && <>
              <div className="col-sm-3 d-grid">
                <select
                  className="form-control"
                  value={manualPackageCartQuantity}
                  onChange={(e) => {setManualPackageCartQuantity(e.target.value); handlePopulatePackagePrice(e.target.value)}}
                >
                  <option value={""}>{props.t("Select Package")}</option>
                  {product.articlePackage.map(pkg => <option value={pkg.quantity}>{pkg.name}</option>)}
                </select>
              </div>
              <div className="col-sm-3 d-grid">
                <button className={"btn btn-primary"}
                  disabled={!manualPackageCartQuantity}
                  onClick={handleAddToCart}
                  style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}>
                  {props.t("Add to Cart")}
                </button>
              </div>
              </>}
              {(quantityInput < 1 && product.articlePackageType != "MANUAL") && (
                <div className="col-sm-3 d-grid">
                  <Button
                    type="button"
                    color="primary"
                    style={{ backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor }}
                    className="btn-block waves-effect waves-light mt-2 me-1  width-130px"
                    onClick={(e) => {
                      e.preventDefault();
                      CartService.addItemToCart(
                        product
                      );
                    }}
                  >
                    <i className="uil uil-shopping-cart-alt me-2"></i>
                    {props.t("Add to cart")}
                  </Button>
                </div>
              )}
              {(quantityInput > 0 && product.articlePackageType != "MANUAL") && (
                <div className="col-sm-4 d-grid">
                  <div className="btn-block waves-effect waves-light mt-2 me-1 d-flex">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-block waves-effect waves-light"
                      style={{
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor
                      }}
                      onClick={(e) => handleQuantityChange(product, true)}
                      disabled={cartItemsLoading}
                    >
                      <i className="uil uil-minus"></i>
                    </Button>
                    <input
                      className="form-control"
                      style={{ borderRadius: "0" }}
                      type="number"
                      defaultValue={quantityInput == 0 ? "" : quantityInput}
                      onChange={(e) => processChange({quantity: parseInt(e.target.value), product})}
                      onWheel={preventNumberInputScroll}
                      // onKeyDown={validateNumber}
                    />
                    <Button
                      type="button"
                      color="primary"
                      className="btn-block waves-effect waves-light"
                      style={{
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0",
                        backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor
                      }}
                      onClick={(e) => handleQuantityChange(product, false)}
                      disabled={cartItemsLoading}
                    >
                      <i className="uil uil-plus"></i>
                    </Button>
                  </div>
                </div>
              )}
            </Row>
            <Row className={"mt-5"} key={manualPackageCartQuantity}>
              {product.articlePackageType && <>
                {product.articlePackageType === "MANUAL" && manualPackageCartQuantity && <>
                  <ol className='pb-0 ps-0 mb-0' style={{"width": '90%'}}>
                    {find(product.articlePackage, {quantity: quantityInput})?.packageItems?.map(function (i, key) {
                      return (
                          <li className='d-flex justify-content-between'>
                            <label className='mb-0'>{(key + 1)}. {i?.articleDto?.description}</label>
                            {i?.quantity ? <label className='mb-0 ms-3'>{props.t("Qty")} - {i?.quantity}</label> : ''}
                          </li>)
                    })}
                  </ol>
                </>}
                {(product.articlePackageType === "DYNAMIC" && quantityInput > 0) &&
                    <ol className='pb-0 ps-0 mb-0' style={{"width": '90%'}}>
                      {product.articlePackage[0]?.packageItems?.map(function (i, key) {
                        return (
                            <li className='d-flex justify-content-between'>
                              <label className='mb-0'>{(key + 1)}. {i?.articleDto?.description}</label>
                              {i?.quantity ? <label className='mb-0 ms-3'>{props.t("Qty")} - {parseExpression(quantityInput , i?.operator, i?.quantity)}</label> : ''}
                            </li>)
                      })}
                    </ol>}
              </>}
            </Row>
          </>)}
        </div>
      </Col>
    </Row>
    </div>


    <div className="mt-4" key={"content_detail_" + product.id}>
      <div className="product-desc">
        <Nav tabs className="nav-tabs-custom">
          {product?.tabs?.map(function (tab) {
            if(tabHasContent(tab)){
              return (
                  <NavItem className="cursor-pointer">
                    <NavLink
                        className={classnames({
                          active:
                              activeDescriptionTab === tab.id,
                        })}
                        onClick={() => {
                          toggledescription(tab.id);
                        }}
                    >
                      {props.t(tab.name)}
                    </NavLink>
                  </NavItem>
              );
            }
          })}
        </Nav>
        <TabContent
          activeTab={activeDescriptionTab}
          className="border border-top-0 p-4"
        >
          {product?.tabs?.map(function (tab){
            if (tab.categoryType === "FILES") {
              return (<TabPane tabId={tab.id}>
                {tab.fields?.map(function (field) {
                  return (<div className="col-lg-12 mt-2">
                    {getFieldValue(field).map(fieldValue => <button
                        className="btn btn-primary btn-sm m-2"
                        style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                        onClick={() => handleDownload(DOWNLOAD_URL + "/" + fieldValue.value)}
                    >
                      {fieldValue?.downloadLabel}
                    </button>)}
                  </div>);
                })}
              </TabPane>)
            } else if (tab.categoryType === "IMAGES") {
              return (<TabPane tabId={tab.id}>
                <div className="row">
                  {tab.fields?.map(function (field) {
                      return (<>
                        {getFieldValue(field).map(fieldValue => <div className="col-lg-2">
                          <img className="certificate-thumbnail height-150" src={DOWNLOAD_URL + "/" + fieldValue.value}/>
                        </div>)}
                      </>);
                  })}
                </div>
              </TabPane>)
            } else if (tab.categoryType === "FREE_TEXT") {
              return (<TabPane tabId={tab.id}>
                <div className="row">
                  {tab.fields?.map(function (field) {
                      return (<>
                      {getFieldValue(field).map(fieldValue => fieldValue.field.fieldType === 'FREE_TEXT' &&
                          <div className="col-lg-12" dangerouslySetInnerHTML={{__html: fieldValue.value}}></div>)}
                      </>);
                  })}
                </div>
              </TabPane>)
            } else {
              return (<TabPane tabId={tab.id}>
                <table className="table table-borderless table-hover">
                  <tbody>
                  {tab.fields?.map(function (field) {
                    if(field?.permission == "ROLE_CUSTOMER_USER") {
                      return (<>
                        {getFieldValue(field).filter(fieldValue => fieldValue?.value).map((fieldValue) => (
                            <>{fieldValue?.field?.contentType === "FILE" ?
                                (<tr>
                                  <td colSpan={2}>
                                    <p>
                                      <a
                                          className={"btn btn-primary btn-sm"}
                                          href={DOWNLOAD_URL + "/" + fieldValue.value}
                                      >
                                        {fieldValue.name}
                                      </a>
                                    </p>
                                  </td>
                                </tr>)
                                : fieldValue?.field?.contentType === "HEADER" ? (
                                    <tr>
                                      <td colSpan={2}>
                                        <h4> {fieldValue.field?.name} </h4>
                                      </td>
                                    </tr>
                                ) : fieldValue?.field?.contentType === "BOOLEAN" ? (
                                    <tr>
                                      <td>{fieldValue.field?.name}</td>
                                      <td>
                                        {fieldValue?.value == "true" && props.t("Yes")}
                                        {fieldValue?.value == "false" && props.t("No")}
                                        {fieldValue?.value == null && props.t("Not applicable")}
                                      </td>
                                    </tr>
                                ) : fieldValue?.field?.fieldType == "DROPDOWN_MULTISELECT" ? (
                                    <tr>
                                      <td>{fieldValue.field?.name}</td>
                                      <td>
                                        {fieldValue?.value.split(",").sort((a, b) => a.localeCompare(b)).map((value) => <>
                                          <span
                                              className="bg-primary badge bg-secondary multi-select-label">{value}</span></>)}
                                      </td>
                                    </tr>
                                ) : fieldValue?.field?.fieldType == "NUMBER_FIELD" ? (
                                    <tr>
                                      <td>{fieldValue.field?.name}</td>
                                      <td>
                                    <span>
                                      {getRangeValue(fieldValue)}
                                    </span>
                                      </td>
                                    </tr>
                                ) : (<tr>
                                  <td className="min-width-200">
                                    {fieldValue?.field?.name}
                                  </td>
                                  <td>
                              <span
                                  dangerouslySetInnerHTML={{
                                    __html: fieldValue.value + " " + (fieldValue.field?.unit ? fieldValue.field?.unit : ""),
                                  }}
                              ></span>
                                  </td>
                                </tr>)}
                            </>
                        ))}
                      </>);
                    }
                  })}
                  </tbody>
                </table>
              </TabPane>)
            }})}
        </TabContent>
      </div>
    </div></>}</>
  )
}

return (
    <React.Fragment>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="table-header-bg" style={{ backgroundColor: brandSettings?.primaryColor}}>
                  <TableToolbar
                      title={product.displayName ? product.displayName : product.description ? product.description : "Article Details"}
                  />
              </CardHeader>

              <Loader loading={props.loading} title="Article Details" />
              {(!props.loading) &&
              <CardBody>
                <ArticleDetailView />
              </CardBody>}
            </Card>
          </Col>
        </Row>
    </React.Fragment>
  );
};

ArticlesDetail.propTypes = {
  product: PropTypes.object,
  contentTypes: PropTypes.array,
  onGetCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  cartItemError: PropTypes.object,
  groupByArticle: PropTypes.array,
  onGetGroupListByArticle: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    contentTypes: state.ecommerce.contentTypesForArticle,
    cartItems: state.Cart?.cartItems,
    cartItemError: state.Cart?.cartItemError,
    articleConfig: state.Config.articleConfig,
    favourites: state.ecommerce.favourites,
    getFavouritesLoading: state.ecommerce.getFavouritesLoading,
    groupByArticle: state.ecommerce.groupByArticle,
    cartItemsLoading: state.Cart?.cartItemsLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onGetContentTypes: (id) => dispatch(getContentTypeForArticle(id)),
  onGetCartItems: () => dispatch(getAllCartItems()),
  onGetArticleConfig: () => dispatch(getArticleConfig()),
  onGetFavourites: () => dispatch(getFavourites()),
  onGetGroupListByArticle: id => dispatch(getGroupsByArticle(id)),
  onResetCartMessage: () => dispatch(resetCartMessage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ArticlesDetail)));
