import Dropzone from "react-dropzone";
import {DOWNLOAD_URL} from "../../helpers/url_helper";
import {Button, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {del, postMultipart} from "../../helpers/api_helper";

const ArticleCertificateTab = (props) => {

    let {article, type, getFileContent, handleAcceptedFilesCertificateCustomContent, handleDeleteContent} = props;
    const [fileUpload, setFileUpload] = useState([]);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if(type && type.fields && type.fields.length > 0){
            setContent(type.fields[0]);
        }
    }, [type])

    const isTabField = (tab, fieldValue) => {
        for(var i = 0; i < tab.fields.length; i++){
            if(tab.fields[i].id == fieldValue.field.id){
                return true;
            }
        }
    }

    useEffect(function(){
        if(fileUpload.length == 0){
            let files = [];
            article?.fieldList?.map(function(fieldValue, index){
                if(isTabField(type, fieldValue)){
                    var obj = {contentObject: fieldValue, content: fieldValue.id, file: {name : fieldValue.label, id: fieldValue.value}};
                    files.push(obj);
                }
            })
            setFileUpload(files)
        }

    }, [article])

    const handleAcceptedFilesCustomContent = async (type, content, files) => {
        let formData = new FormData();
        files.map(file => formData.append("files[]", file));
        let response = await postMultipart("/api/media", formData);
        if (response.status === 200) {
            handleAcceptedFilesCertificateCustomContent(type, content, response.data[0])
            var obj = {content: content?.id, file: response?.data[0]};
            setFileUpload(prevState => ([...fileUpload, obj]));
        }
    }

    const handleDelete = async (file) => {
        let response = await del(`/api/media/${file.file.id}`);
        let index = -1;
        for(var i = 0; i < fileUpload.length; i++){
            if(fileUpload[i].file?.id === file.file?.id){
                index = i;
            }
        }

        var array = [...fileUpload];
        if (index !== -1) {
            array.splice(index, 1);
            setFileUpload(array)
        }

        handleDeleteContent(file.contentObject, file.file?.id, file?.content)
    }

    return (<React.Fragment>
        <Row className="mb-3 margin-top-10px">
            <label htmlFor="example-text-input"
                   className="col-md-2 col-form-label">
                {content?.name}
            </label>
            <div className="col-md-10">
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFilesCustomContent(type, content, acceptedFiles)
                    }}>

                    {({getRootProps, getInputProps}) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload"/>
                                </div>
                                <h4>{props.t("Drop files here or click to upload")}</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <br/>
                {/*<span>{getFileContent(content)}</span>*/}
                <div className={"row"} key={"file-download" + Object.keys(fileUpload).length}>
                    {fileUpload.map(function (file, index) {
                        return (<div key={file.file?.id + "-file"} className="col-lg-2 mt-4 text-align-center">
                            <div className="col-mg-12">
                                <img className="certificate-thumbnail height-150" src={DOWNLOAD_URL + "/" + file.file?.id}/>
                            </div>
                            <br/>
                            <Button
                                color="danger"
                                onClick={() => handleDelete(file)}
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light me-auto">
                                {props.t("Delete")}
                            </Button>
                        </div>)
                    })}
                </div>

            </div>
        </Row>
    </React.Fragment>)
}

export default connect()(withTranslation()(ArticleCertificateTab));
