import {call, put, takeEvery} from "redux-saga/effects"
import {getBrandSettingsIncludingSaSettingsFail, getBrandSettingsIncludingSaSettingsSuccess, getBrandSettingsSuccess, postBrandSettingsSuccess, postResetBrandColorsFail, postResetBrandColorsSuccess} from "./actions";
import {getBrandingSettings, getBrandSettingsWithSuperAdminSettings, postBrandSettings, postResetBrandColors} from "../../helpers/fakebackend_helper";
import {GET_BRAND_SETTINGS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS, POST_BRAND_SETTINGS, POST_RESET_BRAND_COLORS} from "./actionTypes";
import { API_ERR_MSG } from "../../util/constants";


function* fetchBrandingSettings() {
    try {
        const response = yield call(getBrandingSettings)
        yield put(getBrandSettingsSuccess(response))
    } catch (error) {
        console.log(error);
    }
}

function* fetchBrandSettingsIncludingSaSettings() {
    try {
        const response = yield call(getBrandSettingsWithSuperAdminSettings)
        yield put(getBrandSettingsIncludingSaSettingsSuccess(response))
    } catch (error) {
        yield put(getBrandSettingsIncludingSaSettingsFail(API_ERR_MSG))
    }
}

function* postBrandingSetting({brandSettings}) {
    try {
        const response = yield call(postBrandSettings, brandSettings);
        yield put(postBrandSettingsSuccess(response));
    } catch (error) {
        console.log(error);
    }
}

function* postResetBrandingColors() {
    try {
        const response = yield call(postResetBrandColors);
        const response2 = yield call(getBrandingSettings);
        let user = JSON.parse(localStorage.getItem("authUser"));
        user.brandConfig = response2.data;
        localStorage.setItem("authUser", JSON.stringify(user));
        yield put(postResetBrandColorsSuccess(response));
        window.location.reload();
    } catch (error) {
        yield put(postResetBrandColorsFail(API_ERR_MSG))
    }
}

function* brandSettingsSaga() {
    yield takeEvery(GET_BRAND_SETTINGS, fetchBrandingSettings)
    yield takeEvery(GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS, fetchBrandSettingsIncludingSaSettings)
    yield takeEvery(POST_BRAND_SETTINGS, postBrandingSetting)
    yield takeEvery(POST_RESET_BRAND_COLORS, postResetBrandingColors)
}

export default brandSettingsSaga
