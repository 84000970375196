export const GET_SYSTEM_CONFIG = "GET_SYSTEM_CONFIG"
export const GET_SYSTEM_CONFIG_SUCCESS = "GET_SYSTEM_CONFIG_SUCCESS"
export const GET_SYSTEM_CONFIG_FAIL = "GET_SYSTEM_CONFIG_FAIL"
export const GET_SYSTEM_CONFIG_RESET = "GET_SYSTEM_CONFIG_RESET"

export const GET_TENANT_CONFIG = "GET_TENANT_CONFIG"
export const GET_TENANT_CONFIG_SUCCESS = "GET_TENANT_CONFIG_SUCCESS"
export const GET_TENANT_CONFIG_FAIL = "GET_TENANT_CONFIG_FAIL"
export const GET_TENANT_CONFIG_RESET = "GET_TENANT_CONFIG_RESET"

export const GET_SMTP_CONFIG = "GET_SMTP_CONFIG"
export const GET_SMTP_CONFIG_SUCCESS = "GET_SMTP_CONFIG_SUCCESS"
export const GET_SMTP_CONFIG_FAIL = "GET_SMTP_CONFIG_FAIL"
export const GET_SMTP_CONFIG_RESET = "GET_SMTP_CONFIG_RESET"

export const GET_ARTICLE_CONFIG = "GET_ARTICLE_CONFIG"
export const GET_ARTICLE_CONFIG_SUCCESS = "GET_ARTICLE_CONFIG_SUCCESS"
export const GET_ARTICLE_CONFIG_FAIL = "GET_ARTICLE_CONFIG_FAIL"
export const GET_ARTICLE_CONFIG_RESET = "GET_ARTICLE_CONFIG_RESET"

export const GET_STOCK_POINT_CONFIG = "GET_STOCK_POINT_CONFIG"
export const GET_STOCK_POINT_CONFIG_SUCCESS = "GET_STOCK_POINT_CONFIG_SUCCESS"
export const GET_STOCK_POINT_CONFIG_FAIL = "GET_STOCK_POINT_CONFIG_FAIL"
export const GET_STOCK_POINT_CONFIG_RESET = "GET_STOCK_POINT_CONFIG_RESET"

export const GET_STOCK_POINTS = "GET_STOCK_POINTS"
export const GET_STOCK_POINTS_SUCCESS = "GET_STOCK_POINTS_SUCCESS"
export const GET_STOCK_POINTS_FAIL = "GET_STOCK_POINTS_FAIL"
export const GET_STOCK_POINTS_RESET = "GET_STOCK_POINTS_RESET"

export const GET_CURRENCY_LIST = "GET_CURRENCY_LIST"
export const GET_CURRENCY_LIST_SUCCESS = "GET_CURRENCY_LIST_SUCCESS"
export const GET_CURRENCY_LIST_FAIL = "GET_CURRENCY_LIST_FAIL"
export const GET_CURRENCY_LIST_RESET = "GET_CURRENCY_LIST_RESET"

export const GET_PROJECT_CONFIG = "GET_PROJECT_CONFIG"
export const GET_PROJECT_CONFIG_SUCCESS = "GET_PROJECT_CONFIG_SUCCESS"
export const GET_PROJECT_CONFIG_FAIL = "GET_PROJECT_CONFIG_FAIL"
export const GET_PROJECT_CONFIG_RESET = "GET_PROJECT_CONFIG_RESET"

export const configActionTypes = {
    GET_SYSTEM_CONFIG,
    GET_TENANT_CONFIG,
    GET_SMTP_CONFIG,
    GET_ARTICLE_CONFIG,
    GET_STOCK_POINT_CONFIG,
    GET_STOCK_POINTS,
}

export const GET_CRON_LOG = "GET_CRON_LOG";
export const GET_CRON_LOG_SUCCESS = "GET_CRON_LOG_SUCCESS"
export const GET_CRON_LOG_FAIL = "GET_CRON_LOG_FAIL"
