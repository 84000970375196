import React, {useEffect, useState} from "react"
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle,} from "reactstrap"
import {get, map} from "lodash"
import {withTranslation} from "react-i18next"

//i18n
import i18n from "../../../i18n"
import languages from "../../../common/languages"
import PropTypes from "prop-types";
import {getLanguageList} from "../../../store/language/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {LANGUAGE_MASTER_LIST} from "../../../util/constants";

const LanguageDropdown = (props) => {
    // Declare a new state variable, which we'll call "menu"
    const {languageList, onGetLanguageList, loading, error} = props;
    const [selectedLang, setSelectedLang] = useState("")
    const [menu, setMenu] = useState(false)

    const user = JSON.parse(localStorage.getItem("authUser"))

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
        setSelectedLang(currentLanguage)
        onGetLanguageList();
    }, [])

    const changeLanguageAction = lang => {
        //set language as i18n
        if (user?.tenantId !== null) {
            i18n.changeLanguage(lang + '-x-' + user?.tenantId)
        } else {
            i18n.changeLanguage(lang)
        }
        setSelectedLang(lang)
    }

    const toggle = () => {
        setMenu(!menu)
    }

    const getLanguage = (key) => {
        return languages[key];
    }

    const getLanguageLabel = (key) => {
        for (let i = 0; i < LANGUAGE_MASTER_LIST.length; i++) {
            if (LANGUAGE_MASTER_LIST[i].key == key) {
                return LANGUAGE_MASTER_LIST[i].value;
            }
        }
        return key;
    }

    return (
        <>
            <Dropdown isOpen={menu} toggle={toggle} className="language-switch" style={{display: 'flex', alignItems: 'center'}}>
                <DropdownToggle className="btn header-item waves-effect" tag="button">
                    {get(languages, `${selectedLang?.split('-')[0]}.flag`) && <img
                        src={get(languages, `${selectedLang?.split('-')[0]}.flag`)}
                        alt="Header Language"
                        height="16"
                    />}
                    {!get(languages, `${selectedLang?.split('-')[0]}.flag`) &&
                        <>
                            {getLanguageLabel(selectedLang)}
                        </>
                    }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" right>

                    <DropdownItem
                        key={"en"}
                        onClick={() => changeLanguageAction("en")}
                        className={`notify-item ${selectedLang === "en" ? "active" : "none"
                        }`}
                    >
                        <img
                            src={get(languages, `en.flag`)}
                            alt="Language"
                            className="me-1"
                            height="12"
                        />
                        <span className="align-middle">
                                {get(languages, `en.label`)}
                              </span>
                    </DropdownItem>
                    {map(languageList, key => (
                        <DropdownItem
                            key={key}
                            onClick={() => changeLanguageAction(key)}
                            className={`notify-item ${selectedLang === key ? "active" : "none"
                            }`}
                        >
                            {get(languages, `${key}.flag`) &&
                                <img
                                    src={get(languages, `${key}.flag`)}
                                    alt="Language"
                                    className="me-1"
                                    height="12"
                                />
                            }

                            <span className="align-middle">
                            {getLanguageLabel(key)}
                          </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    )
}

LanguageDropdown.propTypes = {
    languageList: PropTypes.array,
    onGetLanguageList: PropTypes.func
}

const mapStateToProps = state => {
    return {
        languageList: state.Language.languageList,
        error: state.Language.languageListError,
        loading: state.Language.languageListLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onGetLanguageList: () => dispatch(getLanguageList()),
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(LanguageDropdown)));
