import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import TableToolbar from "../../components/Common/TableToolbar";
import {useHistory, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Loader from "../../components/Common/Loader";
import {MDBDataTable} from "mdbreact";
import {get} from "../../helpers/api_helper";
import {getTenants} from "../../store/tenant/actions";
import PropTypes from "prop-types";


const DeepSync = (props) => {
    const {tenants, onGetTenants, match: {params}} = props;
    const [brandSettings, setBrandSettings] = useState(null)
    const [filter, setFilter] = useState('ALL');
    const [loading, setLoding] = useState(false);
    const [logData, setLogData] = useState([]);
    const [tenant, setTenant] = useState(null);
    const history = useHistory();
    const [error, setError] = useState(false);

    const user = JSON.parse(localStorage.getItem("authUser"));
    const level = localStorage.getItem("level");
    const CRON_LIST = [{"key": "SYNC_EVERYTHING", "value": "Sync everything"}, {"key": "ARTICLES", "value": "Articles"}, {"key": "CUSTOMERS", "value": "Customer"}, {
        "key": "PROJECTS",
        "value": "Projects"
    }, {"key": "ORDERS", "value": "Orders"}, {"key": "OFFERS", "value": "Offers"}, {"key": "INVOICES", "value": "Invoices"}];
    const [cron, setCron] = useState(null);

    //    CustomerTimeslips
    useEffect(() => {
        getLogs();
        if(level === "SUPERADMIN"){
            onGetTenants();
        }

    }, []);


    const getLogs = async () => {
        setLoding(true);
        let response = await get("/api/deep-sync");
        setLoding(false);
        if (response.status === 200) {
            setLogData(response?.data);
        }
    }

    const execute = async () => {
        setError(false)
        let queryString = "";
        if(level === "SUPERADMIN"){
            queryString = `?tenantId=${tenant}`;

            if(!tenant){
                setError(true);
            }
        }
        let response = await get(`/api/deep-sync/${cron}${queryString}`);
        setCron(null);
        getLogs();
    }


    const getDatatableData = () => {

        let cols = [
            {
                label: props.t("Created"),
                field: "created",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Tenant"),
                field: "tenant",
                width: 300
            },
            {
                label: props.t("Type"),
                field: "type",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Time"),
                field: "time",
                sort: "asc",
                autoWidth: true,
            },
            {
                label: props.t("Status"),
                field: "status",
                sort: "asc",
                autoWidth: true,
            }
        ];
        let data = {
            columns: cols,
            rows: logData?.map((item, key) => ({
                created: item?.createdAt,
                tenant: item?.tenantName,
                type: item?.name,
                time: item?.timeToComplete ? (item?.timeToComplete / 60000).toFixed(2) + " mins" : null,
                status: item?.status,
            })),
        };
        return data;
    };


    return (<React.Fragment>
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col className="col-12 article-table">
                        <Card>
                            <CardHeader className="table-header-bg" style={{backgroundColor: brandSettings?.primaryColor}}>
                                <TableToolbar
                                    title={props.t("DeepSync")}
                                    /*buttons={getTimeslipButton()}*/
                                    /*filters={!user?.customerId && user?.tenantId ? [
                                        {
                                            selected: filter,
                                            options : getFilterButtonGroups(),
                                            onSelect: (option) => {
                                                if(option == 'MY_TIMESLIPS'){
                                                    setCustomFilter({userId: user?.uid})
                                                } else if(option == 'ALL'){
                                                    setCustomFilter(null);
                                                }
                                                setFilter(option);
                                            }
                                        }

                                    ] : []}*/
                                />
                            </CardHeader>
                            <CardBody>
                                {level === "SUPERADMIN" && <Row className="mb-3">
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-md-2 col-form-label"
                                    >
                                        {props.t("Tenant")}
                                    </label>
                                    <div className="col-md-10">
                                        <select className="form-control" onChange={(e) => setTenant(e.target.value)}>
                                            <option value={""}>{props.t("Select Tenants")}</option>
                                            {tenants.map(function (tenant) {
                                                return <option value={tenant.id}>{tenant.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </Row>}
                                <Row className="mb-3">
                                    <label
                                        htmlFor="example-text-input"
                                        className="col-md-2 col-form-label"
                                    >
                                        {props.t("Sync")}
                                    </label>
                                    <div className="col-md-10">
                                        <select className="form-control" onChange={(e) => setCron(e.target.value)}>
                                            <option value={""}>{props.t("Choose cron job")}</option>
                                            {CRON_LIST.map(function (lang) {
                                                return <option value={lang.key}>{props.t(lang.value)}</option>
                                            })}
                                        </select>
                                    </div>
                                </Row>
                                {error && <Row className="mb-3">
                                    <div className="alert alert-danger" role="alert">
                                        {props.t("Please select tenant")}
                                    </div>
                                </Row>}
                                <Row className="mb-3">
                                    <Col align="center">
                                        <Button
                                            type="submit"
                                            color="success"
                                            className="btn btn-success w-lg waves-effect waves-light me-2"
                                            style={{backgroundColor: brandSettings?.primaryBtnColor, borderColor: brandSettings?.primaryBtnColor}}
                                            onClick={execute}
                                            disabled={cron == null}
                                        >
                                            {props.t("Execute")}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-12 article-table">
                                        <Loader loading={loading} title="Logs"/>
                                        <MDBDataTable
                                            responsive
                                            searching={true}
                                            barReverse={true}
                                            hover
                                            borderless
                                            paginationLabel={[props.t("Previous"), props.t("Next")]}
                                            entriesLabel={props.t("Show entries")}
                                            infoLabel={[
                                                props.t("Showing"),
                                                props.t("to"),
                                                props.t("of"),
                                                props.t("entries"),
                                            ]}
                                            searchLabel={props.t("Search") + "..."}
                                            noRecordsFoundLabel={props.t("No matching records found")}
                                            noBottomColumns={true}
                                            data={getDatatableData()}
                                            entries={50}
                                            entriesOptions={[50, 100, 500]}
                                            onSearch={(text) => console.log(text)}
                                            sortRows={[]}
                                            /*onSort={({column, direction}) => {
                                                if (sortState && sortState.column == column) {
                                                    setSortState({...sortState, direction: sortState.direction == 'asc' ? 'desc' : 'asc'});
                                                } else {
                                                    setSortState({column, direction});
                                                }
                                            }}*/
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    </React.Fragment>)
}

DeepSync.propTypes = {
    tenants: PropTypes.array,
    onGetTenants: PropTypes.func
}

const mapStateToProps = (state) => {
    return {
        tenants: state.tenant.tenantList,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetTenants: () => dispatch(getTenants()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(DeepSync)));
