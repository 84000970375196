import {API_ERROR, LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER, LOGOUT_USER_SUCCESS, SOCIAL_LOGIN, BRAND_CONFIG, BRAND_CONFIG_SUCCESS, BRAND_CONFIG_FAIL} from "./actionTypes"

export const loginUser = (user, history) => {
    return {
        type: LOGIN_USER,
        payload: {user, history},
    }
}

export const loginSuccess = user => {
    return {
        type: LOGIN_SUCCESS,
        payload: user,
    }
}

export const logoutUser = history => {
    return {
        type: LOGOUT_USER,
        payload: {history},
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {},
    }
}

export const apiError = error => {
    return {
        type: API_ERROR,
        payload: error,
    }
}

export const socialLogin = (data, history, type) => {
    return {
        type: SOCIAL_LOGIN,
        payload: {data, history, type},
    }
}

export const getBrand = (domain) => {
    return {
        type: BRAND_CONFIG,
        domain
    }
}

export const brandSuccess = response => {
    return {
        type: BRAND_CONFIG_SUCCESS,
        payload: response.data
    }
}

export const brandFail = error => {
    return {
        type: BRAND_CONFIG_FAIL,
        payload: error
    }
}
