import {
    GET_SMTP_CONFIG,
    GET_SMTP_CONFIG_FAIL,
    GET_SMTP_CONFIG_RESET,
    GET_SMTP_CONFIG_SUCCESS,
    GET_SYSTEM_CONFIG,
    GET_SYSTEM_CONFIG_FAIL,
    GET_SYSTEM_CONFIG_RESET,
    GET_SYSTEM_CONFIG_SUCCESS,
    GET_TENANT_CONFIG,
    GET_TENANT_CONFIG_FAIL,
    GET_TENANT_CONFIG_RESET,
    GET_TENANT_CONFIG_SUCCESS,
    GET_ARTICLE_CONFIG,
    GET_ARTICLE_CONFIG_FAIL,
    GET_ARTICLE_CONFIG_RESET,
    GET_ARTICLE_CONFIG_SUCCESS,
    GET_STOCK_POINT_CONFIG,
    GET_STOCK_POINT_CONFIG_SUCCESS,
    GET_STOCK_POINT_CONFIG_FAIL,
    GET_STOCK_POINT_CONFIG_RESET,
    GET_STOCK_POINTS,
    GET_STOCK_POINTS_SUCCESS,
    GET_STOCK_POINTS_FAIL,
    GET_STOCK_POINTS_RESET, GET_CRON_LOG, GET_CRON_LOG_SUCCESS, GET_CRON_LOG_FAIL, GET_CURRENCY_LIST_SUCCESS, GET_CURRENCY_LIST, GET_CURRENCY_LIST_FAIL, GET_CURRENCY_LIST_RESET, GET_PROJECT_CONFIG, GET_PROJECT_CONFIG_SUCCESS, GET_PROJECT_CONFIG_FAIL, GET_PROJECT_CONFIG_RESET,
} from "./actionTypes";


export const getSystemConfig = () => {
    return {
        type: GET_SYSTEM_CONFIG
    }
}

export const getSystemConfigSuccess = (configs) => {
    return {
        type: GET_SYSTEM_CONFIG_SUCCESS,
        payload: configs
    }
}

export const getSystemConfigFail = (error) => {
    return {
        type: GET_SYSTEM_CONFIG_FAIL,
        payload: error
    }
}

export const getSystemConfigReset = () => {
    return {
        type: GET_SYSTEM_CONFIG_RESET
    }
}

export const getTenantConfig = () => {
    return {
        type: GET_TENANT_CONFIG
    }
}

export const getTenantConfigSuccess = (configs) => {
    return {
        type: GET_TENANT_CONFIG_SUCCESS,
        payload: configs
    }
}

export const getTenantConfigFail = (error) => {
    return {
        type: GET_TENANT_CONFIG_FAIL,
        payload: error
    }
}

export const getTenantConfigReset = (error) => {
    return {
        type: GET_TENANT_CONFIG_RESET,
        payload: error
    }
}

export const getSMTPConfig = () => {
    return {
        type: GET_SMTP_CONFIG
    }
}

export const getSMTPConfigSuccess = (config) => {
    return {
        type: GET_SMTP_CONFIG_SUCCESS,
        payload: config
    }
}

export const getSMTPConfigFail = (error) => {
    return {
        type: GET_SMTP_CONFIG_FAIL,
        payload: error
    }
}

export const getSMTPConfigReset = () => {
    return {
        type: GET_SMTP_CONFIG_RESET
    }
}

export const getArticleConfig = () => {
    return {
        type: GET_ARTICLE_CONFIG
    }
}

export const getArticleConfigSuccess = (config) => {
    return {
        type: GET_ARTICLE_CONFIG_SUCCESS,
        payload: config
    }
}

export const getArticleConfigFail = (error) => {
    return {
        type: GET_ARTICLE_CONFIG_FAIL,
        payload: error
    }
}

export const getArticleConfigReset = () => {
    return {
        type: GET_ARTICLE_CONFIG_RESET
    }
}

export const getStockPointConfig = () => {
    return {
        type: GET_STOCK_POINT_CONFIG
    }
}

export const getStockPointConfigSuccess = (config) => {
    return {
        type: GET_STOCK_POINT_CONFIG_SUCCESS,
        payload: config
    }
}

export const getStockPointConfigFail = (error) => {
    return {
        type: GET_STOCK_POINT_CONFIG_FAIL,
        payload: error
    }
}

export const getStockPointConfigReset = () => {
    return {
        type: GET_STOCK_POINT_CONFIG_RESET
    }
}

export const getStockPoints = () => {
    return {
        type: GET_STOCK_POINTS
    }
}

export const getStockPointsSuccess = (config) => {
    return {
        type: GET_STOCK_POINTS_SUCCESS,
        payload: config
    }
}

export const getStockPointsFail = (error) => {
    return {
        type: GET_STOCK_POINTS_FAIL,
        payload: error
    }
}

export const getStockPointsReset = () => {
    return {
        type: GET_STOCK_POINTS_RESET
    }
}

export const getCurrencyList = () => {
    return {
        type: GET_CURRENCY_LIST
    }
}

export const getCurrencyListSuccess = (data) => {
    return {
        type: GET_CURRENCY_LIST_SUCCESS,
        payload: data
    }
}

export const getCurrencyListFail = (error) => {
    return {
        type: GET_CURRENCY_LIST_FAIL,
        payload: error
    }
}

export const getCurrencyListReset = () => {
    return {
        type: GET_CURRENCY_LIST_RESET
    }
}

export const configActionTypes = {
    GET_SYSTEM_CONFIG,
    GET_TENANT_CONFIG,
    GET_SMTP_CONFIG,
    GET_ARTICLE_CONFIG,
    GET_STOCK_POINT_CONFIG,
    GET_STOCK_POINTS,
}


export const getCronLog = () => {
    return {
        type: GET_CRON_LOG
    }
}

export const getCronLogSuccess = (data) => {
    return {
        type: GET_CRON_LOG_SUCCESS,
        payload: data
    }
}

export const getCronLogError = (error) => {
    return {
        type: GET_CRON_LOG_FAIL,
        payload: error
    }
}

export const getProjectConfig = () => {
    return {
        type: GET_PROJECT_CONFIG
    }
}

export const getProjectConfigSuccess = (config) => {
    return {
        type: GET_PROJECT_CONFIG_SUCCESS,
        payload: config
    }
}

export const getProjectConfigFail = (error) => {
    return {
        type: GET_PROJECT_CONFIG_FAIL,
        payload: error
    }
}

export const getProjectConfigReset = () => {
    return {
        type: GET_PROJECT_CONFIG_RESET
    }
}
