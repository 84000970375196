import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Loader from "../../components/Common/Loader";
import {Alert} from "reactstrap";
import {MDBDataTable} from "mdbreact";
import React, {useEffect} from "react";
import {getOrdersByReference, getOrdersByReferenceReset} from "../../store/e-commerce/actions";
import {downloadPDF} from "../../util/helperFunctions";
import {formatCurrency} from "../../helpers/currency_helper";

const CustomerOrderList = (props) => {
    let {
        cid,
        orders,
        onGetOrders,
        ordersByReferenceError,
        ordersLoading
    } = props;
    let history = useHistory();

    useEffect(() => {
        onGetOrders(cid);
    }, [cid])

    const getOrdersDatatableData = () => {
        let data = {
            columns: [
                {
                    label: props.t("Document Number"),
                    field: "orderNumber",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Total"),
                    field: "amount",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("OrderDate"),
                    field: "date",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: props.t("Download"),
                    field: "download",
                }
            ],
            rows: orders?.map((order, key) => ({
                orderNumber: <div onClick={() => openOrderDetails(order)}>{order.DocumentNumber}</div>,
                amount: <div onClick={() => openOrderDetails(order)}>{formatCurrency(order?.Currency, order.Total)}</div>,
                date: <div onClick={() => openOrderDetails(order)}>{order.OrderDate}</div>,
                download: <div onClick={() => downloadPDF(order.DocumentNumber, 'orders')}><i className="fa fa-download font-size-15"></i></div>
            })),
        };
        return data;
    };

    const openOrderDetails = (order) => {
        let fromUrl = "";
        if(props.from){
            fromUrl = `?from=${props.from}`;
        }
        history.push(`/orders/${order.DocumentNumber + fromUrl}`)
    }

    return (
        <React.Fragment>
            <Loader loading={ordersLoading} title="Orders"/>
            {/*{ordersByReferenceError &&
                <Alert className={"mt-4"} color="danger" role="alert">
                    {ordersByReferenceError}
                </Alert>
            }*/}
            {!ordersLoading &&
                <MDBDataTable
                    responsive
                    hover
                    barReverse={true}
                    borderless
                    paginationLabel={[props.t("Previous"), props.t("Next")]}
                    entriesLabel={props.t("Show entries")}
                    infoLabel={[
                        props.t("Showing"),
                        props.t("to"),
                        props.t("of"),
                        props.t("entries"),
                    ]}
                    searchLabel={props.t("Search") + "..."}
                    noRecordsFoundLabel={props.t("No matching records found")}
                    noBottomColumns={true}
                    data={getOrdersDatatableData()}
                    entries={50}
                    entriesOptions={[50, 100, 500]}
                    onSearch={(text) => console.log(text)}
                    searching={true}
                />}
        </React.Fragment>)
}

CustomerOrderList.propTypes = {
    orders: PropTypes.object,
    onGetOrders: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        orders: state.ecommerce.ordersByReference?.Orders,
        ordersByReferenceError: state.ecommerce.ordersByReferenceError,
        ordersLoading: state.ecommerce.ordersByReferenceLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onGetOrders: (id) => dispatch(getOrdersByReference(id)),
    onGetOrdersReset: () => dispatch(getOrdersByReferenceReset()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(CustomerOrderList)));
