import {
    GET_BRAND_SETTINGS, GET_BRAND_SETTINGS_SUCCESS, POST_BRAND_SETTINGS_SUCCESS, POST_BRAND_SETTINGS_FAIL, GET_BRAND_SETTINGS_FAIL, POST_BRAND_SETTINGS,
    GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS, GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL, POST_RESET_BRAND_COLORS, POST_RESET_BRAND_COLORS_SUCCESS, POST_RESET_BRAND_COLORS_FAIL
} from "./actionTypes";

const INIT_STATE = {
    primaryColor: null,
    secondaryColor: null,
    brandLogo: null,
    error: false,
    loading: false
};

const BrandSettings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BRAND_SETTINGS:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_BRAND_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: false
            }
        case GET_BRAND_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS:
            return {
                ...state,
                loading: true,
                error: false
            }
        case GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: false
            }
        case GET_BRAND_SETTINGS_INCLUDING_SA_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case POST_BRAND_SETTINGS:
            return {
                ...state,
                loading: true
            }
        case POST_BRAND_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
            }
        case POST_BRAND_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case POST_RESET_BRAND_COLORS:
            return {
                ...state,
                loading: false,
                error: false
            }
        case POST_RESET_BRAND_COLORS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                error: false
            }
        case POST_RESET_BRAND_COLORS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state;
    }
};

export default BrandSettings
